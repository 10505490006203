import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { Debug, Init, Loading, Menu, MenuTitle, MenuTop, TemplateWindow } from './items';
import { styles } from './styles';
import { Badge, Button, IconButton, Paper, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faComments, faList, faPerson, faQuestion, faUsers, faXmark } from '@fortawesome/free-solid-svg-icons';
import './items.css';

export const Shortcuts = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    let { func } = props;
    const icon_size = props.isSmall ? 46 : 60;
    let color = global.themes[props.theme];

    useEffect(() => {
    }, [props.items, props.isSmall, props.chatNotify, props.productsNotify, props.bonusNotify, props.reklamacieNotify, props.comNotify, props.expireNotify])

    const IconPress = (id) => {
        func(id);
    }

    const Icon = (props) => {
        const avatar_man = require('./react/app/man.png');
        const avatar_woman = require('./react/app/woman.png');
        const avatar = require('./react/app/avatar.jpg');

        let { func } = props;

        const Press = () => {
            func(props.item);
        }

        return (
            <div onClick={() => Press()} style={{ ...styles.BlockCenter, width: props.width, height: props.height, marginRight: 12, marginLeft: 12, cursor: 'pointer' }}>
                <Badge
                    badgeContent={props.item.exec_id == 9021 ? props.chatNotify : props.item.exec_id == 5101 ? props.productsNotify : props.item.exec_id == 5102 ? props.bonusNotify : props.item.exec_id == 5103 ? props.reklamacieNotify : props.item.exec_id == 5104 ? props.expireNotify : props.item.exec_id == 9023 || props.item.exec_id == 9024 ? props.comNotify : 0}
                    //color="primary"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    sx={{
                        "& .MuiBadge-badge": {
                            color: color.theme_white,
                            backgroundColor: global.theme_dark_red
                        }
                    }}
                    style={{ zIndex: 0 }}
                >
                    <Paper elevation={6} style={{ ...styles.Block, width: props.width, height: props.width, background: props.background, borderRadius: 10 }}>
                        {props.typ == 0 || props.typ == 3 || props.typ == 4 ?
                            <FontAwesomeIcon style={{ height: props.width / 2, color: global.theme_white }} icon={props.icon} />
                            : null}
                        {props.typ == 1 ?
                            <img src={props.icon == 0 ? avatar_woman : props.icon == 1 ? avatar_man : avatar} style={{ width: '100%', height: '100%', maxWidth: props.width - 6, maxHeight: props.width - 6, objectFit: 'contain', borderRadius: 6 }}></img>
                            : null}
                        {props.typ == 2 ?
                            <p style={{ ...styles.TextXXLarge, fontsize: props.width / 2, color: global.theme_white }}>{props.icon}</p>
                            : null}
                    </Paper>
                </Badge>
                <p style={{ ...props.isSmall ? styles.TextXXTiny : styles.TextXTiny, color: global.theme_black, fontWeight: '400', textAlign: 'center', marginTop: 7 }}>{props.label}</p>
            </div>
        )
    }
    return (
        <div style={{ ...styles.BlockLeft, width: props.width, height: props.isSmall ? undefined : props.height - (1 * props.offset), /* global.themes[props.theme].theme_window_background, borderRadius: props.radius,*/ msOverflowStyle: 'none', scrollbarWidth: 'none', overflowX: 'scroll' }} className='container'>
            <div style={{ ...styles.BlockRowRaw, flexWrap: props.isSmall ? undefined : undefined }}>
                {props.items.map((item, index) => (
                    item.label.trim() != '' ?
                        <Icon isSmall={props.isSmall} chatNotify={props.chatNotify} productsNotify={props.productsNotify} bonusNotify={props.bonusNotify} reklamacieNotify={props.reklamacieNotify} expireNotify={props.expireNotify} comNotify={props.comNotify} item={item} key={index} id={item.id} typ={item.typ} label={item.label} icon={item.icon} width={icon_size} background={global.themes[props.theme].shortcut_background} height={props.isSmall ? 105 : props.height - (1 * props.offset)} func={IconPress.bind(this)} />
                        : null
                ))}
            </div>
        </div>
    );
}


