import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { Loading, ResetLogin } from './items';
import { styles } from './styles';
import { Button, Checkbox, Fade, FormControlLabel, IconButton, MenuItem, Paper, Radio, RadioGroup, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faCog, faExclamationTriangle, faInfoCircle, faList, faPen, faPlus, faQuestion, faSave, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import './items.css';

export const AppLogout = (props) => {

    const navigate = useNavigate();
    const [isBusy, setBusy] = useState(false);

    useEffect(() => {
        ResetLogin();
        var timer = setTimeout(() => {
            navigate('/');
        }, 1000);

        return () => {            
        };

    }, []);


    return (
        <div style={{ ...styles.Block }}>
            <Loading center />
        </div>
    );
}