import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import './items.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, ConvertToPrice, CopyData, CountrySelect, Debug, DialogEnterNumber, DialogEnterText, DialogImagePreview, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, FormOK, GetDate, GetDate3, GetImageLink, GetMoney, GetPrice, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, TextLine, TextLines, Today } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, CircularProgress, FormControlLabel, Hidden, IconButton, InputAdornment, MenuItem, Pagination, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faA, faAngleDown, faAngleLeft, faAngleRight, faAngleUp, faCheckCircle, faComment, faEdit, faEye, faEyeSlash, faIdCard, faImage, faInfo, faInfoCircle, faL, faLayerGroup, faList, faListAlt, faListCheck, faListOl, faPaperPlane, faPeace, faPen, faPlusCircle, faQuestion, faQuestionCircle, faSave, faShare, faShoppingBag, faShoppingBasket, faShoppingCart, faTrash, faUser, faWarning, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';
import { ProductDialog, ProductPreview, ShoppingCard } from './product_dialog';
import { ClientSelect } from './client_select';
import { centerCrop } from 'react-image-crop';
import { ProductBookingCard } from './products_booking';
import { Photos } from './photos';
import { ProductSelect } from './product_select';
import { SalonCard } from './salon_card';
import { SalonSelect } from './salon_select';

import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { BonusSelect } from './bonus_select';
import QRCode from "react-qr-code";
import { ServiceSelect } from './service_select';
import { ServicePreview } from './service_dialog';

export const NewsAgent = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(1);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);

    // PREDAJ - ZOZNAM
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const itemsMax = 24;
    const [actualYear, setActualYear] = useState(new Date().getFullYear());

    // NOVY PRISPEVOK
    const [showCreate, setShowCreate] = useState(false);
    const [edit, setEdit] = useState(false);

    // NAHLAD PRISPEVKU
    const [showPreview, setShowPreview] = useState(false);

    // EXPORT
    const [showExport, setShowExport] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const menuWidth = props.isSmall ? 0 : 250;
    const rowHeight = props.isSmall ? 120 : 80;
    const rowWidth = 850;
    const selectHeight = props.isSmall ? 40 : 0;

    // COLORS
    const font_selected = global.theme_black;
    const font_disabled = global.theme_darker;

    const no_image = require('./react/app/no_image.jpg');
    const flag_sk = require('./react/app/flag_sk.png');
    const flag_cz = require('./react/app/flag_cz.png');

    // Aktuálna farebna téma
    const color_theme = global.themes[props.theme];

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            let year = new Date().getFullYear();
            db_get(1, year);
        }

    }, []);

    const db_get = async (typ, year) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'news_agent', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    language: props.country.language_id,
                    typ: typ,
                    year: year
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_export = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'news_export', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    news_id: itemSelected.id,
                    agent_id: props.user.id,

                    lang: {
                        do_not_reply: lang.do_not_reply,
                        do_not_reply_text: lang.do_not_reply_text,

                        label: lang.news_item,
                        sub_label: '',
                        created: lang.created,

                        product: lang.product,
                        bonus: lang.bonus,
                        service: lang.service_,
                    }
                })
            })

            const json = await response.json();

            setBusy(false);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const Search = (text) => {
        setSearch(text);
        Redraw(items, 0, text);
    }

    const Redraw = (items, p, search) => {
        // REDRAW PRODUKTOV
        var data = items;

        if (search.trim() != '') {
            // filtrovanie podla hladaneho retazca
            var words = search.split(' ');
            data = data.filter(x => FindWords(x.title, GetDate(x.date), x.author, words));
        }

        var count = data.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax)
        setItemsFiltered(data);

        if (count == 0) {
            setItemsFiltered(false);
        }

        setPage(p);
        GoToStart();
    }

    function FindWords(label, date, salon, words) {
        // hladanie retazca v nazve a keywords        
        var result = 0;
        words.forEach(word => {
            if (label.toLowerCase().includes(word.toLowerCase()) || salon.toLowerCase().includes(word.toLowerCase()) || date.toLowerCase().includes(word.toLowerCase())) {
                result += 1;
            }
        });
        // ak je pocet najdených slov == poctu hladaných slov -> je zhoda
        return words.length == result;
    }

    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);

        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0, '');
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_, search);
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_orders');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }


    const ChangeIndex = (id) => {
        setIndex(id);

        //let year = new Date().getFullYear();
        //setActualYear(year);
        let year = actualYear;

        if (id == 99) {

        } else {
            setSearch('');
            db_get(id, year);
        }
    }

    const ChangeYear = (year, month) => {
        setActualYear(year)
        db_get(index, year);
    }

    const AddNew = () => {
        setEdit(false);
        setShowCreate(true);
    }

    const PostResult = (redraw) => {
        setShowCreate(false);
        if (redraw == true) {
            if (edit == false) {
                setIndex(2);
                db_get(2, actualYear);
            } else {
                db_get(index, actualYear);
            }
        }
    }

    const SelectItem = (item) => {

        if (props.user.shared == true || props.admin == true) {
            setItemSelected(item);
            setEdit(true);
            setShowCreate(true);
        }
    }

    const Preview = (item) => {
        setItemSelected(item);
        setShowPreview(true);
    }

    const Export = (item) => {
        setItemSelected(item);
        setShowExport(true);
    }

    const ExportResult = (value) => {
        setShowExport(false);

        if (value == true) {
            db_export();
        }
    }

    /*    
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faA} />
                        </div>
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? '45%' : '50%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>
                        {props.isSmall ? null :
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        }
                    </div>
                </div>

                <div style={{ ...styles.BlockRow, justifyContent: 'flex-end', alignItems: 'center', width: props.isSmall ? 160 : '30%', height: global.dialog_bar_height }}>
                    <TextField
                        value={search}
                        onInput={e => Search(e.target.value)}
                        size="small"
                        autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off"
                        style={{ width: props.isSmall ? 150 : 300 }}
                        placeholder={lang.search_in_category}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                            }
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                    <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                </IconButton>
                            </InputAdornment>,
                            style: { backgroundColor: global.theme_white, borderRadius: 30 },
                        }}
                        variant="outlined"
                    />
                </div>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 200, height: global.dialog_bar_height, marginLeft: 20 }}>
                        {props.user.shared == true || props.admin == true ?
                            <Button onClick={() => AddNew()} style={{ ...styles.ButtonDark, width: 180 }}>
                                <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPlusCircle} />
                                <p style={{ ...styles.TextTiny, color: global.theme_white }}>
                                    {lang.news_add}
                                </p>
                            </Button>
                            : null}
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                    <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                        {props.isSmall ?
                            <div style={{ ...styles.Block, height: selectHeight }}>
                                <Select
                                    value={index}
                                    onChange={event => ChangeIndex(event.target.value)}
                                    size='small'
                                    style={{ backgroundColor: global.theme_white, width: '100%', height: selectHeight }}
                                    MenuProps={{
                                        style: { zIndex: 10000 }
                                    }}
                                >
                                    <MenuItem value={1}>{lang.news_published}</MenuItem>
                                    <MenuItem value={0}>{lang.news_not_published}</MenuItem>
                                    <MenuItem value={2}>{lang.news_draft}</MenuItem>
                                </Select>
                            </div>
                            :
                            <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                                <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    <Button onClick={() => ChangeIndex(1)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == 1 ? global.theme_back : '#00000000', color: index == 1 ? font_selected : font_disabled }}>
                                        <div style={{ ...styles.BlockLeft }}>
                                            <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{lang.news_published}</p>
                                        </div>
                                    </Button>
                                    {props.user.shared == true || props.admin == true ?
                                        <Button onClick={() => ChangeIndex(0)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == 0 ? global.theme_back : '#00000000', color: index == 0 ? font_selected : font_disabled }}>
                                            <div style={{ ...styles.BlockLeft }}>
                                                <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{lang.news_not_published}</p>
                                            </div>
                                        </Button>
                                        : null}
                                    {props.user.shared == true || props.admin == true ?
                                        <Button onClick={() => ChangeIndex(2)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == 2 ? global.theme_back : '#00000000', color: index == 2 ? font_selected : font_disabled }}>
                                            <div style={{ ...styles.BlockLeft }}>
                                                <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{lang.news_draft}</p>
                                            </div>
                                        </Button>
                                        : null}
                                </div>
                            </div>
                        }
                        <div style={{ ...styles.BlockCenter, width: props.width - menuWidth, height: props.height - global.dialog_bar_height - selectHeight, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>

                            < div id={'id_list'} style={{ ...styles.BlockCenter, width: '98%' }}>
                                <div style={{ ...styles.BlockRight, maxWidth: rowWidth }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <div style={{ ...styles.Block, height: 60 }}>
                                            <p style={{ ...styles.TextTiny }}>{lang.news_list_year}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 200, height: 60 }}>
                                            <SelectYear min={2020} value={actualYear} func={ChangeYear.bind(this)} />
                                        </div>
                                    </div>
                                </div>
                                {/* *********************************************************************************************************  
                                            ZOZNAM PRISPEVKOV
                                    ********************************************************************************************************* */}
                                {props.isSmall ?
                                    <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                                        {props.user.shared == true || props.admin == true ?
                                            <Button onClick={() => AddNew()} style={{ ...styles.ButtonDark, width: 180 }}>
                                                <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPlusCircle} />
                                                <p style={{ ...styles.TextTiny, color: global.theme_white }}>
                                                    {lang.news_add}
                                                </p>
                                            </Button>
                                            : null}
                                    </div>
                                    : null}

                                {itemsFiltered != false ?
                                    itemsFiltered.map((item, index) => (
                                        <Paper elevation={overID == item.id ? 5 : 2} key={item.id} style={{ ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: item.count == 0 ? global.theme_light_red : global.theme_gray, marginTop: 8, marginBottom: 8, borderRadius: global.row_radius, cursor: 'pointer' }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow }}>
                                                {props.isSmall ?
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.Block, height: rowHeight, width: 80, justifyContent: 'center' }}>
                                                        <img src={item.image == false || item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', maxWidth: 60, maxHeight: 60, objectFit: 'contain', borderRadius: 10 }}></img>
                                                    </div>
                                                    :
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.Block, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                        <img src={item.image == false || item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', maxWidth: rowHeight, maxHeight: rowHeight, objectFit: 'contain', borderRadius: global.row_radius }}></img>
                                                    </div>
                                                }
                                                {props.isSmall ?
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: '70%', justifyContent: 'center', marginLeft: 10 }}>
                                                        <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.title}</p>
                                                        <p style={{ ...styles.TextTiny, marginTop: 5 }}>{GetDate(item.date)}</p>
                                                        <p style={{ ...styles.TextTiny, textAlign: 'left', marginRight: 20 }}>{lang.news_author}: {item.author}</p>
                                                    </div>
                                                    :
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: '70%', justifyContent: 'center', marginLeft: 10 }}>
                                                        <div style={{ ...styles.BlockLeft }}>
                                                            <div style={{ ...styles.BlockRowRaw }}>
                                                                <div style={{ ...styles.Block, height: 22, width: 22, backgroundColor: global.actuality_color[item.enabled], borderRadius: '50%' }}>
                                                                    <FontAwesomeIcon style={{ height: 10, color: global.theme_white }} icon={global.actuality_icon[item.enabled]} />
                                                                </div>
                                                                <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left', marginTop: 2, marginLeft: 10 }}>{item.title}</p>
                                                            </div>
                                                        </div>
                                                        <div style={{ ...styles.BlockLeft }}>
                                                            <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                                <div style={{ ...styles.BlockLeft, width: '30%' }}>
                                                                    <p style={{ ...styles.TextTiny }}>{GetDate(item.date)}</p>
                                                                </div>
                                                                <div style={{ ...styles.BlockRight, width: '70%' }}>
                                                                    <p style={{ ...styles.TextTiny, marginRight: 20 }}>{lang.news_author}: {item.author}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div style={{ ...styles.Block, height: rowHeight, width: 60, justifyContent: 'center' }}>
                                                    {item.language == 0 ?
                                                        <img src={flag_sk} style={{ width: '100%', height: '100%', maxWidth: 36, maxHeight: 36, objectFit: 'contain' }}></img>
                                                        : null}
                                                    {item.language == 1 ?
                                                        <img src={flag_cz} style={{ width: '100%', height: '100%', maxWidth: 36, maxHeight: 36, objectFit: 'contain', marginLeft: 5 }}></img>
                                                        : null}
                                                </div>
                                                {props.isSmall == true ? null :
                                                    <div style={{ ...styles.Block, height: rowHeight, width: 60, justifyContent: 'center' }}>
                                                        {props.user.shared == true || props.admin == true ?
                                                            <IconButton onClick={() => Export(item)} style={{ width: 40, height: 40, backgroundColor: global.theme_white }}>
                                                                <FontAwesomeIcon style={{ width: 18, color: global.theme_black }} icon={faPaperPlane} />
                                                            </IconButton>
                                                            : null}
                                                    </div>
                                                }
                                                <div style={{ ...styles.Block, height: rowHeight, width: 60, justifyContent: 'center' }}>
                                                    <IconButton onClick={() => Preview(item)} style={{ width: 40, height: 40, backgroundColor: global.theme_white }}>
                                                        <FontAwesomeIcon style={{ width: 18, color: global.theme_black }} icon={faEye} />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </Paper>
                                    )) :
                                    // ZIADNE OBJEDNAVKY                            
                                    <div style={{ ...styles.Block, paddingTop: 20 }}>
                                        <EmptyList lang={lang} />
                                    </div>
                                }

                                {itemsFiltered != false ?
                                    pageMax > 1 ?
                                        <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                            <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                        </div>
                                        : null
                                    : null}
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {showExport == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.news_export} sub_title={''} text={lang.news_export_question} sub_text={lang.news_export_text + ': ' + props.user.email} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={ExportResult.bind(this)} />
                : null}

            {showCreate == true ?
                <DialogPost edit={edit} item={itemSelected} remoteApp={props.remoteApp} user={props.user} admin={props.admin} countries={props.countries} country={props.country} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} isSmall={props.isSmall} func={PostResult.bind(this)} />
                : null}

            {showPreview == true ?
                <DialogPostRead newsID={itemSelected.id} remoteApp={props.remoteApp} user={props.user} admin={props.admin} countries={props.countries} country={props.country} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} isSmall={props.isSmall} func={() => setShowPreview(false)} />
                : null}

            {showOK == true ?
                <ShowOK side={menuWidth} offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError text={lang.db_error} offset={props.offset} theme={props.theme} func={ShowErrorPress.bind(this)} />
                : null}


            {isBusy == true ?
                <Loading side={menuWidth} offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Paper >
    );
}



export const DialogPost = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <DialogPost remoteApp={props.remoteApp} user={props.user} admin={props.admin} countries={props.countries} country={props.country} lang={props.lang} language={props.language}  offset={props.offset} theme={props.theme}  radius={props.radius} isSmall={props.isSmall} func={PostResult.bind(this)} />

            const PostResult = (typ,value) => {
                console.log(value)
            }
    */
    const no_image = require('./react/app/no_image.jpg');
    const service_1 = require('./react/app/service1.png');
    const service_2 = require('./react/app/service2.png');

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState(false);
    const [index, setIndex] = useState(props.edit == true ? 0 : 0);
    const [redraw, setRedraw] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [items, setitems] = useState(false);
    const [showChooseType, setShowChooseType] = useState(false);
    const [selectedType, setSelectedType] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [editing, setEditing] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [radius, setRadius] = useState(height > 850 ? props.radius : 0);

    const [photo, setPhoto] = useState(props.edit == true ? props.item.image : false);
    const [showSelectPhoto, setShowSelectPhoto] = useState(false);

    // NEWS
    const [newsID, setNewsID] = useState(props.edit == true ? props.item.id : 0);
    const [newsLanguage, setNewsLanguage] = useState(props.edit == true ? props.item.language : props.country.language_id);
    const [newsDate, setNewsDate] = useState(props.edit == true ? GetDate3(props.item.date) : Today());
    const [enabled, setEnabled] = useState(props.edit == true ? props.item.enabled : 2);
    const [priority, setPriority] = useState(0);
    const [showPreview, setShowPreview] = useState(false);

    // TEXTY
    const [labelText, setLabelText] = useState(props.edit == true ? props.item.title : '');
    const [descriptionText, setDescriptionText] = useState(props.edit == true ? props.item.description : '');
    const [author, setAuthor] = useState(props.edit == true ? props.item.author : props.user.name + ' ' + props.user.surname);

    // ZMENA INDEXU
    const [showIndex, setShowIndex] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);

    const dialogWidth = width >= 1200 ? 1200 : width;
    const dialogHeight = height >= 850 ? 850 : height;

    const imageSize = 200;
    const imageRadius = 10;
    const titleHeight = 60;
    const buttonHeight = 80;
    const rowHeight = 80;
    const rowWidth = 850;

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {

        return () => {
        };

    }, []);

    const db_news_update = async (data, redraw, close) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'news_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setRedraw(true);
                if (redraw == true) {
                    var id = newsID;
                    if (data.id == 0) {
                        setNewsID(json.ok);
                        id = json.ok;
                    }
                    setIndex(1);
                    db_news_details(id);
                }

                if (close == true) {
                    func(true);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_news_details = async (id) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'news_details', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    news_id: id,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;
                if (tmp != false) {
                    tmp.sort((a, b) => {
                        if (parseInt(a.priority) > parseInt(b.priority)) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })

                    tmp = tmp.map(function (item, index) { item.index = index; return item; });
                }
                setitems(tmp);

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_news_details_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'news_details_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                db_news_details(newsID);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_news_details_reorder = async (data1, data2, tmp) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'news_details_reorder', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data1: data1,
                    data2: data2,
                })
            })

            const json = await response.json();
            if (json.ok > 0) {

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = () => {
        func(redraw);
    }

    const Save = () => {
        if (index == 0) {

            // Ulozenie hlavicky prispevku
            if (labelText.trim() != '') {
                let data = {
                    id: newsID,
                    date: newsDate,
                    enabled: enabled,
                    language: newsLanguage,
                    agent_id: props.user.id,
                    title: labelText,
                    description: descriptionText,
                    image: photo,
                    author: author,
                    settings: false
                }


                db_news_update(data, true, false);
            }

        } else {
            // ULOZENIE PRISPEVKU + obsahu

        }
    }

    const Publish = () => {
        // PUBLIKOVANIE
        let data = {
            id: newsID,
            enabled: 1
        }

        db_news_update(data, false, true);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const PostResult = (id) => {

    }

    const PhotosResult = (typ, path, file) => {
        setShowSelectPhoto(false);

        if (typ == true) {
            setPhoto(path + file);
        }
    }

    const SelectItem = (item) => {

        setSelectedType(item);
        setEditing(true);
        setPriority(item.priority);
        setShowEdit(true);
    }

    const NewsChooseResult = (typ, value) => {
        setShowChooseType(false);

        if (typ == true) {
            setSelectedType(value);
            setEditing(false);
            if (items == false) {
                setPriority(0);
            } else {
                var p = 0;
                items.forEach(item => {
                    if (p < parseInt(item.priority)) {
                        p = parseInt(item.priority);
                    }
                });
                p++;
                setPriority(p);

            }

            setTimeout(() => {
                setShowEdit(true);
            }, 300);
        }

    }

    const Preview = () => {
        setShowPreview(true);
    }

    const ItemUp = (item) => {
        var item1 = items.find(x => parseInt(x.index) == parseInt(item.index) - 1);
        var item2 = items.find(x => parseInt(x.index) == parseInt(item.index));

        let priority = item1.priority;
        item1.priority = item2.priority;
        item2.priority = priority;

        var tmp = items;
        tmp.sort((a, b) => {
            if (parseInt(a.priority) > parseInt(b.priority)) {
                return 1;
            } else {
                return -1;
            }
        })

        tmp = items.map(function (item, index) { item.index = index; return item; });
        setitems(tmp);

        let data1 = {
            id: item1.id,
            priority: item1.priority
        }
        let data2 = {
            id: item2.id,
            priority: item2.priority
        }

        db_news_details_reorder(data1, data2, tmp);
    }

    const ItemDown = (item) => {
        var item1 = items.find(x => parseInt(x.index) == parseInt(item.index));
        var item2 = items.find(x => parseInt(x.index) == parseInt(item.index) + 1);

        let priority = item1.priority;
        item1.priority = item2.priority;
        item2.priority = priority;

        var tmp = items;
        tmp.sort((a, b) => {
            if (parseInt(a.priority) > parseInt(b.priority)) {
                return 1;
            } else {
                return -1;
            }
        })

        tmp = items.map(function (item, index) { item.index = index; return item; });
        setitems(tmp);

        let data1 = {
            id: item1.id,
            priority: item1.priority
        }
        let data2 = {
            id: item2.id,
            priority: item2.priority
        }

        db_news_details_reorder(data1, data2, tmp);
    }

    const PostTypeEditResult = (typ, data) => {
        setShowEdit(false);
        if (typ == true) {
            db_news_details_update(data);
        }
    }

    const ChangeEnabled = (value) => {
        setEnabled(value);
        let data = {
            id: newsID,
            enabled: value
        }

        db_news_update(data, false, false);
    }

    const ChangeItemIndex = (item) => {
        setItemSelected(item);
        setShowIndex(true);
    }


    const EnterNumberResult = (typ, value) => {
        setShowIndex(false);

        if (typ == true) {
            let data = {
                id: itemSelected.id,
                priority: value
            }

            db_news_details_update(data);
        }

    }

    const DialogPostReadResult = () => {
        setShowPreview(false);
    }

    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: radius, borderTopRightRadius: radius, background: global.themes[props.theme].window_bar_color }}>
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                            <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                                <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faComment} />
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.Block, width: props.isSmall ? '70%' : '50%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                        <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.edit == true ? lang.news_edit_title : lang.news_add_title}</p>
                            <p style={{ ...styles.TextDialogSubLabel, textAlign: 'left', marginTop: 4 }}>{lang.news_author}: {author}</p>
                        </div>
                    </div>
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, alignItems: 'flex-end', width: 190, height: global.dialog_bar_height }}>
                            {index == 1 ?
                                <Button onClick={() => Preview()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faEye} />
                                    {lang.preview}
                                </Button>
                                : null}
                        </div>
                    }
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: 220, height: global.dialog_bar_height, marginLeft: 20 }}>
                            {newsID > 0 ?
                                <Select
                                    value={enabled}
                                    onChange={event => ChangeEnabled(event.target.value)}
                                    size='small'
                                    style={{ backgroundColor: global.theme_white, height: 34, width: '100%', borderRadius: 17 }}
                                >
                                    <MenuItem value={2}>{lang.news_status[2]}</MenuItem>
                                    <MenuItem value={0}>{lang.news_status[0]}</MenuItem>
                                    <MenuItem value={1}>{lang.news_status[1]}</MenuItem>

                                </Select>
                                : null}
                        </div>
                    }
                    {props.isSmall ?
                        <div style={{ ...styles.Block, alignItems: 'flex-end', width: 70, height: global.dialog_bar_height }}>
                            {index == 1 ?
                                <IconButton onClick={() => Preview()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faEye} />
                                </IconButton>
                                : null}
                        </div>
                        : null}
                    <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    { // ZALOZENIE NOVEHO PRISPEVKU
                        index == 0 ?
                            <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRow }}>
                                    {props.isSmall && newsID > 0 ?
                                        <div style={{ ...styles.Block, height: global.dialog_bar_height, backgroundColor: global.theme_white }}>
                                            <Select
                                                value={enabled}
                                                onChange={event => ChangeEnabled(event.target.value)}
                                                size='small'
                                                style={{ backgroundColor: global.theme_white, height: 34, width: 250, borderRadius: 17 }}
                                            >
                                                <MenuItem value={2}>{lang.news_status[2]}</MenuItem>
                                                <MenuItem value={0}>{lang.news_status[0]}</MenuItem>
                                                <MenuItem value={1}>{lang.news_status[1]}</MenuItem>

                                            </Select>
                                        </div>
                                        : null}
                                    <div style={{ ...styles.BlockCenter, height: props.isSmall ? undefined : dialogHeight - global.dialog_bar_height - buttonHeight, width: props.isSmall ? '100%' : dialogWidth - 340, backgroundColor: global.theme_white, borderBottomRightRadius: props.isSmall ? 0 : 30, paddingTop: props.isSmall ? 20 : 0, paddingBottom: props.isSmall ? 40 : 0 }}>
                                        <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRow }}>
                                            <div style={{ ...styles.Block, width: props.isSmall ? '100%' : '35%' }}>
                                                <p style={{ ...styles.TextSmall, marginTop: 10, marginBottom: 5, fontWeight: '400' }}>{lang.photo}</p>
                                                <Paper elevation={3} style={{ maxWidth: imageSize, maxHeight: imageSize, borderRadius: imageRadius }}>
                                                    <img src={photo == false || photo == null || photo == '' ? no_image : GetImageLink(photo)} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain', borderRadius: imageRadius }}></img>
                                                </Paper>
                                                <div style={{ ...styles.Block, marginTop: 20 }}>
                                                    {photo != false ?
                                                        <Button onClick={() => setPhoto(false)} style={{ ...styles.ButtonRed, height: 30, width: 200, marginBottom: 20 }}>
                                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faXmark} />
                                                            {lang.photo_delete}
                                                        </Button>
                                                        : null}
                                                    <Button onClick={() => setShowSelectPhoto(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, height: 30, width: 200 }}>{photo == false ? lang.photo_choose : lang.photo_change}</Button>
                                                </div>
                                            </div>
                                            <div style={{ ...styles.Block, width: props.isSmall ? '100%' : '65%', marginTop: props.isSmall ? 40 : 0 }}>

                                                <div style={{ ...props.isSmall ? styles.Block : styles.BlockLeft, width: '96%' }}>
                                                    <p style={{ ...styles.TextSmall, marginBottom: 15, marginTop: 5, fontWeight: '600' }}>{lang.news_icon_create}</p>
                                                </div>


                                                <div style={{ ...props.isSmall ? styles.Block : styles.BlockLeft, width: '96%' }}>
                                                    <p style={{ ...styles.TextTiny, marginBottom: 5 }}>{lang.news_country}</p>
                                                    <CountrySelect radius width={170} countries={props.countries} background={global.theme_lighter} value={{ id: parseInt(newsLanguage) + 1 }} func={(item) => setNewsLanguage(item.language_id)} />
                                                </div>

                                                <div style={{ ...styles.BlockRow, width: '96%', marginTop: 30 }}>
                                                    <div style={{ ...styles.Block, width: 180 }}>
                                                        <TextInput type={'date'} redraw enabled={true} lang={lang} value={newsDate} label={lang.date} func={(txt) => { setNewsDate(txt) }} />
                                                    </div>
                                                    <div style={{ ...styles.Block, width: '70%', marginLeft: 10 }}>
                                                        <TextInput required redraw enabled={true} lang={lang} value={labelText} label={lang.news_title} func={(txt) => setLabelText(txt)} />
                                                    </div>
                                                </div>

                                                <div style={{ ...styles.Block, width: '96%', marginTop: 30 }}>
                                                    <TextInput redraw rows={5} enabled={true} lang={lang} value={descriptionText} label={lang.news_description} func={(txt) => setDescriptionText(txt)} />
                                                </div>
                                                <div style={{ ...styles.Block, width: '96%', marginTop: 30 }}>
                                                    <TextInput redraw enabled={true} lang={lang} value={author} label={lang.news_author} func={(txt) => setAuthor(txt)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ ...styles.Block, width: props.isSmall ? '100%' : 340, paddingTop: props.isSmall ? 40 : 0, paddingBottom: props.isSmall ? 40 : 0 }}>
                                        <p style={{ ...styles.TextSmall, marginBottom: 5, marginTop: 5 }}>{lang.news_icon_preview}</p>
                                        <PostIcon theme={props.theme} id={0} elevation={3} date={newsDate} image={photo} title={labelText} description={descriptionText} func={PostResult.bind(this)} />
                                    </div>
                                </div>

                            </div>
                            : null}

                    { // UPRAVA PRISPEVKU / DALSI KROK PO ZALOZENI
                        index == 1 ?
                            <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, backgroundColor: global.theme_white }}>
                                <div style={{ ...styles.BlockRow, height: titleHeight }}>
                                    <div style={{ ...styles.Block, width: 100, height: titleHeight }}></div>
                                    <div style={{ ...styles.Block, width: '85%', height: titleHeight }}>
                                        <p style={{ ...styles.TextSmall, marginBottom: 5, marginTop: 5, fontWeight: '600' }}>{lang.news_content_create}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: 100, height: titleHeight }}>
                                        <IconButton onClick={() => setShowChooseType(true)} style={{ width: 40, height: 40, backgroundColor: global.theme_white }}>
                                            <FontAwesomeIcon style={{ height: 40, color: global.theme_dark_red }} icon={faPlusCircle} />
                                        </IconButton>
                                    </div>
                                </div>

                                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight - titleHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    {items != false ?
                                        items.map((item, index) => (
                                            <Paper elevation={2} key={item.id} style={{ ...styles.Block, width: '98%', height: rowHeight, backgroundColor: item.enabled == false ? global.theme_medium_gray : global.theme_gray, marginTop: 8, marginBottom: 8, borderRadius: global.row_radius, cursor: 'pointer' }}>
                                                <div style={{ ...styles.BlockRow }}>
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.Block, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                        {item.enabled == true ?
                                                            <img src={global.news_icons_images[item.typ]} style={{ width: '100%', height: '100%', maxWidth: rowHeight - 20, maxHeight: rowHeight - 20, borderRadius: 5 }}></img>
                                                            :
                                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faEyeSlash} />
                                                        }
                                                    </div>
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockCenter, height: rowHeight, width: props.isSmall ? '60%' : '80%', justifyContent: 'flex-start', alignItems: 'flex-start', overflow: 'hidden' }}>
                                                        {item.typ == 0 ?
                                                            // HTML TEXT
                                                            <div style={{ ...styles.TextTiny, textAlign: 'left', width: '95%' }} dangerouslySetInnerHTML={{ __html: item.text }} />
                                                            : null}

                                                        {item.typ == 1 ?
                                                            // IMAGE
                                                            <div style={{ ...styles.BlockRow }}>
                                                                <div style={{ ...styles.Block, width: rowHeight + 10, height: rowHeight }}>
                                                                    <img src={item.image == false ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', maxWidth: rowHeight - 10, maxHeight: rowHeight - 10, objectFit: 'contain' }}></img>
                                                                </div>
                                                                <div style={{ ...styles.BlockLeft, width: props.isSmall ? '60%' : '80%', justifyContent: 'center', height: rowHeight }}>
                                                                    <p style={{ ...styles.TextTiny }}>{lang.image}</p>
                                                                    {props.isSmall ? null :
                                                                        <p style={{ ...styles.TextTiny }}>{item.text}</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                            : null}

                                                        {item.typ == 2 ?
                                                            // PRODUKT
                                                            <div style={{ ...styles.BlockRow }}>
                                                                <div style={{ ...styles.Block, width: rowHeight + 10, height: rowHeight }}>

                                                                    {item.product != false ?
                                                                        <img src={item.product.image == '' ? no_image : GetImageLink(item.product.image)} style={{ width: '100%', height: '100%', maxWidth: rowHeight - 10, maxHeight: rowHeight - 10, objectFit: 'contain' }}></img>
                                                                        : null}
                                                                </div>
                                                                <div style={{ ...styles.BlockLeft, width: props.isSmall ? '60%' : '80%', justifyContent: 'center', height: rowHeight }}>
                                                                    <p style={{ ...styles.TextTiny }}>{lang.product}</p>
                                                                    {item.product != false ?
                                                                        props.isSmall ? null :
                                                                            <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{item.product.label}</p>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                            : null}

                                                        {item.typ == 3 ?
                                                            // ODDELOVAC
                                                            <div style={{ ...styles.Block, height: rowHeight }}>
                                                                <div style={{ ...styles.Block, width: props.isSmall ? '60%' : '90%', height: 2, backgroundColor: global.theme_light_gray }}></div>
                                                            </div>
                                                            : null}


                                                        {item.typ == 4 ?
                                                            // BONUS
                                                            <div style={{ ...styles.BlockRow }}>
                                                                <div style={{ ...styles.Block, width: rowHeight + 10, height: rowHeight }}>

                                                                    {item.bonus != false ?
                                                                        <img src={item.bonus.image == '' ? no_image : GetImageLink(item.bonus.image)} style={{ width: '100%', height: '100%', maxWidth: rowHeight - 10, maxHeight: rowHeight - 10, objectFit: 'contain' }}></img>
                                                                        : null}
                                                                </div>
                                                                <div style={{ ...styles.BlockLeft, width: props.isSmall ? '60%' : '80%', justifyContent: 'center', height: rowHeight }}>
                                                                    <p style={{ ...styles.TextTiny }}>{lang.bonus}</p>
                                                                    {item.bonus != false ?
                                                                        props.isSmall ? null :
                                                                            <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{item.bonus.label}</p>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                            : null}

                                                        {item.typ == 5 ?
                                                            // QRCODE
                                                            <div style={{ ...styles.Block, height: rowHeight, alignItems: 'flex-start' }}>
                                                                <p style={{ ...styles.TextTiny }}>{item.image}</p>
                                                                {props.isSmall ? null :
                                                                    <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{item.text}</p>
                                                                }
                                                            </div>
                                                            : null}

                                                        {item.typ == 6 ?
                                                            // SLUZBA
                                                            <div style={{ ...styles.BlockRow }}>
                                                                <div style={{ ...styles.Block, width: rowHeight + 10, height: rowHeight }}>

                                                                    {item.service != false ?
                                                                        <img src={service_1} style={{ width: '100%', height: '100%', maxWidth: rowHeight - 10, maxHeight: rowHeight - 10, objectFit: 'contain' }}></img>
                                                                        : null}
                                                                </div>
                                                                <div style={{ ...styles.BlockLeft, width: props.isSmall ? '60%' : '80%', justifyContent: 'center', height: rowHeight }}>
                                                                    <p style={{ ...styles.TextTiny }}>{lang.service_}</p>
                                                                    {item.service != false ?
                                                                        props.isSmall ? null :
                                                                            <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{item.service.label}</p>
                                                                        : null}
                                                                </div>
                                                            </div>
                                                            : null}

                                                    </div>
                                                    <div style={{ ...styles.Block, height: rowHeight, width: 50, justifyContent: 'center' }}>
                                                        {index == 0 ? null :
                                                            <IconButton onClick={() => ItemUp(item)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                                                                <FontAwesomeIcon style={{ width: 10, color: global.theme_dark_blue }} icon={faAngleUp} />
                                                            </IconButton>
                                                        }
                                                    </div>
                                                    <div onDoubleClick={() => ChangeItemIndex(item)} style={{ ...styles.Block, height: rowHeight, width: 30, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextXTiny, color: global.theme_medium_gray }}>{item.priority}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, height: rowHeight, width: 50, justifyContent: 'center' }}>
                                                        {index == items.length - 1 ? null :
                                                            <IconButton onClick={() => ItemDown(item)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                                                                <FontAwesomeIcon style={{ width: 10, color: global.theme_dark_blue }} icon={faAngleDown} />
                                                            </IconButton>
                                                        }
                                                    </div>
                                                </div>
                                            </Paper>
                                        )) :
                                        // ZIADNE POLOZKY                            
                                        <div style={{ ...styles.Block, paddingTop: 20 }}>
                                            <EmptyList lang={lang} />
                                        </div>
                                    }
                                </div>

                            </div>
                            : null}

                </div>
                <div style={{ ...styles.Block, height: buttonHeight }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        {index == 1 ?
                            <Button onClick={() => setIndex(0)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: props.isSmall ? 160 : 180 }}>
                                <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faAngleLeft} />
                                {lang.back}
                            </Button>
                            : null}
                        {index == 0 ?
                            <Button onClick={() => Save()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: index == 0 ? 220 : 180 }}>
                                {lang.news_content_make}
                                <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginLeft: 10 }} icon={faAngleRight} />
                            </Button>
                            : null}
                        {index == 1 ?
                            <Button onClick={() => Publish()} style={{ ...styles.ButtonRed, width: props.isSmall ? 160 : 180, marginLeft: 25 }}>
                                {lang.news_save_publish}
                                <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginLeft: 10 }} icon={faPaperPlane} />
                            </Button>
                            : null}
                        {index == 1 ?
                            props.isSmall ? null :
                                <Button onClick={() => Press()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 180, marginLeft: 25 }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faXmark} />
                                    {lang.close}
                                </Button>
                            : null}
                    </div>

                </div>
            </Paper>

            {showSelectPhoto == true ?
                <Photos lang={lang} typ={7} max_size={600} width={600} height={600} full={false} title={lang.photo_select} sub_title={lang.vision_system} radius={props.radius} offset={props.offset} theme={props.theme} func={PhotosResult.bind(this)} />
                : null}

            {showChooseType == true ?
                <NewsChooseType isSmall={props.isSmall} theme={props.theme} lang={props.lang} radius={props.radius} func={NewsChooseResult.bind(this)} />
                : null}

            {showIndex == true ?
                <DialogEnterNumber theme={props.theme} radius={props.radius} title={lang.priority_enter} sub_title={lang.news} value={itemSelected.priority} min={0} max={1000} icon={faListOl} button={props.lang.save} func={EnterNumberResult.bind(this)} />
                : null}

            {showEdit == true ?
                <PostTypeEdit isSmall={props.isSmall} country={props.country} typ={selectedType.typ} item={selectedType} newsID={newsID} text={editing == true ? selectedType.text : ''} priority={priority} edit={editing} lang={props.lang} radius={props.radius} offset={props.offset} theme={props.theme} user={props.user} func={PostTypeEditResult.bind(this)} />
                : null}

            {showPreview == true ?
                <DialogPostRead newsID={newsID} remoteApp={props.remoteApp} user={props.user} admin={props.admin} countries={props.countries} country={props.country} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} isSmall={props.isSmall} func={DialogPostReadResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop>
    );
}



export const PostIcon = (props) => {
    /*
            Ikona jedneho postu aktualit
            
            USAGE:
            <PostIcon id={id} elevation={0} image={''} title={''} description={''} date={} func={PostResult.bind(this)} />

            const PostResult = (id) => {
                console.log(id)
            }
    */
    const no_image = require('./react/app/no_image.jpg');

    const width = 180;
    const height = 316;
    const imageSize = width - 20;
    const imageRadius = 10;

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {

        return () => {
        };

    }, [props.date, props.image, props.title, props.description]);

    const Press = () => {
        func(props.id);
    }

    return (
        <Paper elevation={props.elevation} onClick={() => Press()} style={{ ...styles.BlockCenter, width: width, height: height, backgroundColor: '#FFFFFF70', borderRadius: imageRadius + 5, overflow: 'hidden' }}>
            {props.image == false || props.image == null || props.image == '' ?
                <div style={{ ...styles.Block, width: width - 10, height: height - 10 }}>
                    <p style={{ ...styles.TextXTiny, color: global.themes[props.theme].theme_medium }}>{GetDate(props.date)}</p>
                    {/*
                    <p style={{ ...styles.TextSmall, fontWeight: '600', marginTop: 12 }}>{props.title}</p>
                    */}
                    <TextLines rows={3} text={props.title} fontSize={global.font_small} fontWeight={'600'} />
                    <div style={{ ...styles.BlockCenter, marginTop: 15, overflow: 'hidden' }}>
                        <TextLines rows={3} text={props.description.replaceAll('\n', ' ')} fontSize={global.font_small} fontWeight={'400'} marginLeft={0} />
                    </div>
                </div>
                :
                <div style={{ ...styles.Block }}>
                    <div style={{ ...styles.Block, width: width, height: width }}>
                        <img src={props.image == false || props.image == null || props.image == '' ? no_image : GetImageLink(props.image)} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain', borderRadius: imageRadius }}></img>
                    </div>
                    <div style={{ ...styles.Block, width: '94%' }}>
                        <p style={{ ...styles.TextXTiny, color: global.themes[props.theme].theme_medium }}>{GetDate(props.date)}</p>
                        <div style={{ ...styles.BlockCenter, height: height - width - 5 }}>
                            {/*
                            <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{props.title}</p>
                            */}
                            <TextLine text={props.title} fontSize={global.font_small} fontWeight={'600'} />
                            <div style={{ ...styles.BlockCenter, marginTop: 5,/* height: (global.font_small + 4) * 3,*/ overflow: 'hidden' }}>
                                {/*
                                <p style={{ ...styles.TextSmall, lineHeight: 1.1 }}>{props.description}</p>
                                */}
                                <TextLines rows={3} text={props.description.replaceAll('\n', ' ')} fontSize={global.font_small} fontWeight={'400'} marginLeft={0} />
                            </div>
                        </div>
                    </div>
                </div>
            }

        </Paper>
    );
}



export const NewsChooseType = (props) => {
    /*
            
            USAGE:
            <NewsChooseType lang={lang} radius={props.radius} func={NewsChooseResult.bind(this)} />

            const NewsChooseResult = (typ,value) => {
                console.log(value)
            }
    */

    const [selectedID, setSelectedID] = useState(-1);
    const [selectedItem, setSelectedItem] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = props.isSmall ? width : 600;
    const dialogHeight = props.isSmall ? height : 600;
    const rowOffset = 20;
    const iconSize = 128;
    const buttonHeight = 80;

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {

        return () => {
        };

    }, []);

    const Press = (typ) => {
        func(typ, selectedItem);
    }

    const SelectItem = (item) => {
        setSelectedID(item.id);
        setSelectedItem(item);
    }

    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.news_choose_typ}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.news}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: global.theme_white, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, width: '95%', height: dialogHeight - global.dialog_bar_height - buttonHeight }}>
                        {/* BODY */}
                        <div style={{ ...styles.Block, flexDirection: 'row', flexWrap: 'wrap' }}>
                            {global.news_icons.map((item, index) => (
                                item.enabled == true ?
                                    <div key={item.id} onClick={() => SelectItem(item)} style={{ ...styles.Block, width: iconSize, height: iconSize + 20, cursor: 'pointer', backgroundColor: selectedID == item.id ? global.theme_light_blue : global.theme_white, borderRadius: 10, marginTop: 20 }}>
                                        <img src={item.image} style={{ width: '100%', height: '100%', maxWidth: iconSize - 20, maxHeight: iconSize - 20, borderRadius: 5 }}></img>
                                        <p style={{ ...styles.TextTiny, marginTop: 8, color: selectedID == item.id ? global.theme_white : global.theme_black }}>{lang.news_type_label[item.id]}</p>
                                    </div>
                                    : null
                            ))}
                        </div>
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        {selectedID > -1 ?
                            <Button onClick={() => Press(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>{lang.select}</Button>
                            : null}
                    </div>
                </div>
            </Paper>
        </Backdrop>
    );
}



export const PostTypeEdit = (props) => {
    /*
            Editácia obsahu - jedna polozka
            
            USAGE:
            <PostTypeEdit typ={id} lang={props.lang} radius={props.radius}  offset={props.offset} theme={props.theme}  func={PostTypeEditResult.bind(this)} />

            const PostTypeEditResult = (typ,item) => {
                
            }
    */

    const no_image = require('./react/app/no_image.jpg');
    const service_1 = require('./react/app/service1.png');
    const service_2 = require('./react/app/service2.png');

    const blocksFromHTML = convertFromHTML(props.text);
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap,);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(state));
    const [contentState, setContentState] = useState();

    const [isBusy, setBusy] = useState(false);
    const [typ, setTyp] = useState(props.typ);
    const [id, setID] = useState(props.edit == true ? props.item.id : 0);
    const [enabled, setEnabled] = useState(props.edit == true ? props.item.enabled == false ? false : true : true);
    const [text, setText] = useState(props.edit == true ? props.item.text : '');
    const [settings, setSettings] = useState(props.edit == true ? JSON.parse(props.item.settings) : false);

    // IMAGE / PHOTO
    const [photo, setPhoto] = useState(props.edit == true ? props.item.image : false);
    const [showPhoto, setShowPhoto] = useState(false);
    const [showSelectPhoto, setShowSelectPhoto] = useState(false);

    // PRODUKT
    const [showSelectProduct, setShowSelectProduct] = useState(false);
    const [product, setProduct] = useState(props.edit == true ? props.item.typ == 2 ? props.item.product : false : false);

    // BONUS
    const [showSelectBonus, setShowSelectBonus] = useState(false);
    const [bonus, setBonus] = useState(props.edit == true ? props.item.typ == 4 ? props.item.bonus : false : false);

    // SLUZBA
    const [showSelectService, setShowSelectService] = useState(false);
    const [service, setService] = useState(props.edit == true ? props.item.typ == 6 ? props.item.service : false : false);


    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1200 ? 1200 : width;
    const dialogHeight = height >= 850 ? 850 : height;
    const imageSize = props.isSmall ? 250 : 300;
    const qrSize = 200;
    const imageRadius = 10;

    const buttonHeight = 80;

    let { func } = props;
    var lang = props.lang;
    const editorRef = useRef(null);

    useEffect(() => {

        return () => {
        };

    }, []);

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(false, 0);
    }

    const PhotosResult = (typ, path, file) => {
        setShowSelectPhoto(false);

        if (typ == true) {
            setPhoto(path + file);
        }
    }

    const Process = () => {
        if (typ == 0) {
            let html = convertToHTML(editorState.getCurrentContent());
            let data = {
                // HTML TEXT
                id: id,
                enabled: enabled,
                news_id: props.newsID,
                typ: typ,
                priority: props.priority,
                image: '',
                text: html,
                settings: settings
            }

            Press(true, data);
        }

        if (typ == 1) {
            // OBRAZOK   
            if (photo != false) {
                let data = {
                    id: id,
                    enabled: enabled,
                    news_id: props.newsID,
                    typ: typ,
                    priority: props.priority,
                    image: photo == false ? '' : photo,
                    text: text,
                    settings: settings
                }

                Press(true, data);
            }
        }

        if (typ == 2) {
            // PRODUKT
            if (product != false) {
                let data = {
                    id: id,
                    enabled: enabled,
                    news_id: props.newsID,
                    typ: typ,
                    priority: props.priority,
                    image: product.id,
                    text: text,
                    settings: settings
                }

                Press(true, data);
            }
        }

        if (typ == 3) {
            // ODDELOVAC OBSAHU
            let data = {
                id: id,
                enabled: enabled,
                news_id: props.newsID,
                typ: typ,
                priority: props.priority,
                image: '',
                text: '',
                settings: settings
            }

            Press(true, data);
        }

        if (typ == 4) {
            // BONUS
            if (bonus != false) {
                let data = {
                    id: id,
                    enabled: enabled,
                    news_id: props.newsID,
                    typ: typ,
                    priority: props.priority,
                    image: bonus.id,
                    text: text,
                    settings: settings
                }

                Press(true, data);
            }
        }

        if (typ == 5) {
            // QRCODE
            if (photo != '' && photo != false) {
                let data = {
                    id: id,
                    enabled: enabled,
                    news_id: props.newsID,
                    typ: typ,
                    priority: props.priority,
                    image: photo,
                    text: text,
                    settings: settings
                }

                Press(true, data);
            }
        }

        if (typ == 6) {
            // SLUZBA
            if (service != false) {
                let data = {
                    id: id,
                    enabled: enabled,
                    news_id: props.newsID,
                    typ: typ,
                    priority: props.priority,
                    image: service.id,
                    text: text,
                    settings: settings
                }

                Press(true, data);
            }
        }

    }

    const ProductSelectResult = (typ, item) => {
        setShowSelectProduct(false);

        if (typ == true) {
            setProduct(item);
        }
    }

    const BonusSelectResult = (typ, item) => {
        setShowSelectBonus(false);

        if (typ == true) {
            setBonus(item);
        }
    }

    const ServiceSelectResult = (typ, item) => {
        setShowSelectService(false);

        if (typ == true) {
            setService(item);
        }
    }


    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '75%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.news_type_label[props.typ]}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.news_content_make}</p>
                        </div>
                    </div>
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: 220, height: global.dialog_bar_height }}>
                            {props.edit == true ?
                                <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.news_item_enabled}</p>}
                                    control={<Checkbox checked={enabled} size={'small'} onChange={() => setEnabled(!enabled)} />}
                                />
                                : null}
                        </div>
                    }
                    <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, backgroundColor: global.theme_white, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                        {/* BODY */}
                        {props.typ == 0 ?
                            // HTML TEXT
                            <div style={{ ...styles.Block, backgroundColor: global.theme_white, height: dialogHeight - global.dialog_bar_height - buttonHeight }}>
                                <Editor
                                    ref={editorRef}
                                    editorState={editorState}
                                    onEditorStateChange={setEditorState}
                                    defaultContentState={contentState}
                                    onContentStateChange={setContentState}

                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"

                                    editorStyle={{ backgroundColor: global.theme_white, height: props.isSmall ? dialogHeight - global.dialog_bar_height - buttonHeight - 100 : dialogHeight - global.dialog_bar_height - buttonHeight - 55, border: '1px solid ' + global.theme_light_gray }}
                                    toolbarStyle={{ backgroundColor: global.theme_gray, width: dialogWidth - 20 }}

                                    toolbar={{
                                        options: ['inline', 'blockType', 'list', 'history'],
                                        inline: { inDropdown: false },
                                        list: { inDropdown: false },
                                        textAlign: { inDropdown: false },
                                        history: { inDropdown: false },
                                        inline: {
                                            inDropdown: false,
                                            options: ['bold', 'italic', 'underline'],
                                        },
                                        blockType: {
                                            inDropdown: false,
                                            options: ['Normal', 'H1', 'H2', 'H3'],
                                        },
                                        list: {
                                            inDropdown: false,
                                            options: ['unordered', 'ordered'],
                                        },
                                    }}
                                />
                            </div>
                            : null}

                        {props.typ == 1 ?
                            // OBRAZOK
                            <div style={{ ...styles.BlockCenter, marginBottom: props.isSmall ? 20 : 400 }}>
                                <p style={{ ...styles.TextSmall, marginTop: 10, marginBottom: 5, fontWeight: '400' }}>{lang.image}</p>
                                <Paper elevation={3} style={{ maxWidth: imageSize, maxHeight: imageSize, borderRadius: imageRadius }}>
                                    <img src={photo == false ? no_image : GetImageLink(photo)} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain', borderRadius: imageRadius }}></img>
                                </Paper>
                                <div style={{ ...styles.Block, width: imageSize, marginTop: 20 }}>
                                    <TextInput redraw enabled={true} lang={lang} value={text} label={lang.image_note} func={(txt) => setText(txt)} />
                                </div>
                                <div style={{ ...styles.Block, marginTop: 40 }}>
                                    {photo != false ?
                                        <Button onClick={() => setPhoto(false)} style={{ ...styles.ButtonRed, height: 30, width: 200, marginBottom: 20 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faXmark} />
                                            {lang.photo_delete}
                                        </Button>
                                        : null}
                                    <Button onClick={() => setShowSelectPhoto(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, height: 30, width: 200 }}>{photo == false ? lang.image_choose : lang.image_change}</Button>
                                </div>
                            </div>
                            : null}

                        {props.typ == 2 ?
                            // PRODUKT
                            <div style={{ ...styles.BlockCenter, marginBottom: props.isSmall ? 20 : 400 }}>
                                <p style={{ ...styles.TextSmall, marginTop: 10, marginBottom: 5, fontWeight: '400' }}>{lang.product}</p>
                                {product != false ?
                                    <p style={{ ...styles.TextSmall, marginBottom: 5, fontWeight: '600', marginTop: 20, marginBottom: 10 }}>{product.label}</p>
                                    : null}
                                <Paper elevation={3} style={{ maxWidth: imageSize, maxHeight: imageSize, borderRadius: imageRadius }}>
                                    {product != false ?
                                        <img src={product.image == '' || product.image == false || product.image == null ? no_image : GetImageLink(product.image)} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, borderRadius: imageRadius }}></img>
                                        :
                                        <img src={no_image} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, borderRadius: imageRadius }}></img>
                                    }
                                </Paper>
                                {product != false ?
                                    <div style={{ ...styles.Block, width: imageSize, marginTop: 20 }}>
                                        <TextInput redraw enabled={true} lang={lang} value={text} label={lang.image_note} func={(txt) => setText(txt)} />
                                    </div>
                                    : null}

                                <div style={{ ...styles.Block, marginTop: 40 }}>
                                    {product != false ?
                                        <Button onClick={() => setProduct(false)} style={{ ...styles.ButtonRed, height: 30, width: 200, marginBottom: 20 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faXmark} />
                                            {lang.delete}
                                        </Button>
                                        : null}
                                    <Button onClick={() => setShowSelectProduct(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, height: 30, width: 200 }}>{product == false ? lang.product_select : lang.product_change}</Button>
                                </div>
                            </div>
                            : null}

                        {props.typ == 4 ?
                            // BONUS
                            <div style={{ ...styles.BlockCenter, marginBottom: props.isSmall ? 20 : 400 }}>
                                <p style={{ ...styles.TextSmall, marginTop: 10, marginBottom: 5, fontWeight: '400' }}>{lang.bonus}</p>
                                {bonus != false ?
                                    <p style={{ ...styles.TextSmall, marginBottom: 5, fontWeight: '600', marginTop: 20, marginBottom: 10 }}>{bonus.label}</p>
                                    : null}
                                <Paper elevation={3} style={{ maxWidth: imageSize, maxHeight: imageSize, borderRadius: imageRadius }}>
                                    {bonus != false ?
                                        <img src={bonus.image == '' || bonus.image == false || bonus.image == null ? no_image : GetImageLink(bonus.image)} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, borderRadius: imageRadius }}></img>
                                        :
                                        <img src={no_image} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, borderRadius: imageRadius }}></img>
                                    }
                                </Paper>
                                {bonus != false ?
                                    <div style={{ ...styles.Block, width: imageSize, marginTop: 20 }}>
                                        <TextInput redraw enabled={true} lang={lang} value={text} label={lang.image_note} func={(txt) => setText(txt)} />
                                    </div>
                                    : null}

                                <div style={{ ...styles.Block, marginTop: 40 }}>
                                    {bonus != false ?
                                        <Button onClick={() => setBonus(false)} style={{ ...styles.ButtonRed, height: 30, width: 200, marginBottom: 20 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faXmark} />
                                            {lang.delete}
                                        </Button>
                                        : null}
                                    <Button onClick={() => setShowSelectBonus(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, height: 30, width: 200 }}>{product == false ? lang.bonus_select : lang.bonus_change}</Button>
                                </div>
                            </div>
                            : null}

                        {props.typ == 6 ?
                            // SLUZBA
                            <div style={{ ...styles.BlockCenter, marginBottom: props.isSmall ? 20 : 400 }}>
                                <p style={{ ...styles.TextSmall, marginTop: 10, marginBottom: 5, fontWeight: '400' }}>{lang.service_}</p>
                                {service != false ?
                                    <p style={{ ...styles.TextSmall, marginBottom: 5, fontWeight: '600', marginTop: 20, marginBottom: 10 }}>{service.label}</p>
                                    : null}
                                <Paper elevation={3} style={{ maxWidth: imageSize, maxHeight: imageSize, borderRadius: imageRadius }}>
                                    {service != false ?
                                        <img src={service_1} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, borderRadius: imageRadius }}></img>
                                        : null}
                                </Paper>
                                {service != false ?
                                    <div style={{ ...styles.Block, width: imageSize, marginTop: 20 }}>
                                        <TextInput redraw enabled={true} lang={lang} value={text} label={lang.image_note} func={(txt) => setText(txt)} />
                                    </div>
                                    : null}

                                <div style={{ ...styles.Block, marginTop: 40 }}>
                                    {service != false ?
                                        <Button onClick={() => setService(false)} style={{ ...styles.ButtonRed, height: 30, width: 200, marginBottom: 20 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faXmark} />
                                            {lang.delete}
                                        </Button>
                                        : null}
                                    <Button onClick={() => setShowSelectService(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, height: 30, width: 200 }}>{service == false ? lang.service_select_ : lang.service_change}</Button>
                                </div>
                            </div>
                            : null}

                        {props.typ == 3 ?
                            // ODDELOVAC
                            <div style={{ ...styles.Block, backgroundColor: global.theme_white, height: dialogHeight - global.dialog_bar_height - buttonHeight }}>
                                <p style={{ ...styles.TextTiny, marginBottom: 20 }}>{lang.news_divider_text}</p>
                                <div style={{ ...styles.Block, width: '90%', height: 1, backgroundColor: global.theme_light_gray }}></div>
                            </div>
                            : null}


                        {props.typ == 5 ?
                            // QR CODE
                            <div style={{ ...styles.BlockCenter, marginBottom: props.isSmall ? 20 : 500 }}>
                                <p style={{ ...styles.TextSmall, marginTop: 10, marginBottom: 10, fontWeight: '400' }}>{lang.qrcode}</p>
                                <div style={{ ...styles.Block, width: qrSize, height: qrSize }}>
                                    <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        value={photo == false ? '' : photo}
                                        viewBox={`0 0 256 256`}
                                    />
                                </div>
                                <div style={{ ...styles.Block, width: 400, marginTop: 30 }}>
                                    <TextInput redraw enabled={true} lang={lang} value={photo == false ? '' : photo} label={lang.news_qrcode_text} func={(txt) => setPhoto(txt)} />
                                </div>
                                <div style={{ ...styles.Block, width: 400, marginTop: 30 }}>
                                    <TextInput redraw enabled={true} lang={lang} value={text} label={lang.qrcode_note} func={(txt) => setText(txt)} />
                                </div>
                            </div>
                            : null}

                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <Button onClick={() => Process()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>{lang.save}</Button>
                    </div>

                </div>
            </Paper>

            {showSelectPhoto == true ?
                <Photos lang={lang} typ={7} max_size={1200} width={1200} height={1200} full={true} title={lang.photo_select} sub_title={lang.vision_system} radius={props.radius} offset={props.offset} theme={props.theme} func={PhotosResult.bind(this)} />
                : null}

            {showSelectProduct == true ?
                <ProductSelect isSmall={props.isSmall} cabine={true} user={{ id: 0 }} lang={lang} language={props.country.language_id} offset={props.offset} theme={props.theme} radius={props.radius} func={ProductSelectResult.bind(this)} />
                : null}

            {showSelectService == true ?
                <ServiceSelect isSmall={props.isSmall} user={{ id: 0 }} lang={props.lang} language={props.country.language_id} offset={props.offset} theme={props.theme} radius={props.radius} func={ServiceSelectResult.bind(this)} />
                : null}

            {showSelectBonus == true ?
                <BonusSelect isSmall={props.isSmall} user={{ id: 0 }} lang={lang} language={props.country.language_id} offset={props.offset} theme={props.theme} radius={props.radius} func={BonusSelectResult.bind(this)} />
                : null}

        </Backdrop>
    );
}


export const DialogPostRead = (props) => {
    /*  

            ZOBRAZENIE PRISPEVKU        

            USAGE:
            <DialogPostRead newID={newsID} remoteApp={props.remoteApp} user={props.user} admin={props.admin} countries={props.countries} country={props.country} lang={props.lang} language={props.language}  offset={props.offset} theme={props.theme}  radius={props.radius} isSmall={props.isSmall} func={DialogPostReadResult.bind(this)} />
 
            const DialogPostReadResult = (typ,value) => {
                console.log(value)
            }
    */
    const service_1 = require('./react/app/service1.png');
    const service_2 = require('./react/app/service2.png');

    const [isBusy, setBusy] = useState(false);
    const [news, setNews] = useState(false);
    const [items, setItems] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);

    const [showProduct, setShowProduct] = useState(false);
    const [showBonus, setShowBonus] = useState(false);
    const [showService, setShowService] = useState(false);

    // IMAGE / FOTOGRAFIE
    const [photo, setPhoto] = useState(false);
    const [showPhoto, setShowPhoto] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [radius, setRadius] = useState(height > 850 ? props.radius : 0);

    const dialogWidth = width >= 1200 ? 1200 : width;
    const dialogHeight = height >= 850 ? 850 : height;
    const introImageSize = 250;
    const imageSize = 300;
    const qrSize = 164;
    const imageRadius = 10;
    const offset = 50;

    let { func } = props;
    var lang = props.lang;
    var running = false;

    useEffect(() => {

        if (running == false) {
            db_get();
            running = true;
        }

        return () => {
        };

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'news_details', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    news_id: props.newsID,
                    enabled: true
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setNews(json.news);

                var tmp = json.items;
                if (tmp != false) {
                    tmp.sort((a, b) => {
                        if (parseInt(a.priority) > parseInt(b.priority)) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }

                setItems(tmp);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ) => {
        func(typ);
    }

    const ProductPreviewPress = (item) => {
        setItemSelected(item);
        setShowProduct(true);
    }

    const ServicePreviewPress = (item) => {
        setItemSelected(item);
        setShowService(true);
    }

    const ImagePreview = (item) => {
        setPhoto(item.image);
        setShowPhoto(true);
    }

    const DivideText = (txt) => {
        var result = txt.split('\n');

        return result;
    }

    const RenderText = (props) => {
        return (
            DivideText(props.text).map((item, index) => (
                item == '' ?
                    <div key={index} style={{ ...styles.Block, height: 12 }}></div>
                    :
                    <p key={index} style={{ ...styles.TextSmall, marginTop: 20, textAlign: 'left' }}>{item}</p>
            ))
        )
    }

    return (

        <Backdrop open={true} style={{ zIndex: 50, paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: radius, borderTopRightRadius: radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{news == false ? lang.news_item : news.title}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.news}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: global.theme_white, borderBottomLeftRadius: radius, borderBottomRightRadius: radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        {/* BODY */}
                        {news != false ?
                            <div style={{ ...styles.Block, backgroundColor: global.theme_back, paddingTop: offset, paddingBottom: offset }}>
                                <div style={{ ...props.isSmall == true ? styles.Block : styles.BlockRow, width: '90%' }}>
                                    {news.image != false ?
                                        <div style={{ ...styles.Block, width: introImageSize + 50 }}>
                                            <img src={GetImageLink(news.image)} style={{ width: '100%', height: '100%', maxWidth: introImageSize, maxHeight: introImageSize, objectFit: 'contain', borderRadius: imageRadius }}></img>
                                        </div>
                                        : null}
                                    <div style={{ ...props.isSmall == true ? styles.Block : styles.BlockLeft, width: '80%', marginTop: props.isSmall == true ? 10 : 0 }}>
                                        <p style={{ ...styles.TextXTiny, color: global.theme_medium }}>{GetDate(news.date)}</p>
                                        <p style={{ ...styles.TextNormal, fontWeight: '600', textAlign: 'left', marginTop: 5 }}>{news.title}</p>
                                        {/*
                                        <p style={{ ...styles.TextSmall, marginTop: 20, textAlign: 'left' }}>{news.description}</p>
                                        */}
                                        <RenderText text={news.description} />
                                    </div>
                                </div>
                            </div>
                            : null}
                        {items != false ?
                            <div style={{ ...styles.Block, width: '90%', marginTop: 20, marginBottom: 0 }}>

                                {items.map((item, index) => (
                                    item.enabled == true ?
                                        <div key={item.id} style={{ ...styles.Block, marginBottom: offset }}>
                                            { // TEXT HTML
                                                item.typ == 0 ?
                                                    <div style={{ ...styles.TextSmall, textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: item.text }} />
                                                    : null}

                                            {item.typ == 1 ?
                                                // IMAGE
                                                <div style={{ ...styles.Block }}>
                                                    <img onClick={() => ImagePreview(item)} src={GetImageLink(item.image)} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain', cursor: 'pointer' }}></img>
                                                    <p style={{ ...styles.TextTiny }}>{item.text}</p>
                                                </div>
                                                : null}

                                            {item.typ == 2 ?
                                                // PRODUKT
                                                <div onClick={() => ProductPreviewPress(item.product)} style={{ ...styles.Block, cursor: 'pointer' }}>
                                                    <p style={{ ...styles.TextLarge, fontWeight: '600', marginBottom: 10 }}>{lang.product}</p>
                                                    {item.product != false ?
                                                        <img src={GetImageLink(item.product.image)} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain' }}></img>
                                                        : null}
                                                    <p style={{ ...styles.TextTiny, fontWeight: '400', marginTop: 5 }}>{item.product.code}</p>
                                                    <p style={{ ...styles.TextNormal, fontWeight: '600' }}>{item.product.label}</p>
                                                    <p style={{ ...styles.TextTiny, fontWeight: '400', marginTop: 5 }}>{item.product.note_short}</p>
                                                    <p style={{ ...styles.TextNormal, fontWeight: '400', marginTop: 10 }}>{item.text}</p>
                                                </div>
                                                : null}


                                            {item.typ == 4 ?
                                                // BONUS
                                                <div style={{ ...styles.Block }}>
                                                    <p style={{ ...styles.TextLarge, fontWeight: '600', marginBottom: 10 }}>{lang.bonus}</p>
                                                    {item.bonus != false ?
                                                        item.bonus.image != '' ?
                                                            <img src={GetImageLink(item.bonus.image)} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain' }}></img>
                                                            : null
                                                        : null}
                                                    <p style={{ ...styles.TextTiny, fontWeight: '400', marginTop: 5 }}>{item.bonus.code}</p>
                                                    <p style={{ ...styles.TextNormal, fontWeight: '600' }}>{item.bonus.label}</p>
                                                    <p style={{ ...styles.TextNormal, fontWeight: '400', marginTop: 10 }}>{item.text}</p>
                                                </div>
                                                : null}

                                            {item.typ == 3 ?
                                                // ODDELOVAC
                                                <div style={{ ...styles.Block, height: 2, backgroundColor: global.theme_light_gray }}></div>
                                                : null}

                                            {item.typ == 5 ?
                                                // QRCODE
                                                <div style={{ ...styles.Block }}>
                                                    <div style={{ ...styles.Block, width: qrSize, height: qrSize }}>
                                                        <QRCode
                                                            size={256}
                                                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                            value={item.image == false ? '' : item.image}
                                                            viewBox={`0 0 256 256`}
                                                        />
                                                    </div>
                                                    <div style={{ ...styles.Block, width: '50%' }}>
                                                        <p style={{ ...styles.TextSmall, marginTop: 10 }}>{item.text}</p>
                                                    </div>
                                                </div>
                                                : null}

                                            {item.typ == 6 ?
                                                // OSETRENIE
                                                <div onClick={() => ServicePreviewPress(item.service)} style={{ ...styles.Block, cursor: 'pointer' }}>
                                                    <p style={{ ...styles.TextLarge, fontWeight: '600', marginBottom: 10 }}>{lang.service_}</p>
                                                    {item.service != false ?
                                                        <img src={service_1} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain', borderRadius: imageRadius }}></img>
                                                        : null}
                                                    <p style={{ ...styles.TextNormal, fontWeight: '600', marginTop: 5 }}>{item.service.label}</p>
                                                    <p style={{ ...styles.TextNormal, fontWeight: '400', marginTop: 10 }}>{item.text}</p>
                                                </div>
                                                : null}

                                        </div>
                                        : null
                                ))}
                            </div>
                            : null}
                        {news != false ?
                            <div style={{ ...styles.Block, marginTop: items == false ? 0 : offset, borderTop: '1px solid ' + global.theme_light_gray, paddingTop: offset, backgroundColor: global.theme_back }}>
                                <div style={{ ...styles.Block, width: '90%', alignItems: 'flex-start' }}>
                                    <p style={{ ...styles.TextTiny, marginBottom: offset }}>{lang.news_author}: {news.author}</p>
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
            </Paper>

            {showProduct == true ?
                <ProductPreview isSmall={props.isSmall} offset={props.offset} theme={props.theme} editing={false} user={props.user} user_id={props.user.id} product={itemSelected} lang={lang} radius={props.radius} title={itemSelected.label} sub_title={itemSelected.note_short} backcolor={true} background={false} language={props.language} func={() => setShowProduct(false)} />
                : null}

            {showService == true ?
                <ServicePreview offset={props.offset} theme={props.theme} editing={false} user_id={props.user.id} service={itemSelected} lang={lang} language={props.language} radius={props.radius} title={itemSelected.label} sub_title={itemSelected.note_short} backcolor={true} background={false} func={() => setShowService(false)} />
                : null}

            {showPhoto == true ?
                <DialogImagePreview theme={props.theme} file={GetImageLink(photo)} radius={props.radius} lang={lang} title={lang.preview} sub_title={lang.news} background={global.theme_dark_gray} crop={false} func={() => setShowPhoto(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop >
    );
}