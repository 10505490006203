/*
*
* =================================================================================================================
* FUNKCIE
* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';
import './App.css';
import { Debug } from './items';


export const GetProductColor = (color) => {
    if (color != '#DAC7B8') {
        return color;
    } else {
        return global.theme_lighter;
    }
}

export const GetProductColor2 = (color, theme) => {

    if (color != '#DAC7B8') {
        return color;
    } else {
        return global.themes[theme].theme_lighter;
    }
}