import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, ConvertToPrice, CopyData, Debug, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, FormError, GetDate, GetDate2, GetDate3, GetPrice, GetTodayTime, GetTopPosition, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, ShowError, ShowOK, TemplateWindow, TextInput, TextSearch, Today } from './items';
import { styles } from './styles';
import { Backdrop, Button, IconButton, InputAdornment, Pagination, Paper, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faL, faLayerGroup, faList, faListAlt, faQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';
import { ProductDialog } from './product_dialog';
import { ServicesDialog } from './service_dialog';
import { centerCrop } from 'react-image-crop';
import { Select, MenuItem } from '@mui/material';


export const ServiceSelect = (props) => {

    /*

        <ServiceSelect items={items} note employees={props.employees} user={props.user} lang={props.lang}  offset={props.offset} theme={props.theme}  radius={props.radius} func={ServiceSelectResult.bind(this)} />

        ------------------------------------------------------------------------------------------------------------
        note    -> pridat poznamku k výberu ošetrenia
        items   -> zoznam sluzieb (diagnostika vyhodnotenie)
        ------------------------------------------------------------------------------------------------------------

        const ServiceSelectResult = (typ, item) => {
            if(typ == true){

            }
        }

    */


    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [showNote, setShowNote] = useState(false);

    // KATEGORIE
    const [categories, setCategories] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(false);

    // PRODUKTY
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const itemsMax = 24;

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1024 ? 1024 : width;
    const dialogHeight = height >= 768 ? 768 : height;

    const menuWidth = 250;
    const rowHeight = 100;
    const rowWidth = dialogWidth - menuWidth - 20;
    const selectHeight = props.isSmall ? 50 : 0;

    // COLORS
    const font_selected = global.theme_black;
    const font_disabled = global.theme_darker;

    const no_image = require('./react/app/no_image.jpg');
    const service_1 = require('./react/app/service1.png');
    const service_2 = require('./react/app/service2.png');

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;
            db_get();
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'groups', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    default: lang.services_my,
                    //new_services: lang.services_news,
                    language: props.language,
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                var tmp = json.items;
                tmp = tmp.filter(x => x.enabled == true);

                if (props.items != undefined) {
                    tmp = tmp.filter(x => x.brand_id == props.user.brand_id,);
                }

                setCategories(tmp);
                if (tmp != false) {
                    db_services(tmp[0].id, tmp[0].brand_id == 0 ? true : false);
                    setIndex(tmp[0].id);
                }

            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_services = async (category_id, my_services) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'services', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    category_id: category_id,
                    language: props.language,
                    my_services: my_services
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (props.items == undefined) {
                    var tmp = json.services;

                    if (tmp != false) {
                        tmp = tmp.filter(x => x.enabled == true);

                        tmp.sort((a, b) => {
                            if (a.label > b.label) {
                                return 1;
                            } else {
                                return -1;
                            }
                        })
                    }

                } else {
                    tmp = props.items;
                    tmp = tmp.filter(x => x.group_id == category_id);
                }
                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_search = async (search) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'service_search', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    search: search,
                    language: props.language
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.products;

                if (tmp != false) {
                    tmp = tmp.filter(x => x.enabled == true);

                    tmp.sort((a, b) => {
                        if (a.label > b.label) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                    setIndex(-1);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                    setIndex(-1);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ, item) => {
        func(typ, item);
    }

    const Close = () => {
        Press(false, false);
    }

    const ChangeIndex = (id) => {
        if (categories != false) {
            var item = categories.find(x => x.id == id);

            if (item != undefined) {
                setIndex(item.id);
                setSearch('');
                db_services(item.id, item.brand_id == 0 ? true : false);
            }
        }
    }

    const Search = (text) => {
        setSearch(text);
        if (text.trim().length > 2) {
            db_search(text.trim());
        }
        if (text == '') {
            setItems(false);
            setItemsFiltered(false);
            setIndex(-1);
        }
    }

    const Redraw = (items, p, search) => {
        // REDRAW PRODUKTOV
        var data = items;
        if (search.trim() != '') {
            // filtrovanie podla hladaneho retazca
            var words = search.split(' ');
            data = items.filter(x => FindWords(x.label, x.keywords, words));
        }

        var count = data.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax)
        setItemsFiltered(data);

        if (count == 0) {
            setItemsFiltered(false);
        }

        setPage(p);
        GoToStart();
    }

    function FindWords(label, keywords, words) {
        // hladanie retazca v nazve a keywords
        var result = 0;
        words.forEach(word => {
            if (label.toLowerCase().includes(word.toLowerCase()) || keywords.toLowerCase().includes(word.toLowerCase())) {
                result += 1;
            }

        });
        // ak je pocet najdených slov == poctu hladaných slov -> je zhoda
        return words.length == result;
    }

    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);

        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0, '');
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_, search);
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_products');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }


    const SelectItem = (item) => {
        if (props.note == true) {
            // PRIDANIE POZNAMKY K OSETRENIU
            var tmp = item;
            tmp.note = '';
            tmp.worker_id = props.employee_id;
            setItemSelected(tmp);
            setShowNote(true);
        } else {
            Press(true, item);
        }
    }

    const AddNoteResult = (typ, item) => {
        setShowNote(false);
        if (typ == true) {
            var data = itemSelected;
            data.note = item.note;
            data.date = item.date;
            data.eur = item.eur;
            data.worker_id = item.worker_id;

            Press(true, data);
        }
    }



    /*
    *
    * =================================================================================================================
    * 
    * RENDER
    * 
    * =================================================================================================================
    *
    */
    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '40%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title == undefined ? lang.service_select : props.title}</p>
                            {props.isSmall == true ? null :
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.services}</p>
                            }
                        </div>
                    </div>
                    <div style={{ ...styles.Block, justifyContent: 'center', alignItems: 'center', width: props.isSmall == true ? 160 : '50%', height: global.dialog_bar_height }}>
                        <TextSearch lang={props.lang} isSmall={props.isSmall} func={Search.bind(this)} />
                        {/*
                        <TextField
                            value={search}
                            onInput={e => Search(e.target.value)}
                            size="small"
                            autoCapitalize="off"
                            autoComplete="off"
                            autoCorrect="off"
                            style={{ width: props.isSmall == true ? 150 : 300 }}
                            placeholder={lang.search_in_category}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                }
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                    </IconButton>
                                </InputAdornment>,
                                style: { backgroundColor: global.theme_white, borderRadius: 30 },
                            }}
                            variant="outlined"
                        />
                        */}
                    </div>
                    <div style={{ ...styles.Block, width: props.isSmall == true ? 70 : 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                    <div style={{ ...props.isSmall == true ? styles.BlockCenter : styles.BlockRow, height: dialogHeight - global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockCenter, width: props.isSmall == true ? '100%' : menuWidth, height: props.isSmall == true ? selectHeight : dialogHeight - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                            {props.isSmall ?
                                <div style={{ ...styles.BlockCenter, width: '96%', height: selectHeight }}>
                                    <Select
                                        value={index}
                                        onChange={event => ChangeIndex(event.target.value)}
                                        size='small'
                                        style={{ backgroundColor: global.theme_white, width: '100%' }}
                                    >

                                        {categories != false ?
                                            categories.map((item) => (
                                                item.typ != 100 ?
                                                    <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                                                    : null
                                            ))
                                            : null}
                                    </Select>

                                </div>
                                :
                                <div style={{ ...styles.BlockCenter, width: menuWidth, height: dialogHeight - global.dialog_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    {categories != false ?
                                        categories.map((item) => (
                                            <Button key={item.id} onClick={() => ChangeIndex(item.id)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == item.id ? global.theme_back : '#00000000', color: index == item.id ? font_selected : font_disabled }}>
                                                <div style={{ ...styles.BlockLeft }}>
                                                    <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{item.label}</p>
                                                    <p style={{ ...styles.TextTiny, color: global.themes[props.theme].theme_darker, marginTop: 2, lineHeight: 1, textAlign: 'left' }}>{item.brand}</p>
                                                </div>
                                            </Button>
                                        ))
                                        : null}

                                </div>
                            }
                        </div>
                        <div style={{ ...styles.BlockCenter, width: props.isSmall == true ? '100%' : dialogWidth - menuWidth, height: dialogHeight - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                            {/* BODY */}
                            <div id={'id_products'}>
                            </div>

                            {/* *********************************************************************************************************  

                                ZOZNAM SLUZIEB

                            ********************************************************************************************************* */}
                            {itemsFiltered != false ?
                                itemsFiltered.map((item, index) => (
                                    <div onClick={() => SelectItem(item)} key={item.id} style={{ ...styles.Block, width: '98%', height: rowHeight, backgroundColor: item.enabled == true ? global.theme_lighter : global.theme_light_gray, marginTop: 5, marginBottom: 5, borderRadius: rowHeight / 2, cursor: 'pointer' }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            <div style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                <img src={item.typ == 0 ? service_1 : service_2} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                {item.description != '' ?
                                                    <div style={{ ...styles.BlockLeft, width: '100%', marginTop: 10, height: 33, overflowY: 'hidden' }}>
                                                        <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{item.description}</p>
                                                    </div>
                                                    : null}
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', marginTop: 5 }}>{lang.service_time_}: {item.time} {lang.minutes}</p>
                                            </div>
                                            <div style={{ ...styles.Block, height: rowHeight, width: 120 }}>
                                                <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{GetPrice(item.eur)} {lang.money}</p>
                                            </div>
                                        </div>
                                    </div>
                                )) :
                                // ZIADNE SLUZBY                            
                                <div style={{ ...styles.Block, paddingTop: 20 }}>
                                    <FontAwesomeIcon style={{ height: 30, color: global.theme_dark_gray }} icon={faListAlt} />
                                    <p style={{ ...styles.TextSmall, marginTop: 5 }}>{lang.services_none}</p>
                                </div>
                            }

                            {itemsFiltered != false ?
                                pageMax > 1 ?
                                    <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                        <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                    </div>
                                    : null
                                : null}

                        </div>
                    </div>
                </div>

                {isBusy == true ?
                    <Loading offset={props.offset} theme={props.theme} ></Loading>
                    : null}

                {showNote == true ?
                    <AddServiceNote isSmall={props.isSmall} theme={props.theme} lang={lang} employees={props.employees} radius={props.radius} item={itemSelected} backcolor={true} background={true} func={AddNoteResult.bind(this)} />
                    : null}

            </Paper>
        </Backdrop>
    );
}


/*
   *
   * =================================================================================================================
   * PRIDAT POZNAMKU
   * =================================================================================================================
   *
   */

export const AddServiceNote = (props) => {
    /*
            PRIDAT POZNAMKU K OSETRENIU - PRI VKLADANI DO KARTY ZAKAZNIKA
            
            USAGE:
            <AddServiceNote item={serviceSelected} employees={props.employees} lang={lang} radius={props.radius} backcolor={true} background={false} func={AddServiceNoteResult.bind(this)} />

            -----------------------------------------------------------------------------------------------------------------------
            delete  -> zobrazi tlačítko na vymazanie ošetrenie z karty zákazníka
            -----------------------------------------------------------------------------------------------------------------------
            
            const AddServiceNoteResult = (typ,item) => {
                console.log(item)
            }
    */
    const no_image = require('./react/app/no_image.jpg');
    const service_1 = require('./react/app/service1.png');
    const service_2 = require('./react/app/service2.png');

    const [error, setError] = useState(false);
    const [date, setDate] = useState(props.item.date != undefined ? GetDate3(props.item.date) : Today());
    const [note, setNote] = useState(props.item.note);
    const [price, setPrice] = useState(props.item.eur);
    const [employeeID, setEmployeeID] = useState(props.item.worker_id);
    const [showAskDelete, setShowAskDelete] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = props.isSmall ? width : 850;
    const dialogHeight = props.isSmall ? height : 450;
    const titleHeight = props.isSmall ? 80 : 120;
    const errorHeight = 40;
    const buttonHeight = 80;
    const employeeWidth = props.isSmall ? 150 : 250;

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

        }
        return () => {
        };

    }, []);

    const Press = (typ, data) => {
        func(typ, data);
    }

    const Close = () => {
        Press(false);
    }

    const Process = () => {
        var data = {
            enabled: true,
            date: date + ' ' + GetTodayTime(),
            note: note,
            eur: ConvertToPrice(price),
            worker_id: employeeID
        };

        Press(true, data);
    }

    const ProcessDelete = (value) => {
        setShowAskDelete(false);
        if (value == true) {
            var data = {
                enabled: false,
            };

            Press(true, data);
        }
    }

    return (

        <Backdrop open={true} style={{ position: 'fixed', top: props.isSmall ? undefined : GetTopPosition(height, dialogHeight), paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: titleHeight, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: titleHeight, height: titleHeight }}>
                            <img src={props.item.typ == 0 ? service_1 : service_2} style={{ width: '100%', height: '100%', maxWidth: titleHeight - 20, maxHeight: titleHeight - 20, objectFit: 'contain', borderRadius: props.radius / 2 }}></img>
                        </div>
                    }
                    <div style={{ ...styles.Block, width: props.isSmall ? '50%' : dialogWidth - titleHeight - 100 - employeeWidth, height: titleHeight }}>
                        <div style={{ ...styles.BlockLeft, height: titleHeight, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel, textAlign: 'left', marginLeft: props.isSmall ? 10 : 0 }}>{props.item.label}</p>
                            {props.isSmall ? null :
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.service}</p>
                            }
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: employeeWidth, height: titleHeight }}>
                        {props.employees.length > 1 ?
                            <EmployeeSelect theme={props.theme} select small radius background={global.theme_lighter} width={employeeWidth} employees={props.employees} value={employeeID} func={(value) => setEmployeeID(value)} />
                            : null}
                    </div>
                    <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: titleHeight }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight, backgroundColor: props.background == true ? '#FFFFFFB0' : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '95%', height: dialogHeight - titleHeight - errorHeight - buttonHeight, paddingTop: 20 }}>
                        {/* BODY */}
                        <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockLeft }}>
                            <div style={{ ...styles.Block, width: 200 }}>
                                <TextInput type={'date'} redraw enabled={true} lang={lang} value={date} label={lang.service_date} func={(txt) => { setDate(txt) }} />
                            </div>
                        </div>
                        <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRow, marginTop: 20 }}>
                            <div style={{ ...styles.Block, width: props.isSmall ? '90%' : '80%' }}>
                                <TextInput redraw enabled={true} lang={lang} value={note} label={lang.note} func={(txt) => { setNote(txt) }} />
                            </div>
                            <div style={{ ...styles.Block, width: props.isSmall ? '90%' : '20%', marginLeft: props.isSmall ? 0 : 10, marginTop: props.isSmall ? 20 : 0 }}>
                                <TextInput redraw money enabled={true} lang={lang} value={price} label={lang.price_dph} func={(txt) => { setPrice(txt) }} />
                            </div>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '95%' }}>
                        <FormError error={error} />
                        <div style={{ ...styles.BlockRowRaw, height: buttonHeight }}>
                            {props.delete == true ?
                                <Button onClick={() => setShowAskDelete(true)} style={{ ...styles.ButtonRed, width: 180, marginRight: 20 }}>{lang.card_delete}</Button>
                                : null}
                            <Button onClick={() => Process()} style={{ ...styles.ButtonDark, width: 180 }}>{lang.save}</Button>
                        </div>
                    </div>
                </div>
            </Paper>

            {
                showAskDelete == true ?
                    <DialogYesNo theme={props.theme} radius={props.radius} title={lang.service_delete} sub_title={lang.service} text={lang.service_delete_ask} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={(value) => ProcessDelete(value)} />
                    : null
            }
        </Backdrop >
    );
}