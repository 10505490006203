/*
*
* =================================================================================================================
* STATISTIKA SALONOV - PREDAJ PRODUKTOV A SLUZIEB (jeden salon / vsetky salony)
* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';
import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { CategorySelect, Colors, ConvertToPrice, CopyData, Debug, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, FormatMoney, GetCountry, GetDPH, GetDate, GetImageLink, GetMoney, GetPrice, GetTodayYearMonth, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, ShowError, ShowOK, TemplateWindow, Today } from './items';
import { styles } from './styles';
import { Backdrop, Button, CircularProgress, IconButton, InputAdornment, Pagination, Paper, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCheckCircle, faEdit, faFileExport, faL, faLayerGroup, faList, faListAlt, faPaperPlane, faQuestion, faSave, faShoppingBag, faShoppingBasket, faTrash, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Graph } from './graph';


export const StatsSalons = (props) => {
    /*

        <StatsSalons selector={false} typ={0} country={props.country} language={props.language} lang={props.lang} user={props.user} salon={salon_id} width={dialogWidth - menuWidth}  offset={props.offset} theme={props.theme}  menu_width={0}  />

        -------------------------------------------------------------------------------------
        selector    -> true - obrazuje výber typu (produkty, sluzby,...)
        typ         ->  0 - produkty zakúpené v salóne
                        1 - produkty zakúpené cez e-shop
                        2 - služby
        admin       -> true - zobrazí štatistiku salónov v celej krajine, false -> len moje
        salon       -> false - bude sa riadiť podľa admin, inak len daný salón
        export      -> možnosť export údajov
        -------------------------------------------------------------------------------------

    */
    const dph = GetDPH(props.salon == false ? props.country.language_id : props.salon.language);

    const [isBusy, setBusy] = useState(false);
    const [subIndex, setSubIndex] = useState(-1);
    const [items, setItems] = useState(false);
    const [money, setMoney] = useState(GetMoney(props.salon == false ? props.country.language_id : props.salon.language));

    // GRAF
    const [graphData, setGraphData] = useState(false);
    const [label, setLabel] = useState('');
    const [cost, setCost] = useState(0);
    const [costAll, setCostAll] = useState(0);
    const [quantity, setQuantity] = useState(0);

    // EXPORT STATISTIKY
    const [showExport, setShowExport] = useState(false);

    // OK
    const [showOK, setShowOK] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);


    const rowHeight = 80;

    const no_image = require('./react/app/no_image.jpg');

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            let date = GetTodayYearMonth();
            db_get_stats(props.typ, date);
            setSubIndex(props.typ);
        }

    }, []);

    const db_get_stats = async (typ, date) => {
        setItems(false);

        var link = '';
        if (typ == 0) {
            link = 'stats_products_salon';
        }
        if (typ == 1) {
            link = 'stats_products_eshop';
        }
        if (typ == 2) {
            link = 'stats_services';
        }

        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + link, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.salon == false ? 0 : props.salon.id,
                    admin: props.admin,
                    language: props.country.language_id,
                    agent_id: props.user.id,
                    date: date
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setQuantity(json.quantity_1);
                setCostAll(json.cost_sum);
                var tmp = json.graph_data;

                tmp.forEach(element => {
                    element.value2 = undefined;
                });
                setGraphData(tmp);

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_export = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stat_agent_export', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    admin: props.admin,
                    email: props.user.email,
                    graph_data: graphData,
                    quantity: quantity,
                    cost: costAll,
                    typ: props.typ,

                    lang: {
                        do_not_reply: lang.do_not_reply,
                        do_not_reply_text: lang.do_not_reply_text,

                        label: lang.statistic,
                        sub_label: props.admin == true ? lang.salons + ' - ' + GetCountry(props.country.language_id) : lang.salons_rep_ + ' (' + props.user.name + ' ' + props.user.surname + ')',
                        graph_label: props.typ == 0 ? lang.salon_stat_products_salon_label : props.typ == 1 ? lang.salon_stat_products_eshop_label : lang.salon_stat_services_label,
                        graph_sub_label: props.typ == 0 ? lang.salon_stat_products_quantity : props.typ == 1 ? lang.salon_stat_products_quantity : lang.salon_stat_services_quantity,
                        created: lang.created,

                        money: money,
                        pieces: lang.pieces,

                        product: lang.product,
                        sum_dph: lang.sum_dph,
                        sum: lang.sum,
                        quantity: lang.quantity,

                        sell_quantity: props.typ == 0 ? lang.sell_products_quantity : props.typ == 1 ? lang.sell_products_quantity : lang.sell_services_quantity,
                        cost: lang.cost,
                        products_list: lang.products_list,
                        month_list: lang.month_list,

                        email_subject: lang.export_stats,
                        email_label: lang.info_salons_orders,
                        email_text: lang.export_stats_text,
                    }
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const StatsChange = (id) => {
        setSubIndex(id);
        setGraphData(false);
        let date = GetTodayYearMonth();
        db_get_stats(id, date);
    }

    const GraphStatResult = (id, value, value2) => {

        if (id == -2) {
            // ZMENA ROKA
            setItems(false);
            db_get_stats(subIndex, value + '-' + value2.toString().padStart(2, '0'));
        }
        if (id == -1) {
            // STLPEC - deselect
            setItems(false);
        }
        if (id >= 0) {
            // OZNACENY STLPEC            
            let sum_ = 0;
            let cost_ = 0;
            var tmp = graphData.find(x => x.id == id);
            var items_ = [];
            if (subIndex == 2) {
                // SLUZBY
                items_ = tmp.services1;
                if (value == 1) {
                    items_ = tmp.services2;
                }
            } else {
                // PRODUKTY
                items_ = tmp.products1;
                if (value == 1) {
                    items_ = tmp.products2;
                }
            }
            setLabel(tmp.label);

            items_.sort((a, b) => {
                if (parseInt(a.quantity) < parseInt(b.quantity)) {
                    return 1;
                } else {
                    return -1;
                }
            })

            items_.forEach(item => {
                //sum_ = sum_ + parseFloat(item.sum);
                cost_ = cost_ + parseFloat(item.cost_sum);
            });
            setCost(cost_);
            if (tmp != undefined) {
                setItems(items_);
            }

        }
    }

    const ExportResult = (value) => {
        setShowExport(false);

        if (value == true) {
            db_export();
        }
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <div style={{ ...styles.Block, maxWidth: 900 }}>

            {props.selector == true ?
                <div style={{ ...props.isSmall ? styles.Block : styles.BlockRowRaw, marginTop: 10 }}>
                    <Button onClick={() => StatsChange(0)} style={{ ...styles.ButtonDarkSmall, backgroundColor: subIndex == 0 ? global.theme_dark_red : global.theme_dark, width: 200, marginLeft: 10, marginRight: 10 }}>{lang.salon_stat_products_salon}</Button>
                    <Button onClick={() => StatsChange(1)} style={{ ...styles.ButtonDarkSmall, backgroundColor: subIndex == 1 ? global.theme_dark_red : global.theme_dark, width: 200, marginLeft: 10, marginRight: 10, marginTop: props.isSmall ? 20 : 0 }}>{lang.salon_stat_products_eshop}</Button>
                    <Button onClick={() => StatsChange(2)} style={{ ...styles.ButtonDarkSmall, backgroundColor: subIndex == 2 ? global.theme_dark_red : global.theme_dark, width: 200, marginLeft: 10, marginRight: 10, marginTop: props.isSmall ? 20 : 0 }}>{lang.salon_stat_services}</Button>
                </div>
                : null}

            {graphData != false ?
                <div style={{ marginTop: 10 }}>
                    <Graph
                        lang={props.lang}
                        elevation={0}
                        readius={0}
                        title={subIndex == 0 ? lang.salon_stat_products_salon_label : subIndex == 1 ? lang.salon_stat_products_eshop_label : subIndex == 2 ? lang.salon_stat_services_label : ''}
                        sub_title={subIndex == 2 ?
                            lang.salon_stat_services_quantity + ': ' + quantity + ' ' + lang.pieces + ', ' + lang.cost_.toLowerCase() + ': ' + FormatMoney(costAll) + ' ' + money + ' ' + lang.with_dph + ' / ' + FormatMoney(costAll / dph) + ' ' + money + ' ' + lang.without_dph
                            :
                            lang.salon_stat_products_quantity + ': ' + quantity + ' ' + lang.pieces + ', ' + lang.cost_.toLowerCase() + ': ' + FormatMoney(costAll) + ' ' + money + ' ' + lang.with_dph + ' / ' + FormatMoney(costAll / dph) + ' ' + money + ' ' + lang.without_dph
                        }
                        width={props.isSmall ? width - 10 : props.width - 60 > 900 ? 900 : props.width - 60}
                        height={400}
                        typ={0}
                        data={graphData}
                        title_align={'left'}
                        barWidth={props.isSmall ? 26 : 60}
                        highColor={global.theme_red}
                        overColor={global.theme_blue}
                        showMax={false}
                        lines={true}
                        units={subIndex == 2 ? 'x' : lang.pieces}
                        legend={false}
                        legenda={lang.institut}
                        legenda2={lang.products_my}
                        marginTop={10}
                        selectable={true}
                        show_value={true}
                        year={true}
                        isSmall={props.isSmall}
                        func={GraphStatResult.bind(this)}
                    />
                </div>
                : null}

            <div style={{ ...styles.BlockRow }}>
                <div style={{ ...styles.BlockLeft }}>
                    {graphData != false && items == false ?
                        <p style={{ ...styles.TextTiny, marginTop: 10, color: global.theme_dark_gray }}>{lang.graph_click}</p>
                        : null}
                </div>

                {props.export == true ?
                    graphData != false ?
                        <div style={{ ...styles.BlockRight, marginTop: 10 }}>
                            <Button onClick={() => setShowExport(true)} style={{ ...styles.ButtonDarkSmall, backgroundColor: global.theme_light_gray, color: global.theme_black, width: 200 }}>
                                <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginRight: 10 }} icon={faPaperPlane} />
                                {lang.export}
                            </Button>
                        </div>
                        : null
                    : null}
            </div>


            {items != false ?
                <div style={{ ...styles.Block, marginTop: 20, marginBottom: 20 }}>
                    <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{label}</p>
                    <p style={{ ...styles.TextSmall, marginTop: 5, marginBottom: 10, fontWeight: '400' }}>{lang.salon_stat_sum_cost}: {FormatMoney(cost.toFixed(2))} {money} {lang.with_dph} / {FormatMoney((cost / dph).toFixed(2))} {money} {lang.without_dph}</p>
                    {items.map((item, index) => (
                        <Paper elevation={2} key={item.id} style={{ ...styles.Block, width: '98%', height: rowHeight, backgroundColor: parseInt(item.instock) == 0 ? global.theme_lighter_red : global.theme_lighter, marginTop: 5, marginBottom: 5, borderRadius: rowHeight / 2, cursor: 'pointer' }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                    <img src={item.image == null || item.image == '' ? no_image : item.image.substring(0, 4) == 'http' ? item.image : global.web_images + '/' + item.image} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                </div>
                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                    <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                    <p style={{ ...styles.TextTiny, marginTop: 5, textAlign: 'left' }}>*{lang.sum_dph_}: {FormatMoney(item.cost_sum)} {money} {lang.with_dph} / {FormatMoney(item.cost_sum / dph)} {money} {lang.without_dph}</p>
                                </div>
                                <div style={{ ...styles.Block, height: rowHeight, width: 150 }}>
                                    <p style={{ ...styles.TextSmall }}>{item.quantity} {lang.pieces}</p>
                                </div>
                            </div>
                        </Paper>
                    ))}
                </div>
                : null}

            {showExport == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.export_stats} sub_title={''} text={lang.export_agent_data_ask} sub_text={lang.export_agent_data_text + ' ' + props.user.email} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={ExportResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} side={props.menu_width}></Loading>
                : null}
        </div>
    );
}
