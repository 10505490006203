/*
*
* =================================================================================================================
* STATISTIKA SALONOV - PODLA OBCHODNYCH ZASTUPCOV
* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { CategorySelect, Colors, ConvertToPrice, CopyData, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, FormatMoney, GetCountry, GetDate, GetImageLink, GetMoney, GetPrice, GetTodayYear, GetTodayYearMonth, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, SelectYear, ShowError, ShowOK, TemplateWindow, Today } from './items';
import { styles } from './styles';
import { Backdrop, Button, CircularProgress, IconButton, InputAdornment, Pagination, Paper, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faArrowDown, faCheckCircle, faEdit, faL, faLayerGroup, faList, faListAlt, faPaperPlane, faQuestion, faSave, faShoppingBag, faShoppingBasket, faTrash, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Graph } from './graph';
import { StatsSalons } from './stats_salons';
import { SalonCard } from './salon_card';


export const StatsSalonsReps = (props) => {
    const [isBusy, setBusy] = useState(false);

    const [services, setServices] = useState(false);
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [actualYear, setActualYear] = useState(new Date().getFullYear());
    const [column, setColumn] = useState(-1);
    const [row, setRow] = useState(-1);
    const [showCard, setShowCard] = useState(false);
    const [clientID, setClientID] = useState(0);
    const [sortID, setSortID] = useState(-1);
    const [money, setMoney] = useState(GetMoney(props.country.language_id));

    // SUMY
    const [cardSum, setCardSum] = useState(0);
    const [productsSalonSum, setProductsSalonSum] = useState(0);
    const [productsEshopSum, setProductsEshopSum] = useState(0);
    const [servicesSum, setServicesSum] = useState(0);
    const [naosSum, setNaosSum] = useState(0);
    const [bonusSum, setBonusSum] = useState(0);
    const [creditsSum, setCreditsSum] = useState(0);

    // EXPORT STATISTIKY
    const [showExport, setShowExport] = useState(false);

    // OK
    const [showOK, setShowOK] = useState(false);


    const offset = 10;
    const rowWidth = 160;
    const cellHeight = 100;
    const titleHeight = 70;
    const bottomHeight = 40;

    const no_image = require('./react/app/no_image.jpg');

    // Aktuálna farebna téma
    const color_theme = global.themes[props.theme];

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;
            let date = GetTodayYear();
            db_get(date);
        }

    }, []);

    const db_get = async (date) => {

        setBusy(true);
        try {
            const response = await fetch(
                global.db_url_agent + 'stats_salons_reps', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    admin: props.admin,
                    language: props.country.language_id,
                    agent_id: props.user.id,
                    date: date
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;
                tmp = tmp.filter(x => x.salons_quantity > 0);
                setItems(tmp);
                setItemsFiltered(tmp);
                setCardSum(json.cards_sum);
                setProductsSalonSum(json.products_salon_sum);
                setProductsEshopSum(json.products_eshop_sum);
                setServicesSum(json.services_sum);
                setNaosSum(json.naos_sum);
                setBonusSum(json.bonus_sum);
                setCreditsSum(json.credits_sum);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_export = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url_agent + 'stat_agent_cards_export', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    typ: 1,
                    user_id: props.user.id,
                    admin: props.admin,
                    email: props.user.email,
                    items: itemsFiltered,
                    year: actualYear,

                    card_sum: cardSum,
                    products_salon_sum: productsSalonSum,
                    products_eshop_sum: productsEshopSum,
                    services_sum: servicesSum,
                    naos_sum: naosSum,
                    bonus_sum: bonusSum,
                    credits_sum: creditsSum,

                    lang: {
                        do_not_reply: lang.do_not_reply,
                        do_not_reply_text: lang.do_not_reply_text,

                        label: lang.stats_salons,
                        sub_label: props.admin == true ? lang.salons + ' - ' + GetCountry(props.country.language_id) : lang.salons_rep_ + ' (' + props.user.name + ' ' + props.user.surname + ')',
                        created: lang.created,

                        money: money,
                        pieces: lang.pieces,

                        product: lang.product,
                        sum_dph: lang.sum_dph,
                        sum: lang.sum,
                        quantity: lang.quantity,

                        sell_quantity: props.typ == 0 ? lang.sell_products_quantity : props.typ == 1 ? lang.sell_products_quantity : lang.sell_services_quantity,
                        cost: lang.cost,
                        products_list: lang.products_list,
                        month_list: lang.month_list,

                        stats_season: lang.stats_season,
                        salon: lang.salon,
                        salon_stats_cards_quantity: lang.salon_stats_cards_quantity,
                        salon_stats_products_salon_quantity: lang.salon_stats_products_salon_quantity,
                        salon_stats_products_eshop_quantity: lang.salon_stats_products_eshop_quantity,
                        salon_stats_services_quantity: lang.salon_stats_services_quantity,
                        salon_stats_orders_value: lang.salon_stats_orders_value,
                        salon_stats_bonus_value: lang.salon_stats_bonus_value,
                        salon_stats_credit_value: lang.salon_stats_credit_value,
                        salon_stats_salons_quantity: lang.salon_stats_salons_quantity,

                        email_subject: lang.export_stats,
                        email_label: lang.stats_salons,
                        email_text: lang.export_stats_text + ' - ' + lang.stats_salons,
                    }
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ) => {
        func(typ);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const SelectYearResult = (year, month) => {
        db_get(year)
    }

    const SetColumn = (col, item) => {
        setColumn(col);
        setRow(item.id);
    }


    const Card = (item) => {
        setClientID(item.id);
        setShowCard(true);
    }

    const SortTable = (id) => {
        setSortID(id);
        var tmp = items;

        if (id == 0) {
            // Počet kariet
            tmp.sort((a, b) => {
                if (parseInt(a.cards) < parseInt(b.cards)) {
                    return 1;
                } else {
                    return -1;
                }
            })
        }
        if (id == 1) {
            // Počet produktov - salon
            tmp.sort((a, b) => {
                if (parseInt(a.products_salon) < parseInt(b.products_salon)) {
                    return 1;
                } else {
                    return -1;
                }
            })
        }
        if (id == 2) {
            // Počet produktov - eshop
            tmp.sort((a, b) => {
                if (parseInt(a.products_eshop) < parseInt(b.products_eshop)) {
                    return 1;
                } else {
                    return -1;
                }
            })
        }
        if (id == 3) {
            // Počet ošetrení
            tmp.sort((a, b) => {
                if (parseInt(a.services) < parseInt(b.services)) {
                    return 1;
                } else {
                    return -1;
                }
            })
        }
        if (id == 4) {
            // hodnota objedávok produktov
            tmp.sort((a, b) => {
                if (parseFloat(a.naos) < parseFloat(b.naos)) {
                    return 1;
                } else {
                    return -1;
                }
            })
        }
        if (id == 5) {
            // hodnota objedávok bonusu
            tmp.sort((a, b) => {
                if (parseFloat(a.bonus) < parseFloat(b.bonus)) {
                    return 1;
                } else {
                    return -1;
                }
            })
        }
        if (id == 6) {
            // počet kreditov
            tmp.sort((a, b) => {
                if (parseFloat(a.bonus_credit) < parseFloat(b.bonus_credit)) {
                    return 1;
                } else {
                    return -1;
                }
            })
        }
        setItemsFiltered(tmp);
        setColumn(-1);
        setRow(-1);
    }

    const ExportResult = (value) => {
        setShowExport(false);

        if (value == true) {
            db_export();
        }
    }

    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                        <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                    </div>
                </div>
                <div style={{ ...styles.Block, width: '65%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: 45 }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                        <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                    </div>
                </div>
                <div style={{ ...styles.Block, width: 260, height: global.dialog_bar_height }}>
                    <Button onClick={() => setShowExport(true)} style={{ ...styles.ButtonDarkSmall, backgroundColor: global.themes[props.theme].theme_medium, color: global.theme_white, width: 160 }}>
                        <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPaperPlane} />
                        {lang.export}
                    </Button>
                </div>
                <div style={{ ...styles.Block, width: 150, height: global.dialog_bar_height }}>
                    <SelectYear min={2020} value={actualYear} func={SelectYearResult.bind(this)} />
                </div>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Press()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius }}>
                {itemsFiltered != false ?
                    <div style={{ ...styles.BlockCenter, justifyContent: 'flex-start', alignItems: 'flex-start', width: props.width, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overscrollBehavior: 'none', msOverflowStyle: 'none', scrollbarWidth: 'none', overflow: 'scroll' }} className='container'>
                        <div style={{ ...styles.BlockRowRaw, position: 'sticky', top: 0, zIndex: 110, backgroundColor: global.theme_light, borderBottom: '1px solid ' + global.theme_dark }}>
                            <div style={{ ...styles.Block, alignItems: 'flex-start', position: 'sticky', left: 0, zIndex: 120, height: titleHeight, width: rowWidth * 2, borderLeft: '1px solid ' + global.theme_medium_gray, borderRight: '1px solid ' + global.theme_medium_gray, backgroundColor: global.theme_white }}>
                                <p style={{ ...styles.TextTiny, marginLeft: offset }}>{lang.agent}</p>
                            </div>
                            <div onClick={() => SortTable(0)} style={{ ...styles.Block, height: titleHeight, width: rowWidth, borderRight: '1px solid ' + global.theme_dark, cursor: 'pointer', backgroundColor: sortID == 0 ? global.theme_dark : undefined }}>
                                <p style={{ ...styles.TextTiny, color: sortID == 0 ? global.theme_white : global.theme_black }}>{lang.salon_stats_cards_quantity}</p>
                                {sortID == 0 ?
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white }} icon={faArrowDown} />
                                    : null}
                            </div>
                            <div onClick={() => SortTable(1)} style={{ ...styles.Block, height: titleHeight, width: rowWidth, borderRight: '1px solid ' + global.theme_dark, cursor: 'pointer', backgroundColor: sortID == 1 ? global.theme_dark : undefined }}>
                                <p style={{ ...styles.TextTiny, color: sortID == 1 ? global.theme_white : global.theme_black }}>{lang.salon_stats_products_salon_quantity}</p>
                                {sortID == 1 ?
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginTop: 5 }} icon={faArrowDown} />
                                    : null}
                            </div>
                            <div onClick={() => SortTable(2)} style={{ ...styles.Block, height: titleHeight, width: rowWidth, borderRight: '1px solid ' + global.theme_dark, cursor: 'pointer', backgroundColor: sortID == 2 ? global.theme_dark : undefined }}>
                                <p style={{ ...styles.TextTiny, color: sortID == 2 ? global.theme_white : global.theme_black }}>{lang.salon_stats_products_eshop_quantity}</p>
                                {sortID == 2 ?
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginTop: 5 }} icon={faArrowDown} />
                                    : null}
                            </div>
                            <div onClick={() => SortTable(3)} style={{ ...styles.Block, height: titleHeight, width: rowWidth, borderRight: '1px solid ' + global.theme_dark, cursor: 'pointer', backgroundColor: sortID == 3 ? global.theme_dark : undefined }}>
                                <p style={{ ...styles.TextTiny, color: sortID == 3 ? global.theme_white : global.theme_black }}>{lang.salon_stats_services_quantity}</p>
                                {sortID == 3 ?
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginTop: 5 }} icon={faArrowDown} />
                                    : null}
                            </div>
                            <div onClick={() => SortTable(4)} style={{ ...styles.Block, height: titleHeight, width: rowWidth, borderRight: '1px solid ' + global.theme_dark, cursor: 'pointer', backgroundColor: sortID == 4 ? global.theme_dark : undefined }}>
                                <p style={{ ...styles.TextTiny, color: sortID == 4 ? global.theme_white : global.theme_black }}>{lang.salon_stats_orders_value}</p>
                                {sortID == 4 ?
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginTop: 5 }} icon={faArrowDown} />
                                    : null}
                            </div>
                            <div onClick={() => SortTable(5)} style={{ ...styles.Block, height: titleHeight, width: rowWidth, borderRight: '1px solid ' + global.theme_dark, cursor: 'pointer', backgroundColor: sortID == 5 ? global.theme_dark : undefined }}>
                                <p style={{ ...styles.TextTiny, color: sortID == 5 ? global.theme_white : global.theme_black }}>{lang.salon_stats_bonus_value}</p>
                                {sortID == 5 ?
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginTop: 5 }} icon={faArrowDown} />
                                    : null}
                            </div>
                            <div onClick={() => SortTable(6)} style={{ ...styles.Block, height: titleHeight, width: rowWidth, borderRight: '1px solid ' + global.theme_dark, cursor: 'pointer', backgroundColor: sortID == 6 ? global.theme_dark : undefined }}>
                                <p style={{ ...styles.TextTiny, color: sortID == 6 ? global.theme_white : global.theme_black }}>{lang.salon_stats_credit_value}</p>
                                {sortID == 6 ?
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginTop: 5 }} icon={faArrowDown} />
                                    : null}
                            </div>
                        </div>
                        {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                            <div key={item.id} style={{ ...styles.BlockRowRaw, backgroundColor: row == item.id ? global.theme_lighteen : index % 2 == 0 ? global.theme_white : global.theme_gray, borderBottom: '1px solid ' + global.theme_medium_gray }}>
                                <div /* onClick={() => Card(item)} */ style={{ ...styles.BlockLeft, position: 'sticky', left: 0, zIndex: 100, width: rowWidth * 2, cursor: 'pointer', height: cellHeight, borderLeft: '1px solid ' + global.theme_medium_gray, borderRight: '1px solid ' + global.theme_medium_gray, backgroundColor: row == item.id ? global.theme_lighteen : index % 2 == 0 ? global.theme_white : global.theme_gray }}>
                                    <p style={{ ...styles.TextTiny, marginTop: 5, marginLeft: offset, textAlign: 'left', fontWeight: '600' }}>{item.surname} {item.name}</p>
                                    <p style={{ ...styles.TextTiny, marginTop: 5, marginLeft: offset, textAlign: 'left' }}>{lang.salon_stats_salons_quantity}: {item.salons_quantity}</p>
                                </div>
                                <div onClick={() => SetColumn(0, item)} style={{ ...styles.Block, width: rowWidth, height: cellHeight, borderRight: '1px solid ' + global.theme_medium_gray, backgroundColor: column == 0 ? global.theme_lighteen : undefined, cursor: 'pointer' }}>
                                    <p style={{ ...styles.TextTiny, color: item.cards == 0 ? global.theme_dark_red : global.theme_black }}>{item.cards}</p>
                                </div>
                                <div onClick={() => SetColumn(1, item)} style={{ ...styles.Block, width: rowWidth, height: cellHeight, borderRight: '1px solid ' + global.theme_medium_gray, backgroundColor: column == 1 ? global.theme_lighteen : undefined, cursor: 'pointer' }}>
                                    <p style={{ ...styles.TextTiny, color: item.products_salon == 0 ? global.theme_dark_red : global.theme_black }}>{item.products_salon} {lang.pieces}</p>
                                </div>
                                <div onClick={() => SetColumn(2, item)} style={{ ...styles.Block, width: rowWidth, height: cellHeight, borderRight: '1px solid ' + global.theme_medium_gray, backgroundColor: column == 2 ? global.theme_lighteen : undefined, cursor: 'pointer' }}>
                                    <p style={{ ...styles.TextTiny, color: item.products_eshop == 0 ? global.theme_dark_red : global.theme_black }}>{item.products_eshop} {lang.pieces}</p>
                                </div>
                                <div onClick={() => SetColumn(3, item)} style={{ ...styles.Block, width: rowWidth, height: cellHeight, borderRight: '1px solid ' + global.theme_medium_gray, backgroundColor: column == 3 ? global.theme_lighteen : undefined, cursor: 'pointer' }}>
                                    <p style={{ ...styles.TextTiny, color: item.services == 0 ? global.theme_dark_red : global.theme_black }}>{item.services} x</p>
                                </div>
                                <div onClick={() => SetColumn(4, item)} style={{ ...styles.Block, width: rowWidth, height: cellHeight, borderRight: '1px solid ' + global.theme_medium_gray, backgroundColor: column == 4 ? global.theme_lighteen : undefined, cursor: 'pointer' }}>
                                    <p style={{ ...styles.TextTiny, color: item.naos == 0 ? global.theme_dark_red : global.theme_black }}>{FormatMoney(item.naos)} {money}</p>
                                </div>
                                <div onClick={() => SetColumn(5, item)} style={{ ...styles.Block, width: rowWidth, height: cellHeight, borderRight: '1px solid ' + global.theme_medium_gray, backgroundColor: column == 5 ? global.theme_lighteen : undefined, cursor: 'pointer' }}>
                                    <p style={{ ...styles.TextTiny, marginBottom: 5, color: item.bonus == 0 ? global.theme_dark_red : global.theme_black }}><span style={{ fontSize: global.font_xxtiny }}>{lang.bonus_from_credit}:</span> {FormatMoney(item.bonus_credits)} {money}</p>
                                    <p style={{ ...styles.TextTiny, marginBottom: 0, color: item.bonus == 0 ? global.theme_dark_red : global.theme_black }}><span style={{ fontSize: global.font_xxtiny }}>{lang.bonus_pay_}:</span> {FormatMoney(item.bonus_pay)} {money}</p>
                                </div>
                                <div onClick={() => SetColumn(6, item)} style={{ ...styles.Block, width: rowWidth, height: cellHeight, borderRight: '1px solid ' + global.theme_medium_gray, backgroundColor: column == 6 ? global.theme_lighteen : undefined, cursor: 'pointer' }}>
                                    <p style={{ ...styles.TextTiny, color: item.bonus_credit == 0 ? global.theme_dark_red : global.theme_black }}>{FormatMoney(item.bonus_credit)} {money}</p>
                                </div>
                            </div>
                        )) : null}

                    </div>
                    : null}
            </div>


            {showExport == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.export_stats} sub_title={''} text={lang.export_agent_data_ask} sub_text={lang.export_agent_data_text + ' ' + props.user.email} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={ExportResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {/* KARTA SALONU - POSLEDNA OTVORENA */}
            {showCard == true ?
                <SalonCard salon_id={clientID} remoteApp={props.remoteApp} user={props.user} admin={props.admin} countries={props.countries} country={props.country} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} isSmall={props.isSmall} title={props.lang.salons_list} sub_title={props.lang.salons} func={() => setShowCard(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme}  ></Loading>
                : null}


        </Paper >
    );
}
