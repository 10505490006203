import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, ConvertToPrice, CopyData, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, GetDate, GetImageLink, GetPrice, GetTodayYearMonth, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, ShowError, ShowOK, TemplateWindow, Today } from './items';
import { styles } from './styles';
import { Backdrop, Button, CircularProgress, IconButton, InputAdornment, Pagination, Paper, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCheckCircle, faEdit, faL, faLayerGroup, faList, faListAlt, faQuestion, faSave, faShoppingBag, faShoppingBasket, faTrash, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Graph } from './graph';
import './items.css';


export const StatsEmployees = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);

    const [actualDate, setActualDate] = useState(false);
    const [data, setData] = useState(false);
    const [graphData, setGraphData] = useState(false);
    const [items, setItems] = useState(false);
    const [sum, setSum] = useState(0);
    const [cost, setCost] = useState(0);
    const [label, setLabel] = useState('');

    const menuWidth = 250;
    const rowHeight = 100;
    const rowWidth = props.width - menuWidth - 20;

    // COLORS
    const font_selected = global.theme_black;
    const font_disabled = global.theme_darker;

    const no_image = require('./react/app/no_image.jpg');
    const service_1 = require('./react/app/service1.png');
    const service_2 = require('./react/app/service2.png');

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            let date = GetTodayYearMonth();
            setActualDate(date);
            db_get(date, 0);
        }

    }, []);

    const db_get = async (date, id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stats_employees', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    date: date,
                    employee_id: id,
                    language: props.language,
                })
            })

            const json = await response.json();
            
            if (json.ok > 0) {
                setData(json);
                setGraphData(json.graph_data);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = () => {
        func(false);
    }

    const GraphResult = (id, value, value2) => {
        if (id == -2) {
            // ZMENA ROKA
            setItems(false);
            let date = value + '-' + value2.toString().padStart(2, '0');
            setActualDate(date);
            db_get(date, index);
        }
        if (id == -1) {
            // STLPEC - deselect
            setItems(false);
        }
        if (id >= 0) {
            // OZNACENY STLPEC
            let sum_ = 0;
            let cost_ = 0;
            var tmp = graphData.find(x => x.id == id);
            var services = tmp.services1;
            if (value == 1) {
                services = tmp.services2;
            }
            setLabel(tmp.label);

            services.sort((a, b) => {
                if (parseInt(a.quantity) < parseInt(b.quantity)) {
                    return 1;
                } else {
                    return -1;
                }
            })

            services.forEach(item => {
                sum_ = sum_ + parseFloat(item.sum);
                cost_ = cost_ + parseFloat(item.cost_sum);
            });

            setSum(sum_);
            setCost(sum_ - cost_);
            if (tmp != undefined) {
                setItems(services);
                
                GotoStart();
            }
        }
    }

    const ChangeIndex = (id) => {
        setIndex(id);
        setItems(false);
        db_get(actualDate, id);
    }

    const GotoStart = () => {
        setTimeout(() => {
            const element = document.getElementById('view_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 300);
    }

    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                        <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                    </div>
                </div>
                <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: 45 }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                        <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                    </div>
                </div>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Press()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                        <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            {props.employees != false ?
                                props.employees.map((item) => (
                                    <Button key={item.id} onClick={() => ChangeIndex(item.id)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == item.id ? global.theme_back : '#00000000', color: index == item.id ? font_selected : font_disabled }}>
                                        <div style={{ ...styles.BlockLeft }}>
                                            <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{item.name} {item.surname}</p>
                                        </div>
                                    </Button>
                                ))
                                : null}

                        </div>
                    </div>
                    <div style={{ ...styles.BlockCenter, width: props.width - menuWidth, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none' }} className='container'>
                        <div style={{ ...styles.BlockCenter, width: props.width - menuWidth - 20, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                            <div id={'view_start'}></div>
                            {/* *********************************************************************************************************  
                                BODY
                            ********************************************************************************************************* */}
                            {data != false ?
                                <div style={{ ...styles.BlockLeft, }}>
                                    <p style={{ ...styles.TextSmall, fontWeight: '600', marginTop: 10 }}>{lang.stats_info_active}: {data.clients}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: '600', marginTop: 10 }}>{lang.sum_dph}: {GetPrice(ConvertToPrice(data.sum))} {lang.money}</p>
                                    <p style={{ ...styles.TextSmall }}>{lang.rabat} {GetPrice(ConvertToPrice(data.cost))} {lang.money}</p>
                                </div>
                                : null}
                            <div style={{ ...styles.BlockCenter, marginTop: 20 }}>
                                <Graph
                                    lang={props.lang}
                                    elevation={0}
                                    readius={0}
                                    title={lang.services}
                                    sub_title={''}
                                    width={props.width - menuWidth - 20}
                                    height={400}
                                    typ={0}
                                    data={graphData}
                                    barWidth={60}
                                    highColor={global.theme_red}
                                    overColor={global.theme_blue}
                                    showMax={false}
                                    lines={true}
                                    units={''}
                                    legend={true}
                                    legenda={lang.institut}
                                    legenda2={lang.services_my}
                                    marginTop={10}
                                    selectable={true}
                                    show_value={true}
                                    year={true}
                                    func={GraphResult.bind(this)}
                                />
                                <p style={{ ...styles.TextTiny, marginTop: 5, color: global.theme_dark_gray }}>{lang.graph_click}</p>
                                <div style={{ ...styles.Block, marginTop: 20, paddingBottom: 30 }}>
                                    {items.length > 0 ?
                                        <div style={{ ...styles.Block }}>
                                            <p style={{ ...styles.TextTiny }}>{lang.services_list} ({label})</p>
                                            <p style={{ ...styles.TextTiny, fontWeight: '600', marginTop: 10 }}>{lang.sum_dph}: {GetPrice(ConvertToPrice(sum))} {lang.money}</p>
                                            <p style={{ ...styles.TextTiny, marginTop: 0 }}>{lang.rabat}: {GetPrice(ConvertToPrice(cost))} {lang.money}</p>
                                        </div>
                                        : null}
                                    {
                                        // ZOZNAM PRODUKTOV / SLUZIEB
                                        items != false ?
                                            items.map((item, index) => (
                                                <Paper elevation={2} key={item.id} style={{ ...styles.Block, height: rowHeight, backgroundColor: global.theme_light_gray, marginTop: 5, marginBottom: 5, borderRadius: rowHeight }}>
                                                    <div style={{ ...styles.BlockRow }}>
                                                        <div style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                            <img src={item.group_id == 1 ? service_1 : service_2} style={{ width: '100%', height: '100%', width: (rowHeight) - 10, height: (rowHeight) - 10, objectFit: 'contain', borderRadius: (rowHeight) - 10, marginLeft: 5 }}></img>
                                                        </div>
                                                        <div style={{ ...styles.BlockLeft, height: (rowHeight), width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                            <div style={{ ...styles.BlockLeft, borderBottom: '1px solid ' + global.theme_medium_gray, paddingBottom: 5 }}>
                                                                <div style={{ ...styles.BlockLeft, width: '60%' }}>
                                                                    <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                                </div>
                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: '100%', marginTop: 5 }}>
                                                                <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.quantity_}: {item.quantity}</p>
                                                            </div>
                                                        </div>
                                                        <div style={{ ...styles.Block, height: (rowHeight), width: 120 }}>
                                                            <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{GetPrice(ConvertToPrice(GetPrice(ConvertToPrice(item.sum))))} {lang.money}</p>
                                                        </div>
                                                    </div>
                                                </Paper>
                                            ))
                                            :
                                            null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {
                isBusy == true ?
                    <Loading  offset={props.offset} theme={props.theme}  ></Loading>
                    : null
            }

        </Paper >
    );
}
