import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { ConvertToPrice, CopyData, DialogEmployee, DialogInfo, DialogLogin, DialogPasswordChange, DialogYesNo, FormError, GetMinutesFromHours, GetVersion, Init, IsEmpty, Loading, Menu, MenuTitle, MenuTop, nonEmail, notNumber, notTime, ResetLogin, ShowError, ShowOK, TemplateWindow, TextInput } from './items';
import { styles } from './styles';
import { Backdrop, BackdropRoot, Button, Checkbox, Fade, FormControlLabel, IconButton, MenuItem, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faCircle, faCog, faList, faLock, faPen, faPlus, faQuestion, faSave, faStarOfLife, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import './items.css';


export const PinCode = (props) => {
    /*
              OKNO - full screen
              
              USAGE:
              <PinCode type={0} pin={'123456'} lang={lang} radius={props.radius} title={'nazov'} func={PinResult.bind(this)} />
              ----------------------------------------------------------------------------------------------------
              type = 2  -> zmena pin kódu
                   = 1  -> vloženie nového kódu
              pin       -> povodny pin - pouzíva sa len pri zmene pinu
              alert     -> Ak je nesprávny pin kod pri vkladani typ 0 -> zobrazí hlášku
              ----------------------------------------------------------------------------------------------------
              const PinResult = (typ,value) => {
                  console.log(value)
              }
      */

    const [data, setData] = useState(false);
    const [index, setIndex] = useState(0);
    const [pinOld, setPinOld] = useState('');
    const [pinNew1, setPinNew1] = useState('');
    const [pinNew2, setPinNew2] = useState('');
    const [pinArray, setPinArray] = useState([]);
    const [label, setLabel] = useState('');
    const [error, setError] = useState('');
    const [redraw, setRedraw] = useState(false);
    const [showAlert, setShowAlert] = useState(false);


    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 300;
    const dialogHeight = 540;
    const buttonSize = 75;
    const rowSize = 75;

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {

        var n = 1;
        var items = [];
        while (n < 10) {
            items.push({ id: n, label: n });
            n++;
        }
        items.push({ id: 10, label: lang.delete });
        items.push({ id: 0, label: 0 });
        items.push({ id: 11, label: lang.back });
        setData(items);

        CreateLabel(index);

        return () => {
        };

    }, []);

    const Press = (typ, pin_) => {
        func(typ, pin_);
    }

    const Close = () => {
        Press(0, 0);
    }

    const GetPin = (items_) => {

        var value = '';
        items_.forEach(element => {
            value += element.label;
        });

        return value;
    }

    const CreateLabel = (id) => {
        if (props.type == 1) {
            if (id == 0) {
                setLabel(lang.settings_pin_enter_new_label);
            } else {
                setLabel(lang.settings_pin_reenter_label);
            }
        } else if (props.type == 2) {
            if (id == 0) {
                setLabel(lang.settings_pin_enter_old_label);
            } else if (id == 1) {
                setLabel(lang.settings_pin_enter_new_label);
            } else {
                setLabel(lang.settings_pin_reenter_label);
            }
        } else {
            setLabel(lang.settings_pin_enter_label);
        }
        setIndex(id);
    }

    const PinPress = (item) => {
        var tmp = pinArray;
        var id = index;
        var err = false;

        setError('');
        if (tmp.length < 6) {
            if (item.id == 10) {
                tmp = [];
            } else if (item.id == 11) {
                if (tmp.length > 0) {
                    tmp.pop();
                }
            } else {
                tmp.push(item);
            }
        }

        setPinArray(tmp);
        setRedraw(!redraw);

        if (tmp.length > 5) {
            if (props.type == 1) {
                // NOVY KOD
                if (id == 0) {
                    setPinNew1(GetPin(tmp));
                    setTimeout(() => {
                        tmp = [];
                        setPinArray(tmp);
                    }, 250);
                }
                if (id == 1) {
                    var pin = GetPin(tmp);
                    setTimeout(() => {
                        tmp = [];
                        setPinArray(tmp);
                    }, 250);

                    setPinArray(tmp);
                    if (pinNew1.toString() != pin.toString()) {
                        setError(lang.settings_pin_not_equal);
                        id = 0;
                    } else {
                        Press(true, pinNew1);
                    }
                }
                id++;
                CreateLabel(id);
            } else if (props.type == 2) {
                // ZMENA KODU
                if (id == 0) {
                    // pôvodný pin
                    setPinOld(GetPin(tmp));
                    setTimeout(() => {
                        tmp = [];
                        setPinArray(tmp);
                    }, 250);
                    if (props.pin != GetPin(tmp)) {
                        err = true;
                        setError(lang.settings_pin_error);
                    }
                }
                if (id == 1) {
                    setPinNew1(GetPin(tmp));
                    setTimeout(() => {
                        tmp = [];
                        setPinArray(tmp);
                    }, 250);
                }
                if (id == 2) {
                    var pin = GetPin(tmp);
                    setTimeout(() => {
                        tmp = [];
                        setPinArray(tmp);
                    }, 250);

                    setPinArray(tmp);
                    if (pinNew1.toString() != pin.toString()) {
                        setError(lang.settings_pin_not_equal);
                        id = 1;
                    } else {
                        Press(true, pinNew1);
                    }
                }
                if (err == false) {
                    id++;
                    CreateLabel(id);
                }
            } else {
                // KONTROLA KODU
                if (props.alert == true) {
                    if (props.pin != GetPin(tmp)) {
                        setError(lang.settings_pin_error);
                        setTimeout(() => {
                            Press(true, GetPin(tmp));
                        }, 1000);
                    } else {
                        setTimeout(() => {
                            Press(true, GetPin(tmp));
                        }, 250); 
                    }
                } else {
                    setTimeout(() => {
                        Press(true, GetPin(tmp));
                    }, 250);                    
                }
            }
        }
    }

    return (
        <Backdrop open={true} style={{ backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 1000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height, marginLeft: 15 }}>
                        <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{label}</p>
                            <p style={{ ...styles.TextDialogSubLabel }}>{lang.settings_pin_code_6}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: global.theme_lighter, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, height: rowSize }}>
                        <div style={{ ...styles.Block, width: dialogWidth - 20, height: rowSize - 40, marginTop: 10, backgroundColor: global.theme_light, borderRadius: rowSize / 2, border: '2px solid ' + global.theme_dark }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                {pinArray.map((item, index) => (
                                    <FontAwesomeIcon key={index} style={{ height: 16, color: global.theme_black, marginLeft: 10, marginRight: 10 }} icon={faStarOfLife} />
                                ))}
                            </div>
                        </div>
                        <div style={{ ...styles.Block, height: 22 }}>
                            {error != '' ?
                                <p style={{ ...styles.TextTiny, fontWeight: '600', color: global.theme_dark_red }}>{error}</p>
                                : null}
                        </div>
                    </div>
                    <div style={{ ...styles.Block, flexDirection: 'row', flexWrap: 'wrap', width: '95%', height: dialogHeight - global.dialog_bar_height - rowSize }}>
                        {/* BODY */}
                        {data != false ? data.map((item, index) => (
                            <IconButton key={item.id} onClick={() => PinPress(item)} style={{ width: buttonSize, height: buttonSize, backgroundColor: '#FFFFFF40', border: '1px solid #FFFFFF', margin: 10 }}>
                                <p style={{ ...styles.TextLarge, fontWeight: item.id < 10 ? '600' : '400', fontSize: item.id < 10 ? global.font_xxlarge : global.font_xtiny }}>{item.label}</p>
                            </IconButton>
                        )) : null}
                    </div>
                </div>
            </Paper>

        </Backdrop >
    );
}