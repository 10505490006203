import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { Colors, Debug, DialogImagePreview, DialogInfo, DialogLogin, DialogVideo, DialogYesNo, EmployeeSelect, GetDatum2, GetDatumTime2, GetTimeHM, GetTodayYear, Init, Loading, Menu, MenuTitle, MenuTop, SelectYear, ShowError, ShowOK, TemplateWindow } from './items';
import { styles } from './styles';
import { Button, IconButton, MenuItem, Paper, Select, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faList, faMinus, faMinusCircle, faPen, faPlus, faPlusCircle, faQuestion, faTrash, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { CalendarBirthday } from './calendar_dialog';

export const TestConditions = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [value, setValue] = useState('');

    const [conditions, setConditions] = useState([
        {
            id: 0, operator: null, statements: [
                { id: 0, variable1: { datatype: 0, value: 123 }, operator: 0, variable2: { datatype: 0, value: 100.50 }, statement_operator: 6 },
                { id: 1, variable1: { datatype: 1, value: 1234567890 }, operator: 1, variable2: { datatype: 2, value: 'text' }, statement_operator: 7 },
                { id: 2, variable1: { datatype: 3, value: false }, operator: 1, variable2: { datatype: 4, value: '2023-09-30' }, statement_operator: 7 },
                { id: 3, variable1: { datatype: 5, value: '12:53' }, operator: 1, variable2: { datatype: 6, value: '2023-09-19 16:35:15' }, statement_operator: 6 },
                { id: 4, variable1: { datatype: 7, value: [1, 5, 100, 69, 2, 45], array_datatype: 0 }, operator: 1, variable2: { datatype: 7, value: [1, 5, 100, 69, 2, 45, 30, 40, 90, 100, 200, 300, 400], array_datatype: 1 } },
            ]
        },
        //{ id: 1, operator: 6, statements: false },
    ]);

    const no_image = require('./react/app/no_image.jpg');

    const themeColor = global.theme_dark_blue;
    const polygonSize = 80;
    const lineSize = 15;
    const rowSize = 30;
    const radius = 8;
    const paddingSize = 20;
    const maxWidth = 650;
    const decimal = 3; // POCET DESATINYCH MIEST

    let { func } = props;
    var lang = props.lang;


    useEffect(() => {
    }, []);

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const GetOperator = (id) => {
        var result = '';
        var tmp = global.operators.find(x => x.id === id);

        if (tmp != undefined) {
            result = tmp.label;
        }
        return result;
    }

    const ParseVariable = (variable) => {
        var result = '';
        if (variable != false) {
            switch (variable.datatype) {
                case 0: // Decimal 1000.000
                    result = String(parseFloat(variable.value).toFixed(decimal));
                    break;
                case 1: // Bigint
                    result = String(parseInt(variable.value));
                    break;
                case 2: // String
                    result = variable.value
                    break;
                case 3: // Boolean
                    result = variable.value == false ? 'Nepravda' : 'Pravda'
                    break;
                case 4: // Date
                    result = GetDatum2(variable.value);
                    break;
                case 5: // Time
                    result = GetTimeHM(variable.value);
                    break;
                case 6: // Datetime
                    result = GetDatumTime2(variable.value);
                    break;
                case 7: // Array                
                    variable.value.forEach(item => {
                        if (result != '') {
                            result += ', ';
                        }
                        if (variable.array_datatype == 0) {
                            // Array - Decimal                            
                            result += String(parseFloat(item).toFixed(decimal));
                        }
                        if (variable.array_datatype == 1) {
                            // Array - Bigint                            
                            result += String(parseInt(item));
                        }

                    });

                    break;
            }
        }

        return result;
    }

    const DrawLine = (props) => {
        return (
            <div style={{ ...styles.Block, width: polygonSize }}>
                <div style={{ ...styles.Block, width: 2, height: props.size == undefined ? lineSize : props.size, backgroundColor: themeColor }}></div>
            </div>
        )
    }

    const LineNumber = (props) => {
        return (
            <div style={{ ...styles.Block, width: rowSize - 2, height: rowSize - 2, borderRadius: '50%', backgroundColor: global.theme_gray, border: '1px solid ' + global.theme_dark_gray }}>
                <p style={{ ...styles.TextTiny, fontWeight: '500' }}>{props.number}</p>
            </div>
        )
    }

    const LineOperator = (props) => {
        return (
            <div style={{ ...styles.BlockLeft, justifyContent: 'center', height: 40 }}>
                <div style={{ ...styles.Block, width: rowSize, marginLeft: rowSize / 2 }}>
                    <div style={{ ...styles.Block, width: 2, height: 10, backgroundColor: global.theme_medium_gray }}></div>
                </div>
                <div style={{ ...styles.Block, width: rowSize * 2, height: 20, backgroundColor: global.theme_lighteen_blue, borderRadius: 10, paddingTop: 2, paddingBottom: 2 }}>
                    <p style={{ ...styles.TextTiny, color: global.theme_dark_blue }}>{props.operator}</p>
                </div>
                <div style={{ ...styles.Block, width: rowSize, marginLeft: rowSize / 2 }}>
                    <div style={{ ...styles.Block, width: 2, height: 10, backgroundColor: global.theme_medium_gray }}></div>
                </div>
            </div>
        )
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                        <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                    </div>
                </div>
                <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: 45 }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                        <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                    </div>
                </div>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, width: props.width, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_light_gray, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, paddingTop: 20, paddingBottom: 100, overflowY: 'scroll' }}>

                    <div style={{ ...styles.Block, width: polygonSize * 3, minHeight: polygonSize, backgroundColor: themeColor, borderRadius: radius }}>
                        <p style={{ ...styles.TextSmall, letterSpacing: 1, color: global.theme_white, fontWeight: '600' }}>Spustenie podmienky</p>
                        <div style={{ ...styles.Block, width: '90%', marginTop: 10 }}>
                            <Select
                                value={value}
                                onChange={event => setValue(event.target.value)}
                                size='small'
                                style={{ backgroundColor: '#FFFFFF', height: 38, width: '100%', borderRadius: radius }}
                                sx={{ '.MuiOutlinedInput-notchedOutline': { borderColor: '#000000' }, }}>
                                <MenuItem value={0}>{'pri načítaní údajov'}</MenuItem>
                                <MenuItem value={1}>{'pri zápise údajov'}</MenuItem>
                            </Select>
                        </div>
                    </div>

                    {/* ******************************************************************************************************************************
                        CONDITIONS - LIST
                    ****************************************************************************************************************************** */}
                    {conditions != false ? conditions.map((condition) => (
                        <div key={condition.id} style={{ ...styles.Block }}>
                            <DrawLine />
                            <div style={{ ...styles.Block, width: polygonSize + 80, minHeight: polygonSize + 40, backgroundColor: themeColor, clipPath: 'polygon(49% 0%, 51% 0%, 100% 49%, 100% 51%, 51% 100%, 49% 100%, 0% 51%, 0% 49%)', borderRadius: '50%' }}>
                                <p style={{ ...styles.TextXSmall, letterSpacing: 1, color: global.theme_white, fontWeight: '600' }}>{condition.operator == null ? 'Podmienka' : GetOperator(condition.operator)}</p>
                            </div>
                            <DrawLine />
                            <Paper elevation={3} style={{ ...styles.Block, width: '96%', maxWidth: maxWidth, backgroundColor: global.theme_white, borderRadius: radius, paddingBottom: paddingSize }}>
                                <div style={{ ...styles.BlockRow, marginTop: 5 }}>
                                    <div style={{ ...styles.Block, width: '95%' }}></div>
                                    <IconButton style={{ width: 30, height: 30, backgroundColor: global.theme_white, marginRight: 15, border: '1px solid ' + global.theme_xxgray }}>
                                        <FontAwesomeIcon style={{ height: 14, color: global.theme_black }} icon={faTrash} />
                                    </IconButton>
                                    <IconButton style={{ width: 30, height: 30, backgroundColor: global.theme_white, marginRight: 5, border: '1px solid ' + global.theme_xxgray }}>
                                        <FontAwesomeIcon style={{ height: 14, color: global.theme_black }} icon={faPen} />
                                    </IconButton>
                                </div>
                                <div style={{ ...styles.BlockLeft, width: '96%' }}>
                                    <div style={{ ...styles.BlockLeft, justifyContent: 'center', marginLeft: rowSize / 2 }}>
                                        {condition.statements != false ? condition.statements.map((statement, index) => (
                                            <div style={{ ...styles.BlockLeft }}>
                                                <div style={{ ...styles.BlockRowRaw, marginLeft: 15 }}>
                                                    <LineNumber number={index + 1} />
                                                    <div key={statement.id} style={{ ...styles.BlockRowRaw, width: undefined }}>
                                                        <div style={{ ...styles.Block, width: undefined, justifyContent: 'center', height: rowSize, marginLeft: 15 }}>
                                                            <p style={{ ...styles.TextXSmall, color: global.theme_darker_gray, textAlign: 'left' }}>{ParseVariable(statement.variable1)}</p>
                                                        </div>
                                                        <div style={{ ...styles.Block, width: undefined, justifyContent: 'center', height: rowSize, marginLeft: 15 }}>
                                                            <p style={{ ...styles.TextXSmall, color: global.theme_darker_gray }}>{GetOperator(statement.operator)}</p>
                                                        </div>
                                                        <div style={{ ...styles.BlockLeft, width: undefined, justifyContent: 'center', height: rowSize, marginLeft: 15 }}>
                                                            <p style={{ ...styles.TextXSmall, color: global.theme_darker_gray, textAlign: 'left' }}>{ParseVariable(statement.variable2)}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                                {statement.statement_operator != null ?
                                                    <LineOperator operator={GetOperator(statement.statement_operator)} />
                                                    : null}
                                            </div>
                                        )) : null}
                                    </div>

                                </div>
                            </Paper>
                        </div>
                    )) : null}

                    {/* -----------------------------------------------------------------------------------------------------------------------------
                    PRIDAT DALSIU PODMIENKU 
                    ----------------------------------------------------------------------------------------------------------------------------- */}
                    <DrawLine size={lineSize / 2} />
                    <div style={{ ...styles.Block, width: polygonSize * 3, minHeight: 40, backgroundColor: global.theme_light_gray, border: '1px solid #808080', borderRadius: radius }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <IconButton style={{ width: 30, height: 30, backgroundColor: global.theme_white, marginRight: 10 }}>
                                <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faPlus} />
                            </IconButton>
                            <div style={{ ...styles.Block, width: undefined, height: 30 }}>
                                <p style={{ ...styles.TextXSmall, color: global.theme_black }}>Ďalšia podmienka</p>
                            </div>
                        </div>
                    </div>

                    {/* -----------------------------------------------------------------------------------------------------------------------------
                    VYSLEDOK                 
                    ----------------------------------------------------------------------------------------------------------------------------- */}
                    <DrawLine size={lineSize * 3} />
                    <div style={{ ...styles.Block, width: polygonSize * 3, minHeight: 40, backgroundColor: themeColor, borderRadius: radius }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <div style={{ ...styles.Block, width: undefined, height: 30 }}>
                                <FontAwesomeIcon style={{ height: 20, color: global.theme_white, marginRight: 10 }} icon={faCheckCircle} />
                            </div>
                            <div style={{ ...styles.Block, width: undefined, height: 30 }}>
                                <p style={{ ...styles.TextXSmall, color: global.theme_white }}>Výsledok</p>
                            </div>
                            <div style={{ ...styles.Block, width: undefined, height: 30 }}>
                                <FontAwesomeIcon style={{ height: 20, color: global.theme_white, marginLeft: 10 }} icon={faXmarkCircle} />
                            </div>
                        </div>
                    </div>

                    <div style={{ ...styles.BlockRow, width: '96%' }}>
                        <div style={{ ...styles.BlockRight, width: '50%' }}>
                            <DrawLine />
                            <div style={{ ...styles.Block, width: polygonSize, minHeight: polygonSize, backgroundColor: themeColor, borderRadius: '50%' }}>
                                <p style={{ ...styles.TextSmall, letterSpacing: 1, color: global.theme_white, fontWeight: '600' }}>ÁNO</p>
                            </div>
                            <DrawLine />
                            {/* -----------------------------------------------------------------------------------------------------------------------------
                            VYSLEDOK == TRUE 
                            ----------------------------------------------------------------------------------------------------------------------------- */}
                            <Paper elevation={3} style={{ ...styles.Block, maxWidth: maxWidth, backgroundColor: global.theme_white, borderRadius: radius, paddingTop: 10, paddingBottom: 10 }}>
                                <div style={{ ...styles.BlockLeft, width: '96%' }}>
                                    <div style={{ ...styles.BlockRow, paddingBottom: 5, marginBottom: 5, borderBottom: '1px solid ' + global.theme_light_gray }}>
                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_dark_green, marginRight: 5 }} icon={faCheckCircle} />
                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>Splnená podmienka</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, paddingTop: 10, paddingBottom: 10 }}>
                                        <p style={{ ...styles.TextXSmall }}>Cena: 12,50 €</p>
                                    </div>
                                </div>
                            </Paper>
                        </div>
                        <div style={{ ...styles.BlockLeft, width: '50%', marginLeft: 20 }}>
                            <DrawLine />
                            <div style={{ ...styles.Block, width: polygonSize, minHeight: polygonSize, backgroundColor: themeColor, borderRadius: '50%' }}>
                                <p style={{ ...styles.TextSmall, letterSpacing: 1, color: global.theme_white, fontWeight: '600' }}>NIE</p>
                            </div>
                            <DrawLine />
                            {/* -----------------------------------------------------------------------------------------------------------------------------
                            VYSLEDOK == FALSE 
                            ----------------------------------------------------------------------------------------------------------------------------- */}
                            <Paper elevation={3} style={{ ...styles.Block, maxWidth: maxWidth, backgroundColor: global.theme_white, borderRadius: radius, paddingTop: 10, paddingBottom: 10 }}>
                                <div style={{ ...styles.BlockLeft, width: '96%' }}>
                                    <div style={{ ...styles.BlockRow, paddingBottom: 5, marginBottom: 5, borderBottom: '1px solid ' + global.theme_light_gray }}>
                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_dark_red, marginRight: 5 }} icon={faXmarkCircle} />
                                        <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>Nesplnená podmienka</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, paddingTop: 10, paddingBottom: 10 }}>
                                        <p style={{ ...styles.TextXSmall }}>Cena: 12,50 €</p>
                                    </div>
                                </div>
                            </Paper>
                        </div>
                    </div>

                </div >
            </div >

            {/*showDialog == true ?
                <ClientSelect employees={props.employees} user={props.user} lang={props.lang}  offset={props.offset} theme={props.theme}  radius={props.radius} func={DialogResult.bind(this)} />
            : null*/}

            {
                isBusy == true ?
                    <Loading screenWidth={props.screenWidth}></Loading>
                    : null
            }

        </Paper >
    );
}


