/*
*
* =================================================================================================================
* OBJEDNAVKY Z E-SHOPU
* =================================================================================================================
*
*/

import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation, createRoutesFromChildren } from "react-router-dom";
import { cz, sk } from './language.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark, faCircle, faBackward, faHome, faUser, faSearch, faRightFromBracket, faImage, faChevronRight, faChevronUp, faSquare, faBars, faTriangleExclamation, faInfoCircle, faTrash, faEdit, faAdd, faMessage, faQuestionCircle, faLayerGroup, faTag, faLocationDot, faBell, faCog, faList, faPlusCircle, faPlus, faCogs, faAngleLeft, faCalendarDay, faCheckDouble, faQuestion } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Drawer, Slide } from '@mui/material';
import { Alert, Autocomplete, Button, IconButton, MenuItem, Select, TextField, Paper, Tabs, Tab } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { styles } from './styles.js';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import useMediaQuery from '@mui/material/useMediaQuery';
import './items.css';
import { EmptyList, GetTodayDatum, GetHours, Today, GetDatum2, TextInput, DialogYesNo, Colors, GetImageLink, DialogImagePreview, GetTopPosition, GetDatum, GetDate, Debug } from './items.js';
import { TerminPreview } from './calendar_dialog.js';

export const DesktopEshop = (props) => {

    const [isBusy, setBusy] = useState(true);
    const [items, setItems] = useState([]);
    const [count, setCount] = useState(0);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {

        if (running == false) {
            running = true;
            db_eshop_orders();
        }
        return () => {
        };

    }, [props.employee_id, props.user]);

    const db_eshop_orders = async () => {

        try {
            const response = await fetch(
                global.db_url + 'eshop_orders_count', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    language: props.language,
                    filter: { active: true }
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                setCount(json.count);

            }
            setBusy(false);
        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ) => {
        func(typ);
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: '100%', background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius, marginTop: props.marginTop != undefined ? props.marginTop : 0 }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                        <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                    </div>
                </div>
                <div style={{ ...styles.Block, width: '85%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: 45 }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>
                        <p style={{ ...styles.TextDialogSubLabel, marginTop: 4, textAlign: 'left' }}>{props.sub_title}</p>
                    </div>
                </div>
            </div>
            <div style={{ ...styles.BlockCenter, backgroundColor: props.background == true ? global.theme_back : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, paddingBottom: props.radius }}>
                <div onClick={() => Press(true)} style={{ ...styles.Block, minHeight: 60, cursor: 'pointer', paddingTop: 10, paddingBottom: props.isSmall ? 10 : 0 }}>
                    {count > 0 ?
                        <p style={{ ...styles.TextSmall, fontWeight: '600', color: global.theme_dark_red }}>{lang.eshop_received}: {count}</p>
                        :
                        <div style={{ ...styles.Block, paddingTop: 0, height: 60 }}>
                            {isBusy == false ?
                                <EmptyList row small lang={lang} />
                                : null}
                        </div>
                    }
                </div>
            </div>
        </Paper>
    );
}
