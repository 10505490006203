/*
*
* =================================================================================================================
* LOGIN Z iPAD APKY CEZ URL
* =================================================================================================================
*
*/
import React, { useEffect, useState } from 'react';
import './App.css';
import { useParams, useNavigate } from "react-router-dom";
import { Loading, SaveLogin } from './items';
import { styles } from './styles';
import './items.css';

// IMAGES
import img_background from './react/app/background.jpg';

export const RemoteLogin = () => {
    const navigate = useNavigate();
    const [isBusy, setBusy] = useState(true);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    let params = useParams();

    useEffect(() => {
        db_token(params.token, params.code);
    }, [])

    const db_token = async (token, code) => {
        try {
            const response = await fetch(
                global.db_url + 'token_check', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token,
                    token_code: code
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.user != false) {
                    var version = 2.00;
                    if (params.version != undefined) {
                        version = parseFloat(params.version.replace('_', '.'));
                    }
                    SaveLogin({ token: token, expire: 0, version: version });
                    navigate('/application/remote');
                } else {
                    navigate('/error-404');
                }
            } else {
                navigate('/error-404');
            }


        } catch (error) {
            setBusy(false);
            console.log(error);
        }
    }



    return (
        <div style={{ ...styles.Block, height: height, backgroundImage: `url(${img_background})`, backgroundSize: 'cover' }}>
            {isBusy == true ?
                <Loading screenWidth={width} offset={0} center={true}></Loading>
                : null}
        </div>
    );
}


