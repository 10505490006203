import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { CategorySelect, Colors, ConvertToPrice, CopyData, Debug, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, GetDate, GetImageLink, GetPrice, GetTodayYear, GetTodayYearMonth, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, ShowError, ShowOK, TemplateWindow, Today } from './items';
import { styles } from './styles';
import { Backdrop, Button, CircularProgress, IconButton, InputAdornment, Pagination, Paper, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCheckCircle, faEdit, faL, faLayerGroup, faList, faListAlt, faPaperPlane, faQuestion, faSave, faShare, faShoppingBag, faShoppingBasket, faTrash, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Graph } from './graph';


export const StatsProductsBooking = (props) => {
    const [isBusy, setBusy] = useState(false);

    const [actualDate, setActualDate] = useState(false);
    const [actualYear, setActualYear] = useState(GetTodayYear());
    const [data, setData] = useState(false);
    const [graphData, setGraphData] = useState(false);
    const [items, setItems] = useState(false);
    const [sum, setSum] = useState(0);
    const [cost, setCost] = useState(0);
    const [label, setLabel] = useState('');

    // EXPORT STATISTIKY
    const [showExport, setShowExport] = useState(false);

    // OK
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);


    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const rowHeight = 80;
    const rowWidth = props.width < 800 ? props.width : 800;

    const no_image = require('./react/app/no_image.jpg');

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            let date = GetTodayYearMonth();
            setActualDate(date);
            db_get(date);
        }

    }, []);

    const db_get = async (date) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stats_booking', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    language: props.language,
                    date: date
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setData(json);
                setGraphData(json.graph_data);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_export = async () => {
        Debug(actualYear);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stats_booking_export', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    language: props.language,
                    date: actualYear,
                    lang: {
                        text: lang.products_export_email,
                        do_not_reply: lang.do_not_reply,
                        do_not_reply_text: lang.do_not_reply_text,
                        label: lang.stats_booking_text,
                        sub_label: lang.year + ': ' + actualYear,
                        created: lang.created,

                        email: lang.email_,
                        mobil: lang.mobil.toLowerCase(),
                        firm: lang.settings_company,
                        ico: lang.ico,
                        dic: lang.dic,
                        ic_dph: lang.ic_dph,

                        products_list: lang.products_list,
                        product_name: lang.product_name,
                        code: lang.code_,
                        cost: lang.cost,
                        price: lang.price,
                        stock_items: lang.stock_items,

                        money: lang.money,
                        pieces: lang.pieces,

                        ie: lang.institut,
                        cabine: lang.cabine,
                        products_mine: lang.products_mine,
                        booking_product_ordered: lang.booking_product_ordered,
                        bonus_from_credit_pieces: lang.bonus_from_credit_pieces,
                        bonus_pay_sum: lang.bonus_pay_sum,

                        products: lang.products,
                        cabine: lang.cabine,
                        bonus: lang.bonus,

                        sumar: lang.sumar
                    }
                })
            })

            const json = await response.json();

            setBusy(false);
            Debug(json);

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }


        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = () => {
        func(false);
    }

    const GraphResult = (id, value, value2) => {
        if (id == -2) {
            // ZMENA ROKA
            setItems(false);
            setActualYear(value);
            db_get(value + '-' + value2.toString().padStart(2, '0'));
        }
        if (id == -1) {
            // STLPEC - deselect
            setItems(false);
        }
        if (id >= 0) {
            // OZNACENY STLPEC
            let sum_ = 0;
            let cost_ = 0;
            var tmp = graphData.find(x => x.id == id);
            setLabel(tmp.label);

            if (value == 0) {
                tmp.products1.sort((a, b) => {
                    if (parseInt(a.quantity) < parseInt(b.quantity)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })

                tmp.products1.forEach(item => {
                    cost_ = cost_ + parseFloat(item.cost_sum);
                });

                setCost(cost_);
                if (tmp != undefined) {
                    setItems(tmp.products1);
                    GotoStart();
                }
            } else {
                tmp.products2.sort((a, b) => {
                    if (parseInt(a.quantity) < parseInt(b.quantity)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })

                tmp.products2.forEach(item => {
                    cost_ = cost_ + parseFloat(item.cost_sum);
                });

                setCost(cost_);
                if (tmp != undefined) {
                    setItems(tmp.products2);
                    GotoStart();
                }
            }
        }
    }

    const GotoStart = () => {
        setTimeout(() => {
            const element = document.getElementById('view_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 300);
    }

    const ExportResult = (value) => {
        setShowExport(false);

        if (value == true) {
            db_export();
        }
    }

    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                        <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                    </div>
                </div>
                <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                        <p style={{ ...styles.TextDialogSubLabel, marginTop: 4, textAlign: 'left' }}>{props.sub_title}</p>
                    </div>
                </div>
                {props.isSmall == true ?
                    <div style={{ ...styles.Block, width: props.isSmall ? 80 : 80, height: global.dialog_bar_height, alignItems: props.isSmall ? 'center' : 'flex-end' }}>
                        {props.user.typ == global.account_salon ?
                            <IconButton onClick={() => setShowExport(true)} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                                <FontAwesomeIcon style={{ width: 14, color: global.themes[props.theme].theme_dark }} icon={faShare} />
                            </IconButton>
                            : null}
                    </div>
                    :
                    <div style={{ ...styles.BlockRight, width: 200, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <Button onClick={() => setShowExport(true)} style={{ ...styles.ButtonDarkSmall, backgroundColor: global.themes[props.theme].theme_medium, color: global.theme_white, width: 150 }}>
                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPaperPlane} />
                            {lang.export}
                        </Button>
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Press()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, width: props.width, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                    <div id={'view_start'}></div>
                    {/* *********************************************************************************************************  
                                BODY
                        ********************************************************************************************************* */}
                    {data != false ?
                        <div style={{ ...styles.BlockLeft, width: props.isSmall ? '96%' : rowWidth }}>
                            <p style={{ ...styles.TextSmall, fontWeight: '600', marginTop: 10 }}>{lang.stats_sum_dph} {GetPrice(ConvertToPrice(data.cost))} {lang.money}</p>
                        </div>
                        : null}
                    <div style={{ ...styles.BlockCenter, width: rowWidth, marginTop: 20 }}>
                        <Graph
                            lang={props.lang}
                            elevation={0}
                            readius={0}
                            title={lang.stats_booking_label}
                            sub_title={''}
                            width={props.isSmall ? width - 10 : rowWidth}
                            height={400}
                            typ={0}
                            data={graphData}
                            barWidth={props.isSmall ? 25 : 60}
                            highColor={global.theme_red}
                            overColor={global.theme_blue}
                            showMax={true}
                            lines={true}
                            units={lang.pieces}
                            legend={false}
                            marginTop={10}
                            selectable={true}
                            show_value={true}
                            year={true}
                            isSmall={props.isSmall}
                            func={GraphResult.bind(this)}
                        />
                        <p style={{ ...styles.TextTiny, marginTop: 5, color: global.theme_dark_gray }}>{lang.graph_click}</p>
                        <div style={{ ...styles.Block, maxWidth: rowWidth, marginTop: 20, paddingBottom: 30 }}>
                            {items.length > 0 ?
                                <div style={{ ...styles.Block }}>
                                    <p style={{ ...styles.TextTiny }}>{lang.products_list} ({label})</p>
                                    <p style={{ ...styles.TextTiny, fontWeight: '600', marginTop: 10 }}>{lang.booking_sum}: {GetPrice(ConvertToPrice(cost))} {lang.money}</p>
                                </div>
                                : null}
                            {
                                // ZOZNAM PRODUKTOV / SLUZIEB
                                items != false ?
                                    items.map((item, index) => (
                                        <Paper elevation={2} key={item.id} style={{ ...styles.Block, height: rowHeight, backgroundColor: global.theme_light_gray, marginTop: 5, marginBottom: 5, borderRadius: rowHeight }}>
                                            <div style={{ ...styles.BlockRow }}>
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                    <img src={item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', width: (rowHeight) - 10, height: (rowHeight) - 10, objectFit: 'contain', borderRadius: (rowHeight) - 10, marginLeft: 5 }}></img>
                                                </div>
                                                <div style={{ ...styles.BlockLeft, height: (rowHeight), width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                    <div style={{ ...styles.BlockLeft, borderBottom: '1px solid ' + global.theme_medium_gray, paddingBottom: 5 }}>
                                                        <div style={{ ...styles.BlockLeft }}>
                                                            <p style={{ ...props.isSmall ? styles.TextTiny : styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, marginTop: 5 }}>
                                                        <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.quantity}: {item.quantity}</p>
                                                        {item.quantity_credit != undefined ?
                                                            <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.bonus_from_credit_pieces}: {item.quantity_credit}</p>
                                                            : null}
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.Block, height: (rowHeight), width: 150 }}>
                                                    {item.quantity_credit != undefined ?
                                                        <p style={{ ...styles.TextXTiny, textAlign: 'center' }}>{lang.bonus_pay_sum}</p>
                                                        : null}
                                                    <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{GetPrice(ConvertToPrice(GetPrice(ConvertToPrice(item.cost_sum))))} {lang.money}</p>
                                                    <p style={{ ...styles.TextXTiny, textAlign: 'center', color: global.theme_dark_gray }}>{lang.with_dph}</p>
                                                </div>
                                            </div>
                                        </Paper>
                                    ))
                                    :
                                    null}
                        </div>
                    </div>
                </div>

            </div>

            {showExport == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.export_stats} sub_title={''} text={lang.export_agent_data_ask} sub_text={lang.export_agent_data_text + ' ' + props.user.email} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={ExportResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK offset={props.offset} func={() => setShowOK(false)} />
                : null}

            {showError == true ?
                <ShowError offset={props.offset} text={lang.db_error} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme}  ></Loading>
                : null}

        </Paper >
    );
}
