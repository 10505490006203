import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { Init, Loading, Menu, MenuTitle, MenuTop, TemplateWindow } from './items';
import { styles } from './styles';
import { Button, IconButton, Paper, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faB, faCakeCandles, faCalendarCheck, faCalendarDays, faList, faListCheck, faP, faQuestion, faShoppingBag, faShoppingBasket, faShoppingCart, faXmark } from '@fortawesome/free-solid-svg-icons';
import './items.css';
import { DesktopBanner, DesktopBirthday, DesktopKalendar, DesktopTodo } from './desktop_dialogs';
import { DesktopReserve } from './desktop_reserve';
import { DesktopEshop } from './desktop_eshop';
import { DesktopProductsOrders } from './desktop_products_orders';
import { DesktopBonusOrders } from './desktop_bonus_orders';
import { DesktopReklamacie } from './desktop_reklamacie';
import { DesktopNews } from './desktop_news';

export const Desktop = (props) => {
    const [employeeID, setEmployeeID] = useState(props.employee_id);
    const [width, setWidth] = useState(props.width);

    let { func } = props;
    var lang = props.lang;


    useEffect(() => {

        setEmployeeID(props.employee_id)
        setWidth(props.width);

    }, [props.employee_id, props.user, props.width, props.country])

    const Press = (typ, value) => {
        func(typ, value);
    }


    const DesktopKalendarResult = () => {

    }

    const DesktopBirthdayResult = (item) => {
        Press(2, item);
    }

    const DesktopReserveResult = () => {

    }

    const DesktopEshopResult = (typ) => {
        Press(1, typ);
    }

    const DesktopProductsResult = (typ) => {
        Press(3, typ);
    }

    const DesktopBonusResult = (typ) => {
        Press(4, typ);
    }

    const DesktopReklamacieResult = (typ) => {
        Press(5, typ);
    }

    return (props.user != false ?
        props.user.typ == global.account_salon ?
            props.isSmall == true || props.isMedium == true ?
                // ------------------------------------------------------------------------------
                // SMALL / MEDIUM SCREEN - SALON
                // ------------------------------------------------------------------------------
                <div style={{ ...styles.BlockLeft, width: props.width, height: undefined, borderRadius: props.radius, marginTop: props.offset, marginBottom: 30, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll', paddingBottom: props.offset }} className='container'>
                    {props.banner != false ?
                        (props.user.demo == true && props.languageManual == false) || props.user.demo == false ?
                            <DesktopBanner isSmall={props.isSmall} theme={props.theme} banner={props.banner} radius={props.radius} lang={props.lang} />
                            : null : null}

                    <DesktopNews countries={props.countries} country={props.country} isSmall={props.isSmall} admin={props.admin} employees={props.employees} employee_id={employeeID} user={props.user} lang={lang} language={props.language} marginTop={global.smallOffset * 2} radius={props.radius} title={props.lang.news_} sub_title={props.lang.vision_system} icon={faShoppingCart} background={true} offset={props.offset} theme={props.theme} func={DesktopProductsResult.bind(this)} />


                    {props.settings != false ? props.settings.desktop_settings.desktop.reserve == true && props.user.calendar_public == true ?
                        <DesktopReserve isSmall={props.isSmall} employees={props.employees} employee_id={employeeID} user={props.user} lang={lang} language={props.language} marginTop={global.smallOffset * 2} radius={props.radius} title={props.lang.reserve_label} sub_title={props.lang.reserve_desktop_label} icon={faCalendarCheck} background={true} offset={props.offset} theme={props.theme} func={DesktopReserveResult.bind(this)} />
                        : null : null}
                    {props.settings != false ? props.settings.desktop_settings.desktop.eshop == true ?
                        <DesktopEshop isSmall={props.isSmall} employees={props.employees} employee_id={employeeID} user={props.user} lang={lang} language={props.language} marginTop={global.smallOffset * 2} radius={props.radius} title={props.lang.eshop_history} sub_title={props.lang.eshop} icon={faShoppingCart} background={true} offset={props.offset} theme={props.theme} func={DesktopEshopResult.bind(this)} />
                        : null : null}
                    {props.settings != false ? props.settings.desktop_settings.desktop.calendar == true ?
                        <DesktopKalendar isSmall={props.isSmall} employees={props.employees} employee_id={employeeID} user={props.user} lang={lang} language={props.language} marginTop={global.smallOffset * 2} radius={props.radius} title={props.lang.calendar} sub_title={props.lang.calendar_todays} icon={faCalendarDays} background={true} offset={props.offset} theme={props.theme} func={DesktopKalendarResult.bind(this)} />
                        : null : null}
                    {props.settings != false ? props.settings.desktop_settings.desktop.birthday == true ?
                        <DesktopBirthday isSmall={props.isSmall} employees={props.employees} employee_id={employeeID} user={props.user} lang={lang} language={props.language} marginTop={global.smallOffset * 2} radius={props.radius} title={props.lang.birthday} sub_title={props.lang.today} icon={faCakeCandles} background={true} offset={props.offset} theme={props.theme} func={DesktopBirthdayResult.bind(this)} />
                        : null : null}
                    {props.settings != false ? props.settings.desktop_settings.desktop.todo == true ?
                        <DesktopTodo isSmall={props.isSmall} lang={lang} language={props.language} employee_id={employeeID} user={props.user} marginTop={global.smallOffset * 2} radius={props.radius} title={props.lang.todo} sub_title={props.lang.todo_text} icon={faListCheck} background={true} offset={props.offset} theme={props.theme} func={DesktopKalendarResult.bind(this)} />
                        : null : null}
                </div>
                :
                // ------------------------------------------------------------------------------
                // LARGE SCREEN
                // ------------------------------------------------------------------------------
                <div style={{ width: props.width, height: props.height - (1 * props.offset), borderRadius: props.radius, marginTop: props.offset, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    <div style={{ ...styles.BlockRow, paddingTop: 0, paddingBottom: 0 }}>
                        <div style={{ ...styles.BlockLeft, width: '50%', marginRight: props.offset }}>
                            {props.banner != false ?
                                (props.user.demo == true && props.languageManual == false) || props.user.demo == false ?
                                    <DesktopBanner theme={props.theme} banner={props.banner} language={props.language} radius={props.radius} lang={props.lang} />
                                    : null : null}

                            <DesktopNews countries={props.countries} country={props.country} isSmall={props.isSmall} admin={props.admin} employees={props.employees} employee_id={employeeID} user={props.user} lang={lang} language={props.language} marginTop={global.smallOffset * 2} radius={props.radius} title={props.lang.news_} sub_title={props.lang.vision_system} icon={faShoppingCart} background={true} offset={props.offset} theme={props.theme} func={DesktopProductsResult.bind(this)} />

                            {props.settings != false ? props.settings.desktop_settings.desktop.reserve == true && props.user.calendar_public == true ?
                                <DesktopReserve isSmall={props.isSmall} employees={props.employees} employee_id={employeeID} user={props.user} lang={lang} language={props.language} marginTop={global.smallOffset * 2} radius={props.radius} title={props.lang.reserve_label} sub_title={props.lang.reserve_desktop_label} icon={faCalendarCheck} background={true} offset={props.offset} theme={props.theme} func={DesktopReserveResult.bind(this)} />
                                : null : null
                            }
                            {props.settings != false ? props.settings.desktop_settings.desktop.calendar == true ?
                                <DesktopKalendar isSmall={props.isSmall} employees={props.employees} employee_id={employeeID} user={props.user} lang={lang} language={props.language} marginTop={props.offset} radius={props.radius} title={props.lang.calendar} sub_title={props.lang.calendar_todays} icon={faCalendarDays} background={true} offset={props.offset} theme={props.theme} func={DesktopKalendarResult.bind(this)} />
                                : null : null
                            }
                        </div>
                        <div style={{ ...styles.BlockLeft, width: '50%', marginTop: 0 }}>
                            {props.settings != false ? props.settings.desktop_settings.desktop.eshop == true ?
                                <DesktopEshop isSmall={props.isSmall} employees={props.employees} employee_id={employeeID} user={props.user} lang={lang} language={props.language} radius={props.radius} title={props.lang.eshop_history} sub_title={props.lang.eshop} icon={faShoppingCart} background={true} offset={props.offset} theme={props.theme} func={DesktopEshopResult.bind(this)} />
                                : null : null}
                            {props.settings != false ? props.settings.desktop_settings.desktop.birthday == true ?
                                <DesktopBirthday isSmall={props.isSmall} lang={lang} language={props.language} employee_id={employeeID} user={props.user} radius={props.radius} marginTop={global.smallOffset * 2} title={props.lang.birthday} sub_title={props.lang.today} icon={faCakeCandles} background={true} offset={props.offset} theme={props.theme} func={DesktopBirthdayResult.bind(this)} />
                                : null : null}
                            {props.settings != false ? props.settings.desktop_settings.desktop.todo == true ?
                                <DesktopTodo isSmall={props.isSmall} lang={lang} language={props.language} employee_id={employeeID} user={props.user} marginTop={props.offset} radius={props.radius} title={props.lang.todo} sub_title={props.lang.todo_text} icon={faListCheck} background={true} offset={props.offset} theme={props.theme} func={DesktopKalendarResult.bind(this)} />
                                : null : null}
                        </div>
                    </div>
                </div>
            :
            props.isSmall == true || props.isMedium == true ?
                // ------------------------------------------------------------------------------
                // SMALL / MEDIUM SCREEN - AGENT
                // ------------------------------------------------------------------------------
                <div style={{ ...styles.BlockLeft, width: props.width, height: undefined, borderRadius: props.radius, marginTop: props.offset, marginBottom: 30, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll', paddingBottom: props.offset }} className='container'>
                    {props.banner != false ?
                        (props.user.demo == true && props.languageManual == false) || props.user.demo == false ?
                            <DesktopBanner isSmall={props.isSmall} theme={props.theme} banner={props.banner} radius={props.radius} lang={props.lang} />
                            : null : null}

                    <DesktopNews countries={props.countries} country={props.country} isSmall={props.isSmall} admin={props.admin} employees={props.employees} employee_id={employeeID} user={props.user} lang={lang} language={props.language} marginTop={global.smallOffset * 2} radius={props.radius} title={props.lang.news_} sub_title={props.lang.vision_system} icon={faShoppingCart} background={true} offset={props.offset} theme={props.theme} func={DesktopProductsResult.bind(this)} />

                    {props.settings != false ? props.settings.desktop_settings.desktop.products == true ?
                        <DesktopProductsOrders countries={props.countries} country={props.country} isSmall={props.isSmall} admin={props.admin} employees={props.employees} employee_id={employeeID} user={props.user} lang={lang} language={props.language} marginTop={global.smallOffset * 2} radius={props.radius} title={props.lang.sc_salon_booking_products} sub_title={props.lang.vision_system} icon={faP} background={true} offset={props.offset} theme={props.theme} func={DesktopProductsResult.bind(this)} />
                        : null : null}

                    {props.settings != false ? props.settings.desktop_settings.desktop.bonus == true ?
                        <DesktopBonusOrders countries={props.countries} country={props.country} isSmall={props.isSmall} admin={props.admin} employees={props.employees} employee_id={employeeID} user={props.user} lang={lang} language={props.language} marginTop={global.smallOffset * 2} radius={props.radius} title={props.lang.sc_salon_booking_bonus} sub_title={props.lang.vision_system} icon={faB} background={true} offset={props.offset} theme={props.theme} func={DesktopBonusResult.bind(this)} />
                        : null : null}

                    {props.settings != false ? props.settings.desktop_settings.desktop.reklamacie == true ?
                        <DesktopReklamacie countries={props.countries} country={props.country} isSmall={props.isSmall} admin={props.admin} employees={props.employees} employee_id={employeeID} user={props.user} lang={lang} language={props.language} marginTop={global.smallOffset * 2} radius={props.radius} title={props.lang.reklamacie_products_bonus} sub_title={props.lang.vision_system} icon={faB} background={true} offset={props.offset} theme={props.theme} func={DesktopReklamacieResult.bind(this)} />
                        : null : null}

                    {props.settings != false ? props.settings.desktop_settings.desktop.todo == true ?
                        <DesktopTodo countries={props.countries} country={props.country} isSmall={props.isSmall} lang={lang} language={props.language} employee_id={employeeID} user={props.user} marginTop={global.smallOffset * 2} radius={props.radius} title={props.lang.todo} sub_title={props.lang.todo_text} icon={faListCheck} background={true} offset={props.offset} theme={props.theme} func={DesktopKalendarResult.bind(this)} />
                        : null : null}

                </div>
                :
                // ------------------------------------------------------------------------------
                // LARGE SCREEN
                // ------------------------------------------------------------------------------
                <div style={{ width: props.width, height: props.height - (1 * props.offset), borderRadius: props.radius, marginTop: props.offset, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    <div style={{ ...styles.BlockRow, paddingTop: 0, paddingBottom: 0 }}>
                        <div style={{ ...styles.BlockLeft, width: '50%', marginRight: props.offset }}>
                            {props.banner != false ?
                                (props.user.demo == true && props.languageManual == false) || props.user.demo == false ?
                                    <DesktopBanner theme={props.theme} banner={props.banner} admin={props.admin} language={props.language} radius={props.radius} lang={props.lang} />
                                    : null : null}
                            <DesktopNews countries={props.countries} country={props.country} isSmall={props.isSmall} admin={props.admin} employees={props.employees} employee_id={employeeID} user={props.user} lang={lang} language={props.language} marginTop={global.smallOffset * 2} radius={props.radius} title={props.lang.news_} sub_title={props.lang.vision_system} icon={faShoppingCart} background={true} offset={props.offset} theme={props.theme} func={DesktopProductsResult.bind(this)} />

                            {props.settings != false ? props.settings.desktop_settings.desktop.products == true ?
                                <DesktopProductsOrders countries={props.countries} country={props.country} isSmall={props.isSmall} admin={props.admin} employees={props.employees} employee_id={employeeID} user={props.user} lang={lang} language={props.language} marginTop={global.smallOffset * 2} radius={props.radius} title={props.lang.sc_salon_booking_products} sub_title={props.lang.vision_system} icon={faP} background={true} offset={props.offset} theme={props.theme} func={DesktopProductsResult.bind(this)} />
                                : null : null}
                            {props.settings != false ? props.settings.desktop_settings.desktop.bonus == true ?
                                <DesktopBonusOrders countries={props.countries} country={props.country} isSmall={props.isSmall} admin={props.admin} employees={props.employees} employee_id={employeeID} user={props.user} lang={lang} language={props.language} marginTop={global.smallOffset * 2} radius={props.radius} title={props.lang.sc_salon_booking_bonus} sub_title={props.lang.vision_system} icon={faB} background={true} offset={props.offset} theme={props.theme} func={DesktopBonusResult.bind(this)} />
                                : null : null}

                            {props.settings != false ? props.settings.desktop_settings.desktop.reklamacie == true ?
                                <DesktopReklamacie countries={props.countries} country={props.country} isSmall={props.isSmall} admin={props.admin} employees={props.employees} employee_id={employeeID} user={props.user} lang={lang} language={props.language} marginTop={global.smallOffset * 2} radius={props.radius} title={props.lang.reklamacie_products_bonus} sub_title={props.lang.vision_system} icon={faB} background={true} offset={props.offset} theme={props.theme} func={DesktopReklamacieResult.bind(this)} />
                                : null : null}

                        </div>
                        <div style={{ ...styles.BlockLeft, width: '50%', marginTop: 0 }}>
                            {props.settings != false ? props.settings.desktop_settings.desktop.todo == true ?
                                <DesktopTodo countries={props.countries} country={props.country} isSmall={props.isSmall} admin={props.admin} lang={lang} language={props.language} employee_id={employeeID} user={props.user} marginTop={0} radius={props.radius} title={props.lang.todo} sub_title={props.lang.todo_text} icon={faListCheck} background={true} offset={props.offset} theme={props.theme} func={DesktopKalendarResult.bind(this)} />
                                : null : null}
                        </div>
                    </div>
                </div>
        : null
    );
}


