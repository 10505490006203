import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { ConvertToPrice, CopyData, Debug, DialogAgreements, DialogEmployee, DialogInfo, DialogLogin, DialogPasswordChange, DialogWarningYesNo, DialogYesNo, FormError, GetLanguage, GetMinutesFromHours, GetVersion, Init, IsEmpty, Loading, Menu, MenuTitle, MenuTop, nonEmail, notNumber, notTime, ResetLogin, ShowError, ShowOK, ShowTokenError, TemplateWindow, TextInput } from './items';
import { styles } from './styles';
import { Button, Checkbox, Fade, FormControlLabel, IconButton, MenuItem, Paper, Radio, RadioGroup, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCheckCircle, faCog, faExclamationTriangle, faEye, faInfoCircle, faList, faPen, faPlus, faQuestion, faSave, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import './items.css';
import { PinCode } from './pin_code';


export const Settings = (props) => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);
    const [loading, setLoading] = useState(true);

    const [index, setIndex] = useState(-1);
    const [showLogout, setShowLogout] = useState(false);
    const [overID, setOverID] = useState(-1);

    const [error, setError] = useState(false);
    const [edit, setEdit] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    // ZAKLADNE UDAJE
    const [userData, setUserData] = useState();
    const [settings, setSettings] = useState({});

    // ZAMESTNANCI
    const [employeeEditing, setEmployeeEditing] = useState(false);
    const [employeeSelected, setEmployeeSelected] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [employeesFiltered, setEmployeesFiltered] = useState([]);
    const [showEmployee, setShowEmployee] = useState(false);

    // VERNOSTNY SYSTEM
    const [bonusEnable, setBonusEnable] = useState(false);
    const [bonusPoints, setBonusPoints] = useState('0');
    const [bonusPrice, setBonusPrice] = useState('0');

    // OBCHODNY ZASTUPCA
    const [agents, setAgents] = useState(false);
    const [agent, setAgent] = useState(0);
    const [agentItem, setAgentItem] = useState(false);

    // WEB + ESHOP
    const [webEnabled, setWebEnabled] = useState(false);
    const [alias, setAlias] = useState('');
    const [aliasName, setAliasName] = useState('');
    const [aliasEdit, setAliasEdit] = useState(false);
    const [aliasError, setAliasError] = useState('');
    const [eshopEnabled, setEshopEnabled] = useState(false);
    const [tradeID, setTradeID] = useState(0);
    const [tradeEnabled, setTradeEnabled] = useState(false);
    const [tradeName, setTradeName] = useState('');
    const [tradeEur, setTradeEur] = useState('0');
    const [tradeText, setTradeText] = useState('');
    const [tradeFree, setTradeFree] = useState(false);
    const [tradeFreeEur, setTradeFreeEur] = useState('100.00');
    const [paymentBank, setPaymentBank] = useState(false);
    const [publicServices, setPublicServices] = useState(false);
    const [publicServicesPrices, setPublicServicesPrices] = useState(false);

    // REZERVACIE
    const [reserveID, setReserveID] = useState(0);
    const [reserveEnabled, setReserveEnabled] = useState(false);
    const [reserveEqual, setReserveEqual] = useState(false);
    const [reserveTime, setReserveTime] = useState('0');
    const [reserveHours, setReserveHours] = useState(false);

    // PIN KÓD
    const [pinEnabled, setPinEnabled] = useState(false);
    const [pin, setPin] = useState('');
    const [showPin, setShowPin] = useState(false);
    const [pinType, setPinType] = useState(1);

    // FORUM - DISKUSIA
    const [forumEnabled, setForumEnabled] = useState(false);
    const [forum, setForum] = useState(0);

    // NOTIFIKACIE ZAKAZNIKOM S PRIPOMIENKOU
    const [calendarNotify, setCalendarNotify] = useState(false);

    // DATABASE STATUS
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showTokenError, setShowTokenError] = useState(false);

    // EMAILOVA ADRESA UZ EXISTUJE
    const [showEmailError, setShowEmailError] = useState(false);

    // ZRUSENIE UCTU
    const [showAccountCancel, setShowAccountCancel] = useState(false);
    const [accountCancelTyp, setAccountCancelTyp] = useState(0);
    const [showAccountCancelOK, setShowAccountCancelOK] = useState(false);

    // GDPR
    const [showGDPR, setShowGDPR] = useState(false);
    const [showVOP, setShowVOP] = useState(false);

    const avatar_man = require('./react/app/man.png');
    const avatar_woman = require('./react/app/woman.png');


    let { func } = props;
    var lang = props.lang;

    const menu_width = props.isSmall ? 0 : 200;
    const rowOffset = 20;
    const rowHeight = 70;
    const rowWidth = props.isSmall ? props.width - 20 : 700;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            db_user();
        }
    }, [])


    const db_user = async () => {
        try {
            const response = await fetch(
                global.db_url + 'user', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.user.id,
                    token: props.token
                })
            })

            const json = await response.json();

            setLoading(false);
            if (json.ok > 0) {
                if (json.user != false) {
                    setUserData(json.user);
                    setSettings(json.settings);

                    if (json.user.typ == global.account_salon) {
                        setIndex(0);
                    } else {
                        setIndex(20);
                    }

                    setBonusEnable(json.user.score == false ? false : true);
                    setBonusPoints(json.user.bonus_points);
                    setBonusPrice(json.user.bonus_price);

                    var tmp = json.agents;
                    //var tmp = json.agents.filter(x => x.language == json.user.language);
                    setAgents(tmp);

                    if (json.user.agent_id > 0) {
                        tmp = json.agents.find(x => x.id == json.user.agent_id);
                        if (tmp == undefined) {
                            setAgent(0);
                            setAgentItem(false);
                        } else {
                            setAgent(json.user.agent_id);
                            setAgentItem(tmp);
                        }
                    }

                    // WEB A ESHOP
                    setAlias(json.user.alias);
                    setWebEnabled(json.user.eshop == false ? false : true);
                    setEshopEnabled(json.user.eshop_ready == false ? false : true);
                    setPublicServices(json.user.public_services == false ? false : true);
                    setPublicServicesPrices(json.user.public_prices == false ? false : true);

                    if (json.settings != false) {
                        setTradeEnabled(json.settings.trade_enabled == false ? false : true);
                        setTradeFree(json.settings.trade_free_enabled == false ? false : true);
                        setTradeFreeEur(json.settings.trade_free_price);
                        setPaymentBank(json.settings.payment_enabled == false ? false : true);
                    } else {

                    }
                    if (json.trade != false) {
                        setTradeID(json.trade.id);
                        setTradeName(json.trade.label);
                        setTradeText(json.trade.note);
                        setTradeEur(json.trade.price);
                    } else {
                        setTradeText(lang.trade_text);
                    }

                    if (json.reserve != false) {
                        setReserveID(json.reserve.id);
                        setReserveEnabled(json.user.calendar_public == false ? false : true);
                        setReserveEqual(json.reserve.equal == false ? false : true);
                        setReserveHours(JSON.parse(json.reserve.data));
                        setReserveTime(json.user.reserve_break_time);
                    } else {
                        let data = [
                            { id: 0, od: '', do: '', obed_od: '', obed_do: '' },
                            { id: 1, od: '08:00', do: '17:00', obed_od: '12:00', obed_do: '12:30' },
                            { id: 2, od: '08:00', do: '17:00', obed_od: '12:00', obed_do: '12:30' },
                            { id: 3, od: '08:00', do: '17:00', obed_od: '12:00', obed_do: '12:30' },
                            { id: 4, od: '08:00', do: '17:00', obed_od: '12:00', obed_do: '12:30' },
                            { id: 5, od: '08:00', do: '17:00', obed_od: '12:00', obed_do: '12:30' },
                            { id: 6, od: '08:00', do: '12:00', obed_od: '', obed_do: '' },
                        ];
                        setReserveHours(data);
                    }

                    // PIN KOD
                    setPinEnabled(json.user.protect == false ? false : true);
                    setPin(json.user.pin);

                    // FORUM - DISKUSIA
                    setForumEnabled(json.user.forum_enabled == false ? false : true);
                    setForum(json.user.forum_max);

                    // KALENDAR - NOTIFIKACIE
                    setCalendarNotify(json.user.calendar_notify == false ? false : true);
                }
            } else {
                setShowTokenError(true);
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const db_user_update = async (data) => {
        try {
            const response = await fetch(
                global.db_url + 'user_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
                setEdit(false);

                if (data.alias != undefined) {
                    setAliasEdit(false);
                    setAlias(data.alias);
                }
            } else {
                setShowError(true);
            }
            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }


    const db_check_email = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'check_email_exists', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: data.id,
                    email: data.email
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                if (json.count == 0) {
                    db_user_update(data);
                } else {
                    setShowEmailError(true);
                }
            }


        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_employees = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'employees', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.employees != false) {
                    var tmp = json.items;
                    setEmployees(tmp);
                    setEmployeesFiltered(tmp);
                } else {
                    setEmployees(false);
                    setEmployeesFiltered(false);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_settings_update = async (user_data, settings_data, trade_data, reserve_data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'settings_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_data: user_data,
                    settings_data: settings_data,
                    trade_data: trade_data,
                    reserve_data: reserve_data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_account_cancel = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'account_cancel', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    typ: accountCancelTyp,
                    language: lang.language,
                    label: lang.account_cancel_label,
                    text: accountCancelTyp == 0 ? lang.account_cancel_option_1 : accountCancelTyp == 1 ? lang.account_cancel_option_2 : lang.account_cancel_option_3,
                    sub_text: accountCancelTyp == 0 ? lang.account_cancel_option_1_ : accountCancelTyp == 1 ? lang.account_cancel_option_2_ : lang.account_cancel_option_3_
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.sended == true) {
                    setShowAccountCancelOK(true);
                } else {
                    setShowError(true);
                }
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_alias_check = async (alias_) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'alias_check', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.user.id,
                    alias: alias_
                })
            })

            const json = await response.json();

            setBusy(false);
            if (json.ok > 0) {
                if (json.count > 0) {
                    // ERROR
                    setAliasError(lang.alias_error);
                } else {
                    // OK
                    var data = {
                        id: props.user.id,
                        alias: alias_,
                    };
                    db_user_update(data);
                }
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = () => {
        func();
    }

    const Close = () => {
        Press();
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const Logout = (value) => {
        setShowLogout(false);
        if (value == true) {
            ResetLogin();
            navigate('/logout');
        }
    }

    const Save = (index) => {
        var error = false;
        setError('');

        if (index == 0) {
            error += IsEmpty(userData.name) == true ? true : false;
            error += IsEmpty(userData.surname) == true ? true : false;
            error += IsEmpty(userData.email) == true ? true : false;
            error += nonEmail(userData.email) == true ? true : false;
            error += IsEmpty(userData.salon_name) == true ? true : false;
            error += IsEmpty(userData.salon_street) == true ? true : false;
            error += IsEmpty(userData.salon_street_number) == true ? true : false;
            error += IsEmpty(userData.salon_psc) == true ? true : false;
            error += IsEmpty(userData.salon_town) == true ? true : false;

            if (error > 0) {
                setError(lang.form_error);
            } else {
                // SAVE DATA - ZAKLADNE UDAJE               
                var data = {
                    id: props.user.id,
                    name: userData.name,
                    surname: userData.surname,
                    email: userData.email,
                    mobil: userData.mobil,
                    salon_name: userData.salon_name,
                    salon_street: userData.salon_street,
                    salon_street_number: userData.salon_street_number,
                    salon_psc: userData.salon_psc,
                    salon_town: userData.salon_town
                };

                db_check_email(data);
            }
        }

        if (index == 1) {
            error += IsEmpty(userData.company) == true ? true : false;
            error += IsEmpty(userData.street) == true ? true : false;
            error += IsEmpty(userData.psc) == true ? true : false;
            error += IsEmpty(userData.town) == true ? true : false;
            error += IsEmpty(userData.ico) == true ? true : false;
            if (props.user.typ == global.account_salon) {
                error += IsEmpty(userData.account) == true ? true : false;
            }

            if (error > 0) {
                setError(lang.form_error);
            } else {
                // SAVE DATA - FIREMNE UDAJE              
                var data = {
                    id: props.user.id,
                    company: userData.company,
                    street: userData.street,
                    street_number: userData.street_number,
                    psc: userData.psc,
                    town: userData.town,
                    ico: userData.ico,
                    dic: userData.dic,
                    ic_dph: userData.ic_dph,
                    account: userData.account
                };

                db_user_update(data);
            }
        }

        // ZMENA OBCHODNEHO ZASTUPCU
        if (index == 7) {

            if (agent > 0) {
                var data = {
                    id: props.user.id,
                    agent_id: agent
                };
                db_user_update(data);
            }
        }

        if (index == 20) {
            error += IsEmpty(userData.name) == true ? true : false;
            error += IsEmpty(userData.surname) == true ? true : false;
            error += IsEmpty(userData.email) == true ? true : false;
            error += nonEmail(userData.email) == true ? true : false;

            if (error > 0) {
                setError(lang.form_error);
            } else {
                // SAVE DATA - ZAKLADNE UDAJE               
                var data = {
                    id: props.user.id,
                    name: userData.name,
                    surname: userData.surname,
                    email: userData.email,
                    mobil: userData.mobil,
                };

                db_check_email(data);
            }
        }

    }

    const ChangeIndex = (index) => {
        setIndex(index);
        setEdit(false);
        setError('');
        setAliasEdit(false);
        setAliasError('');

        if (index == 5) {
            // ZAMESTNANCI
            setOverID(-1);
            setEmployees([]);
            setEmployeesFiltered([]);
            db_employees();
        }
        GoToStart();
    }

    const DialogPasswordChangeResult = () => {
        setShowPassword(false);
    }

    const EmployeeAdd = () => {
        setEmployeeEditing(false);
        setShowEmployee(true);
    }

    const EmployeeEdit = (item) => {
        setEmployeeSelected(item);
        setEmployeeEditing(true);
        setShowEmployee(true);

    }
    const DialogEmployeeResult = (value) => {
        setShowEmployee(false);
        if (value == true) {
            db_employees();
        }
    }

    const BonusUpdate = () => {
        var price = ConvertToPrice(bonusPrice);
        var error = 0;

        error += IsEmpty(bonusPoints) == true ? true : false;
        error += IsEmpty(bonusPrice) == true ? true : false;
        if (price == 0) {
            error++;
        }
        if (parseInt(bonusPoints) == 0) {
            error++;
        }

        if (error == 0) {
            var data = {
                id: props.user.id,
                score: bonusEnable,
                //bonus_points: bonusPoints,
                //bonus_price: price
            }
            db_user_update(data);
        }


    }

    const BonusExample = (txt) => {
        var result = txt.replace('@price', bonusPoints);
        result = result.replaceAll('@bonus', bonusPrice);

        return result;
    }

    const CancelEdit = () => {
        setEdit(false);
    }

    const EshopUpdate = () => {
        var error_ = '';
        setError('');

        if (webEnabled == true) {
            if (eshopEnabled == true) {
                if (tradeEnabled == true) {
                    if (IsEmpty(tradeName) == true) {
                        error_ = lang.required + ' - ' + lang.trade_name;
                    }
                    if (IsEmpty(tradeEur) == true) {
                        error_ = lang.required + ' - ' + lang.trade_eur;
                    }
                }
            }
        }

        if (tradeFree == true) {
            if (IsEmpty(tradeFreeEur) == true) {
                error_ = lang.required + ' - ' + lang.tradeFreeEur;
            }
        }

        if (error_ == '') {
            var settings_id = 0;
            if (settings == false) {
                settings_id = 0;
            } else {
                settings_id = settings.id
            }

            var user_data = {
                id: props.user.id,
                eshop: webEnabled,
                eshop_ready: eshopEnabled,
                public_services: publicServices,
                public_prices: publicServicesPrices,

            }
            var settings_data = {
                id: settings_id,
                salon_id: props.user.id,
                trade_enabled: tradeEnabled,
                trade_free_enabled: tradeFree,
                trade_free_price: tradeFreeEur.replace(',', '.'),
                payment_enabled: paymentBank
            }

            var trade_data = {
                id: tradeID,
                salon_id: props.user.id,
                label: tradeName,
                note: tradeText,
                price: tradeEur.replace(',', '.')
            }

            db_settings_update(user_data, settings_data, trade_data, false);
        } else {
            setError(error_);
        }
    }


    const ReserveUpdate = () => {
        // KONTROLA CASU
        setError('');
        var err = false;

        var n = 0;
        while (n < 7) {
            var item = reserveHours[n];
            if (item.od != '') {
                if (notTime(item.od)) {
                    setError(lang.days[item.id] + ' - ' + lang.time_error);
                    err = true;
                    break;
                }
            }
            if (item.do != '') {
                if (notTime(item.do)) {
                    setError(lang.days[item.id] + ' - ' + lang.time_error);
                    err = true;
                    break;
                }
            }
            if (item.obed_od != '') {
                if (notTime(item.obed_od)) {
                    setError(lang.days[item.id] + ' - ' + lang.time_error);
                    err = true;
                    break;
                }
            }
            if (item.obed_do != '') {
                if (notTime(item.obed_do)) {
                    setError(lang.days[item.id] + ' - ' + lang.time_error);
                    err = true;
                    break;
                }
            }

            n++;
        }

        if (err == false) {
            var n = 0;
            while (n < 7) {
                var item = reserveHours[n];
                if (item.od != '' && item.do != '') {
                    if (GetMinutesFromHours(item.od) >= GetMinutesFromHours(item.do)) {
                        setError(lang.days[item.id] + ' - ' + lang.time_error_);
                        err = true;
                        break;
                    }
                }
                if (item.obed_od != '' && item.obed_do != '') {
                    if (GetMinutesFromHours(item.obed_od) >= GetMinutesFromHours(item.obed_do)) {
                        setError(lang.days[item.id] + ' - ' + lang.time_error_);
                        err = true;
                        break;
                    }
                }
                n++;
            }

            if (err == false) {
                var time = 0;
                if (reserveTime != '') {
                    if (notNumber(reserveTime)) {
                        err = true;
                        setError(lang.format_error + ' - ' + lang.settings_calendar_time);
                    } else {
                        time = reserveTime;
                    }
                }
                if (err == false) {

                    var user_data = {
                        id: props.user.id,
                        calendar_public: reserveEnabled,
                        reserve_break_time: time,
                    }

                    var reserve_data = {
                        id: reserveID,
                        salon_id: props.user.id,
                        equal: reserveEqual,
                        data: JSON.stringify(reserveHours),
                    }

                    db_settings_update(user_data, false, false, reserve_data);
                }
            }
        }
    }

    const HoursResult = (hours, equal_) => {
        setReserveHours(hours);
        setReserveEqual(equal_);
    }

    const AppUpdate = () => {
        var user_data = {
            id: props.user.id,
            protect: pinEnabled,
            pin: pinEnabled == true ? pin : '',
            forum_enabled: forumEnabled,
            forum_max: forum,
            calendar_notify: calendarNotify
        }

        db_settings_update(user_data, false, false, false);
    }

    const EnterPin = () => {
        if (pin.trim() != '') {

            setPinType(2);
        } else {
            setPinType(1);
        }
        setShowPin(true);
    }

    const PinResult = (typ, code) => {
        setShowPin(false);

        if (typ == true) {
            setPin(code);
        }
    }

    const ChangePinEnabled = (value) => {
        if (value == false) {
            setPin('');
        }
        setPinEnabled(value);
    }

    const TokenErrorPress = () => {
        setShowTokenError(false);
        Press();
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const CancelAccount = (value) => {
        setShowAccountCancel(false);
        if (value == true) {
            db_account_cancel();
        }
    }

    const EditAlias = (value) => {
        setAliasError('');
        if (value == true) {
            setAliasName(alias);
            setAliasEdit(true);
        } else {
            setAliasEdit(false);
        }
    }

    const ChangeAlias = () => {
        // ZMENA ALIASU - kontrola ci uz existuje a zmena
        setAliasError('');
        db_alias_check(aliasName);
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                        <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faCog} />
                    </div>
                </div>
                <div style={{ ...styles.Block, width: '85%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: 45 }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                        <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                    </div>
                </div>
                <div style={{ ...styles.Block, width: 180, height: global.dialog_bar_height }}>
                    <p style={{ ...styles.TextTiny }}>{lang.version} {GetVersion(props.version)}</p>
                </div>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...props.isSmall == true ? styles.BlockCenter : styles.BlockRow, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                {props.isSmall == true ?
                    <div style={{ ...styles.Block }}>
                        {props.user != false ? props.user.typ == global.account_salon ?

                            <Select
                                value={index}
                                onChange={event => ChangeIndex(event.target.value)}
                                size='small'
                                style={{ backgroundColor: global.theme_white, width: '100%' }}
                            >

                                <MenuItem value={0}>{lang.settings_basic}</MenuItem>
                                <MenuItem value={1}>{lang.settings_company}</MenuItem>
                                <MenuItem value={2}>{lang.page_eshop}</MenuItem>
                                <MenuItem value={3}>{lang.settings_calendar}</MenuItem>
                                <MenuItem value={6}>{lang.bonus_system}</MenuItem>
                                <MenuItem value={5}>{lang.employees}</MenuItem>
                                <MenuItem value={7}>{lang.agent}</MenuItem>
                                <MenuItem value={4}>{lang.settings_system}</MenuItem>
                                <MenuItem value={8}>{lang.account_cancel}</MenuItem>
                                <MenuItem value={9}>{lang.gdpr_}</MenuItem>
                                <MenuItem value={99}>{lang.settings_logout}</MenuItem>
                            </Select>
                            : null : null}

                        {props.user != false ? props.user.typ == global.account_medical ?

                            <Select
                                value={index}
                                onChange={event => ChangeIndex(event.target.value)}
                                size='small'
                                style={{ backgroundColor: global.theme_white, width: '100%' }}
                            >
                                <MenuItem value={0}>{lang.settings_basic}</MenuItem>
                                <MenuItem value={1}>{lang.settings_company}</MenuItem>
                                <MenuItem value={7}>{lang.agent}</MenuItem>
                                <MenuItem value={8}>{lang.account_cancel}</MenuItem>
                                <MenuItem value={9}>{lang.gdpr_}</MenuItem>
                                <MenuItem value={99}>{lang.settings_logout}</MenuItem>

                            </Select>
                            : null : null}

                        {props.user != false ? props.user.typ == global.account_agent ?

                            <Select
                                value={index}
                                onChange={event => ChangeIndex(event.target.value)}
                                size='small'
                                style={{ backgroundColor: global.theme_white, width: '100%' }}
                            >

                                <MenuItem value={20}>{lang.settings_basic}</MenuItem>
                                <MenuItem value={99}>{lang.settings_logout}</MenuItem>
                            </Select>
                            : null : null}

                    </div>
                    :
                    <div>
                        {props.user != false ? props.user.typ == global.account_salon ?
                            <div style={{ ...styles.BlockCenter, width: menu_width, height: props.height - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                                <Button onClick={() => ChangeIndex(0)} style={{ ...styles.ButtonSettings, backgroundColor: index == 0 ? global.theme_back : '#00000000', color: index == 0 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.settings_basic}</Button>
                                <Button onClick={() => ChangeIndex(1)} style={{ ...styles.ButtonSettings, backgroundColor: index == 1 ? global.theme_back : '#00000000', color: index == 1 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.settings_company}</Button>
                                <Button onClick={() => ChangeIndex(2)} style={{ ...styles.ButtonSettings, backgroundColor: index == 2 ? global.theme_back : '#00000000', color: index == 2 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.page_eshop}</Button>
                                <Button onClick={() => ChangeIndex(3)} style={{ ...styles.ButtonSettings, backgroundColor: index == 3 ? global.theme_back : '#00000000', color: index == 3 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.settings_calendar}</Button>
                                <Button onClick={() => ChangeIndex(6)} style={{ ...styles.ButtonSettings, backgroundColor: index == 6 ? global.theme_back : '#00000000', color: index == 6 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.bonus_system}</Button>
                                <Button onClick={() => ChangeIndex(5)} style={{ ...styles.ButtonSettings, backgroundColor: index == 5 ? global.theme_back : '#00000000', color: index == 5 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.employees}</Button>
                                <Button onClick={() => ChangeIndex(7)} style={{ ...styles.ButtonSettings, backgroundColor: index == 7 ? global.theme_back : '#00000000', color: index == 7 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.agent}</Button>
                                <Button onClick={() => ChangeIndex(4)} style={{ ...styles.ButtonSettings, backgroundColor: index == 4 ? global.theme_back : '#00000000', color: index == 4 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.settings_system}</Button>
                                <Button onClick={() => ChangeIndex(8)} style={{ ...styles.ButtonSettings, backgroundColor: index == 8 ? global.theme_back : '#00000000', color: index == 8 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.account_cancel}</Button>
                                <Button onClick={() => ChangeIndex(9)} style={{ ...styles.ButtonSettings, backgroundColor: index == 9 ? global.theme_back : '#00000000', color: index == 9 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.gdpr_}</Button>
                                <Button onClick={() => ChangeIndex(99)} style={{ ...styles.ButtonSettings, backgroundColor: index == 99 ? global.theme_back : '#00000000', color: index == 99 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.settings_logout}</Button>
                            </div>
                            : null : null}

                        {props.user != false ? props.user.typ == global.account_medical ?
                            <div style={{ ...styles.BlockCenter, width: menu_width, height: props.height - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                                <Button onClick={() => ChangeIndex(0)} style={{ ...styles.ButtonSettings, backgroundColor: index == 0 ? global.theme_back : '#00000000', color: index == 0 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.settings_basic}</Button>
                                <Button onClick={() => ChangeIndex(1)} style={{ ...styles.ButtonSettings, backgroundColor: index == 1 ? global.theme_back : '#00000000', color: index == 1 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.settings_company}</Button>
                                <Button onClick={() => ChangeIndex(7)} style={{ ...styles.ButtonSettings, backgroundColor: index == 7 ? global.theme_back : '#00000000', color: index == 7 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.agent}</Button>
                                <Button onClick={() => ChangeIndex(8)} style={{ ...styles.ButtonSettings, backgroundColor: index == 8 ? global.theme_back : '#00000000', color: index == 8 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.account_cancel}</Button>
                                <Button onClick={() => ChangeIndex(9)} style={{ ...styles.ButtonSettings, backgroundColor: index == 9 ? global.theme_back : '#00000000', color: index == 9 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.gdpr_}</Button>
                                <Button onClick={() => ChangeIndex(99)} style={{ ...styles.ButtonSettings, backgroundColor: index == 99 ? global.theme_back : '#00000000', color: index == 99 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.settings_logout}</Button>
                            </div>
                            : null : null}

                        {props.user != false ? props.user.typ == global.account_agent ?
                            <div style={{ ...styles.BlockCenter, width: menu_width, height: props.height - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                                <Button onClick={() => ChangeIndex(20)} style={{ ...styles.ButtonSettings, backgroundColor: index == 20 ? global.theme_back : '#00000000', color: index == 20 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.settings_basic}</Button>
                                <Button onClick={() => ChangeIndex(99)} style={{ ...styles.ButtonSettings, backgroundColor: index == 99 ? global.theme_back : '#00000000', color: index == 99 ? global.theme_black : global.themes[props.theme].theme_dark }}>{lang.settings_logout}</Button>
                            </div>
                            : null : null}
                    </div>
                }
                <div style={{ ...styles.BlockCenter, width: props.width - menu_width, height: props.height - global.dialog_bar_height, borderBottomRightRadius: props.radius, backgroundColor: global.theme_back, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    <div style={{ ...styles.BlockCenter, width: props.isSmall ? '94%' : '100%' }}>
                        <div id={'id_start'}></div>
                        {index == 0 ?
                            // *********************************************************************************************************
                            // ZAKLADNE UDAJE
                            // *********************************************************************************************************
                            <div style={{ ...styles.BlockCenter, paddingTop: 10, paddingBottom: props.isSmall ? global.keyboard_padding_small : global.keyboard_padding }}>
                                <div style={{ ...styles.Block, maxWidth: 540 }}>
                                    {props.isSmall == true ? null :
                                        <p style={{ ...styles.TextNormal, fontWeight: '600' }}>{lang.settings_basic}</p>
                                    }

                                    {/* MENO, PRIEZVISKO */}
                                    <div style={{ ...styles.BlockRow, marginTop: rowOffset }}>
                                        <div style={{ ...styles.Block }}>
                                            <TextInput enabled={edit} lang={lang} required value={userData.name} label={lang.name} func={(txt) => { userData.name = txt }} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, marginLeft: 10 }}>
                                            <TextInput enabled={edit} lang={lang} required value={userData.surname} label={lang.surname} func={(txt) => { userData.surname = txt }} />
                                        </div>
                                    </div>

                                    {/* EMAIL, MOBIL */}
                                    <div style={{ ...styles.BlockRow, marginTop: rowOffset }}>
                                        <div style={{ ...styles.Block }}>
                                            <TextInput enabled={edit} email required lang={lang} value={userData.email} label={lang.email} func={(txt) => { userData.email = txt }} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, marginLeft: 10 }}>
                                            <TextInput enabled={edit} phone lang={lang} value={userData.mobil} label={lang.mobil} func={(txt) => { userData.mobil = txt }} />
                                        </div>
                                    </div>

                                    <p style={{ ...styles.TextNormal, fontWeight: '600', marginTop: rowOffset * 2 }}>{props.user.salon1_id == 0 ? lang.prevadzka : lang.settings_medical_address}</p>
                                    {/* PREVAZDKA */}
                                    <div style={{ ...styles.Block, marginTop: rowOffset }}>
                                        <TextInput enabled={edit} lang={lang} required value={userData.salon_name} label={lang.prevadzka_name} func={(txt) => { userData.salon_name = txt }} />
                                    </div>

                                    {/* ULICA, ČISLO */}
                                    <div style={{ ...styles.BlockRow, marginTop: rowOffset }}>
                                        <div style={{ ...styles.Block, width: '70%' }}>
                                            <TextInput enabled={edit} required lang={lang} value={userData.salon_street} label={lang.street} func={(txt) => { userData.salon_street = txt }} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '30%', marginLeft: 10 }}>
                                            <TextInput enabled={edit} required lang={lang} value={userData.salon_street_number} label={lang.street_number} func={(txt) => { userData.salon_street_number = txt }} />
                                        </div>
                                    </div>

                                    {/* PSC MESTO */}
                                    <div style={{ ...styles.BlockRow, marginTop: rowOffset }}>
                                        <div style={{ ...styles.Block, width: '30%' }}>
                                            <TextInput enabled={edit} required lang={lang} value={userData.salon_psc} label={lang.psc} func={(txt) => { userData.salon_psc = txt }} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '70%', marginLeft: 10 }}>
                                            <TextInput enabled={edit} required lang={lang} value={userData.salon_town} label={lang.town} func={(txt) => { userData.salon_town = txt }} />
                                        </div>
                                    </div>


                                    <FormError error={error} />

                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.Block, width: '50%' }}>
                                            {edit == false ?
                                                <Button onClick={() => setShowPassword(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, marginTop: rowOffset }}>{lang.password_change}</Button>
                                                :
                                                <Button onClick={() => CancelEdit()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, marginTop: rowOffset }}>{lang.cancel}</Button>
                                            }
                                        </div>
                                        <div style={{ ...styles.Block, width: '50%' }}>
                                            <Button onClick={() => { edit == false ? setEdit(true) : Save(index) }} style={{ ...styles.ButtonDark, backgroundColor: edit == true ? global.theme_dark_red : global.themes[props.theme].button_dark, marginTop: rowOffset }}>
                                                {edit == false ?
                                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPen} />
                                                    : null}
                                                {edit == false ? lang.edit : lang.save}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}

                        {index == 1 ?
                            // *********************************************************************************************************
                            // FIREMNE UDAJE
                            // *********************************************************************************************************
                            <div style={{ ...styles.BlockCenter, paddingTop: 10, paddingBottom: props.isSmall ? global.keyboard_padding_small : global.keyboard_padding }}>

                                <div style={{ ...styles.Block, maxWidth: 540 }}>
                                    {props.isSmall == true ? null :
                                        <p style={{ ...styles.TextNormal, fontWeight: '600' }}>{lang.settings_company}</p>
                                    }

                                    {/* NAZOV FIRMY */}
                                    <div style={{ ...styles.Block, marginTop: rowOffset }}>
                                        <TextInput enabled={edit} lang={lang} required value={userData.company} label={lang.company_name} func={(txt) => { userData.company = txt }} />
                                    </div>

                                    {/* ULICA, ČISLO */}
                                    <div style={{ ...styles.BlockRow, marginTop: rowOffset }}>
                                        <div style={{ ...styles.Block, width: '70%' }}>
                                            <TextInput enabled={edit} required lang={lang} value={userData.street} label={lang.street} func={(txt) => { userData.street = txt }} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '30%', marginLeft: 10 }}>
                                            <TextInput enabled={edit} required lang={lang} value={userData.street_number} label={lang.street_number} func={(txt) => { userData.street_number = txt }} />
                                        </div>
                                    </div>

                                    {/* PSC MESTO */}
                                    <div style={{ ...styles.BlockRow, marginTop: rowOffset }}>
                                        <div style={{ ...styles.Block, width: '30%' }}>
                                            <TextInput enabled={edit} required lang={lang} value={userData.psc} label={lang.psc} func={(txt) => { userData.psc = txt }} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '70%', marginLeft: 10 }}>
                                            <TextInput enabled={edit} required lang={lang} value={userData.town} label={lang.town} func={(txt) => { userData.town = txt }} />
                                        </div>
                                    </div>

                                    {/* ICO, DIC, IC-DPH */}
                                    <div style={{ ...styles.BlockRow, marginTop: rowOffset * 2 }}>
                                        <div style={{ ...styles.Block }}>
                                            <TextInput enabled={edit} required lang={lang} value={userData.ico} label={lang.ico} func={(txt) => { userData.ico = txt }} />
                                        </div>
                                        {props.language == 0 ?
                                            <div style={{ ...styles.BlockLeft, marginLeft: 10 }}>
                                                <TextInput enabled={edit} lang={lang} value={userData.dic} label={lang.dic} func={(txt) => { userData.dic = txt }} />
                                            </div>
                                            : null}
                                        <div style={{ ...styles.BlockLeft, marginLeft: 10 }}>
                                            <TextInput enabled={edit} lang={lang} value={userData.ic_dph} label={lang.ic_dph} func={(txt) => { userData.ic_dph = txt }} />
                                        </div>
                                    </div>

                                    {/* CISLO UCTU */}
                                    {props.user.typ != global.account_medical ?
                                        <div style={{ ...styles.Block, marginTop: rowOffset * 2 }}>
                                            <div style={{ ...styles.Block, width: '100%' }}>
                                                <TextInput required enabled={edit} lang={lang} value={userData.account} label={lang.account_eshop} func={(txt) => { userData.account = txt }} />
                                            </div>
                                        </div>
                                        : null}

                                    <FormError error={error} />

                                    <div style={{ ...styles.BlockRow }}>
                                        {edit == true ?
                                            <div style={{ ...styles.Block, width: '50%' }}>
                                                <Button onClick={() => CancelEdit()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, marginTop: rowOffset }}>{lang.cancel}</Button>
                                            </div>
                                            : null}
                                        <div style={{ ...styles.Block, width: edit == false ? '100%' : '50%' }}>
                                            <Button onClick={() => { edit == false ? setEdit(true) : Save(index) }} style={{ ...styles.ButtonDark, backgroundColor: edit == true ? global.theme_dark_red : global.themes[props.theme].button_dark, marginTop: rowOffset }}>
                                                {edit == false ?
                                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPen} />
                                                    : null}
                                                {edit == false ? lang.edit : lang.save}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}

                        {index == 2 ?
                            // *********************************************************************************************************
                            // E-SHOP + WEBOVA STRANKA
                            // *********************************************************************************************************
                            <div style={{ ...styles.BlockCenter, paddingTop: 10, paddingBottom: props.isSmall ? global.keyboard_padding_small : global.keyboard_padding }}>
                                <div style={{ ...styles.Block, maxWidth: 700 }}>
                                    {props.isSmall == true ? null :
                                        <p style={{ ...styles.TextNormal, fontWeight: '600' }}>{lang.page_eshop_}</p>
                                    }

                                    <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                        <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.settings_page_enable}</p>}
                                            control={<Checkbox checked={webEnabled} size={'small'} onChange={() => setWebEnabled(!webEnabled)} />}
                                        />

                                        {webEnabled == true ?
                                            <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                                <Paper elevation={3} style={{ ...styles.Block, paddingTop: 15, paddingBottom: 15, backgroundColor: global.theme_white, borderRadius: props.radius }}>
                                                    <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{lang.register_alias_text}:</p>
                                                    {aliasEdit == false ?
                                                        <div style={{ ...styles.Block, height: 50 }}>
                                                            <p style={{ ...styles.TextSmall, fontWeight: '600', marginTop: 5, color: global.theme_dark_blue }}>{lang.www_eshop}/{alias}</p>
                                                        </div>
                                                        :
                                                        <div style={{ ...styles.Block, height: 50 }}>
                                                            <div style={{ ...styles.Block, width: 310 }}>
                                                                <TextInput outline lang={lang} value={aliasName} width={300} label={''} func={(txt) => { setAliasName(txt) }} />
                                                            </div>
                                                        </div>
                                                    }
                                                    {aliasEdit == false ?
                                                        <div style={{ ...styles.Block, marginTop: 10 }}>
                                                            <Button onClick={() => EditAlias(true)} style={{ ...styles.ButtonDarkSmall, backgroundColor: global.themes[props.theme].button_dark }}>
                                                                <FontAwesomeIcon style={{ height: 12, color: global.theme_white, marginRight: 10 }} icon={faPen} />
                                                                {lang.edit}
                                                            </Button>
                                                        </div>
                                                        :
                                                        <div style={{ ...styles.Block }}>
                                                            <FormError small error={aliasError} />
                                                            <div style={{ ...styles.BlockRowRaw, marginTop: 20 }}>
                                                                <Button onClick={() => EditAlias(false)} style={{ ...styles.ButtonDarkSmall, backgroundColor: global.themes[props.theme].theme_dark }}>{lang.cancel}</Button>
                                                                <Button onClick={() => ChangeAlias(false)} style={{ ...styles.ButtonDarkSmall, backgroundColor: global.theme_dark_red, marginLeft: 20 }}>{lang.save}</Button>
                                                            </div>
                                                        </div>
                                                    }
                                                </Paper>
                                                <div style={{ ...styles.Block, height: 1, backgroundColor: global.theme_light_gray, marginTop: 20 }}></div>

                                                <p style={{ ...styles.TextNormal, fontWeight: '600', marginTop: 20 }}>{lang.settings_eshop}</p>
                                                <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.settings_eshop_enable}</p>}
                                                    control={<Checkbox checked={eshopEnabled} size={'small'} onChange={() => setEshopEnabled(!eshopEnabled)} />}
                                                />
                                                <p style={{ ...styles.TextTiny, fontWeight: '400', fontWeight: eshopEnabled == false ? '600' : '400', color: eshopEnabled == true ? global.theme_black : global.theme_dark_red }}>{eshopEnabled == true ? lang.eshop_enabled : lang.eshop_disabled}</p>

                                                {eshopEnabled == true ?
                                                    <div style={{ ...styles.BlockLeft, marginTop: 20 }}>
                                                        <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{lang.trade}:</p>

                                                        <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.trade_enabled}</p>}
                                                            control={<Checkbox checked={tradeEnabled} size={'small'} onChange={() => setTradeEnabled(!tradeEnabled)} />}
                                                        />
                                                        {tradeEnabled == true ?
                                                            <div style={{ ...styles.BlockLeft }}>
                                                                <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                                    <div style={{ ...styles.Block, width: '65%' }}>
                                                                        <TextInput required outline lang={lang} value={tradeName} label={lang.trade_name} func={(txt) => { setTradeName(txt) }} />
                                                                    </div>
                                                                    <div style={{ ...styles.Block, width: '35%', marginLeft: 20 }}>
                                                                        <TextInput numeric required outline money={true} lang={lang} value={tradeEur} label={lang.trade_eur} func={(txt) => { setTradeEur(txt) }} />
                                                                    </div>
                                                                </div>
                                                                <div style={{ ...styles.Block, marginTop: 10 }}>
                                                                    <TextInput outline lang={lang} value={tradeText} label={lang.trade_note} func={(txt) => { setTradeText(txt) }} />
                                                                </div>
                                                            </div>
                                                            : null}
                                                        <div style={{ ...styles.BlockLeft, marginTop: 20 }}>
                                                            <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.trade_free}</p>}
                                                                control={<Checkbox checked={tradeFree} size={'small'} onChange={() => setTradeFree(!tradeFree)} />}
                                                            />
                                                            {tradeFree == true ?
                                                                <div style={{ ...styles.BlockLeft }}>
                                                                    <p style={{ ...styles.TextInput, color: global.theme_dark_gray, marginTop: 5, marginLeft: 12 }}>{lang.trade_free_eur}</p>
                                                                    <div style={{ ...styles.Block, width: 200 }}>
                                                                        <TextInput numeric required outline money={true} lang={lang} value={tradeFreeEur} label={''} func={(txt) => { setTradeFreeEur(txt) }} />
                                                                    </div>
                                                                </div>
                                                                : null}
                                                        </div>
                                                        <div style={{ ...styles.BlockLeft, marginTop: 20 }}>
                                                            <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.payment_bank}</p>}
                                                                control={<Checkbox checked={paymentBank} size={'small'} onChange={() => setPaymentBank(!paymentBank)} />}
                                                            />
                                                        </div>
                                                    </div>
                                                    : null}


                                            </div>
                                            : null}
                                        <div style={{ ...styles.Block, height: 1, backgroundColor: global.theme_light_gray, marginTop: 20 }}></div>
                                        <p style={{ ...styles.TextNormal, fontWeight: '600', marginTop: 20 }}>{lang.public_services}</p>
                                        <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                            <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.public_services_show}</p>}
                                                control={<Checkbox checked={publicServices} size={'small'} onChange={() => setPublicServices(!publicServices)} />}
                                            />
                                            <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.public_prices_show}</p>}
                                                control={<Checkbox checked={publicServicesPrices} size={'small'} onChange={() => setPublicServicesPrices(!publicServicesPrices)} />}
                                            />
                                        </div>

                                        <FormError error={error} />

                                    </div>
                                    <div style={{ ...styles.Block, marginBottom: 40 }}>
                                        <Button onClick={() => EshopUpdate()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, marginTop: 10 }}>
                                            {lang.save}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            : null}


                        {index == 3 ?
                            // *********************************************************************************************************
                            // REZERVACNY SYSTEM
                            // *********************************************************************************************************
                            <div style={{ ...styles.BlockCenter, paddingTop: 10, paddingBottom: props.isSmall ? global.keyboard_padding_small : global.keyboard_padding }}>
                                <div style={{ ...styles.Block, maxWidth: 700 }}>
                                    {props.isSmall == true ? null :
                                        <p style={{ ...styles.TextNormal, fontWeight: '600' }}>{lang.settings_calendar}</p>
                                    }

                                    <p style={{ ...styles.TextSmall, marginTop: 5, fontWeight: '400' }}>{lang.settings_calendar_text}</p>
                                    <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                        <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.settings_calendar_}</p>}
                                            control={<Checkbox checked={reserveEnabled} size={'small'} onChange={() => setReserveEnabled(!reserveEnabled)} />}
                                        />
                                        {reserveEnabled == true ?
                                            <div style={{ ...styles.BlockLeft }}>
                                                <p style={{ ...styles.TextInput, color: global.theme_dark_gray, marginTop: 20, marginLeft: 12 }}>{lang.settings_calendar_time}</p>
                                                <div style={{ ...styles.Block, marginTop: 0, width: 200 }}>
                                                    <TextInput numeric outline lang={lang} money money_text={lang.minutes} value={reserveTime} label={''} func={(txt) => { setReserveTime(txt) }} />
                                                </div>

                                                <div style={{ ...styles.Block, height: 1, backgroundColor: global.theme_light_gray, marginTop: 20 }}></div>
                                                <p style={{ ...styles.TextSmall, fontWeight: '600', marginTop: 20 }}>{lang.settings_calendar_hours}</p>
                                                <p style={{ ...styles.TextSmall, fontWeight: '400', marginTop: 10 }}>{lang.settings_calendar_hours_text}</p>
                                                <p style={{ ...styles.TextSmall, fontWeight: '400', marginTop: 2 }}>{lang.settings_calendar_hours_text_}</p>

                                                <div style={{ ...styles.BlockLeft, marginTop: 25 }}>
                                                    <HoursSettings isSmall={props.isSmall} hours={reserveHours} equal={reserveEqual} lang={lang} radius={props.radius} func={HoursResult.bind(this)} />
                                                </div>
                                                <FormError error={error} />
                                            </div>
                                            : null}
                                    </div>
                                    <div style={{ ...styles.Block, marginBottom: 40 }}>
                                        <Button onClick={() => ReserveUpdate()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, marginTop: rowOffset * 3 }}>
                                            {lang.save}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            : null}


                        {index == 4 ?
                            // *********************************************************************************************************
                            // NASTAVENIE APLIKÁCIE
                            // *********************************************************************************************************
                            <div style={{ ...styles.BlockCenter, paddingTop: 10 }}>
                                <div style={{ ...styles.Block, maxWidth: 700 }}>
                                    {props.isSmall == true ? null :
                                        <p style={{ ...styles.TextNormal, fontWeight: '600' }}>{lang.settings_app}</p>
                                    }

                                    {/* OCHRANA PIN KODOM */}
                                    <div style={{ ...styles.BlockRow, marginTop: 20 }}>
                                        <div style={{ ...styles.BlockLeft }}>
                                            <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{lang.settings_pin_label}</p>
                                            <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.settings_pin}</p>}
                                                control={<Checkbox checked={pinEnabled} size={'small'} onChange={() => ChangePinEnabled(!pinEnabled)} />}
                                            />
                                            <p style={{ ...styles.TextTiny }}>{pinEnabled == true ? lang.settings_pin_enabled : lang.settings_pin_disabled}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight }}>
                                            {pinEnabled == true ?
                                                <Button onClick={() => EnterPin()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, marginTop: 30 }}>
                                                    <p style={{ ...styles.TextTiny, color: global.theme_white }}>
                                                        {pin != '' ? lang.settings_pin_change : lang.settings_pin_enter}
                                                    </p>
                                                </Button>
                                                : null}
                                        </div>
                                    </div>
                                    <div style={{ ...styles.Block, height: 1, backgroundColor: global.theme_light_gray, marginTop: 20 }}></div>

                                    {/* DISKUSIA */}
                                    <div style={{ ...styles.BlockLeft, marginTop: 20 }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{lang.chat}</p>
                                        <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.chat_enable}</p>}
                                            control={<Checkbox checked={forumEnabled} size={'small'} onChange={() => setForumEnabled(!forumEnabled)} />}
                                        />
                                        {forumEnabled == true ?
                                            <div style={{ ...styles.BlockLeft, marginTop: 20 }}>
                                                <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{lang.settings_forum_label}</p>
                                                <RadioGroup
                                                    value={forum}
                                                    name={''}
                                                >
                                                    <FormControlLabel value={0} control={<Radio onChange={() => setForum(0)} />} label={<p style={{ ...styles.TextTiny }}>{lang.settings_forum_1}</p>} />
                                                    <FormControlLabel value={1} control={<Radio onChange={() => setForum(1)} />} label={<p style={{ ...styles.TextTiny }}>{lang.settings_forum_3}</p>} />
                                                    <FormControlLabel value={2} control={<Radio onChange={() => setForum(2)} />} label={<p style={{ ...styles.TextTiny }}>{lang.settings_forum_6}</p>} />
                                                </RadioGroup>
                                            </div>
                                            : null}
                                    </div>
                                    <div style={{ ...styles.Block, height: 1, backgroundColor: global.theme_light_gray, marginTop: 20 }}></div>

                                    {/* NOTIFIKACIE -> KALENDAR */}
                                    <div style={{ ...styles.BlockLeft, marginTop: 20 }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{lang.calendar}</p>
                                        <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.calendar_notify}</p>}
                                            control={<Checkbox checked={calendarNotify} size={'small'} onChange={() => setCalendarNotify(!calendarNotify)} />}
                                        />
                                    </div>
                                    <div style={{ ...styles.Block, height: 1, backgroundColor: global.theme_light_gray, marginTop: 20 }}></div>

                                    <div style={{ ...styles.Block, marginBottom: 40 }}>
                                        {(pinEnabled == true && pin != '') || (pinEnabled == false) ?
                                            <Button onClick={() => AppUpdate()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, marginTop: rowOffset * 5 }}>
                                                {lang.save}
                                            </Button>
                                            : null}
                                    </div>
                                </div>
                            </div>
                            : null}



                        {index == 5 ?
                            // *********************************************************************************************************
                            // ZAMESTNANCI
                            // *********************************************************************************************************
                            <div style={{ ...styles.BlockCenter, paddingTop: 0 }}>
                                <div style={{ ...styles.Block, height: 50 }}>
                                    {props.isSmall == true ?
                                        <IconButton onClick={() => EmployeeAdd()} style={{ width: 36, height: 36, backgroundColor: global.theme_dark_red }}>
                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white }} icon={faPlus} />
                                        </IconButton>
                                        :
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <div style={{ ...styles.Block, width: 50 }}></div>
                                            <div style={{ ...styles.Block, width: 300, height: 50 }}>
                                                <p style={{ ...styles.TextNormal, fontWeight: '600' }}>{lang.employees}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 50, height: 50 }}>
                                                <IconButton onClick={() => EmployeeAdd()} style={{ width: 36, height: 36, backgroundColor: global.theme_dark_red }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white }} icon={faPlus} />
                                                </IconButton>
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div style={{ ...styles.Block, width: rowWidth, marginTop: 20 }}>
                                    {employeesFiltered != false ?
                                        employeesFiltered.map((item, index) => (
                                            <Paper key={item.id} elevation={overID == item.id ? 6 : 2} onClick={() => EmployeeEdit(item)} style={{ ...styles.BlockRow, height: rowHeight, backgroundColor: item.enabled == true ? global.theme_white : global.theme_light_gray, marginBottom: 10, borderRadius: rowHeight / 2, cursor: 'pointer' }} onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)} >
                                                <div style={{ ...styles.Block, position: 'relative', width: rowHeight + rowHeight / 2, height: rowHeight }}>
                                                    <img src={item.photo == 0 ? avatar_woman : avatar_man} style={{ top: 0, left: 0, width: '100%', height: '100%', maxWidth: rowHeight - 10, maxHeight: rowHeight - 10, borderRadius: (rowHeight - 10) / 2, objectFit: 'contain' }} />
                                                    {item.color > 0 ?
                                                        <div style={{ position: 'absolute', top: 4, left: rowHeight / 2 + (rowHeight / 3), height: 12, width: 12, backgroundColor: global.colors_dark[item.color], borderRadius: 6 }}></div>
                                                        : null}
                                                </div>
                                                <div style={{ ...styles.Block, height: rowHeight }}>
                                                    <div style={{ ...styles.BlockLeft }}>
                                                        <div style={{ ...styles.BlockRow }}>
                                                            <div style={{ ...styles.BlockLeft, width: '90%' }}>
                                                                <p style={{ ...styles.TextSmall }}>{item.name} {item.surname}</p>
                                                            </div>

                                                            <div style={{ ...styles.BlockRight, width: '10%' }}>
                                                                {item.enabled == true ?
                                                                    <FontAwesomeIcon style={{ height: 18, color: global.theme_dark_green }} icon={faCheckCircle} />
                                                                    : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {item.note != '' ?
                                                        <div style={{ ...styles.BlockRight, width: '100%', borderTop: '1px solid #DDDDDD', marginTop: 5, paddingTop: 5 }}>
                                                            <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{item.note}</p>
                                                        </div>
                                                        : null}
                                                </div>
                                                <div style={{ ...styles.Block, width: rowHeight / 2, height: rowHeight }}></div>
                                            </Paper>
                                        ))
                                        :
                                        <div style={{ ...styles.Block, height: 50 }}>
                                            <p style={{ ...styles.TextTiny }}>{lang.records_none}</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            : null}

                        {index == 6 ?
                            // *********************************************************************************************************
                            // VERNOSTNY SYSTEM
                            // *********************************************************************************************************
                            <div style={{ ...styles.BlockCenter, paddingTop: 10, paddingBottom: 50 }}>
                                <div style={{ ...styles.Block, maxWidth: 800 }}>
                                    {props.isSmall == true ? null :
                                        <p style={{ ...styles.TextNormal, fontWeight: '600' }}>{lang.bonus_system}</p>
                                    }

                                    <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.bonus_system_enable}</p>}
                                        control={<Checkbox checked={bonusEnable} size={'small'} onChange={() => setBonusEnable(!bonusEnable)} />}
                                    />
                                    {bonusEnable == true ?
                                        <div style={{ ...styles.Block }}>
                                            <Paper elevation={3} style={{ ...styles.Block, marginTop: 20, paddingTop: 15, paddingBottom: 15, backgroundColor: global.theme_white, borderRadius: props.radius }}>
                                                <div style={{ ...styles.Block, width: '98%' }}>
                                                    <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{lang.bonus_system_label}</p>
                                                    <p style={{ ...styles.TextSmall, marginTop: 10 }}>{lang.bonus_system_label_}</p>
                                                </div>
                                            </Paper>
                                            <div style={{ ...styles.Block, marginTop: 40 }}>
                                                <TextInput enabled={false} center numeric required redraw width={120} lang={lang} value={bonusPoints} label={lang.bonus_system_points} func={(txt) => { setBonusPoints(txt) }} />
                                            </div>
                                            <div style={{ ...styles.Block, marginTop: 20 }}>
                                                <TextInput enabled={false} center required redraw width={120} money lang={lang} value={bonusPrice} label={lang.bonus_system_price} func={(txt) => { setBonusPrice(txt) }} />
                                            </div>
                                            <div style={{ ...styles.Block, marginTop: 20 }}>
                                                <p style={{ ...styles.TextTiny }}>{BonusExample(lang.bonus_system_example)}</p>
                                            </div>
                                        </div>
                                        : null}
                                    <Button onClick={() => BonusUpdate()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, marginTop: rowOffset * 3 }}>
                                        {lang.save}
                                    </Button>

                                </div>
                            </div>
                            : null}

                        {index == 7 ?
                            // *********************************************************************************************************
                            // OBCHODNY ZASTUPCA
                            // *********************************************************************************************************
                            <div style={{ ...styles.BlockCenter, paddingTop: 10 }}>
                                {props.isSmall == true ? null :
                                    <>
                                        <p style={{ ...styles.TextNormal, fontWeight: '600' }}>{lang.agent}</p>
                                    </>
                                }
                                <div style={{ ...styles.Block, marginTop: 40 }}>
                                    {agentItem != false ?
                                        <div style={{ ...styles.Block }}>
                                            <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{agentItem.name} {agentItem.surname}</p>
                                            <p style={{ ...styles.TextXSmall, marginTop: 10 }}>{lang.mobil.toLowerCase()}:<span style={{ marginLeft: 10 }}>{agentItem.mobil}</span></p>
                                            <p style={{ ...styles.TextXSmall }}>{lang.email_}:<span style={{ marginLeft: 10 }}>{agentItem.email}</span></p>
                                        </div>
                                        : null}
                                    {/*agents != false ?
                                        <Select
                                            value={agent}
                                            disabled
                                            onChange={event => setAgent(event.target.value)}
                                            size='small'
                                            style={{ backgroundColor: global.theme_white, width: 350, marginTop: 20, marginBottom: 30, borderRadius: 20 }}
                                        >
                                            <MenuItem disabled value={0}>{lang.agent_choose}</MenuItem>
                                            {agents.map((item, index) => (
                                                <MenuItem key={item.id} value={item.id}>{item.name} {item.surname}<span style={{ color: global.theme_medium_gray, marginLeft: 10 }}>({GetLanguage(item.language).language.toLowerCase()})</span></MenuItem>
                                            ))}
                                        </Select>
                                            : null*/}
                                    {/*
                                    <Button onClick={() => Save(index)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, marginTop: rowOffset }}>
                                        {lang.save}
                                    </Button>
                                    */}
                                </div>
                            </div>
                            : null}

                        {index == 20 ?
                            // *********************************************************************************************************
                            // ZAKLADNE UDAJE - AGENT
                            // *********************************************************************************************************
                            <div style={{ ...styles.BlockCenter, paddingTop: 10, paddingBottom: props.isSmall ? global.keyboard_padding_small : global.keyboard_padding }}>
                                <div style={{ ...styles.Block, maxWidth: 540 }}>
                                    {props.isSmall == true ? null :
                                        <p style={{ ...styles.TextNormal, fontWeight: '600' }}>{lang.settings_basic}</p>
                                    }

                                    {/* MENO, PRIEZVISKO */}
                                    <div style={{ ...styles.BlockRow, marginTop: rowOffset }}>
                                        <div style={{ ...styles.Block }}>
                                            <TextInput enabled={edit} lang={lang} required value={userData.name} label={lang.name} func={(txt) => { userData.name = txt }} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, marginLeft: 10 }}>
                                            <TextInput enabled={edit} lang={lang} required value={userData.surname} label={lang.surname} func={(txt) => { userData.surname = txt }} />
                                        </div>
                                    </div>

                                    {/* EMAIL, MOBIL */}
                                    <div style={{ ...styles.BlockRow, marginTop: rowOffset }}>
                                        <div style={{ ...styles.Block }}>
                                            <TextInput enabled={edit} email required lang={lang} value={userData.email} label={lang.email} func={(txt) => { userData.email = txt }} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, marginLeft: 10 }}>
                                            <TextInput enabled={edit} phone lang={lang} value={userData.mobil} label={lang.mobil} func={(txt) => { userData.mobil = txt }} />
                                        </div>
                                    </div>


                                    <FormError error={error} />

                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.Block, width: '50%' }}>
                                            {edit == false ?
                                                <Button onClick={() => setShowPassword(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, marginTop: rowOffset }}>{lang.password_change}</Button>
                                                :
                                                <Button onClick={() => CancelEdit()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, marginTop: rowOffset }}>{lang.cancel}</Button>
                                            }
                                        </div>
                                        <div style={{ ...styles.Block, width: '50%' }}>
                                            <Button onClick={() => { edit == false ? setEdit(true) : Save(index) }} style={{ ...styles.ButtonDark, backgroundColor: edit == true ? global.theme_dark_red : global.themes[props.theme].button_dark, marginTop: rowOffset }}>
                                                {edit == false ?
                                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPen} />
                                                    : null}
                                                {edit == false ? lang.edit : lang.save}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : null}

                        {index == 8 ?
                            // *********************************************************************************************************
                            // ZRUSENIE UCTU
                            // *********************************************************************************************************
                            <div style={{ ...styles.BlockCenter, maxWidth: 650, paddingTop: 10 }}>
                                {props.isSmall == true ? null :
                                    <p style={{ ...styles.TextNormal, fontWeight: '600' }}>{lang.account_cancel_request}</p>
                                }

                                <RadioGroup
                                    value={accountCancelTyp}
                                    name={''}
                                    style={{ ...styles.BlockLeft, marginTop: 40 }}
                                >
                                    <FormControlLabel value={0} control={<Radio onChange={() => setAccountCancelTyp(0)} />} label={<p style={{ ...styles.TextSmall, fontWeight: '500' }}>{lang.account_cancel_option_1}</p>} />
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', marginLeft: 32, marginBottom: 15 }}>{lang.account_cancel_option_1_}</p>
                                    <FormControlLabel value={1} control={<Radio onChange={() => setAccountCancelTyp(1)} />} label={<p style={{ ...styles.TextSmall, fontWeight: '500' }}>{lang.account_cancel_option_2}</p>} />
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', marginLeft: 32, marginBottom: 15 }}>{lang.account_cancel_option_2_}</p>
                                    <FormControlLabel value={2} control={<Radio onChange={() => setAccountCancelTyp(2)} />} label={<p style={{ ...styles.TextSmall, fontWeight: '500' }}>{lang.account_cancel_option_3}</p>} />
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', marginLeft: 32 }}>{lang.account_cancel_option_3_}</p>
                                </RadioGroup>

                                <div style={{ ...styles.Block, marginTop: 80 }}>
                                    <Button onClick={() => setShowAccountCancel(true)} style={{ ...styles.ButtonRed, width: 200 }}>{lang.send_request}</Button>
                                </div>
                            </div>
                            : null}

                        {index == 9 ?
                            // *********************************************************************************************************
                            // GDPR + VOP
                            // *********************************************************************************************************
                            <div style={{ ...styles.BlockCenter, maxWidth: 650, paddingTop: 10 }}>
                                {props.isSmall == true ? null :
                                    <p style={{ ...styles.TextNormal, fontWeight: '600' }}>{lang.gdpr_vop}</p>
                                }
                                <div style={{ ...styles.Block, marginTop: 80 }}>
                                    <p style={{ ...styles.TextSmall }}>{lang.gdpr}</p>
                                    <Button onClick={() => setShowGDPR(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 250, marginTop: 10 }}>
                                        <FontAwesomeIcon style={{ height: 14, color: global.theme_white, marginRight: 10 }} icon={faEye} />
                                        {lang.gdpr_read}
                                    </Button>
                                    <p style={{ ...styles.TextSmall, marginTop: 50 }}>{lang.vop}</p>
                                    <Button onClick={() => setShowVOP(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 250, marginTop: 10 }}>
                                        <FontAwesomeIcon style={{ height: 14, color: global.theme_white, marginRight: 10 }} icon={faEye} />
                                        {lang.gdpr_read}
                                    </Button>
                                </div>
                            </div>
                            : null}

                        {index == 99 ?
                            // *********************************************************************************************************
                            // ODHLASIŤ SA
                            // *********************************************************************************************************
                            <div style={{ ...styles.BlockCenter, paddingTop: 10 }}>
                                {props.isSmall == true ? null :
                                    <p style={{ ...styles.TextNormal, fontWeight: '600' }}>{lang.logout_title}</p>
                                }
                                <div style={{ ...styles.Block, marginTop: 20 }}>
                                    <Button onClick={() => setShowLogout(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, marginTop: 40 }}>{lang.logout}</Button>
                                </div>
                            </div>
                            : null}

                    </div>
                </div>
            </div >

            {
                showPassword == true ?
                    <DialogPasswordChange theme={props.theme} offset={props.offset} remoteApp={props.remoteApp} user_id={props.user.id} radius={props.radius} title={lang.password_change_} sub_title={lang.settings} backcolor={true} icon={faQuestion} background={false} lang={lang} func={DialogPasswordChangeResult.bind(this)} />
                    : null
            }

            {
                showLogout == true ?
                    <DialogYesNo theme={props.theme} radius={props.radius} title={lang.logout_label} sub_title={''} text={lang.logout_text} icon={faUser} yes={lang.yes} no={lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={Logout.bind(this)} />
                    : null
            }

            {
                showAccountCancel == true ?
                    <DialogWarningYesNo theme={props.theme} center red confirm lang={props.lang} radius={props.radius} title={lang.account_cancel_label} sub_title={''} text={''} sub_text={lang.account_cancel_question} sub_text_2={''} icon={faQuestion} yes={props.lang.send} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={CancelAccount.bind(this)} />
                    : null
            }

            {
                showAccountCancelOK == true ?
                    <DialogInfo theme={props.theme} radius={props.radius} title={lang.account_cancel_label} sub_title={''} text={lang.send_request_ok} sub_text={lang.account_cancel_ok + ': ' + props.user.email} icon={faInfoCircle} button={props.lang.close} icon_show={faCheckCircle} icon_color={global.theme_darker_green} func={() => setShowAccountCancelOK(false)} />
                    : null
            }

            {
                showEmployee == true ?
                    <DialogEmployee isSmall={props.isSmall} remoteApp={props.remoteApp} user_id={props.user.id} offset={props.offset} theme={props.theme} lang={lang} editing={employeeEditing} employee={employeeSelected} radius={props.radius} func={DialogEmployeeResult.bind(this)} />
                    : null
            }

            {
                showPin == true ?
                    <PinCode theme={props.theme} type={pinType} pin={pin} lang={lang} radius={props.radius} title={lang.settings_pin_enter_label} func={PinResult.bind(this)} />
                    : null
            }

            {
                showOK == true ?
                    <ShowOK side={menu_width} offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null
            }

            {
                showError == true ?
                    <ShowError theme={props.theme} text={props.lang.db_error} func={ShowErrorPress.bind(this)} />
                    : null
            }

            {
                showEmailError == true ?
                    <ShowError theme={props.theme} text={props.lang.email_exists} func={() => setShowEmailError(false)} />
                    : null
            }

            {
                showTokenError == true ?
                    <ShowTokenError theme={props.theme} remoteApp={props.remoteApp} text={lang.token_error} func={TokenErrorPress.bind(this)} />
                    : null
            }

            {
                showGDPR == true ?
                    <DialogAgreements theme={props.theme} typ={1} user={props.user} lang={lang} radius={props.radius} sub_title={lang.vision_system} backcolor={true} background={false} func={() => setShowGDPR(false)} />
                    : null
            }

            {
                showVOP == true ?
                    <DialogAgreements theme={props.theme} typ={21} user={props.user} lang={lang} radius={props.radius} sub_title={lang.vision_system} backcolor={true} background={false} func={() => setShowVOP(false)} />
                    : null
            }

            {
                isBusy == true || loading == true ?
                    <Loading side={menu_width} offset={props.offset} theme={props.theme}  ></Loading>
                    : null
            }

        </Paper >
    );
}


export const HoursSettings = (props) => {
    /*
            NASTAVENIE OTVARACICH HODIN / REZERVACNYCH
    */

    const [hours, setHours] = useState(false);
    const [equal, setEqual] = useState(props.equal);
    const [error, setError] = useState(false);

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {
        if (props.hours == false || props.hours == null) {
            let data = [
                { id: 0, od: '', do: '', obed_od: '', obed_do: '' },
                { id: 1, od: '08:00', do: '17:00', obed_od: '12:00', obed_do: '12:30' },
                { id: 2, od: '08:00', do: '17:00', obed_od: '12:00', obed_do: '12:30' },
                { id: 3, od: '08:00', do: '17:00', obed_od: '12:00', obed_do: '12:30' },
                { id: 4, od: '08:00', do: '17:00', obed_od: '12:00', obed_do: '12:30' },
                { id: 5, od: '08:00', do: '17:00', obed_od: '12:00', obed_do: '12:30' },
                { id: 6, od: '08:00', do: '12:00', obed_od: '', obed_do: '' },
            ];
            setHours(data);
        } else {
            setHours(props.hours);
        }

        return () => {
        };

    }, []);


    const ChangeHours = (id, index, value) => {
        if (equal == true) {
            if (index == 0) {
                hours[id].od = value;
            }
            if (index == 1) {
                hours[id].do = value;
            }
            if (index == 2) {
                hours[id].obed_od = value;
            }
            if (index == 3) {
                hours[id].obed_do = value;
            }
        } else {
            if (id == 1) {
                if (index == 0) {
                    hours[1].od = value;
                    hours[2].od = value;
                    hours[3].od = value;
                    hours[4].od = value;
                    hours[5].od = value;
                }
                if (index == 1) {
                    hours[1].do = value;
                    hours[2].do = value;
                    hours[3].do = value;
                    hours[4].do = value;
                    hours[5].do = value;
                }
                if (index == 2) {
                    hours[1].obed_od = value;
                    hours[2].obed_od = value;
                    hours[3].obed_od = value;
                    hours[4].obed_od = value;
                    hours[5].obed_od = value;
                }
                if (index == 3) {
                    hours[1].obed_do = value;
                    hours[2].obed_do = value;
                    hours[3].obed_do = value;
                    hours[4].obed_do = value;
                    hours[5].obed_do = value;
                }
            } else {
                if (index == 0) {
                    hours[id].od = value;
                }
                if (index == 1) {
                    hours[id].do = value;
                }
                if (index == 2) {
                    hours[id].obed_od = value;
                }
                if (index == 3) {
                    hours[id].obed_do = value;
                }
            }
        }

        func(hours);
    }

    const ChangeEqual = (value) => {
        if (value == false) {
            var n = 1;
            while (n < 6) {
                hours[n].od = hours[1].od;
                hours[n].do = hours[1].do;
                hours[n].obed_od = hours[1].obed_od;
                hours[n].obed_do = hours[1].obed_do;

                n++;
            }
        }
        setEqual(value);

        func(hours, value);
    }

    return (
        <div style={{ ...styles.BlockLeft }}>
            <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.settings_calendar_hours_equal}</p>}
                control={<Checkbox checked={equal} size={'small'} onChange={() => ChangeEqual(!equal)} />}
            />
            {hours != false ? equal == false ?
                hours.map((item, index) => (
                    item.id == 1 || item.id == 6 ?
                        <div key={item.id} style={{ ...styles.BlockRow, marginTop: 15 }}>
                            <div style={{ ...styles.BlockLeft }}>
                                <p style={{ ...styles.TextTiny, fontWeight: '600', marginTop: 25, textAlign: 'left' }}>{index == 1 ? lang.calendar_days : lang.days[index]}</p>
                            </div>
                            <div style={{ ...styles.BlockLeft }}>
                                <TextInput isSmall={props.isSmall} placeholder={'00:00'} numeric redraw enabled={true} lang={lang} value={hours[index].od} label={lang.time_from} func={(txt) => { ChangeHours(index, 0, txt) }} />
                            </div>
                            {props.isSmall ? null :
                                <div style={{ ...styles.Block, width: 20, marginLeft: 10 }}>
                                    <p style={{ ...styles.TextNormal, marginTop: 28 }}>-</p>
                                </div>
                            }
                            <div style={{ ...styles.BlockLeft, marginLeft: props.isSmall ? 3 : 10 }}>
                                <TextInput isSmall={props.isSmall} placeholder={'00:00'} numeric redraw enabled={true} lang={lang} value={hours[index].do} label={lang.to} func={(txt) => { ChangeHours(index, 1, txt) }} />
                            </div>
                            <div style={{ ...styles.BlockLeft, marginLeft: 10 }}>
                                <TextInput isSmall={props.isSmall} numeric redraw enabled={true} lang={lang} value={hours[index].obed_od} label={lang.dinner_from} func={(txt) => { ChangeHours(index, 2, txt) }} />
                            </div>
                            {props.isSmall ? null :
                                <div style={{ ...styles.Block, width: 20, marginLeft: 10 }}>
                                    <p style={{ ...styles.TextNormal, marginTop: 28 }}>-</p>
                                </div>
                            }
                            <div style={{ ...styles.BlockLeft, marginLeft: props.isSmall ? 3 : 10 }}>
                                <TextInput isSmall={props.isSmall} numeric redraw enabled={true} lang={lang} value={hours[index].obed_do} label={lang.dinner_to} func={(txt) => { ChangeHours(index, 3, txt) }} />
                            </div>
                        </div>
                        : null
                ))
                :
                hours.map((item, index) => (
                    index > 0 ?
                        <div key={item.id} style={{ ...styles.BlockRow, marginTop: 15 }}>
                            <div style={{ ...styles.BlockLeft }}>
                                <p style={{ ...styles.TextTiny, fontWeight: '600', marginTop: 25, textAlign: 'left' }}>{lang.days[index]}</p>
                            </div>
                            <div style={{ ...styles.BlockLeft }}>
                                <TextInput isSmall={props.isSmall} placeholder={'00:00'} numeric redraw enabled={true} lang={lang} value={hours[index].od} label={lang.time_from} func={(txt) => { ChangeHours(index, 0, txt) }} />
                            </div>
                            {props.isSmall ? null :
                                <div style={{ ...styles.Block, width: 20, marginLeft: 10 }}>
                                    <p style={{ ...styles.TextNormal, marginTop: 28 }}>-</p>
                                </div>
                            }
                            <div style={{ ...styles.BlockLeft, marginLeft: props.isSmall ? 3 : 10 }}>
                                <TextInput isSmall={props.isSmall} placeholder={'00:00'} numeric redraw enabled={true} lang={lang} value={hours[index].do} label={lang.to} func={(txt) => { ChangeHours(index, 1, txt) }} />
                            </div>
                            <div style={{ ...styles.BlockLeft, marginLeft: 10 }}>
                                <TextInput isSmall={props.isSmall} numeric redraw enabled={true} lang={lang} value={hours[index].obed_od} label={lang.dinner_from} func={(txt) => { ChangeHours(index, 2, txt) }} />
                            </div>
                            {props.isSmall ? null :
                                <div style={{ ...styles.Block, width: 20, marginLeft: 10 }}>
                                    <p style={{ ...styles.TextNormal, marginTop: 28 }}>-</p>
                                </div>
                            }
                            <div style={{ ...styles.BlockLeft, marginLeft: props.isSmall ? 3 : 10 }}>
                                <TextInput isSmall={props.isSmall} numeric redraw enabled={true} lang={lang} value={hours[index].obed_do} label={lang.dinner_to} func={(txt) => { ChangeHours(index, 3, txt) }} />
                            </div>
                        </div>
                        : null
                ))
                : null}
            {hours != false ?
                <div style={{ ...styles.BlockRow, marginTop: 15 }}>
                    <div style={{ ...styles.BlockLeft }}>
                        <p style={{ ...styles.TextTiny, fontWeight: '600', marginTop: 25, textAlign: 'left' }}>{lang.days[0]}</p>
                    </div>
                    <div style={{ ...styles.BlockLeft }}>
                        <TextInput isSmall={props.isSmall} placeholder={'00:00'} numeric redraw enabled={true} lang={lang} value={hours[0].od} label={lang.time_from} func={(txt) => { ChangeHours(0, 0, txt) }} />
                    </div>
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: 20, marginLeft: 10 }}>
                            <p style={{ ...styles.TextNormal, marginTop: 28 }}>-</p>
                        </div>
                    }
                    <div style={{ ...styles.BlockLeft, marginLeft: props.isSmall ? 3 : 10 }}>
                        <TextInput isSmall={props.isSmall} placeholder={'00:00'} numeric redraw enabled={true} lang={lang} value={hours[0].do} label={lang.to} func={(txt) => { ChangeHours(0, 1, txt) }} />
                    </div>
                    <div style={{ ...styles.BlockLeft, marginLeft: 10 }}>
                        <TextInput isSmall={props.isSmall} numeric redraw enabled={true} lang={lang} value={hours[0].obed_od} label={lang.dinner_from} func={(txt) => { ChangeHours(0, 2, txt) }} />
                    </div>
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: 20, marginLeft: 10 }}>
                            <p style={{ ...styles.TextNormal, marginTop: 28 }}>-</p>
                        </div>
                    }
                    <div style={{ ...styles.BlockLeft, marginLeft: props.isSmall ? 3 : 10 }}>
                        <TextInput isSmall={props.isSmall} numeric redraw enabled={true} lang={lang} value={hours[0].obed_do} label={lang.dinner_to} func={(txt) => { ChangeHours(0, 3, txt) }} />
                    </div>
                </div>
                : null}

        </div>
    );
}

