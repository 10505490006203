/*
*
* =================================================================================================================
* REKLAMACIA - zobrazenie na webe
* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { Debug, DialogImagePreview, GetDate, GetImageLink, GetLanguage, Init, Loading, LoadLanguage, LoadLogin, Menu, MenuTitle, MenuTop, ResetLogin, SaveLanguage } from './items';
import { styles } from './styles';
import './items.css';
import { Button, CircularProgress, Paper, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GoogleMapReact from 'google-map-react';

// IMAGES
import logo from './react/app/logo.png';

import { faAngleLeft, faGlobe, faMap } from '@fortawesome/free-solid-svg-icons';

export const Geolocation = () => {
    const navigate = useNavigate();
    let params = useParams();

    const [isBusy, setBusy] = useState(false);
    const [radius, setRadius] = useState(0);
    const [token, setToken] = useState(false);

    const [showMap, setShowMap] = useState(false);
    const [lat, setLat] = useState(48);
    const [lng, setLng] = useState(18);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    //const isLarge = useMediaQuery('(min-width:' + global.device_medium + 1 + 'px)');
    //const isMedium = useMediaQuery('(min-width:' + global.device_small + 1 + 'px; max-width:' + global.device_medium + 1 + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const no_image = require('./react/app/no_image.jpg');
    const pin = require('./react/app/pin.png');

    var started = false;
    var lang = GetLanguage(params.language);
    var theme = global.themes[params.theme];

    useEffect(() => {

        if (started == false) {
            started = true;

            var login = LoadLogin();
            var token_ = login.token;
            if (token_ != '') {
                setToken(token_);
                console.log(token_);
            }

            if (window.innerWidth <= global.device_small) {
                // MOBILE
                setRadius(0);
            } else {
                // DESKTOP
                setRadius(global.radius);

                // RELOAD PAGE - AK SA ZMENI VELKOST OKNA               
                function handleResize() {
                    setWidth(window.innerWidth);
                    setHeight(window.innerHeight);
                }
                //window.addEventListener('resize', handleResize);
            }

            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }

    }, [window.innerWidth])

    const GoBack = () => {
        navigate('/application/remote');
    }

    const ShowMap = () => {
        setShowMap(true);
    }

    const AnyReactComponent = ({ text }) => <div style={{ position: 'absolute', top: -40, left: -20, width: 40, height: 40 }}>
        <img src={pin} height={40} />
    </div>;

    return (
        <div style={{ ...styles.Block, height: height, width: '100%', fontFamily: 'roboto', backgroundImage: `url(${theme.background_image})`, overflowY: 'scroll' }}>
            <div style={{ ...styles.Block, width: isSmall ? width : 600, height: showMap == true ? 600 : 350, borderRadius: 20, paddingTop: 20, paddingBottom: 20, backgroundColor: '#000000A0' }}>
                <p style={{ ...styles.TextLarge, color: global.theme_white, marginBottom: 20, fontWeight: '500' }}>{showMap == true ? lang.geo_map : lang.geo}</p>

                {showMap == false ?
                    <img src={logo} style={{ width: '100%', height: '100%', maxWidth: 300, maxHeight: 120, objectFit: 'contain' }}></img>
                    :
                    <SalonMap width={isSmall ? 300 : 400} height={isSmall ? 300 : 400} />
                }
                {showMap == false ?
                    <Button onClick={() => ShowMap()} style={{ ...styles.ButtonDark, backgroundColor: global.theme_dark_green, width: 220, marginTop: 20 }}>
                        <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faGlobe} />
                        {lang.geo_show_map}
                    </Button>
                    : null}

                <Button onClick={() => GoBack()} style={{ ...styles.ButtonDark, color: global.theme_black, backgroundColor: theme.theme_light, marginTop: 50, width: 220 }}>
                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginRight: 10 }} icon={faAngleLeft} />
                    {lang.back_app}
                </Button>
            </div>

            {isBusy == true ?
                <Loading center={true} />
                : null}

        </div >
    );
}



export const SalonMap = (props) => {

    /*
        ZOBRAZENIE SALONU NA MAPE
        
        USAGE:
        <SalonMap width={400} height={400} />
        
    */

    const [isBusy, setBusy] = useState(true);
    const [lat, setLat] = useState(48);
    const [lng, setLng] = useState(18);
    const [showMap, setShowMap] = useState(false);
    const pin = require('./react/app/pin.png');

    var started = false;

    useEffect(() => {

        if (started == false) {
            started = true;

            var login = LoadLogin();
            var token = login.token;
            if (token != '') {
                db_get(token)
            }
        }

    }, [])

    const db_get = async (token) => {
        try {
            const response = await fetch(
                global.db_url + 'google_map', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setLat(json.lat);
                setLng(json.lng);
                setTimeout(() => {
                    setShowMap(true);
                }, 250);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const AnyReactComponent = ({ text }) => <div style={{ position: 'absolute', top: -40, left: -20, width: 40, height: 40 }}>
        <img src={pin} height={40} />
    </div>;

    return (
        <div style={{ height: props.height == undefined ? 400 : props.height, width: props.width == undefined ? 400 : props.width }}>
            {showMap == true ?
                // V GOOGLE ÚCTE POVOLIŤ WEBOVÚ ADRESU
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyCVLKeXod-XpjnLLg1MEbf_wO5OFc5Wv3g' }}
                    defaultCenter={{ lat: parseFloat(lat), lng: parseFloat(lng) }}
                    defaultZoom={17}
                >
                    <AnyReactComponent
                        lat={lat}
                        lng={lng}
                        text="SALON"
                    />
                </GoogleMapReact>
                : null}

            {isBusy == true ?
                <Loading center={true} />
                : null}

        </div>

    );
}



