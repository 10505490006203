import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, ConvertToPrice, CopyData, Debug, DialogEnterText, DialogImagePreview, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, FormOK, GetDate, GetImageLink, GetMoney, GetPrice, GetTodayYear, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, SelectYear, ShowError, ShowOK, TemplateWindow } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, CircularProgress, FormControlLabel, IconButton, InputAdornment, MenuItem, Pagination, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faAt, faCheck, faCheckCircle, faComment, faEdit, faIdCard, faImage, faInfo, faInfoCircle, faL, faLayerGroup, faList, faListAlt, faListCheck, faPeace, faPen, faPenAlt, faPlusCircle, faQuestion, faQuestionCircle, faSave, faShare, faShoppingBag, faShoppingBasket, faShoppingCart, faTrash, faUser, faWarning, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';


export const ReklamacieSalon = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);
    const [money, setMoney] = useState('');

    // PREDAJ - ZOZNAM
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const itemsMax = 24;

    // NAHLAD
    const [itemSelected, setItemSelected] = useState(false);
    const [showOrder, setShowOrder] = useState(false);
    const [myOrders, setMyOrders] = useState(false);

    // AKTUALNY ROK - EXPORT
    const [actualYear, setActualYear] = useState(GetTodayYear());
    const [showExport, setShowExport] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [sended, setSended] = useState(false);

    const menuWidth = props.isSmall ? 0 : 250;
    const rowHeight = props.isSmall ? 120 : 80;
    const rowWidth = 850;

    const no_image = require('./react/app/no_image.jpg');

    // Aktuálna farebna téma
    const color_theme = global.themes[props.theme];

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            let year = new Date().getFullYear();
            db_get(0, year);
        }

    }, []);

    const db_get = async (typ, year) => {
        setBusy(true);
        setItems(false);
        setItemsFiltered(false);
        try {
            const response = await fetch(
                global.db_url + 'reklamacie_salon', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    language: props.language,
                    year: year
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const Search = (text) => {
        setSearch(text);
        Redraw(items, 0, text, myOrders);
    }

    const Redraw = (items, p, search, mine) => {
        // REDRAW PRODUKTOV
        var data = items;

        if (mine == true) {
            data = data.filter(x => x.agent_id == props.user.id);
        }

        if (search.trim() != '') {
            // filtrovanie podla hladaneho retazca
            data = data.filter(x => x.number.toLowerCase().includes(search.toLowerCase()));
        }

        var count = data.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax)
        setItemsFiltered(data);

        if (count == 0) {
            setItemsFiltered(false);
        }

        setPage(p);
        GoToStart();
    }


    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);

        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0, '', myOrders);
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_, search, myOrders);
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_orders');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    // ------------------------------------------------------------------------------------
    // NAHLAD OBJEDNAVKY
    // ------------------------------------------------------------------------------------
    const Edit = (item) => {
        setItemSelected(item);
        setShowOrder(true);
    }

    const ReklamaciaResult = (value, value2) => {
        setShowOrder(false);
        if (value == true || value2 == true) {
            db_get(index, actualYear);
        }
    }

    const ChangeMyOrders = (value) => {
        setMyOrders(value);
        Redraw(items, 0, search, value);
    }

    const ChangeYear = (year, month) => {
        setActualYear(year)
        db_get(index, year);
    }


    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? '45%' : '45%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.isSmall ? lang.reklamacie : props.title}</p>
                        {props.isSmall ? null :
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        }
                    </div>
                </div>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, justifyContent: 'center', alignItems: 'flex-end', width: '20%', height: global.dialog_bar_height }}>
                        {props.admin == true && index != 99 ?
                            <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.show_mine}</p>}
                                control={<Checkbox checked={myOrders} size={'small'} onChange={() => ChangeMyOrders(!myOrders)} />}
                            />
                            : null}
                    </div>
                }
                <div style={{ ...styles.BlockRow, justifyContent: 'flex-end', alignItems: 'center', width: props.isSmall ? 160 : '30%', height: global.dialog_bar_height }}>
                    <TextField
                        value={search}
                        onInput={e => Search(e.target.value)}
                        size="small"
                        autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off"
                        style={{ width: props.isSmall ? 150 : 300 }}
                        placeholder={lang.search_in_category}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                            }
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                    <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                </IconButton>
                            </InputAdornment>,
                            style: { backgroundColor: global.theme_white, borderRadius: 30 },
                        }}
                        variant="outlined"
                    />
                </div>
                <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                    <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockCenter, width: props.width, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>

                            <div id={'id_orders'} style={{ ...styles.BlockCenter, width: '98%' }}>

                                <div style={{ ...styles.BlockRight, maxWidth: rowWidth }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <div style={{ ...styles.Block, height: 60 }}>
                                            <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.reklamacie_year_list}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 200, height: 60 }}>
                                            <SelectYear min={2020} value={actualYear} func={ChangeYear.bind(this)} />
                                        </div>
                                    </div>
                                </div>

                                {/* *********************************************************************************************************  
                                            ZOZNAM REKLAMACII
                                    ********************************************************************************************************* */}

                                {itemsFiltered != false ?
                                    itemsFiltered.map((item, index) => (
                                        <Paper elevation={overID == item.id ? 5 : 2} key={item.id} style={{ ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: global.theme_gray, marginTop: 8, marginBottom: 8, borderRadius: global.row_radius, cursor: 'pointer' }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow }}>
                                                <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: props.isSmall ? 50 : rowHeight, justifyContent: 'center' }}>
                                                    <div style={{ ...styles.Block, maxHeight: rowWidth }}>
                                                        <div style={{ ...styles.Block, height: 36, width: 36, backgroundColor: global.reklamacia_back_color[item.status], borderRadius: '50%' }}>
                                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_black, color: global.reklamacia_color[item.status] }} icon={global.reklamacia_icon[item.status]} />
                                                        </div>
                                                    </div>
                                                </div>
                                                {props.isSmall ?
                                                    <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                        <p style={{ ...styles.TextTinyMobile, fontWeight: '600', textAlign: 'left', marginBottom: 10 }}>{item.typ == 0 ? lang.reklamacia_products : lang.reklamacia_bonus} <span style={{ fontSize: global.font_xtiny, fontWeight: '400' }}>{item.number}</span></p>
                                                        {item.salon != false ?
                                                            <p style={{ ...styles.TextTinyMobile, fontWeight: '400', textAlign: 'left' }}>{item.salon}</p>
                                                            : null}
                                                        <p style={{ ...styles.TextTiny }}>{GetDate(item.date)}</p>
                                                        {item.reklamacia == true ?
                                                            <p style={{ ...styles.TextTiny, color: global.theme_dark_red, fontWeight: '600', marginTop: 10 }}>{lang.booking_reklamacia_true}</p>
                                                            :
                                                            <p style={{ ...styles.TextTiny, fontWeight: '600', marginTop: 10 }}>{lang.reklamacia_status[item.status]}</p>
                                                        }
                                                    </div>
                                                    :
                                                    <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                        <div style={{ ...styles.BlockRow }}>
                                                            <div style={{ ...styles.BlockLeft, width: '30%' }}>
                                                                <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.typ == 0 ? lang.reklamacia_products : lang.reklamacia_bonus}</p>
                                                                <p style={{ ...styles.TextTiny, fontWeight: '400', textAlign: 'left' }}>{item.number}</p>
                                                            </div>
                                                            <div style={{ ...styles.BlockRight, width: '70%' }}>
                                                            </div>

                                                        </div>
                                                        <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                            <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                                <div style={{ ...styles.BlockLeft, width: '30%' }}>
                                                                    <div style={{ ...styles.BlockRowRaw }}>
                                                                        <p style={{ ...styles.TextTiny }}>{GetDate(item.date)}</p>
                                                                    </div>

                                                                </div>
                                                                <div style={{ ...styles.Block, width: '30%' }}>
                                                                </div>
                                                                <div style={{ ...styles.BlockRight, width: '40%' }}>
                                                                    {item.reklamacia == true ?
                                                                        <p style={{ ...styles.TextTiny, color: global.theme_dark_red, fontWeight: '600' }}>{lang.booking_reklamacia_true}</p>
                                                                        :
                                                                        <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.reklamacia_status[item.status]}</p>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </Paper>
                                    )) :
                                    // ZIADNE OBJEDNAVKY                            
                                    <div style={{ ...styles.Block, paddingTop: 20 }}>
                                        <EmptyList lang={lang} />
                                    </div>
                                }

                                {itemsFiltered != false ?
                                    pageMax > 1 ?
                                        <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                            <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                        </div>
                                        : null
                                    : null}
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {showOrder == true ?
                <ReklamaciaDetailsSalon isSmall={props.isSmall} offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} remoteApp={props.remoteApp} admin={props.admin} countries={props.countries} country={props.country} reklamacia_id={itemSelected.id} lang={lang} radius={props.radius} backcolor={true} background={true} language={props.language} func={ReklamaciaResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK side={menuWidth} offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading side={menuWidth} offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Paper >
    );
}


export const ReklamaciaDetailsSalon = (props) => {


    const [isBusy, setBusy] = useState(false);
    const [order, setOrder] = useState(false);
    const [showOrder, setShowOrder] = useState(false);
    const [reklamaciaStatus, setReklamaciaStatus] = useState(0);
    const [items, setItems] = useState(false);
    const [changes, setChanges] = useState(false);
    const [money, setMoney] = useState('');
    const [showOK, setShowOK] = useState(false);

    // UDAJE O ZAKAZNIKOVI
    const [salon, setSalon] = useState(false);

    // Reklamacia
    const [reklamacia, setReklamacia] = useState(false);
    const [enterText, setEnterText] = useState(false);
    const [showReasonEdit, setShowReasonEdit] = useState(false);

    // Obrázky
    const [images, setImages] = useState(false);
    const [image, setImage] = useState(false);
    const [showImage, setShowImage] = useState(false);

    // cislo objednavky
    const [number, setNumber] = useState('');

    // Komentár k reklamácii
    const [showComment, setShowComment] = useState(false);
    const [comment, setComment] = useState('');

    // kontrola zmeny
    const [changed, setChanged] = useState(false);

    // Rozmery okna
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1200 ? 1200 : width;
    const dialogHeight = height >= 850 ? 850 : height;

    const infoHeight = 34;
    const buttonHeight = 60;
    const rowHeight = 100;
    const labelHeight = props.isSmall ? 60 : 100;
    const imageSize = 275;
    const rowOffset = 35;

    const no_image = require('./react/app/no_image.jpg');


    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            db_get();

        }
        return () => {
        };

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'reklamacia', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language,
                    reklamacia_id: props.reklamacia_id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setItems(json.products);
                setReklamacia(json.reklamacia);
                setReklamaciaStatus(json.reklamacia.status);
                setNumber(json.reklamacia.number);
                setSalon(json.salon);
                setImages(json.images);
                setOrder(json.order);
                setComment(json.reklamacia.comment);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = () => {
        var redraw = false;
        if (reklamaciaStatus != reklamacia.status) {
            redraw = true;
        }
        if (changed == true) {
            redraw = true;
        }
        func(redraw);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const Preview = (item) => {
        let data = {
            file: item.path + item.file
        }
        setImage(data);
        setShowImage(true);
    }


    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: labelHeight, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.BlockCenter, width: 100, height: labelHeight, justifyContent: 'center' }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faShoppingCart} />
                        </div>
                    </div>
                    {props.isSmall ?
                        <div style={{ ...styles.BlockLeft, width: '85%', height: labelHeight, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{lang.reklamacia}</p>
                        </div>
                        :
                        <div style={{ ...styles.BlockLeft, width: '45%', height: labelHeight, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextTiny }}>{lang.dodaci_list_cislo}:</p>
                            <p style={{ ...styles.TextTiny, fontWeight: '600', marginTop: 1 }}>{reklamacia.dodaci_list}</p>

                            <p style={{ ...styles.TextTiny, marginTop: 10 }}>{lang.reklamacia_reason}:</p>
                            <p style={{ ...styles.TextTiny, fontWeight: '600', marginTop: 1 }}>{reklamacia.reason}</p>
                        </div>
                    }
                    {props.isSmall ? null :
                        <div style={{ ...styles.BlockLeft, width: '40%', height: labelHeight, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.faktura_cislo}:</p>
                            <p style={{ ...styles.TextTiny, fontWeight: '600', marginTop: 1 }}>{reklamacia.faktura_cislo}</p>
                            <p style={{ ...styles.TextTiny, marginTop: 5, marginTop: 10 }}>{lang.faktura_date}:</p>
                            <p style={{ ...styles.TextTiny, fontWeight: '600', marginTop: 1 }}>{reklamacia.faktura_date != null ? GetDate(reklamacia.faktura_date) : ''}</p>
                        </div>
                    }
                    <div style={{ ...styles.BlockCenter, width: 100, height: labelHeight, justifyContent: 'center' }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - labelHeight, backgroundColor: props.background == true ? global.theme_back : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '100%', height: dialogHeight - labelHeight - buttonHeight }}>
                        {/* BODY */}
                        <div style={{ ...styles.BlockCenter, width: '100%', height: dialogHeight - labelHeight - buttonHeight, overflowY: 'scroll', paddingBottom: 10 }}>
                            {/* *********************************************************************************************************  
                                KOMENTAR PRE ZAKAZNIKA
                            ********************************************************************************************************* */}
                            {comment != '' ?
                                <div elevation={2} onClick={() => setShowComment(true)} style={{ ...styles.Block, width: '96%', backgroundColor: global.light_gray, marginTop: 5, marginBottom: 5, borderRadius: 10, cursor: 'pointer' }}>
                                    <div style={{ ...styles.BlockLeft, width: '98%' }}>
                                        <p style={{ ...props.isSmall ? styles.TextTinyMobile : styles.TextSmall, textAlign: 'left', marginTop: 10, fontWeight: '600', color: global.theme_darker_gray }}>{lang.reklamacie_message_agent}:</p>
                                        <p style={{ ...props.isSmall ? styles.TextTinyMobile : styles.TextSmall, textAlign: 'left', marginTop: 5, marginBottom: 10, color: global.theme_darker_gray }}>{comment}</p>
                                    </div>
                                </div>
                                : null}

                            {/* *********************************************************************************************************  
                                DOVOD ZAMIETNUTIA
                            ********************************************************************************************************* */}
                            {reklamacia.status == 3 ?
                                <Paper elevation={2} style={{ ...styles.Block, width: '96%', backgroundColor: global.light_gray, marginTop: 5, marginBottom: 5, borderRadius: 10 }}>
                                    <div style={{ ...styles.BlockLeft, width: '98%' }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            <div style={{ ...styles.BlockLeft, width: '98%' }}>
                                                <p style={{ ...props.isSmall ? styles.TextTinyMobile : styles.TextSmall, textAlign: 'left', marginTop: 10, fontWeight: '600' }}>{lang.reklamacie_deny_reason}:</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 30 }}>
                                            </div>
                                        </div>

                                        <p style={{ ...props.isSmall ? styles.TextTinyMobile : styles.TextSmall, textAlign: 'left', marginTop: 5, marginBottom: 10 }}>{reklamacia.deny_reason}</p>
                                    </div>
                                </Paper>
                                : null}

                            {/* *********************************************************************************************************  
                                TEXT REKLAMACIE
                            ********************************************************************************************************* */}
                            {reklamacia.message != '' ?
                                <Paper elevation={2} style={{ ...styles.Block, width: '96%', backgroundColor: global.light_gray, marginTop: 5, marginBottom: 5, borderRadius: 10 }}>
                                    <div style={{ ...styles.BlockLeft, width: '98%' }}>
                                        <p style={{ ...props.isSmall ? styles.TextTinyMobile : styles.TextSmall, textAlign: 'left', marginTop: 10, fontWeight: '600' }}>{lang.booking_reklamacia_text}:</p>
                                        <p style={{ ...props.isSmall ? styles.TextTinyMobile : styles.TextSmall, textAlign: 'left', marginTop: 5, marginBottom: 10 }}>{reklamacia.message}</p>
                                    </div>
                                </Paper>
                                : null}


                            {/* *********************************************************************************************************  
                                ZOZNAM PRODUKTOV
                            ********************************************************************************************************* */}
                            <div style={{ ...styles.Block, height: infoHeight }}>
                                <p style={{ ...styles.TextSmall, color: global.theme_dark }}>{lang.products_list}</p>
                            </div>

                            {items != false ?
                                items.map((item, index) => (
                                    <Paper elevation={2} key={item.id} style={{ ...styles.Block, width: '96%', height: rowHeight, backgroundColor: global.theme_lighter, marginTop: 5, marginBottom: 5, borderRadius: rowHeight / 2 }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            <div style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                <img src={item.image == null || item.image == '' ? no_image : item.image.substring(0, 4) == 'http' ? item.image : global.web_images + '/' + item.image} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                            </div>
                                            {props.isSmall ?
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '75%', justifyContent: 'center', marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextTinyMobile, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                    <p style={{ ...styles.TextTinyMobile, marginTop: 0 }}>{item.code} <span style={{ ...styles.TextTiny }}>{item.details}</span></p>
                                                    <p style={{ ...styles.TextTinyMobile, marginTop: 0 }}>{item.booking_reklamacia_quantity} <span style={{ ...styles.TextTiny }}>{item.quantity}</span></p>
                                                </div>
                                                :
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '75%', justifyContent: 'center', marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                    <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                        <p style={{ ...styles.TextTiny, marginTop: 0 }}>{item.code} <span style={{ ...styles.TextTiny }}>{item.details}</span></p>
                                                    </div>
                                                </div>
                                            }
                                            {props.isSmall ? null :
                                                <div style={{ ...styles.Block, height: rowHeight, width: 250, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, marginTop: 0 }}>{lang.booking_reklamacia_quantity}</p>
                                                    <p style={{ ...styles.TextTiny, marginTop: 3 }}><b>{item.quantity}</b></p>
                                                </div>
                                            }
                                        </div>
                                    </Paper>
                                )) :
                                // ZIADNE PRODUKTY                            
                                <div style={{ ...styles.Block, paddingTop: 20 }}>
                                    <EmptyList small row lang={lang} />
                                </div>
                            }

                            {images != false ?
                                // FOTOGRAFIE
                                <div style={{ ...styles.Block, marginTop: rowOffset }}>
                                    <p style={{ ...styles.TextSmall, color: global.theme_dark }}>{lang.photos}</p>
                                    <div style={{ ...styles.Block, flexDirection: 'row', flexWrap: 'wrap' }}>
                                        {images.map((item, index) => (
                                            <Paper onClick={() => Preview(item)} elevation={2} key={item.id} style={{ ...styles.Block, width: imageSize, margin: 10, cursor: 'pointer' }}>
                                                <img src={GetImageLink(item.path + item.file)} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain' }}></img>
                                            </Paper>
                                        ))}
                                    </div>
                                </div>
                                : <div style={{ ...styles.Block, paddingTop: 20 }}>
                                    <p style={{ ...styles.TextSmall, color: global.theme_dark, marginBottom: 10 }}>{lang.photos}</p>
                                    <EmptyList small row lang={lang} />
                                </div>
                            }

                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '100%', height: buttonHeight, backgroundColor: global.themes[props.theme].theme_lighter, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                        <div style={{ ...styles.Block, width: '50%', height: buttonHeight, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextTiny }}>{lang.booking_reklamacia_status}: <b>{reklamacia != false ? lang.reklamacia_status[reklamaciaStatus] : null}</b></p>
                        </div>
                    </div>
                </div>
            </Paper>

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop >
    );
}