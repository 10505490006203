/*
*
* =================================================================================================================
* STATISTIKA PO JEDNOTLIVYCH PRODUKTOCH
* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';
import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, CopyData, Debug, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, GetPrice, GetTodayYearMonth, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, ShowError, ShowOK, TemplateWindow } from './items';
import { styles } from './styles';
import { Backdrop, Button, IconButton, InputAdornment, MenuItem, Pagination, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faL, faLayerGroup, faList, faListAlt, faQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';
import { ProductDialog, ProductPreview } from './product_dialog';
import { Graph } from './graph';


export const StatsProductsInfo = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);

    // KATEGORIE
    const [categories, setCategories] = useState(false);
    const [subCategories, setSubCategories] = useState(false);
    const [subCategoryID, setSubCategoryID] = useState(0);

    // PRODUKTY
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const itemsMax = global.items_max;

    // EDITING + NOVY PRODUKT
    const [itemSelected, setItemSelected] = useState(false);
    const [editing, setEditing] = useState(false);
    const [showProduct, setShowProduct] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const menuWidth = props.isSmall ? 0 : 250;
    const rowHeight = 100;
    const rowWidth = 850;

    // COLORS
    const font_selected = global.theme_black;
    const font_disabled = global.theme_darker;

    const no_image = require('./react/app/no_image.jpg');

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;
            db_get();
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'categories', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    language: props.language,
                    default: lang.products_my,
                    action: lang.action,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;
                tmp = tmp.filter(x => x.enabled == true);
                setCategories(tmp);
                if (tmp != false) {
                    db_products(tmp[0].id);
                    setIndex(tmp[0].id);
                }
            }
            //setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_products = async (category_id) => {

        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'products', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    category_id: category_id,
                    language: props.language,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setSubCategories(json.categories);
                setSubCategoryID(0);
                var tmp = json.products;

                if (tmp != false) {
                    tmp = tmp.filter(x => x.enabled == true);

                    tmp.sort((a, b) => {
                        if (a.label > b.label) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_search = async (search) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_search', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    search: search,
                    language: props.language,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setSubCategories(false);
                setSubCategoryID(0);
                var tmp = json.products;

                if (tmp != false) {
                    tmp.sort((a, b) => {
                        if (a.label > b.label) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })

                    setItems(tmp);
                    PageInit(tmp);
                    setIndex(-1);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                    setIndex(-1);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ChangeIndex = (id) => {
        setIndex(id);
        setSubCategoryID(0);
        setSearch('');
        db_products(id);
    }

    const CategorySelectResult = (id) => {
        setSubCategoryID(id);
        Redraw(items, 0, search, id);
    }

    const Search = (text) => {
        setSearch(text);
        /*
        setSubCategoryID(0);
        Redraw(items, 0, text, 0);
        */
        if (text.trim().length > 2) {
            db_search(text.trim());
        }
        if (text == '') {
            setItems(false);
            setItemsFiltered(false);
            setIndex(-1);
        }
    }

    const Redraw = (items, p, search, sub_category) => {
        // REDRAW PRODUKTOV
        if (items != false) {
            var data = items;
            if (search.trim() != '') {
                // filtrovanie podla hladaneho retazca
                //var words = search.split(' ');
                //data = items.filter(x => FindWords(x.label, x.keywords, words));
            } else {

                if (sub_category > 0) {
                    // filtrovanie vybratej kategórie
                    data = items.filter(x => parseInt(x.category_id) == sub_category);
                }

            }

            var count = data.length;
            count = Math.ceil(count / itemsMax);
            setPageMax(count);

            data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax)
            setItemsFiltered(data);

            if (count == 0) {
                setItemsFiltered(false);
            }

            setPage(p);
            GoToStart();
        }
    }

    function FindWords(label, keywords, words) {
        // hladanie retazca v nazve a keywords
        var result = 0;
        words.forEach(word => {
            if (label.toLowerCase().includes(word.toLowerCase()) || keywords.toLowerCase().includes(word.toLowerCase())) {
                result += 1;
            }

        });
        // ak je pocet najdených slov == poctu hladaných slov -> je zhoda
        return words.length == result;
    }

    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);

        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0, '', 0);
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_, search, subCategoryID);
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_products');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }


    const ShowProduct = (item) => {
        setItemSelected(item);
        setShowProduct(true);
    }

    const ProductDialogResult = (value, data) => {
        setShowProduct(false);
    }

    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? '50%' : '90%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                        <p style={{ ...styles.TextDialogSubLabel, marginTop: 4, textAlign: 'left' }}>{props.sub_title}</p>
                    </div>
                </div>
                <div style={{ ...styles.BlockRow, justifyContent: 'flex-end', alignItems: 'center', width: props.isSmall ? 160 : '50%', height: global.dialog_bar_height }}>
                    <TextField
                        value={search}
                        onInput={e => Search(e.target.value)}
                        size="small"
                        autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off"
                        style={{ width: props.isSmall ? 150 : 300 }}
                        placeholder={lang.search_in_category}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                            }
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                    <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                </IconButton>
                            </InputAdornment>,
                            style: { backgroundColor: global.theme_white, borderRadius: 30 },
                        }}
                        variant="outlined"
                    />

                </div>
                <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                    {props.isSmall ?
                        <div style={{ ...styles.Block }}>
                            <Select
                                value={index}
                                onChange={event => ChangeIndex(event.target.value)}
                                size='small'
                                style={{ backgroundColor: global.theme_white, width: '100%' }}
                            >

                                {categories != false ?
                                    categories.map((item) => (
                                        item.typ != 100 ?
                                            <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                                            : null
                                    ))
                                    : null}
                            </Select>
                        </div>
                        :
                        <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                            <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                {categories != false ?
                                    categories.map((item) => (
                                        <Button key={item.id} onClick={() => ChangeIndex(item.id)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == item.id ? global.theme_back : '#00000000', color: index == item.id ? font_selected : font_disabled }}>
                                            <div style={{ ...styles.BlockLeft }}>
                                                <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{item.label}</p>
                                                <p style={{ ...styles.TextTiny, color: global.themes[props.theme].theme_darker, marginTop: 2, lineHeight: 1, textAlign: 'left' }}>{item.brand}</p>
                                            </div>
                                        </Button>
                                    ))
                                    : null}

                            </div>
                        </div>
                    }
                    <div style={{ ...styles.BlockCenter, width: props.isSmall ? width : props.width - menuWidth, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                        {/* BODY */}
                        <div id={'id_products'}>
                            {subCategories != false ?
                                subCategories.length > 1 ?
                                    <CategorySelect background={global.theme_back} width={props.width - menuWidth} items={subCategories} value={subCategoryID} func={CategorySelectResult.bind(this)} />
                                    : null
                                : null}
                        </div>

                        {/* *********************************************************************************************************  
                                ZOZNAM PRODUKTOV
                        ********************************************************************************************************* */}
                        {itemsFiltered != false ?
                            itemsFiltered.map((item, index) => (
                                <Paper elevation={overID == item.id ? 5 : 2} onClick={() => ShowProduct(item)} key={item.id} style={{ ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: global.theme_lighter, marginTop: 5, marginBottom: 5, borderRadius: rowHeight / 2, cursor: 'pointer' }}
                                    onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                >
                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                            <img src={item.image == null || item.image == '' ? no_image : item.image.substring(0, 4) == 'http' ? item.image : global.web_images + '/' + item.image} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                        </div>
                                        {props.isSmall ?
                                            <div style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                <p style={{ ...styles.TextTiny, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                <p style={{ ...styles.TextTiny, marginTop: 0 }}>{item.code}</p>
                                            </div>
                                            :
                                            <div style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                    <p style={{ ...styles.TextTiny, marginTop: 0 }}>{item.code}</p>
                                                    <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                                        <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{item.note_short}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {props.isSmall ? null :
                                            <div style={{ ...styles.Block, height: rowHeight, width: 150 }}>
                                                <p style={{ ...styles.TextSmall, fontWeight: '500' }}>{GetPrice(item.eur)} {lang.money}</p>
                                            </div>
                                        }
                                    </div>
                                </Paper>
                            )) :
                            // ZIADNE PRODUKTY                            
                            <div style={{ ...styles.Block, paddingTop: 20 }}>
                                <EmptyList lang={lang} />
                            </div>
                        }

                        {itemsFiltered != false ?
                            pageMax > 1 ?
                                <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                    <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                </div>
                                : <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>1/1</div>
                            :
                            null
                        }

                    </div>
                </div>
            </div>

            {showProduct == true ?
                <ProductStats isSmall={props.isSmall} offset={props.offset} theme={props.theme} editing={editing} user_id={props.user.id} product={itemSelected} lang={lang} radius={props.radius} title={itemSelected.label} sub_title={itemSelected.note_short} backcolor={true} background={false} language={props.language} func={ProductDialogResult.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Paper>
    );
}

export const ProductStats = (props) => {
    /*
            NAHLAD PRODUKTU
            
            USAGE:
            <ProductPreview radius={props.radius} title={'nazov'} sub_title={'podnázov'} backcolor={true} icon={faQuestion} background={false} func={ProductPreviewResult.bind(this)} />

            const ProductPreviewResult = (typ,value) => {
                console.log(value)
            }
    */

    const [isBusy, setBusy] = useState(false);

    const [photo, setPhoto] = useState(false);
    const [graphData, setGraphData] = useState(false);
    const [product, setProduct] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = props.isSmall ? width : 1000;
    const dialogHeight = props.isSmall ? height : 700;
    const titleHeight = 180;

    const no_image = require('./react/app/no_image.jpg');


    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            let date = GetTodayYearMonth();
            db_get(date);

        }
        return () => {
        };

    }, []);

    const db_get = async (date) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stats_product', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user_id,
                    product_id: props.product.id,
                    date: date
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setProduct(json.product);
                setPhoto(json.product.image);
                setGraphData(json.graph_data);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false, product);
    }

    const GraphResult = (id, value, value2) => {

        if (id == -2) {
            // ZMENA ROKA            
            db_get(value + '-' + value2.toString().padStart(2, '0'));
        }
        if (id == -1) {
        }
        if (id >= 0) {

        }
    }

    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: titleHeight, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: titleHeight, height: titleHeight }}>
                        <img src={photo == false || photo == null || photo == '' ? no_image : photo.substring(0, 4) == 'http' ? photo : global.web_images + '/' + photo} style={{ width: '100%', height: '100%', maxWidth: titleHeight - 30, maxHeight: titleHeight - 30, objectFit: 'contain', borderRadius: props.radius * 0.8, }}></img>
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - titleHeight - 160 - 100, height: titleHeight }}>
                        <div style={{ ...styles.BlockLeft, justifyContent: 'center', height: titleHeight, marginLeft: 20 }}>
                            <p style={{ ...styles.TextTiny, marginBottom: 2, textAlign: 'left', color: global.theme_dark }}>{product.code}</p>
                            <p style={{ ...props.isSmall ? styles.TextSmall : styles.TextDialogLabel, fontWeight: '600', textAlign: 'left' }}>{product.label}</p>
                            {props.isSmall ? null :
                                <p style={{ ...styles.TextSmall, marginTop: 5, textAlign: 'left' }}>{product.note_short}</p>
                            }
                            <p style={{ ...styles.TextTiny, marginTop: 20, textAlign: 'left', color: global.theme_dark }}>{product.details}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 160, height: titleHeight }}>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: titleHeight, justifyContent: 'flex-start' }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white, marginTop: 20 }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight, backgroundColor: global.theme_white, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight, overflowY: 'scroll' }}>
                        <Graph
                            lang={props.lang}
                            elevation={0}
                            readius={0}
                            title={lang.stats_product}
                            sub_title={''}
                            width={dialogWidth - 20}
                            height={400}
                            typ={0}
                            data={graphData}
                            barWidth={props.isSmall ? 30 : 65}
                            highColor={global.theme_red}
                            overColor={global.theme_blue}
                            showMax={false}
                            lines={true}
                            units={lang.pieces}
                            legend={true}
                            legenda={lang.stats_product_salon}
                            legenda2={lang.stats_product_eshop}
                            marginTop={10}
                            selectable={false}
                            show_value={true}
                            year={true}
                            isSmall={props.isSmall}
                            func={GraphResult.bind(this)}
                        />
                    </div>
                </div>
            </Paper>

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop>
    );
}

