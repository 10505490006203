import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { IsEmpty, TextInput, CopyData, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, GetPrice, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, ShowError, ShowOK, TemplateWindow, DialogEmail, DialogNotify, TextSearch } from './items';
import { styles } from './styles';
import { Button, IconButton, InputAdornment, Pagination, Paper, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEnvelope, faExclamationTriangle, faL, faLayerGroup, faList, faListAlt, faMobileAlt, faPen, faQuestion, faUserPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';
import { ProductDialog } from './product_dialog';
import { ServicesDialog } from './service_dialog';
import { DialogClient } from './clients_dialogs';
import { Select, MenuItem } from '@mui/material';


export const Clients = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);
    const [filter, setFilter] = useState(false);
    const [filterTyp, setFilterTyp] = useState(4);

    // KATEGORIE
    const [categories, setCategories] = useState(false);

    // ZOZNAM
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const itemsMax = 24;

    // EDITING + NOVY PRODUKT
    const [itemSelected, setItemSelected] = useState(false);
    const [editing, setEditing] = useState(false);
    const [showClient, setShowClient] = useState(false);
    const [showCard, setShowCard] = useState(false);

    // EMAILS - odoslať email
    const [showEmail, setShowEmail] = useState(false);

    // SPRAVY - odoslať správy / notifikácie
    const [showNotify, setShowNotify] = useState(false);

    const menuWidth = 250;
    const rowHeight = 120;
    const rowWidth = 1000;
    const bottomHeight = 120;


    // COLORS
    const font_selected = global.theme_black;
    const font_disabled = global.theme_darker;

    const no_image = require('./react/app/no_image.jpg');
    const avatar_man = require('./react/app/man.png');
    const avatar_woman = require('./react/app/woman.png');


    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;
            db_get();
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'employees', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                })
            })

            const json = await response.json();
            //setBusy(false);

            if (json.ok > 0) {
                var tmp = json.items;
                tmp = tmp.filter(x => x.enabled == true);

                var data = [];

                // SALON
                var id = 0;
                if (tmp.length > 0) {
                    var salon = { id: id, label: props.user.salon_name, photo: 2, typ: 0 };
                    data.push(salon);
                    id++;
                }

                // ZAMESTNANCI
                tmp.forEach(item => {
                    let employee = { id: id, employee_id: item.id, label: item.name + ' ' + item.surname, photo: item.photo, typ: 1 };
                    data.push(employee);
                    id++;
                });

                // CIARA - PREDEL
                if (tmp.length > 0) {
                    var salon = { id: id, label: lang.cliant_all, photo: 2, typ: 100 };
                    data.push(salon);
                    id++;
                }

                // ZOZNAM VSETKYCH
                var salon = { id: id, label: lang.cliant_all, photo: 2, typ: 4 };
                data.push(salon);
                id++;

                // DNES OBJEDNANÝ
                var salon = { id: id, label: lang.clients_today, photo: 2, typ: 2 };
                data.push(salon);
                id++;

                // NEAKTÍVNY
                var salon = { id: id, label: lang.clients_hidden, photo: 2, typ: 3 };
                data.push(salon);
                id++;

                setCategories(data);
                if (data != false) {
                    var filter = { enabled: true };
                    setFilter(filter);
                    db_clients(filter);
                    var tmp = data.filter(x => x.typ == 4);
                    setIndex(tmp[0].id);
                } else {
                    setBusy(false);
                }
            } else {
                setBusy(false);
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_clients = async (filter) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'clients', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    filter: filter
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;

                if (tmp != false) {
                    tmp.sort((a, b) => {
                        if (a.surname > b.surname) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_search = async (search) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'client_search', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    search: search,
                    brand_id: props.user.brand_id,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;

                if (tmp != false) {
                    tmp.sort((a, b) => {
                        if (a.surname > b.surname) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                    setIndex(-1);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                    setIndex(-1);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ChangeIndex = (id) => {
        if (categories != false) {
            var filter = false;
            var item = categories.find(x => x.id == id);

            if (item != undefined) {
                if (item.typ == 0) {
                    // SALON
                    filter = { enabled: true, employee_id: 0 };
                }
                if (item.typ == 1) {
                    // ZAMESTNANEC
                    filter = { enabled: true, employee_id: item.employee_id };
                }
                if (item.typ == 2) {
                    // DNES OBJEDNANY
                    filter = { enabled: true, employee_id: props.employee_id, today: true };
                }
                if (item.typ == 3) {
                    // NEAKTIVNY
                    filter = { enabled: false };
                }

                if (item.typ == 4) {
                    // ZOZNAM VSETKYCH
                    filter = { enabled: true };
                }

                setFilterTyp(item.typ);
                setIndex(item.id);
                setSearch('');
                setFilter(filter);
                db_clients(filter);
            }
        }
    }

    const Search = (text) => {
        setSearch(text);
        if (text.trim().length > 2) {
            db_search(text.trim());
        }
        if (text == '') {
            setItems(false);
            setItemsFiltered(false);
            setIndex(-1);
        }
    }

    const Redraw = (items, p, search) => {
        // REDRAW PRODUKTOV
        var data = items;
        /*
        if (search.trim() != '') {
            // filtrovanie podla hladaneho retazca
            var words = search.split(' ');
            data = items.filter(x => FindWords(x.name, x.surname, words));
        }
        */
        var count = data.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax)
        setItemsFiltered(data);

        if (count == 0) {
            setItemsFiltered(false);
        }

        setPage(p);
        GoToStart();
    }

    function FindWords(label, keywords, words) {
        // hladanie retazca v nazve a keywords
        var result = 0;
        words.forEach(word => {
            if (label.toLowerCase().includes(word.toLowerCase()) || keywords.toLowerCase().includes(word.toLowerCase())) {
                result += 1;
            }

        });
        // ak je pocet najdených slov == poctu hladaných slov -> je zhoda
        return words.length == result;
    }

    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);

        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0, '');
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_, search);
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_scroll');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    // ------------------------------------------------------------------------------------
    // EDITOVANIE + NOVY ZAKAZNIK
    // ------------------------------------------------------------------------------------
    const NewClient = () => {
        setEditing(false);
        setShowClient(true);
    }

    const Edit = (item) => {
        setItemSelected(item);
        setEditing(true);
        setShowClient(true);
    }

    const ClientDialogResult = (value, data) => {
        setShowClient(false);
        if (value == true) {
            if (editing == false) {
                // NOVY ZAZNAM
                db_clients(filter);
            } else {
                CopyData(data, itemSelected, []);
            }
        }
    }

    const Card = (item) => {
        setItemSelected(item);
        setShowCard(true);
    }

    const SendEmail = () => {
        setShowEmail(true);
    }

    const SendNotify = () => {
        setShowNotify(true);
    }

    const DialogNotifyResult = () => {
        setShowNotify(false);
    }

    const ClientCardResult = (typ, item) => {
        CopyData(item, itemSelected, []);
        setShowCard(false);
        if (typ == true) {
            // znovu načítať zoznam zákazníkov
            db_get();
        }
    }

    const DialogEmailResult = () => {
        setShowEmail(false);
    }


    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                        <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                    </div>
                </div>
                <div style={{ ...styles.Block, width: '40%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.Block, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>

                        {props.isSmall ? null :
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        }
                    </div>
                </div>
                <div style={{ ...styles.BlockRow, justifyContent: 'flex-end', alignItems: 'center', width: props.isSmall ? 160 : '50%', height: global.dialog_bar_height }}>
                    <TextSearch lang={props.lang} isSmall={props.isSmall} func={Search.bind(this)} />
                    {/*
                    <TextField
                        value={search}
                        onInput={e => Search(e.target.value)}
                        size="small"
                        autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off"
                        style={{ width: props.isSmall ? 150 : 250 }}
                        placeholder={lang.search_in_category}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                            }
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                    <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                </IconButton>
                            </InputAdornment>,
                            style: { backgroundColor: global.theme_white, borderRadius: 30 },
                        }}
                        variant="outlined"
                    />
                    */}
                    {props.isSmall ? null :
                        <Button onClick={() => NewClient()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 200, width: 180, marginLeft: 20 }}>
                            <FontAwesomeIcon style={{ height: 15, color: global.theme_white, marginRight: 10 }} icon={faUserPlus} />
                            {lang.client_new}
                        </Button>
                    }
                </div>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                    {props.isSmall ?
                        <div style={{ ...styles.Block }}>
                            <Select
                                value={index}
                                onChange={event => ChangeIndex(event.target.value)}
                                size='small'
                                style={{ backgroundColor: global.theme_white, width: '100%' }}
                            >

                                {categories != false ?
                                    categories.map((item) => (
                                        item.typ != 100 ?
                                            <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                                            : null
                                    ))
                                    : null}
                            </Select>
                        </div>
                        :
                        <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                            <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height - bottomHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                {categories != false ?
                                    categories.map((item) => (
                                        item.typ == 100 ?
                                            <div key={item.id} style={{ ...styles.Block, width: '100%', height: 1, background: global.theme_line, marginBottom: 10, marginTop: 10 }}></div>
                                            :
                                            <Button key={item.id} onClick={() => ChangeIndex(item.id)} style={{ ...styles.ButtonSettingsMedium, justifyContent: 'flex-start', backgroundColor: index == item.id ? global.theme_back : '#00000000', color: index == item.id ? font_selected : font_disabled }}>
                                                <div style={{ ...styles.BlockLeft }}>
                                                    <p style={{ ...styles.TextSmall, fontSize: item.typ == 0 || item.typ == 1 ? 14 : global.font_small, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{item.label}</p>
                                                </div>
                                            </Button>
                                    ))
                                    : null}

                            </div>
                            <div style={{ ...styles.Block, height: bottomHeight, borderTop: '1px solid #FFFFFF50' }}>
                                {/* ODOSLAT SPRAVU / EMAIL */}
                                {filterTyp == 4 ?
                                    <div style={{ ...styles.Block }}>
                                        <Button onClick={() => SendEmail()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: menuWidth - 20 }}>
                                            <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faEnvelope} />
                                            {lang.send_email}
                                        </Button>
                                        <Button onClick={() => SendNotify()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: menuWidth - 20, marginTop: 20 }}>
                                            <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faMobileAlt} />
                                            {lang.send_notify}
                                        </Button>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.BlockCenter, width: props.isSmall ? '100%' : props.width - menuWidth, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        {/* BODY */}
                        <div id={'id_scroll'}>
                        </div>
                        {props.isSmall ?
                            <div style={{ ...styles.Block, height: 90 }}>
                                <Button onClick={() => NewClient()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 220 }}>
                                    <FontAwesomeIcon style={{ height: 15, color: global.theme_white, marginRight: 10 }} icon={faUserPlus} />
                                    {lang.client_new}
                                </Button>
                            </div>
                            : null}
                        <div style={{ ...styles.Block, width: '98%' }}>
                            {/* *********************************************************************************************************  
                                ZOZNAM ZAKAZNIKOV
                        ********************************************************************************************************* */}
                            {itemsFiltered != false ?
                                itemsFiltered.map((item) => (
                                    <Paper elevation={item.id == overID ? 4 : 1} key={item.id} style={{
                                        ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: item.enabled == true ? item.theme_id == 0 || item.theme_id == null ? global.theme_lighter : global.colors[item.theme_id] : global.theme_gray, marginTop: 10, marginBottom: 10, borderRadius: rowHeight / 2, cursor: 'pointer'
                                    }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow }}>
                                            <div onClick={() => Card(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                <img src={item.photo == null || item.photo == '' ? item.sex == 0 ? avatar_woman : avatar_man : item.photo.substring(0, 4) == 'http' ? item.photo : global.web_images + '/' + item.photo} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                            </div>
                                            {props.isSmall ?
                                                <div onClick={() => Card(item)} style={{ ...styles.Block, height: rowHeight, width: '85%', justifyContent: 'center', alignItems: 'flex-start', marginLeft: 10, overflowX: 'hidden' }}>
                                                    <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.name} {item.surname}</p>
                                                    <div style={{ ...styles.BlockRowRaw, marginTop: 20 }}>
                                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faMobileAlt} />
                                                        <p style={{ ...styles.TextTiny, marginLeft: 5, textAlign: 'left' }}>{item.mobil}</p>
                                                    </div>
                                                    <div style={{ ...styles.BlockRowRaw, marginTop: 4 }}>
                                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faEnvelope} />
                                                        <p style={{ ...styles.TextTiny, marginLeft: 5, textAlign: 'left' }}>{item.email}</p>
                                                    </div>
                                                </div>
                                                :
                                                <div onClick={() => Card(item)} style={{ ...styles.Block, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                    <div style={{ ...styles.BlockRow, justifyContent: 'center', height: rowHeight / 2 - 5 }}>
                                                        <div style={{ ...styles.BlockLeft, justifyContent: 'center', height: rowHeight / 2 - 5 }}>
                                                            <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.name} {item.surname}</p>
                                                            {item.alias == 1 ?
                                                                <p style={{ ...styles.TextTiny }}>{lang.register_from_eshop}</p>
                                                                : null}
                                                            {item.enabled == false ?
                                                                <p style={{ ...styles.TextTiny, fontWeight: '400', marginTop: 2, textAlign: 'left' }}>{lang.clients_hidden}</p>
                                                                : null}
                                                        </div>
                                                        <div style={{ ...styles.BlockLeft, justifyContent: 'flex-start', height: rowHeight / 2 - 5, overflowY: 'hidden' }}>
                                                            <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{item.note}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{ ...styles.BlockRow, justifyContent: 'center', height: rowHeight / 2 - 5, borderTop: '1px solid #00000030' }}>
                                                        <div style={{ ...styles.BlockLeft, justifyContent: 'center', height: rowHeight / 2 - 5 }}>
                                                            <div style={{ ...styles.BlockRowRaw }}>
                                                                <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faMobileAlt} />
                                                                <p style={{ ...styles.TextTiny, marginLeft: 5 }}>{item.mobil}</p>
                                                            </div>

                                                            {item.email_verifed == true ?
                                                                <div style={{ ...styles.BlockRowRaw, marginTop: 4 }}>
                                                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faEnvelope} />
                                                                    <p style={{ ...styles.TextTiny, marginLeft: 5 }}>{item.email}</p>
                                                                </div>
                                                                :
                                                                <div style={{ ...styles.BlockRowRaw, marginTop: 4 }}>
                                                                    <p style={{ ...styles.TextTiny, marginLeft: 0, color: global.theme_dark_red }}><span><FontAwesomeIcon style={{ height: global.font_tiny, color: global.theme_dark_red }} icon={faExclamationTriangle} /></span> {item.email}</p>
                                                                </div>
                                                            }

                                                        </div>
                                                        <div style={{ ...styles.BlockLeft, justifyContent: 'center', height: rowHeight / 2 - 5 }}>
                                                            {item.token != '' && item.token != null ?
                                                                <p style={{ ...styles.TextTiny }}>{lang.client_with_app}</p>
                                                                : null}
                                                            {item.group_id > 0 && filterTyp != 1 ?
                                                                <p style={{ ...styles.TextTiny, marginTop: 2 }}>{item.employee}</p>
                                                                : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div style={{ ...styles.Block, width: 80, height: rowHeight }}>
                                                <IconButton onClick={() => Edit(item)} style={{ width: 40, height: 40, backgroundColor: global.theme_white }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_dark_blue }} icon={faPen} />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </Paper>
                                )) :
                                // ZIADNE ZAZNAMY                            
                                <div style={{ ...styles.Block, paddingTop: 20 }}>
                                    {isBusy == false ?
                                        <EmptyList lang={lang} />
                                        : null}
                                </div>
                            }

                            {itemsFiltered != false ?
                                pageMax > 1 ?
                                    <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                        <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                    </div>
                                    : null
                                : null}
                        </div>

                    </div>
                </div>
            </div>

            {showClient == true ?
                <DialogClient isSmall={props.isSmall} employees={props.employees} offset={props.offset} theme={props.theme} editing={editing} user_id={props.user.id} user={props.user} client={itemSelected} lang={lang} radius={props.radius} title={''} sub_title={lang.client} backcolor={true} background={true} func={ClientDialogResult.bind(this)} />
                : null}

            {showCard == true ?
                <ClientCard isSmall={props.isSmall} employees={props.employees} employee_id={props.employee_id} user_id={props.user.id} user={props.user} client_id={itemSelected.id} lang={lang} language={props.language} radius={props.radius} offset={props.offset} theme={props.theme} func={ClientCardResult.bind(this)} />
                : null}

            {showEmail == true ?
                <DialogEmail offset={props.offset} theme={props.theme} user_id={props.user.id} user={props.user} lang={lang} radius={props.radius} title={lang.send_email_clients} sub_title={lang.clients_with_email} backcolor={true} icon={faQuestion} background={true} func={DialogEmailResult.bind(this)} />
                : null}

            {showNotify == true ?
                <DialogNotify offset={props.offset} theme={props.theme} user_id={props.user.id} user={props.user} lang={lang} radius={props.radius} title={lang.send_notify_clients} sub_title={lang.clients_with_app} backcolor={true} icon={faQuestion} background={true} func={DialogNotifyResult.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Paper >
    );
}
