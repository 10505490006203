import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { Colors, DialogCategory, DialogGroup, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, Init, Loading, Menu, MenuTitle, MenuTop, ShowError, ShowOK, TemplateWindow } from './items';
import { styles } from './styles';
import { Button, IconButton, Paper, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEarth, faList, faPlus, faQuestion, faShoppingBag, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';


export const Groups = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [overID, setOverID] = useState(-1);

    const [itemSelected, setItemSelected] = useState(false);
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [editing, setEditing] = useState(false);
    const [showDialog, setShowCategory] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);


    const rowHeight = 70;
    const rowWidth = props.isSmall ? width - 10 : 450;


    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            db_get();
        }
    }, []);


    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'groups', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    language: props.language,
                    salon_only: false
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;

                tmp.sort((a, b) => {
                    if (a.label > b.label) {
                        return 1;
                    } else {
                        return -1;
                    }
                })

                setItems(tmp);
                setItemsFiltered(tmp)
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const AddNew = () => {
        setEditing(false);
        setShowCategory(true);
    }

    const Edit = (item) => {
        setItemSelected(item)
        setEditing(true);
        setShowCategory(true);

    }

    const DialogCategoryResult = (value) => {
        setShowCategory(false);
        if (value == true) {
            db_get();
        }
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                        <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                    </div>
                </div>
                <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: 45 }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                        <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                    </div>
                </div>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                <div style={{ ...styles.BlockCenter, paddingTop: 0 }}>
                    <div style={{ ...styles.Block, height: 50, backgroundColor: global.theme_light_gray }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <div style={{ ...styles.BlockLeft, width: rowWidth - 60, height: 50, justifyContent: 'center' }}>
                                <p style={{ ...props.isSmall ? styles.TextSmall : styles.TextNormal, fontWeight: '600' }}>{lang.services_categories}</p>
                            </div>
                            <div style={{ ...styles.Block, width: 50, height: 50 }}>
                                <IconButton onClick={() => AddNew()} style={{ width: 36, height: 36, backgroundColor: global.theme_dark_red }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white }} icon={faPlus} />
                                </IconButton>
                            </div>
                        </div>
                    </div>

                    <div style={{ ...styles.Block, width: rowWidth, marginTop: 20 }}>
                        {itemsFiltered != false ?
                            itemsFiltered.map((item, index) => (
                                <Paper key={item.id} elevation={overID == item.id ? 6 : 2} onClick={() => Edit(item)} style={{ ...styles.BlockRow, height: rowHeight, backgroundColor: item.enabled == true ? global.theme_white : global.theme_light_gray, marginBottom: 10, borderRadius: rowHeight / 2, cursor: 'pointer' }} onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)} >
                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.BlockLeft, height: rowHeight, width: '75%', justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextSmall, marginLeft: 20 }}>{item.label}</p>
                                        </div>
                                        <div style={{ ...styles.Block, height: rowHeight, width: '15%', justifyContent: 'center' }}>
                                            {item.eshop == true && item.enabled == true ?
                                                <p style={{ ...styles.TextTiny }}>{lang.web_page}</p>
                                                : null}
                                        </div>

                                        <div style={{ ...styles.BlockRight, height: rowHeight, width: '10%', justifyContent: 'center' }}>
                                            {item.enabled == true ?
                                                <FontAwesomeIcon style={{ height: 18, color: global.theme_dark_green, marginRight: 20 }} icon={faCheckCircle} />
                                                : null}
                                        </div>
                                    </div>
                                </Paper>
                            ))
                            :
                            <div style={{ ...styles.Block, height: 50 }}>
                                <p style={{ ...styles.TextTiny }}>{lang.records_none}</p>
                            </div>
                        }
                    </div>
                </div>
            </div>

            {showDialog == true ?
                <DialogGroup item={itemSelected} editing={editing} lang={lang} language={props.language} radius={props.radius} user_id={props.user.id} offset={props.offset} theme={props.theme} func={DialogCategoryResult.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Paper>
    );
}


