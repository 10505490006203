import React, { useEffect, useState } from 'react';

import './App.css';
import './styles.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { Colors, DialogImagePreview, DialogInfo, DialogLogin, DialogVideo, DialogYesNo, EmployeeSelect, GetTodayYear, Init, Loading, Menu, MenuTitle, MenuTop, SelectYear, ShowError, ShowOK, TemplateWindow } from './items';
import { styles } from './styles';
import { Button, IconButton, Paper, rgbToHex, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarDays, faCheckCircle, faList, faQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';
import { Photos } from './photos';
import { ServiceSelect } from './service_select';
import { ProductSelect } from './product_select';
import { ClientSelect } from './client_select';
import { CalendarDialog } from './calendar_dialog';


export const Calendar = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [showOK, setShowOK] = useState(false);
    const [value, setValue] = useState('');

    const [employeeID, setEmployeeID] = useState(props.employees.length > 1 ? props.employee_id : -1);

    const barHeight = global.dialog_bar_height - 20;

    const no_image = require('./react/app/no_image.jpg');
    const avatar = require('./react/app/avatar.jpg');

    let { func } = props;
    var lang = props.lang;


    useEffect(() => {
    }, []);

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const EmployeeSelectResult = (value) => {
        setEmployeeID(value);
    }

    const CalendarResult = (typ, value) => {
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: barHeight, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                <div style={{ ...styles.Block, width: 100, height: barHeight }}>
                    <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                        <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faCalendarDays} />
                    </div>
                </div>
                <div style={{ ...styles.Block, width: props.isSmall ? '40%' : '30%', height: barHeight }}>
                    <div style={{ ...styles.BlockLeft, height: barHeight, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                        {props.sub_title != '' ?
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                            : null}
                    </div>
                </div>
                <div style={{ ...styles.Block, width: '30%', height: barHeight, justifyContent: 'center' }}>
                    {props.employees.length > 1 ?
                        <div style={{ ...styles.Block, width: props.isSmall ? 150 : 300 }}>
                            <EmployeeSelect theme={props.theme} select small radius lang={lang} background={global.theme_lighter} width={props.isSmall ? 150 : 300} employees={props.employees} value={employeeID} func={EmployeeSelectResult.bind(this)} />
                        </div>
                        : null}
                </div>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: '30%', height: barHeight }}></div>
                }
                <div style={{ ...styles.Block, width: 100, height: barHeight }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - barHeight, backgroundColor: global.theme_back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                <div style={{ ...styles.Block }}>
                    <CalendarDialog remoteApp={props.remoteApp} height={props.height - barHeight} employees={props.employees} employee_id={employeeID} user={props.user} user_id={props.user.id} admin={props.admin} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} isSmall={props.isSmall} listing={false} token={props.token} func={CalendarResult.bind(this)} />
                </div>
            </div>


            {isBusy == true ?
                <Loading screenWidth={props.screenWidth}></Loading>
                : null}

        </Paper>
    );
}


