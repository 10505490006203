/*
*
* =================================================================================================================
* ZOZNAM OSETRENI V JEDNOTLIVYCH SALONOCH
* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { CategorySelect, Colors, ConvertToPrice, CopyData, Debug, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, GetDate, GetImageLink, GetPrice, GetTodayYear, GetTodayYearMonth, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, SelectYear, ShowError, ShowOK, TemplateWindow, Today } from './items';
import { styles } from './styles';
import { Backdrop, Button, CircularProgress, IconButton, InputAdornment, Pagination, Paper, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCheckCircle, faEdit, faL, faLayerGroup, faList, faListAlt, faPaperPlane, faQuestion, faSave, faShoppingBag, faShoppingBasket, faTrash, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Graph } from './graph';
import { StatsSalons } from './stats_salons';
import { SalonCard } from './salon_card';


export const StatsSalonsServices = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [showOK, setShowOK] = useState(false);

    const [services, setServices] = useState(false);
    const [items, setItems] = useState(false);
    const [actualYear, setActualYear] = useState(new Date().getFullYear());
    const [column, setColumn] = useState(-1);
    const [row, setRow] = useState(-1);
    const [label, setLabel] = useState('');
    const [subLabel, setSubLabel] = useState('');
    const [showCard, setShowCard] = useState(false);
    const [clientID, setClientID] = useState(0);



    // EXPORT STATISTIKY
    const [showExport, setShowExport] = useState(false);


    const offset = 10;
    const rowWidth = 100;
    const cellHeight = 100;
    const labelHeight = 40;

    const no_image = require('./react/app/no_image.jpg');

    // Aktuálna farebna téma
    const color_theme = global.themes[props.theme];

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;
            let date = GetTodayYear();
            db_get(date);
        }

    }, []);

    const db_get = async (date) => {

        setBusy(true);
        try {
            const response = await fetch(
                global.db_url_agent + 'stats_services_salons', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    admin: props.admin,
                    language: props.country.language_id,
                    agent_id: props.user.id,
                    date: date
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setItems(json.items);
                var tmp = json.services;

                var n = 0;
                tmp.forEach(element => {
                    element.index = n;
                    n++;
                });
                setServices(tmp);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_export = async () => {
        Debug(items);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url_agent + 'stats_services_salons_export', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    admin: props.admin,
                    email: props.user.email,
                    items: items,
                    year: actualYear,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ) => {
        func(typ);
    }

    const SelectYearResult = (year, month) => {
        db_get(year)
    }

    const SetColumn = (col, item) => {
        Debug('col');
        Debug(col);
        Debug(item);

        var tmp = services.find(x => x.index == col);
        setRow(-1);
        Debug(tmp);

        if (tmp == undefined) {
            setLabel('');
            setSubLabel('');
        } else {
            if (item == false) {
                //setLabel(tmp.label);
                //setSubLabel('');
            } else {
                //setLabel(tmp.label);
                //setSubLabel(item.surname + ' ' + item.name + ' (' + item.salon_town + ')');
                setRow(item.id);

            }
        }

        setColumn(col);

    }

    const Export = () => {
        setShowExport(true);

    }

    const ExportResult = (value) => {
        setShowExport(false);

        if (value == true) {
            db_export();
        }
    }

    const Card = (item) => {
        setClientID(item.id);
        setShowCard(true);
    }
    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                        <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                    </div>
                </div>
                <div style={{ ...styles.Block, width: '65%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: 45 }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                        <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                    </div>
                </div>
                <div style={{ ...styles.Block, width: 260, height: global.dialog_bar_height }}>
                    <Button onClick={() => Export()} style={{ ...styles.ButtonDarkSmall, backgroundColor: global.theme_medium, color: global.theme_white, width: 160 }}>
                        <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPaperPlane} />
                        {lang.export}
                    </Button>
                </div>
                <div style={{ ...styles.Block, width: 150, height: global.dialog_bar_height }}>
                    <SelectYear min={2020} value={actualYear} func={SelectYearResult.bind(this)} />
                </div>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Press()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.Block, height: labelHeight }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{label}</p>
                        <p style={{ ...styles.TextSmall, fontWeight: '400', marginLeft: 15 }}>{subLabel}</p>
                    </div>
                </div>
                {items != false ?
                    <div style={{ ...styles.BlockCenter, justifyContent: 'flex-start', alignItems: 'flex-start', width: props.width, height: props.height - global.dialog_bar_height - labelHeight, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overscrollBehavior: 'none', msOverflowStyle: 'none', scrollbarWidth: 'none', overflow: 'scroll' }} className='container'>
                        <div style={{ ...styles.BlockRowRaw, position: 'sticky', top: 0, zIndex: 110, backgroundColor: global.theme_light, borderBottom: '1px solid ' + global.theme_dark }}>
                            <div style={{ ...styles.Block, alignItems: 'flex-start', position: 'sticky', left: 0, zIndex: 120, height: labelHeight, width: rowWidth * 2.5, borderLeft: '1px solid ' + global.theme_medium_gray, borderRight: '1px solid ' + global.theme_medium_gray, backgroundColor: global.theme_white }}>
                                <p style={{ ...styles.TextTiny, marginLeft: offset }}>{lang.salon}</p>
                            </div>
                            {services != false ? services.map((service, col) => (
                                <div key={service.id} onClick={() => SetColumn(col, false)} style={{ ...styles.Block, height: labelHeight, width: rowWidth, borderRight: '1px solid ' + global.theme_dark, cursor: 'pointer', backgroundColor: column == col ? global.theme_dark : undefined }}>
                                    <p style={{ ...styles.TextTiny, color: column == col ? global.theme_white : global.theme_black }}>{service.label}</p>
                                </div>
                            )) : null}

                        </div>
                        {items != false ? items.map((item, index) => (
                            <div key={item.id} style={{ ...styles.BlockRowRaw, backgroundColor: row == item.id ? global.theme_lighteen : index % 2 == 0 ? global.theme_white : global.theme_gray, borderBottom: '1px solid ' + global.theme_medium_gray }}>
                                <div onClick={() => Card(item)} style={{ ...styles.BlockLeft, position: 'sticky', left: 0, zIndex: 100, width: rowWidth * 2.5, cursor: 'pointer', height: cellHeight, borderLeft: '1px solid ' + global.theme_medium_gray, borderRight: '1px solid ' + global.theme_medium_gray, backgroundColor: row == item.id ? global.theme_lighteen : index % 2 == 0 ? global.theme_white : global.theme_gray }}>
                                    <p style={{ ...styles.TextTiny, marginLeft: offset, textAlign: 'left', fontWeight: '600', marginTop: 5 }}>{item.company}</p>
                                    <p style={{ ...styles.TextTiny, marginTop: 5, marginLeft: offset, textAlign: 'left' }}>{item.surname} {item.name}</p>
                                    <p style={{ ...styles.TextTiny, marginLeft: offset, textAlign: 'left' }}>{item.salon_town}</p>
                                    <p style={{ ...styles.TextTiny, color: global.theme_dark_gray, marginLeft: offset, marginTop: 5, textAlign: 'left' }}>({item.agent})</p>
                                </div>
                                {item.services.map((service, col) => (
                                    <div key={service.service_id} onClick={() => SetColumn(col, item)} style={{ ...row == item.id ? styles.BlockCenter : styles.Block, width: rowWidth, height: cellHeight, borderRight: '1px solid ' + global.theme_medium_gray, backgroundColor: column == col ? global.theme_lighteen : undefined, cursor: 'pointer' }}>
                                        {row == item.id ?
                                            <div style={{ ...styles.BlockCenter, height: 40 }}>
                                                <p style={{ ...styles.TextXTiny, marginBottom: 10 }}>{service.label}</p>
                                            </div>
                                            : null}
                                        <p style={{ ...styles.TextSmall, color: parseInt(service.count) == 0 ? global.theme_dark_red : global.theme_black, fontWeight: parseInt(service.count) == 0 ? '400' : '400' }}>{service.count} {parseInt(service.count) > 0 ? ' x' : ''}</p>
                                        {service.enabled == false ?
                                            <div style={{ padding: 5, backgroundColor: global.theme_lighter_red, borderRadius: 15, marginTop: 5 }}>
                                                <p style={{ ...styles.TextXXTiny, marginLeft: 5, marginRight: 5 }}>{lang.off}</p>
                                            </div>
                                            : null}
                                    </div>
                                ))}
                            </div>
                        )) : null}

                        <div style={{ ...styles.BlockRowRaw, backgroundColor: global.theme_lighteen_blue, borderTop: '1px solid ' + global.theme_medium_gray, borderBottom: '1px solid ' + global.theme_medium_gray }}>
                            <div style={{ ...styles.BlockLeft, justifyContent: 'center', position: 'sticky', left: 0, zIndex: 100, width: rowWidth * 2.5, height: cellHeight, borderLeft: '1px solid ' + global.theme_medium_gray, borderRight: '1px solid ' + global.theme_medium_gray, backgroundColor: global.theme_lighteen_red }}>
                                <p style={{ ...styles.TextSmall, marginLeft: offset, textAlign: 'left', fontWeight: '600', marginTop: 5 }}>{lang.sumar}</p>
                            </div>
                            {services.map((service, col) => (
                                <div key={service.service_id} style={{ ...styles.Block, width: rowWidth, height: cellHeight, borderRight: '1px solid ' + global.theme_medium_gray, backgroundColor: global.theme_lighteen_red, cursor: 'pointer' }}>
                                    <div style={{ ...styles.Block, minHeight: 32, overflow: 'hidden' }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_black, fontWeight: '400' }}>{service.label}</p>
                                    </div>
                                    <p style={{ ...styles.TextSmall, color: global.theme_black, fontWeight: '600', marginTop: 2 }}>{service.sum}</p>
                                    <p style={{ ...styles.TextTiny, color: global.theme_black, fontWeight: '400', marginTop: 5 }}>{parseFloat(service.percentage).toFixed(2)}%</p>
                                </div>
                            ))}
                        </div>

                        <div style={{ ...styles.Block, minHeight: 100 }}></div>
                    </div>
                    : null}
            </div>


            {showExport == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.export_stats} sub_title={''} text={lang.export_agent_data_ask} sub_text={lang.export_agent_data_text + ' ' + props.user.email} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={ExportResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={() => setShowOK(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme}  ></Loading>
                : null}

            {/* KARTA SALONU - POSLEDNA OTVORENA */}
            {showCard == true ?
                <SalonCard salon_id={clientID} remoteApp={props.remoteApp} user={props.user} admin={props.admin} countries={props.countries} country={props.country} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} isSmall={props.isSmall} title={props.lang.salons_list} sub_title={props.lang.salons} func={() => setShowCard(false)} />
                : null}

        </Paper >
    );
}
