import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { IsEmpty, TextInput, CopyData, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, GetPrice, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, ShowError, ShowOK, TemplateWindow, DialogEmail, DialogNotify, GetMoney, Debug, GetImageLink } from './items';
import { styles } from './styles';
import { Backdrop, Button, IconButton, InputAdornment, MenuItem, Pagination, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEnvelope, faExclamationTriangle, faL, faLayerGroup, faList, faListAlt, faMobileAlt, faPen, faQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';
import { ProductDialog } from './product_dialog';
import { ServicesDialog } from './service_dialog';
import { DialogClient } from './clients_dialogs';


export const SalonSelect = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(props.country != false ? props.country.id : 0);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);
    const [filter, setFilter] = useState(false);
    const [filterTyp, setFilterTyp] = useState(4);

    // ZOZNAM REPREZENTANTOV
    const [agents, setAgents] = useState(false);
    const [agentID, setAgentID] = useState(-1);

    // ZOZNAM
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const itemsMax = 24;

    // EDITING + NOVY PRODUKT
    const [itemSelected, setItemSelected] = useState(false);
    const [editing, setEditing] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1200 ? 1200 : width;
    const dialogHeight = height >= 850 ? 850 : height;

    const menuWidth = props.isSmall ? 0 : 250;
    const rowHeight = props.isSmall ? 140 : 128;
    const rowWidth = 1000;
    const bottomHeight = 120;

    // COLORS
    const font_selected = global.theme_black;
    const font_disabled = global.theme_darker;

    const no_image = require('./react/app/no_image.jpg');
    const avatar_man = require('./react/app/man.png');
    const avatar_woman = require('./react/app/woman.png');


    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {

        if (running == false) {
            running = true;

            var filter_ = { enabled: true, language: props.country.language_id };
            db_agents(filter_);
        }

    }, []);

    const db_get = async (filter_) => {

        setBusy(true);
        try {
            const response = await fetch(
                global.db_url_agent + 'salons', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    admin: props.admin,
                    filter: filter_
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;

                if (tmp != false) {
                    tmp.sort((a, b) => {
                        if (a.surname > b.surname) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_agents = async (filter_) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url_agent + 'agents', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    admin: props.admin,
                    filter: filter_
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setAgents(json.agents);

                var filter_ = { enabled: true, language: props.country != false ? props.country.language_id : 0 };
                db_get(filter_);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_search = async (search) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url_agent + 'salons_search', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    admin: props.admin,
                    filter: filter,
                    search: search,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;

                if (tmp != false) {
                    tmp.sort((a, b) => {
                        if (a.surname > b.surname) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                    setIndex(-1);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                    setIndex(-1);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ChangeIndex = (typ, id) => {

        if (typ == 1) {
            // ZMENA KRAJINY
            var item = props.countries.find(x => x.id == id);
            if (item != undefined) {
                var filter_ = { enabled: true, language: item.language_id };
                setIndex(item.id);
                setAgentID(0);
            }
        }
        if (typ == 2) {
            // ZMENA AGENTA
            var filter_ = { enabled: true, agent_id: id };
            setIndex(9000 + parseInt(id));
            setAgentID(id);
        }

        setSearch('');
        setFilter(filter_);

        db_get(filter_);
    }

    const Search = (text) => {
        setSearch(text);
        if (text.trim().length > 2) {
            db_search(text.trim());
        }
        if (text == '') {
            setItems(false);
            setItemsFiltered(false);
            setIndex(-1);

            var filter_ = { enabled: true, language: props.country.language_id };
            db_agents(filter_);
            setIndex(props.country != false ? props.country.id : 0);
        }
    }

    const Redraw = (items, p, search) => {
        // REDRAW ZOZNAMU
        var data = items;

        var count = data.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax)
        setItemsFiltered(data);

        if (count == 0) {
            setItemsFiltered(false);
        }

        setPage(p);
        GoToStart();
    }

    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);

        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0, '');
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_, search);
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_scroll');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const Card = (item) => {

    }

    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                            <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                                <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.Block, width: props.isSmall ? '45%' : '38%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                        <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>
                            {props.isSmall ? null :
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                            }
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRow, justifyContent: 'flex-end', alignItems: 'center', width: props.isSmall ? 160 : '50%', height: global.dialog_bar_height }}>
                        <TextField
                            value={search}
                            onInput={e => Search(e.target.value)}
                            size="small"
                            autoCapitalize="off"
                            autoComplete="off"
                            autoCorrect="off"
                            style={{ width: props.isSmall ? 150 : 300 }}
                            placeholder={lang.search_in_category}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                }
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                    </IconButton>
                                </InputAdornment>,
                                style: { backgroundColor: global.theme_white, borderRadius: 30 },
                            }}
                            variant="outlined"
                        />
                    </div>
                    <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, width: dialogWidth, height: dialogHeight - global.dialog_bar_height }}>

                        {props.isSmall ?
                            <div style={{ ...styles.Block }}>
                                <Select
                                    value={index}
                                    onChange={event => ChangeIndex(1, event.target.value)}
                                    size='small'
                                    style={{ backgroundColor: global.theme_white, width: '100%' }}
                                >
                                    {props.countries != false ?
                                        props.countries.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>{item.country}</MenuItem>
                                        ))
                                        : null}

                                </Select>
                            </div>
                            :
                            <div style={{ ...styles.BlockCenter, width: menuWidth, height: dialogHeight - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                                <div style={{ ...styles.BlockCenter, width: menuWidth, height: dialogHeight - global.dialog_bar_height - bottomHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    {props.countries != false ?
                                        props.countries.map((item) => (
                                            item.language_id == props.country.language_id ?
                                                <Button key={item.id} onClick={() => ChangeIndex(1, item)} style={{ ...styles.ButtonSettingsMedium, justifyContent: 'flex-start', backgroundColor: index == item.id ? global.theme_back : '#00000000', color: index == item.id ? font_selected : font_disabled }}>
                                                    <div style={{ ...styles.BlockLeft }}>
                                                        <p style={{ ...styles.TextSmall, fontSize: global.font_small, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{item.country}</p>
                                                    </div>
                                                </Button>
                                                : null
                                        ))
                                        : null}

                                    {props.admin == true ?
                                        <div style={{ ...styles.BlockLeft, height: 40, borderBottom: '1px solid ' + global.themes[props.theme].theme_medium, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextTiny, color: global.themes[props.theme].theme_darker, marginLeft: 10 }}>{lang.salons_rep}</p>
                                        </div>
                                        : null}

                                    {props.admin == true ? agents != false ?
                                        agents.map((item) => (
                                            <Button key={item.id} onClick={() => ChangeIndex(2, item.id)} style={{ ...styles.ButtonSettingsMedium, justifyContent: 'flex-start', backgroundColor: index == 9000 + parseInt(item.id) ? global.theme_back : '#00000000', color: index == 9000 + parseInt(item.id) ? font_selected : font_disabled }}>
                                                <div style={{ ...styles.BlockLeft }}>
                                                    <p style={{ ...styles.TextSmall, fontSize: global.font_small, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{item.name} {item.surname}</p>
                                                </div>
                                            </Button>
                                        ))
                                        : null : null}
                                </div>
                                <div style={{ ...styles.Block, height: bottomHeight, borderTop: '1px solid #FFFFFF50' }}>
                                </div>
                            </div>
                        }
                        <div style={{ ...styles.BlockCenter, width: dialogWidth - menuWidth, height: dialogHeight - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            {/* BODY */}
                            <div id={'id_scroll'}>
                            </div>
                            <div style={{ ...styles.Block, width: '98%' }}>
                                {/* *********************************************************************************************************  
                                ZOZNAM SALONOV
                            ********************************************************************************************************* */}
                                {itemsFiltered != false ?
                                    itemsFiltered.map((item) => (
                                        <Paper onClick={() => Press(true, item)} elevation={item.id == overID ? 4 : 1} key={item.id} style={{
                                            ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: item.enabled == true ? item.theme_id == 0 ? global.theme_lighter : global.colors[item.theme_id] : global.theme_gray, marginTop: 10, marginBottom: 10, borderRadius: props.isSmall ? 20 : rowHeight / 2, cursor: 'pointer'
                                        }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow }}>
                                                {props.isSmall ?
                                                    <div style={{ ...styles.BlockLeft, height: rowHeight, width: 90, justifyContent: 'center' }}>
                                                        <img src={item.photo == null || item.photo == '' ? no_image : GetImageLink(item.photo)} style={{ width: '100%', height: '100%', width: 80, height: 80, objectFit: 'contain', borderRadius: 10, marginLeft: 5 }}></img>
                                                    </div>
                                                    :
                                                    <div style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                        <img src={item.photo == null || item.photo == '' ? no_image : GetImageLink(item.photo)} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                                    </div>
                                                }
                                                {props.isSmall ?
                                                    <div style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                        <p style={{ ...styles.TextTinyMobile, fontWeight: '600', textAlign: 'left' }}>{item.name} {item.surname}</p>
                                                        <p style={{ ...styles.TextTinyMobile }}>{item.street} {item.street_number}</p>
                                                        <p style={{ ...styles.TextTinyMobile }}>{item.psc} {item.town}</p>
                                                        <div style={{ ...styles.BlockRowRaw, marginTop: 10 }}>
                                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faMobileAlt} />
                                                            <p style={{ ...styles.TextTiny, marginLeft: 5 }}>{item.mobil}</p>
                                                        </div>
                                                        <div style={{ ...styles.BlockRowRaw, marginTop: 4 }}>
                                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faEnvelope} />
                                                            <p style={{ ...styles.TextTiny, marginLeft: 5 }}>{item.email}</p>
                                                        </div>
                                                        <p style={{ ...styles.TextTiny, marginTop: 10 }}>{item.agent}</p>
                                                    </div>
                                                    :
                                                    <div style={{ ...styles.Block, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                        <div style={{ ...styles.BlockRow, justifyContent: 'center', height: rowHeight / 2 - 5 }}>
                                                            <div style={{ ...styles.BlockLeft, width: '40%', justifyContent: 'center', height: rowHeight / 2 - 5 }}>
                                                                <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.name} {item.surname}</p>
                                                                <p style={{ ...styles.TextTiny, fontWeight: '400', textAlign: 'left' }}>{item.company}</p>
                                                                {item.enabled == false ?
                                                                    <p style={{ ...styles.TextTiny, fontWeight: '400', marginTop: 2, textAlign: 'left' }}>{lang.salon_hidden}</p>
                                                                    : null}
                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: '30%', justifyContent: 'center', height: rowHeight / 2 - 5 }}>
                                                                <p style={{ ...styles.TextTiny }}>{lang.bonus_credit}: <b>{GetPrice(item.bonus_credit)} {GetMoney(item.language)}</b></p>
                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: '30%', justifyContent: 'center', height: rowHeight / 2 - 5 }}>
                                                                <p style={{ ...styles.TextTiny }}>{item.agent}</p>
                                                            </div>
                                                        </div>
                                                        <div style={{ ...styles.BlockRow, justifyContent: 'center', height: rowHeight / 2 - 5, borderTop: '1px solid #00000030' }}>
                                                            <div style={{ ...styles.BlockLeft, width: '40%', justifyContent: 'center', height: rowHeight / 2 - 5 }}>
                                                                <div style={{ ...styles.BlockRowRaw }}>
                                                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faMobileAlt} />
                                                                    <p style={{ ...styles.TextTiny, marginLeft: 5 }}>{item.mobil}</p>
                                                                </div>
                                                                <div style={{ ...styles.BlockRowRaw, marginTop: 4 }}>
                                                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faEnvelope} />
                                                                    <p style={{ ...styles.TextTiny, marginLeft: 5 }}>{item.email}</p>
                                                                </div>
                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: '30%', justifyContent: 'center', height: rowHeight / 2 - 5 }}>
                                                                <p style={{ ...styles.TextTiny }}>{item.street} {item.street_number}</p>
                                                                <p style={{ ...styles.TextTiny }}>{item.psc} {item.town}</p>
                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: '30%', justifyContent: 'center', height: rowHeight / 2 - 5 }}>
                                                                <p style={{ ...styles.TextTiny }}>{lang.alias}: {item.alias}</p>
                                                            </div>

                                                        </div>
                                                    </div>
                                                }
                                                {props.isSmall ? null :
                                                    <div style={{ ...styles.Block, width: 80, height: rowHeight }}>
                                                        <p style={{ ...styles.TextXXTiny }}>{lang.version}</p>
                                                        <p style={{ ...styles.TextTiny, marginTop: 5 }}>{item.version}</p>
                                                    </div>
                                                }
                                            </div>
                                        </Paper>
                                    )) :
                                    // ZIADNE ZAZNAMY                            
                                    <div style={{ ...styles.Block, paddingTop: 20 }}>
                                        {isBusy == false ?
                                            <EmptyList lang={lang} />
                                            : null}
                                    </div>
                                }

                                {itemsFiltered != false ?
                                    pageMax > 1 ?
                                        <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                            <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                        </div>
                                        : null
                                    : null}
                            </div>

                        </div>
                    </div>
                </div>

                {isBusy == true ?
                    <Loading offset={props.offset} theme={props.theme} ></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}
