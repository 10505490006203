/*
*
* =================================================================================================================
* CHAT SALON <-> AGENTS - zoznam pre agenta
* =================================================================================================================
*
*/


import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, ConvertToPrice, CopyData, Debug, DialogImagePreview, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, GetDate, GetDatum, GetDatumTime, GetImageLink, GetPrice, GetTopPosition, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, SelectInput, ShowError, ShowOK, TemplateWindow, Today } from './items';
import { styles } from './styles';
import { Backdrop, Button, CircularProgress, IconButton, InputAdornment, Pagination, Paper, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faAnglesLeft, faCheck, faCheckCircle, faCircle, faComments, faEdit, faL, faLayerGroup, faList, faListAlt, faMessage, faPaperPlane, faPlus, faPlusCircle, faQuestion, faSave, faShoppingBag, faShoppingBasket, faTrash, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import './items.css';
import { Photos } from './photos';

export const ChatAgentsRep = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');

    const [forum, setForum] = useState(false);
    const [salon, setSalon] = useState(false);
    const [salonName, setSalonName] = useState('');
    const [salons, setSalons] = useState(false);
    const [salonID, setSalonID] = useState(-1);
    const [salonsFiltered, setSalonsFiltered] = useState(false);
    const [agents, setAgents] = useState(false);
    const [agentID, setAgentID] = useState(props.user.id);
    const [offset, setOffset] = useState(0);

    // PRISPEVOK
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [messageEdit, setMessageEdit] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    // FOTO
    const [photo, setPhoto] = useState('');
    const [photoDelete, setPhotoDelete] = useState(false);
    const [showPhoto, setShowPhoto] = useState(false);

    const menuWidth = 340;

    // RIADKY
    const rowRadius = 20;
    const rowPadding = 7;
    const rowOffset = 15;
    const rowTextOffset = 10;
    const rowHeight = 60;
    const imageSize = 300;
    const imageRadius = 10;

    const bottomHeight = 70;

    const no_image = require('./react/app/no_image.jpg');

    let { func } = props;
    var lang = props.lang;

    var running = false;
    var color = global.themes[props.theme];

    useEffect(() => {
        if (running == false) {
            running = true;

            db_get_salons(props.user.id);
        }

    }, []);

    const db_get_salons = async (agent_id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'chat_agents_salons', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    agent_id: agent_id,
                    admin: props.admin
                })
            })

            const json = await response.json();
            Debug('SALONY');
            Debug(json);
            if (json.ok > 0) {
                setAgents(json.agents);
                setAgentID(agent_id);

                var tmp = json.salons;

                if (tmp != false) {
                    /*
                    var array = [];

                    tmp.sort((a, b) => {
                        if (a.surname > b.surname) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })

                    let unreaded = tmp.filter(x => x.not_readed > 0);
                    unreaded.forEach(item => {
                        array.push(item);
                    });

                    let readed = tmp.filter(x => x.not_readed == 0);
                    readed.forEach(item => {
                        array.push(item);
                    });

                    setSalons(array);
                    setSalonsFiltered(array);
                    */
                    tmp.sort((a, b) => {
                        if (a.date < b.date) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                    setSalons(tmp);
                    setSalonsFiltered(tmp);
                } else {
                    setSalons(false);
                    setSalonsFiltered(false);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get = async (salon_) => {

        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'chat_agents_rep', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    agent_id: agentID,
                    user_id: props.user.id,
                    salon_id: salon_.id,
                    language: props.language,
                    limit: global.chat_limit,
                    offset: offset,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                // PRISPEVKY
                var tmp = json.forum;
                var lastID = 0;
                if (tmp != false) {
                    tmp.forEach(item => {
                        item.datum = GetDatumTime(lang.months, item.date);
                    });
                    tmp.sort((a, b) => {
                        if (a.date > b.date) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                    lastID = tmp[tmp.length - 1].id;
                }
                setForum(tmp);
                setSalon(json.salon);
                setSalonID(salon_.id);
                setSalonName(json.salon.name + ' ' + json.salon.surname);
                salon_.not_readed = 0;
                GoToLast(lastID);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_update = async (data) => {
        // ODOSLANIE SPRAVY / UPRAVA SPRAVY
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'chat_agents_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (data.id == 0) {
                    // nový príspevok
                    var tmp = forum;
                    data.id = json.ok;
                    tmp.push(data);

                    setForum(tmp);
                    var lastID = json.ok;
                    GoToLast(lastID);
                } else {
                    // uprava príspevku
                    if (data.text != undefined) {
                        message.text = data.text;
                    }
                    if (data.enabled != undefined) {
                        message.enabled = data.enabled;
                    }
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = () => {
        func(false);
    }

    const GoToLast = (id) => {

        var timer = setTimeout(() => {
            const element = document.getElementById('chat_' + id);

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 500);
    }

    const PreviewImage = (item, deletable) => {
        setMessage(item);
        setPhotoDelete(deletable);
        setPhoto(item.image);
        setShowPhoto(true);
    }

    const AddNew = () => {
        setMessageEdit(false);
        setShowMessage(true);
    }

    const EditMessage = (item) => {

        setMessage(item);
        setMessageEdit(true);
        setShowMessage(true);

    }

    const MessageResult = (typ, text, photo) => {
        setShowMessage(false);

        if (typ == true) {
            if (text.trim() != '' || photo != '') {
                var data = {
                    id: messageEdit == true ? message.id : 0,
                    sender_id: agentID,
                    receiver_id: salonID,
                    salon_id: salonID,
                    agent_id: agentID,
                    enabled: true,
                    text: text.trim(),
                    image: photo == false ? '' : photo,
                    readed: false
                }

                db_update(data);
            } else if (messageEdit == true) {
                DeleteMessage(message);
            }
        }
    }


    const DeleteMessage = (item) => {
        setMessage(item);
        setShowDelete(true);
    }

    const DeleteResult = (value) => {
        setShowDelete(false);

        if (value == true) {
            let data = {
                id: message.id,
                enabled: false
            }

            db_update(data);
        }
    }

    const PhotoResult = (typ, delete_) => {
        setShowPhoto(false);
        if (delete_ == true) {
            let data = {
                id: message.id,
                enabled: false
            }

            db_update(data);
        }
    }

    const ChangeSalon = (item) => {
        setSalonID(item.id);
        setIndex(1);
        db_get(item);

    }

    const Search = (txt) => {
        if (salons != false) {
            let tmp = salons.filter(x => x.name.toLowerCase().includes(txt.toLowerCase()) || x.surname.toLowerCase().includes(txt.toLowerCase()) || x.salon_name.toLowerCase().includes(txt.toLowerCase()) || x.salon_town.toLowerCase().includes(txt.toLowerCase()));
            if (tmp.length == 0) {
                setSalonsFiltered(false);
            } else {
                setSalonsFiltered(tmp);
            }
        }

        setSearch(txt);
    }

    const ChangeAgent = (id) => {
        setAgentID(id);
        setForum(false);
        setSalon(false);
        setSalonID(-1);
        setSalonName('');
        db_get_salons(id);
    }

    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                        <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faComments} />
                    </div>
                </div>
                <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.chat_agents}</p>
                        <p style={{ ...styles.TextDialogSubLabel, marginTop: 4, textAlign: 'left' }}>{lang.chat_agents_salon}</p>
                    </div>
                </div>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Press()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height }}>
                    {props.isSmall == true ?
                        <div style={{ ...styles.BlockCenter, width: props.width, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                            {/*
                                *********************************************************************************************************  
                                BODY -> SMALL SCREEN
                                *********************************************************************************************************
                            */}
                            {index == 0 ?
                                <div style={{ ...styles.BlockCenter, width: '100%', paddingTop: 0, backgroundColor: global.theme_gray, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none' }} className='container'>
                                    {/* ZOZNAM ZAKAZNIKOV */}
                                    <div style={{ ...styles.BlockCenter, width: '98%', paddingBottom: 50 }}>

                                        {props.admin == true ? agents != false ?
                                            <div style={{ ...styles.BlockLeft, paddingTop: 10, paddingBottom: 20, width: '98%' }}>
                                                <p style={{ ...styles.TextXSmall, marginLeft: 10, marginBottom: 2 }}>{lang.rep}</p>
                                                <SelectInput default={lang.choose} theme={props.theme} enabled={true} lang={lang} value={agentID} items={agents} field={'name_surname'} label={lang.mobil} radius={global.radius} func={(txt) => ChangeAgent(txt)} />
                                            </div>
                                            : null : null}

                                        <div style={{ ...styles.Block, marginTop: 10, marginBottom: 10 }}>
                                            <TextField
                                                value={search}
                                                onInput={e => Search(e.target.value)}
                                                size="small"
                                                autoCapitalize="off"
                                                autoComplete="off"
                                                autoCorrect="off"
                                                style={{ width: '98%' }}
                                                placeholder={lang.search_in_category}
                                                onKeyDown={(event) => {
                                                    if (event.key === 'Enter') {
                                                    }
                                                }}
                                                InputProps={{
                                                    endAdornment: <InputAdornment position="end">
                                                        <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                                        </IconButton>
                                                    </InputAdornment>,
                                                    style: { backgroundColor: global.theme_white, borderRadius: 30 },
                                                }}
                                                variant="outlined"
                                            />
                                        </div>


                                        <div style={{ ...styles.BlockLeft, marginTop: 10, marginBottom: 10 }}>
                                            <p style={{ ...styles.TextXSmall, marginLeft: 10, marginBottom: 2 }}>{lang.salons}:</p>
                                        </div>


                                        {salonsFiltered != false ?
                                            salonsFiltered.map((item, index) => (
                                                <div key={item.id} onClick={() => ChangeSalon(item)} style={{ ...styles.Block, backgroundColor: salonID == item.id ? global.theme_light_blue : '#00000000', marginTop: 0, paddingTop: 10, marginBottom: 0, paddingBottom: 10, height: rowHeight, borderRadius: salonID == item.id ? 5 : 0, cursor: 'pointer', borderBottom: salonID == item.id ? undefined : '1px solid ' + global.theme_light_gray }}>
                                                    <div style={{ ...styles.BlockRow }}>
                                                        <div style={{ ...styles.Block, width: rowHeight, height: rowHeight, marginLeft: 5 }}>
                                                            <img src={item.photo != null && item.photo != '' ? GetImageLink(item.photo) : no_image} style={{ width: '100%', height: '100%', width: rowHeight, height: rowHeight, objectFit: 'contain', borderRadius: rowHeight / 2 }}></img>
                                                        </div>
                                                        <div style={{ ...styles.BlockLeft, width: '75%', height: rowHeight, justifyContent: 'center', marginLeft: 10 }}>
                                                            <p style={{ ...styles.TextTiny, fontWeight: '600', color: salonID == item.id ? global.theme_white : global.theme_black }}>{item.surname} {item.name}</p>
                                                            {/*
                                                            <p style={{ ...styles.TextXTiny, marginTop: 2, color: salonID == item.id ? global.theme_white : global.theme_dark_gray }}>{item.not_readed > 0 ? lang.chat_not_readed + ': ' + item.not_readed : ''} </p>
                                                            */}
                                                        </div>
                                                        <div style={{ ...styles.Block, height: rowHeight, justifyContent: 'center', width: 20 }}>
                                                            {item.not_readed > 0 ?
                                                                <FontAwesomeIcon style={{ height: 10, color: global.theme_dark_green }} icon={faCircle} />
                                                                : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : null}
                                    </div>
                                </div>
                                : null}
                            {index == 1 ?
                                <div style={{ ...styles.BlockCenter, width: '100%' }}>

                                    <div style={{ ...styles.BlockRow, height: rowHeight, backgroundColor: global.theme_white, borderBottom: '1px solid ' + global.theme_light_gray }}>
                                        <div style={{ ...styles.Block, width: 50, height: rowHeight, alignItems: 'flex-end' }}>
                                            <IconButton onClick={() => setIndex(0)} style={{ width: 32, height: 32, borderRadius: 16, backgroundColor: global.theme_white }}>
                                                <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faAngleLeft} />
                                            </IconButton>
                                        </div>
                                        <div style={{ ...styles.Block, height: rowHeight }}>
                                            <p style={{ ...styles.TextSmall, color: global.theme_black, fontWeight: '600' }}>{salon.name + ' ' + salon.surname}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 50, height: rowHeight }}></div>
                                    </div>

                                    {/* PRISPEVKY */}
                                    <div style={{ ...styles.BlockCenter, width: '98%', height: props.height - global.dialog_bar_height - bottomHeight - rowHeight, msOverflowStyle: 'none', scrollbarWidth: 'none' }} className='container'>
                                        {forum != false ?
                                            forum.map((item, index) => (
                                                item.enabled == true ?
                                                    <div id={'chat_' + item.id} key={item.id} style={{ ...styles.Block, marginTop: rowOffset, marginBottom: rowOffset }}>
                                                        <div style={{ ...styles.Block, cursor: 'pointer' }}>
                                                            <p style={{ ...styles.TextXXTiny, color: global.theme_dark_gray, marginBottom: 10, marginTop: 10 }}>{item.datum}</p>
                                                        </div>
                                                        {item.sender_id == agentID ?
                                                            // MOJE PRISPEVKY
                                                            <div style={{ ...styles.BlockRight }}>
                                                                <div style={{ ...styles.BlockRight, paddingBottom: 2 }}>
                                                                    {item.readed == true ?
                                                                        <div style={{ ...styles.Block, width: 20, height: 20, backgroundColor: global.theme_xxgray, borderRadius: 10 }}>
                                                                            <FontAwesomeIcon style={{ height: 12, color: global.theme_white }} icon={faCheck} />
                                                                        </div>
                                                                        : null}
                                                                </div>

                                                                {item.image != null && item.image != '' ?
                                                                    <div style={{ ...styles.BlockRight, marginRight: 10 }}>
                                                                        <img onClick={() => PreviewImage(item, true)} src={GetImageLink(item.image)} style={{ width: '100%', height: '100%', maxWidth: imageSize, objectFit: 'contain', borderRadius: imageRadius, marginTop: 5, marginBottom: 5, marginRight: rowTextOffset, cursor: 'pointer' }}></img>
                                                                    </div>
                                                                    : null}
                                                                {item.text != null && item.text != '' ?
                                                                    <div onClick={() => EditMessage(item)} style={{ ...styles.Block, width: undefined, marginRight: 10, maxWidth: '75%', backgroundColor: item.group_id == agentID ? global.theme_lighteen_red : global.theme_lighter_blue, paddingTop: rowPadding, paddingBottom: rowPadding, borderRadius: rowRadius, borderBottomRightRadius: 0, cursor: 'pointer' }}>
                                                                        <div style={{ ...styles.Block }}>
                                                                            {item.group_id == agentID ?
                                                                                <p style={{ ...styles.TextTiny, color: global.theme_dark_red, textAlign: 'left', marginLeft: 10, marginRight: 10, marginBottom: 5 }}>{lang.chat_public_item}</p>
                                                                                : null}
                                                                            <p style={{ ...styles.TextSmall, textAlign: 'left', marginLeft: 10, marginRight: 10 }}>{item.text}</p>
                                                                        </div>
                                                                    </div>
                                                                    : null}
                                                            </div>
                                                            :
                                                            // PRISPEVKY SALONU
                                                            <div style={{ ...styles.BlockLeft }}>
                                                                <p style={{ ...styles.TextXTiny, color: global.theme_dark_gray, marginLeft: rowTextOffset }}>{salonName}</p>
                                                                {item.image != null && item.image != '' ?
                                                                    <div style={{ ...styles.BlockLeft }}>
                                                                        <img onClick={() => PreviewImage(item, false)} src={GetImageLink(item.image)} style={{ width: '100%', height: '100%', maxWidth: imageSize, objectFit: 'contain', borderRadius: imageRadius, marginTop: 5, marginBottom: 5, marginLeft: rowTextOffset, cursor: 'pointer' }}></img>
                                                                    </div>
                                                                    : null}
                                                                {item.text != null && item.text != '' ?
                                                                    <div style={{ ...styles.Block, width: undefined, maxWidth: '75%', marginTop: 2, backgroundColor: global.theme_light_gray, paddingTop: rowPadding, paddingBottom: rowPadding, borderRadius: rowRadius, borderTopLeftRadius: 0 }}>
                                                                        <div style={{ ...styles.Block }}>
                                                                            <p style={{ ...styles.TextTiny, textAlign: 'left', marginLeft: 10, marginRight: 10 }}>{item.text}</p>
                                                                        </div>
                                                                    </div>
                                                                    : null}
                                                            </div>
                                                        }
                                                    </div>
                                                    : null
                                            ))
                                            :
                                            <div style={{ ...styles.Block, height: props.height - global.dialog_bar_height - bottomHeight }}>
                                                {isBusy == false ?
                                                    <EmptyList text={lang.chat_none} lang={lang} />
                                                    : null}
                                            </div>
                                        }
                                    </div>
                                    {/* VKLADANIE PRISPEVKOV */}
                                    <div style={{ ...styles.Block, backgroundColor: global.theme_gold_lighter, height: bottomHeight }}>
                                        {salonID != 0 ?
                                            <Button onClick={() => AddNew()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 240 }}>
                                                <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPlusCircle} />
                                                {lang.chat_new}
                                            </Button>
                                            : null}
                                    </div>
                                </div>
                                : null}
                        </div>
                        :
                        <div style={{ ...styles.BlockRow, width: props.width, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                            <div style={{ ...styles.BlockCenter, width: menuWidth, paddingTop: 0, backgroundColor: global.theme_gray, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none' }} className='container'>
                                {/* ZOZNAM ZAKAZNIKOV */}
                                <div style={{ ...styles.BlockCenter, width: '98%', paddingTop: 10, paddingBottom: 10 }}>
                                    <div style={{ ...styles.Block, paddingBottom: 20 }}>

                                        {props.admin == true ? agents != false ?
                                            <div style={{ ...styles.BlockLeft, paddingBottom: 20, width: '98%' }}>
                                                <p style={{ ...styles.TextXSmall, marginLeft: 10, marginBottom: 2 }}>{lang.rep}</p>
                                                <SelectInput default={lang.choose} theme={props.theme} enabled={true} lang={lang} value={agentID} items={agents} field={'name_surname'} label={lang.mobil} radius={global.radius} func={(txt) => ChangeAgent(txt)} />
                                            </div>
                                            : null : null}

                                        <div onClick={() => ChangeSalon({ id: 0 })} style={{ ...styles.Block, marginBottom: 20, backgroundColor: salonID == 0 ? global.theme_light_blue : '#00000000', height: props.admin == true ? 56 : 40, borderRadius: salonID == 0 ? 5 : 0, cursor: 'pointer', borderBottom: salonID == 0 ? undefined : '1px solid ' + global.theme_light_gray }}>
                                            <p style={{ ...styles.TextSmall, fontWeight: '500', color: salonID == 0 ? global.theme_white : global.theme_black }}>{lang.chat_agents_salons}</p>
                                            {props.admin == true ?
                                                <p style={{ ...styles.TextTiny, color: salonID == 0 ? global.theme_white : global.theme_black, marginTop: 2 }}>{lang.chat_agents_salons_reps}</p>
                                                : null}
                                        </div>

                                        <TextField
                                            value={search}
                                            onInput={e => Search(e.target.value)}
                                            size="small"
                                            autoCapitalize="off"
                                            autoComplete="off"
                                            autoCorrect="off"
                                            style={{ width: '98%' }}
                                            placeholder={lang.search_in_category}
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter') {
                                                }
                                            }}
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">
                                                    <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                                    </IconButton>
                                                </InputAdornment>,
                                                style: { backgroundColor: global.theme_white, borderRadius: 30 },
                                            }}
                                            variant="outlined"
                                        />
                                    </div>

                                    <div style={{ ...styles.BlockLeft, marginTop: 10, marginBottom: 10 }}>
                                        <p style={{ ...styles.TextXSmall, marginLeft: 10, marginBottom: 2 }}>{lang.salons}:</p>
                                    </div>

                                    {salonsFiltered != false ?
                                        salonsFiltered.map((item, index) => (
                                            <div key={item.id} onClick={() => ChangeSalon(item)} style={{ ...styles.Block, backgroundColor: salonID == item.id ? global.theme_light_blue : '#00000000', marginTop: 0, paddingTop: 10, marginBottom: 0, paddingBottom: 10, height: rowHeight, borderRadius: salonID == item.id ? 5 : 0, cursor: 'pointer', borderBottom: salonID == item.id ? undefined : '1px solid ' + global.theme_light_gray }}>
                                                <div style={{ ...styles.BlockRow }}>
                                                    <div style={{ ...styles.Block, width: rowHeight - 10, height: rowHeight, marginLeft: 5 }}>
                                                        <img src={item.photo != null && item.photo != '' ? GetImageLink(item.photo) : no_image} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight / 2 }}></img>
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, width: '75%', height: rowHeight, justifyContent: 'center', marginLeft: 10 }}>
                                                        <p style={{ ...styles.TextXSmall, textAlign: 'left', fontWeight: '600', color: salonID == item.id ? global.theme_white : global.theme_black }}>{item.surname} {item.name}</p>
                                                        <p style={{ ...styles.TextXTiny, textAlign: 'left', color: salonID == item.id ? global.theme_white : global.theme_black }}>{item.salon_name}</p>
                                                        <p style={{ ...styles.TextXTiny, textAlign: 'left', color: salonID == item.id ? global.theme_white : global.theme_black }}>{item.salon_town}</p>
                                                        {/*
                                                        <p style={{ ...styles.TextXTiny, textAlign: 'left', marginTop: 2, color: salonID == item.id ? global.theme_white : global.theme_dark_gray }}>{item.not_readed > 0 ? lang.chat_not_readed + ': ' + item.not_readed : ''} </p>
                                                        */}
                                                    </div>
                                                    <div style={{ ...styles.Block, height: rowHeight, justifyContent: 'center', width: 20 }}>
                                                        {item.not_readed > 0 ?
                                                            <FontAwesomeIcon style={{ height: 10, color: global.theme_dark_green }} icon={faCircle} />
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                        )) : null}
                                </div>
                            </div>
                            {/*
                                *********************************************************************************************************  
                                BODY - LARGE SCREEN
                                *********************************************************************************************************
                            */}
                            <div style={{ ...styles.BlockCenter, width: props.width - menuWidth }}>
                                {/* PRISPEVKY */}
                                <div style={{ ...styles.BlockCenter, width: '98%', height: props.height - global.dialog_bar_height - bottomHeight, msOverflowStyle: 'none', scrollbarWidth: 'none' }} className='container'>
                                    {forum != false ?
                                        forum.map((item, index) => (
                                            item.enabled == true ?
                                                <div id={'chat_' + item.id} key={item.id} style={{ ...styles.Block, marginTop: rowOffset, marginBottom: rowOffset }}>
                                                    <div style={{ ...styles.Block, cursor: 'pointer' }}>
                                                        <p style={{ ...styles.TextXXTiny, color: global.theme_dark_gray, marginBottom: 10, marginTop: 10 }}>{item.datum}</p>
                                                    </div>
                                                    {item.sender_id == agentID ?
                                                        // MOJE PRISPEVKY
                                                        <div style={{ ...styles.BlockRight }}>
                                                            <div style={{ ...styles.BlockRight, paddingBottom: 2 }}>
                                                                {item.readed == true ?
                                                                    <div style={{ ...styles.Block, width: 20, height: 20, backgroundColor: global.theme_xxgray, borderRadius: 10 }}>
                                                                        <FontAwesomeIcon style={{ height: 12, color: global.theme_white }} icon={faCheck} />
                                                                    </div>
                                                                    : null}
                                                            </div>
                                                            {item.image != null && item.image != '' ?
                                                                <div style={{ ...styles.BlockRight, marginRight: 10 }}>
                                                                    {item.group_id == agentID ?
                                                                        <p style={{ ...styles.TextTiny, color: global.theme_dark_red, textAlign: 'left', marginLeft: 10, marginRight: 10, marginBottom: 5 }}>{lang.chat_public_item}</p>
                                                                        : null}
                                                                    <img onClick={() => PreviewImage(item, true)} src={GetImageLink(item.image)} style={{ width: '100%', height: '100%', maxWidth: imageSize, objectFit: 'contain', borderRadius: imageRadius, marginTop: 5, marginBottom: 5, marginRight: rowTextOffset, cursor: 'pointer' }}></img>
                                                                </div>
                                                                : null}
                                                            {item.text != null && item.text != '' ?
                                                                <div onClick={() => EditMessage(item)} style={{ ...styles.Block, marginRight: 10, width: undefined, maxWidth: '75%', backgroundColor: item.group_id == agentID ? global.theme_lighteen_red : global.theme_lighter_blue, paddingTop: rowPadding, paddingBottom: rowPadding, borderRadius: rowRadius, borderBottomRightRadius: 0, cursor: 'pointer' }}>
                                                                    <div style={{ ...styles.Block }}>
                                                                        {item.group_id == agentID ?
                                                                            <p style={{ ...styles.TextTiny, color: global.theme_dark_red, textAlign: 'left', marginLeft: 10, marginRight: 10, marginBottom: 5 }}>{lang.chat_public_item}</p>
                                                                            : null}
                                                                        <p style={{ ...styles.TextSmall, textAlign: 'left', marginLeft: 10, marginRight: 10 }}>{item.text}</p>
                                                                    </div>
                                                                </div>
                                                                : null}
                                                        </div>
                                                        :
                                                        // PRISPEVKY SALONU
                                                        <div style={{ ...styles.BlockLeft }}>
                                                            <p style={{ ...styles.TextXTiny, color: global.theme_dark_gray, marginLeft: rowTextOffset }}>{salonName}</p>
                                                            {item.image != null && item.image != '' ?
                                                                <div style={{ ...styles.BlockLeft }}>
                                                                    <img onClick={() => PreviewImage(item, false)} src={GetImageLink(item.image)} style={{ width: '100%', height: '100%', maxWidth: imageSize, objectFit: 'contain', borderRadius: imageRadius, marginTop: 5, marginBottom: 5, marginLeft: rowTextOffset, cursor: 'pointer' }}></img>
                                                                </div>
                                                                : null}
                                                            {item.text != null && item.text != '' ?
                                                                <div style={{ ...styles.Block, width: undefined, maxWidth: '75%', marginTop: 2, backgroundColor: global.theme_light_gray, paddingTop: rowPadding, paddingBottom: rowPadding, borderRadius: rowRadius, borderTopLeftRadius: 0 }}>
                                                                    <div style={{ ...styles.Block }}>
                                                                        <p style={{ ...styles.TextSmall, textAlign: 'left', marginLeft: 10, marginRight: 10 }}>{item.text}</p>
                                                                    </div>
                                                                </div>
                                                                : null}
                                                        </div>
                                                    }
                                                </div>
                                                : null
                                        ))
                                        :
                                        <div style={{ ...styles.Block, height: props.height - global.dialog_bar_height - bottomHeight }}>
                                            {isBusy == false ?
                                                <EmptyList text={lang.chat_none} lang={lang} />
                                                : null}
                                        </div>
                                    }
                                </div>
                                {/* VKLADANIE PRISPEVKOV */}
                                <div style={{ ...styles.Block, backgroundColor: global.theme_gold_lighter, height: bottomHeight }}>
                                    <Button onClick={() => AddNew()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 240 }}>
                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPlusCircle} />
                                        {lang.chat_agents_new_message}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>

            {
                showDelete == true ?
                    <DialogYesNo theme={props.theme} radius={props.radius} title={lang.chat_delete} sub_title={''} text={lang.chat_delete_ask} sub_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={DeleteResult.bind(this)} />
                    : null
            }

            {
                showMessage == true ?
                    <ChatAgentMessage messageEdit={messageEdit} message={message.text} radius={props.radius} lang={lang} title={''} sub_title={lang.chat} offset={props.offset} theme={props.theme} isSmall={props.isSmall} func={MessageResult.bind(this)} />
                    : null
            }

            {
                showPhoto == true ?
                    <DialogImagePreview theme={props.theme} delete={photoDelete} file={GetImageLink(photo)} radius={props.radius} lang={lang} title={lang.preview} sub_title={lang.chat} background={global.theme_dark_gray} crop={false} func={PhotoResult.bind(this)} />
                    : null
            }

            {
                isBusy == true ?
                    <Loading offset={props.offset} theme={props.theme}  ></Loading>
                    : null
            }

        </Paper >
    );
}


export const ChatAgentMessage = (props) => {
    /*
        Nová / úprava správy
    */

    const [isBusy, setBusy] = useState(false);

    // MESSAGE
    const [text, setText] = useState('');

    // PHOTO
    const [showSelectPhoto, setShowSelectPhoto] = useState(false);
    const [photo, setPhoto] = useState(false);
    const [filename, setFilename] = useState('');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 800 ? 800 : width;
    const dialogHeight = 350;
    const buttonHeight = 80;
    const imageSize = props.isSmall ? 130 : 170;

    const no_image = require('./react/app/no_image.jpg');

    let { func } = props;

    const inputRef = useRef(null);
    var lang = props.lang;
    var running = false;

    useEffect(() => {

        if (running == false) {
            running = true;

            if (props.messageEdit == true) {
                setText(props.message);
            }

            // FOCUS TO TEXTAREA
            setTimeout(() => {
                inputRef.current.focus();
            }, 300);

        }

        return () => {
        };

    }, []);

    const db_photo_delete = async (file) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'upload_image_delete', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    file: file
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setPhoto(false);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (value) => {
        func(value, text, photo);
    }

    const DeletePhoto = () => {
        db_photo_delete(photo);
    }

    const PhotosResult = (typ, path, file) => {
        setShowSelectPhoto(false);

        if (typ == true) {
            setPhoto(path + file);
            setFilename(file);
        }
    }

    return (
        <Backdrop open={true} style={{ position: 'fixed', top: GetTopPosition(height, dialogHeight), backgroundColor: '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faMessage} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.chat_agents_new}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.chat_agents}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, backgroundColor: global.theme_back }}>

                    <div style={{ ...props.isSmall == true ? styles.Block : styles.BlockRow }}>
                        <div style={{ ...styles.BlockCenter, width: props.messageEdit == false ? props.isSmall == true ? '100%' : '70%' : '100%' }}>
                            <div style={{ ...styles.BlockCenter, width: '98%' }}>
                                <div style={{ ...styles.BlockLeft, height: 30, justifyContent: 'center' }}>
                                    <p style={{ ...styles.TextTiny, marginLeft: 5 }}>{lang.chat_agents_text}</p>
                                </div>
                                <TextField
                                    inputRef={inputRef}
                                    value={text}
                                    onInput={e => setText(e.target.value)}
                                    size="small"
                                    autoCapitalize="off"
                                    autoComplete="off"
                                    autoCorrect="off"
                                    multiline={true}
                                    rows={props.isSmall == true ? props.messageEdit ? 5 : 1 : 5}
                                    style={{ width: '100%' }}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                        }
                                    }}
                                    InputProps={{
                                        style: { backgroundColor: global.theme_white, borderRadius: 0 }
                                    }}
                                    variant="outlined"
                                />
                            </div>
                        </div>
                        {props.messageEdit == false ?
                            <div style={{ ...styles.Block, width: '30%', height: props.isSmall ? 100 : dialogHeight - global.dialog_bar_height - buttonHeight, position: 'relative' }}>
                                {photo != false ?
                                    <div style={{ ...styles.Block, marginTop: props.isSmall ? 10 : 30 }}>
                                        <img src={photo == false ? no_image : GetImageLink(photo)} style={{ width: '100%', height: '100%', maxWidth: imageSize - 40, maxHeight: imageSize - 40, objectFit: 'contain' }}></img>
                                    </div>
                                    :
                                    null
                                }
                                {photo == false ?
                                    <Button onClick={() => setShowSelectPhoto(true)} style={{ ...styles.ButtonDark, backgroundColor: global.theme_dark_gray, width: 200 }}>
                                        <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faPlusCircle} />
                                        <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.galery_add}</p>
                                    </Button>
                                    :
                                    <IconButton onClick={() => DeletePhoto()} style={{ width: 30, height: 30, position: 'absolute', top: props.isSmall ? 5 : 15, right: props.isSmall ? 5 : 15, backgroundColor: global.theme_black }}>
                                        <FontAwesomeIcon style={{ height: 12, color: global.theme_white }} icon={faXmark} />
                                    </IconButton>
                                }
                            </div>
                            : null}
                    </div>
                </div>
                <div style={{ ...styles.Block, height: buttonHeight }}>
                    <Button onClick={() => Press(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 200 }}>
                        <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faPaperPlane} />
                        {lang.send}
                    </Button>
                </div>
            </Paper>

            {showSelectPhoto == true ?
                <Photos lang={lang} typ={1} max_size={1200} width={1200} height={1200} full={true} title={lang.photo_select} sub_title={lang.vision_system} radius={props.radius} offset={props.offset} theme={props.theme} func={PhotosResult.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop>
    );
}