import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { IsEmpty, TextInput, CopyData, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, GetPrice, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, ShowError, ShowOK, TemplateWindow, DialogEmail, DialogNotify, GetMoney, Debug, TextSearch } from './items';
import { styles } from './styles';
import { Button, Chip, IconButton, InputAdornment, MenuItem, Pagination, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEnvelope, faExclamationTriangle, faL, faLayerGroup, faList, faListAlt, faMobileAlt, faPen, faQuestion, faShare, faXmark } from '@fortawesome/free-solid-svg-icons';
import { SalonCard } from './salon_card';


export const Salons = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(props.country != false ? props.country.id : 0);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);
    const [filter, setFilter] = useState(false);

    // ZOZNAM REPREZENTANTOV
    const [agents, setAgents] = useState(false);
    const [agentID, setAgentID] = useState(-1);

    // ZOZNAM
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const itemsMax = 24;

    // KARTA ZAKAZNIKA - SALON
    const [showCard, setShowCard] = useState(false);

    // EXPORT KARTY
    const [showExport, setShowExport] = useState(false);

    // OK
    const [showOK, setShowOK] = useState(false);

    const menuWidth = 220;
    const rowHeight = props.isSmall ? 140 : 134;
    const rowWidth = 1000;
    const bottomHeight = 120;


    // COLORS
    const font_selected = global.theme_black;
    const font_disabled = global.theme_darker;

    const no_image = require('./react/app/no_image.jpg');
    const avatar_man = require('./react/app/man.png');
    const avatar_woman = require('./react/app/woman.png');

    // Aktuálna farebna téma
    const color_theme = global.themes[props.theme];

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {

        if (running == false) {
            running = true;

            var filter_ = {};
            db_agents(filter_);
        }

    }, []);

    const db_get = async (filter_) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url_agent + 'salons', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    admin: props.admin,
                    filter: filter_
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;

                if (tmp != false) {
                    tmp.sort((a, b) => {
                        if (a.surname > b.surname) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_agents = async (filter_) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url_agent + 'agents', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    admin: props.admin,
                    filter: filter_
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.agents;

                if (props.admin == false) {
                    tmp = tmp.filter(x => x.id == props.user.id);
                }
                setAgents(tmp);

                var filter_ = { enabled: true, language: props.country != false ? props.country.language_id : 0 };
                db_get(filter_);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_search = async (search) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url_agent + 'salons_search', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    admin: props.admin,
                    filter: filter,
                    search: search,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;

                if (tmp != false) {
                    tmp.sort((a, b) => {
                        if (a.surname > b.surname) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                    setIndex(-1);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                    setIndex(-1);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }



    const db_export = async () => {
        if (items != false) {
            setBusy(true);

            var arr = [];
            items.forEach(item => {
                arr.push(item.id);
            });


            try {
                const response = await fetch(
                    global.db_url_agent + 'salons_export', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        user_id: props.user.id,
                        admin: props.admin,
                        filter: filter,
                        arr: arr,
                        email: props.user.email,
                        lang: {
                            do_not_reply: lang.do_not_reply,
                            do_not_reply_text: lang.do_not_reply_text,

                            label: lang.salons,
                            sub_label: '',
                            created: lang.created,
                            email: lang.email_,
                            mobil: lang.mobil.toLowerCase(),
                            firm: lang.settings_company,
                            ico: lang.ico,
                            dic: lang.dic,
                            ic_dph: lang.ic_dph,
                            rep: lang.rep,
                            credit: lang.bonus_credit,
                            clients_count: lang.info_clients_count,

                            email_subject: lang.salons,
                            email_label: lang.salons,
                            email_text: lang.salons_export,
                        }
                    })
                })

                const json = await response.json();
                setBusy(false);

                if (json.ok > 0) {
                    setShowOK(true);
                }

            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const ChangeIndex = (typ, id) => {

        if (typ == 1) {
            // ZMENA KRAJINY
            var item = props.countries.find(x => x.id == id);
            if (item != undefined) {
                var filter_ = { enabled: true, language: item.language_id };
                setIndex(item.id);
                setAgentID(0);
            }
        }
        if (typ == 2) {
            // ZMENA AGENTA
            var filter_ = { enabled: true, agent_id: id };
            setIndex(9000 + parseInt(id));
            setAgentID(id);
        }

        setSearch('');
        setFilter(filter_);

        db_get(filter_);
    }

    const Search = (text) => {
        setSearch(text);
        if (text.trim().length > 2) {
            db_search(text.trim());
        }
        if (text == '') {
            setItems(false);
            setItemsFiltered(false);
            setIndex(-1);
        }
    }

    const Redraw = (items, p, search) => {
        // REDRAW ZOZNAMU
        var data = items;

        var count = data.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax)
        setItemsFiltered(data);

        if (count == 0) {
            setItemsFiltered(false);
        }

        setPage(p);
        GoToStart();
    }

    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);

        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0, '');
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_, search);
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_scroll');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const ExportResult = (value) => {
        setShowExport(false);
        if (value == true) {
            db_export();
        }
    }

    const Card = (item) => {
        setItemSelected(item);
        setShowCard(true);
    }

    const CardResult = (typ, data) => {

        if (typ == 1) {
            // ZMENA OBCHODNEHO ZASTUPCU
            itemSelected.agent = data.agent;
            itemSelected.agent_id = data.agent_id;
        } else if (typ == 2) {
            itemSelected.salon1_id = data.salon1_id;
        }
        else {
            setShowCard(false);
        }
    }

    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                }
                <div style={{ ...styles.Block, width: '40%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                    <div style={{ ...styles.Block, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...props.isSmall ? styles.TextNormal : styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>

                        {props.isSmall ? null :
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        }
                    </div>
                </div>
                <div style={{ ...styles.BlockRow, justifyContent: 'flex-end', alignItems: 'center', width: props.isSmall ? 160 : '50%', height: global.dialog_bar_height }}>
                    <TextSearch lang={props.lang} isSmall={props.isSmall} func={Search.bind(this)} />
                    {/*
                    <TextField
                        value={search}
                        onInput={e => Search(e.target.value)}
                        size="small"
                        autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off"
                        style={{ width: props.isSmall ? 150 : 300 }}
                        placeholder={lang.search_in_category}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                            }
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                    <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                </IconButton>
                            </InputAdornment>,
                            style: { backgroundColor: global.theme_white, borderRadius: 30 },
                        }}
                        variant="outlined"
                    />
                    */}
                </div>
                <div style={{ ...styles.Block, width: props.isSmall ? 150 : 200, height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        <IconButton disabled={items != false ? false : true} onClick={() => setShowExport(true)} style={{ width: 36, height: 36, backgroundColor: global.theme_white, marginRight: props.isSmall ? 20 : 36 }}>
                            <FontAwesomeIcon style={{ width: 16, color: color_theme.theme_darker }} icon={faShare} />
                        </IconButton>


                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, height: props.height - global.dialog_bar_height }}>

                    {props.isSmall ?
                        <div style={{ ...styles.Block }}>
                            <Select
                                value={index}
                                onChange={event => ChangeIndex(1, event.target.value)}
                                size='small'
                                style={{ backgroundColor: global.theme_white, width: '100%' }}
                            >
                                {props.countries != false ?
                                    props.countries.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>{item.country}</MenuItem>
                                    ))
                                    : null}

                            </Select>
                        </div>
                        :

                        <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                            <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height - bottomHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                {props.countries != false ?
                                    props.countries.map((item) => (
                                        <Button key={item.id} onClick={() => ChangeIndex(1, item.id)} style={{ ...styles.ButtonSettingsMedium, width: menuWidth - 5, justifyContent: 'flex-start', backgroundColor: index == item.id ? global.theme_back : '#00000000', color: index == item.id ? font_selected : font_disabled }}>
                                            <div style={{ ...styles.BlockLeft }}>
                                                <p style={{ ...styles.TextSmall, fontSize: global.font_small, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{item.country}</p>
                                            </div>
                                        </Button>
                                    ))
                                    : null}

                                <div style={{ ...styles.BlockLeft, height: 40, borderBottom: '1px solid ' + global.themes[props.theme].theme_medium, justifyContent: 'center' }}>
                                    <p style={{ ...styles.TextTiny, color: global.themes[props.theme].theme_dark, marginLeft: 10 }}>{lang.salons_rep}</p>
                                </div>

                                {agents != false ?
                                    agents.map((item) => (
                                        <Button key={item.id} onClick={() => ChangeIndex(2, item.id)} style={{ ...styles.ButtonSettingsMedium, width: menuWidth - 5, justifyContent: 'flex-start', backgroundColor: index == 9000 + parseInt(item.id) ? global.theme_back : '#00000000', color: index == 9000 + parseInt(item.id) ? font_selected : font_disabled }}>
                                            <div style={{ ...styles.BlockLeft }}>
                                                <p style={{ ...styles.TextSmall, fontSize: global.font_small, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{item.name} {item.surname}</p>
                                            </div>
                                        </Button>
                                    ))
                                    : null}
                            </div>
                            <div style={{ ...styles.Block, height: bottomHeight, borderTop: '1px solid #FFFFFF50' }}>
                                {/* ODOSLAT SPRAVU / EMAIL */}
                                {/*
                            <div style={{ ...styles.Block }}>
                                <Button onClick={() => SendEmail()} style={{ ...styles.ButtonDark, width: menuWidth - 20 }}>
                                    <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faEnvelope} />
                                    {lang.send_email}
                                </Button>
                                <Button onClick={() => SendNotify()} style={{ ...styles.ButtonDark, width: menuWidth - 20, marginTop: 20 }}>
                                    <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faMobileAlt} />
                                    {lang.send_notify}
                                </Button>
                            </div>
                            */}
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.BlockCenter, width: props.isSmall ? '100%' : props.width - menuWidth, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        {/* BODY */}
                        <div id={'id_scroll'}>
                        </div>
                        <div style={{ ...styles.Block, width: '98%' }}>
                            {/* *********************************************************************************************************  
                                ZOZNAM SALONOV
                            ********************************************************************************************************* */}
                            {itemsFiltered != false ?
                                itemsFiltered.map((item) => (
                                    <Paper elevation={item.id == overID ? 4 : 1} key={item.id} style={{
                                        ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: item.enabled == true ? item.theme_id == 0 ? global.theme_lighter : global.colors[item.theme_id] : global.theme_gray, marginTop: 10, marginBottom: 10, borderRadius: 10, cursor: 'pointer'
                                    }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow }}>
                                            {props.isSmall ? null :
                                                <div onClick={() => Card(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center', alignItems: 'center' }}>
                                                    <img src={item.photo == null || item.photo == '' ? no_image : item.photo.substring(0, 4) == 'http' ? item.photo : global.web_images + '/' + item.photo} style={{ width: '100%', height: '100%', width: rowHeight - 30, height: rowHeight - 30, objectFit: 'contain', borderRadius: rowHeight - 30, marginLeft: 10 }}></img>
                                                </div>
                                            }
                                            {props.isSmall ?
                                                <div onClick={() => Card(item)} style={{ ...styles.Block, height: rowHeight, width: '100%' }}>
                                                    <div style={{ ...styles.Block, width: '90%', justifyContent: 'center', alignItems: 'flex-start' }}>
                                                        <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.name} {item.surname}</p>
                                                        <p style={{ ...styles.TextTiny }}>{lang.bonus_credit}: <b>{GetPrice(parseFloat(item.bonus_credit) < 0 ? 0 : item.bonus_credit)}</b></p>
                                                        <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                            <div style={{ ...styles.BlockLeft, width: '60%' }}>
                                                                <div style={{ ...styles.BlockRowRaw }}>
                                                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faMobileAlt} />
                                                                    <p style={{ ...styles.TextTiny, marginLeft: 5, textAlign: 'left' }}>{item.mobil}</p>
                                                                </div>
                                                                <div style={{ ...styles.BlockRowRaw }}>
                                                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faEnvelope} />
                                                                    <p style={{ ...styles.TextTiny, marginLeft: 5, textAlign: 'left' }}>{item.email}</p>
                                                                </div>
                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: '40%' }}>
                                                                <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{item.street} {item.street_number}</p>
                                                                <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{item.town}</p>
                                                            </div>
                                                        </div>
                                                        <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.rep}: {item.agent}</p>
                                                    </div>
                                                </div>
                                                :
                                                <div onClick={() => Card(item)} style={{ ...styles.Block, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                    <div style={{ ...styles.BlockRow, justifyContent: 'center', height: rowHeight / 2 - 5 }}>
                                                        <div style={{ ...styles.BlockLeft, width: '40%', justifyContent: 'center', height: rowHeight / 2 - 5 }}>
                                                            <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.name} {item.surname}</p>
                                                            <p style={{ ...styles.TextTiny, fontWeight: '400', textAlign: 'left' }}>{item.company}</p>
                                                            {item.enabled == false ?
                                                                <p style={{ ...styles.TextTiny, fontWeight: '400', marginTop: 2, textAlign: 'left' }}>{lang.salon_hidden}</p>
                                                                : null}
                                                        </div>
                                                        <div style={{ ...styles.BlockLeft, width: '30%', justifyContent: 'center', height: rowHeight / 2 - 5 }}>
                                                            <p style={{ ...styles.TextTiny }}>{lang.bonus_credit}: <b>{GetPrice(parseFloat(item.bonus_credit) < 0 ? 0 : item.bonus_credit)}</b></p>
                                                        </div>
                                                        <div style={{ ...styles.BlockLeft, width: '30%', justifyContent: 'center', height: rowHeight / 2 - 5 }}>
                                                            <p style={{ ...styles.TextTiny }}>{item.agent}</p>
                                                        </div>
                                                    </div>
                                                    <div style={{ ...styles.BlockRow, justifyContent: 'center', height: rowHeight / 2 - 5, borderTop: '1px solid #00000030' }}>
                                                        <div style={{ ...styles.BlockLeft, width: '40%', justifyContent: 'center', height: rowHeight / 2 - 5 }}>
                                                            <div style={{ ...styles.BlockRowRaw }}>
                                                                <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faMobileAlt} />
                                                                <p style={{ ...styles.TextTiny, marginLeft: 5 }}>{item.mobil}</p>
                                                            </div>
                                                            <div style={{ ...styles.BlockRowRaw, marginTop: 4 }}>
                                                                <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faEnvelope} />
                                                                <p style={{ ...styles.TextTiny, marginLeft: 5 }}>{item.email}</p>
                                                            </div>
                                                        </div>
                                                        <div style={{ ...styles.BlockLeft, width: '30%', justifyContent: 'center', height: rowHeight / 2 - 5 }}>
                                                            <p style={{ ...styles.TextTiny }}>{item.street} {item.street_number}</p>
                                                            <p style={{ ...styles.TextTiny }}>{item.psc} {item.town}</p>
                                                        </div>
                                                        <div style={{ ...styles.BlockLeft, width: '30%', justifyContent: 'center', height: rowHeight / 2 - 5 }}>
                                                            {item.crm_id == '' ?
                                                                <div style={{ ...styles.BlockLeft }}>
                                                                    <p style={{ ...styles.TextTiny, color: global.theme_dark_red }}>{lang.booking_missing_id}:</p>
                                                                </div>
                                                                :
                                                                <div style={{ ...styles.BlockLeft }}>
                                                                    <p style={{ ...styles.TextTiny }}>{lang.alias}: {item.alias}</p>
                                                                </div>
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            }
                                            {props.isSmall ? null :
                                                <div style={{ ...styles.Block, width: 130, height: rowHeight }}>
                                                    <p style={{ ...styles.TextXXTiny }}>{lang.version}</p>
                                                    <p style={{ ...styles.TextTiny, marginTop: 5, marginBottom: 10 }}>{item.version}</p>
                                                    <p style={{ ...styles.TextXXTiny }}>{lang.register}</p>
                                                    <Chip size="small" variant={item.salon1_id == 0 ? "outlined" : undefined} label={item.salon1_id == 0 ? lang.salon_ : lang.typ_other} style={{ marginTop: 2, backgroundColor: item.salon1_id == 0 ? undefined : global.theme_lighteen_red }}></Chip>
                                                </div>
                                            }
                                        </div>
                                    </Paper>
                                )) :
                                // ZIADNE ZAZNAMY                            
                                <div style={{ ...styles.Block, paddingTop: 20 }}>
                                    {isBusy == false ?
                                        <EmptyList lang={lang} />
                                        : null}
                                </div>
                            }

                            {itemsFiltered != false ?
                                pageMax > 1 ?
                                    <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                        <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                    </div>
                                    : <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>1/1</div>
                                : null}
                        </div>

                    </div>
                </div>
            </div>


            {showExport == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.booking_export_} sub_title={''} text={lang.export_agent_data_ask} sub_text={lang.export_agent_data_text + ' ' + props.user.email} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={ExportResult.bind(this)} />
                : null}

            {showCard == true ?
                <SalonCard editing={true} salon_id={itemSelected.id} remoteApp={props.remoteApp} user={props.user} admin={props.admin} countries={props.countries} country={props.country} lang={lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} isSmall={props.isSmall} title={lang.salons_list} sub_title={lang.salons} func={CardResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} side={menuWidth} func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} side={menuWidth}></Loading>
                : null}

        </Paper >
    );
}
