import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './globals.js';
import { Init } from './items';
import { Main } from './Main';
import { NotFound } from './notfound';
import { styles } from './styles';
import { Start } from './Start';
import { Report } from './report';
import { RemoteLogin } from './RemoteLogin';
import { Helmet } from 'react-helmet';
import { AppLogout } from './logout';
import { Register } from './register';
import { NewsDisplay } from './news';
import { Geolocation } from './geolocation';

function App() {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {

        // RELOAD PAGE - AK SA ZMENI VELKOST OKNA               
        function handleResize() {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }
        window.addEventListener('resize', handleResize);

        Init();
    }, [])


    return (
        <div className="App" style={{ ...styles.Block, height: height, backgroundColor: global.theme_black, fontFamily: 'roboto', WebkitUserSelect: 'none', msUserSelect: 'none', MozUserSelect: 'none', userSelect: 'none' }}>
            <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
            />
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
            </Helmet>
            <Routes>
                <Route path="/" element={<Start />} />
                <Route path="/public" element={<Start />} />
                <Route path="/code/:token/:code" element={<RemoteLogin />} />
                <Route path="/code/:token/:code/:version" element={<RemoteLogin />} />
                <Route path="/application" element={<Main />} />
                <Route path="/application/remote" element={<Main remote={true} />} />
                <Route path="/application/geolocation/:language/:theme" element={<Geolocation />} />                
                <Route path="/report/:id" element={<Report />} />
                <Route path="/news/:uid" element={<NewsDisplay />} />
                <Route path="/logout" element={<AppLogout />} />
                <Route path="/register" element={<Register />} />
                <Route path="error-404" element={<NotFound />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </div>
    );
}

export default App;
