import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, ConvertToPrice, CopyData, Debug, DialogImagePreview, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, GetDate, GetImageLink, GetPrice, GetTodayYearMonth, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, ShowError, ShowOK, ShowTokenError, TemplateWindow, TextInput, Today } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, CircularProgress, FormControlLabel, IconButton, InputAdornment, MenuItem, Pagination, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCheckCircle, faEdit, faL, faLayerGroup, faList, faListAlt, faQuestion, faSave, faShoppingBag, faShoppingBasket, faTrash, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Graph } from './graph';
import { HoursSettings } from './Settings';
import { Photos } from './photos';
import './items.css';
import { SalonMap } from './geolocation';
import { EditorHtml } from './Editor';

import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';


export const WebSettings = (props) => {
    const blocksFromHTML = convertFromHTML('');
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap,);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(state));
    const [contentState, setContentState] = useState();

    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(-1);

    // DATA
    const [settings, setSettings] = useState(false);
    const [userData, setUserData] = useState(false);

    // TEXTY
    const [webLabel, setWebLabel] = useState('');
    const [webText, setWebText] = useState('');
    const [webInfo, setWebInfo] = useState('');

    // OTVARACIE HODINY
    const [hours, setHours] = useState(false);
    const [hoursEqual, setHoursEqual] = useState(false);

    // DATABASE STATUS
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showTokenError, setShowTokenError] = useState(false);

    // VYBER FOTOGRAFII
    const [showSelectPhoto, setShowSelectPhoto] = useState(false);
    const [photoTyp, setPhotoTyp] = useState(0);
    const [photo, setPhoto] = useState(false);
    const [photoSalon, setPhotoSalon] = useState(false);
    const [previewPhoto, setPreviewPhoto] = useState(false);
    const [showPhoto, setShowPhoto] = useState(false);

    // BANNERY
    const [banners, setBanners] = useState([]);
    const [overID, setOverID] = useState(-1);

    // GEOLOKACIA
    const [geoEnabled, setGeoEnabled] = useState(false);
    const [lat, setLat] = useState('0');
    const [lng, setLng] = useState('0');
    const [showMap, setShowMap] = useState(false);

    // HTML PREVIEW TEXT
    const [showHTML, setShowHTML] = useState(false);
    const [previewHTML, setPreviewHTML] = useState('');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);


    const menuWidth = 250;
    const maxWidth = 800;

    // COLORS
    const font_selected = global.theme_black;
    const font_disabled = global.theme_darker;

    const photoSize = 300;
    const bannerSize = 600;

    const no_image = require('./react/app/no_image.jpg');
    const service_1 = require('./react/app/service1.png');
    const service_2 = require('./react/app/service2.png');

    let { func } = props;
    var lang = props.lang;
    const navigate = useNavigate();

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            db_get();
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'user', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.user.id,
                    token: props.token
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                setUserData(json.user);
                setSettings(json.settings);
                if (json.settings != false) {
                    // TEXTY NA STRANKE
                    setWebLabel(json.settings.label);
                    setWebText(json.settings.text == null ? '' : json.settings.text);
                    setWebInfo(json.settings.info);

                    let html = convertFromHTML(json.settings.text == null ? '' : json.settings.text);
                    let html_state = ContentState.createFromBlockArray(html.contentBlocks, html.entityMap,);
                    setEditorState(EditorState.createWithContent(html_state));

                    // OTVARACIE HODINY
                    let data = [
                        { id: 1, text: json.settings.hours_1 == null ? '' : json.settings.hours_1 },
                        { id: 2, text: json.settings.hours_2 == null ? '' : json.settings.hours_2 },
                        { id: 3, text: json.settings.hours_3 == null ? '' : json.settings.hours_3 },
                        { id: 4, text: json.settings.hours_4 == null ? '' : json.settings.hours_4 },
                        { id: 5, text: json.settings.hours_5 == null ? '' : json.settings.hours_5 },
                        { id: 6, text: json.settings.hours_6 == null ? '' : json.settings.hours_6 },
                        { id: 0, text: json.settings.hours_7 == null ? '' : json.settings.hours_7 },
                    ];
                    setHours(data);

                    // FOTO SALONA
                    setPhotoSalon(json.settings.photo == null || json.settings.photo == '' ? false : json.settings.photo);

                    // BANNERY                    
                    var tmp = [];
                    if (json.settings.banner != null && json.settings.banner != '') {
                        tmp.push({ id: 0, file: json.settings.banner });
                    }
                    if (json.settings.banner2 != null && json.settings.banner2 != '') {
                        tmp.push({ id: 1, file: json.settings.banner2 });
                    }
                    if (json.settings.banner3 != null && json.settings.banner3 != '') {
                        tmp.push({ id: 2, file: json.settings.banner3 });
                    }

                    //tmp.push({ id: 2, file: json.settings.banner3 != null && json.settings.banner3 != '' ? json.settings.banner3 : false });

                    setBanners(tmp);

                    // Geolokacia
                    setGeoEnabled(json.settings.map == false ? false : true);
                    setLat(json.settings.lat);
                    setLng(json.settings.lng);
                }

                setIndex(0);
            } else {
                setShowTokenError(true);
            }


        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_settings_update = async (user_data, settings_data, trade_data, reserve_data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'settings_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_data: user_data,
                    settings_data: settings_data,
                    trade_data: trade_data,
                    reserve_data: reserve_data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = () => {
        func(false);
    }

    const ChangeIndex = (id) => {
        setShowMap(false);
        setIndex(id);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const TokenErrorPress = () => {
        setShowTokenError(false);
        Press();
    }

    const Save = (id) => {

        let html = convertToHTML(editorState.getCurrentContent());

        var settings_id = 0;
        if (settings == false) {
            settings_id = 0;
        } else {
            settings_id = settings.id
        }

        if (id == 0) {
            var data = {
                id: settings_id,
                salon_id: props.user.id,
                label: webLabel.trim(),
                text: html,
                info: webInfo.trim()
            }
        }

        if (id == 1) {
            var data = {
                id: settings_id,
                salon_id: props.user.id,
                hours_1: hours[0].text.trim(),
                hours_2: hours[1].text.trim(),
                hours_3: hours[2].text.trim(),
                hours_4: hours[3].text.trim(),
                hours_5: hours[4].text.trim(),
                hours_6: hours[5].text.trim(),
                hours_7: hours[6].text.trim(),
            }
        }

        db_settings_update(false, data, false, false);

    }

    const HoursResult = (hours_) => {
        setHours(hours_);
    }

    const ChoosePhoto = (typ) => {
        setPhotoTyp(typ);
        setShowSelectPhoto(true);
    }

    const PhotosResult = (typ, path, file) => {
        setShowSelectPhoto(false);

        if (typ == true) {
            var settings_id = 0;
            if (settings == false) {
                settings_id = 0;
            } else {
                settings_id = settings.id
            }

            if (photoTyp == 0) {
                setPhotoSalon(path + file);
                var data = {
                    id: settings_id,
                    salon_id: props.user.id,
                    photo: path + file
                }

                db_settings_update(false, data, false, false);
            }
            if (photoTyp == 1) {
                setPhoto(path + file);
                var tmp = banners;
                var data = {
                    id: tmp.length == 0 ? 0 : tmp[tmp.length - 1].id + 1,
                    file: path + file
                }
                tmp.push(data);
                setBanners(tmp);

                BannersUpdate(tmp);
            }
        }
    }

    const ShowPhoto = (file) => {
        setPreviewPhoto(file);
        setShowPhoto(true);
    }

    const BannerDelete = (item) => {
        var tmp = banners.filter(x => x.id != item.id);
        setBanners(tmp);

        BannersUpdate(tmp);
    }

    const BannersUpdate = (items) => {
        var settings_id = 0;
        if (settings == false) {
            settings_id = 0;
        } else {
            settings_id = settings.id
        }

        var n = 0;
        var banner1 = '';
        var banner2 = '';
        var banner3 = '';
        items.forEach(item => {
            switch (n) {
                case 0:
                    banner1 = item.file;
                    break;
                case 1:
                    banner2 = item.file;
                    break;
                case 2:
                    banner3 = item.file;
                    break;

            }
            n++;
        });

        var data = {
            id: settings_id,
            salon_id: props.user.id,
            banner: banner1,
            banner2: banner2,
            banner3: banner3,
        }
        db_settings_update(false, data, false, false);
    }

    const GetGeo = () => {
        navigate('/application/geolocation/' + props.language + '/' + props.theme);
    }

    const ChangeGeoEnabled = (value) => {
        var settings_id = 0;
        if (settings == false) {
            settings_id = 0;
        } else {
            settings_id = settings.id
        }

        setGeoEnabled(value);

        var data = {
            id: settings_id,
            map: value,
        }
        db_settings_update(false, data, false, false);
    }

    const PreviewHTML = (value) => {
        if (value == true) {
            let html = convertToHTML(editorState.getCurrentContent());
            setPreviewHTML(html);
        }
        setShowHTML(value);
    }

    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                }
                <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left', marginLeft: props.isSmall ? 10 : 0 }}>{props.title}</p>
                        {props.isSmall ? null :
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        }
                    </div>
                </div>
                <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Press()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                    {props.isSmall ?
                        <div style={{ ...styles.Block }}>
                            <Select
                                value={index}
                                onChange={event => ChangeIndex(event.target.value)}
                                size='small'
                                style={{ backgroundColor: global.theme_white, width: '100%' }}
                            /*
                            sx={{
                                border: "1px solid black",
                                color: global.theme_dark_blue,
                                "& .MuiSvgIcon-root": {
                                    color: "black",
                                },
                            }}
                            */
                            >

                                <MenuItem value={0}>{lang.web_page_texts}</MenuItem>
                                <MenuItem value={1}>{lang.web_page_hours}</MenuItem>
                                <MenuItem value={2}>{lang.web_page_banners}</MenuItem>
                                <MenuItem value={3}>{lang.web_page_photo}</MenuItem>
                                {/*props.user.id != global.demo_salon && props.remoteApp == true ?
                                    <MenuItem value={4}>{lang.geo}</MenuItem>
                        : null*/}
                            </Select>
                        </div>
                        :
                        <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                            <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                <Button onClick={() => ChangeIndex(0)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == 0 ? global.theme_back : '#00000000', color: index == 0 ? font_selected : font_disabled }}>
                                    <div style={{ ...styles.BlockLeft }}>
                                        <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{lang.web_page_texts}</p>
                                    </div>
                                </Button>
                                <Button onClick={() => ChangeIndex(1)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == 1 ? global.theme_back : '#00000000', color: index == 1 ? font_selected : font_disabled }}>
                                    <div style={{ ...styles.BlockLeft }}>
                                        <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{lang.web_page_hours}</p>
                                    </div>
                                </Button>
                                <Button onClick={() => ChangeIndex(2)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == 2 ? global.theme_back : '#00000000', color: index == 2 ? font_selected : font_disabled }}>
                                    <div style={{ ...styles.BlockLeft }}>
                                        <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{lang.web_page_banners}</p>
                                    </div>
                                </Button>
                                <Button onClick={() => ChangeIndex(3)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == 3 ? global.theme_back : '#00000000', color: index == 3 ? font_selected : font_disabled }}>
                                    <div style={{ ...styles.BlockLeft }}>
                                        <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{lang.web_page_photo}</p>
                                    </div>
                                </Button>
                                {global.appstore_publishing == false && props.remoteApp == true ?
                                    <Button onClick={() => ChangeIndex(4)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == 4 ? global.theme_back : '#00000000', color: index == 3 ? font_selected : font_disabled }}>
                                        <div style={{ ...styles.BlockLeft }}>
                                            <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{lang.geo}</p>
                                        </div>
                                    </Button>
                                    : null}
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.BlockCenter, width: props.isSmall ? width : props.width - menuWidth, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, overflowY: 'scroll', msOverflowStyle: 'none', scrollbarWidth: 'none' }} className='container'>
                        {
                            /*
                            *
                            * =================================================================================================================
                            * TEXTY - nastavenie textov na stránke
                            * =================================================================================================================
                            *
                            */
                            index == 0 ?
                                <div style={{ ...styles.Block, width: '96%', maxWidth: maxWidth, paddingBottom: global.keyboard_padding }}>
                                    <div style={{ ...styles.Block, width: '98%' }}>
                                        <p style={{ ...styles.TextNormal, fontWeight: '600', marginTop: 20 }}>{lang.settings_web_texts}</p>
                                        <div style={{ ...styles.Block, marginTop: 20 }}>
                                            <TextInput lang={lang} value={webLabel} label={lang.settings_web_label} func={(txt) => setWebLabel(txt)} />
                                        </div>
                                        <div style={{ ...styles.Block, marginTop: 40 }}>
                                            <p style={{ ...styles.TextSmall, marginBottom: 5 }}>{lang.settings_web_text}</p>
                                            {showHTML == false ?
                                                <div style={{ ...styles.Block, height: props.isSmall ? 420 : 380 }}>
                                                    <Editor
                                                        editorState={editorState}
                                                        onEditorStateChange={setEditorState}
                                                        defaultContentState={contentState}
                                                        onContentStateChange={setContentState}

                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"

                                                        editorStyle={{ backgroundColor: global.theme_white, height: 300, border: '1px solid ' + global.theme_light_gray }}
                                                        toolbarStyle={{ backgroundColor: global.theme_gray, width: props.isSmall ? width - 20 : 750 }}

                                                        toolbar={{
                                                            options: ['inline', 'blockType', 'list', 'history'],
                                                            inline: { inDropdown: false },
                                                            list: { inDropdown: false },
                                                            textAlign: { inDropdown: false },
                                                            history: { inDropdown: false },
                                                            inline: {
                                                                inDropdown: false,
                                                                options: ['bold', 'italic', 'underline'],
                                                            },
                                                            blockType: {
                                                                inDropdown: false,
                                                                options: ['Normal', 'H1', 'H2', 'H3'],
                                                            },
                                                            list: {
                                                                inDropdown: false,
                                                                options: ['unordered', 'ordered'],
                                                            },
                                                        }}
                                                    />
                                                </div>
                                                :
                                                <div style={{ ...styles.BlockCenter, height: props.isSmall ? 420 : 380 }}>
                                                    <div style={{ ...styles.BlockLeft, width: '95%' }}>
                                                        <div style={{ ...styles.TextSmall, textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: previewHTML }} />
                                                    </div>
                                                </div>
                                            }
                                            <Button onClick={() => PreviewHTML(!showHTML)} style={{ ...styles.ButtonLink, width:200 }}>{showHTML == true ? lang.text_editor : lang.preview}</Button>

                                        </div>
                                        <div style={{ ...styles.Block, marginTop: 40 }}>
                                            <TextInput lang={lang} value={webInfo} label={lang.settings_web_info} func={(txt) => setWebInfo(txt)} />
                                        </div>
                                    </div>
                                    <div style={{ ...styles.Block, marginTop: 40 }}>
                                        <Button onClick={() => { Save(index) }} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>
                                            <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.save}</p>
                                        </Button>
                                    </div>
                                </div>
                                : null}
                        {/*
                            *
                            * =================================================================================================================
                            * OTVARACIE HODINY
                            * =================================================================================================================
                            *
                            */}
                        {index == 1 ?
                            <div style={{ ...styles.Block, width: '96%', maxWidth: maxWidth, paddingBottom: global.keyboard_padding }}>
                                <p style={{ ...styles.TextNormal, fontWeight: '600', marginTop: 20 }}>{lang.web_page_hours}</p>
                                <div style={{ ...styles.Block, marginTop: 20 }}>
                                    <OpeningHours isSmall={props.isSmall} hours={hours} equal={hoursEqual} lang={lang} radius={props.radius} func={HoursResult.bind(this)} />
                                </div>

                                <div style={{ ...styles.Block, marginTop: 40 }}>
                                    <Button onClick={() => { Save(index) }} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.save}</p>
                                    </Button>
                                </div>
                            </div>
                            : null}

                        {/*
                            *
                            * =================================================================================================================
                            * BANNERY
                            * =================================================================================================================
                            *
                            */}
                        {index == 2 ?
                            <div style={{ ...styles.Block, width: '96%', maxWidth: maxWidth }}>
                                <p style={{ ...styles.TextNormal, fontWeight: '600', marginTop: 20 }}>{lang.web_page_banners}</p>
                                <p style={{ ...styles.TextTiny, fontWeight: '400', marginTop: 2 }}>{lang.web_page_banners_text}</p>
                                <div style={{ ...styles.Block, marginTop: 20, marginBottom: 40 }}>
                                    {banners.map((item, index) => (
                                        <div key={item.id}>
                                            <Paper /* onClick={() => ShowPhoto(item.file)}*/ elevation={item.id == overID ? 4 : 0} style={{ ...styles.BlockCenter, position: 'relative', width: bannerSize, marginTop: 5, marginBottom: 5, borderRadius: props.radius / 2 }}
                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                            >
                                                <img src={GetImageLink(item.file)} style={{ width: '100%', height: '100%', width: props.isSmall ? width - 10 : bannerSize, objectFit: 'contain', borderRadius: 10, cursor: 'pointer' }}></img>
                                                {props.isSmall != true ? item.id == overID ?
                                                    <IconButton onClick={() => BannerDelete(item)} style={{ width: 30, height: 30, position: 'absolute', top: 5, right: 5, backgroundColor: global.theme_black }}>
                                                        <FontAwesomeIcon style={{ height: 12, color: global.theme_white }} icon={faXmark} />
                                                    </IconButton>
                                                    : null : null}
                                            </Paper>
                                            {props.isSmall ?
                                                <div style={{ ...styles.Block, paddingBottom: 30, marginTop: 5 }}>
                                                    <Button onClick={() => BannerDelete(item)} style={{ ...styles.ButtonLink }}>
                                                        <p style={{ ...styles.TextTiny, color: global.theme_black }}>{lang.delete}</p>
                                                    </Button>
                                                </div>
                                                : null}
                                        </div>
                                    ))}
                                </div>

                                {banners.length < 3 ?
                                    <div style={{ ...styles.Block, marginTop: 20, paddingBottom: 40 }}>
                                        <Button onClick={() => { ChoosePhoto(1) }} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>
                                            <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.photo_select}</p>
                                        </Button>
                                    </div>
                                    : null}
                            </div>
                            : null}

                        {/*
                            *
                            * =================================================================================================================
                            * FOTOGRAFIA SALONA
                            * =================================================================================================================
                            *
                            */}
                        {index == 3 ?
                            <div style={{ ...styles.Block, width: '96%', maxWidth: maxWidth }}>
                                <p style={{ ...styles.TextNormal, fontWeight: '600', marginTop: 20 }}>{lang.settings_web_photo}</p>
                                <div style={{ ...styles.Block, marginTop: 20 }}>
                                    <img onClick={() => ShowPhoto(photoSalon)} src={photoSalon == false || photoSalon == null || photoSalon == '' ? no_image : GetImageLink(photoSalon)} style={{ width: '100%', height: '100%', maxWidth: photoSize, maxHeight: photoSize, objectFit: 'contain', borderRadius: 10, cursor: 'pointer' }}></img>
                                    <div style={{ ...styles.Block, marginTop: 20 }}>
                                        <Button onClick={() => { ChoosePhoto(0) }} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>
                                            <p style={{ ...styles.TextTiny, color: global.theme_white }}>{photoSalon == false ? lang.photo_select : lang.photo_change}</p>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            : null}

                        {/*
                            *
                            * =================================================================================================================
                            * GEOLOKACIA
                            * =================================================================================================================
                            *
                            */}
                        {index == 4 ?
                            <div style={{ ...styles.BlockCenter, width: '96%', maxWidth: maxWidth, paddingBottom: 100 }}>
                                <p style={{ ...styles.TextNormal, fontWeight: '600', marginTop: 20 }}>{lang.geo}</p>
                                <div style={{ ...styles.Block }}>
                                    <p style={{ ...styles.TextSmall, fontWeight: '600', marginTop: 20 }}>{lang.geo_map}</p>
                                    <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.geo_web_show}</p>}
                                        control={<Checkbox checked={geoEnabled} size={'small'} onChange={() => ChangeGeoEnabled(!geoEnabled)} />}
                                    />

                                </div>
                                {geoEnabled == true ?
                                    <div style={{ ...styles.Block, marginTop: 50 }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: '600', marginTop: 20 }}>{lang.geo_google_maps}</p>
                                        <div style={{ ...props.isSmall ? styles.Block : styles.BlockRowRaw, marginTop: 15 }}>
                                            <div style={{ ...styles.Block, width: 200 }}>
                                                <TextInput enabled={false} lang={lang} value={lat} label={lang.geo_lat} func={(txt) => setLat(txt)} />
                                            </div>
                                            <div style={{ ...styles.Block, width: 200, marginLeft: props.isSmall ? 0 : 20, marginTop: props.isSmall ? 20 : 0 }}>
                                                <TextInput enabled={false} lang={lang} value={lng} label={lang.geo_lng} func={(txt) => setLng(txt)} />
                                            </div>

                                        </div>

                                        {showMap == true ?
                                            <div style={{ ...styles.Block, marginTop: 20 }}>
                                                <SalonMap width={props.isSmall ? 300 : 400} height={props.isSmall ? 300 : 400} />
                                            </div>
                                            : null}

                                        {showMap == false ?
                                            <div style={{ ...styles.Block, marginTop: 20 }}>
                                                <Button onClick={() => setShowMap(true)} style={{ ...styles.ButtonLink, width: 250 }}>
                                                    <p style={{ ...styles.TextTiny, color: global.theme_black }}>{lang.geo_show_map}</p>
                                                </Button>
                                            </div>
                                            : null}

                                        <div style={{ ...styles.Block, marginTop: 40 }}>
                                            <Button onClick={() => GetGeo()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 250 }}>
                                                <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.geo_get}</p>
                                            </Button>
                                        </div>
                                    </div>
                                    : null}
                            </div>
                            : null}

                    </div>
                </div>
            </div>

            {showSelectPhoto == true ?
                <Photos lang={lang} typ={1} max_size={photoTyp == 0 ? 600 : 1700} width={photoTyp == 0 ? 600 : 1700} height={600} full={false} title={lang.photo_select} sub_title={lang.vision_system} radius={props.radius} offset={props.offset} theme={props.theme} func={PhotosResult.bind(this)} />
                : null}

            {showPhoto == true ?
                <DialogImagePreview theme={props.theme} file={GetImageLink(previewPhoto)} radius={props.radius} lang={lang} title={lang.preview} sub_title={lang.web_page_settings} background={global.theme_dark_gray} crop={false} func={() => setShowPhoto(false)} />
                : null}

            {showOK == true ?
                <ShowOK side={menuWidth} offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError text={props.lang.db_error} func={ShowErrorPress.bind(this)} />
                : null}

            {showTokenError == true ?
                <ShowTokenError remoteApp={props.remoteApp} text={lang.token_error} func={TokenErrorPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading side={menuWidth} offset={props.offset} theme={props.theme}  ></Loading>
                : null}

        </Paper >
    );
}


export const OpeningHours = (props) => {
    /*
            NASTAVENIE OTVARACICH HODIN
    */

    const [hours, setHours] = useState(false);
    const [equal, setEqual] = useState(props.equal);
    const [redraw, setRedraw] = useState(false);

    let { func } = props;
    var lang = props.lang;
    var running = false;
    useEffect(() => {
        if (running == false) {
            if (props.hours == false) {
                let data = [
                    { id: 1, text: '' },
                    { id: 2, text: '' },
                    { id: 3, text: '' },
                    { id: 4, text: '' },
                    { id: 5, text: '' },
                    { id: 6, text: '' },
                    { id: 0, text: '' }
                ];
                setHours(data);
                setEqual(false);
            } else {
                let h = props.hours;
                setHours(h);
                var n = 1;
                var e = false;
                while (n < 5) {
                    if (h[n].text != h[0].text) {
                        e = true
                    }
                    n++;
                }
                setEqual(e);
            }
            running = true;
        }

        return () => {
        };

    }, []);


    const ChangeHours = (id, value) => {
        if (equal == true) {
            hours[id].text = value;
        } else {
            if (id < 5) {
                hours[0].text = value;
                hours[1].text = value;
                hours[2].text = value;
                hours[3].text = value;
                hours[4].text = value;
            } else {
                hours[id].text = value;
            }
        }
        func(hours);
        setRedraw(!redraw);
    }

    const ChangeEqual = (value) => {
        if (value == false) {
            hours[1].text = hours[0].text;
            hours[2].text = hours[0].text;
            hours[3].text = hours[0].text;
            hours[4].text = hours[0].text;
        }
        func(hours);
        setEqual(value);
    }


    return (
        <div style={{ ...styles.BlockLeft, maxWidth: 700, }}>
            <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.settings_calendar_hours_equal}</p>}
                control={<Checkbox checked={equal} size={'small'} onChange={() => ChangeEqual(!equal)} />}
            />
            {hours != false ?
                hours.map((item, index) => (
                    (equal == true) || (equal == false && (item.id == 0 || item.id == 1 || item.id == 6)) ?
                        <div key={item.id} style={{ ...styles.BlockRow, marginTop: 15 }}>
                            <div style={{ ...styles.BlockLeft, width: props.isSmall ? '40%' : '20%' }}>
                                <p style={{ ...styles.TextTiny, fontWeight: '600', marginTop: 25, textAlign: 'left' }}>{item.id == 1 && equal == false ? lang.calendar_days : lang.days[item.id]}</p>
                            </div>
                            <div style={{ ...styles.BlockLeft, width: '60%' }}>
                                <TextInput placeholder={'00:00 - 00:00'} redraw enabled={true} lang={lang} value={hours[index].text} label={lang.time_from + ' - ' + lang.to + ' / ' + lang.hours_order.toLowerCase()} func={(txt) => { ChangeHours(index, txt) }} />
                            </div>
                            {props.isSmall ? null :
                                <div style={{ ...styles.BlockLeft, width: 180, alignItems: 'flex-end' }}>
                                    <Button onClick={() => ChangeHours(index, lang.hours_order)} style={{ ...styles.ButtonDarkSmall, backgroundColor: global.theme_light_gray, marginTop: 25 }} ><p style={{ ...styles.TextTiny, color: global.theme_black }}>{lang.hours_order}</p></Button>
                                </div>
                            }
                        </div>
                        : null
                ))
                : null}

        </div>
    );
}
