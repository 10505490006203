import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { AddDays, CategorySelect, Colors, ConvertToPrice, CopyData, Debug, DialogEnterText, DialogImagePreview, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, FormOK, FormatMoney, GetDate, GetDate3, GetDatum2, GetImageLink, GetMoney, GetPrice, GetTodayYear, GetTopPosition, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, Today, nonEmail } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, CircularProgress, FormControlLabel, IconButton, InputAdornment, MenuItem, Pagination, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faAt, faCheck, faCheckCircle, faComment, faEdit, faEnvelope, faIdCard, faImage, faInfo, faInfoCircle, faK, faL, faLayerGroup, faList, faListAlt, faListCheck, faP, faPaperPlane, faPeace, faPen, faPenAlt, faPlusCircle, faPrint, faQuestion, faQuestionCircle, faS, faSave, faShare, faShoppingBag, faShoppingBasket, faShoppingCart, faTrash, faUser, faWarning, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { ProductSelect } from './product_select.js';
import { ServiceSelect } from './service_select.js';


export const Faktury = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);
    const [money, setMoney] = useState('');

    // PREDAJ - ZOZNAM
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const [filter, setFilter] = useState(false);
    const itemsMax = 24;

    // NAHLAD
    const [itemSelected, setItemSelected] = useState(false);
    const [showOrder, setShowOrder] = useState(false);
    const [myOrders, setMyOrders] = useState(false);

    // AKTUALNY ROK - EXPORT
    const [actualYear, setActualYear] = useState(GetTodayYear());

    const [showOK, setShowOK] = useState(false);
    const [sended, setSended] = useState(false);

    const menuWidth = props.isSmall ? 0 : 250;
    const rowHeight = props.isSmall ? 120 : 80;
    const rowWidth = 850;

    const no_image = require('./react/app/no_image.jpg');

    // Aktuálna farebna téma
    const color = global.themes[props.theme];

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            let year = new Date().getFullYear();
            db_get(filter, year);
        }

    }, []);

    const db_get = async (filter, year) => {
        setBusy(true);
        setItems(false);
        setItemsFiltered(false);
        try {
            const response = await fetch(
                global.db_url + 'faktury', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    language: props.language,
                    year: year
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;

                if (tmp != false) {
                    setItems(tmp);
                    setItemsFiltered(tmp);
                    //PageInit(tmp);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }
                setActualYear(year);

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const Search = (text) => {
        setSearch(text);

        let tmp = items.filter(x => x.name.toLowerCase().includes(text.toLowerCase()) || x.surname.toLowerCase().includes(text.toLowerCase()) || x.number.toLowerCase().includes(text.toLowerCase()) || x.town.toLowerCase().includes(text.toLowerCase()))

        if (tmp.length == 0) {
            setItemsFiltered(false);
        } else {
            setItemsFiltered(tmp);
        }
    }


    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_orders');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const Edit = (item) => {
        setItemSelected(item);
        setShowOrder(true);
    }

    const FakturaResult = (redraw) => {
        setShowOrder(false);
        if (redraw == true) {
            db_get(filter, actualYear);
        }
    }

    const ChangeYear = (year, month) => {
        setActualYear(year)
        db_get(filter, year);
    }


    const NewFaktura = () => {
        setItemSelected(false);
        setShowOrder(true);
    }

    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? '45%' : '58%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.isSmall ? lang.reklamacie : props.title}</p>
                        {props.isSmall ? null :
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        }
                    </div>
                </div>
                <div style={{ ...styles.BlockRow, justifyContent: 'flex-end', alignItems: 'center', width: props.isSmall ? 160 : '30%', height: global.dialog_bar_height }}>
                    <TextField
                        value={search}
                        onInput={e => Search(e.target.value)}
                        size="small"
                        autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off"
                        style={{ width: props.isSmall ? 150 : 300 }}
                        placeholder={lang.search_in_category}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                            }
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                    <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                </IconButton>
                            </InputAdornment>,
                            style: { backgroundColor: global.theme_white, borderRadius: 30 },
                        }}
                        variant="outlined"
                    />
                </div>
                <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                    <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockCenter, width: props.width, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                            <div id={'id_orders'} style={{ ...styles.BlockCenter, width: '98%' }}>

                                <div style={{ ...styles.BlockRight, maxWidth: rowWidth }}>
                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.BlockLeft, width: '60%', height: 60, justifyContent: 'center' }}>
                                            <Button onClick={() => NewFaktura()} style={{ ...styles.ButtonDark, backgroundColor: color.button_dark, width: 180 }}>{lang.faktura_new}</Button>
                                        </div>
                                        {props.isSmall ? null :
                                            <div style={{ ...styles.BlockRight, width: 250, height: 60, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.faktury_list_year}</p>
                                            </div>
                                        }
                                        <div style={{ ...styles.BlockRight, width: 180, height: 60, justifyContent: 'center' }}>
                                            <SelectYear min={2020} value={actualYear} func={ChangeYear.bind(this)} />
                                        </div>
                                    </div>
                                </div>

                                {/* *********************************************************************************************************  
                                            ZOZNAM FAKTUR
                                    ********************************************************************************************************* */}

                                {itemsFiltered != false ?
                                    itemsFiltered.map((item, index) => (
                                        <Paper elevation={overID == item.id ? 5 : 2} key={item.id} style={{ ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: global.theme_gray, marginTop: 8, marginBottom: 8, borderRadius: global.row_radius, cursor: 'pointer' }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow }}>
                                                {props.isSmall ?
                                                    <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, minHeight: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                        <p style={{ ...styles.TextTiny, fontWeight: '400', textAlign: 'left' }}>{GetDatum2(item.date)}</p>
                                                        <p style={{ ...styles.TextSmall, fontWeight: '400', textAlign: 'left', marginTop: 2 }}>{lang.faktura}: <b>{item.number}</b></p>

                                                        <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                            <div style={{ ...styles.BlockLeft, width: '60%', justifyContent: 'center' }}>
                                                                <p style={{ ...styles.TextXSmall, fontWeight: '400', textAlign: 'left' }}>{item.name} {item.surname}</p>
                                                                <p style={{ ...styles.TextXSmall, fontWeight: '400', textAlign: 'left' }}>{item.town}</p>
                                                            </div>
                                                            <div style={{ ...styles.BlockRight, width: '40%', justifyContent: 'center' }}>
                                                                <p style={{ ...styles.TextXSmall, fontWeight: '400', textAlign: 'left' }}>{GetPrice((item.sum))} {lang.money}</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    :
                                                    <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: '96%', justifyContent: 'center', marginLeft: 10 }}>
                                                        <div style={{ ...styles.BlockRow }}>
                                                            <div style={{ ...styles.BlockLeft, width: '40%', height: rowHeight, justifyContent: 'center' }}>
                                                                <p style={{ ...styles.TextTiny, fontWeight: '400', textAlign: 'left' }}>{GetDatum2(item.date)}</p>
                                                                <p style={{ ...styles.TextSmall, fontWeight: '400', textAlign: 'left', marginTop: 2 }}>{lang.faktura}: <b>{item.number}</b></p>
                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: '40%', height: rowHeight, justifyContent: 'center' }}>
                                                                <p style={{ ...styles.TextXSmall, fontWeight: '400', textAlign: 'left' }}>{item.name} {item.surname}</p>
                                                                <p style={{ ...styles.TextXSmall, fontWeight: '400', textAlign: 'left' }}>{item.town}</p>
                                                            </div>
                                                            <div style={{ ...styles.BlockRight, width: 180, height: rowHeight, justifyContent: 'center', borderLeft: '1px solid #D0D0D0' }}>
                                                                <p style={{ ...styles.TextXSmall, fontWeight: '400', textAlign: 'left' }}>{GetPrice((item.sum))} {lang.money}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </Paper>
                                    )) :
                                    // ZIADNE OBJEDNAVKY                            
                                    <div style={{ ...styles.Block, paddingTop: 20 }}>
                                        <EmptyList lang={lang} />
                                    </div>
                                }

                                <div style={{ ...styles.Block, height: 100 }}></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {showOrder == true ?
                <Faktura faktura={itemSelected} isSmall={props.isSmall} offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} remoteApp={props.remoteApp} admin={props.admin} countries={props.countries} country={props.country} lang={lang} radius={props.radius} backcolor={true} background={true} language={props.language} func={FakturaResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK side={menuWidth} offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading side={menuWidth} offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Paper >
    );
}


export const Faktura = (props) => {


    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    const [faktura, setFaktura] = useState(props.faktura == false ? false : props.faktura);
    const [fakturaItems, setFakturaItems] = useState(false);
    const [selectedItem, setSelectedItem] = useState(false);
    const [showItem, setShowItem] = useState(false);
    const [pdfLink, setPdfLink] = useState(false);
    const [email, setEmail] = useState('');

    const [showFakturaData, setShowFakturaData] = useState(false);
    const [fakturaDays, setFakturaDays] = useState(global.faktura_days);

    const [showDelete, setShowDelete] = useState(false);
    const [showEnterEmail, setShowEnterEmail] = useState(false);
    const [showEmailError, setShowEmailError] = useState(false);

    // Rozmery okna
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1200 ? 1200 : width;
    const dialogHeight = height >= 850 ? 850 : height;

    const buttonHeight = 80;
    const labelHeight = props.isSmall ? 80 : 140;

    const no_image = require('./react/app/no_image.jpg');


    let { func } = props;
    let offset = 10;
    var lang = props.lang;
    const color = global.themes[props.theme];
    var running = false;
    const inputRef = useRef(null);

    useEffect(() => {
        if (running == false) {
            running = true;

            if (props.faktura == false) {
                setShowFakturaData(true);
            } else {
                db_get(props.faktura.id);
            }

        }
        return () => {
        };

    }, []);

    const db_get = async (faktura_id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'faktura', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language,
                    faktura_id: faktura_id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setFaktura(json.item);
                setFakturaItems(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'faktura_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setRedraw(true);
                if (data.enabled == false) {
                    func(true);
                } else {
                    if (faktura == false) {
                        db_get(json.ok);
                    } else {
                        db_get(faktura.id);
                    }
                }
            }


        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_items_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'faktura_items_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setRedraw(true);
                db_get(faktura.id);
            }


        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_print = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'faktura_print', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    faktura_id: faktura.id,
                })
            })

            const json = await response.json();
            Debug(json);

            setBusy(false);

            if (json.ok > 0) {
                if (json.filename != false) {
                    setPdfLink(json.filename);
                    setTimeout(() => {
                        inputRef.current.click();
                    }, 300);
                } else {
                    setShowError(true);
                }
            } else {
                setShowError(true);
            }


        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_send = async (text) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'faktura_send', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    faktura_id: faktura.id,
                    email: text
                })
            })

            const json = await response.json();
            Debug(json);

            setBusy(false);

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }


        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = () => {
        func(redraw);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }


    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const ItemAdd = () => {
        setSelectedItem(false);
        setShowItem(true);
    }

    const FakturaResult = (typ, data) => {
        setShowFakturaData(false);

        if (typ == true) {
            data.id = faktura == false ? 0 : faktura.id;

            if (faktura == false) {
                // NOVA FAKTURA - UDAJE
                data.uid = '';
                data.salon_id = props.user.id;
                data.eshop_id = 0;
                data.order_id = 0;
                data.enabled = true;
                data.language = props.language;
                data.number = '';
                data.ico = '';
                data.dic = '';
                data.ic_dph = '';
                data.sum = 0;
                data.days = fakturaDays;
                data.kon_symbol = '';
                data.uhradena = false;
            }

            Debug(data);
            db_update(data);
        } else {
            if (faktura == false) {
                Press(false);
            }
        }
    }

    const EditItem = (item) => {
        setSelectedItem(item);
        setShowItem(true);
    }

    const ItemResult = (typ, label_, note_, quantity_, price_) => {
        setShowItem(false);
        let price = ConvertToPrice(price_);

        if (typ == true) {
            let data = {
                id: selectedItem == false ? 0 : selectedItem.id,
                salon_id: props.user.id,
                faktura_id: faktura.id,
                line: 0,
                label: label_,
                note: note_,
                quantity: quantity_,
                price: price,
                sum: quantity_ * price
            }

            db_items_update(data);
        }
    }

    const FakturaDelete = (value) => {
        setShowDelete(false);

        if (value == true) {
            let data = {
                id: faktura.id,
                salon_id: 0,
                enabled: false
            }

            db_update(data);
        }
    }

    const Print = () => {
        db_print();
    }

    const EnterEmailResult = (value, text) => {
        setShowEnterEmail(false);

        if (value == true) {
            if (nonEmail(text) == false) {
                db_send(text);
            } else {
                setTimeout(() => {
                    setShowEmailError(true);
                }, 200);
            }
        }
    }

    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: labelHeight, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.BlockCenter, width: 100, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                    {props.isSmall ?
                        <div style={{ ...styles.BlockLeft, width: '85%', height: labelHeight, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{lang.faktura}</p>
                            {faktura != false ?
                                <p style={{ ...styles.TextDialogSubLabel, textAlign: 'left' }}>{faktura.number}</p>
                                : null}
                        </div>
                        :
                        <div style={{ ...styles.BlockLeft, width: '30%', height: labelHeight, justifyContent: 'center' }}>
                            {faktura != false ?
                                <div onClick={() => setShowFakturaData(true)} style={{ ...styles.BlockLeft, height: labelHeight, cursor: 'pointer' }}>
                                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                                        <p style={{ ...styles.TextSmall }}>{lang.faktura_cislo}: <b>{faktura.number}</b></p>
                                        <p style={{ ...styles.TextTiny, marginTop: 0 }}>{lang.faktura_var_symbol}: {faktura.var_symbol}</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, height: labelHeight - global.dialog_bar_height, justifyContent: 'flex-start' }}>
                                        <p style={{ ...styles.TextTiny, marginTop: 0 }}>{lang.faktura_date_}: {GetDatum2(faktura.date)}</p>
                                        <p style={{ ...styles.TextTiny, marginTop: 0 }}>{lang.faktura_date_to_pay}: {GetDatum2(faktura.date_to_pay)}</p>
                                    </div>
                                </div>
                                : null}
                        </div>
                    }
                    {props.isSmall ? null :
                        <div style={{ ...styles.BlockLeft, width: '35%', height: labelHeight, justifyContent: 'center' }}>
                            {faktura != false ?
                                <div onClick={() => setShowFakturaData(true)} style={{ ...styles.BlockLeft, cursor: 'pointer' }}>
                                    <p style={{ ...styles.TextXSmall, fontWeight: '600', marginBottom: 8 }}>{lang.faktura_odberatel}:</p>
                                    <p style={{ ...styles.TextTiny }}>{faktura.company}</p>
                                    <p style={{ ...styles.TextTiny }}>{faktura.name} {faktura.surname}</p>
                                    <p style={{ ...styles.TextTiny }}>{faktura.street} {faktura.street_number}</p>
                                    <p style={{ ...styles.TextTiny }}>{faktura.psc} {faktura.town}</p>
                                    <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.email_}: {faktura.email}</p>
                                </div>
                                : null}
                        </div>
                    }
                    {props.isSmall ? null :
                        <div style={{ ...styles.BlockLeft, width: 150, height: labelHeight, justifyContent: 'center' }}>
                            <div style={{ ...styles.BlockLeft, width: 150, height: labelHeight - 36, justifyContent: 'center', backgroundColor: global.theme_white, borderRadius: 20 }}>
                                {faktura != false ?
                                    <div style={{ ...styles.Block }}>
                                        <p style={{ ...styles.TextTiny }}>{lang.faktura_sum_to_pay}</p>
                                        <p style={{ ...styles.TextTiny }}>{lang.with_dph}</p>
                                        <p style={{ ...styles.TextSmall, fontWeight: '600', marginTop: 10 }}>{GetPrice((faktura.sum))} {lang.money}</p>
                                    </div>
                                    : null}
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.BlockRight, width: 100, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <IconButton onClick={() => setShowFakturaData(true)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 14, color: global.theme_black }} icon={faPen} />
                        </IconButton>
                    </div>
                    <div style={{ ...styles.BlockCenter, width: 100, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - labelHeight, backgroundColor: props.background == true ? global.theme_back : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '100%', height: dialogHeight - labelHeight - buttonHeight }}>
                        {/* BODY */}
                        <div style={{ ...styles.BlockCenter, width: '100%', height: dialogHeight - labelHeight - buttonHeight, paddingBottom: 10, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                            {props.isSmall ?
                                <div style={{ ...styles.BlockLeft, width: '96%' }}>
                                    {faktura != false ?
                                        <div onClick={() => setShowFakturaData(true)} style={{ ...styles.BlockLeft, cursor: 'pointer', marginTop: 5, marginBottom: 10 }}>
                                            <p style={{ ...styles.TextTiny, marginTop: 0 }}>{lang.faktura_var_symbol}: {faktura.var_symbol}</p>
                                            <p style={{ ...styles.TextTiny, marginTop: 0 }}>{lang.faktura_date_}: {GetDatum2(faktura.date)}</p>
                                            <p style={{ ...styles.TextTiny, marginTop: 0 }}>{lang.faktura_date_to_pay}: {GetDatum2(faktura.date_to_pay)}</p>

                                            <p style={{ ...styles.TextXSmall, fontWeight: '600', marginBottom: 8, marginTop: 10 }}>{lang.faktura_odberatel}:</p>
                                            <p style={{ ...styles.TextTiny }}>{faktura.company}</p>
                                            <p style={{ ...styles.TextTiny }}>{faktura.name} {faktura.surname}</p>
                                            <p style={{ ...styles.TextTiny }}>{faktura.street} {faktura.street_number}</p>
                                            <p style={{ ...styles.TextTiny }}>{faktura.psc} {faktura.town}</p>
                                            <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.email_}: {faktura.email}</p>
                                        </div>
                                        : null}
                                </div>
                                : null}

                            <div style={{ ...styles.BlockLeft, width: '96%', height: 40, justifyContent: 'center' }}>
                                <p style={{ ...styles.TextXSmall, fontWeight: props.isSmall == true ? '600' : '400' }}>{lang.faktura_items}</p>
                            </div>

                            {props.isSmall == true ?
                                <div style={{ ...styles.BlockRow, width: '96%', marginTop: 5, paddingTop: 5, paddingBottom: 5, backgroundColor: global.theme_white }}>
                                    <div style={{ ...styles.Block, width: 50 }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.faktura_numbering}</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '90%' }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.faktura_item_name}</p>
                                    </div>
                                </div>
                                :
                                <div style={{ ...styles.BlockRow, width: '96%', marginTop: 5, paddingTop: 5, paddingBottom: 5, backgroundColor: global.theme_white }}>
                                    <div style={{ ...styles.Block, width: 50 }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.faktura_numbering}</p>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '60%' }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.faktura_item_name}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: '10%' }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.quantity_}</p>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '15%' }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.faktura_item_price} {lang.with_dph}</p>
                                    </div>
                                    <div style={{ ...styles.BlockRight, width: '15%' }}>
                                        <p style={{ ...styles.TextTiny, marginRight: 10, color: global.theme_dark_gray }}>{lang.faktura_item_sum}</p>
                                    </div>
                                </div>
                            }

                            {fakturaItems != false ? fakturaItems.map((item, index) => (
                                props.isSmall == true ?
                                    <div onClick={() => EditItem(item)} key={item.id} style={{ ...styles.Block, width: '96%', borderTop: '1px solid #D0D0D0', paddingTop: offset, paddingBottom: offset, cursor: 'pointer', backgroundColor: item.quantity > 0 ? undefined : global.theme_light_gray }}>
                                        <div style={{ ...styles.BlockRow }}>

                                            <div style={{ ...styles.Block, width: 50 }}>
                                                <p style={{ ...styles.TextXSmall, textAlign: 'left', color: item.quantity > 0 ? global.theme_black : global.theme_medium_gray }}>{index + 1}.</p>
                                            </div>
                                            {item.quantity > 0 ?
                                                <div style={{ ...styles.BlockLeft, width: '90%' }}>
                                                    <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: '600', color: item.quantity > 0 ? global.theme_black : global.theme_medium_gray }}>{item.label}</p>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'left', color: item.quantity > 0 ? global.theme_black : global.theme_medium_gray }}>{item.note}</p>
                                                </div>
                                                :
                                                <div style={{ ...styles.BlockLeft, width: '90%' }}>
                                                    <p style={{ ...styles.TextXSmall, fontWeight: '600', color: '#A0A0A0' }}>{lang.faktura_item_deleted}</p>
                                                </div>
                                            }
                                        </div>
                                        {item.quantity > 0 ?
                                            <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                <div style={{ ...styles.BlockLeft, width: 50 }}></div>
                                                <div style={{ ...styles.Block, width: '20%' }}>
                                                    <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.quantity_}</p>
                                                    <p style={{ ...styles.TextXSmall, color: item.quantity > 0 ? global.theme_black : global.theme_medium_gray }}>{item.quantity} x</p>
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: '35%' }}>
                                                    <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.faktura_item_price}</p>
                                                    <p style={{ ...styles.TextXSmall, color: item.quantity > 0 ? global.theme_black : global.theme_medium_gray }}>{GetPrice((item.price))} {lang.money}</p>
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: '35%' }}>
                                                    <p style={{ ...styles.TextTiny, marginRight: 10, color: global.theme_dark_gray }}>{lang.faktura_item_sum}</p>
                                                    <p style={{ ...styles.TextXSmall, marginRight: 10, color: item.quantity > 0 ? global.theme_black : global.theme_medium_gray }}>{GetPrice((item.sum))} {lang.money}</p>
                                                </div>
                                            </div>
                                            : null}
                                    </div>
                                    :
                                    <div onClick={() => EditItem(item)} key={item.id} style={{ ...styles.BlockRow, width: '96%', borderTop: '1px solid #D0D0D0', paddingTop: offset, paddingBottom: offset, cursor: 'pointer', backgroundColor: item.quantity > 0 ? undefined : global.theme_light_gray }}>
                                        <div style={{ ...styles.Block, width: 50 }}>
                                            <p style={{ ...styles.TextXSmall, textAlign: 'left', color: item.quantity > 0 ? global.theme_black : global.theme_medium_gray }}>{index + 1}.</p>
                                        </div>
                                        {item.quantity > 0 ?
                                            <div style={{ ...styles.BlockLeft, width: '60%' }}>
                                                <p style={{ ...styles.TextSmall, textAlign: 'left', fontWeight: '600', color: item.quantity > 0 ? global.theme_black : global.theme_medium_gray }}>{item.label}</p>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', color: item.quantity > 0 ? global.theme_black : global.theme_medium_gray }}>{item.note}</p>
                                            </div>
                                            :
                                            <div style={{ ...styles.BlockLeft, width: '60%' }}>
                                                <p style={{ ...styles.TextXSmall, fontWeight: '600', color: '#A0A0A0' }}>{lang.faktura_item_deleted}</p>
                                            </div>
                                        }
                                        <div style={{ ...styles.Block, width: '10%' }}>
                                            {item.quantity > 0 ?
                                                <p style={{ ...styles.TextXSmall, color: item.quantity > 0 ? global.theme_black : global.theme_medium_gray }}>{item.quantity} x</p>
                                                : null}
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: '15%' }}>
                                            {item.quantity > 0 ?
                                                <p style={{ ...styles.TextXSmall, color: item.quantity > 0 ? global.theme_black : global.theme_medium_gray }}>{GetPrice((item.price))} {lang.money}</p>
                                                : null}
                                        </div>
                                        <div style={{ ...styles.BlockRight, width: '15%' }}>
                                            {item.quantity > 0 ?
                                                <p style={{ ...styles.TextXSmall, marginRight: 10, color: item.quantity > 0 ? global.theme_black : global.theme_medium_gray }}>{GetPrice((item.sum))} {lang.money}</p>
                                                : null}
                                        </div>
                                    </div>
                            )) : null}

                            {faktura != false ? faktura.note != '' ?
                                <div onClick={() => setShowFakturaData(true)} style={{ ...styles.BlockLeft, width: '96%', borderTop: '1px solid #D0D0D0', paddingTop: offset, paddingBottom: offset, cursor: 'pointer' }}>
                                    <p style={{ ...styles.TextXSmall, textAlign: 'left', fontWeight: '600' }}>{lang.note}:</p>
                                    <p style={{ ...styles.TextXSmall, textAlign: 'left' }}>{faktura.note}</p>
                                </div>
                                : null : null}

                            {faktura != false ?
                                <div style={{ ...styles.Block, width: '96%', borderTop: '1px solid #D0D0D0', paddingTop: 30, paddingBottom: 30 }}>
                                    <Button onClick={() => ItemAdd()} style={{ ...styles.ButtonDark, width: 180, backgroundColor: color.theme_medium }}>
                                        <FontAwesomeIcon style={{ height: 18, color: global.theme_white, marginRight: 10 }} icon={faPlusCircle} />
                                        {lang.faktura_item_add}
                                    </Button>
                                </div>
                                : null}

                        </div>
                    </div>

                    <div style={{ ...styles.Block, width: '100%', height: buttonHeight, backgroundColor: global.themes[props.theme].theme_lighter, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                        {faktura != false ?
                            <div style={{ ...styles.BlockRowRaw }}>
                                <Button onClick={() => setShowEnterEmail(true)} style={{ ...styles.ButtonDark, width: props.isSmall ? 115 : 180, backgroundColor: color.button_dark }}>
                                    {props.isSmall == true ? null :
                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faShare} />
                                    }
                                    {lang.send}
                                </Button>
                                <a ref={inputRef} href={global.pdf_exported + pdfLink} style={{ textDecoration: 'none' }} target='_blank'></a>
                                <Button onClick={() => Print()} style={{ ...styles.ButtonDark, width: props.isSmall ? 115 : 180, backgroundColor: color.button_dark, marginLeft: props.isSmall ? 10 : 20 }}>
                                    {props.isSmall == true ? null :
                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPrint} />
                                    }
                                    {lang.print}
                                </Button>
                                <Button onClick={() => setShowDelete(true)} style={{ ...styles.ButtonDark, width: props.isSmall ? 115 : 180, backgroundColor: global.theme_dark_red, marginLeft: props.isSmall ? 10 : 20 }}>
                                    {props.isSmall ? lang.delete : lang.faktura_delete}
                                </Button>
                            </div>
                            : null}
                    </div>
                </div>
            </Paper>

            {showEnterEmail == true ?
                <DialogEnterText button1={lang.email_of_client} button1_text={faktura.email} button2={lang.email_mine} button2_text={props.user.email} theme={props.theme} rows={1} radius={props.radius} title={lang.email} sub_title={lang.faktura_send} text={email} icon={faEnvelope} button={props.lang.send} func={EnterEmailResult.bind(this)} />
                : null}

            {showEmailError == true ?
                <DialogInfo theme={props.theme} radius={props.radius} title={lang.format_error} sub_title={''} text={lang.email_wrong_format} icon={faEnvelope} button={props.lang.close} icon_show={faXmarkCircle} icon_color={global.theme_dark_red} func={() => setShowEmailError(false)} />
                : null}

            {showItem == true ?
                <FakturaItem item={selectedItem} isSmall={props.isSmall} offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} remoteApp={props.remoteApp} admin={props.admin} employees={props.employees} countries={props.countries} country={props.country} lang={lang} radius={props.radius} backcolor={true} background={true} language={props.language} func={ItemResult.bind(this)} />
                : null}

            {showFakturaData == true ?
                <FakturaData faktura={faktura} isSmall={props.isSmall} offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} remoteApp={props.remoteApp} admin={props.admin} countries={props.countries} country={props.country} lang={lang} radius={props.radius} backcolor={true} background={true} language={props.language} func={FakturaResult.bind(this)} />
                : null}

            {showDelete == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.faktura_delete} sub_title={''} text={lang.faktura_delete_ask} icon={faQuestion} yes={lang.yes} no={lang.no} yes_color={global.theme_dark_red} no_color={color.button_dark} func={FakturaDelete.bind(this)} />
                : null}

            {showError == true ?
                <ShowError text={lang.faktura_error} offset={props.offset} theme={props.theme} func={ShowErrorPress.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop >
    );
}


export const FakturaItem = (props) => {

    const [label, setLabel] = useState('');
    const [note, setNote] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState('0');

    const [showProduct, setShowProduct] = useState(false);
    const [showService, setShowService] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 600 : width;
    const dialogHeight = height >= 500 ? 500 : height;

    let { func } = props;
    let lang = props.lang;
    const inputRef = useRef(null);
    const color = global.themes[props.theme];
    var running = false;

    useEffect(() => {

        if (props.item != false) {
            setLabel(props.item.label);
            setNote(props.item.note);
            setQuantity(props.item.quantity)
            setPrice(props.item.price);
        }
        // FOCUS TO TEXTAREA
        setTimeout(() => {
            inputRef.current.focus();
        }, 300);


        return () => {
        };

    }, []);

    const PlusMinusResult = (value) => {
        setQuantity(value);
    }

    const Press = (value) => {
        func(value, label, note, quantity, price);
    }

    const ProductSelectResult = (typ, item) => {
        setShowProduct(false);

        if (typ == true) {
            setLabel(item.label);
            setNote(item.code + ' - ' + item.details);
            setPrice(item.eur);
        }
    }

    const ServiceSelectResult = (typ, item) => {
        setShowService(false);
        if (typ == true) {
            setLabel(item.label);
            setNote(lang.service);
            setPrice(item.eur);
        }
    }


    return (
        <Backdrop open={true} style={{ position: 'fixed', top: GetTopPosition(height, dialogHeight), paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.item == false ? lang.faktura_item_new : lang.faktura_item_edit}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.faktura}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    <div style={{ ...styles.BlockCenter, width: '96%', paddingBottom: 200 }}>
                        <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                            <div style={{ ...styles.Block, width: '78%' }}>
                                <TextInput inputRef={inputRef} required redraw enabled={true} lang={lang} value={label} label={lang.faktura_item_name} func={(txt) => setLabel(txt)} />
                            </div>
                            <div style={{ ...styles.BlockRowRaw, width: 140 }}>
                                <div style={{ ...styles.Block, width: 70, paddingTop: 0 }}>
                                    <p style={{ ...props.isSmall ? styles.TextXTiny : styles.TextInput, color: global.theme_dark_gray }}>{lang.product}</p>
                                    <IconButton onClick={() => setShowProduct(true)} style={{ width: 36, height: 36, backgroundColor: color.theme_light }}>
                                        <FontAwesomeIcon style={{ height: 14, color: global.theme_black }} icon={faP} />
                                    </IconButton>
                                </div>
                                <div style={{ ...styles.Block, width: 70, paddingTop: 0 }}>
                                    <p style={{ ...props.isSmall ? styles.TextXTiny : styles.TextInput, color: global.theme_dark_gray }}>{lang.service}</p>
                                    <IconButton onClick={() => setShowService(true)} style={{ width: 36, height: 36, backgroundColor: color.theme_light }}>
                                        <FontAwesomeIcon style={{ height: 14, color: global.theme_black }} icon={faS} />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                        <div style={{ ...styles.Block, marginTop: 15 }}>
                            <TextInput redraw enabled={true} lang={lang} value={note} label={lang.note} func={(txt) => setNote(txt)} />
                        </div>
                        <div style={{ ...styles.BlockRowRaw, marginTop: 35 }}>
                            <div style={{ ...styles.Block, width: 120 }}>
                                <p style={{ ...props.isSmall ? styles.TextXTiny : styles.TextInput, color: global.theme_dark_gray, marginLeft: 12, textAlign: 'left' }}>{lang.quantity}</p>
                                <PlusMinus min={0} value={quantity} border no_wait func={PlusMinusResult.bind(this)} />
                            </div>
                            <div style={{ ...styles.Block, width: 150, marginLeft: 40 }}>
                                <TextInput required redraw money={true} money_text={lang.money} enabled={true} lang={lang} value={price} label={lang.faktura_item_price + ' ' + lang.with_dph} func={(txt) => setPrice(txt)} />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: 100 }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        {label.trim() != '' && price.trim() != '' ?
                            <Button onClick={() => Press(true)} style={{ ...styles.ButtonDark, backgroundColor: color.button_dark }}>{lang.save}</Button>
                            : null}
                        <Button onClick={() => Press(false)} style={{ ...styles.ButtonDark, backgroundColor: color.button_dark, marginLeft: 20 }}>{lang.close}</Button>
                    </div>
                </div>
            </Paper>

            {showProduct == true ?
                <ProductSelect employee_id={props.employee_id} isSmall={props.isSmall} employees={props.employees} user={props.user} lang={lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} func={ProductSelectResult.bind(this)} />
                : null}

            {showService == true ?
                <ServiceSelect employees={props.employees} isSmall={props.isSmall} user={props.user} lang={props.lang} offset={props.offset} theme={props.theme} radius={props.radius} language={props.language} func={ServiceSelectResult.bind(this)} />
                : null}

        </Backdrop>
    );
}


export const FakturaData = (props) => {

    const [number, setNumber] = useState('');
    const [dateToPay, setDateToPay] = useState(GetDate3(AddDays(Today(), global.faktura_days)));
    const [date, setDate] = useState(Today());
    const [text, setText] = useState('');
    const [varSymbol, setVarSymbol] = useState('');
    const [note, setNote] = useState('');

    const [company, setCompany] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [street, setStreet] = useState('');
    const [streetNumber, setStreetNumber] = useState('');
    const [psc, setPsc] = useState('');
    const [town, setTown] = useState('');
    const [email, setEmail] = useState('');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 400 ? 400 : width;
    const dialogHeight = height >= 700 ? 700 : height;

    let { func } = props;
    let lang = props.lang;
    const inputRef = useRef(null);
    const color = global.themes[props.theme];
    var running = false;
    let offset = 10;

    useEffect(() => {

        if (props.faktura != false) {
            let faktura = props.faktura;
            Debug(faktura);
            setNumber(faktura.number);
            setDate(GetDate3(faktura.date));
            setDateToPay(GetDate3(faktura.date_to_pay));
            setVarSymbol(faktura.var_symbol);

            setName(faktura.name);
            setSurname(faktura.surname);
            setStreet(faktura.street);
            setStreetNumber(faktura.street_number);
            setPsc(faktura.psc);
            setTown(faktura.town);
            setEmail(faktura.email);

            setNote(faktura.note);
        }

        return () => {
        };

    }, []);

    const ChangeDate = (txt) => {
        setDate(txt);
        setDateToPay(GetDate3(AddDays(txt, global.faktura_days)))
        Debug(txt);
    }

    const Press = (value) => {
        var data = {};
        if (value == true) {
            data = {
                number: number,
                date: date,
                date_to_pay: dateToPay,
                var_symbol: varSymbol,
                company: '',
                name: name,
                surname: surname,
                street: street,
                street_number: streetNumber,
                psc: psc,
                town: town,
                email: email,
                note: note,
                text: text
            };
        }
        func(value, data);
    }

    return (
        <Backdrop open={true} style={{ position: 'fixed', top: GetTopPosition(height, dialogHeight), paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.faktura == false ? lang.faktura_new : lang.faktura_data}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    <div style={{ ...styles.BlockCenter, width: '96%' }}>

                        {props.faktura != false ?
                            <div style={{ ...styles.BlockRow, marginTop: offset * 2 }}>
                                <div style={{ ...styles.Block, width: '49%' }}>
                                    <TextInput required redraw enabled={true} lang={lang} value={number} label={lang.faktura_cislo} func={(txt) => setNumber(txt)} />
                                </div>
                                <div style={{ ...styles.Block, width: '49%', marginLeft: 10 }}>
                                    <TextInput redraw enabled={true} lang={lang} value={varSymbol} label={lang.faktura_var_symbol} func={(txt) => setVarSymbol(txt)} />
                                </div>
                            </div>
                            : null}

                        <div style={{ ...styles.BlockRow, marginTop: offset }}>
                            <div style={{ ...styles.BlockRow, marginTop: offset * 2 }}>
                                <div style={{ ...styles.Block, width: '49%' }}>
                                    <TextInput date required redraw enabled={true} lang={lang} value={date} label={lang.faktura_date_} func={(txt) => ChangeDate(txt)} />
                                </div>
                                <div style={{ ...styles.Block, width: '49%', marginLeft: 10 }}>
                                    <TextInput date redraw enabled={true} lang={lang} value={dateToPay} label={lang.faktura_date_to_pay} func={(txt) => setDateToPay(txt)} />
                                </div>
                            </div>
                        </div>

                        <div style={{ ...styles.Block, marginTop: offset * 3, paddingBottom: 300 }}>
                            <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.faktura_odberatel}</p>
                            <TextInput required redraw enabled={true} lang={lang} value={name} label={lang.name} func={(txt) => setName(txt)} />
                            <div style={{ ...styles.Block, marginTop: offset }}></div>
                            <TextInput required redraw enabled={true} lang={lang} value={surname} label={lang.surname} func={(txt) => setSurname(txt)} />

                            <div style={{ ...styles.BlockRow, marginTop: offset }}>
                                <div style={{ ...styles.Block, width: '65%' }}>
                                    <TextInput redraw enabled={true} lang={lang} value={street} label={lang.street} func={(txt) => setStreet(txt)} />
                                </div>
                                <div style={{ ...styles.Block, width: '32%', marginLeft: 10 }}>
                                    <TextInput redraw enabled={true} lang={lang} value={streetNumber} label={lang.street_number} func={(txt) => setStreetNumber(txt)} />
                                </div>
                            </div>

                            <div style={{ ...styles.BlockRow, marginTop: offset }}>
                                <div style={{ ...styles.Block, width: '32%' }}>
                                    <TextInput redraw enabled={true} lang={lang} value={psc} label={lang.psc} func={(txt) => setPsc(txt)} />
                                </div>
                                <div style={{ ...styles.Block, width: '65%', marginLeft: 10 }}>
                                    <TextInput redraw enabled={true} lang={lang} value={town} label={lang.town} func={(txt) => setTown(txt)} />
                                </div>
                            </div>

                            <div style={{ ...styles.Block, marginTop: offset * 2 }}>
                                <TextInput redraw enabled={true} lang={lang} value={email} label={lang.email} func={(txt) => setEmail(txt)} />
                            </div>

                            <div style={{ ...styles.Block, marginTop: offset * 2 }}>
                                <TextInput redraw enabled={true} rows={3} lang={lang} value={note} label={lang.note} func={(txt) => setNote(txt)} />
                            </div>

                        </div>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: 100 }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        {name.trim() != '' && surname.trim() != '' ?
                            <Button onClick={() => Press(true)} style={{ ...styles.ButtonDark, backgroundColor: color.button_dark }}>{lang.save}</Button>
                            : null}
                        <Button onClick={() => Press(false)} style={{ ...styles.ButtonDark, backgroundColor: color.button_dark, marginLeft: 20 }}>{lang.close}</Button>
                    </div>
                </div>
            </Paper>
        </Backdrop>
    );
}

