import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, ConvertToPrice, CopyData, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, GetDate, GetImageLink, GetPrice, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, ShowError, ShowOK, TemplateWindow, Today } from './items';
import { styles } from './styles';
import { Backdrop, Button, CircularProgress, IconButton, InputAdornment, Pagination, Paper, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCheckCircle, faEdit, faL, faLayerGroup, faList, faListAlt, faQuestion, faSave, faShoppingBag, faShoppingBasket, faTrash, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Graph } from './graph';

export const StatsInfo = (props) => {
    const [isBusy, setBusy] = useState(false);

    const [data, setData] = useState(false);
    const [graphData, setGraphData] = useState(false);

    const menuWidth = 300;

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const no_image = require('./react/app/no_image.jpg');

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            db_get();
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stats_info', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    language: props.language,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setData(json);
                setGraphData(json.graph_data);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = () => {
        func(false);
    }

    const GraphResult = (id, value, value2) => {
        if (id == -2) {
            // ZMENA ROKA

        }
        if (id == -1) {
            // STLPEC - deselect
        }
        if (id >= 0) {
            // OZNACENA STLPEC
        }
    }

    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                }
                <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>
                        <p style={{ ...styles.TextDialogSubLabel, marginTop: 4, textAlign: 'left' }}>{props.sub_title}</p>
                    </div>
                </div>
                <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Press()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                    <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRow, width: props.width, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>

                        {/* *********************************************************************************************************  
                                BODY
                        ********************************************************************************************************* */}
                        <div style={{ ...styles.BlockCenter, width: props.isSmall ? width : '50%', height: props.height - global.dialog_bar_height, backgroundColor: global.theme_gray }}>
                            {data != false ?
                                <div style={{ ...styles.Block }}>
                                    <p style={{ ...styles.TextNormal, fontWeight: '400', marginTop: 20, fontWeight: '600' }}>{lang.stats_info_active}: {data.clients_count}</p>
                                    {data.clients_min == 0 ? null :
                                        <p style={{ ...styles.TextSmall, fontWeight: '400', marginTop: 5 }}>{lang.stats_info_young}: {data.clients_min} {lang.age_years}</p>
                                    }
                                    {data.clients_min > 110 ? null :
                                        <p style={{ ...styles.TextSmall, fontWeight: '400', marginTop: 2 }}>{lang.stats_info_old}: {data.clients_max} {lang.age_years}</p>
                                    }
                                    <p style={{ ...styles.TextSmall, fontWeight: '400', marginTop: 10 }}>{lang.stats_info_average}: {data.clients_average} {lang.age_years}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: '400', marginTop: 10 }}>{lang.stats_info_woman}: {data.clients_woman}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: '400', marginTop: 2 }}>{lang.stats_info_man}: {data.clients_man}</p>
                                    <p style={{ ...styles.TextNormal, fontWeight: '600', marginTop: 30 }}>{lang.eshop}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: '400', marginTop: 2 }}>{lang.stats_info_web}: {data.clients_eshop}</p>
                                    <p style={{ ...styles.TextNormal, fontWeight: '600', marginTop: 30 }}>{lang.stats_info_presents_}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: '400', marginTop: 2 }}>{lang.stats_info_presents}: {data.products} {lang.pieces}</p>
                                    <p style={{ ...styles.TextNormal, fontWeight: '600', marginTop: 30 }}>{lang.bonus_system}</p>
                                    <p style={{ ...styles.TextSmall, fontWeight: '400', marginTop: 2 }}>{lang.stats_info_discount}: {data.vouchers} {lang.money}</p>
                                </div>
                                : null}
                        </div>
                        <div style={{ ...styles.BlockCenter, width: props.isSmall ? width : props.width - menuWidth, height: props.height - global.dialog_bar_height, paddingBottom: 40 }}>
                            <Graph
                                lang={props.lang}
                                elevation={0}
                                readius={0}
                                title={lang.stats_info_age}
                                sub_title={''}
                                width={props.isSmall ? width : 400}
                                height={400}
                                typ={0}
                                data={graphData}
                                barWidth={50}
                                highColor={global.theme_red}
                                overColor={global.theme_blue}
                                showMax={true}
                                lines={true}
                                units={''}
                                legend={false}
                                marginTop={10}
                                selectable={true}
                                show_value={true}
                                isSmall={props.isSmall}
                                func={GraphResult.bind(this)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {
                isBusy == true ?
                    <Loading offset={props.offset} theme={props.theme}  ></Loading>
                    : null
            }

        </Paper >
    );
}
