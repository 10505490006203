import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, CopyData, DialogEnterText, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, GetImageLink, GetMoney, GetPrice, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, QuickHelp, ShowError, ShowOK, TemplateWindow } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, CircularProgress, FormControlLabel, IconButton, InputAdornment, MenuItem, Pagination, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCheckCircle, faInfo, faInfoCircle, faL, faLayerGroup, faList, faListAlt, faPen, faQuestion, faShoppingBasket, faShoppingCart, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';
import { ProductDialog } from './product_dialog';
import { ConvertToPrice } from './items';
import { IsEmpty } from './items';
import { notNumber } from './items';
import { Photos } from './photos';
import { DialogImagePreview } from './items';
import { FormError } from './items';
import { TextInput } from './items';
import { Graph } from './graph';
import { GetDate } from './items';


export const BonusInfo = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [isUpdating, setUpdating] = useState(false);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);
    const [money, setMoney] = useState(GetMoney(props.language));

    // KATEGORIE
    const [categories, setCategories] = useState(false);
    const [subCategories, setSubCategories] = useState(false);
    const [subCategoryID, setSubCategoryID] = useState(0);

    // PRODUKTY
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const itemsMax = 24;

    // EDITING + NOVY PRODUKT
    const [itemSelected, setItemSelected] = useState(false);
    const [editing, setEditing] = useState(false);
    const [showProduct, setShowProduct] = useState(false);

    // SUMA
    const [sum, setSum] = useState(0);
    const [credit, setCredit] = useState(0);

    // KOSIK
    const [showCard, setShowCard] = useState(false);

    const menuWidth = props.isSmall ? 0 : 250;
    const rowHeight = 100;
    const rowWidth = 850;
    const selectHeight = props.isSmall ? 60 : 0;

    // COLORS
    const font_selected = global.theme_black;
    const font_disabled = global.theme_darker;

    const no_image = require('./react/app/no_image.jpg');

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;
            db_get();
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'bonus_categories', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    language: props.language,
                    //default: lang.products_my,
                    cabine: lang.cabine,
                    action: lang.action,
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                var tmp = json.items;
                tmp = tmp.filter(x => x.enabled == true);
                setCategories(tmp);

                if (tmp != false) {
                    db_products(tmp[0].id);
                    setIndex(tmp[0].id);
                }
            }
            //setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_products = async (category_id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'bonus', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    category_id: category_id,
                    language: props.language,
                    shopping_card: false
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.products;

                if (tmp != false) {
                    tmp.sort((a, b) => {
                        if (a.label > b.label) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }
                setCredit(json.credit);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_search = async (search) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'bonus_search', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    search: search,
                    language: props.language,
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                var tmp = json.products;

                if (tmp != false) {
                    tmp.sort((a, b) => {
                        if (a.label > b.label) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                    setIndex(-1);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                    setIndex(-1);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_update = async (data) => {
        setUpdating(true);
        try {
            const response = await fetch(
                global.db_url + 'bonus_buy', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                })
            })

            const json = await response.json();
            setUpdating(false);
            if (json.ok > 0) {
                var product = items.find(x => x.id == data.product_id);
                if (product != undefined) {
                    product.quantity = data.quantity;
                }

                var product = itemsFiltered.find(x => x.id == data.product_id);
                if (product != undefined) {
                    product.quantity = data.quantity;
                }
            }

        } catch (error) {
            setUpdating(false);
            console.log(error);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ChangeIndex = (id) => {
        setIndex(id);
        setSearch('');
        db_products(id);
    }

    const CategorySelectResult = (id) => {
        Redraw(items, 0, search, id);
    }

    const Search = (text) => {
        setSearch(text);
        /*
        setSubCategoryID(0);
        Redraw(items, 0, text, 0);
        */
        if (text.trim().length > 2) {
            db_search(text.trim());
        }
        if (text == '') {
            setItems(false);
            setItemsFiltered(false);
            setIndex(-1);
        }
    }

    const Redraw = (items, p, search, sub_category) => {
        // REDRAW PRODUKTOV
        var data = items;
        if (search.trim() != '') {
            // filtrovanie podla hladaneho retazca
            var words = search.split(' ');
            data = items.filter(x => FindWords(x.label, x.keywords, words));
        } else {

            if (sub_category > 0) {
                // filtrovanie vybratej kategórie
                data = items.filter(x => parseInt(x.category_id) == sub_category);
            }

        }

        var count = data.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax)
        setItemsFiltered(data);

        if (count == 0) {
            setItemsFiltered(false);
        }

        setPage(p);
        GoToStart();
    }

    function FindWords(label, keywords, words) {
        // hladanie retazca v nazve a keywords
        var result = 0;
        words.forEach(word => {
            if (label.toLowerCase().includes(word.toLowerCase()) || keywords.toLowerCase().includes(word.toLowerCase())) {
                result += 1;
            }

        });
        // ak je pocet najdených slov == poctu hladaných slov -> je zhoda
        return words.length == result;
    }

    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);

        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0, '', 0);
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_, search, subCategoryID);
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_products');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    // ------------------------------------------------------------------------------------
    // NAHLAD PRODUKTU
    // ------------------------------------------------------------------------------------

    const Edit = (item) => {
        setItemSelected(item);
    }


    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? '60%' : '75%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left', marginLeft: props.isSmall ? 10 : 0 }}>{props.title}</p>
                        <p style={{ ...styles.TextTiny, marginTop: 4, fontWeight: '600', textAlign: 'left', marginLeft: props.isSmall ? 10 : 0 }}>{props.sub_title}</p>
                    </div>
                </div>

                <div style={{ ...styles.Block, width: props.isSmall ? 160 : 300, height: global.dialog_bar_height, justifyContent: 'center' }}>
                    <TextField
                        value={search}
                        onInput={e => Search(e.target.value)}
                        size="small"
                        autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off"
                        style={{ width: props.isSmall ? 150 : 280 }}
                        placeholder={lang.search_in_category}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                            }
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                    <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                </IconButton>
                            </InputAdornment>,
                            style: { backgroundColor: global.theme_white, borderRadius: 30 },
                        }}
                        variant="outlined"
                    />
                </div>
                <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                    {props.isSmall ?
                        <div style={{ ...styles.Block, height: selectHeight, backgroundColor: global.theme_back }}>
                            <Select
                                value={index}
                                onChange={event => ChangeIndex(event.target.value)}
                                size='small'
                                style={{ backgroundColor: global.theme_white, width: '100%' }}
                            >

                                {categories != false ?
                                    categories.map((item) => (
                                        item.typ != 100 ?
                                            <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                                            : null
                                    ))
                                    : null}
                            </Select>
                        </div>
                        :
                        <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                            <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                {categories != false ?
                                    categories.map((item) => (
                                        <Button key={item.id} onClick={() => ChangeIndex(item.id)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == item.id ? global.theme_back : '#00000000', color: index == item.id ? font_selected : font_disabled }}>
                                            <div style={{ ...styles.BlockLeft }}>
                                                <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{item.label}</p>
                                                <p style={{ ...styles.TextTiny, color: global.themes[props.theme].theme_darker, marginTop: 2, lineHeight: 1, textAlign: 'left' }}>{item.brand}</p>
                                            </div>
                                        </Button>
                                    ))
                                    : null}

                            </div>
                        </div>
                    }
                    <div style={{ ...styles.BlockCenter, width: props.width - menuWidth, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                        {/* BODY */}
                        <div id={'id_products'}>
                        </div>
                        <div style={{ ...styles.Block, width: '98%' }}>
                            {/* *********************************************************************************************************  
                                ZOZNAM PRODUKTOV
                        ********************************************************************************************************* */}
                            {itemsFiltered != false ?
                                itemsFiltered.map((item, index) => (
                                    <Paper elevation={overID == item.id ? 5 : 2} key={item.id} style={{ ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: global.theme_lighter, marginTop: 5, marginBottom: 5, borderRadius: rowHeight / 2 }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow }}>
                                            <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                <img src={item.image == null || item.image == '' ? no_image : item.image.substring(0, 4) == 'http' ? item.image : global.web_images + '/' + item.image} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                            </div>
                                            <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: '70%', justifyContent: 'center', marginLeft: 10 }}>
                                                <p style={{ ...props.isSmall ? styles.TextTinyMobile : styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                    <p style={{ ...styles.TextTiny, marginTop: 0, textAlign: 'left' }}>{item.code} {item.details}</p>
                                                    <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                        <div style={{ ...styles.BlockLeft, width: '40%' }}>
                                                        </div>
                                                        <div style={{ ...styles.BlockLeft, width: '40%' }}>
                                                        </div>
                                                        <div style={{ ...styles.BlockLeft, width: '20%' }}>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ ...styles.Block, height: rowHeight, width: 250 }}>
                                                <div style={{ ...styles.BlockRowRaw }}>
                                                    <p style={{ ...styles.TextSmall }}>{lang.bonus_credit_quantity}: <b>{GetPrice(item.cost_dph)}</b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </Paper>
                                )) :
                                // ZIADNE PRODUKTY                            
                                <div style={{ ...styles.Block, paddingTop: 20 }}>
                                    <EmptyList lang={lang} />
                                </div>
                            }

                            {itemsFiltered != false ?
                                pageMax > 1 ?
                                    <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                        <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                    </div>
                                    : null
                                : null}
                        </div>
                    </div>
                </div>
            </div>

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Paper>
    );
}

