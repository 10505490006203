import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import './items.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, ConvertToPrice, CopyData, Debug, DialogEnterNumber, DialogEnterText, DialogImagePreview, DialogInfo, DialogLogin, DialogVideo, DialogYesNo, DialogYoutube, EmployeeSelect, EmptyList, FormOK, GetDate, GetDate3, GetDatum, GetDatum2, GetHoursFromMinutes, GetImageLink, GetMinutesFromHours, GetMoney, GetPages, GetPrice, GetTimeHM, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, Today } from './items';
import { styles } from './styles';
import { Backdrop, Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Hidden, IconButton, InputAdornment, MenuItem, Pagination, Paper, Popper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faA, faAngleDown, faAngleLeft, faAngleRight, faAngleUp, faBook, faBookOpen, faCheck, faCheckCircle, faComment, faEdit, faEye, faEyeSlash, faIdCard, faImage, faInfo, faInfoCircle, faL, faLayerGroup, faList, faListAlt, faListCheck, faListOl, faPaperPlane, faPeace, faPen, faPlusCircle, faQuestion, faQuestionCircle, faSave, faSearch, faShare, faShoppingBag, faShoppingBasket, faShoppingCart, faTrash, faUser, faWarning, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export const Lessons = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);

    // PREDAJ - ZOZNAM
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const itemsMax = 24;

    // NOVY PRISPEVOK
    const [showCreate, setShowCreate] = useState(false);
    const [edit, setEdit] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DETAIL SKOLENIA
    const [showLesson, setShowLesson] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const rowHeight = props.isSmall ? 160 : 100;
    const rowWidth = 850;
    const selectHeight = props.isSmall ? 40 : 0;

    const no_image = require('./react/app/no_image.jpg');

    // Aktuálna farebna téma
    const color = global.themes[props.theme];

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            //let year = new Date().getFullYear();
            db_get();
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'lessons', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    language: props.user.language,
                    typ: 0,
                    admin: false
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const Search = (text) => {
        setSearch(text);
        Redraw(items, 0, text);
    }

    const Redraw = (items, p, search) => {
        // REDRAW PRODUKTOV
        var data = items;

        if (search.trim() != '') {
            // filtrovanie podla hladaneho retazca
            var words = search.split(' ');
            data = data.filter(x => FindWords(x.title, GetDate(x.date), x.teacher, words));
        }

        var count = data.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax)
        setItemsFiltered(data);

        if (count == 0) {
            setItemsFiltered(false);
        }

        setPage(p);
        GoToStart();
    }

    function FindWords(label, date, salon, words) {
        // hladanie retazca v nazve a keywords        
        var result = 0;
        words.forEach(word => {
            if (label.toLowerCase().includes(word.toLowerCase()) || salon.toLowerCase().includes(word.toLowerCase()) || date.toLowerCase().includes(word.toLowerCase())) {
                result += 1;
            }
        });
        // ak je pocet najdených slov == poctu hladaných slov -> je zhoda
        return words.length == result;
    }

    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);

        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0, '');
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_, search);
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }


    const AddNew = () => {
        setEdit(false);
        setShowCreate(true);
    }

    const LessonResult = (redraw) => {

        setShowLesson(false);
        if (redraw == true) {
            db_get();
        }
    }


    const SelectItem = (item) => {
        if (item.typ != global.lesson_canceled) {
            setItemSelected(item);
            setShowLesson(true);
        }
    }


    /*    
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faBookOpen} />
                        </div>
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? '45%' : '50%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>
                        {props.isSmall ? null :
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        }
                    </div>
                </div>

                <div style={{ ...styles.BlockRow, justifyContent: 'flex-end', alignItems: 'center', width: props.isSmall ? 160 : '30%', height: global.dialog_bar_height }}>
                    <TextField
                        value={search}
                        onInput={e => Search(e.target.value)}
                        size="small"
                        autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off"
                        style={{ width: props.isSmall ? 150 : 300 }}
                        placeholder={lang.search_in_category}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                            }
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                    <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                </IconButton>
                            </InputAdornment>,
                            style: { backgroundColor: global.theme_white, borderRadius: 30 },
                        }}
                        variant="outlined"
                    />
                </div>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 200, height: global.dialog_bar_height, marginLeft: 20 }}>
                        {props.user.shared == true || props.admin == true ?
                            <Button onClick={() => AddNew()} style={{ ...styles.ButtonDark, backgroundColor: color.theme_dark, width: 180 }}>
                                <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPlusCircle} />
                                <p style={{ ...styles.TextTiny, color: global.theme_white }}>
                                    {lang.lesson_new}
                                </p>
                            </Button>
                            : null}
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                    <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockCenter, width: props.width, height: props.height - global.dialog_bar_height - selectHeight, backgroundColor: global.theme_back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>

                            < div id={'id_start'} style={{ ...styles.BlockCenter, width: '98%' }}>
                                {/* *********************************************************************************************************  
                                            ZOZNAM SKOLENI
                                    ********************************************************************************************************* */}
                                {itemsFiltered != false ?
                                    itemsFiltered.map((item, index) => (
                                        <Paper onClick={() => SelectItem(item)} elevation={overID == item.id ? 5 : 2} key={item.id} style={{ ...styles.Block, maxWidth: rowWidth, minHeight: rowHeight, backgroundColor: item.typ == global.lesson_canceled ? global.theme_lighteen_red : global.theme_gray, marginTop: 8, marginBottom: 8, borderRadius: global.row_radius, cursor: 'pointer' }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow }}>
                                                {props.isSmall ?
                                                    <div style={{ ...styles.BlockCenter, height: rowHeight, width: 80, justifyContent: 'center' }}>
                                                        <img src={item.image == false || item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', maxWidth: 60, maxHeight: 60, objectFit: 'contain', borderRadius: 10 }}></img>
                                                    </div>
                                                    :
                                                    <div style={{ ...styles.Block, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                        <img src={item.image == false || item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', maxWidth: rowHeight, maxHeight: rowHeight, objectFit: 'contain', borderRadius: global.row_radius }}></img>
                                                    </div>
                                                }
                                                {props.isSmall ?
                                                    <div style={{ ...styles.BlockLeft, height: rowHeight, width: '72%', justifyContent: 'center', marginLeft: 10 }}>
                                                        <div ostyle={{ ...styles.BlockLeft }}>
                                                            <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.title}</p>
                                                            <p style={{ ...styles.TextTiny, marginTop: 5, textAlign: 'left' }}>{GetDate(item.date)} {GetTimeHM(item.time)} {lang.hour_short}</p>
                                                            <p style={{ ...styles.TextTiny, marginTop: 10, textAlign: 'left' }}>{lang.lesson_registered}: {item.count}/{item.capacity}</p>
                                                            <p style={{ ...styles.TextTiny, textAlign: 'left', marginRight: 20 }}>{lang.lesson_teacher}: {item.teacher}</p>
                                                            {item.typ != global.lesson_canceled && item.registered.registered == true ?
                                                                <div style={{ ...styles.Block, width: 80, backgroundColor: global.theme_darker_green, height: 20, borderRadius: 10, marginTop: 10 }}>
                                                                    <p style={{ ...styles.TextXTiny, color: global.theme_white }}>{lang.lesson_registered_user_} </p>
                                                                </div>
                                                                : null}
                                                            {item.typ == global.lesson_canceled ?
                                                                <div style={{ ...styles.Block, width: 130, backgroundColor: global.theme_dark_red, height: 20, borderRadius: 10, marginTop: 10 }}>
                                                                    <p style={{ ...styles.TextXTiny, color: global.theme_white }}>{lang.lesson_canceled} </p>
                                                                </div>
                                                                : null}
                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{ ...styles.BlockLeft, height: rowHeight, width: '87%', justifyContent: 'center', marginLeft: 10 }}>
                                                        <div style={{ ...styles.Block, height: rowHeight, justifyContent: 'center' }}>

                                                            <div style={{ ...styles.BlockRow }}>
                                                                <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                                                    <div style={{ ...styles.BlockRowRaw }}>
                                                                        {item.typ != global.lesson_canceled && item.online == true ?
                                                                            <div style={{ ...styles.Block, width: 80, backgroundColor: global.theme_dark_gray, height: 20, borderRadius: 10, marginRight: 10 }}>
                                                                                <p style={{ ...styles.TextXTiny, color: global.theme_white }}>{lang.lesson_online_} </p>
                                                                            </div>
                                                                            : null}
                                                                        <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.title}</p>
                                                                    </div>
                                                                </div>
                                                                <div style={{ ...styles.BlockRight, width: '20%', marginRight: 15 }}>
                                                                    {item.typ != global.lesson_canceled && item.registered.registered == true ?
                                                                        <div style={{ ...styles.Block, width: 80, backgroundColor: global.theme_darker_green, height: 20, borderRadius: 10, marginLeft: 10 }}>
                                                                            <p style={{ ...styles.TextXTiny, color: global.theme_white }}>{lang.lesson_registered_user_} </p>
                                                                        </div>
                                                                        : null}
                                                                    {item.typ == global.lesson_canceled ?
                                                                        <div style={{ ...styles.Block, width: 130, backgroundColor: global.theme_dark_red, height: 20, borderRadius: 10, marginLeft: 10 }}>
                                                                            <p style={{ ...styles.TextXTiny, color: global.theme_white }}>{lang.lesson_canceled} </p>
                                                                        </div>
                                                                        : null}
                                                                </div>
                                                            </div>

                                                            <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                                <div style={{ ...styles.BlockLeft, width: '50%' }}>
                                                                    <p style={{ ...styles.TextTiny }}>{GetDate(item.date)} {GetTimeHM(item.time)} {lang.hour_short}</p>
                                                                    <p style={{ ...styles.TextTiny, marginTop: 2 }}>{lang.lesson_duration}: {GetHoursFromMinutes(item.duration, lang)}</p>
                                                                </div>
                                                                {item.typ != global.lesson_canceled ?
                                                                    <div style={{ ...styles.BlockRight, width: '50%', marginRight: 15 }}>
                                                                        {item.online == true ?
                                                                            <p style={{ ...styles.TextTiny }}>{lang.lesson_registered}: {item.count}</p>
                                                                            :
                                                                            <p style={{ ...styles.TextTiny }}>{lang.lesson_registered}: {item.count}/{item.capacity}</p>
                                                                        }
                                                                        <p style={{ ...styles.TextTiny, marginTop: 2 }}>{lang.lesson_teacher}: {item.teacher}</p>
                                                                    </div>
                                                                    : null}
                                                            </div>

                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </Paper>
                                    )) :
                                    // ZIADNE POLOZKY                            
                                    <div style={{ ...styles.Block, paddingTop: 20 }}>
                                        <EmptyList lang={lang} />
                                    </div>
                                }

                                {itemsFiltered != false ?
                                    pageMax > 1 ?
                                        <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                            <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                        </div>
                                        : null
                                    : null}
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {showLesson == true ?
                <LessonDetails item={itemSelected} remoteApp={props.remoteApp} appVersion={props.appVersion} user={props.user} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} isSmall={props.isSmall} func={LessonResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError text={lang.db_error} offset={props.offset} theme={props.theme} func={ShowErrorPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Paper >
    );
}


export const LessonDetails = (props) => {
    /*
            DETAIL SKOLENIA + PRIHLASENIE SA NA SKOLENIE
            
    */
    let isSmall = props.isSmall;

    const [isBusy, setBusy] = useState(false);
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showStatus, setShowStatus] = useState(false);
    const [statusText, setStatusText] = useState('');
    const [statusRegistered, setStatusRegistered] = useState(false);
    const [redraw, setRedraw] = useState(false);

    const [lesson, setLesson] = useState(false);
    const [lessonFor, setLessonFor] = useState([]);
    const [count, setCount] = useState(0);
    const [persons, setPersons] = useState(0);
    const [status, setStatus] = useState(-1);
    const [inCapacity, setInCapacity] = useState(false);
    const [registerAllowed, setRegisterAllowed] = useState(false);

    const [showRegister, setShowRegister] = useState(false);
    const [showCancel, setShowCancel] = useState(false);
    const [showChange, setShowChange] = useState(false);
    const [showCopy, setShowCopy] = useState(false);
    const [showYoutube, setShowYoutube] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1200 ? 1200 : width;
    const dialogHeight = height >= 850 ? 850 : height;
    const buttonHeight = isSmall ? 110 : 80;
    const imageSize = 200;

    let { func } = props;
    var lang = props.lang;
    let color = global.themes[props.theme];

    var running = false;

    useEffect(() => {
        if (running == false) {
            if (props.data == undefined) {
                db_get();
            } else {

                setLesson(props.data);
                let lesson_for = JSON.parse(props.data.lesson_for);
                setLessonFor(lesson_for);

            }
        }
        running = true;

        return () => {
        };

    }, []);

    const db_get = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'lesson', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    language: props.language,
                    id: props.item.id,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var item = json.item;
                setLesson(item);
                setCount(parseInt(json.count));
                setStatus(json.status);

                var tmp = JSON.parse(item.lesson_for);
                setLessonFor(tmp);

                if (item.online == true) {
                    setInCapacity(true);
                } else {
                    if (json.users != false) {
                        var tmp = json.users.find(x => x.user_id == props.user.id);
                        if (tmp != undefined) {
                            setInCapacity(tmp.in_capacity == false ? false : true);
                        }
                    }
                }
                if (json.user != false) {
                    setPersons(json.user.persons);
                }

                if (item.date_register >= Today()) {
                    setRegisterAllowed(true);
                } else {
                    setRegisterAllowed(false);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (status, persons, changeDate) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'lessons_register', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    lesson_id: lesson.id,
                    status: status,
                    datum: GetDatum2(lesson.date),
                    time: GetTimeHM(lesson.time),
                    duration: GetHoursFromMinutes(lesson.duration, lang),
                    address: lesson.online == true ? lang.lesson_online : lesson.address,
                    persons: persons,
                    change_date: changeDate,
                    lang: {
                        do_not_reply: lang.do_not_reply,
                        do_not_reply_text: lang.do_not_reply_text,
                        title: lang.lesson,
                        register: lang.lesson_registered_label,
                        cancel: lang.lesson_unregistered_label,
                        persons: lang.lesson_count_persons
                    }
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setRedraw(true);

                if (status == global.lesson_user_canceled) {
                    // Zrusenie
                    setStatus(global.lesson_user_canceled);
                    setShowOK(true);
                }
                if (status == global.lesson_user_registered) {
                    // prihlásenie
                    if (json.registered > 0) {
                        setStatus(global.lesson_user_registered);
                        setStatusText(lang.lesson_registered_text);
                        setStatusRegistered(true);
                        setShowStatus(true);
                    } else {
                        if (json.full == true) {
                            setStatusText(lang.lesson_registered_full);
                            setStatusRegistered(false);
                            setShowStatus(true);
                        }
                    }
                }

                db_get();

            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = () => {
        func(redraw);
    }

    const Close = () => {
        Press(redraw);
    }

    const RegisterResult = (value, persons) => {
        setShowRegister(false);
        if (value == true) {
            db_update(global.lesson_user_registered, persons, true);
        }
    }

    const ChangeResult = (value, persons) => {
        setShowChange(false);
        if (value == true) {
            db_update(global.lesson_user_registered, persons, false);
        }
    }

    const CancelResult = (value, persons) => {
        setShowCancel(false);
        if (value == true) {
            db_update(global.lesson_user_canceled, 0, true);
        }
    }

    const DialogInfoResult = () => {
        setShowStatus(false);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const CopyLink = () => {
        navigator.clipboard.writeText(lesson.link);
        setShowCopy(true);
    }

    return (

        <Backdrop
            open={true}
            style={{
                paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 10000,
            }}
        >
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    {isSmall ? null :
                        <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                            <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                                <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faBookOpen} />
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.Block, width: isSmall ? '95%' : '80%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, justifyContent: 'center', height: global.dialog_bar_height }}>
                            <p style={{ ...styles.TextDialogLabel, textAlign: 'left', marginLeft: props.isSmall ? 10 : 0 }}>{lesson.title == '' ? lang.lesson : isSmall ? lang.lesson : lesson.title}</p>
                            {isSmall ?
                                props.preview != true ? lesson != false ? status == 0 ?
                                    <div style={{ ...styles.BlockLeft }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_black, marginLeft: 10, marginTop: 5 }}>{inCapacity == true ? lang.lesson_registered_user : lang.lesson_registered_user_reserve}</p>
                                        <p style={{ ...styles.TextTiny, color: global.theme_black, marginLeft: 10 }}>{lang.lesson_count_persons_}: {persons}</p>
                                    </div>
                                    : null : null : null
                                :
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4, textAlign: 'left' }}>{lang.lesson}</p>
                            }
                        </div>
                    </div>
                    {isSmall ? null :
                        <div style={{ ...styles.Block, width: 180, height: global.dialog_bar_height, marginRight: 20 }}>
                            {props.preview != true ? lesson != false ? status == 0 ?
                                <div style={{ ...styles.Block, width: 180, height: 50, backgroundColor: inCapacity == true ? global.theme_darker_green : global.theme_dark_blue, borderRadius: 5 }}>
                                    <div style={{ ...styles.Block, width: 176 }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_white }}>{inCapacity == true ? lang.lesson_registered_user : lang.lesson_registered_user_reserve}</p>
                                        {inCapacity == true ?
                                            <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.lesson_count_persons_}: {persons}</p>
                                            : null}
                                    </div>
                                </div>
                                : null : null : null}
                        </div>
                    }
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: global.theme_white, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '95%', height: dialogHeight - global.dialog_bar_height - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                        {lesson != false ?
                            <div style={{ ...styles.BlockCenter, paddingTop: 20, paddingBottom: 50 }}>
                                {lesson.image != false ?
                                    <img src={GetImageLink(lesson.image)} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain' }}></img>
                                    : null}
                                <p style={{ ...styles.TextLarge, marginTop: 20, fontWeight: '600' }}>{lesson.title}</p>
                                {lesson.online == true ?
                                    <p style={{ ...styles.TextSmall, marginTop: 5 }}>{lang.lesson_online}</p>
                                    : null}

                                <div style={{ ...styles.TextSmall, width: '96%', textAlign: 'left', marginTop: 10 }} dangerouslySetInnerHTML={{ __html: lesson.description }} />
                                {lesson.typ != 3 &&
                                    <div style={{ ...styles.BlockLeft, width: '96%', marginTop: 20, paddingTop: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                        <div style={{ ...styles.BlockLeft }}>
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                <p style={{ ...styles.TextSmall, textAlign: 'left' }}>{lang.lesson_for_}:</p>
                                                {lessonFor != false ? lessonFor.map((item, index) => (
                                                    <p key={index} style={{ ...styles.TextSmall, marginLeft: 10, fontWeight: '600' }}>{lang.lesson_for[item]}</p>
                                                )) : null}
                                            </div>

                                            <p style={{ ...styles.TextSmall, marginTop: 20, textAlign: 'left' }}>{lang.lesson_date}:<span style={{ marginLeft: 10, fontWeight: '600' }}>{GetDatum2(lesson.date)}</span></p>
                                            <p style={{ ...styles.TextSmall, textAlign: 'left' }}>{lang.lesson_time}:<span style={{ marginLeft: 10, fontWeight: '600' }}>{GetTimeHM(lesson.time)}</span></p>
                                            <p style={{ ...styles.TextSmall, textAlign: 'left' }}>{lang.lesson_duration}:<span style={{ marginLeft: 10, fontWeight: '600' }}>{GetHoursFromMinutes(lesson.duration, lang)}</span></p>
                                            <p style={{ ...styles.TextSmall, marginTop: 20, textAlign: 'left' }}>{lang.lesson_teacher}:<span style={{ marginLeft: 10, fontWeight: '600' }}>{lesson.teacher}</span></p>
                                        </div>

                                        {lesson.online == true ? props.preview != true || props.data != undefined ?
                                            <div style={{ ...styles.BlockLeft, marginTop: 0 }}>
                                                <p style={{ ...styles.TextSmall, marginTop: 0, textAlign: 'left' }}>{lang.lesson_online}: <span onClick={() => CopyLink()} style={{ ...styles.TextSmall, marginLeft: 3, cursor: 'pointer' }}><u>{lang.lesson_copy}</u></span></p>

                                                {props.remoteApp == true ? null :
                                                    <div style={{ ...styles.BlockLeft }}>
                                                        <a href={lesson.link} target='_blank' style={{ ...styles.TextSmall, color: global.theme_blue, marginLeft: 20, marginTop: 10 }}>{lang.lesson_url_}</a>
                                                    </div>
                                                }

                                                {props.remoteApp == true ?
                                                    <div style={{ ...styles.BlockLeft }}>
                                                        {props.appVersion > 2 ?
                                                            <div style={{ ...styles.BlockLeft }}>
                                                                <a href={lesson.link + '.in_safari'} target='_blank' style={{ ...styles.TextSmall, color: global.theme_blue, marginLeft: 20, marginTop: 10 }}>{lang.lesson_url_}</a>
                                                            </div>
                                                            :
                                                            <div style={{ ...styles.BlockLeft }}>
                                                                <a href={global.web + '/webinar/.pdf/' + lesson.id} target='_blank' style={{ ...styles.TextSmall, color: global.theme_blue, marginLeft: 20, marginTop: 10 }}>{lang.lesson_url_}</a>
                                                                <p style={{ ...styles.TextSmall, marginTop: 0, textAlign: 'left', marginLeft: 20 }}>{lang.lesson_url_note}</p>
                                                            </div>
                                                        }
                                                    </div>
                                                    : null}
                                                {/*
                                            <p style={{ ...styles.TextTiny, color: global.theme_dark_gray, marginLeft: 20 }}>{lesson.link}</p>
                                            */}
                                                <p onClick={() => navigator.clipboard.writeText(lesson.meeting_id)} style={{ ...styles.TextSmall, marginLeft: 20, marginTop: 15, cursor: 'pointer' }}>{lang.lesson_meeting_id}: {lesson.meeting_id}</p>
                                                <p onClick={() => navigator.clipboard.writeText(lesson.passcode)} style={{ ...styles.TextSmall, marginTop: 5, marginLeft: 20, cursor: 'pointer' }}>{lang.lesson_passcode}: {lesson.passcode}</p>
                                                <p style={{ ...styles.TextTiny, marginLeft: 20, color: global.theme_dark_gray }}>{lang.touch_to_copy}</p>
                                            </div>
                                            : null :
                                            <p style={{ ...styles.TextSmall, marginTop: 20, textAlign: 'left' }}>{lang.lesson_address}:<span style={{ marginLeft: 10, fontWeight: '600' }}>{lesson.address}</span></p>
                                        }
                                        {props.preview != true || props.data != undefined ?
                                            <div style={{ ...styles.BlockLeft }}>
                                                <p style={{ ...styles.TextSmall, marginTop: 25 }}>{lang.lesson_register_to}:<span style={{ marginLeft: 10, fontWeight: '600' }}>{GetDatum2(lesson.date_register)}</span></p>
                                                {lesson.online == false ?
                                                    <div style={{ ...styles.BlockLeft, marginTop: 0 }}>
                                                        <p style={{ ...styles.TextSmall, textAlign: 'left' }}>{lang.lesson_registered}:<span style={{ marginLeft: 10, fontWeight: '600' }}>{parseInt(count) > parseInt(lesson.capacity) ? lesson.capacity : count}/{lesson.capacity}</span></p>
                                                        <p style={{ ...styles.TextSmall, textAlign: 'left' }}>{lang.lesson_registered_free}:<span style={{ marginLeft: 10, fontWeight: '600' }}>{parseInt(count) > parseInt(lesson.capacity) ? parseInt(count) - parseInt(lesson.capacity) : 0}/{lesson.capacity_reserve}</span></p>
                                                    </div>
                                                    : null}
                                            </div>
                                            : null}
                                    </div>}
                            </div>
                            : null}
                    </div>
                    {props.preview == true ?
                        isBusy == false ?
                            lesson.youtube_link != '' ?
                                <div style={{ ...styles.Block, height: buttonHeight, backgroundColor: color.theme_lighteen }}>
                                    <Button onClick={() => setShowYoutube(true)} style={{ ...styles.ButtonDark, backgroundColor: global.theme_dark_red, width: 240 }}>{lang.lesson_video}</Button>
                                </div>
                                : null : null
                        :
                        <div style={{ ...styles.Block, height: buttonHeight, backgroundColor: color.theme_lighteen }}>
                            <div style={{ ...isSmall ? styles.Block : styles.BlockRowRaw }}>
                                {isBusy == false && registerAllowed == true ? lesson.online == false && (status == -1 || status == global.lesson_user_canceled) ? parseInt(count) < (parseInt(lesson.capacity) + parseInt(lesson.capacity_reserve)) ?
                                    <div style={{ ...styles.Block, width: 260, height: buttonHeight }}>
                                        <Button disabled={isBusy} onClick={() => setShowRegister(true)} style={{ ...styles.ButtonDark, backgroundColor: color.theme_dark, width: 240 }}>{parseInt(count) < parseInt(lesson.capacity) ? lang.lesson_register : lang.lesson_register_reserve}</Button>
                                    </div>
                                    : null : null : null}
                                {isBusy == false && registerAllowed == true ? lesson.online == true && (status == -1 || status == global.lesson_user_canceled) ?
                                    <div style={{ ...styles.Block, width: 260, height: buttonHeight }}>
                                        <Button disabled={isBusy} onClick={() => setShowRegister(true)} style={{ ...styles.ButtonDark, backgroundColor: color.theme_dark, width: 240 }}>{lang.lesson_register}</Button>
                                    </div>
                                    : null : null}
                                {isBusy == false ? (status == 0 && (parseInt(lesson.capacity) - parseInt(count) + parseInt(persons) > 0)) ?
                                    <div style={{ ...styles.Block, width: 260, height: isSmall ? buttonHeight / 2 : buttonHeight }}>
                                        <Button disabled={isBusy} onClick={() => setShowChange(true)} style={{ ...styles.ButtonDark, backgroundColor: color.theme_dark, width: 240 }}>{lang.lesson_persons_edit}</Button>
                                    </div>
                                    : null : null}
                                {isBusy == false ? status == 0 ?
                                    <div style={{ ...styles.Block, width: 260, height: isSmall ? buttonHeight / 2 : buttonHeight }}>
                                        <Button disabled={isBusy} onClick={() => setShowCancel(true)} style={{ ...styles.ButtonDark, backgroundColor: global.theme_dark_red, width: 240 }}>{lang.lesson_register_cancel}</Button>
                                    </div>
                                    : null : null}
                            </div>
                        </div>
                    }
                </div>
            </Paper>

            {showYoutube == true ?
                <DialogVideo theme={props.theme} file={global.web_uploads + lesson.youtube_link} radius={props.radius} lang={props.lang} title={lesson.title} sub_title={'Video'} background={global.theme_dark_gray} func={() => setShowYoutube(false)} />
                : null}

            {showChange == true ?
                <LessonChange lesson={lesson} free={lesson.online == true ? 999999 : parseInt(lesson.capacity) - parseInt(count) + parseInt(persons) + (parseInt(count) > parseInt(lesson.capacity) ? parseInt(count) - parseInt(lesson.capacity) : 0)} persons={persons} theme={props.theme} radius={props.radius} lang={props.lang} func={ChangeResult.bind(this)} />
                : null}

            {showRegister == true ?
                <LessonRegister lesson={lesson} registered={count} reserve={parseInt(count) < parseInt(lesson.capacity) ? false : true} online={lesson.online} theme={props.theme} radius={props.radius} title={lang.lesson_registered_label} sub_title={lesson.title} text={lang.lesson_registered_ask} sub_text={lang.lesson_registered_subtext} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} lang={props.lang} func={RegisterResult.bind(this)} />
                : null}

            {showCancel == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.lesson_register_cancel} sub_title={lesson.title} text={lang.lesson_register_cancel_ask} sub_text={''} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={CancelResult.bind(this)} />
                : null}

            {showStatus == true ?
                <DialogInfo theme={props.theme} radius={props.radius} title={lang.lesson_registered_label} sub_title={''} text={statusText} button={props.lang.close} icon_show={statusRegistered ? faCheckCircle : faXmarkCircle} icon_color={statusRegistered ? global.theme_darker_green : global.theme_dark_red} func={DialogInfoResult.bind(this)} />
                : null}

            {showCopy == true ?
                <DialogInfo theme={props.theme} radius={props.radius} title={lang.lesson_link_copy_label} sub_title={''} text={lang.lesson_link_copy_text_1} sub_text={lang.lesson_link_copy_text_2} button={props.lang.close} func={() => setShowCopy(false)} />
                : null}

            {showError == true ?
                <ShowError offset={props.offset} theme={props.theme} text={lang.db_error} func={ShowErrorPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop >

    );
}


export const LessonRegister = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <DialogYesNo theme={props.theme} radius={props.radius} title={'Otázka'} sub_title={''} text={'Text otázky'} sub_text={''} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={DialogYesNoResult.bind(this)} />

            ------------------------------------------------------------
            center      -> v strede
            hideClose   -> nebude sa zobrazovat zatvorenie okna
            warning_text    -> upozornenie - cervene
            ------------------------------------------------------------
            
            const DialogYesNoResult = (value) => {
                console.log(value)
            }
    */

    const [number, setNumber] = useState(1);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 700 : width;
    const dialogHeight = height >= 360 ? 360 : height;

    let { func } = props;
    let lang = props.lang;
    let lesson = props.lesson;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (value) => {
        func(value, number);
    }

    const PlusMinusResult = (value) => {
        setNumber(value);
        Debug(value);
    }

    return (
        <Backdrop open={true} style={{ zIndex: 500, paddingLeft: props.center == true ? 0 : width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                            {props.sub_title != '' && props.sub_title != undefined ?
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                                : null}
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        {props.hideClose == true ? null :
                            <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                                <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                            </IconButton>
                        }
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '96%', marginTop: 10 }}>
                        <p style={{ ...styles.TextNormal, textAlign: 'center' }}>{props.text}</p>
                        {props.sub_text != undefined ?
                            <p style={{ ...styles.TextTiny, textAlign: 'center', marginTop: 5 }}>{props.sub_text}</p>
                            : null}
                        {props.reserve == false && (parseInt(lesson.capacity) - parseInt(props.registered) > 1) ?
                            <div style={{ ...styles.Block, marginTop: 50 }}>
                                <p style={{ ...styles.TextTiny, textAlign: 'center', marginBottom: 10, fontWeight: '500' }}>{lang.lesson_count}</p>
                                <PlusMinus min={1} max={props.reserve == false ? parseInt(lesson.capacity) - parseInt(props.registered) : 1} value={number} no_wait theme={props.theme} func={PlusMinusResult.bind(this)} />
                            </div>
                            : null}
                    </div>
                </div>
                <div style={{ ...styles.BlockRow, height: 100 }}>
                    <div style={{ ...styles.Block, height: 100 }}>
                        <Button onClick={() => Press(true)} style={{ ...styles.ButtonDark, backgroundColor: props.yes_color == undefined ? global.themes[props.theme].button_dark : props.yes_color }}>{props.yes}</Button>
                    </div>
                    <div style={{ ...styles.Block, height: 100 }}>
                        <Button onClick={() => Press(false)} style={{ ...styles.ButtonDark, backgroundColor: props.no_color == undefined ? global.themes[props.theme].button_dark : props.no_color }}>{props.no}</Button>
                    </div>

                </div>
            </Paper>
        </Backdrop >
    );
}



export const LessonChange = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <DialogYesNo theme={props.theme} radius={props.radius} title={'Otázka'} sub_title={''} text={'Text otázky'} sub_text={''} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={DialogYesNoResult.bind(this)} />

            ------------------------------------------------------------
            center      -> v strede
            hideClose   -> nebude sa zobrazovat zatvorenie okna
            warning_text    -> upozornenie - cervene
            ------------------------------------------------------------
            
            const DialogYesNoResult = (value) => {
                console.log(value)
            }
    */

    const [number, setNumber] = useState(props.persons);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 600 ? 700 : width;
    const dialogHeight = height >= 360 ? 360 : height;

    let { func } = props;
    let lang = props.lang;
    let lesson = props.lesson;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (value) => {
        func(value, number);
    }

    const PlusMinusResult = (value) => {
        setNumber(value);
        Debug(value);
    }

    return (
        <Backdrop open={true} style={{ zIndex: 500, paddingLeft: props.center == true ? 0 : width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.lesson_persons_edit}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '96%', marginTop: 10 }}>
                        <p style={{ ...styles.TextNormal, textAlign: 'center', marginBottom: 10 }}>{lang.lesson_count_change}</p>
                        <PlusMinus min={1} max={props.free} value={number} no_wait theme={props.theme} func={PlusMinusResult.bind(this)} />
                    </div>
                </div>
                <div style={{ ...styles.BlockRow, height: 100 }}>
                    <div style={{ ...styles.Block, height: 100 }}>
                        <Button onClick={() => Press(true)} style={{ ...styles.ButtonDark, backgroundColor: props.yes_color == undefined ? global.theme_dark_red : props.yes_color }}>{lang.save}</Button>
                    </div>
                    <div style={{ ...styles.Block, height: 100 }}>
                        <Button onClick={() => Press(false)} style={{ ...styles.ButtonDark, backgroundColor: props.no_color == undefined ? global.themes[props.theme].button_dark : props.no_color }}>{lang.close}</Button>
                    </div>

                </div>
            </Paper>
        </Backdrop >
    );
}



export const LessonsVideo = (props) => {
    let defaultFilter = {
        enabled: true,
    }

    const [isBusy, setBusy] = useState(false);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);

    // PREDAJ - ZOZNAM
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [page, setPage] = useState(0);
    const maxItems = global.items_max;
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(defaultFilter);

    // NOVY PRISPEVOK
    const [showCreate, setShowCreate] = useState(false);
    const [edit, setEdit] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DETAIL SKOLENIA
    const [showLesson, setShowLesson] = useState(false);

    const rowHeight = 100;
    const rowWidth = 850;
    const selectHeight = props.isSmall ? 40 : 0;

    const no_image = require('./react/app/no_image.jpg');

    // Aktuálna farebna téma
    const color = global.themes[props.theme];

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            db_get(0, defaultFilter);
        }

    }, []);

    const db_get = async (page, filter_) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'lessons_video', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter_
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems));

                setFilter(filter_);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = defaultFilter;

        if (txt.length > 1) {
            filter.search = txt;
            db_get(0, filter);
        }
        if (txt == '') {
            db_get(0, filter);
        }

    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id-video-start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }


    const SelectItem = (item) => {
        if (item.typ != global.lesson_canceled) {
            setItemSelected(item);
            setShowLesson(true);
        }
    }


    /*    
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faBookOpen} />
                        </div>
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? '45%' : '60%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>
                        {props.isSmall ? null :
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        }
                    </div>
                </div>

                <div style={{ ...styles.BlockRow, justifyContent: 'flex-end', alignItems: 'center', width: props.isSmall ? 160 : '30%', height: global.dialog_bar_height }}>
                    <TextField
                        value={search}
                        onInput={e => Search(e.target.value)}
                        size="small"
                        autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off"
                        style={{ width: props.isSmall ? 150 : 300 }}
                        placeholder={lang.search_in_category}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                            }
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                    <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                </IconButton>
                            </InputAdornment>,
                            style: { backgroundColor: global.theme_white, borderRadius: 30 },
                        }}
                        variant="outlined"
                    />
                </div>
                <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                    <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockCenter, width: props.width, height: props.height - global.dialog_bar_height - selectHeight, backgroundColor: global.theme_back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>

                            <div id={'id-video-start'} style={{ ...styles.BlockCenter, width: '98%' }}>
                                {/* *********************************************************************************************************  
                                            ZOZNAM SKOLENI
                                    ********************************************************************************************************* */}
                                {itemsFiltered != false ?
                                    itemsFiltered.map((item, index) => (
                                        <Paper onClick={() => SelectItem(item)} elevation={overID == item.id ? 5 : 2} key={item.id} style={{ ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: global.theme_gray, marginTop: 8, marginBottom: 8, borderRadius: global.row_radius, cursor: 'pointer' }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow }}>
                                                {props.isSmall ?
                                                    <div style={{ ...styles.BlockCenter, height: rowHeight, width: 80, justifyContent: 'center' }}>
                                                        <img src={item.image == false || item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', maxWidth: 60, maxHeight: 60, objectFit: 'contain', borderRadius: 10 }}></img>
                                                    </div>
                                                    :
                                                    <div style={{ ...styles.Block, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                        <img src={item.image == false || item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', maxWidth: rowHeight, maxHeight: rowHeight, objectFit: 'contain', borderRadius: global.row_radius }}></img>
                                                    </div>
                                                }
                                                {props.isSmall ?
                                                    <div style={{ ...styles.BlockLeft, height: rowHeight, width: '72%', justifyContent: 'center', marginLeft: 10 }}>
                                                        <div ostyle={{ ...styles.BlockLeft }}>
                                                            <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.title}</p>
                                                            <p style={{ ...styles.TextTiny, marginTop: 5, textAlign: 'left' }}>{GetDate(item.date)} {GetTimeHM(item.time)} {lang.hour_short}</p>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{ ...styles.BlockLeft, height: rowHeight, width: '87%', justifyContent: 'center', marginLeft: 10 }}>
                                                        <div style={{ ...styles.BlockLeft, height: rowHeight, justifyContent: 'center' }}>
                                                            <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.title}</p>
                                                            <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                                <p style={{ ...styles.TextTiny }}>{GetDate(item.date)} {GetTimeHM(item.time)} {lang.hour_short}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </Paper>
                                    )) :
                                    // ZIADNE POLOZKY                            
                                    <div style={{ ...styles.Block, paddingTop: 20 }}>
                                        <EmptyList lang={lang} />
                                    </div>
                                }

                                {/* PAGINATION */}
                                {pages > 1 ?
                                    <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                        <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                    </div>
                                    :
                                    <div style={{ ...styles.Block, minHeight: 50 }}></div>
                                }

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {showLesson == true && itemSelected.youtube_lin != '' ?
                <DialogVideo theme={props.theme} file={global.web_uploads + itemSelected.youtube_link} radius={props.radius} lang={props.lang} title={itemSelected.title} sub_title={'Video'} background={global.theme_dark_gray} func={() => setShowLesson(false)} />
                : null}

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError text={lang.db_error} offset={props.offset} theme={props.theme} func={ShowErrorPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Paper >
    );
}


