import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, ConvertToPrice, CopyData, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, GetDate, GetImageLink, GetPrice, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, ShowError, ShowOK, TemplateWindow, Today } from './items';
import { styles } from './styles';
import { Backdrop, Button, CircularProgress, IconButton, InputAdornment, Pagination, Paper, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCheckCircle, faEdit, faFilePdf, faL, faLayerGroup, faList, faListAlt, faQuestion, faSave, faShoppingBag, faShoppingBasket, faTrash, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Graph } from './graph';
import QRCode from "react-qr-code";

export const WebInfo = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);

    const apple = require('./react/app/apple-logo.png');
    const android = require('./react/app/android-logo.png');

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

        }

    }, []);


    const Press = () => {
        func(false);
    }


    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                {props.isSmall == true ? null :
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                }
                <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                    <div style={{ ...styles.BlockLeft, height: 45 }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>
                        <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                    </div>
                </div>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Press()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, width: props.width, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                    {/* *********************************************************************************************************  
                                BODY
                        ********************************************************************************************************* */}
                    <div style={{ ...styles.BlockCenter, paddingTop: 20 }}>
                        <div style={{ ...styles.Block, width: '50%' }}>
                            <p style={{ ...styles.TextXXLarge, fontWeight: '600' }}>{lang.web_page}</p>
                            <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.web_page_eshop}</p>
                            <div style={{ ...styles.Block, marginTop: 50 }}>
                                <div style={{ ...styles.Block, width: 200, height: 200 }}>
                                    <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                        value={lang.www_eshop + '/' + props.user.alias.toLowerCase()}
                                        viewBox={`0 0 256 256`}
                                    />
                                </div>
                                <p style={{ ...styles.TextNormal, marginTop: 20 }}>{lang.www_eshop + '/' + props.user.alias.toLowerCase()}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div >

            {
                isBusy == true ?
                    <Loading offset={props.offset} theme={props.theme}  ></Loading>
                    : null
            }

        </Paper >
    );
}
