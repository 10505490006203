import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, CopyData, Debug, DialogEnterText, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, GetImageLink, GetPrice, Init, LoadBonusNote, Loading, Menu, MenuTitle, MenuTop, PlusMinus, SaveBonusNote, ShowError, ShowOK, TemplateWindow, TextSearch } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, CircularProgress, FormControlLabel, IconButton, InputAdornment, MenuItem, Pagination, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCheckCircle, faInfo, faInfoCircle, faL, faLayerGroup, faList, faListAlt, faPen, faQuestion, faShoppingBasket, faShoppingCart, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';
import { ProductDialog } from './product_dialog';
import { ConvertToPrice } from './items';
import { IsEmpty } from './items';
import { notNumber } from './items';
import { Photos } from './photos';
import { DialogImagePreview } from './items';
import { FormError } from './items';
import { TextInput } from './items';
import { Graph } from './graph';
import { GetDate } from './items';


export const BonusBooking = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [isUpdating, setUpdating] = useState(false);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);

    // KATEGORIE
    const [categories, setCategories] = useState(false);
    const [subCategories, setSubCategories] = useState(false);
    const [subCategoryID, setSubCategoryID] = useState(0);

    // PRODUKTY
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const itemsMax = 24;

    // EDITING + NOVY PRODUKT
    const [itemSelected, setItemSelected] = useState(false);

    // SUMA
    const [status, setStatus] = useState(false);
    const [showStatus, setShowStatus] = useState(false);
    const [credit, setCredit] = useState(0);

    // KOSIK
    const [showCard, setShowCard] = useState(false);

    const menuWidth = props.isSmall ? 0 : 250;
    const rowHeight = props.isSmall ? 140 : 100;
    const rowWidth = 850;
    const selectHeight = props.isSmall ? 60 : 0;


    // COLORS
    const font_selected = global.theme_black;
    const font_disabled = global.theme_darker;

    const no_image = require('./react/app/no_image.jpg');

    let { func } = props;
    var lang = props.lang;
    const colors = global.themes[props.theme];

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;
            db_get();
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'bonus_categories', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    language: props.language,
                    //default: lang.products_my,
                    cabine: lang.cabine,
                    action: lang.action,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;
                tmp = tmp.filter(x => x.enabled == true);
                setCategories(tmp);

                if (tmp != false) {
                    db_products(tmp[0].id);
                    setIndex(tmp[0].id);
                }
            }
            //setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_products = async (category_id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'bonus', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    category_id: category_id,
                    language: props.language,
                    shopping_card: true
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                var tmp = json.products;

                if (tmp != false) {
                    tmp.sort((a, b) => {
                        if (a.label > b.label) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }

                setCredit(json.credit);
                setStatus(json.status);

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_search = async (search) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'bonus_search', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    search: search,
                    language: props.language,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.products;

                if (tmp != false) {
                    tmp.sort((a, b) => {
                        if (a.label > b.label) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                    setIndex(-1);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                    setIndex(-1);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_update = async (data) => {
        setUpdating(true);
        try {
            const response = await fetch(
                global.db_url + 'bonus_buy', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                })
            })

            const json = await response.json();
            
            setUpdating(false);
            if (json.ok > 0) {
                var product = items.find(x => x.id == data.product_id);
                if (product != undefined) {
                    product.quantity = data.quantity;
                }

                var product = itemsFiltered.find(x => x.id == data.product_id);
                if (product != undefined) {
                    product.quantity = data.quantity;
                }
            }

        } catch (error) {
            setUpdating(false);
            console.log(error);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ChangeIndex = (id) => {
        setIndex(id);
        setSearch('');
        db_products(id);
    }

    const CategorySelectResult = (id) => {
        Redraw(items, 0, search, id);
    }

    const Search = (text) => {
        setSearch(text);
        /*
        setSubCategoryID(0);
        Redraw(items, 0, text, 0);
        */
        if (text.trim().length > 2) {
            db_search(text.trim());
        }
        if (text == '') {
            setItems(false);
            setItemsFiltered(false);
            setIndex(-1);
        }
    }

    const Redraw = (items, p, search, sub_category) => {
        // REDRAW PRODUKTOV
        var data = items;
        if (search.trim() != '') {
            // filtrovanie podla hladaneho retazca
            var words = search.split(' ');
            data = items.filter(x => FindWords(x.label, x.keywords, words));
        } else {

            if (sub_category > 0) {
                // filtrovanie vybratej kategórie
                data = items.filter(x => parseInt(x.category_id) == sub_category);
            }

        }

        var count = data.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax)
        setItemsFiltered(data);

        if (count == 0) {
            setItemsFiltered(false);
        }

        setPage(p);
        GoToStart();
    }

    function FindWords(label, keywords, words) {
        // hladanie retazca v nazve a keywords
        var result = 0;
        words.forEach(word => {
            if (label.toLowerCase().includes(word.toLowerCase()) || keywords.toLowerCase().includes(word.toLowerCase())) {
                result += 1;
            }

        });
        // ak je pocet najdených slov == poctu hladaných slov -> je zhoda
        return words.length == result;
    }

    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);

        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0, '', 0);
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_, search, subCategoryID);
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_products');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const PlusMinusResult = (value, item) => {

        let data = {
            salon_id: props.user.id,
            product_id: item.id,
            quantity: value,
            quantity_original: value,
            sended: false,
            order_id: 0,
            cost_dph: item.cost_dph
        }
        
        db_update(data);
    }

    const ProductCardResult = () => {
        setShowCard(false);
        db_products(index);
    }

    // ------------------------------------------------------------------------------------
    // NAHLAD PRODUKTU
    // ------------------------------------------------------------------------------------

    const Edit = (item) => {
        setItemSelected(item);
    }


    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? '60%' : '50%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left', marginLeft: props.isSmall ? 10 : 0 }}>{props.title}</p>
                        <p style={{ ...styles.TextTiny, marginTop: props.isSmall == true ? 0 : 4, fontWeight: '600', textAlign: 'left', marginLeft: props.isSmall ? 10 : 0 }}>{lang.bonus_credit_quantity}: {credit}</p>
                        {props.isSmall == true ?
                            <Button onClick={() => setShowStatus(true)} style={{ ...styles.ButtonOutlinedSmall, color: global.theme_black, marginTop: 5, height: 26, marginLeft: 10, width: 150 }}>
                                {lang.bonus_credit_status_small}
                            </Button>
                            : null}
                    </div>
                </div>
                <div style={{ ...styles.Block, width: props.isSmall ? 160 : 300, height: global.dialog_bar_height, justifyContent: 'center' }}>
                    <TextSearch lang={props.lang} isSmall={props.isSmall} func={Search.bind(this)} />
                    {/*
                    <TextField
                        value={search}
                        onInput={e => Search(e.target.value)}
                        size="small"
                        autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off"
                        style={{ width: props.isSmall ? 150 : 280 }}
                        placeholder={lang.search_in_category}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                            }
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                    <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                </IconButton>
                            </InputAdornment>,
                            style: { backgroundColor: global.theme_white, borderRadius: 30 },
                        }}
                        variant="outlined"
                    />
                    */}
                </div>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 200, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <Button onClick={() => setShowStatus(true)} style={{ ...styles.ButtonBlue, width: 160, marginLeft: 20 }}>
                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faInfoCircle} />
                            {lang.bonus_credit_status}
                        </Button>
                    </div>
                }
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 250, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <Button onClick={() => setShowCard(true)} style={{ ...styles.ButtonBlue, width: 200, marginLeft: 20 }}>
                            {/*<FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faShoppingCart} />*/}
                            {isUpdating == true ?
                                <CircularProgress size={16} style={{ color: global.theme_white }} ></CircularProgress>
                                :
                                <p style={{ ...styles.TextTiny, color: global.theme_white }}>
                                    <FontAwesomeIcon style={{ height: 14, color: global.theme_white, marginRight: 10 }} icon={faShoppingBasket} />
                                    {lang.booking_preview}
                                </p>
                            }
                        </Button>
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                    {props.isSmall ?
                        <div style={{ ...styles.BlockRow, height: selectHeight, backgroundColor: global.theme_back }}>
                            <div style={{ ...styles.Block, height: selectHeight }}>
                                <Select
                                    value={index}
                                    onChange={event => ChangeIndex(event.target.value)}
                                    size='small'
                                    style={{ backgroundColor: global.theme_white, width: '100%' }}
                                >

                                    {categories != false ?
                                        categories.map((item) => (
                                            item.typ != 100 ?
                                                <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                                                : null
                                        ))
                                        : null}
                                </Select>
                            </div>
                            <div style={{ ...styles.Block, height: selectHeight }}>
                                <Button onClick={() => setShowCard(true)} style={{ ...styles.ButtonBlue, width: 190 }}>
                                    {isUpdating == true ?
                                        <CircularProgress size={16} style={{ color: global.theme_white }} ></CircularProgress>
                                        :
                                        <p style={{ ...styles.TextXTiny, color: global.theme_white }}>
                                            {lang.booking_preview}
                                        </p>
                                    }
                                </Button>
                            </div>
                        </div>
                        :
                        <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                            <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                {categories != false ?
                                    categories.map((item) => (
                                        <Button key={item.id} onClick={() => ChangeIndex(item.id)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == item.id ? global.theme_back : '#00000000', color: index == item.id ? font_selected : font_disabled }}>
                                            <div style={{ ...styles.BlockLeft }}>
                                                <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{item.label}</p>
                                                <p style={{ ...styles.TextTiny, color: global.themes[props.theme].theme_darker, marginTop: 2, lineHeight: 1, textAlign: 'left' }}>{item.brand}</p>
                                            </div>
                                        </Button>
                                    ))
                                    : null}

                            </div>
                        </div>
                    }
                    <div style={{ ...styles.BlockCenter, width: props.width - menuWidth, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                        {/* BODY */}
                        <div id={'id_products'}>
                        </div>
                        <div style={{ ...styles.Block, width: '98%', paddingBottom: 20 }}>
                            {/* *********************************************************************************************************  
                                ZOZNAM PRODUKTOV
                            ********************************************************************************************************* */}
                            {itemsFiltered != false ?
                                itemsFiltered.map((item, index) => (
                                    <Paper elevation={overID == item.id ? 5 : 2} key={item.id} style={{ ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: global.theme_lighter, marginTop: 5, marginBottom: 5, borderRadius: props.isSmall ? 20 : rowHeight / 2, cursor: 'pointer' }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow }}>
                                            {props.isSmall ?
                                                <div onClick={() => Edit(item)} style={{ ...styles.Block, height: rowHeight, width: 90, justifyContent: 'center' }}>
                                                    <img src={item.image == null || item.image == '' ? no_image : item.image.substring(0, 4) == 'http' ? item.image : global.web_images + '/' + item.image} style={{ width: '100%', height: '100%', width: 80, height: 80, objectFit: 'contain', borderRadius: 10, marginLeft: 5 }}></img>
                                                    {item.new == true ?
                                                        <div style={{ ...styles.Block, width: 80, height: 20, backgroundColor: global.theme_dark_red, borderRadius: 10, marginTop: 5 }}>
                                                            <p style={{ ...styles.TextXTiny, color: global.theme_white }}>{lang.product_news}</p>
                                                        </div>
                                                        : null}
                                                </div>
                                                :
                                                <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                    <img src={item.image == null || item.image == '' ? no_image : item.image.substring(0, 4) == 'http' ? item.image : global.web_images + '/' + item.image} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                                </div>
                                            }
                                            {props.isSmall ?
                                                <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextTiny, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                    <p style={{ ...styles.TextTiny, marginTop: 0, textAlign: 'left' }}>{item.code} {item.details}</p>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'left', marginTop: 10 }}>{lang.bonus_credit_quantity}</p>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'left', marginTop: 0 }}>{GetPrice(item.cost_dph)}</p>
                                                </div>
                                                :
                                                <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                    <div style={{ ...styles.BlockRowRaw }}>
                                                        <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                        {item.new == true ?
                                                            <div style={{ ...styles.Block, width: 90, height: 20, backgroundColor: global.theme_dark_red, borderRadius: 10, marginLeft: 10 }}>
                                                                <p style={{ ...styles.TextXTiny, color: global.theme_white }}>{lang.product_news}</p>
                                                            </div>
                                                            : null}
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                        <p style={{ ...styles.TextTiny, marginTop: 0 }}>{item.code} {item.details}</p>
                                                        <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                            <div style={{ ...styles.BlockLeft, width: '40%' }}>
                                                                <p style={{ ...styles.TextTiny }}>{lang.bonus_credit_quantity}: {GetPrice(item.cost_dph)}</p>
                                                                {item.limited > 0 ?
                                                                    <p style={{ ...styles.TextTiny, textAlign: 'left', marginTop: 0 }}>{lang.bonus_order_max}: {item.limited}</p>
                                                                    : null}
                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: '40%' }}>
                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: '20%' }}>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div style={{ ...styles.Block, height: rowHeight, width: 180, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny }}>{lang.booking_pieces}</p>
                                                <PlusMinus data={item} value={item.quantity} max={item.code.substring(0, 1).toLowerCase() == 'v' ? 1 : item.limited == 0 ? 999999 : item.limited} func={PlusMinusResult.bind(this)} />
                                            </div>
                                        </div>
                                    </Paper>
                                )) :
                                // ZIADNE PRODUKTY                            
                                <div style={{ ...styles.Block, paddingTop: 20 }}>
                                    <EmptyList lang={lang} />
                                </div>
                            }

                            {itemsFiltered != false ?
                                pageMax > 1 ?
                                    <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                        <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                    </div>
                                    : <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>1/1</div>
                                : null}
                        </div>
                    </div>
                </div>
            </div>


            {showStatus == true ?
                <CreditStatus status={status} isSmall={props.isSmall} offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} lang={lang} radius={props.radius} language={props.language} func={() => setShowStatus(false)} />
                : null}

            {showCard == true ?
                <BonusBookingCard isSmall={props.isSmall} offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} lang={lang} radius={props.radius} backcolor={true} background={true} language={props.language} func={ProductCardResult.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Paper>
    );
}

export const BonusBookingCard = (props) => {

    const [isBusy, setBusy] = useState(false);
    const [isUpdating, setUpdating] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [showCheckout, setShowCheckout] = useState(false);
    const [items, setItems] = useState(false);

    // poznámka k objednávke
    const [note, setNote] = useState(LoadBonusNote());
    const [showNote, setShowNote] = useState(false);
    const [dph, setDph] = useState(1);

    // Suma, Kredit
    const [sum, setSum] = useState(0);
    const [minSum, setMinSum] = useState(100000);
    const [creditBefore, setCreditBefore] = useState(0);
    const [credit, setCredit] = useState(0);

    // cislo objednavky
    const [number, setNumber] = useState('');

    // objednávka odoslana
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // Rozmery okna    
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1000 ? 1000 : width;
    const dialogHeight = height >= 768 ? 768 : height;
    const errorHeight = 40;
    const buttonHeight = 80;
    const rowHeight = props.isSmall ? 160 : 100;


    const no_image = require('./react/app/no_image.jpg');


    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            db_get();

        }
        return () => {
        };

    }, []);

    const db_get = async () => {
        
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'bonus_shopping_card', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language,
                    salon_id: props.user_id,
                })
            })

            const json = await response.json();
            
            if (json.ok > 0) {
                setItems(json.items);
                setMinSum(json.min_sum);
                setNumber(json.number);
                setCreditBefore(json.credit);
                setCredit(json.sums.credit);
                setSum(json.sums.sum);
                setDph(json.dph);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'bonus_buy', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                })
            })

            const json = await response.json();
            
            if (json.ok > 0) {
                /*
                var tmp = items.find(x => x.product_id == data.product_id);
                if (tmp != undefined) {
                    tmp.quantity = data.quantity
                }
                */
                setItems(json.sums.products);
                setSum(json.sums.sum);
                setCredit(json.sums.credit);
                setRedraw(!redraw);
                
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_checkout = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'bonus_checkout', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language,
                    data: data,

                    // PREKLADY TEXTOV
                    lang: {
                        money: lang.money,
                        label: lang.bonus_email_label,
                        do_not_reply: lang.do_not_reply,
                        quantity: lang.quantity,
                        code: lang.code_,
                        product_name: lang.product_name,
                        quantity: lang.email_table_quantity,
                        quantity_from_credit: lang.email_table_quantity_from_credit,
                        quantity_to_pay: lang.email_table_quantity_to_pay
                    }
                })
            })

            const json = await response.json();
            
            if (json.ok > 0) {
                setShowOK(true);
                SaveBonusNote('');
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = () => {
        func();
    }


    const PlusMinusResult = (value, item) => {
        
        let data = {
            salon_id: props.user.id,
            product_id: item.product_id,
            quantity: value,
            sended: false,
            booking_id: 0,
            quantity_original: value,
            cost: item.cost_price
        }

        db_update(data);
    }

    const CheckoutResult = (typ) => {
        setShowCheckout(false);
        if (typ == true) {
            let data = {
                number: number,
                language: props.language,
                salon_id: props.user.id,
                agent_id: props.user.agent_id,
                status: 0,
                note: note,
                credit: creditBefore,
                credit_left: credit
            }

            db_checkout(data);
        }
    }

    const DialogEnterTextResult = (typ, text) => {
        setShowNote(false);
        if (typ == true) {
            setNote(text);
            SaveBonusNote(text);
        }
    }

    const DialogOKResult = () => {
        setShowOK(false);
        Press();
    }

    const DialogErrorResult = () => {
        setShowError(false);
        Press();
    }

    const ChangeItem = (item) => {
        let value = item.credit;
        if (item.credit == false) {
            value = true;
        } else {
            value = false;
        }

        item.credit = value;

        let data = {
            salon_id: props.user.id,
            product_id: item.product_id,
            quantity: item.quantity,
            credit: value
        }

        db_update(data);

        setRedraw(!redraw);
    }

    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    {props.isSmall ? null :
                        < div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                            <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                                <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faShoppingBasket} />
                            </div>
                        </div>
                    }
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: '35%', height: global.dialog_bar_height }}>
                            <div style={{ ...styles.BlockLeft, height: 45, justifyContent: 'center' }}>
                                <p style={{ ...styles.TextDialogLabel }}>{props.lang.bonus_order_preview}</p>
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.lang.products}</p>
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.Block, width: 220, height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                        <div style={{ ...styles.Block, height: global.dialog_bar_height - 8, backgroundColor: global.theme_white, borderRadius: props.radius }}>
                            <p style={{ ...styles.TextTiny }}>{props.lang.bonus_left}</p>
                            {isUpdating == false ?
                                <div style={{ ...styles.Block, height: 24 }}>
                                    <p style={{ ...props.isSmall ? styles.TextTiny : styles.TextNormal, marginTop: 5, fontWeight: '600' }}>{GetPrice(ConvertToPrice(credit))}</p>
                                </div>
                                : <div style={{ ...styles.Block, height: 24 }}>
                                    <CircularProgress size={16}></CircularProgress>
                                </div>
                            }
                        </div>
                    </div>

                    <div style={{ ...styles.Block, width: 220, height: global.dialog_bar_height, marginLeft: 10 }}>
                        <div style={{ ...styles.Block, height: global.dialog_bar_height - 8, backgroundColor: global.theme_white, borderRadius: props.radius }}>
                            {isUpdating == false ?
                                <div style={{ ...styles.Block, height: global.dialog_bar_height - 8 }}>
                                    <p style={{ ...styles.TextTiny }}>{props.lang.bonus_missing}</p>
                                    <p style={{ ...props.isSmall ? styles.TextTiny : styles.TextNormal, marginTop: 5, fontWeight: '600' }}>{GetPrice(ConvertToPrice(sum))}</p>
                                </div>
                                : <div style={{ ...styles.Block, height: global.dialog_bar_height - 8 }}>
                                    <CircularProgress size={16}></CircularProgress>
                                </div>
                            }
                        </div>
                    </div>

                    <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? global.theme_back : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '100%', height: dialogHeight - global.dialog_bar_height - buttonHeight }}>
                        {/* BODY */}
                        <div style={{ ...styles.BlockCenter, width: '100%', height: dialogHeight - global.dialog_bar_height - buttonHeight, overflowY: 'scroll', paddingTop: 10, paddingBottom: 10 }}>
                            {props.isSmall ?
                                <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                                    <p style={{ ...styles.TextDialogLabel }}>{props.lang.bonus_order_preview}</p>
                                </div>
                                : null}


                            {sum > 0 ?
                                <Paper elevation={2} style={{ ...styles.Block, width: '96%', backgroundColor: global.theme_dark_red, paddingTop: 10, paddingBottom: 10, marginTop: 5, marginBottom: 5, borderRadius: 10 }}>
                                    <p style={{ ...styles.TextXSmall, fontWeight: '600', color: global.theme_white }}>{lang.bonus_order_missing} !</p>
                                    <p style={{ ...styles.TextXSmall, marginTop: 5, color: global.theme_white }}>{lang.bonus_order_missing_note}</p>
                                </Paper>
                                : null}


                            {note != '' ?
                                <Paper elevation={2} style={{ ...styles.Block, width: '96%', minHeight: rowHeight, backgroundColor: global.theme_lighteen_red, paddingTop: 10, paddingBottom: 10, marginTop: 5, marginBottom: 5, borderRadius: 10 }}>
                                    <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.eshop_note}:</p>
                                    <p style={{ ...styles.TextTiny, marginTop: 5 }}>{note}</p>
                                </Paper>
                                : null}

                            {/* *********************************************************************************************************  
                                ZOZNAM PRODUKTOV
                            ********************************************************************************************************* */}
                            {items != false ?
                                items.map((item, index) => (
                                    <Paper elevation={2} key={item.id} style={{ ...styles.Block, width: '96%', height: rowHeight, backgroundColor: global.theme_lighter, marginTop: 5, marginBottom: 5, borderRadius: props.isSmall ? 20 : rowHeight / 2 }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            {props.isSmall ?
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: 90, justifyContent: 'center' }}>
                                                    <img src={item.image == null || item.image == '' ? no_image : item.image.substring(0, 4) == 'http' ? item.image : global.web_images + '/' + item.image} style={{ width: '100%', height: '100%', width: 80, height: 80, objectFit: 'contain', borderRadius: 10, marginLeft: 5 }}></img>
                                                </div>
                                                :
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                    <img src={item.image == null || item.image == '' ? no_image : item.image.substring(0, 4) == 'http' ? item.image : global.web_images + '/' + item.image} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                                </div>
                                            }
                                            {props.isSmall ?
                                                <div style={{ ...styles.BlockLeft, minHeight: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextTiny, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                    <p style={{ ...styles.TextTiny, marginTop: 10, textAlign: 'left' }}>{item.code} <span style={{ ...styles.TextTiny }}>{item.details}</span></p>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.bonus_credit_quantity}: {GetPrice(item.cost_dph)}</p>
                                                    {item.credit == true ?
                                                        <div style={{ ...styles.Block, alignItems: 'flex-start', marginTop: 5 }}>
                                                            <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.bonus_from_credit_pieces}: {item.credit_quantity}</p>
                                                            <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{item.quantity - item.credit_quantity > 0 ? lang.bonus_missing + ': ' + (item.quantity - item.credit_quantity) + ' ' + lang.pieces : ''}</p>
                                                        </div>
                                                        : null}
                                                    {item.limited > 0 ?
                                                        <Chip size='small' label={lang.bonus_order_limited} style={{ backgroundColor: global.theme_dark_red, color: global.theme_white, marginTop: 10 }} />
                                                        : null}
                                                    {item.quantity_ordered != undefined ?
                                                        <p style={{ ...styles.TextTiny, textAlign: 'left', marginTop: 5 }}>{lang.bonus_order_quantity_history}: {item.quantity_ordered} {lang.pieces}</p>
                                                        : null}
                                                </div>
                                                :
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                    <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                        <p style={{ ...styles.TextTiny, marginTop: 0 }}>{item.code} <span style={{ ...styles.TextTiny }}>{item.details}</span></p>
                                                        <div style={{ ...styles.BlockRow, marginTop: 7 }}>
                                                            <div style={{ ...styles.BlockLeft, width: '60%' }}>
                                                                <p style={{ ...styles.TextTiny }}>{lang.bonus_credit_quantity}: {GetPrice(item.cost_dph)}</p>
                                                                {item.credit == true ?
                                                                    <p style={{ ...styles.TextTiny }}>{lang.bonus_from_credit_pieces}: {item.credit_quantity} {item.quantity - item.credit_quantity > 0 ? ' / ' + lang.bonus_missing.toLowerCase() + ': ' + (item.quantity - item.credit_quantity) + ' ' + lang.pieces : ''}</p>
                                                                    : null}

                                                            </div>
                                                            {/*
                                                            <div style={{ ...styles.BlockLeft, width: '40%' }}>
                                                            </div>
                                                            */}
                                                            <div style={{ ...styles.Block, width: '40%' }}>
                                                                {item.limited > 0 ?
                                                                    <Chip size='small' label={lang.bonus_order_limited} style={{ backgroundColor: global.theme_dark_red, color: global.theme_white }} />
                                                                    : null}

                                                                {item.quantity_ordered != undefined ?
                                                                    <p style={{ ...styles.TextTiny, marginTop: 2 }}>{lang.bonus_order_quantity_history}: {item.quantity_ordered} {lang.pieces}</p>
                                                                    : null}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div style={{ ...styles.Block, height: rowHeight, width: 180, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, marginBottom: 5 }}>{lang.booking_pieces}</p>
                                                <PlusMinus data={item} value={item.quantity} max={item.code.substring(0, 1).toLowerCase() == 'v' ? 1 : item.limited == 0 ? 999999 : item.limited} func={PlusMinusResult.bind(this)} />
                                                {/* props.isSmall == true ?
                                                    <div style={{ ...styles.Block, marginTop: 15 }}>
                                                        <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.bonus_from_credit}</p>}
                                                            control={<Checkbox checked={item.credit == 0 ? false : true} size={'small'} onChange={() => ChangeItem(item)} />}
                                                        />
                                                    </div>
                                                : null */}
                                            </div>
                                        </div>
                                    </Paper>
                                )) :
                                // ZIADNE PRODUKTY                            
                                <div style={{ ...styles.Block, paddingTop: 20 }}>
                                    <EmptyList lang={lang} />
                                </div>
                            }

                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '100%', height: buttonHeight, backgroundColor: global.themes[props.theme].theme_lighter, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                        {parseFloat(minSum) <= parseFloat(sum) ? items.length > 0 ?
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, width: '30%' }}>
                                    <Button onClick={() => setShowNote(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: props.isSmall ? 170 : 220, marginLeft: 20 }}>
                                        {props.isSmall ? null :
                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPen} />
                                        }
                                        <p style={{ ...styles.TextTiny, color: global.theme_white }}>{note.trim() == '' ? lang.booking_note : lang.booking_note_edit}</p>
                                    </Button>
                                </div>
                                <div style={{ ...styles.BlockRight, width: '70%' }}>
                                    {props.user.agent_id > 0 ?
                                        sum == 0 ?
                                            <Button onClick={() => setShowCheckout(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: props.isSmall ? 170 : 220, marginRight: 20 }}>
                                                <p style={{ ...styles.TextTiny, color: global.theme_white }}>{props.isSmall ? lang.send : lang.booking_checkout}</p>
                                                {props.isSmall ? null :
                                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginLeft: 10 }} icon={faAngleRight} />
                                                }
                                            </Button>
                                            : null
                                        :
                                        <p style={{ ...styles.TextSmall, marginRight: 30 }}>{props.lang.agent_error}</p>
                                    }
                                </div>
                            </div>
                            : null
                            :
                            <p style={{ ...styles.TextSmall, fontWeight: '500' }}>{lang.booking_min_sum} {minSum} {lang.money}</p>
                        }
                    </div>
                </div>
            </Paper>

            {
                showNote == true ?
                    <DialogEnterText theme={props.theme} rows={9} radius={props.radius} title={lang.booking_note} sub_title={''} text={note} icon={faPen} button={props.lang.save} func={DialogEnterTextResult.bind(this)} />
                    : null
            }

            {
                showOK == true ?
                    <DialogInfo theme={props.theme} radius={props.radius} title={lang.booking_sended_label} sub_title={''} text={lang.booking_sended_text} icon={faInfo} button={props.lang.close} icon_show={faCheckCircle} icon_color={global.theme_darker_green} func={DialogOKResult.bind(this)} />
                    : null
            }

            {
                showError == true ?
                    <DialogInfo theme={props.theme} radius={props.radius} title={lang.booking_error_label} sub_title={''} text={lang.booking_error_text} icon={faInfo} button={props.lang.close} icon_show={faXmark} icon_color={global.theme_dark_red} func={DialogErrorResult.bind(this)} />
                    : null
            }

            {
                showCheckout == true ?
                    <DialogYesNo theme={props.theme} radius={props.radius} title={lang.booking_checkout + '?'} sub_title={''} text={lang.booking_checkout_question} sub_text={note.trim() == '' ? lang.booking_note_not_included : lang.booking_note_included} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={CheckoutResult.bind(this)} />
                    : null
            }

            {
                isBusy == true ?
                    <Loading offset={props.offset} theme={props.theme} ></Loading>
                    : null
            }

        </Backdrop >
    );
}


export const CreditStatus = (props) => {
    /*
            STAV KREDITOV
    */

    const status = props.status;

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 700;
    const dialogHeight = 500;
    const rowOffset = 20;
    const errorHeight = 40;
    const buttonHeight = 80;

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {
        return () => {
        };

    }, []);

    const Close = () => {
        func(false);
    }


    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.bonus_credit_status}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.bonus_booking}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.Block, width: '94%', height: dialogHeight - global.dialog_bar_height - buttonHeight }}>
                        {/* BODY */}
                        <div style={{ ...styles.Block, paddingTop: 15, paddingBottom: 15, backgroundColor: global.theme_white, borderRadius: 10 }}>
                            <p style={{ ...styles.TextLarge, fontWeight: '600' }}>{lang.bonus_credit_year}: {props.status.credit_year}</p>
                            <p style={{ ...styles.TextXSmall, marginTop: 2, color: global.theme_dark_gray }}>{lang.bonus_credit_year_note1}</p>
                            <div style={{ ...styles.Block, width: '90%', height: 1, backgroundColor: global.theme_light_gray, marginTop: 6 }}></div>
                            <p style={{ ...styles.TextSmall, marginTop: 6, fontWeight: '600' }}>{lang.bonus_credit_year_left}: {props.status.credit_left}</p>
                            <p style={{ ...styles.TextXSmall, marginTop: 2, color: global.theme_dark_gray }}>{lang.bonus_credit_year_left_note}</p>
                        </div>
                        <div style={{ ...styles.Block, marginTop: 20 }}>
                            <p style={{ ...styles.TextXSmall }}>{lang.bonus_credit_year_note2}</p>
                            <p style={{ ...styles.TextXSmall }}>{lang.bonus_credit_year_note3.replace('@n', status.periode_months)}</p>
                            <p style={{ ...styles.TextXSmall, marginTop: 10 }}>{lang.bonus_credit_periode.replace('@n', parseFloat(status.credit_period).toFixed(2))}</p>
                            <p style={{ ...styles.TextXSmall }}>{lang.bonus_credit_periode_left}: {props.status.periode_left}</p>
                        </div>
                        <div style={{ ...styles.Block, marginTop: 20 }}>
                            <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{lang.bonus_credit_actual}: {props.status.credit}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <Button onClick={() => Close()} style={{ ...styles.ButtonDark }}>{lang.close}</Button>
                    </div>
                </div>
            </Paper>
        </Backdrop>
    );
}

