/*
*
* =================================================================================================================
* REKLAMACIA - zobrazenie na webe
* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { DialogImagePreview, GetDate, GetImageLink, GetLanguage, Init, Loading, LoadLanguage, LoadLogin, Menu, MenuTitle, MenuTop, ResetLogin, SaveLanguage } from './items';
import { styles } from './styles';
import './items.css';

// IMAGES
import img_background from './react/app/background.jpg';
import { Paper, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Report = () => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);

    // APLIKACIA
    const [image, setImage] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const [language, setLanguage] = useState(0);

    // VZHLAD APKY
    const [offset, setOffset] = useState(16);
    const [radius, setRadius] = useState(16);


    // SALON - AGENT - NASTAVENIA
    const [salon, setSalon] = useState(false);
    const [agent, setAgent] = useState(false);

    // DETAILY OBJEDNAVKY
    const [order, setOrder] = useState(false);
    const [reklamacia, setReklamacia] = useState(false);
    const [products, setProducts] = useState(false);
    const [images, setImages] = useState(false);


    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    //const isLarge = useMediaQuery('(min-width:' + global.device_medium + 1 + 'px)');
    //const isMedium = useMediaQuery('(min-width:' + global.device_small + 1 + 'px; max-width:' + global.device_medium + 1 + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');

    const imageSize = 275;
    const rowHeight = 80;
    const rowWidth = 900;
    const rowOffset = 35;

    var lang = GetLanguage(language);

    const no_image = require('./react/app/no_image.jpg');

    var started = false;
    let params = useParams();

    useEffect(() => {

        if (started == false) {
            started = true;

            if (window.innerWidth <= global.device_small) {
                // MOBILE
                setRadius(0);
                setOffset(0);
            } else {
                // DESKTOP
                setRadius(global.radius);
                setOffset(global.offset);

                // RELOAD PAGE - AK SA ZMENI VELKOST OKNA               
                function handleResize() {
                    setWidth(window.innerWidth);
                    setHeight(window.innerHeight);
                }
                //window.addEventListener('resize', handleResize);
            }

            setWidth(window.innerWidth);
            setHeight(window.innerHeight);

            db_get();
        }

    }, [window.innerWidth])

    const db_get = async () => {

        if (isBusy == false) {
            if (isBusy == true) {
                setBusy(true);
            }
            try {
                const response = await fetch(
                    global.db_url + 'report', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        booking_id: params.id
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    setLanguage(json.language);
                    setSalon(json.salon);
                    setAgent(json.agent);
                    setReklamacia(json.reklamacia);
                    setOrder(json.order);
                    setImages(json.images);
                    setProducts(json.products);

                }
                setBusy(false);

            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }
    }

    const Preview = (item) => {
        let data = {
            file: item.path + item.file
        }
        setImage(data);
        setShowImage(true);
    }

    return (
        <div style={{ ...styles.BlockCenter, height: height, width: '100%', fontFamily: 'roboto', backgroundColor: global.theme_light_gray, overflowY: 'scroll' }}>
            <div style={{ ...styles.Block, maxWidth: rowWidth, backgroundColor: global.theme_white, paddingTop: 0, paddingBottom: 40 }}>
                {order != false ?
                    <div style={{ ...styles.Block, backgroundColor: global.theme_dark_blue, paddingTop: 20, paddingBottom: 20 }}>
                        <div style={{ ...styles.BlockRow, width: '98%' }}>
                            <div style={{ ...styles.BlockLeft, width: '50%' }}>
                                <p style={{ ...styles.TextXXLarge, fontWeight: '600', color: global.theme_white }}>{lang.booking_reklamacia}</p>
                                <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.booking_reklamacia_status}: {lang.reklamacia_status[reklamacia.status]}</p>
                            </div>
                            <div style={{ ...styles.BlockRight, width: '50%' }}>
                                <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.number.toLowerCase()}: <b>{reklamacia.number}</b></p>
                                <p style={{ ...styles.TextTiny, color: global.theme_white, marginTop: 5 }}>{lang.date.toLowerCase()}: {GetDate(reklamacia.date)}</p>
                            </div>
                        </div>
                    </div>
                    : null}

                <div style={{ ...styles.BlockRow, width: '98%' }}>
                    <div style={{ ...styles.BlockLeft, width: '50%' }}>
                        {salon != false ?
                            <div style={{ ...styles.BlockLeft, width: '98%', marginTop: 20 }}>
                                <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.client}:</p>
                                <p style={{ ...styles.TextTiny, marginTop: 5 }}>{salon.company}</p>
                                <p style={{ ...styles.TextTiny }}>{salon.name} {salon.surname}</p>
                                <p style={{ ...styles.TextTiny }}>{salon.street} {salon.street_number}</p>
                                <p style={{ ...styles.TextTiny }}>{salon.psc} {salon.town}, {lang.language}</p>
                                <p style={{ ...styles.TextTiny, marginTop: 10 }}>{lang.email_}: {salon.email}</p>
                                <p style={{ ...styles.TextTiny }}>{lang.mobil.toLowerCase()}: {salon.mobil}</p>
                            </div>
                            : null}
                    </div>
                    <div style={{ ...styles.BlockLeft, width: '50%' }}>
                        {salon != false ?
                            <div style={{ ...styles.BlockLeft, width: '98%', marginTop: 20 }}>
                                <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.agent}:</p>
                                <p style={{ ...styles.TextTiny, marginTop: 5 }}>{agent.name} {agent.surname}</p>
                                <p style={{ ...styles.TextTiny, marginTop: 10 }}>{lang.email_}: {agent.email}</p>
                                <p style={{ ...styles.TextTiny }}>{lang.mobil.toLowerCase()}: {agent.mobil}</p>
                            </div>
                            : null}
                    </div>
                </div>

                {reklamacia != false ?
                    <div style={{ ...styles.BlockLeft, width: '98%', marginTop: rowOffset }}>
                        <p style={{ ...styles.TextTiny }}>{lang.booking_number}: {order.number}</p>
                        <p style={{ ...styles.TextTiny }}>{lang.booking_date}: {GetDate(order.date)}</p>
                        <p style={{ ...styles.TextTiny, marginTop: 20, fontWeight: '600' }}>{lang.reklamacia_reason}: {reklamacia.reason}</p>
                        <p style={{ ...styles.TextTiny, marginTop: 20, fontWeight: '600' }}>{lang.booking_reklamacia_text}:</p>
                        <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{reklamacia.message}</p>
                    </div>
                    : null}

                {products != false ?
                    <div style={{ ...styles.Block, marginTop: rowOffset }}>
                        <p style={{ ...styles.TextLarge, fontWeight: '600' }}>{lang.booking_reklamacia_products}</p>

                        {products.map((item, index) => (
                            <div key={item.id} style={{ ...styles.Block }}>
                                <Paper elevation={2} key={item.id} style={{ ...styles.Block, width: '96%', height: rowHeight, backgroundColor: global.theme_lighter, marginTop: 5, marginBottom: 5, borderRadius: rowHeight / 2 }}>
                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                            <img src={item.image == null || item.image == '' ? no_image : item.image.substring(0, 4) == 'http' ? item.image : global.web_images + '/' + item.image} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                        </div>
                                        <div style={{ ...styles.BlockLeft, height: rowHeight, width: '75%', justifyContent: 'center', marginLeft: 10 }}>
                                            <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                            <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                <p style={{ ...styles.TextTiny, marginTop: 0 }}>{item.code} <span style={{ ...styles.TextTiny }}>{item.details}</span></p>
                                                <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                    <div style={{ ...styles.BlockLeft, width: '40%' }}>
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, width: '40%' }}>

                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, width: '20%' }}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ ...styles.BlockRight, height: rowHeight, width: 250, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextTiny, marginRight: 25 }}>{lang.booking_product_ordered}:</p>
                                            <p style={{ ...styles.TextTiny, marginRight: 25, marginTop: 5 }}><b>{item.quantity}</b> {lang.pieces}</p>
                                        </div>

                                    </div>
                                </Paper>
                            </div>
                        ))}
                    </div>
                    : null}

                {images != false ?
                    <div style={{ ...styles.Block, marginTop: rowOffset }}>
                        <p style={{ ...styles.TextLarge, fontWeight: '600' }}>{lang.photos}</p>
                        <div style={{ ...styles.Block, flexDirection: 'row', flexWrap: 'wrap' }}>
                            {images.map((item, index) => (
                                <Paper onClick={() => Preview(item)} elevation={2} key={item.id} style={{ ...styles.Block, width: imageSize, margin: 10, cursor: 'pointer' }}>
                                    <img src={GetImageLink(item.path + item.file)} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain' }}></img>
                                </Paper>
                            ))}
                        </div>
                    </div>
                    : null}

            </div>

            {showImage == true ?
                <DialogImagePreview theme={0} file={GetImageLink(image.file)} radius={radius} lang={lang} title={lang.preview} sub_title={lang.photo} background={global.theme_dark_gray} crop={false} func={() => setShowImage(false)} />
                : null}

            {isBusy == true ?
                <Loading screenWidth={width} offset={offset} center={true} />
                : null}


        </div >
    );
}


