import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { ConvertToPrice, CopyData, Debug, DialogAgreements, DialogEmployee, DialogInfo, DialogLogin, DialogPasswordChange, DialogYesNo, FormError, GetLanguage, GetTid, Init, IsEmpty, Loading, Menu, MenuTitle, MenuTop, nonEmail, ResetLogin, ShowError, ShowOK, ShowTokenError, TemplateWindow, TextInput } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, FormControlLabel, IconButton, MenuItem, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCheck, faCheckCircle, faCog, faList, faPen, faPlus, faQuestion, faSave, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';


export const RegisterSalon = (props) => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);
    const [loading, setLoading] = useState(true);
    const [languages, setLanguages] = useState(false);
    const [language, setLanguage] = useState(0);
    const [country, setCountry] = useState(false);
    const [typ, setTyp] = useState(0);

    const [index, setIndex] = useState(-1);
    const [showLogout, setShowLogout] = useState(false);
    const [alias, setAlias] = useState(-1);

    const [error, setError] = useState(false);
    const [edit, setEdit] = useState(false);

    // ZAKLADNE UDAJE + NASTAVENIA
    const [userData, setUserData] = useState();
    const [gdpr, setGDPR] = useState(false);
    const [showGDPR, setShowGDPR] = useState(false);
    const [showVOP, setShowVOP] = useState(false);

    // OBCHODNY ZASTUPCA
    const [agent, setAgent] = useState(0);
    const [agentsItems, setAgentsItems] = useState(false);
    const [agents, setAgents] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [dialogHeight, setDialogHeight] = useState(props.isSmall ? height : 380);
    const dialogWidth = props.isSmall ? width : 1000;
    const gdprHeight = props.isSmall ? 170 : 100;
    const dialog_bar_height = global.dialog_bar_height - 20;

    const [showTokenError, setShowTokenError] = useState(false);

    let { func } = props;
    var lang = GetLanguage(language);

    const menu_width = 200;
    const rowOffset = 20;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;
            db_user();
        }
    }, [])


    const db_user = async () => {
        try {
            const response = await fetch(
                global.db_url + 'user', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.user.id,
                    token: props.token
                })
            })

            const json = await response.json();

            setLoading(false);
            if (json.ok > 0) {
                if (json.user != false) {
                    setUserData(json.user);
                    var usr = json.user;
                    /*
                    usr.name = 'Magdaléna';
                    usr.surname = 'Testovacia';
                    usr.mobil = '+421123445678';
                    usr.salon_name = 'Studio beauty face';
                    usr.salon_street = 'Hviezdoslavova';
                    usr.salon_street_number = '123456/456';
                    usr.salon_psc = '951 01';
                    usr.salon_town = ' Banská Bystrica';
                    usr.company = 'Studio beauty, s.r.o.';
                    usr.street = 'Kpt. Nálepku';
                    usr.street_number = '1234/999';
                    usr.psc = '951 02';
                    usr.town = 'Veľké Kostolany';
                    usr.ico = '123456789';
                    usr.dic = '123456789';
                    usr.ic_dph = 'SK-1234567890';
                    usr.account = 'SK000000123400001234';
                    usr.alias = 'test-salon';
                    */
                    setUserData(usr);

                    var tmp = json.languages;
                    setLanguages(tmp);
                    setLanguage(json.user.language);

                    tmp = tmp.find(x => x.language_id == props.language);
                    setCountry(tmp);

                    setAlias(json.user.alias);

                    setAgentsItems(json.agents);
                    tmp = json.agents;
                    //tmp = json.agents.filter(x => x.language == json.user.language);
                    setAgents(tmp);

                    setIndex(0);
                }
            } else {
                setShowTokenError(true);
            }


        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }


    const db_alias_check = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'alias_check', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.user.id,
                    alias: alias
                })
            })

            const json = await response.json();

            setBusy(false);
            if (json.ok > 0) {
                if (json.count > 0) {
                    setError(lang.alias_error);
                } else {
                    setIndex(index + 1);
                }
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_user_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'user_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            setBusy(false);
            if (json.ok > 0) {
                Press(true);
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (value) => {
        func(value);
    }

    function Check(index_) {
        var error = 0;
        setError('');

        if (index_ == 1) {
            error += IsEmpty(userData.name) == true ? true : false;
            error += IsEmpty(userData.surname) == true ? true : false;
            error += IsEmpty(userData.email) == true ? true : false;
            //error += nonEmail(userData.email) == true ? true : false;
        }

        if (index_ == 2) {
            error += IsEmpty(userData.salon_name) == true ? true : false;
            error += IsEmpty(userData.salon_street) == true ? true : false;
            error += IsEmpty(userData.salon_psc) == true ? true : false;
            error += IsEmpty(userData.salon_town) == true ? true : false;
        }

        if (index_ == 3) {
            error += IsEmpty(userData.company) == true ? true : false;
            error += IsEmpty(userData.street) == true ? true : false;
            error += IsEmpty(userData.psc) == true ? true : false;
            error += IsEmpty(userData.town) == true ? true : false;
            error += IsEmpty(userData.ico) == true ? true : false;
        }

        if (index_ == 4) {
            error += IsEmpty(userData.account) == true ? true : false;
        }

        if (index_ == 5) {
            error += IsEmpty(alias) == true ? true : false;
        }

        if (index_ == 6) {

            if (agent == 0) {
                error = 1;
                setError(lang.required);
            }
        }


        if (error > 0 && index_ < 6) {
            setError(lang.required_red);
        }

        return error;
    }

    const ChangeIndex = (index_) => {
        if (index > index_) {
            setIndex(index_);
        }
    }

    const ChangeCountry = (item) => {
        setLanguage(item.language_id);
        setCountry(item);
        var tmp = agentsItems.filter(x => x.language == item.language_id);
        setAgents(tmp);
        setAgent(0);
    }

    const ChangeAlias = (txt) => {
        var text = GetTid(txt.trim());
        setAlias(text.toLowerCase());
    }


    const Prev = () => {
        var value = index - 1;
        if (typ == 1) {
            if (value == 5) {
                value = 3;
            }
        }
        if (value == 7) {
            setDialogHeight(props.isSmall ? height : 500);
        } else {
            setDialogHeight(props.isSmall ? height : 380);
        }

        setError('');
        setIndex(value);
    }

    const Next = () => {

        setError('');
        var value = index + 1;
        if (typ == 1) {
            if (value == 4) {
                value = 6;
            }
        }
        Debug(value);
        if (Check(index) == 0) {

            if (value == 6) {
                if (typ == 0) {
                    db_alias_check();
                } else {
                    setIndex(value);
                }
            } else {
                if (value == 7) {
                    setDialogHeight(props.isSmall ? height : 500);
                } else {
                    setDialogHeight(props.isSmall ? height : 380);
                    if (value == 3) {
                        ChangeAlias(userData.salon_name);
                    }
                }
                setIndex(value);
            }
        }
    }


    const Finish = () => {
        let data = {
            id: props.user.id,
            salon1_id: typ == 0 ? 0 : 1,
            language: language,
            name: userData.name,
            surname: userData.surname,
            mobil: userData.mobil,
            salon_name: userData.salon_name,
            salon_street: userData.salon_street,
            salon_street_number: userData.salon_street_number,
            salon_psc: userData.salon_psc,
            salon_town: userData.salon_town,
            company: userData.company,
            street: userData.street,
            street_number: userData.street_number,
            psc: userData.psc,
            town: userData.town,
            ico: userData.ico,
            dic: userData.dic,
            ic_dph: userData.ic_dph,
            psc: userData.psc,
            account: typ == 0 ? userData.account : '',
            alias: typ == 0 ? alias : '-lekaren-',
            gdpr: true,
            agent_id: agent,
            theme: typ == 0 ? 1 : 0
        }

        db_user_update(data);
    }

    const TokenErrorPress = () => {
        //setShowTokenError(false);
    }

    const Close = () => {
        Press(false);
    }

    return (<Backdrop open={true} style={{ backgroundColor: '#000000D0', top: props.remoteApp == true ? index < 7 ? props.isSmall ? undefined : -((props.height / 2) + 30) : undefined : undefined, zIndex: 1000 }}>
        <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 100, height: dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faCog} />
                        </div>
                    </div>
                }
                <div style={{ ...styles.BlockLeft, width: '30%', height: dialog_bar_height, justifyContent: 'center' }}>
                    <p style={{ ...props.isSmall ? styles.TextSmall : styles.TextDialogLabel, textAlign: 'left', marginLeft: props.isSmall ? 10 : 0 }}>{props.title}</p>
                </div>
                <div style={{ ...styles.Block, width: '55%', height: dialog_bar_height }}>
                    <div style={{ ...styles.BlockRow }}>
                        <div style={{ ...styles.Block, width: '50%', height: dialog_bar_height }}>
                            {index > 0 ?
                                props.isSmall ?
                                    <IconButton onClick={() => Prev()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faAngleLeft} />
                                    </IconButton>
                                    :
                                    <Button onClick={() => { Prev() }} style={{ ...styles.ButtonGray, width: props.isSmall ? 100 : 200 }}>
                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginRight: 15 }} icon={faAngleLeft} />
                                        {lang.back}
                                    </Button>
                                : null}
                        </div>
                        <div style={{ ...styles.Block, width: '50%', height: dialog_bar_height }}>
                            {index < 7 ?
                                <Button onClick={() => { Next() }} style={{ ...styles.ButtonDark, width: props.isSmall ? 120 : 200 }}>
                                    {lang.continue}
                                    {props.isSmall ? null :
                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginLeft: 15 }} icon={faAngleRight} />
                                    }
                                </Button>
                                :
                                gdpr == true ?
                                    <Button onClick={() => { Finish() }} style={{ ...styles.ButtonRed, width: props.isSmall ? 120 : 200 }}>
                                        {lang.register_finish}
                                    </Button>
                                    : null}
                        </div>
                    </div>
                </div>
                {props.remoteApp == false ?
                    <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                    : null}
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockRow, height: props.height - dialog_bar_height, borderBottomRightRadius: props.radius }}>
                {index < 7 ?
                    props.isSmall ? null :
                        <div style={{ ...styles.BlockCenter, width: menu_width, height: dialogHeight - dialog_bar_height, background: global.theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                            <Button onClick={() => ChangeIndex(0)} style={{ ...styles.ButtonSettings, marginBottom: 5, backgroundColor: index == 0 ? global.theme_back : '#00000000', color: index == 0 ? global.theme_black : global.theme_dark_gray }}>{lang.state}</Button>
                            <Button onClick={() => ChangeIndex(1)} style={{ ...styles.ButtonSettings, marginBottom: 5, backgroundColor: index == 1 ? global.theme_back : '#00000000', color: index == 1 ? global.theme_black : global.theme_dark_gray }}>{lang.settings_basic}</Button>
                            <Button onClick={() => ChangeIndex(2)} style={{ ...styles.ButtonSettings, marginBottom: 5, backgroundColor: index == 2 ? global.theme_back : '#00000000', color: index == 2 ? global.theme_black : global.theme_dark_gray }}>{lang.salon}</Button>
                            <Button onClick={() => ChangeIndex(3)} style={{ ...styles.ButtonSettings, marginBottom: 5, backgroundColor: index == 3 ? global.theme_back : '#00000000', color: index == 3 ? global.theme_black : global.theme_dark_gray }}>{lang.settings_company}</Button>
                            {typ == 0 ?
                                <Button onClick={() => ChangeIndex(4)} style={{ ...styles.ButtonSettings, marginBottom: 5, backgroundColor: index == 4 ? global.theme_back : '#00000000', color: index == 4 ? global.theme_black : global.theme_dark_gray }}>{lang.account}</Button>
                                : null}
                            {typ == 0 ?
                                <Button onClick={() => ChangeIndex(5)} style={{ ...styles.ButtonSettings, marginBottom: 5, backgroundColor: index == 5 ? global.theme_back : '#00000000', color: index == 5 ? global.theme_black : global.theme_dark_gray }}>{lang.register_settings}</Button>
                                : null}
                            <Button onClick={() => ChangeIndex(6)} style={{ ...styles.ButtonSettings, backgroundColor: index == 6 ? global.theme_back : '#00000000', color: index == 6 ? global.theme_black : global.theme_dark_gray }}>{lang.agent}</Button>
                            {/*
                        <Button onClick={() => ChangeIndex(7)} style={{ ...styles.ButtonSettings, marginBottom: 5, backgroundColor: index == 7 ? global.theme_back : '#00000000', color: index == 7 ? global.theme_black : global.theme_dark_gray }}>{lang.register_sumar}</Button>
                        */}
                        </div>
                    : null}
                <div style={{ ...styles.BlockCenter, width: index < 7 ? props.isSmall ? dialogWidth : dialogWidth - menu_width : dialogWidth, height: dialogHeight - dialog_bar_height, borderBottomRightRadius: props.radius, backgroundColor: global.theme_back }}>
                    {index == 0 ?
                        // *********************************************************************************************************
                        // KRAJINA PODNIKANIA
                        // *********************************************************************************************************
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - dialog_bar_height }}>
                            <div style={{ ...styles.BlockCenter, height: dialogHeight - dialog_bar_height, width: '95%' }}>
                                <p style={{ ...styles.TextNormal, fontWeight: '600', marginTop: 10 }}>{lang.register_state_}</p>
                                <div style={{ ...styles.BlockRowRaw, marginTop: 10 }}>
                                    {languages != false ?
                                        languages.map((item, index) => (
                                            <div key={item.id} onClick={() => ChangeCountry(item)} style={{ ...styles.Block, margin: 15 }}>
                                                <img src={item.image} style={{ width: '100%', height: '100%', maxWidth: 60, maxHeight: 60, objectFit: 'contain', opacity: item.language_id == language ? 1.0 : 0.3 }}></img>
                                            </div>
                                        ))
                                        : null}
                                </div>
                                <div style={{ ...styles.BlockRowRaw, marginTop: 10 }}>
                                    <p style={{ ...styles.TextSmall, fontWeight: '600', marginTop: 0 }}>{lang.register_typ}</p>
                                </div>
                                <div style={{ ...styles.BlockRowRaw, marginTop: 8 }}>
                                    <Button onClick={() => setTyp(0)} style={{ ...styles.ButtonDark, backgroundColor: typ == 0 ? global.theme_dark : global.theme_light_gray, color: typ == 0 ? global.theme_white : global.theme_medium_gray, width: 170 }}>{lang.salon}</Button>
                                    <Button onClick={() => setTyp(1)} style={{ ...styles.ButtonDark, backgroundColor: typ == 1 ? global.theme_dark : global.theme_light_gray, color: typ == 1 ? global.theme_white : global.theme_medium_gray, width: 170, marginLeft: 30 }}>{lang.typ_other}</Button>
                                </div>
                                {country != false ?
                                    <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, marginTop: 30 }}>
                                        <div style={{ ...props.isSmall ? styles.Block : styles.BlockLeft, width: props.isSmall ? '100%' : '50%' }}>
                                            <p style={{ ...styles.TextSmall, fontWeight: '600', marginTop: 0 }}>{country.country}</p>
                                            <p style={{ ...styles.TextSmall, fontWeight: '400', marginTop: 2 }}>{lang.money_payment}: {country.money}</p>
                                            <p style={{ ...styles.TextSmall, fontWeight: '400', marginTop: 2 }}>{lang.eshop}: {country.link}</p>
                                        </div>
                                        <div style={{ ...props.isSmall ? styles.Block : styles.BlockRight, width: props.isSmall ? '100%' : '50%', marginTop: props.isSmall ? 10 : 0 }}>
                                            <Button onClick={() => { setShowVOP(true) }} style={{ ...styles.ButtonLight, marginTop: 10, width: 250, height: 30, backgroundColor: global.theme_white, color: global.theme_darker, fontSize: global.font_tiny }}>
                                                {lang.vop}
                                            </Button>
                                        </div>
                                    </div>
                                    : null}

                            </div>
                        </div>
                        : null}

                    {index == 1 ?
                        // *********************************************************************************************************
                        // ZAKLADNE UDAJE
                        // *********************************************************************************************************
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - dialog_bar_height }}>
                            <div style={{ ...styles.BlockCenter, height: dialogHeight - dialog_bar_height, width: '95%' }}>
                                <p style={{ ...styles.TextNormal, fontWeight: '600', marginTop: 10 }}>{lang.settings_basic}</p>

                                {/* MENO, PRIEZVISKO */}
                                <div style={{ ...styles.BlockRow, marginTop: rowOffset }}>
                                    <div style={{ ...styles.Block }}>
                                        <TextInput required outline lang={lang} value={userData.name} label={lang.name} func={(txt) => { userData.name = txt }} />
                                    </div>
                                    <div style={{ ...styles.BlockLeft, marginLeft: 10 }}>
                                        <TextInput required outline lang={lang} value={userData.surname} label={lang.surname} func={(txt) => { userData.surname = txt }} />
                                    </div>
                                </div>

                                {/* EMAIL, MOBIL */}
                                <div style={{ ...styles.BlockRow, marginTop: rowOffset }}>
                                    <div style={{ ...styles.BlockLeft }}>
                                        <TextInput required outline phone lang={lang} value={userData.mobil} label={lang.mobil_format} func={(txt) => { userData.mobil = txt }} />
                                    </div>

                                    <div style={{ ...styles.Block, marginLeft: 10 }}>
                                        {/*
                                        <TextInput required outline email lang={lang} value={userData.email} label={lang.email} func={(txt) => { userData.email = txt }} />
                                        */}
                                    </div>
                                </div>

                                <FormError error={error} small></FormError>

                            </div>
                        </div>
                        : null}

                    {index == 2 ?
                        // *********************************************************************************************************
                        // SALON / LEKARNA
                        // *********************************************************************************************************
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - dialog_bar_height }}>
                            <div style={{ ...styles.BlockCenter, height: dialogHeight - dialog_bar_height, width: '95%' }}>
                                <p style={{ ...styles.TextNormal, fontWeight: '600', marginTop: 10 }}>{typ == 0 ? lang.settings_salon_address : lang.settings_medical_address} / {lang.medical_}</p>

                                {/* PREVAZDKA */}
                                <div style={{ ...styles.Block, marginTop: rowOffset }}>
                                    <TextInput required outline lang={lang} value={userData.salon_name} label={typ == 0 ? lang.prevadzka_name : lang.medical_name} func={(txt) => { userData.salon_name = txt }} />
                                </div>

                                {/* ULICA, ČISLO */}
                                <div style={{ ...styles.BlockRow, marginTop: rowOffset / 2 }}>
                                    <div style={{ ...styles.Block, width: '70%' }}>
                                        <TextInput required outline lang={lang} value={userData.salon_street} label={lang.street} func={(txt) => { userData.salon_street = txt }} />
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '30%', marginLeft: 10 }}>
                                        <TextInput lang={lang} value={userData.salon_street_number} label={lang.street_number} func={(txt) => { userData.salon_street_number = txt }} />
                                    </div>
                                </div>

                                {/* PSC MESTO */}
                                <div style={{ ...styles.BlockRow, marginTop: rowOffset / 2 }}>
                                    <div style={{ ...styles.Block, width: '30%' }}>
                                        <TextInput required outline lang={lang} value={userData.salon_psc} label={lang.psc} func={(txt) => { userData.salon_psc = txt }} />
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '70%', marginLeft: 10 }}>
                                        <TextInput required outline lang={lang} value={userData.salon_town} label={lang.town} func={(txt) => { userData.salon_town = txt }} />
                                    </div>
                                </div>

                                <FormError error={error} small></FormError>

                            </div>
                        </div>
                        : null}

                    {index == 3 ?
                        // *********************************************************************************************************
                        // FIREMNE UDAJE
                        // *********************************************************************************************************
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - dialog_bar_height }}>
                            <div style={{ ...styles.BlockCenter, height: dialogHeight - dialog_bar_height, width: '95%' }}>
                                <p style={{ ...styles.TextNormal, fontWeight: '600', marginTop: 10 }}>{lang.settings_company}</p>
                                {/* NAZOV FIRMY */}
                                {/* ULICA, ČISLO */}
                                <div style={{ ...styles.BlockRow, marginTop: rowOffset }}>
                                    <div style={{ ...styles.Block, width: '50%' }}>
                                        <TextInput required outline lang={lang} value={userData.company} label={lang.company_name} func={(txt) => { userData.company = txt }} />
                                    </div>
                                    <div style={{ ...styles.Block, width: '30%', marginLeft: 10 }}>
                                        <TextInput required outline lang={lang} value={userData.street} label={lang.street} func={(txt) => { userData.street = txt }} />
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '20%', marginLeft: 10 }}>
                                        <TextInput lang={lang} value={userData.street_number} label={lang.street_number} func={(txt) => { userData.street_number = txt }} />
                                    </div>
                                </div>

                                {/* PSC MESTO */}
                                <div style={{ ...styles.BlockRow, marginTop: rowOffset / 2 }}>
                                    <div style={{ ...styles.Block, width: '30%' }}>
                                        <TextInput required outline lang={lang} value={userData.psc} label={lang.psc} func={(txt) => { userData.psc = txt }} />
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '70%', marginLeft: 10 }}>
                                        <TextInput required outline lang={lang} value={userData.town} label={lang.town} func={(txt) => { userData.town = txt }} />
                                    </div>
                                </div>

                                {/* ICO, DIC, IC-DPH */}
                                <div style={{ ...styles.BlockRow, marginTop: rowOffset / 2 }}>
                                    <div style={{ ...styles.Block }}>
                                        <TextInput required outline lang={lang} value={userData.ico} label={lang.ico} func={(txt) => { userData.ico = txt }} />
                                    </div>
                                    {language != 1 ?
                                        <div style={{ ...styles.BlockLeft, marginLeft: 10 }}>
                                            <TextInput lang={lang} value={userData.dic} label={lang.dic} func={(txt) => { userData.dic = txt }} />
                                        </div>
                                        : null}
                                    <div style={{ ...styles.BlockLeft, marginLeft: 10 }}>
                                        <TextInput lang={lang} value={userData.ic_dph} label={lang.ic_dph} func={(txt) => { userData.ic_dph = txt }} />
                                    </div>
                                </div>

                                <FormError error={error} small></FormError>

                            </div>

                        </div>
                        : null}

                    {index == 4 ?
                        // *********************************************************************************************************
                        // UCET
                        // *********************************************************************************************************
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - dialog_bar_height }}>
                            <div style={{ ...styles.BlockCenter, height: dialogHeight - dialog_bar_height, width: '95%' }}>
                                <p style={{ ...styles.TextNormal, fontWeight: '600', marginTop: 10 }}>{lang.account_eshop}</p>

                                {/* cislo uctu */}
                                <div style={{ ...styles.Block, marginTop: rowOffset, width: props.isSmall ? '80%' : 500 }}>
                                    <TextInput required outline lang={lang} value={userData.account} label={lang.account} func={(txt) => { userData.account = txt }} />
                                </div>

                                <FormError error={error} small></FormError>

                            </div>

                        </div>
                        : null}

                    {index == 5 ?
                        // *********************************************************************************************************
                        // NASTAVENIA - ALIAS
                        // *********************************************************************************************************
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - dialog_bar_height }}>
                            <div style={{ ...styles.BlockCenter, height: dialogHeight - dialog_bar_height, width: '95%' }}>
                                <p style={{ ...styles.TextNormal, fontWeight: '600', marginTop: 10 }}>{lang.register_alias}</p>

                                <p style={{ ...styles.TextSmall, fontWeight: '400', marginTop: 15 }}>{lang.register_alias_text}</p>
                                <p style={{ ...styles.TextSmall, fontWeight: '600', marginTop: 2, color: global.theme_dark_blue }}>{lang.www_eshop}/{alias}</p>

                                {/* PREVAZDKA */}
                                <div style={{ ...styles.Block, width: 300, marginTop: rowOffset * 2 }}>
                                    <p style={{ ...styles.TextTiny }}>{lang.register_alias_input}</p>
                                    <TextInput required outline lang={lang} value={alias} label={''} func={(txt) => { ChangeAlias(txt) }} />
                                    <p style={{ ...styles.TextTiny, marginTop: 2, color: global.theme_dark_gray }}>{lang.register_alias_info}</p>
                                </div>

                                <FormError error={error} small isSmall={props.isSmall}></FormError>

                            </div>

                        </div>
                        : null}

                    {index == 6 ?
                        // *********************************************************************************************************
                        // OBCHODNY ZASTUPCA
                        // *********************************************************************************************************
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - dialog_bar_height }}>
                            <div style={{ ...styles.BlockCenter, height: dialogHeight - dialog_bar_height, width: '95%' }}>
                                <p style={{ ...styles.TextNormal, fontWeight: '600', marginTop: 10 }}>{lang.agent}</p>
                                {agents != false ?
                                    <Select
                                        value={agent}
                                        onChange={event => setAgent(event.target.value)}
                                        size='small'
                                        style={{ backgroundColor: global.theme_white, width: 350, marginTop: 20, marginBottom: 30, borderRadius: 20 }}
                                    >
                                        <MenuItem disabled value={0}>{lang.agent_choose}</MenuItem>
                                        {agents.map((item, index) => (
                                            <MenuItem key={item.id} value={item.id}>{item.name} {item.surname}<span style={{ color: global.theme_medium_gray, marginLeft: 10 }}>({GetLanguage(item.language).language.toLowerCase()})</span></MenuItem>
                                        ))}
                                    </Select>
                                    : null}

                                <FormError error={error} small></FormError>
                            </div>

                        </div>
                        : null}

                    {index == 7 ?
                        // *********************************************************************************************************
                        // ZHRNUTIE
                        // *********************************************************************************************************
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - dialog_bar_height }}>
                            <div style={{ ...styles.BlockCenter, height: dialogHeight - dialog_bar_height - gdprHeight, width: '95%', overflowY: 'scroll', paddingTop: props.isSmall ? 20 : 0, paddingBottom: props.isSmall ? 20 : 0 }}>
                                <p style={{ ...styles.TextNormal, fontWeight: '600', marginTop: 10 }}>{lang.register_sumar}</p>
                                {typ == 0 ?
                                    <div style={{ ...styles.Block }}>
                                        <p style={{ ...styles.TextTiny, fontWeight: '400', marginTop: 20 }}>{lang.register_alias_text}</p>
                                        <p style={{ ...styles.TextTiny, fontWeight: '600', marginTop: 0, color: global.theme_dark_blue }}>{lang.www_eshop}/{alias}</p>
                                    </div>
                                    : null}

                                <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, marginTop: 40 }}>
                                    <div style={{ ...styles.BlockCenter }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: '600', marginBottom: 8 }}>{lang.settings_basic}</p>
                                        <p style={{ ...styles.TextTiny, fontWeight: '400' }}>{userData.name} {userData.surname}</p>
                                        <p style={{ ...styles.TextTiny, fontWeight: '400' }}>{userData.email}</p>
                                        <p style={{ ...styles.TextTiny, fontWeight: '400' }}>{userData.mobil}</p>
                                    </div>
                                    <div style={{ ...styles.BlockCenter, marginLeft: props.isSmall ? 0 : 10, marginRight: props.isSmall ? 0 : 10, marginTop: props.isSmall ? 40 : 0 }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: '600', marginBottom: 8 }}>{typ == 0 ? lang.salon : lang.medical_}</p>
                                        <p style={{ ...styles.TextTiny, fontWeight: '400' }}>{userData.salon_name}</p>
                                        <p style={{ ...styles.TextTiny, fontWeight: '400' }}>{userData.salon_street} {userData.salon_street_number}</p>
                                        <p style={{ ...styles.TextTiny, fontWeight: '400' }}>{userData.salon_psc} {userData.salon_town}</p>
                                    </div>
                                    <div style={{ ...styles.BlockCenter, marginTop: props.isSmall ? 40 : 0 }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: '600', marginBottom: 8 }}>{lang.settings_company}</p>
                                        <p style={{ ...styles.TextTiny, fontWeight: '400' }}>{userData.company}</p>
                                        <p style={{ ...styles.TextTiny, fontWeight: '400' }}>{userData.street} {userData.street_number}</p>
                                        <p style={{ ...styles.TextTiny, fontWeight: '400' }}>{userData.psc} {userData.town}</p>
                                        <p style={{ ...styles.TextTiny, fontWeight: '400', marginTop: 5 }}>{lang.ico}: {userData.ico}</p>
                                        {userData.dic != '' ?
                                            <p style={{ ...styles.TextTiny, fontWeight: '400' }}>{lang.dic} {userData.dic}</p>
                                            : null}
                                        {userData.ic_dph != '' ?
                                            <p style={{ ...styles.TextTiny, fontWeight: '400' }}>{lang.ic_dph} {userData.ic_dph}</p>
                                            : null}
                                        <p style={{ ...styles.TextTiny, fontWeight: '400', marginTop: 5 }}>{lang.account}: {userData.account}</p>
                                    </div>

                                </div>
                            </div>
                            <div style={{ ...styles.Block, height: gdprHeight, backgroundColor: global.theme_light_gray, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                                <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, height: gdprHeight }}>
                                    <div style={{ ...styles.Block, width: props.isSmall ? '90%' : '70%', height: props.isSmall ? undefined : gdprHeight }}>
                                        <FormControlLabel label={<p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.gdpr_vop_agree}</p>}
                                            control={<Checkbox checked={gdpr} size={'small'} onChange={() => setGDPR(!gdpr)} />}
                                        />
                                    </div>
                                    <div style={{ ...styles.Block, width: 300, height: props.isSmall ? undefined : gdprHeight, marginTop: props.isSmall ? 10 : 0 }}>
                                        <Button onClick={() => { setShowGDPR(true) }} style={{ ...styles.ButtonLight, width: 250, height: 30, backgroundColor: global.theme_white, color: global.theme_darker, fontSize: global.font_tiny }}>
                                            {lang.gdpr}
                                        </Button>
                                        <Button onClick={() => { setShowVOP(true) }} style={{ ...styles.ButtonLight, marginTop: 10, width: 250, height: 30, backgroundColor: global.theme_white, color: global.theme_darker, fontSize: global.font_tiny }}>
                                            {lang.vop}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null}


                </div>
            </div>

            {showGDPR == true ?
                <DialogAgreements theme={props.theme} typ={1} user={{ id: 2 }} lang={lang} radius={props.radius} sub_title={lang.vision_system} backcolor={true} background={false} func={() => setShowGDPR(false)} />
                : null}

            {showVOP == true ?
                <DialogAgreements theme={props.theme} typ={21} user={{ id: 2 }} lang={lang} radius={props.radius} sub_title={lang.vision_system} backcolor={true} background={false} func={() => setShowVOP(false)} />
                : null}

            {showTokenError == true ?
                <ShowTokenError theme={props.theme} center remoteApp={props.remoteApp} text={lang.token_error} sub_text={lang.token_error_text} func={TokenErrorPress.bind(this)} />
                : null}

            {isBusy == true || loading == true ?
                <Loading center screenWidth={dialogWidth} offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Paper >
    </Backdrop >
    );
}


