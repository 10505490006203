import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation, createRoutesFromChildren } from "react-router-dom";
import { cz, sk } from './language.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark, faCircle, faBackward, faHome, faUser, faSearch, faRightFromBracket, faImage, faChevronRight, faChevronUp, faSquare, faBars, faTriangleExclamation, faInfoCircle, faTrash, faEdit, faAdd, faMessage, faQuestionCircle, faLayerGroup, faTag, faLocationDot, faBell, faCog, faList, faPlusCircle, faPlus, faCogs, faAngleLeft, faQuestion, faEye } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Checkbox, Drawer, FormControlLabel, Radio, RadioGroup, Slide } from '@mui/material';
import { Alert, Autocomplete, Button, IconButton, MenuItem, Select, TextField, Paper, Tabs, Tab } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { styles } from './styles.js';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import useMediaQuery from '@mui/material/useMediaQuery';
import './items.css';
import { BinaryToData, Colors, DataToBinary, Debug, DialogAgreements, DialogImagePreview, DialogYesNo, EmployeeSelect, EmptyList, FormError, GDPRDetails, GetDatum2, GetTopPosition, IsEmpty, Loading, ShowError, ShowOK, TextInput, Today, nonEmail } from './items.js';
import { Photos } from './photos.js';
import { CalendarBirthday } from './calendar_dialog.js';

export const DialogClient = (props) => {
    /*
            ZAKAZNIK - údaje

            USAGE:
            <DialogClient employees={props.employees}  offset={props.offset} theme={props.theme}  editing={editing} user_id={props.user.id} client={itemSelected} lang={lang} radius={props.radius} title={''} sub_title={lang.client} backcolor={true} background={true} func={ClientDialogResult.bind(this)} />

            const DialogClientResult = (typ,value) => {
                console.log(value)
            }

            <CalendarBirthday date={'2001-08-19'} lang={props.lang} language={props.language}  offset={props.offset} theme={props.theme}  radius={props.radius} func={BirthdayResult.bind(this)} />
    */

    const avatar_man = require('./react/app/man.png');
    const avatar_woman = require('./react/app/woman.png');

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [redraw, setRedraw] = useState(false);

    const [data, setData] = useState(props.editing == true ? false : {
        name: '',
        surname: '',
        mobil: '',
        email: '',
        birthday: '1995-07-15',
        note: '',
        color: 0,
    });
    const [enabled, setEnabled] = useState(true);
    const [sex, setSex] = useState(0);
    const [avatar, setAvatar] = useState('');
    const [birthday, setBirthday] = useState('1995-07-15');
    const [employee, setEmployee] = useState(0);

    // DB
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showGDPR, setShowGDPR] = useState(false);
    const [showPhoto, setShowPhoto] = useState(false);
    const [showSelectPhoto, setShowSelectPhoto] = useState(false);
    const [showGDPRDetails, setShowGDPRDetails] = useState(false);
    const [showBirthday, setShowBirthday] = useState(false);


    // GDPR
    const [gdpr, setGDPR] = useState(false);
    const [gdprData, setGDPRData] = useState({
        basic_data: true,
        notes: false,
        gallery: false,
        diagnostic: false
    });

    // AK NIEJE SUHLAS GDPR -> OTAZKA O ODSLANI EMAILU
    const [showSendEmail, setShowSendEmail] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = props.isSmall ? width : 950;
    const dialogHeight = props.isSmall ? height - global.topmenu_height : 680;
    const rowOffset = 20;
    const errorHeight = 40;
    const buttonHeight = 100;
    const avatar_size = 150;

    let { func } = props;
    var lang = props.lang;

    var running = false;
    useEffect(() => {
        if (running == false) {
            running = true;

            if (props.editing == true) {
                db_get();
            }
        }
        return () => {
        };

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'client', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    client_id: props.client.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setData(json.item);
                setEnabled(json.item.enabled == false ? false : true);
                setSex(json.item.sex);
                setGDPR(json.item.gdpr == false ? false : true);
                setEmployee(json.item.group_id);
                setAvatar(json.item.photo);
                setBirthday(json.item.birthday);

                if (json.item.gdpr_v2 == 0) {
                    // kompatibilita s V1
                    let data = {
                        basic_data: true,
                        notes: true,
                        gallery: true,
                        diagnostic: true
                    }
                    setGDPRData(data);
                } else {
                    let items = BinaryToData(json.item.gdpr_v2);
                    let data = {
                        basic_data: items.d1,
                        notes: items.d2,
                        gallery: items.d3,
                        diagnostic: items.d4
                    }
                    setGDPRData(data);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'client_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                    salon_id: props.user_id,
                    lang: {
                        do_not_reply: lang.do_not_reply,
                        do_not_reply_text: lang.do_not_reply_text_,
                        client_registered: lang.client_registered,
                    }
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setBusy(false);
                setShowOK(true);
                var timer = setTimeout(() => {
                    Press(true, data);
                }, 1100);

            } else {
                setBusy(false);
                setShowError(true);
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_check = async (email, client) => {
        // KONROLA, CI UZ JE EMAIL REGISTROVANY
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'client_check', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.editing == true ? props.client.id : 0,
                    email: email
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.count > 0) {
                    setError(lang.email_exists);
                    setBusy(false);
                } else {
                    db_update(client);
                }
            } else {
                setShowError(true);
                setBusy(false);
            }


        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ, data) => {
        func(typ, data);
    }

    const ShowOKPress = () => {
        setShowOK(false);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const ColorsResult = (value) => {
        data.color = value;
    }

    const PhotoPreview = () => {
        if (avatar != '' && avatar != null) {
            setShowPhoto(true);
        }
    }

    const DialogImagePreviewResult = () => {
        setShowPhoto(false);
    }

    const PhotosResult = (typ, path, file) => {
        setShowSelectPhoto(false);

        if (typ == true) {
            setAvatar(path + file);
        }
    }

    const EmployeeSelectResult = (value) => {
        setEmployee(value);
    }

    const GDPRDetailsResult = (typ, items) => {
        setShowGDPRDetails(false);
        if (typ == true) {
            setGDPRData(items);
        }
        setGDPR(items.basic_data);
    }

    const ChangeGDPR = (value) => {
        setGDPR(value);

        let items = {
            basic_data: true,
            notes: value,
            gallery: value,
            diagnostic: value
        }
        setGDPRData(items);
    }

    const Save = () => {
        if (props.editing == false) {
            // NOVY ZAZNAM - kontrola odsuhlasenia GDPR
            if (gdpr == true) {
                Process(1);
            } else {
                if (data.email.trim() == '') {
                    Process(0);
                } else {
                    setShowSendEmail(true);
                }
            }

        } else {
            // UPDATE UDAJOV            
            Process(1);
        }

    }


    const SendEmailResult = (value) => {
        setShowSendEmail(false);

        if (value == true) {
            Process(2);
        } else {
            Process(0);
        }
    }


    const Process = (sendEmail_) => {

        var error = 0;
        var email_error = false;

        error += IsEmpty(data.name) == true ? true : false;
        error += IsEmpty(data.surname) == true ? true : false;
        if (data.email != '') {
            error += nonEmail(data.email) == true ? true : false;
            email_error = true;
        }

        if (error > 0) {
            if (email_error == true) {
                setError(lang.email_error);
            } else {
                setError(lang.form_error);
            }
        } else {
            setError('');

            if (props.editing == false) {

                let gdpr_v2 = DataToBinary(gdprData.basic_data, gdprData.notes, gdprData.gallery, gdprData.diagnostic, false, false, false, false)

                // NOVY ZAZNAM
                var client = {
                    id: 0,
                    enabled: enabled,
                    typ: 3,
                    language: props.user.language,
                    salon1_id: props.user_id,
                    worker_id: employee,
                    date: new Date(),
                    username: '',
                    password: '',
                    name: data.name.trim(),
                    surname: data.surname.trim(),
                    mobil: data.mobil.trim(),
                    email: data.email.trim(),
                    note: data.note.trim(),
                    street: '',
                    street_number: '',
                    psc: '',
                    town: '',
                    token: '',
                    photo: avatar,
                    theme_id: data.color,
                    score: 0,
                    sex: sex,
                    birthday: birthday,
                    gdpr: gdpr,
                    gdpr_v2: gdpr_v2,
                    version: '0',
                    forum_last_login: new Date(),
                    send_email: sendEmail_
                }
            } else {
                // UPDATE ZAZNAMU
                var client = {
                    id: props.editing == true ? props.client.id : 0,
                    enabled: enabled,
                    group_id: employee,
                    date: new Date(),
                    name: data.name.trim(),
                    surname: data.surname.trim(),
                    mobil: data.mobil.trim(),
                    email: data.email.trim(),
                    note: data.note.trim(),
                    photo: avatar,
                    theme_id: data.color,
                    sex: sex,
                    birthday: birthday,
                }
            }
            if (data.email.trim() != '') {
                db_check(data.email.trim(), client);
            } else {
                db_update(client);
            }
        }

    }


    const BirthdayResult = (value, date, y, m, d) => {
        if (value == true) {
            setBirthday(date)
        }

        setShowBirthday(false);
        setRedraw(!redraw);
    }


    return (
        <Backdrop open={true} style={{ position: 'fixed', top: GetTopPosition(height, dialogHeight), paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faUser} />
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRow, width: props.isSmall ? '70%' : '90%', height: global.dialog_bar_height, alignItems: 'center' }}>
                        <div style={{ ...styles.BlockLeft, width: 180, height: 45, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.client}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.editing == false ? lang.record_new : lang.record_edit}</p>
                        </div>
                        <div style={{ ...styles.BlockRow, height: 45 }} >
                            {props.isSmall ? null :
                                <div style={{ ...styles.Block, width: '70%', height: 45 }}>
                                    <Colors small light value={data.color} func={ColorsResult.bind(this)} />
                                </div>
                            }
                            <div style={{ ...styles.Block, width: props.isSmall ? 150 : 300, height: 45 }}>
                                {props.employees.length > 1 ?
                                    <EmployeeSelect theme={props.theme} select radius width={props.isSmall ? 150 : 300} employees={props.employees} value={employee} func={EmployeeSelectResult.bind(this)} />
                                    : null}
                            </div>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false, false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockRow, height: dialogHeight - global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, width: avatar_size }}>
                            <img onClick={() => PhotoPreview()} src={avatar == '' || avatar == null ? sex == 0 ? avatar_woman : avatar_man : avatar.substring(0, 4) == 'http' ? avatar : global.web_images + '/' + avatar} style={{ width: '100%', height: '100%', maxWidth: avatar_size - 20, maxHeight: avatar_size - 20, objectFit: 'contain', marginTop: 10, borderRadius: 10, cursor: 'pointer' }}></img>
                            {gdprData.gallery == true ?
                                <Button onClick={() => setShowSelectPhoto(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].theme_light, color: global.theme_black, width: avatar_size - 20, marginTop: 10 }}>{lang.select}</Button>
                                : null}
                        </div>

                        {data != false ?
                            <div style={{ ...styles.BlockCenter, width: dialogWidth - avatar_size, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? '#FFFFFF77' : undefined }}>
                                <div style={{ ...styles.BlockCenter, width: '90%', height: dialogHeight - global.dialog_bar_height - buttonHeight - errorHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                        <FormControlLabel label={<p style={{ ...styles.TextSmall }}>{lang.client_enabled}</p>}
                                            control={<Checkbox checked={enabled} size={'small'} onChange={() => setEnabled(!enabled)} />}
                                        />
                                    </div>
                                    <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, marginTop: rowOffset }}>
                                        <div style={{ ...styles.BlockLeft, width: props.isSmall ? '100%' : '33%' }}>
                                            <TextInput required enabled={true} lang={lang} value={data.name} label={lang.name} func={(txt) => { data.name = txt }} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: props.isSmall ? '100%' : '33%', marginLeft: props.isSmall ? 0 : 10, marginTop: props.isSmall ? 10 : 0 }}>
                                            <TextInput required enabled={true} lang={lang} value={data.surname} label={lang.surname} func={(txt) => { data.surname = txt }} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: props.isSmall ? '100%' : '33%', marginLeft: props.isSmall ? 0 : 10, paddingTop: 16 }}>
                                            <RadioGroup value={sex} onChange={(event) => setSex(event.target.value)} style={{ width: '100%' }}>
                                                <div style={{ ...styles.BlockRow }}>
                                                    <div style={{ ...styles.Block }}>
                                                        <FormControlLabel value={0} label={<p style={{ ...styles.TextTiny }}>{lang.female}</p>}
                                                            control={<Radio size={'small'} />}
                                                        />
                                                    </div>
                                                    <div style={{ ...styles.Block }}>
                                                        <FormControlLabel value={1} label={<p style={{ ...styles.TextTiny }}>{lang.male}</p>}
                                                            control={<Radio size={'small'} />}
                                                        />
                                                    </div>
                                                </div>
                                            </RadioGroup>
                                        </div>
                                    </div>
                                    <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, marginTop: rowOffset }}>
                                        <div style={{ ...styles.BlockLeft, width: props.isSmall ? '100%' : '33%' }}>
                                            <TextInput enabled={true} lang={lang} value={data.mobil} label={lang.mobil} func={(txt) => { data.mobil = txt }} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: props.isSmall ? '100%' : '33%', marginLeft: props.isSmall ? 0 : 10, marginTop: props.isSmall ? 10 : 0 }}>
                                            <TextInput enabled={true} lang={lang} value={data.email} label={lang.email} func={(txt) => { data.email = txt }} />
                                        </div>
                                        <div onClick={() => setShowBirthday(true)} style={{ ...styles.BlockLeft, width: props.isSmall ? '100%' : '33%', marginLeft: props.isSmall ? 0 : 10, marginTop: props.isSmall ? 10 : 0, cursor: 'pointer' }}>
                                            <p style={{ ...styles.TextInput, color: global.theme_dark_gray, marginLeft: 12 }}>{lang.birthdate}</p>
                                            <div style={{ ...styles.Block, width: '98%', height: 36, backgroundColor: global.theme_white, border: '1px solid ' + global.theme_dark, borderRadius: 18 }}>
                                                <p style={{ ...styles.TextSmall }}>{GetDatum2(birthday)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.Block, marginTop: props.isSmall ? 40 : rowOffset }}>
                                        <TextInput rows={1} enabled={true} lang={lang} value={data.note} label={lang.note} func={(txt) => { data.note = txt }} />
                                    </div>
                                    {props.editing == false ?
                                        <div style={{ ...styles.Block, marginTop: rowOffset, marginBottom: props.isSmall ? 50 : 300 }}>
                                            <FormControlLabel label={<p style={{ ...styles.TextSmall, textAlign: 'left' }}>{lang.client_gdpr}</p>}
                                                control={<Checkbox checked={gdpr} size={'small'} onChange={() => ChangeGDPR(!gdpr)} />}
                                            />
                                            <div style={{ ...props.isSmall ? styles.Block : styles.BlockRowRaw, marginTop: 16 }}>
                                                <Button onClick={() => setShowGDPRDetails(true)} style={{ ...styles.ButtonLight, width: props.isSmall ? 200 : 250 }}>{props.isSmall ? lang.gdpr_details_ : lang.gdpr_details}</Button>
                                                <Button onClick={() => setShowGDPR(true)} style={{ ...styles.ButtonLight, width: props.isSmall ? 200 : 250, marginLeft: props.isSmall ? 0 : 20, marginTop: props.isSmall ? 30 : 0 }}>
                                                    {props.isSmall ? null :
                                                        < FontAwesomeIcon style={{ height: 14, color: global.theme_white, marginRight: 10 }} icon={faEye} />
                                                    }
                                                    {lang.gdpr_read}
                                                </Button>
                                            </div>

                                        </div>
                                        : null}
                                </div>
                                <div style={{ ...styles.Block, width: '95%', height: buttonHeight + errorHeight }}>
                                    <FormError error={error} />
                                    <div style={{ ...styles.Block, height: buttonHeight }}>
                                        {/*props.editing == true || (props.editing == false && gdpr == true) || (props.editing == false && gdprData.basic_data == true) ?*/}
                                        <Button onClick={() => Save()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>{props.editing == true ? lang.save : lang.register_}</Button>

                                    </div>
                                </div>
                            </div>
                            : null}
                    </div>
                </div>
            </Paper>

            {showGDPR == true ?
                <DialogAgreements theme={props.theme} typ={5} user={props.user} lang={lang} radius={props.radius} sub_title={lang.vision_system} backcolor={true} icon={faQuestion} background={false} func={() => setShowGDPR(false)} />
                : null}

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={props.lang.db_error} func={ShowErrorPress.bind(this)} />
                : null}

            {showPhoto == true ?
                <DialogImagePreview theme={props.theme} file={avatar.substring(0, 4) == 'http' ? avatar : global.web_images + "/" + avatar} radius={props.radius} lang={lang} title={lang.preview} sub_title={lang.vision_system} background={global.theme_dark_gray} crop={false} func={DialogImagePreviewResult.bind(this)} />
                : null}

            {showSelectPhoto == true ?
                <Photos lang={lang} typ={0} max_size={600} width={600} height={600} title={lang.photo_select} sub_title={lang.vision_system} radius={props.radius} offset={props.offset} theme={props.theme} func={PhotosResult.bind(this)} />
                : null}

            {showGDPRDetails == true ?
                <GDPRDetails isSmall={props.isSmall} theme={props.theme} data={gdprData} lang={lang} radius={props.radius} func={GDPRDetailsResult.bind(this)} />
                : null}

            {showBirthday == true ?
                <CalendarBirthday date={birthday} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} func={BirthdayResult.bind(this)} />
                : null}

            {showSendEmail == true ?
                <DialogYesNo hideClose={true} theme={props.theme} radius={props.radius} title={lang.client_registered} sub_title={''} text={lang.client_register_send_email} sub_text={lang.client_register_send_email_1} warning_text={lang.client_register_send_email_2} icon={faQuestion} yes={props.lang.send} no={props.lang.not_send} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={SendEmailResult.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}


        </Backdrop >
    );
}

