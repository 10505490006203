import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { Debug, DialogLogin, GetLanguage, Init, LoadLanguage, LoadLogin, Menu, MenuTitle, MenuTop, ResetLogin, SaveLanguage, SaveLogin } from './items';
import { styles } from './styles';
import './items.css';

// IMAGES
import img_background from './react/app/background_intro_2.jpg';
import img_background_small from './react/app/background_intro_small_2.jpg';
import { Button, Paper, useMediaQuery } from '@mui/material';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Start = () => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);

    // APLIKACIA
    const [appVersion, setAppVersion] = useState('');
    const [language, setLanguage] = useState(0);
    const [token, setToken] = useState('');
    const [logged, setLogged] = useState(true);

    // VZHLAD APKY
    const [offset, setOffset] = useState(16);
    const [radius, setRadius] = useState(16);
    const [showRegister, setShowRegister] = useState(false);
    const [showLogin, setShowLogin] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');
    const isMedium = useMediaQuery('(max-width:' + global.device_medium + 'px)');


    const logo = require('./react/app/logo.png');
    const flag_sk = require('./react/app/flag_sk.png');
    const flag_cz = require('./react/app/flag_cz.png');
    const apple = require('./react/app/apple-logo-white.png');
    const android = require('./react/app/android-logo-white.png');

    const logo_size = isSmall == true ? 300 : 400;
    const dialogWidth = isSmall == true ? 300 : 600;
    const flag_size = 40;

    const buttonHeight = 56;
    const buttonWeight = 180;
    const buttonRadius = 10;
    const imageSize = 42;

    var lang = GetLanguage(language);

    useEffect(() => {

        if (width < global.device_small) {
            // MOBILE
            setRadius(0);
            setOffset(0);
        } else {
            // DESKTOP
            setRadius(global.radius);
            setOffset(global.offset);

            // RELOAD PAGE - AK SA ZMENI VELKOST OKNA               
            function handleResize() {
                setWidth(window.innerWidth);
                setHeight(window.innerHeight);
            }
            window.addEventListener('resize', handleResize);
        }

        SaveLanguage(0);
        lang = LoadLanguage();

        var login = LoadLogin();
        if (login.token != '') {

            navigate('/application');
        } else {
            Debug('Country reset');
            localStorage.removeItem('esthederm-salon-app-country');
        }

    }, [])

    const ChangeLanguage = (value) => {
        setLanguage(value);
        SaveLanguage(value);
    }

    const Login = () => {
        setShowLogin(true);
    }

    const Register = () => {

    }

    const DialogLoginResult = (user, token) => {
        setShowLogin(false);
        if (user != false) {
            if (token != '') {
                SaveLogin({ token: token, expire: 0, version: 2.0 });
                navigate('/application');
            }
        }
    }

    return (
        <div style={{ ...styles.Block, height: height, alignItems: 'flex-start', justifyContent: 'flex-start', fontFamily: 'roboto', msOverflowStyle: 'none', scrollbarWidth: 'none', backgroundImage: isSmall ? `url(${img_background_small})` : `url(${img_background})`, backgroundSize: 'cover' }} className='container'>
            <div style={{ ...styles.Block, height: height }}>
                <img src={logo} style={{ width: '100%', height: '100%', maxWidth: logo_size, maxHeight: logo_size / 3, objectFit: 'contain' }}></img>
                <p style={{ ...styles.TextLarge, color: global.theme_white, marginTop: 15 }}>{lang.slogan}</p>
                <div style={{ ...styles.Block, width: 280, height: flag_size + 20, backgroundColor: '#FFFFFF20', borderRadius: (flag_size + 20) / 2, marginTop: 20, border: '0px solid #303030' }}>
                    <div style={{ ...styles.BlockRowRaw }}>
                        <div onClick={() => ChangeLanguage(0)} style={{ ...styles.Block, width: flag_size + 20, cursor: 'pointer' }}>
                            <img src={flag_sk} style={{ width: '100%', height: '100%', maxWidth: flag_size, maxHeight: flag_size, objectFit: 'contain', opacity: language == 0 ? 1.0 : 0.2 }}></img>
                        </div>
                        <div onClick={() => ChangeLanguage(1)} style={{ ...styles.Block, width: flag_size + 20, cursor: 'pointer' }}>
                            <img src={flag_cz} style={{ width: '100%', height: '100%', maxWidth: flag_size, maxHeight: flag_size, objectFit: 'contain', opacity: language == 1 ? 1.0 : 0.2 }}></img>
                        </div>
                    </div>
                </div>
                <div style={{ ...styles.Block, width: dialogWidth, marginTop: 100 }}>
                    <Button onClick={() => Login()} style={{ ...styles.ButtonDark, backgroundColor: '#00224477', width: 200, height: 46, borderRadius: 23, border: '2px solid #00000044' }}>
                        <p style={{ ...styles.TextSmall, letterSpacing: 1.5, fontWeight: '600', color: global.theme_white }}>{lang.login}</p>
                        <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginLeft: 10 }} icon={faAngleRight} />
                    </Button>
                    {/*
                    <Button onClick={() => Register()} style={{ ...styles.ButtonLink, color: global.theme_white, marginTop: 30 }}>{lang.register}</Button>
                    */}
                </div>

                <div style={{ ...styles.Block, width: dialogWidth, marginTop: 100 }}>
                    <p style={{ ...styles.TextNormal, color: global.theme_white }}>{lang.mobile_apps}</p>
                    <div style={{ ...isSmall ? styles.Block : styles.BlockRowRaw, marginTop: 10 }}>
                        <a href={global.ipad_link} target='_blank' style={{ ...styles.Block, width: buttonWeight, height: buttonHeight, background: global.theme_buttons_rainbow, borderRadius: buttonRadius, textDecoration: 'none', marginLeft: 10, marginRight: 10 }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.Block, width: imageSize + 20, height: buttonHeight }}>
                                    <img src={apple} width={imageSize} height={imageSize}></img>
                                </div>
                                <div style={{ ...styles.Block, width: buttonWeight - imageSize - 20, height: buttonHeight }}>
                                    <p style={{ ...styles.TextNormal, color: global.theme_white, fontWeight: '600' }}>Apple</p>
                                    <p style={{ ...styles.TextTiny, color: global.theme_white }}>App Store</p>
                                </div>
                            </div>
                        </a>
                        <a href={global.android_link} target='_blank' style={{ ...styles.Block, width: buttonWeight, height: buttonHeight, background: global.theme_buttons_rainbow2, borderRadius: buttonRadius, marginLeft: 10, marginRight: 10, textDecoration: 'none', marginTop: isSmall ? 15 : 0 }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.Block, width: imageSize + 20, height: buttonHeight }}>
                                    <img src={android} width={imageSize} height={imageSize}></img>
                                </div>
                                <div style={{ ...styles.Block, width: buttonWeight - imageSize - 20, height: buttonHeight }}>
                                    <p style={{ ...styles.TextNormal, color: global.theme_white, fontWeight: '600' }}>Android</p>
                                    <p style={{ ...styles.TextTiny, color: global.theme_white }}>Google Play</p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            {
                showLogin == true ?
                    <DialogLogin theme={0} lang={lang} radius={16} func={DialogLoginResult.bind(this)} />
                    : null
            }
        </div >
    );
}


