import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, ConvertToPrice, CopyData, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, GetDate, GetImageLink, GetPrice, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, ShowError, ShowOK, TemplateWindow, Today } from './items';
import { styles } from './styles';
import { Backdrop, Button, CircularProgress, IconButton, InputAdornment, Pagination, Paper, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faArrowDown, faArrowUp, faCheckCircle, faEdit, faL, faLayerGroup, faList, faListAlt, faQuestion, faSave, faShoppingBag, faShoppingBasket, faTrash, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Graph } from './graph';
import { ClientCard } from './client_card';

export const StatsClients = (props) => {
    const [isBusy, setBusy] = useState(false);

    const [clients, setClients] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [sortTyp, setSortTyp] = useState(7);
    const [sortDown, setSortDown] = useState(false);
    const [width, setWidth] = useState(window.innerWidth);

    // VYBER RIADKA
    const [rowSelected, setRowSelected] = useState(-1);

    // KARTA ZAKAZNIKA
    const [itemSelected, setItemSelected] = useState(false);
    const [showCard, setShowCard] = useState(false);

    const rowWidth = props.width < 1000 ? width - 50 : 900;
    const cellHeight = 50;
    const cellPadding = 6;
    const borderColor = global.theme_dark_gray;

    const no_image = require('./react/app/no_image.jpg');
    const avatar_man = require('./react/app/man.png');
    const avatar_woman = require('./react/app/woman.png');

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            db_get();
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stats_clients', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    language: props.language,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.clients;
                if (tmp != false) {
                    tmp.sort((a, b) => {
                        if (parseFloat(a.sum) < parseFloat(b.sum)) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }
                setClients(tmp);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = () => {
        func(false);
    }

    const ClientsSort = (typ) => {
        var tmp = clients;

        if (typ == 0) {
            // podla abacedy
            if (sortDown == false) {
                tmp.sort((a, b) => {
                    if (a.surname > b.surname) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
            } else {
                tmp.sort((a, b) => {
                    if (a.surname < b.surname) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
            }
        }
        if (typ == 1) {
            // podla kusov produktov
            if (sortDown == false) {
                tmp.sort((a, b) => {
                    if (parseInt(a.quantity_salon) > parseInt(b.quantity_salon)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
            } else {
                tmp.sort((a, b) => {
                    if (parseInt(a.quantity_salon) < parseInt(b.quantity_salon)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
            }
        }
        if (typ == 2) {
            // podla sumy produktov
            if (sortDown == false) {
                tmp.sort((a, b) => {
                    if (parseFloat(a.sum_salon) > parseFloat(b.sum_salon)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
            } else {
                tmp.sort((a, b) => {
                    if (parseFloat(a.sum_salon) < parseFloat(b.sum_salon)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
            }
        }
        if (typ == 3) {
            // podla poctu sluzieb
            if (sortDown == false) {
                tmp.sort((a, b) => {
                    if (parseInt(a.quantity_services) > parseInt(b.quantity_services)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
            } else {
                tmp.sort((a, b) => {
                    if (parseInt(a.quantity_services) < parseInt(b.quantity_services)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
            }
        }
        if (typ == 4) {
            // podla sumy sluzieb
            if (sortDown == false) {
                tmp.sort((a, b) => {
                    if (parseFloat(a.sum_services) > parseFloat(b.sum_services)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
            } else {
                tmp.sort((a, b) => {
                    if (parseFloat(a.sum_services) < parseFloat(b.sum_services)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
            }
        }
        if (typ == 5) {
            // podla poctu produktov - eshop
            if (sortDown == false) {
                tmp.sort((a, b) => {
                    if (parseInt(a.quantity_eshop) > parseInt(b.quantity_eshop)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
            } else {
                tmp.sort((a, b) => {
                    if (parseInt(a.quantity_eshop) < parseInt(b.quantity_eshop)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
            }
        }
        if (typ == 6) {
            // podla sumy produktov- eshop
            if (sortDown == false) {
                tmp.sort((a, b) => {
                    if (parseFloat(a.sum_eshop) > parseFloat(b.sum_eshop)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
            } else {
                tmp.sort((a, b) => {
                    if (parseFloat(a.sum_eshop) < parseFloat(b.sum_eshop)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
            }
        }
        if (typ == 7) {
            // podla celkovej
            if (sortDown == false) {
                tmp.sort((a, b) => {
                    if (parseFloat(a.sum) > parseFloat(b.sum)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
            } else {
                tmp.sort((a, b) => {
                    if (parseFloat(a.sum) < parseFloat(b.sum)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
            }
        }
        setSortDown(!sortDown);
        setSortTyp(typ)
        setClients(tmp);
        setRedraw(!redraw);
    }


    const Client = (item) => {
        setItemSelected(item);
        setRowSelected(item.id);
        setShowCard(true);
    }


    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                        <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                    </div>
                </div>
                <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: 45 }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                        <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                    </div>
                </div>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Press()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, width: props.width, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                    {clients != false ?
                        <div style={{ ...styles.BlockCenter, maxWidth: rowWidth, borderBottom: '1px solid ' + borderColor, marginTop: 20, marginBottom: 40 }}>
                            <div style={{ ...styles.Block, position: 'sticky', top: 0, zIndex: 110, height: cellHeight, borderTop: '1px solid ' + borderColor, backgroundColor: global.theme_lighter }}>
                                <div style={{ ...styles.BlockRow }}>

                                    {/* MENO, PRIEZVISKO */}
                                    <div onClick={() => ClientsSort(0)} style={{ ...styles.Block, height: cellHeight, justifyContent: 'center', width: '20%', borderLeft: '1px solid ' + borderColor, borderRight: '1px solid ' + borderColor, cursor: 'pointer' }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <p style={{ ...styles.TextXXTiny, textAlign: 'left' }}>{lang.clients}</p>
                                            {sortTyp == 0 ?
                                                <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginLeft: 10 }} icon={sortDown == true ? faArrowDown : faArrowUp} />
                                                : null}
                                        </div>
                                    </div>

                                    {/* PREDAJ PRODUKTROV V SALONE */}
                                    <div style={{ ...styles.Block, width: '25%', borderRight: '1px solid ' + borderColor }}>
                                        <div style={{ ...styles.Block, height: cellHeight / 2 }}>
                                            <p style={{ ...styles.TextXXTiny, textAlign: 'left' }}>{lang.products}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRow, height: cellHeight / 2 }}>
                                            <div onClick={() => ClientsSort(1)} style={{ ...styles.Block, width: '30%', height: cellHeight / 2, cursor: 'pointer' }}>
                                                <div style={{ ...styles.BlockRowRaw }}>
                                                    <p style={{ ...styles.TextXXTiny, textAlign: 'left' }}>{lang.pieces}</p>
                                                    {sortTyp == 1 ?
                                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginLeft: 10 }} icon={sortDown == true ? faArrowDown : faArrowUp} />
                                                        : null}
                                                </div>
                                            </div>
                                            <div style={{ ...styles.BlockRow, width: '70%', height: cellHeight / 2 }}>
                                                <div style={{ ...styles.BlockLeft, width: '30%', height: cellHeight / 2, justifyContent: 'center' }}>
                                                </div>
                                                <div onClick={() => ClientsSort(2)} style={{ ...styles.BlockRight, width: '70%', height: cellHeight / 2, justifyContent: 'center', cursor: 'pointer' }}>
                                                    <div style={{ ...styles.BlockRowRaw, marginRight: cellPadding }}>
                                                        <p style={{ ...styles.TextXXTiny, textAlign: 'left' }}>{lang.booking_sum}</p>
                                                        {sortTyp == 2 ?
                                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginLeft: 10 }} icon={sortDown == true ? faArrowDown : faArrowUp} />
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* PREDAJ SLUZIEB */}
                                    <div style={{ ...styles.Block, width: '25%', borderRight: '1px solid ' + borderColor }}>
                                        <div style={{ ...styles.Block, height: cellHeight / 2 }}>
                                            <p style={{ ...styles.TextXXTiny, textAlign: 'left' }}>{lang.services}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRow, height: cellHeight / 2 }}>
                                            <div onClick={() => ClientsSort(3)} style={{ ...styles.Block, width: '30%', height: cellHeight / 2, cursor: 'pointer' }}>
                                                <div style={{ ...styles.BlockRowRaw }}>
                                                    <p style={{ ...styles.TextXXTiny, textAlign: 'left' }}>{lang.pieces}</p>
                                                    {sortTyp == 3 ?
                                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginLeft: 10 }} icon={sortDown == true ? faArrowDown : faArrowUp} />
                                                        : null}
                                                </div>
                                            </div>
                                            <div style={{ ...styles.BlockRow, width: '70%', height: cellHeight / 2 }}>
                                                <div style={{ ...styles.BlockLeft, width: '30%', height: cellHeight / 2, justifyContent: 'center' }}>
                                                </div>
                                                <div onClick={() => ClientsSort(4)} style={{ ...styles.BlockRight, width: '70%', height: cellHeight / 2, justifyContent: 'center', cursor: 'pointer' }}>
                                                    <div style={{ ...styles.BlockRowRaw, marginRight: cellPadding }}>
                                                        <p style={{ ...styles.TextXXTiny, textAlign: 'left' }}>{lang.booking_sum}</p>
                                                        {sortTyp == 4 ?
                                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginLeft: 10 }} icon={sortDown == true ? faArrowDown : faArrowUp} />
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* PREDAJ PRODUKTROV CEZ ESHOP */}
                                    <div style={{ ...styles.Block, width: '25%', borderRight: '1px solid ' + borderColor }}>
                                        <div style={{ ...styles.Block, height: cellHeight / 2 }}>
                                            <p style={{ ...styles.TextXXTiny, textAlign: 'left' }}>{lang.products_buy_eshop}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRow, height: cellHeight / 2 }}>
                                            <div onClick={() => ClientsSort(5)} style={{ ...styles.Block, width: '30%', height: cellHeight / 2, cursor: 'pointer' }}>
                                                <div style={{ ...styles.BlockRowRaw }}>
                                                    <p style={{ ...styles.TextXXTiny, textAlign: 'left' }}>{lang.pieces}</p>
                                                    {sortTyp == 5 ?
                                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginLeft: 10 }} icon={sortDown == true ? faArrowDown : faArrowUp} />
                                                        : null}
                                                </div>
                                            </div>
                                            <div style={{ ...styles.BlockRow, width: '70%', height: cellHeight / 2 }}>
                                                <div style={{ ...styles.BlockLeft, width: '30%', height: cellHeight / 2, justifyContent: 'center' }}>
                                                </div>
                                                <div onClick={() => ClientsSort(6)} style={{ ...styles.BlockRight, width: '70%', height: cellHeight / 2, justifyContent: 'center', cursor: 'pointer' }}>
                                                    <div style={{ ...styles.BlockRowRaw, marginRight: cellPadding }}>
                                                        <p style={{ ...styles.TextXXTiny, textAlign: 'left' }}>{lang.booking_sum}</p>
                                                        {sortTyp == 6 ?
                                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginLeft: 10 }} icon={sortDown == true ? faArrowDown : faArrowUp} />
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* PREDAJ CELKOM */}
                                    <div style={{ ...styles.BlockRight, width: '15%', height: cellHeight, borderRight: '1px solid ' + borderColor }}>
                                        <div onClick={() => ClientsSort(7)} style={{ ...styles.Block, height: cellHeight, justifyContent: 'center', cursor: 'pointer' }}>
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                <p style={{ ...styles.TextXXTiny, textAlign: 'left' }}>{lang.stats_sum_dph}</p>
                                                {sortTyp == 7 ?
                                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginLeft: 10 }} icon={sortDown == true ? faArrowDown : faArrowUp} />
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                /*
                                *
                                * =================================================================================================================
                                * ZOZNAM ZAKAZNÍKOV
                                * =================================================================================================================
                                *
                                */
                                clients != false ? clients.map((item, index) => (
                                    <div key={item.id} style={{ ...styles.Block, height: cellHeight, borderTop: '1px solid ' + borderColor, backgroundColor: rowSelected == item.id ? global.theme_lighteen_red : index % 2 == 0 ? global.theme_gray : global.theme_white }}>
                                        <div style={{ ...styles.BlockRow }}>

                                            {/* MENO, PRIEZVISKO */}
                                            <div onClick={() => Client(item)} style={{ ...styles.BlockLeft, height: cellHeight, justifyContent: 'center', width: '20%', borderLeft: '1px solid ' + borderColor, borderRight: '1px solid ' + borderColor, cursor: 'pointer' }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left', marginLeft: cellPadding }}>{item.name} {item.surname}</p>
                                            </div>

                                            {/* PREDAJ PRODUKTROV V SALONE */}
                                            <div onClick={() => setRowSelected(item.id)} style={{ ...styles.BlockRow, width: '25%', borderRight: '1px solid ' + borderColor }}>
                                                <div style={{ ...styles.Block, width: '30%', height: cellHeight, borderRight: '1px solid ' + borderColor }}>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{item.quantity_salon}</p>
                                                </div>
                                                <div style={{ ...styles.BlockRow, width: '70%' }}>
                                                    <div style={{ ...styles.BlockLeft, width: '30%', height: cellHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextXXTiny, marginLeft: cellPadding, color: global.theme_dark_gray, textAlign: 'left' }}>{lang.obrat}</p>
                                                        <p style={{ ...styles.TextXXTiny, marginLeft: cellPadding, color: global.theme_dark_gray, textAlign: 'left', marginTop: 5 }}>{lang.rabat}</p>
                                                    </div>
                                                    <div style={{ ...styles.BlockRight, width: '70%', height: cellHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextXTiny, textAlign: 'left', marginRight: cellPadding }}>{GetPrice(ConvertToPrice(item.sum_salon))} {lang.money}</p>
                                                        <p style={{ ...styles.TextXTiny, textAlign: 'left', marginTop: 5, marginRight: cellPadding }}>{GetPrice(ConvertToPrice(item.cost_salon))} {lang.money}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* PREDAJ SLUZIEB */}
                                            <div onClick={() => setRowSelected(item.id)} style={{ ...styles.BlockRow, width: '25%', borderRight: '1px solid ' + borderColor }}>
                                                <div style={{ ...styles.Block, width: '30%', height: cellHeight, borderRight: '1px solid ' + borderColor }}>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{item.quantity_services}</p>
                                                </div>
                                                <div style={{ ...styles.BlockRow, width: '70%' }}>
                                                    <div style={{ ...styles.BlockLeft, width: '30%', height: cellHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextXXTiny, marginLeft: cellPadding, color: global.theme_dark_gray, textAlign: 'left' }}>{lang.obrat}</p>
                                                        <p style={{ ...styles.TextXXTiny, marginLeft: cellPadding, color: global.theme_dark_gray, textAlign: 'left', marginTop: 5 }}>{lang.rabat}</p>
                                                    </div>
                                                    <div style={{ ...styles.BlockRight, width: '70%', height: cellHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextXTiny, textAlign: 'left', marginRight: cellPadding }}>{GetPrice(ConvertToPrice(item.sum_services))} {lang.money}</p>
                                                        <p style={{ ...styles.TextXTiny, textAlign: 'left', marginTop: 5, marginRight: cellPadding }}>{GetPrice(ConvertToPrice(item.cost_services))} {lang.money}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* PREDAJ PRODUKTROV CEZ ESHOP */}
                                            <div onClick={() => setRowSelected(item.id)} style={{ ...styles.BlockRow, width: '25%', borderRight: '1px solid ' + borderColor }}>
                                                <div style={{ ...styles.Block, width: '30%', height: cellHeight, borderRight: '1px solid ' + borderColor }}>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{item.quantity_eshop}</p>
                                                </div>
                                                <div style={{ ...styles.BlockRow, width: '70%' }}>
                                                    <div style={{ ...styles.BlockLeft, width: '30%', height: cellHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextXXTiny, marginLeft: cellPadding, color: global.theme_dark_gray, textAlign: 'left' }}>{lang.obrat}</p>
                                                        <p style={{ ...styles.TextXXTiny, marginLeft: cellPadding, color: global.theme_dark_gray, textAlign: 'left', marginTop: 5 }}>{lang.rabat}</p>
                                                    </div>
                                                    <div style={{ ...styles.BlockRight, width: '70%', height: cellHeight, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextXTiny, textAlign: 'left', marginRight: cellPadding }}>{GetPrice(ConvertToPrice(item.sum_eshop))} {lang.money}</p>
                                                        <p style={{ ...styles.TextXTiny, textAlign: 'left', marginTop: 5, marginRight: cellPadding }}>{GetPrice(ConvertToPrice(item.cost_eshop))} {lang.money}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* PREDAJ CELKOM */}
                                            <div onClick={() => setRowSelected(item.id)} style={{ ...styles.BlockRow, width: '15%', borderRight: '1px solid ' + borderColor }}>
                                                <div style={{ ...styles.BlockLeft, width: '30%', height: cellHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextXXTiny, marginLeft: cellPadding, color: global.theme_dark_gray, textAlign: 'left' }}>{lang.obrat}</p>
                                                    <p style={{ ...styles.TextXXTiny, marginLeft: cellPadding, color: global.theme_dark_gray, textAlign: 'left', marginTop: 5 }}>{lang.rabat}</p>
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: '70%', height: cellHeight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextXTiny, textAlign: 'left', marginRight: cellPadding }}>{GetPrice(ConvertToPrice(item.sum))} {lang.money}</p>
                                                    <p style={{ ...styles.TextXTiny, textAlign: 'left', marginTop: 5, marginRight: cellPadding }}>{GetPrice(ConvertToPrice(item.cost))} {lang.money}</p>
                                                </div>                                    </div>
                                        </div>
                                    </div>
                                )) : null
                            }
                        </div>
                        :
                        <div style={{ ...styles.Block, height: 200 }}>
                            {isBusy == false ?
                                <EmptyList lang={lang} />
                                : null}
                        </div>
                    }
                </div>
            </div >

            {showCard == true ?
                <div style={{ ...styles.Block, position: 'relative', zIndex: 200 }}>
                    <ClientCard isSmall={props.isSmall} employees={props.employees} employee_id={props.employee_id} user_id={props.user.id} user={props.user} client_id={itemSelected.id} lang={lang} language={props.language} radius={props.radius} offset={props.offset} theme={props.theme} func={() => setShowCard(false)} />
                </div>
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme}  ></Loading>
                : null}

        </Paper >
    );
}
