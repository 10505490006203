/*
*
* =================================================================================================================
* OBJEDNAVKY Z E-SHOPU
* =================================================================================================================
*
*/


import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { AddDays, CategorySelect, Colors, ConvertToPrice, CopyData, Debug, DialogEnterText, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, GetDate, GetDate3, GetImageLink, GetPages, GetPrice, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, ShowError, ShowOK, TemplateWindow, Today } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, CircularProgress, FormControlLabel, IconButton, InputAdornment, MenuItem, Pagination, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faAngleLeft, faAngleRight, faCheckCircle, faEdit, faExclamation, faImage, faInfo, faL, faLayerGroup, faList, faListAlt, faPeace, faPen, faPlusCircle, faQuestion, faQuestionCircle, faSave, faShoppingBag, faShoppingBasket, faTrash, faUser, faWarning, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';
import { ProductDialog, ProductPreview, ShoppingCard } from './product_dialog';
import { ClientSelect } from './client_select';
import { centerCrop } from 'react-image-crop';
import { ProductBookingCard } from './products_booking';
import { Photos } from './photos';
import { ProductSelect } from './product_select';


export const EshopHistory = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);

    // PREDAJ - ZOZNAM
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(false);
    const itemsMax = global.items_max;

    // NAHLAD
    const [itemSelected, setItemSelected] = useState(false);
    const [showOrder, setShowOrder] = useState(false);

    const [showOK, setShowOK] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // zobrazovat nevybavene
    const [showActive, setShowActive] = useState(false);

    const selectHeight = props.isSmall ? 60 : 0;
    const rowHeight = props.isSmall ? 130 : 90;
    const rowWidth = 850;

    // COLORS
    const font_selected = global.theme_black;
    const font_disabled = global.theme_darker;

    const no_image = require('./react/app/no_image.jpg');

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;
            db_orders(0, false);
        }

    }, []);

    const db_orders = async (page, filter) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'eshop_orders', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    language: props.language,
                    start: page * itemsMax,
                    length: itemsMax,
                    filter: filter
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, itemsMax));

                setFilter(filter);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }



    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }



    const Search = (txt) => {
        setSearch(txt);
        if (txt.length > 2) {
            let filter = { active: showActive, search: txt };
            db_orders(0, filter);
        }
        if (txt == '') {
            let filter = { active: showActive };
            db_orders(0, filter);
        }
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_orders');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }


    const ChangeActive = (value) => {
        setShowActive(value);
        let filter = { active: value, search: search };
        db_orders(0, filter);
    }

    const ChangePage = (value) => {
        db_orders(value - 1, filter);
    }


    // ------------------------------------------------------------------------------------
    // NAHLAD OBJEDNAVKY
    // ------------------------------------------------------------------------------------
    const Edit = (item) => {
        setItemSelected(item);
        setShowOrder(true);
    }

    const ProductCardResult = (value, value2) => {

        setShowOrder(false);
        if (value == true || value2 == true) {
            db_orders(page, filter);
        }
    }


    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? '70%' : '40%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left', marginLeft: props.isSmall ? 10 : 0 }}>{props.title}</p>
                        {props.isSmall ? null :
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        }
                    </div>
                </div>
                {props.isSmall ? null :
                    <div style={{ ...styles.BlockRow, justifyContent: 'flex-end', alignItems: 'center', width: '20%', height: global.dialog_bar_height }}>
                        <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.show_active}</p>}
                            control={<Checkbox checked={showActive} size={'small'} onChange={() => ChangeActive(!showActive)} />}
                        />
                    </div>
                }
                <div style={{ ...styles.BlockRow, justifyContent: 'flex-end', alignItems: 'center', width: props.isSmall ? 160 : '30%', height: global.dialog_bar_height }}>
                    <TextField
                        value={search}
                        onInput={e => Search(e.target.value)}
                        size="small"
                        autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off"
                        style={{ maxWidth: props.isSmall ? 160 : 300 }}
                        placeholder={lang.search_in_category}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                            }
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                    <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                </IconButton>
                            </InputAdornment>,
                            style: { backgroundColor: global.theme_white, borderRadius: 30 },
                        }}
                        variant="outlined"
                    />
                </div>
                <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.Block, height: props.height - global.dialog_bar_height }}>
                    {props.isSmall ?
                        <div style={{ ...styles.Block, height: selectHeight, backgroundColor: global.theme_white }}>
                            <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.show_active}</p>}
                                control={<Checkbox checked={showActive} size={'small'} onChange={() => ChangeActive(!showActive)} />}
                            />
                        </div>
                        : null}

                    <div style={{ ...styles.BlockCenter, width: props.isSmall ? width : props.width, height: props.height - global.dialog_bar_height - selectHeight, backgroundColor: global.theme_white, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>

                        {/* *********************************************************************************************************  
                                ZOZNAM OBJEDNAVOK
                        ********************************************************************************************************* */}
                        <div id={'id_orders'} style={{ ...styles.BlockCenter, width: '98%' }}>
                            {itemsFiltered != false ?
                                itemsFiltered.map((item, index) => (
                                    <Paper elevation={overID == item.id ? 5 : 2} key={item.id} style={{ ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: global.theme_gray, marginTop: 8, marginBottom: 8, borderRadius: global.row_radius, cursor: 'pointer' }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow }}>
                                            <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight - 20, justifyContent: 'center' }}>
                                                <div style={{ ...styles.Block, height: rowWidth }}>
                                                    <div style={{ ...styles.Block, height: 36, width: 36, backgroundColor: global.eshop_color[item.status_id], borderRadius: '50%' }}>
                                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={global.eshop_icon[item.status_id]} />
                                                    </div>
                                                </div>
                                            </div>
                                            {props.isSmall ?
                                                <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{lang.booking_preview} <span style={{ fontSize: global.font_xtiny, fontWeight: '400' }}>{item.number}</span></p>
                                                    <p style={{ ...styles.TextTinyMobile, marginTop: 5 }}>{lang.date}: {GetDate(item.date)}</p>
                                                    <p style={{ ...styles.TextTinyMobile }}>{item.name} {item.surname}</p>
                                                    <p style={{ ...styles.TextTinyMobile, marginTop: 5 }}>{lang.booking_sum}: <b>{item.eur} {lang.money}</b></p>
                                                    <p style={{ ...styles.TextTinyMobile, marginTop: 3 }}>{lang.eshop_status[item.status_id]}</p>
                                                </div>
                                                :
                                                <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{lang.booking_preview} <span style={{ fontSize: global.font_xtiny, fontWeight: '400' }}>{item.number}</span></p>
                                                    <div style={{ ...styles.BlockLeft, width: '100%', marginTop: 5 }}>
                                                        <div style={{ ...styles.BlockRow }}>
                                                            <div style={{ ...styles.BlockLeft, width: '30%' }}>
                                                                <p style={{ ...styles.TextTiny }}>{lang.date}: {GetDate(item.date)}</p>
                                                                <p style={{ ...styles.TextTiny }}>{item.name} {item.surname}</p>
                                                            </div>
                                                            <div style={{ ...styles.Block, width: '30%' }}>
                                                                <p style={{ ...styles.TextTiny }}>{lang.booking_items}: {item.count} {lang.pieces}</p>
                                                            </div>
                                                            <div style={{ ...styles.BlockRight, width: '40%' }}>
                                                                <p style={{ ...styles.TextTiny }}>{lang.booking_sum}: <b>{item.eur} {lang.money}</b></p>
                                                                <p style={{ ...styles.TextTiny, marginTop: 3 }}>{lang.eshop_status[item.status_id]}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </Paper>
                                )) :
                                // ZIADNE OBJEDNAVKY                            
                                <div style={{ ...styles.Block, paddingTop: 20 }}>
                                    <EmptyList lang={lang} />
                                </div>
                            }

                            {pages > 1 ?
                                <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                </div>
                                : null}

                        </div>

                    </div>
                </div>
            </div>

            {
                showOrder == true ?
                    <BonusBookingDetails isSmall={props.isSmall} employee_id={props.employee_id} employees={props.employees} offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} item={itemSelected} lang={lang} radius={props.radius} backcolor={true} background={true} language={props.language} func={ProductCardResult.bind(this)} />
                    : null
            }

            {
                showOK == true ?
                    <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null
            }

            {
                isBusy == true ?
                    <Loading offset={props.offset} theme={props.theme} ></Loading>
                    : null
            }

        </Paper >
    );
}


export const BonusBookingDetails = (props) => {


    const [isBusy, setBusy] = useState(false);
    const [isUpdating, setUpdating] = useState(false);
    const [order, setOrder] = useState(false);
    const [orderStatus, setOrderStatus] = useState(0);
    const [items, setItems] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);

    // pridať produkt
    const [showProducts, setShowProducts] = useState('');
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // Delete item
    const [showAskDelete, setShowAskDelete] = useState(false);

    // Odoslať email so statusom
    const [showEmail, setShowEmail] = useState(false);

    // Zmeny v objednávke
    const [changed, setChanged] = useState(false);

    // cislo objednavky
    const [number, setNumber] = useState('');

    // existuje faktura?
    const [faktura, setFaktura] = useState(true);
    const [fakturaDays, setFakturaDays] = useState(global.faktura_days);
    const [askFaktura, setAskFaktura] = useState(false);
    const [showFakturaOK, setShowFakturaOK] = useState(false);

    // ZRUSIT OBJEDNAVKU
    const [showAskDeleteOrder, setShowAskDeleteOrder] = useState(false);

    // Rozmery okna
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1000 ? 1000 : width;
    const dialogHeight = height >= 768 ? 768 : height;

    const infoHeight = 50;
    const buttonHeight = props.isSmall ? 140 : 100;
    const rowHeight = 100;
    const titleHeight = props.isSmall ? 230 : 190;

    const no_image = require('./react/app/no_image.jpg');


    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            db_get();

        }
        return () => {
        };

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'eshop_order', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language,
                    order_id: props.item.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setItems(json.items);
                setNumber(json.order.number);
                setOrder(json.order);
                setOrderStatus(json.order.status_id);

                setFaktura(json.faktura > 0 ? true : false);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    // ZMENA STAVU OBJEDNAVKY
    const db_update = async (data) => {

        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'eshop_order_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    client_id: order.client_id,
                    data: data,
                    email_label: lang.eshop_ask_email,
                    email_text: lang.eshop_email_text + ' ' + number,
                    email_eshop: lang.www_eshop,
                    email_status: lang.eshop_status[data.status_id],
                })
            })

            const json = await response.json();

            if (json.ok > 0) {

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_delete = async (id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'eshop_item_delete', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: id,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                db_get();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_order_delete = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'eshop_order_delete', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    order_id: order.id,
                    email_label: lang.eshop_ask_email,
                    email_text: lang.eshop_email_text + ' ' + order.number,
                    email_eshop: lang.www_eshop,
                    email_status: lang.eshop_status[5],
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setOrderStatus(5);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_add = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'eshop_order_add', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                db_get();
                setChanged(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_faktura = async (data, data_items) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'faktura_create', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                    data_items: data_items
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setFaktura(true);
                setShowFakturaOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (redraw) => {
        var value = false;
        if (orderStatus != order.status_id) {
            value = true;
        }
        if (changed == true) {
            value = true;
        }
        func(value, redraw);
    }


    const DeleteItem = (item) => {
        setItemSelected(item);
        setShowAskDelete(true);
    }

    const DeleteResult = (typ) => {
        setShowAskDelete(false);
        if (typ == true) {
            setChanged(true);

            db_delete(itemSelected.id);
        }
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const ProductAdd = () => {
        setShowProducts(true);
    }

    const ProductSelectResult = (typ, item) => {
        setShowProducts(false);
        if (typ == true) {
            let data = {
                card_key: order.card_key == null ? '' : order.card_key,
                client_id: order.client_id,
                salon_id: order.salon_id,
                product_id: item.id,
                slug: item.slug,
                quantity: item.quantity,
                unit: lang.pieces,
                enabled: true,
                eur: item.eur,
                label: item.label,
                image: item.image,
                sum: (parseFloat(item.quantity) * parseFloat(item.eur)).toFixed(2),
                order_id: order.id,
                voucher_id: 0,
                code: item.code,
                cost_sum: (parseFloat(item.quantity) * parseFloat(item.cost_price)).toFixed(2),
                cost: item.cost_price,
                stack_quantity: item.stock_status,
                deleted: false
            };

            db_add(data);
        }
    }

    const ChangeStatus = (value) => {
        if (value == 2 || value == 3) {
            // ZMENA STAVU -> ODOSLAT E-MAIL ZAKAZNIKOVI?
            setShowEmail(true);
            setOrderStatus(value);
        } else if (value == 5) {
            // ZRUSENIE OBJEDNAVKY
            setShowAskDeleteOrder(true);
        } else {
            // ZMENA STATUSU -> BEZ EMAILU
            let data = {
                id: order.id,
                status_id: value,
                email: false
            }
            db_update(data);
            setOrderStatus(value);
        }
    }

    const EmailResult = (value) => {
        setShowEmail(false);

        let data = {
            id: order.id,
            status_id: orderStatus,
            email: false
        }

        if (value == true) {
            data.email = true;
        }

        db_update(data);

    }

    const OrderDeleteResult = (value) => {
        setShowAskDeleteOrder(false);

        if (value == true) {
            db_order_delete();
        }
    }

    const CreateFaktura = (value) => {
        setAskFaktura(false);

        if (value == true) {
            let data = {
                id: 0,
                uid: '',
                date_to_pay: GetDate3(AddDays(Today(), fakturaDays)),
                salon_id: props.user.id,
                eshop_id: order.id,
                order_id: 0,
                enabled: true,
                language: props.language,
                number: '',
                company: '',
                name: order.name,
                surname: order.surname,
                street: order.street,
                street_number: order.street_number,
                psc: order.psc,
                town: order.town,
                email: order.email,
                ico: '',
                dic: '',
                ic_dph: '',
                sum: order.eur,
                days: fakturaDays,
                var_symbol: '',
                kon_symbol: '',
                text: '',
                note: '',
                uhradena: false
            };

            var data_items = [];

            var n = 1;
            if (items != false) {
                items.forEach(item => {
                    let element = {
                        id: 0,
                        salon_id: props.user.id,
                        faktura_id: 0,
                        line: n,
                        label: item.label,
                        note: item.code,
                        quantity: item.quantity,
                        price: item.eur,
                        sum: item.sum
                    };

                    data_items.push(element);

                    n++;
                });
            }

            // DOPRAVA
            var element = {
                id: 0,
                salon_id: props.user.id,
                faktura_id: 0,
                line: n,
                label: order.trade,
                note: '',
                quantity: 1,
                price: order.trade_price,
                sum: order.trade_price
            };
            data_items.push(element);
            n++;

            // PLATBA
            element = {
                id: 0,
                salon_id: props.user.id,
                faktura_id: 0,
                line: n,
                label: order.payment,
                note: '',
                quantity: 1,
                price: order.payment_price,
                sum: order.payment_price
            };
            data_items.push(element);
            n++;

            Debug(data);
            Debug(data_items);

            db_faktura(data, data_items);
        }
    }

    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.Block, justifyContent: 'flex-start', height: titleHeight, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.BlockRow, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                            <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                                <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faShoppingBasket} />
                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: '60%', height: global.dialog_bar_height }}>
                            <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                                {props.isSmall ? null :
                                    <p style={{ ...styles.TextDialogLabel }}>{props.lang.eshop_card}</p>
                                }
                                <p style={{ ...styles.TextTiny, marginTop: 4, textAlign: 'left' }}>{lang.booking_number}: <b>{number}</b></p>
                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: 200, height: global.dialog_bar_height }}>
                            <div style={{ ...styles.Block, height: global.dialog_bar_height - 16, backgroundColor: global.theme_white, borderRadius: props.radius }}>
                                <p style={{ ...styles.TextTiny }}>{props.lang.sum_to_pay}</p>
                                {order != false ?
                                    <div style={{ ...styles.Block, height: 24 }}>
                                        <p style={{ ...props.isSmall ? styles.TextTiny : styles.TextNormal, marginTop: 5, fontWeight: '600' }}>{GetPrice(ConvertToPrice(order.eur))} {lang.money}</p>
                                    </div>
                                    : <div style={{ ...styles.Block, height: 24 }}>
                                        <CircularProgress size={16}></CircularProgress>
                                    </div>
                                }
                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                            <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                                <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRow, width: '95%', borderTop: '1px solid ' + global.theme_light }}>
                        <div style={{ ...styles.BlockRow, width: '50%', marginTop: 5 }}>
                            {props.isSmall ? false :
                                <div style={{ ...styles.BlockLeft, width: '20%' }}>
                                    <p style={{ ...styles.TextTiny }}>{lang.client}:</p>
                                </div>
                            }
                            <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{order.name} {order.surname}</p>
                                <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{order.street} {order.street_number}</p>
                                <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{order.psc} {order.town}</p>
                                <p style={{ ...styles.TextTiny, textAlign: 'left', marginTop: 7 }}>{lang.email.toLowerCase()}: {order.email}</p>
                                <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.mobil.toLowerCase()}: {order.mobil}</p>
                            </div>
                        </div>
                        {order != false ?
                            props.isSmall ?
                                <div style={{ ...styles.BlockLeft, width: '50%', marginTop: 5 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.eshop_trade}:</p>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left' }}><b>{order.trade}</b> {GetPrice(order.trade_price)} {lang.money}</p>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', marginTop: 5 }}>{lang.eshop_payment}:</p>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', marginTop: 0 }}><b>{order.payment}</b> {GetPrice(order.payment_price)} {lang.money}</p>
                                </div>
                                :
                                <div style={{ ...styles.BlockRight, width: '50%', marginTop: 5 }}>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.eshop_trade}: <b>{order.trade}</b> <span style={{ marginLeft: 20, fontWeight: '600' }}>{GetPrice(order.trade_price)} {lang.money}</span></p>
                                    <p style={{ ...styles.TextTiny, textAlign: 'left', marginTop: 5 }}>{lang.eshop_payment}: <b>{order.payment}</b> <span style={{ marginLeft: 20, fontWeight: '600' }}>{GetPrice(order.payment_price)} {lang.money}</span></p>
                                </div>

                            : null}
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight, backgroundColor: props.background == true ? global.theme_back : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '100%', height: dialogHeight - titleHeight - buttonHeight }}>
                        {/* BODY */}
                        <div style={{ ...styles.BlockRow, width: '98%', height: infoHeight }}>
                            <div style={{ ...styles.BlockLeft, width: '50%', height: infoHeight, justifyContent: 'center' }}>
                                <p style={{ ...props.isSmall ? styles.TextTiny : styles.TextSmall, color: global.theme_dark, textAlign: 'left' }}>{lang.eshop_products}</p>
                            </div>
                            <div style={{ ...styles.BlockRight, width: '50%', height: infoHeight, justifyContent: 'center' }}>
                                {orderStatus == 0 || orderStatus == 1 ?
                                    <Button onClick={() => ProductAdd()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 200 }}>
                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginRight: 10, color: global.theme_white }} icon={faPlusCircle} />
                                        <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.eshop_item_add}</p>
                                    </Button>
                                    : null}
                            </div>
                        </div>
                        <div style={{ ...styles.BlockCenter, width: '100%', height: dialogHeight - titleHeight - buttonHeight - infoHeight, overflowY: 'scroll', paddingBottom: 10 }}>
                            {/* *********************************************************************************************************  
                                POZNAMKA
                            ********************************************************************************************************* */}
                            {order.note != null ? order.note.trim() != '' ?
                                <Paper elevation={2} style={{ ...styles.Block, width: '96%', backgroundColor: global.theme_light_gray, marginTop: 15, marginBottom: 5, borderRadius: 5, paddingTop: 10, paddingBottom: 10 }}>
                                    <div style={{ ...styles.BlockLeft, width: '98%' }}>
                                        <p style={{ ...styles.TextTiny, marginRight: 25, textAlign: 'left' }}><b>{lang.eshop_note}:</b> {order.note}</p>
                                    </div>
                                </Paper>
                                : null : null}

                            {/* *********************************************************************************************************  
                                ZOZNAM PRODUKTOV
                            ********************************************************************************************************* */}
                            {items != false ?
                                items.map((item, index) => (
                                    <Paper elevation={2} key={item.id} style={{ ...styles.Block, width: '96%', height: rowHeight, backgroundColor: global.theme_lighter, marginTop: 5, marginBottom: 5, borderRadius: rowHeight / 2 }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            <div style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                <img src={item.image == null || item.image == '' ? no_image : item.image.substring(0, 4) == 'http' ? item.image : global.web_images + '/' + item.image} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                            </div>
                                            {props.isSmall ?
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '60%', justifyContent: 'center', marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                    <p style={{ ...styles.TextTinyMobile, marginTop: 0 }}>{item.code} <span style={{ ...styles.TextTiny }}>{item.details}</span></p>
                                                    <p style={{ ...styles.TextTinyMobile, marginTop: 5 }}>{lang.price_dph} {item.eur} {lang.money} / 1 {lang.pieces}</p>
                                                </div>
                                                :
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '70%', justifyContent: 'center', marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                    <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                        <p style={{ ...styles.TextTiny, marginTop: 0 }}>{item.code} <span style={{ ...styles.TextTiny }}>{item.details}</span></p>
                                                        <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                            <div style={{ ...styles.BlockLeft, width: '40%' }}>
                                                                <p style={{ ...styles.TextTiny, marginTop: 0 }}>{lang.price_dph} {item.eur} {lang.money} / 1 {lang.pieces}</p>
                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: '40%' }}>

                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: '20%' }}>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {props.isSmall ? null :
                                                <div style={{ ...styles.BlockRight, height: rowHeight, width: 250, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextSmall, marginRight: 25 }}>{lang.eshop_quantity}: <b>{item.quantity} {lang.pieces}</b></p>
                                                    <p style={{ ...styles.TextTiny, marginTop: 5, marginRight: 25 }}>{lang.booking_sum} {item.sum} {lang.money}</p>
                                                </div>
                                            }
                                            <div style={{ ...styles.Block, height: rowHeight, width: props.isSmall ? 70 : 100, justifyContent: 'center' }}>
                                                {orderStatus == 0 || orderStatus == 1 ?
                                                    <IconButton onClick={() => DeleteItem(item)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faTrash} />
                                                    </IconButton>
                                                    : null}
                                            </div>
                                        </div>
                                    </Paper>
                                )) :
                                // ZIADNE PRODUKTY                            
                                <div style={{ ...styles.Block, paddingTop: 20 }}>
                                    <EmptyList lang={lang} />
                                </div>
                            }
                        </div>
                    </div>

                    {props.isSmall ?
                        <div style={{ ...styles.Block, width: '100%', height: buttonHeight, backgroundColor: global.themes[props.theme].theme_lighter, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                            <div style={{ ...styles.Block, height: faktura == false ? buttonHeight / 2 : buttonHeight, justifyContent: 'center' }}>
                                <p style={{ ...styles.TextTiny, marginBottom: 2 }}>{lang.booking_status}</p>
                                {orderStatus < 5 ?
                                    <Select
                                        value={orderStatus}
                                        onChange={event => ChangeStatus(event.target.value)}
                                        size='small'
                                        style={{ backgroundColor: global.theme_white, width: '100%', borderRadius: 18, width: 300, height: 36 }}
                                    >
                                        {lang.eshop_status.map((item, index) => (
                                            <MenuItem key={index} value={index}><p style={{ ...styles.TextTiny }}>{item}</p></MenuItem>
                                        ))}
                                    </Select>
                                    :
                                    <p style={{ ...styles.TextNormal, fontWeight: '600', color: global.theme_dark_red }}>{lang.eshop_status[5]}</p>
                                }

                                {faktura == true ?
                                    <p style={{ ...styles.TextTiny, marginTop: 15, fontWeight: '600' }}>{lang.faktura_created}</p>
                                    : null}
                            </div>

                            {faktura == false ?
                                <div style={{ ...styles.Block, height: buttonHeight / 2, justifyContent: 'center' }}>
                                    <Button onClick={() => setAskFaktura(true)} style={{ ...styles.ButtonDark, width: 180 }}>{lang.faktura_create}</Button>
                                </div>
                                : null}
                        </div>
                        :
                        <div style={{ ...styles.BlockRow, width: '100%', height: buttonHeight, backgroundColor: global.themes[props.theme].theme_lighter, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                            <div style={{ ...styles.Block, width: '33%', height: buttonHeight, justifyContent: 'center' }}>
                            </div>

                            <div style={{ ...styles.Block, width: '33%', height: buttonHeight, justifyContent: 'center' }}>
                                <p style={{ ...styles.TextTiny, marginBottom: 2 }}>{lang.booking_status}</p>
                                {orderStatus < 5 ?
                                    <Select
                                        value={orderStatus}
                                        onChange={event => ChangeStatus(event.target.value)}
                                        size='small'
                                        style={{ backgroundColor: global.theme_white, width: '100%', borderRadius: 18, width: 300, height: 36 }}
                                    >
                                        {lang.eshop_status.map((item, index) => (
                                            <MenuItem key={index} value={index}><p style={{ ...styles.TextTiny }}>{item}</p></MenuItem>
                                        ))}
                                    </Select>
                                    :
                                    <p style={{ ...styles.TextNormal, fontWeight: '600', color: global.theme_dark_red }}>{lang.eshop_status[5]}</p>
                                }
                                {faktura == true ?
                                    <p style={{ ...styles.TextTiny, marginTop: 6, fontWeight: '600' }}>{lang.faktura_created}</p>
                                    : null}
                            </div>

                            <div style={{ ...styles.BlockRight, width: '33%', height: buttonHeight, justifyContent: 'center' }}>
                                {faktura == false ?
                                    <Button onClick={() => setAskFaktura(true)} style={{ ...styles.ButtonDark, width: 180, marginTop: 20, marginRight: 20 }}>{lang.faktura_create}</Button>
                                    :
                                    null
                                }
                            </div>
                        </div>
                    }
                </div>
            </Paper>

            {showFakturaOK == true ?
                <DialogInfo radius={props.radius} title={lang.info} sub_title={lang.faktura} text={lang.faktura_created_success} sub_text={lang.faktura_created_success_note} icon={faQuestion} button={props.lang.close} icon_show={faCheckCircle} icon_color={global.theme_darker_green} theme={props.theme} func={()=>setShowFakturaOK(false)} />
                : null}

            {showProducts == true ?
                <ProductSelect isSmall={props.isSmall} note quantityOnly employee_id={props.employee_id} employees={props.employees} user={props.user} lang={lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} func={ProductSelectResult.bind(this)} />
                : null}

            {askFaktura == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.faktura_create} sub_title={''} text={lang.faktura_create_ask} sub_text={lang.faktura_create_note} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={CreateFaktura.bind(this)} />
                : null}

            {showAskDelete == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.eshop_item_delete} sub_title={''} text={lang.eshop_item_delete_text} sub_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={DeleteResult.bind(this)} />
                : null}

            {showEmail == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.eshop_ask_email} sub_title={''} text={lang.eshop_ask_email_text} sub_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={EmailResult.bind(this)} />
                : null}

            {showAskDeleteOrder == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.eshop_order_delete} sub_title={''} text={lang.eshop_order_delete_text} sub_text={lang.eshop_order_delete_text_} icon={faExclamation} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={OrderDeleteResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError text={lang.faktura_error} offset={props.offset} theme={props.theme} func={ShowErrorPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop>
    );
}
