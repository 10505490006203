import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, ConvertToPrice, CopyData, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, GetDate, GetImageLink, GetPrice, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, ShowError, ShowOK, TemplateWindow, Today } from './items';
import { styles } from './styles';
import { Backdrop, Button, CircularProgress, IconButton, InputAdornment, Pagination, Paper, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCheckCircle, faEdit, faL, faLayerGroup, faList, faListAlt, faQuestion, faSave, faShoppingBag, faShoppingBasket, faTrash, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';
import { ProductDialog, ProductPreview, ShoppingCard } from './product_dialog';
import { ClientSelect } from './client_select';
import { centerCrop } from 'react-image-crop';
import { CalendarMini } from './calendar_dialog';


export const StatsToday = (props) => {
    const [isBusy, setBusy] = useState(false);

    const [actualDate, setActualDate] = useState(false);
    const [data, setData] = useState(false);

    const menuWidth = 300;

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);


    const no_image = require('./react/app/no_image.jpg');

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            let date = Today();
            setActualDate(date);
            db_get(date);
        }

    }, []);

    const db_get = async (date) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stats_today', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    language: props.language,
                    date: date
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setData(json);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = () => {
        func(false);
    }

    const CalendarResult = (date, y, m, d) => {
        db_get(date);
    }

    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                        <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                    </div>
                </div>
                <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                        <p style={{ ...styles.TextDialogSubLabel, marginTop: 4, textAlign: 'left' }}>{props.sub_title}</p>
                    </div>
                </div>
                <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Press()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                    <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRow, width: props.width, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>

                        {/* *********************************************************************************************************  
                                BODY
                        ********************************************************************************************************* */}
                        <div style={{ ...styles.BlockCenter, width: props.isSmall ? width : menuWidth, paddingTop: 0, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_gray }}>
                            <div style={{ ...styles.Block, marginTop: 10 }}>
                                <CalendarMini background={global.theme_gray} lang={props.lang} language={props.language} func={CalendarResult.bind(this)} />
                            </div>
                        </div>
                        <div style={{ ...styles.BlockCenter, width: props.isSmall ? width : props.width - menuWidth, height: props.height - global.dialog_bar_height, paddingBottom: 20 }}>
                            {data != false ?
                                <div style={{ ...styles.Block }}>
                                    <div style={{ ...styles.Block, backgroundColor: global.theme_lighteen_blue, paddingTop: 30, paddingBottom: 30 }}>
                                        <p style={{ ...styles.TextTiny }}>{lang.date}: {GetDate(actualDate)}</p>
                                        <p style={{ ...styles.TextNormal, fontWeight: '400', marginTop: 20 }}>{lang.stats_sum}: {GetPrice(ConvertToPrice(data.sum))} {lang.money}</p>
                                        <p style={{ ...styles.TextNormal, fontWeight: '400', marginTop: 2 }}>{lang.stats_sum_vouchers}: {GetPrice(ConvertToPrice(data.vouchers_sum))} {lang.money}</p>
                                        <p style={{ ...styles.TextXXLarge, fontWeight: '600', marginTop: 25 }}>{lang.stats_sum_dph}: {GetPrice(ConvertToPrice(data.sum - data.vouchers_sum))} {lang.money}</p>
                                        <p style={{ ...styles.TextTiny, fontWeight: '400', marginTop: 2 }}>{lang.stats_cost}: {GetPrice(ConvertToPrice(data.cost - data.vouchers_sum))} {lang.money}</p>
                                    </div>
                                    <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                                        <p style={{ ...styles.TextXXLarge, fontWeight: '400' }}>{lang.products}</p>
                                        <p style={{ ...styles.TextTiny, fontWeight: '400' }}>{lang.stats_products}</p>
                                        <p style={{ ...styles.TextSmall, fontWeight: '400', marginTop: 15 }}>{lang.stats_sum_products}: {GetPrice(ConvertToPrice(data.products_sum))} {lang.money}</p>
                                        <p style={{ ...styles.TextSmall, fontWeight: '400', marginTop: 2 }}>{lang.stats_cost_products}: {GetPrice(ConvertToPrice(data.products_sum_cost))} {lang.money}</p>
                                    </div>
                                    <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                                        <p style={{ ...styles.TextXXLarge, fontWeight: '400' }}>{lang.services}</p>
                                        <p style={{ ...styles.TextTiny, fontWeight: '400' }}>{lang.stats_services}</p>
                                        <p style={{ ...styles.TextSmall, fontWeight: '400', marginTop: 15 }}>{lang.stats_sum_services}: {GetPrice(ConvertToPrice(data.services_sum))} {lang.money}</p>
                                        <p style={{ ...styles.TextSmall, fontWeight: '400', marginTop: 2 }}>{lang.stats_cost_services}: {GetPrice(ConvertToPrice(data.services_sum_cost))} {lang.money}</p>
                                    </div>
                                    <div style={{ ...styles.Block, paddingTop: 20, paddingBottom: 20 }}>
                                        <p style={{ ...styles.TextXXLarge, fontWeight: '400' }}>{lang.bonus_system}</p>
                                        <p style={{ ...styles.TextSmall, fontWeight: '400', marginTop: 2 }}>{lang.stats_sum_vouchers}: {GetPrice(ConvertToPrice(data.vouchers_sum))} {lang.money}</p>
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>
                </div>
            </div>

            {
                isBusy == true ?
                    <Loading offset={props.offset} theme={props.theme}  ></Loading>
                    : null
            }

        </Paper >
    );
}
