import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { Debug, DialogLogin, FormError, GetLanguage, Init, IsEmpty, LoadLanguage, LoadLogin, Loading, Menu, MenuTitle, MenuTop, ResetLogin, SaveLanguage, SaveLogin, TextInput, nonEmail } from './items';
import { styles } from './styles';
import './items.css';

// IMAGES
import img_background from './react/app/background.jpg';
import { Button, Paper, useMediaQuery } from '@mui/material';
import { Testovanie } from './Test';
import { Shortcuts } from './shortcuts';
import { Desktop } from './desktop';
import { faAngleLeft, faCalendarDays, faComments, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Register = () => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState(false);

    // APLIKACIA
    const [appVersion, setAppVersion] = useState('');
    const [language, setLanguage] = useState(0);
    const [token, setToken] = useState('');
    const [logged, setLogged] = useState(true);

    // VZHLAD APKY
    const [offset, setOffset] = useState(16);

    // REGISTRACIA
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');
    const [radius, setRadius] = useState(isSmall ? 0 : 16);

    const logo = require('./react/app/logo.png');
    const flag_sk = require('./react/app/flag_sk.png');
    const flag_sk_bw = require('./react/app/flag_sk_bw.png');
    const flag_cz = require('./react/app/flag_cz.png');
    const flag_cz_bw = require('./react/app/flag_cz_bw.png');

    const logo_size = isSmall == true ? 200 : 300;
    const flag_size = 40;

    var lang = GetLanguage(language);

    useEffect(() => {

        if (width < global.device_small) {
            // MOBILE
            setRadius(0);
            setOffset(0);
        } else {
            // DESKTOP
            setRadius(global.radius);
            setOffset(global.offset);

            // RELOAD PAGE - AK SA ZMENI VELKOST OKNA               
            function handleResize() {
                setWidth(window.innerWidth);
                setHeight(window.innerHeight);
            }
            window.addEventListener('resize', handleResize);
        }

        SaveLanguage(0);
        lang = LoadLanguage();


    }, [])


    const db_check = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'check_email_exists', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: 0,
                    email: email
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.count > 0) {
                    setError(lang.email_exists);
                    setBusy(false);
                } else {
                    // VSETKO OK - REGISTRACIA
                    db_register();
                }
            } else {
                setError(lang.db_error)
                setBusy(false);
            }


        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_register = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url_salon + 'register', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language_id: language,
                    username: email.toLowerCase(),
                    password: password
                })
            })

            const json = await response.json();

            setBusy(false);
            if (json.ok > 0) {

                if (json.token != '') {
                    SaveLogin({ token: json.token, expire: 0, version: 2.0 });
                    navigate('/application');
                }

            } else {
                setError(lang.db_error)
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const ChangeLanguage = (value) => {
        setLanguage(value);
        SaveLanguage(value);
    }

    const Register = () => {
        setError(false);

        var err = 0;
        err += IsEmpty(email) == true ? true : false;
        err += IsEmpty(password) == true ? true : false;

        if (err > 0) {
            setError(lang.required);
        } else {
            var err = false;
            if (password.length < 8) {
                setError(lang.password_error_len);
                err = true
            }
            if (nonEmail(email) == true) {
                setError(lang.email_wrong_format);
                err = true
            }
            if (err == false) {
                // KONTROLA CI UZ EMAIL NIEJE ZAREGISTROVANY
                db_check();
            }
        }
    }

    const Login = () => {
        navigate('/');
    }

    return (
        <div style={{ ...styles.Block, height: height, alignItems: 'flex-start', justifyContent: 'flex-start', fontFamily: 'roboto', msOverflowStyle: 'none', scrollbarWidth: 'none', backgroundImage: `url(${img_background})`, backgroundSize: 'cover' }} className='container'>
            <div style={{ ...styles.Block, height: height, backgroundColor: '#00000000' }}>
                <Paper elevation={5} style={{ ...styles.Block, height: isSmall ? height : undefined, maxWidth: 650, backgroundColor: global.theme_white, borderRadius: radius, paddingBottom: isSmall ? 0 : 30 }}>
                    <div style={{ ...styles.Block, maxWidth: 650, height: isSmall ? 150 : undefined, backgroundColor: global.theme_black, borderRadius: radius, paddingTop: 30, paddingBottom: 30 }}>
                        <img src={logo} style={{ width: '100%', height: '100%', maxWidth: logo_size, maxHeight: logo_size / 3, objectFit: 'contain' }}></img>
                        <p style={{ ...isSmall ? styles.TextSmall : styles.TextLarge, color: global.theme_white, marginTop: isSmall ? 5 : 15 }}>{lang.slogan}</p>
                    </div>

                    <div style={{ ...styles.Block, width: 200, height: flag_size + 30, marginTop: isSmall ? 0 : 20 }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <div onClick={() => ChangeLanguage(0)} style={{ ...styles.Block, width: flag_size + 20, cursor: 'pointer' }}>
                                <img src={flag_sk} style={{ width: '100%', height: '100%', maxWidth: flag_size, maxHeight: flag_size, objectFit: 'contain', opacity: language == 0 ? 1.0 : 0.2 }}></img>
                            </div>
                            <div onClick={() => ChangeLanguage(1)} style={{ ...styles.Block, width: flag_size + 20, cursor: 'pointer' }}>
                                <img src={flag_cz} style={{ width: '100%', height: '100%', maxWidth: flag_size, maxHeight: flag_size, objectFit: 'contain', opacity: language == 1 ? 1.0 : 0.2 }}></img>
                            </div>
                        </div>
                    </div>

                    <div style={{ ...styles.Block, height: isSmall ? height - 150 - flag_size - 30 : undefined, width: isSmall ? width : 600, marginTop: isSmall ? 0 : 20 }}>
                        <p style={{ ...styles.TextXLarge, color: global.theme_black, fontWeight: '600' }}>{lang.register_new}</p>
                        <div style={{ ...isSmall ? styles.Block : styles.BlockRow, marginTop: 40 }}>
                            <div style={{ ...styles.BlockLeft, width: isSmall ? '75%' : '50%' }}>
                                <TextInput redraw enabled={true} lang={lang} value={email} label={lang.email} func={(txt) => { setEmail(txt) }} />
                            </div>
                            <div style={{ ...styles.BlockLeft, width: isSmall ? '75%' : '50%', marginLeft: isSmall ? 0 : 40, marginTop: isSmall ? 30 : 0 }}>
                                <TextInput redraw password enabled={true} lang={lang} value={password} label={lang.password_} func={(txt) => { setPassword(txt) }} />
                            </div>
                        </div>

                        <div style={{ ...styles.BlockCenter, marginTop: 40 }}>
                            <div style={{ ...styles.BlockCenter, height: 50 }}>
                                {error != false ?
                                    <FormError error={error} small />
                                    : null}
                            </div>
                            <Button onClick={() => Register()} style={{ ...styles.ButtonDark, width: 200, marginTop: 20 }}>{lang.register_}</Button>
                            <Button onClick={() => Login()} style={{ ...styles.ButtonLink, color: global.theme_black, marginTop: 30 }}>
                                <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginRight: 10 }} icon={faAngleLeft} />
                                {lang.login}
                            </Button>
                        </div>

                    </div>
                </Paper>
            </div>

            {isBusy == true ?
                <Loading></Loading>
                : null}
        </div >
    );
}


