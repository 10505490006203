import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { Colors, Debug, DialogCategory, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, FormError, GetDatum2, GetImageLink, GetTopPosition, Init, IsEmpty, Loading, Menu, MenuTitle, MenuTop, SelectInput, ShowError, ShowOK, TemplateWindow, TextInput } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, FormControlLabel, IconButton, InputAdornment, MenuItem, Pagination, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEarth, faLayerGroup, faList, faPlus, faPlusCircle, faQuestion, faShoppingBag, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';
import './items.css';
import { ProductSelect } from './product_select';

export const LessonsCategories = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    const [itemSelected, setItemSelected] = useState(false);
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const itemsMax = 16;

    const [editing, setEditing] = useState(false);
    const [showDialog, setShowCategory] = useState(false);

    const [services, setServices] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1200 ? 1200 : width;
    const dialogHeight = height >= 850 ? 850 : height;

    const rowHeight = 80;
    const rowWidth = props.isSmall ? width - 10 : 720;
    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;
            Debug(props);
            db_get();
        }
    }, []);


    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'lessons_categories', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language,
                    edit: true
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                if (tmp != false) {
                    /*
                    tmp.sort((a, b) => {
                        if (a.label > b.label) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                    */

                    setItems(tmp);
                    setItemsFiltered(tmp)
                    PageInit(tmp);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }

                setServices(json.services);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Redraw = (items, p, search) => {

        if (items != false) {
            var data = items;
            if (search.trim() != '') {
                data = items.filter(x => x.label.toLowerCase().includes(search.toLowerCase()));
            }

            var count = data.length;
            count = Math.ceil(count / itemsMax);
            setPageMax(count);

            data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax)
            setItemsFiltered(data);

            if (count == 0) {
                setItemsFiltered(false);
            }
            setPage(p);
            GoToStart();
        } else {
            setItemsFiltered(false);
        }
    }

    const Search = (text) => {
        setSearch(text);
        Redraw(items, 0, text);
    }

    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);

        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0, '');
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_, search);
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const AddNew = () => {
        setEditing(false);
        setShowCategory(true);
    }

    const Edit = (item) => {
        setItemSelected(item)
        setEditing(true);
        setShowCategory(true);

    }

    const DialogCategoryResult = (value) => {
        setShowCategory(false);
        if (value == true) {
            db_get();
        }
    }

    const SelectItem = (item) => {
        func(true, item);
    }

    return (
        props.select == true ?
            /*
            *
            * =================================================================================================================
            * WINDOW VERZIA
            * =================================================================================================================
            *
            */

            <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 20000 }}>
                <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                    <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                        {props.isSmall ? null :
                            <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                                <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                                    <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                                </div>
                            </div>
                        }
                        <div style={{ ...styles.Block, width: '60%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 20 : 0 }}>
                            <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                                <p style={{ ...styles.TextDialogLabel }}>{lang.lesson_category_select}</p>
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.lesson}</p>
                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: props.isSmall ? 160 : '30%', height: global.dialog_bar_height }}>
                            <TextField
                                value={search}
                                onInput={e => Search(e.target.value)}
                                size="small"
                                autoCapitalize="off"
                                autoComplete="off"
                                autoCorrect="off"
                                style={{ width: props.isSmall ? 160 : 300 }}
                                placeholder={lang.search_in_category}
                                onKeyDown={(event) => {
                                    if (event.key === 'Enter') {
                                    }
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                            <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                        </IconButton>
                                    </InputAdornment>,
                                    style: { backgroundColor: global.theme_white, borderRadius: 30 },
                                }}
                                variant="outlined"
                            />
                        </div>
                        <div style={{ ...styles.Block, width: props.isSmall ? 80 : 100, height: global.dialog_bar_height }}>
                            <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                                <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>
                    {/* BODY */}
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div id={'id_start'} style={{ ...styles.BlockCenter, paddingTop: 0 }}>
                            <div style={{ ...styles.Block, height: 50, backgroundColor: global.theme_light_gray }}>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    <div style={{ ...styles.BlockLeft, width: rowWidth - 60, height: 50, justifyContent: 'center' }}>
                                        <p style={{ ...props.isSmall ? styles.TextSmall : styles.TextNormal, fontWeight: '600' }}>{lang.lesson_categories_label}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: 50, height: 50 }}>
                                        <IconButton onClick={() => AddNew()} style={{ width: 36, height: 36, backgroundColor: global.theme_dark_red }}>
                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white }} icon={faPlus} />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>

                            <div style={{ ...styles.Block, width: rowWidth, marginTop: 20 }}>
                                {itemsFiltered != false ?
                                    itemsFiltered.map((item, index) => (
                                        <Paper key={item.id} elevation={overID == item.id ? 6 : 2} onClick={() => SelectItem(item)} style={{ ...styles.Block, minHeight: rowHeight, backgroundColor: item.enabled == true ? global.theme_white : global.theme_light_gray, marginBottom: 10, borderRadius: 20, cursor: 'pointer' }} onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)} >
                                            <div style={{ ...styles.BlockLeft, height: rowHeight, width: '96%', justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{item.label}</p>
                                                <p style={{ ...styles.TextTiny, marginTop: 5 }}>{item.service}</p>
                                                <div style={{ ...styles.BlockRow, borderTop: '1px solid #E0E0E0' }}>
                                                    <div style={{ ...styles.BlockLeft, width: '25%' }}>
                                                        <p style={{ ...styles.TextTiny, marginTop: 5 }}>{GetDatum2(item.updated)}</p>
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, width: '25%' }}>
                                                        <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.lesson_services_count}: {item.service_count}</p>
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, width: '25%' }}>
                                                        <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.lesson_products_count}: {item.product_count}</p>
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, width: '25%' }}>
                                                        {item.required == true ?
                                                            <Chip style={{ marginTop: 5 }} size="small" variant="outlined" label={lang.lesson_required_}></Chip>
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </Paper>
                                    ))
                                    :
                                    <div style={{ ...styles.Block, height: 50 }}>
                                        <p style={{ ...styles.TextTiny }}>{lang.records_none}</p>
                                    </div>
                                }
                                {itemsFiltered != false ?
                                    pageMax > 1 ?
                                        <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                            <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                        </div>
                                        : null
                                    : null}
                            </div>
                        </div>
                    </div>

                    {
                        showDialog == true ?
                            <DialogLessonCategory services={services} language={props.language} item={itemSelected} editing={editing} lang={lang} radius={props.radius} user_id={props.user.id} offset={props.offset} country={props.country} theme={props.theme} func={DialogCategoryResult.bind(this)} />
                            : null
                    }

                    {
                        isBusy == true ?
                            <Loading offset={props.offset} theme={props.theme} ></Loading>
                            : null
                    }

                </Paper >
            </Backdrop>
            :
            /*
            *
            * =================================================================================================================
            * DESKOP VERZIA
            * =================================================================================================================
            *
            */

            <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                            <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                                <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.Block, width: '60%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 20 : 0 }}>
                        <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: props.isSmall ? 160 : '30%', height: global.dialog_bar_height }}>
                        <TextField
                            value={search}
                            onInput={e => Search(e.target.value)}
                            size="small"
                            autoCapitalize="off"
                            autoComplete="off"
                            autoCorrect="off"
                            style={{ width: props.isSmall ? 160 : 300 }}
                            placeholder={lang.search_in_category}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                }
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                    </IconButton>
                                </InputAdornment>,
                                style: { backgroundColor: global.theme_white, borderRadius: 30 },
                            }}
                            variant="outlined"
                        />
                    </div>
                    <div style={{ ...styles.Block, width: props.isSmall ? 80 : 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    <div id={'id_start'} style={{ ...styles.BlockCenter, paddingTop: 0 }}>
                        <div style={{ ...styles.Block, height: 50, backgroundColor: global.theme_light_gray }}>
                            <div style={{ ...styles.BlockRowRaw }}>
                                <div style={{ ...styles.BlockLeft, width: rowWidth - 60, height: 50, justifyContent: 'center' }}>
                                    <p style={{ ...props.isSmall ? styles.TextSmall : styles.TextNormal, fontWeight: '600' }}>{lang.lesson_categories_label}</p>
                                </div>
                                <div style={{ ...styles.Block, width: 50, height: 50 }}>
                                    <IconButton onClick={() => AddNew()} style={{ width: 36, height: 36, backgroundColor: global.theme_dark_red }}>
                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_white }} icon={faPlus} />
                                    </IconButton>
                                </div>
                            </div>
                        </div>

                        <div style={{ ...styles.Block, width: rowWidth, marginTop: 20 }}>
                            {itemsFiltered != false ?
                                itemsFiltered.map((item, index) => (
                                    <Paper key={item.id} elevation={overID == item.id ? 6 : 2} onClick={() => Edit(item)} style={{ ...styles.Block, height: rowHeight, backgroundColor: item.enabled == true ? global.theme_white : global.theme_light_gray, marginBottom: 10, borderRadius: 20, cursor: 'pointer' }} onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)} >
                                        <div style={{ ...styles.BlockLeft, height: rowHeight, width: '96%', justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{item.label}</p>
                                            <p style={{ ...styles.TextTiny, marginTop: 5 }}>{item.service}</p>
                                            <div style={{ ...styles.BlockRow, borderTop: '1px solid #E0E0E0' }}>
                                                <div style={{ ...styles.BlockLeft, width: '25%' }}>
                                                    <p style={{ ...styles.TextTiny, marginTop: 5 }}>{GetDatum2(item.updated)}</p>
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '25%' }}>
                                                    <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.lesson_services_count}: {item.service_count}</p>
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '25%' }}>
                                                    <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.lesson_products_count}: {item.product_count}</p>
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: '25%' }}>
                                                    {item.required == true ?
                                                        <Chip style={{ marginTop: 5 }} size="small" variant="outlined" label={lang.lesson_required_}></Chip>
                                                        : null}
                                                </div>
                                            </div>
                                        </div>
                                    </Paper>
                                ))
                                :
                                <div style={{ ...styles.Block, height: 50 }}>
                                    <p style={{ ...styles.TextTiny }}>{lang.records_none}</p>
                                </div>
                            }
                            {itemsFiltered != false ?
                                pageMax > 1 ?
                                    <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                        <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                    </div>
                                    : null
                                : null}
                        </div>
                    </div>
                </div>

                {showDialog == true ?
                    <DialogLessonCategory services={services} language={props.language} item={itemSelected} editing={editing} lang={lang} radius={props.radius} user_id={props.user.id} offset={props.offset} theme={props.theme} country={props.country} func={DialogCategoryResult.bind(this)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset} theme={props.theme} ></Loading>
                    : null}

            </Paper >
    );
}



export const DialogLessonCategory = (props) => {
    /*
            KATEGÓRIE - UPRAVA / NOVA

            USAGE:
            <DialogLessonCategory lang={lang} radius={props.radius} func={CategoryResult.bind(this)} />

            const CategoryResult = (typ,value) => {
                console.log(value)
            }
    */
    const no_image = require('./react/app/no_image.jpg');

    const [isLoading, setLoading] = useState(true);
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState('');
    const [label, setLabel] = useState('');
    const [enabled, setEnabled] = useState(true);
    const [serviceID, setServiceID] = useState([]);

    const [products, setProducts] = useState([]);
    const [codes, setCodes] = useState([]);
    const [required, setRequired] = useState(false);
    const [showClose, setShowClose] = useState(true);

    // Vyber produktu
    const [showSelectProduct, setShowSelectProduct] = useState(false);

    // DB
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width > 500 ? 500 : width;
    const dialogHeight = height >= 680 ? 680 : height;

    const rowOffset = 20;
    const buttonHeight = 80;
    const rowHeight = 80;

    let { func } = props;
    var lang = props.lang;

    var running = false;
    useEffect(() => {
        if (running == false) {
            running = true;

            if (props.editing == true) {
                db_get();

            } else {
                setEnabled(true);
                setLoading(false);
            }
        }
        return () => {
        };

    }, []);

    const db_get = async () => {

        try {
            const response = await fetch(
                global.db_url + 'lessons_category', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.item.id,
                    language: props.country.language_id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var item = json.item;

                setLabel(item.label);
                setEnabled(item.enabled == false ? false : true);
                setServiceID(JSON.parse(item.service_id));

                setCodes(json.codes);
                setProducts(json.products);

                setRequired(item.required == false ? false : true);
            }
            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'lessons_category_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ) => {
        func(typ);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
        Press(true);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const Process = () => {
        var error = 0;

        error += IsEmpty(label) == true ? true : false;

        if (error > 0) {
            setError(lang.form_error);
        } else {
            setError('');

            var data = {
                id: props.editing == true ? props.item.id : 0,
                enabled: enabled,
                required: required,
                label: label,
                service_id: JSON.stringify(serviceID),
                product_id: JSON.stringify(codes)
            }

            db_update(data);
        }

    }

    const ChangeLabel = (txt) => {
        setLabel(txt);
    }

    const AddProduct = (value, item) => {
        setShowSelectProduct(false);
        if (value == true) {
            var codes_ = codes;
            var products_ = products;
            let code = item.code;
            if (!codes_.includes(code)) {
                codes_.push(code);
                setCodes(codes_);
                products_.push(item);
                setProducts(products_);
            }
        }
        var timer = setTimeout(() => {
            setShowClose(true);
        }, 500);
    }

    const ProductDelete = (item) => {
        var tmp = products.filter(x => x.id != item.id);
        setProducts(tmp);
        tmp = codes.filter(x => x != item.code);
        setCodes(tmp);
    }

    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faLayerGroup} />
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRow, width: '90%', height: global.dialog_bar_height, alignItems: 'center' }}>
                        <div style={{ ...styles.BlockLeft, width: 180, height: 45, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.category}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.editing == false ? lang.record_new : lang.record_edit}</p>
                        </div>
                        <div style={{ ...styles.Block, height: 45 }} >
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        {showClose == true ?
                            <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                                <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                            </IconButton>
                            : null}
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight }}>
                        <div style={{ ...styles.Block, width: '90%' }}>
                            <div style={{ ...styles.BlockLeft, height: 40, marginTop: 10 }}>
                                <FormControlLabel label={<p style={{ ...styles.TextSmall }}>{lang.category_enabled}</p>}
                                    control={<Checkbox checked={enabled} size={'small'} onChange={() => setEnabled(!enabled)} />}
                                />
                            </div>
                            <div style={{ ...styles.BlockLeft, height: 40, marginTop: 10 }}>
                                <FormControlLabel label={<p style={{ ...styles.TextSmall }}>{lang.lesson_required}</p>}
                                    control={<Checkbox checked={required} size={'small'} onChange={() => setRequired(!required)} />}
                                />
                            </div>
                            <div style={{ ...styles.Block, marginTop: rowOffset }}>
                                <div style={{ ...styles.BlockLeft }}>
                                    <TextInput required redraw enabled={true} lang={lang} value={label} label={lang.label} func={(txt) => ChangeLabel(txt)} />
                                </div>
                            </div>
                        </div>
                        <div style={{ ...styles.BlockLeft, width: '90%', marginTop: 20 }}>
                            <p style={{ ...props.isSmall ? styles.TextXTiny : styles.TextInput, color: global.theme_dark_gray, textAlign: 'left', marginBottom: 5, marginLeft: 10 }}>{lang.service_}</p>
                            {props.services != false ?
                                <SelectInput multiple default={lang.choose} theme={props.theme} enabled={true} lang={lang} value={serviceID} items={props.services} field={'label'} label={lang.mobil} radius={global.radius} func={(txt) => setServiceID(txt)} />
                                : null}
                        </div>
                        <div style={{ ...styles.BlockLeft, width: '90%', marginTop: 30, height: 40 }}>
                            <div style={{ ...styles.BlockRow, height: 40, justifyContent: 'center' }}>
                                <div style={{ ...styles.BlockLeft, width: '90%', height: 40, justifyContent: 'center' }}>
                                    <p style={{ ...props.isSmall ? styles.TextXTiny : styles.TextInput, color: global.theme_dark_gray, textAlign: 'left', marginBottom: 5, marginLeft: 10 }}>{lang.products}</p>
                                </div>
                                <IconButton onClick={() => { setShowSelectProduct(true); setShowClose(false) }} style={{ width: 34, height: 34, backgroundColor: global.theme_dark_red }}>
                                    <FontAwesomeIcon style={{ width: 16, color: global.theme_white }} icon={faPlus} />
                                </IconButton>

                            </div>
                        </div>
                        <div style={{ ...styles.BlockCenter, width: '90%', height: 230, backgroundColor: global.theme_back, borderRadius: 18, border: '1px solid #444444', msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            {products.map((item, index) => (
                                <Paper elevation={2} key={item.id} style={{ ...styles.BlockRow, width: '98%', height: rowHeight, backgroundColor: global.theme_white, marginTop: 5, marginBottom: 5, borderRadius: 20 }}>
                                    <div style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                        <img src={item.image != '' && item.image != null ? GetImageLink(item.image) : no_image} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, width: '80%', height: rowHeight, justifyContent: 'center', marginLeft: 5 }}>
                                        <p style={{ ...styles.TextTiny, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                        <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{item.code}</p>
                                    </div>
                                    <div style={{ ...styles.Block, width: 50, height: rowHeight }}>
                                        <IconButton onClick={() => ProductDelete(item)} style={{ width: 34, height: 34 }}>
                                            <FontAwesomeIcon style={{ width: 12, color: global.theme_black }} icon={faXmark} />
                                        </IconButton>

                                    </div>
                                </Paper>
                            ))}
                            {products.length == 0 ?
                                <div style={{ ...styles.Block, paddingTop: 20 }}>
                                    <EmptyList small row lang={lang} />
                                </div>
                                : null}
                        </div>
                    </div>

                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <Button onClick={() => Process()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>{lang.save}</Button>
                    </div>
                </div>
            </Paper>

            {
                showSelectProduct == true ?
                    <ProductSelect cabine={true} isSmall={props.isSmall} user={{ id: 0 }} lang={lang} language={props.country.language_id} offset={props.offset} theme={props.theme} radius={props.radius} func={AddProduct.bind(this)} />
                    : null
            }

            {
                showOK == true ?
                    <ShowOK offset={props.offset} func={ShowOKPress.bind(this)} />
                    : null
            }

            {
                showError == true ?
                    <ShowError text={props.lang.db_error} func={ShowErrorPress.bind(this)} />
                    : null
            }

            {
                isBusy == true || isLoading == true ?
                    <Loading offset={props.offset} theme={props.theme} ></Loading>
                    : null
            }

        </Backdrop >
    );
}