import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, DialogInfo, DialogLogin, FormError, EmployeeSelect, Init, Loading, Menu, MenuTitle, MenuTop, ShowError, ShowOK, TemplateWindow, TextInput, IsEmpty, DialogImagePreview, GetTid, GetPrice, DialogVideo, DialogPDF, notNumber, GetTopPosition, GetMoney, Debug } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Pagination, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faFile, faFileAlt, faFileLines, faFilePdf, faFileText, faLeaf, faList, faQuestion, faShoppingBasket, faUser, faVideo, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';
import { Photos } from './photos';


export const ServicesDialog = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <ServicesDialog radius={props.radius} title={'nazov'} sub_title={'podnázov'} backcolor={true} icon={faQuestion} background={false} func={ServicesDialogResult.bind(this)} />

            const ServicesDialogResult = (typ,value) => {
                console.log(value)
            }
    */

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState(false);
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    const [photo, setPhoto] = useState(false);
    const [showPhoto, setShowPhoto] = useState(false);
    const [showSelectPhoto, setShowSelectPhoto] = useState(false);

    const [enabled, setEnabled] = useState(true);
    const [reserve, setReserve] = useState(true);
    const [eshop, setEshop] = useState(true);
    const [categoryID, setCategoryID] = useState(0);
    const [categories, setCategories] = useState([]);
    const [variation, setVariation] = useState(props.service.brand_id > 0 && props.editing == true ? true : false);
    const [dialogHeight, setDialogHeight] = useState(props.service.brand_id > 0 && props.editing == true ? props.isSmall ? 650 : 400 : props.isSmall ? height : 650);

    const [service, setService] = useState(props.editing == true ? false : {
        label: '',
        time: '30',
        eur: '0.00',
        price: '0.00',
        time_break: '0',
        description: '',
        note: '',
    });

    const dialogWidth = props.isSmall ? width : 1000;
    const titleHeight = 120;
    const rowOffset = 20;
    const errorHeight = 40;
    const buttonHeight = 80;

    const service_1 = require('./react/app/service1.png');
    const service_2 = require('./react/app/service2.png');

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            db_get();

        }
        return () => {
        };

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'service_edit', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user_id,
                    service_id: props.service.id,
                    default: lang.services_my,
                    language: props.language,
                    my_service: props.service.typ == 1 ? true : false
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.categories;
                setCategories(tmp);
                if (tmp != false) {
                    setCategoryID(tmp[0].id);
                }

                if (props.editing == true) {
                    setService(json.service);
                    if (props.service.typ == 1) {
                        setCategoryID(json.service.category_id);
                    }
                    setEnabled(json.service.enabled == false ? false : true);
                    setReserve(json.service.reserve_enabled == false ? false : true);
                    setEshop(json.service.eshop == false ? false : true);
                } else {
                    if (props.category.brand_id == 0) {
                        setCategoryID(props.category.id);
                    } else {
                        setCategoryID(0);
                    }
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'service_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);

                var timer = setTimeout(() => {
                    Press(true, data);
                }, 1100);

            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ShowPhoto = () => {
        if (photo != false && photo != null && photo != '') {
            setShowPhoto(true);
        }
    }

    const DialogImagePreviewResult = () => {
        setShowPhoto(false);
    }

    const SelectPhoto = () => {
        setShowSelectPhoto(true);
    }

    const PhotosResult = (typ, path, file) => {
        setShowSelectPhoto(false);

        if (typ == true) {
            setPhoto(path + file);
        }
    }

    const ShowErrorPress = () => {
        setShowError(false);
    }

    const ShowOKPress = () => {
        setShowOK(false);
    }

    const Process = () => {
        var error = 0;
        setError('');

        error += IsEmpty(service.label) == true ? true : false;
        error += IsEmpty(service.price) == true ? true : false;
        error += IsEmpty(service.eur) == true ? true : false;
        error += IsEmpty(service.time_break) == true ? true : false;
        error += IsEmpty(service.time) == true ? true : false;

        error += notNumber(service.price) == true ? true : false;
        error += notNumber(service.eur) == true ? true : false;
        error += notNumber(service.time_break) == true ? true : false;
        error += notNumber(service.time) == true ? true : false;

        if (error > 0) {
            setError(lang.form_error);

        } else {
            var price = service.price.replace(',', '.');
            price = parseFloat(price).toFixed(2);

            var eur = service.eur.replace(',', '.');
            eur = parseFloat(eur).toFixed(2);


            if (variation == false) {
                // JEDNA SA O NOVY PRODUKT, ALEBO UPRAVU MOJHO PRODUKTU
                var data = {
                    variant: false,
                    id: props.editing == true ? props.service.id : 0,
                    service_id: 0,
                    salon_id: parseInt(props.user_id),
                    category_id: categoryID,
                    eur: eur,
                    price: price,
                    time: service.time,
                    label: service.label,
                    description: service.description,
                    enabled: enabled,
                    eshop: eshop,
                    language: props.language,
                    changed: 0,
                    typ: 1,
                    time_break: service.time_break,
                    reserve_enabled: reserve,
                    note: service.note
                }
            } else {
                // PREDEFINOVANY PRODUKT - JEDNA SA O VARIACIU
                var data = {
                    variant: true,
                    service_id: props.editing == true ? props.service.id : 0,
                    salon_id: parseInt(props.user_id),
                    category_id: 0,
                    eur: eur,
                    price: price,
                    time: service.time,
                    label: service.label,
                    //description: '',
                    enabled: enabled,
                    eshop: eshop,
                    language: props.language,
                    changed: 0,
                    typ: 0,
                    time_break: service.time_break,
                    reserve_enabled: reserve,
                    //note: ''
                }
            }
            Debug('TRUE');
            
            db_update(data);
        }
    }

    return (

        <Backdrop open={true} style={{ position: 'fixed', top: props.isSmall ? undefined : GetTopPosition(height, dialogHeight), paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: titleHeight, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: titleHeight + 20, height: titleHeight }}>
                            <img src={variation == false ? service_2 : service_1} style={{ width: '100%', height: '100%', maxWidth: titleHeight - 10, maxHeight: titleHeight - 10, objectFit: 'contain', borderRadius: props.radius * 0.8, cursor: 'pointer' }}></img>
                        </div>
                    }
                    <div style={{ ...styles.Block, width: props.isSmall ? '90%' : '50%', height: titleHeight, marginLeft: 20 }}>
                        <div style={{ ...styles.BlockLeft, justifyContent: 'center', height: titleHeight }}>
                            <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>
                            {props.isSmall ? null :
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 2 }}>{props.sub_title}</p>
                            }
                            {/*
                            variation == false ?
                                <Button onClick={() => SelectPhoto()} style={{ ...styles.ButtonLink, justifyContent: 'flex-start', padding: 0, marginTop: 15, height: 30 }}>{lang.photo_select}</Button>
                                : null
                            */}
                        </div>
                    </div>
                    {props.isSmall ? null :
                        <div style={{ ...styles.BlockLeft, justifyContent: 'center', width: '40%', height: titleHeight }}>
                            {service != false ?
                                <div style={{ ...styles.BlockLeft }}>
                                    <div style={{ ...styles.BlockLeft, height: (titleHeight - 20) / 3, justifyContent: 'center' }}>
                                        <FormControlLabel label={<p style={{ ...styles.TextTiny, marginLeft: 5, textAlign: 'left' }}>{lang.service_enabled}</p>}
                                            control={<Checkbox checked={enabled} size={'small'} style={{ padding: 0 }} onChange={() => setEnabled(!enabled)} />}
                                        />
                                    </div>
                                    <div style={{ ...styles.BlockLeft, height: (titleHeight - 20) / 3, justifyContent: 'center' }}>
                                        {enabled == true ?
                                            <FormControlLabel style={{ marginTop: 7 }} label={<p style={{ ...styles.TextTiny, marginLeft: 5, textAlign: 'left' }}>{lang.service_eshop}</p>}
                                                control={<Checkbox checked={eshop} size={'small'} style={{ padding: 0 }} onChange={() => setEshop(!eshop)} />}
                                            />
                                            : null}
                                    </div>
                                    <div style={{ ...styles.BlockLeft, height: (titleHeight - 20) / 3, justifyContent: 'center' }}>
                                        {enabled == true && eshop == true ?
                                            <FormControlLabel style={{ marginTop: 7 }} label={<p style={{ ...styles.TextTiny, marginLeft: 5, textAlign: 'left' }}>{lang.service_reserve}</p>}
                                                control={<Checkbox checked={reserve} size={'small'} style={{ padding: 0 }} onChange={() => setReserve(!reserve)} />}
                                            />
                                            : null}
                                    </div>
                                </div>
                                : null}
                        </div>
                    }
                    <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: titleHeight }} >
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight, backgroundColor: props.background == true ? global.theme_back : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '100%', height: dialogHeight - titleHeight - errorHeight - buttonHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        {/* BODY */}
                        {service != false ?
                            <div style={{ ...styles.Block, width: dialogWidth, paddingTop: 10, marginBottom: variation == false ? 280 : 100 }}>
                                <div style={{ ...styles.Block, width: '95%' }}>

                                    {props.isSmall ?
                                        <div style={{ ...styles.BlockLeft, width: '92%' }}>
                                            {service != false ?
                                                <div style={{ ...styles.BlockLeft }}>
                                                    <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                                        <FormControlLabel label={<p style={{ ...styles.TextTiny, marginLeft: 5, textAlign: 'left' }}>{lang.service_enabled}</p>}
                                                            control={<Checkbox checked={enabled} size={'small'} style={{ padding: 0 }} onChange={() => setEnabled(!enabled)} />}
                                                        />
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                                        {enabled == true ?
                                                            <FormControlLabel style={{ marginTop: 7 }} label={<p style={{ ...styles.TextTiny, marginLeft: 5, textAlign: 'left' }}>{lang.service_eshop}</p>}
                                                                control={<Checkbox checked={eshop} size={'small'} style={{ padding: 0 }} onChange={() => setEshop(!eshop)} />}
                                                            />
                                                            : null}
                                                    </div>
                                                    <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                                        {enabled == true && eshop == true ?
                                                            <FormControlLabel style={{ marginTop: 7 }} label={<p style={{ ...styles.TextTiny, marginLeft: 5, textAlign: 'left' }}>{lang.service_reserve}</p>}
                                                                control={<Checkbox checked={reserve} size={'small'} style={{ padding: 0 }} onChange={() => setReserve(!reserve)} />}
                                                            />
                                                            : null}
                                                    </div>
                                                </div>
                                                : null}
                                        </div>
                                        : null}

                                    {/* KATEGORIA, NAZOV SLUZBY */}
                                    <div style={{ ...variation == false ? props.isSmall ? styles.BlockCenter : styles.BlockRow : styles.BlockRowRaw, marginTop: rowOffset }}>

                                        {variation == false ?
                                            <div style={{ ...styles.BlockLeft, width: props.isSmall ? '100%' : '25%' }}>
                                                <p style={{ ...styles.TextInput, color: global.theme_dark_gray, marginLeft: 12 }}>{lang.category}</p>
                                                <Select
                                                    value={categoryID}
                                                    onChange={event => setCategoryID(event.target.value)}
                                                    size='small'
                                                    style={{ backgroundColor: global.theme_white, width: '100%', borderRadius: 20, justifyContent: 'flex-start' }}
                                                >
                                                    {categories == false ?
                                                        <MenuItem value={0}><div style={{ ...styles.BlockLeft }}><p style={{ ...styles.TextTiny }}>{lang.services_my}</p></div></MenuItem>
                                                        :
                                                        categories.map((item, index) => (
                                                            <MenuItem key={item.id} value={item.id}><div style={{ ...styles.BlockLeft }}><p style={{ ...styles.TextTiny }}>{item.label}</p></div></MenuItem>
                                                        ))}

                                                </Select>
                                            </div>
                                            : null}
                                        {variation == false ?
                                            <div style={{ ...styles.BlockLeft, marginLeft: props.isSmall ? 0 : rowOffset / 2, width: props.isSmall ? '100%' : '75%', marginTop: props.isSmall ? 20 : 0 }}>
                                                <TextInput required enabled={true} lang={lang} value={service.label} label={lang.service_name} func={(txt) => { service.label = txt }} />
                                            </div>
                                            : null}
                                    </div>


                                    <div style={{ ...variation == false ? props.isSmall ? styles.BlockCenter : styles.BlockRow : props.isSmall ? styles.BlockCenter : styles.BlockRowRaw, marginTop: rowOffset }}>
                                        {/*  CAS, CENY */}

                                        {variation == false ?
                                            <div style={{ ...styles.BlockLeft, marginLeft: props.isSmall ? 0 : rowOffset / 2, width: variation == false ? props.isSmall ? '50%' : '25%' : 250, marginTop: props.isSmall ? 20 : 0 }}>
                                                <TextInput required money money_text={lang.money} enabled={true} lang={lang} value={service.price} label={variation == true ? lang.service_cost : lang.service_cost_} func={(txt) => { service.price = txt }} />
                                            </div>
                                            : null}
                                        <div style={{ ...styles.BlockLeft, marginLeft: props.isSmall ? 0 : rowOffset / 2, width: variation == false ? props.isSmall ? '50%' : '25%' : props.isSmall ? 200 : 250, marginTop: props.isSmall ? 20 : 0 }}>
                                            <TextInput required money money_text={lang.money} enabled={true} lang={lang} value={service.eur} label={lang.price} func={(txt) => { service.eur = txt }} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, marginLeft: props.isSmall ? 0 : rowOffset / 2, width: variation == false ? props.isSmall ? '50%' : '25%' : props.isSmall ? 200 : 250, marginTop: props.isSmall ? 20 : 0 }}>
                                            <TextInput required money money_text={lang.minutes_} enabled={true} lang={lang} value={service.time} label={lang.service_time} func={(txt) => { service.time = txt }} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, marginLeft: props.isSmall ? 10 : rowOffset / 2, width: variation == false ? props.isSmall ? '50%' : '25%' : props.isSmall ? 200 : 250, marginTop: props.isSmall ? 20 : 0 }}>
                                            <TextInput required money money_text={lang.minutes_} enabled={true} lang={lang} value={service.time_break} label={lang.service_reserve_time_} func={(txt) => { service.time_break = txt }} />
                                        </div>
                                    </div>

                                    {/* KRATKY POPIS */}
                                    {variation == false ?
                                        <div style={{ ...styles.BlockLeft, marginTop: props.isSmall ? 20 : rowOffset }}>
                                            <TextInput enabled={true} lang={lang} value={service.description} label={lang.note_short} func={(txt) => { service.description = txt }} />
                                        </div>
                                        : null}


                                    {/* POZNAMKA */}
                                    {variation == false ?
                                        <div style={{ ...styles.BlockLeft, marginTop: rowOffset }}>
                                            <TextInput enabled={true} lang={lang} value={service.note} label={lang.service_note} func={(txt) => { service.note = txt }} />
                                        </div>
                                        : null}
                                </div>
                            </div>
                            : null}
                    </div>
                    <div style={{ ...styles.Block, width: '95%' }}>
                        <FormError error={error} />
                        <div style={{ ...styles.Block, height: buttonHeight }}>
                            <Button onClick={() => Process()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>{lang.save}</Button>
                        </div>
                    </div>
                </div>
            </Paper>

            {
                showSelectPhoto == true ?
                    <Photos lang={lang} typ={3} max_size={600} width={600} height={600} title={lang.photo_select} sub_title={lang.vision_system} radius={props.radius} offset={props.offset} theme={props.theme} func={PhotosResult.bind(this)} />
                    : null
            }

            {
                showPhoto == true ?
                    <DialogImagePreview file={global.web + "/" + photo} radius={props.radius} lang={lang} title={lang.preview} sub_title={lang.vision_system} background={global.theme_dark_gray} crop={false} func={DialogImagePreviewResult.bind(this)} />
                    : null
            }

            {
                showOK == true ?
                    <ShowOK offset={props.offset} func={ShowOKPress.bind(this)} />
                    : null
            }

            {
                showError == true ?
                    <ShowError text={'Chyba'} func={ShowErrorPress.bind(this)} />
                    : null
            }

            {
                isBusy == true ?
                    <Loading offset={props.offset} theme={props.theme} ></Loading>
                    : null
            }

        </Backdrop >
    );
}


export const ServicePreview = (props) => {
    /*
            NAHLAD PRODUKTU
            
            USAGE:
            <ServicePreview radius={props.radius} title={'nazov'} sub_title={'podnázov'} backcolor={true} icon={faQuestion} background={false} func={ServicePreviewResult.bind(this)} />

            const ServicePreviewResult = (typ,value) => {
                console.log(value)
            }
    */

    const [isBusy, setBusy] = useState(false);

    const [photo, setPhoto] = useState(false);
    const [showPhoto, setShowPhoto] = useState(false);
    const [service, setService] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [showPdf, setShowPdf] = useState(false);
    const [money, setMoney] = useState(GetMoney(props.language));

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = props.isSmall ? width : 1000;
    const dialogHeight = props.isSmall ? height : 600;
    const titleHeight = props.isSmall ? 120 : 180;
    const bottomHeight = 100;

    const no_image = require('./react/app/no_image.jpg');
    const service_1 = require('./react/app/service1.png');
    const service_2 = require('./react/app/service2.png');

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            db_get();

        }
        return () => {
        };

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'service_edit', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user_id,
                    service_id: props.service.id,
                    default: lang.services_my,
                    language: props.language,
                    my_service: props.service.typ == 1 ? true : false
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setService(json.service);
                //setPhoto(json.product.image);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false);
    }

    const ShowPhoto = () => {
        if (photo != false && photo != null && photo != '') {
            setShowPhoto(true);
        }
    }

    const DialogImagePreviewResult = () => {
        setShowPhoto(false);
    }

    const PlayVideo = () => {
        setShowVideo(true);
    }

    const ShowPDF = () => {
        setShowPdf(true);
    }


    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: titleHeight, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: titleHeight, height: titleHeight }}>
                            <img src={props.service.typ == 0 ? service_1 : service_2} style={{ width: '100%', height: '100%', maxWidth: titleHeight - 10, maxHeight: titleHeight - 10, objectFit: 'contain', borderRadius: props.radius * 0.8, cursor: 'pointer' }}></img>
                        </div>
                    }
                    <div style={{ ...styles.Block, width: props.isSmall ? '90%' : dialogWidth - titleHeight - 160 - 100, height: titleHeight }}>
                        <div style={{ ...styles.BlockLeft, justifyContent: 'center', height: titleHeight, marginLeft: 20 }}>
                            <p style={{ ...styles.TextDialogLabel, fontWeight: '600', textAlign: 'left' }}>{props.title}</p>
                            <p style={{ ...styles.TextTiny, marginTop: 10, textAlign: 'left', color: global.theme_dark }}>{lang.service_time_}: {service.time} {lang.minutes}</p>
                        </div>
                    </div>
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: 160, height: titleHeight }}>
                            <div style={{ ...styles.Block, justifyContent: 'center', height: titleHeight }}>
                                <Paper elevation={4} style={{ ...styles.Block, width: 120, height: 120, backgroundColor: global.theme_white, borderRadius: props.radius, cursor: 'pointer' }}>
                                    <FontAwesomeIcon style={{ height: 26, color: global.theme_light }} icon={faUser} />
                                    <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, fontWeight: '600' }}>{GetPrice(props.service.eur)} {money}</p>
                                    <p style={{ ...styles.TextXXTiny, textAlign: 'left', marginTop: 2, color: global.theme_dark_gray }}>{lang.price_dph}</p>
                                </Paper>
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: titleHeight, justifyContent: 'flex-start' }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white, marginTop: 20 }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight, backgroundColor: props.background == true ? global.theme_back : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '95%', height: dialogHeight - titleHeight - bottomHeight, overflowY: 'scroll' }}>
                        {/* BODY */}
                        {props.isSmall ?
                            <div style={{ ...styles.Block, width: 160, paddingTop: 10, paddingBottom: 10 }}>
                                <div style={{ ...styles.Block, justifyContent: 'center', height: titleHeight }}>
                                    <Paper elevation={4} style={{ ...styles.Block, width: 120, height: 120, backgroundColor: global.theme_white, borderRadius: props.radius, cursor: 'pointer' }}>
                                        <FontAwesomeIcon style={{ height: 26, color: global.theme_light }} icon={faUser} />
                                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, fontWeight: '600' }}>{GetPrice(props.service.eur)} {money}</p>
                                        <p style={{ ...styles.TextXXTiny, textAlign: 'left', marginTop: 2, color: global.theme_dark_gray }}>{lang.price_dph}</p>
                                    </Paper>
                                </div>
                            </div>
                            : null}
                        {service != false ?
                            <div style={{ ...styles.Block }}>
                                <p style={{ ...styles.TextSmall, marginTop: 20, textAlign: 'left', lineHeight: 1.2 }}>{service.description}</p>
                                <p style={{ ...styles.TextSmall, marginTop: 20, textAlign: 'left', lineHeight: 1.2 }}>{service.note}</p>
                            </div>
                            : null}
                    </div>
                    {service.typ == 0 ?
                        <div style={{ ...styles.BlockCenter, height: bottomHeight }}>
                            <div style={{ ...styles.Block, width: '98%', height: 30, backgroundColor: '#00000020', borderRadius: 15 }}>
                                <p style={{ ...styles.TextTiny }}>{lang.service_materials}</p>
                            </div>
                            <div style={{ ...styles.Block, height: bottomHeight - 30 }}>
                                <div style={{ ...styles.BlockRowRaw }}>
                                    {service.link != '' && service.link != null ?
                                        <div style={{ ...styles.Block, width: bottomHeight * 2, height: bottomHeight }}>
                                            <Paper onClick={() => PlayVideo()} elevation={4} style={{ ...styles.Block, width: (bottomHeight * 2) - 40, height: (bottomHeight / 2) - 10, backgroundColor: global.theme_white, borderRadius: 15, cursor: 'pointer' }}>
                                                <div style={{ ...styles.BlockRowRaw }}>
                                                    <FontAwesomeIcon style={{ height: 18, color: global.theme_dark, marginRight: 10 }} icon={faVideo} />
                                                    <p style={{ ...styles.TextTiny }}>Video</p>
                                                </div>
                                            </Paper>
                                        </div>
                                        : null}
                                    {service.paper != '' && service.paper != null ?
                                        <div style={{ ...styles.Block, width: bottomHeight * 2, height: bottomHeight }}>
                                            {props.remoteApp == false ?
                                                <Paper onClick={() => setShowPdf(true)} elevation={4} style={{ ...styles.Block, width: (bottomHeight * 2) - 40, height: (bottomHeight / 2) - 10, backgroundColor: global.theme_white, borderRadius: 15, cursor: 'pointer' }}>
                                                    <div style={{ ...styles.BlockRowRaw }}>
                                                        <FontAwesomeIcon style={{ height: 18, color: global.theme_dark, marginRight: 10 }} icon={faFileAlt} />
                                                        <p style={{ ...styles.TextTiny }}>PDF</p>
                                                    </div>
                                                </Paper>
                                                :
                                                <a href={global.web_pdf + service.paper} style={{ textDecoration: 'none' }} target='_blank'>
                                                    <Paper elevation={4} style={{ ...styles.Block, width: (bottomHeight * 2) - 40, height: (bottomHeight / 2) - 10, backgroundColor: global.theme_white, borderRadius: 15, cursor: 'pointer' }}>
                                                        <div style={{ ...styles.BlockRowRaw }}>
                                                            <FontAwesomeIcon style={{ height: 18, color: global.theme_dark, marginRight: 10 }} icon={faFileAlt} />
                                                            <p style={{ ...styles.TextTiny }}>PDF</p>
                                                        </div>
                                                    </Paper>
                                                </a>
                                            }
                                        </div>
                                        : null}
                                </div>
                            </div>
                        </div>
                        : null}
                </div>
            </Paper>

            {showPhoto == true ?
                <DialogImagePreview theme={props.theme} file={photo.substring(0, 4) == 'http' ? photo : global.web_images + "/" + photo} radius={props.radius} lang={lang} title={lang.preview} sub_title={lang.vision_system} background={global.theme_dark_gray} crop={false} func={DialogImagePreviewResult.bind(this)} />
                : null}

            {showVideo == true ?
                <DialogVideo theme={props.theme} file={global.web_video + service.link} radius={props.radius} lang={props.lang} title={service.label} sub_title={'Video'} background={global.theme_dark_gray} crop={false} func={() => setShowVideo(false)} />
                : null}

            {showPdf == true ?
                <DialogPDF theme={props.theme} remoteApp={props.remoteApp} file={global.web_pdf + service.paper} radius={props.radius} lang={props.lang} title={service.label} sub_title={'Pdf'} background={global.theme_dark_gray} crop={false} func={() => setShowPdf(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop>
    );
}
