import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, DialogInfo, DialogLogin, FormError, EmployeeSelect, Init, Loading, Menu, MenuTitle, MenuTop, ShowError, ShowOK, TemplateWindow, TextInput, IsEmpty, DialogImagePreview, GetTid, GetPrice, notNumber, ConvertToPrice, EmptyList, PlusMinus, GetImageLink, GetTopPosition, GetMoney, QuickHelp, DialogPDF, Debug } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, CircularProgress, FormControlLabel, IconButton, InputAdornment, MenuItem, Pagination, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCheckCircle, faFileAlt, faFileLines, faFileText, faLayerGroup, faLeaf, faList, faPerson, faQuestion, faShoppingBasket, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';
import { Photos } from './photos';
import { ClientSelect } from './client_select';
import { DialogYesNo } from './items';


export const ProductDialog = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <ProductDialog radius={props.radius} title={'nazov'} sub_title={'podnázov'} backcolor={true} icon={faQuestion} background={false} func={ProductDialogResult.bind(this)} />

            const ProductDialogResult = (typ,value) => {
                console.log(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState(false);
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    const [photo, setPhoto] = useState(false);
    const [showPhoto, setShowPhoto] = useState(false);
    const [showSelectPhoto, setShowSelectPhoto] = useState(false);

    const [enabled, setEnabled] = useState(true);
    const [action, setAction] = useState(false);
    const [eshop, setEshop] = useState(false);
    const [categoryID, setCategoryID] = useState(0);
    const [categories, setCategories] = useState([]);
    const [showNote, setShowNote] = useState(false);


    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);


    const [variation, setVariation] = useState(props.product.brand_id > 0 && props.editing == true ? true : false);
    const [dialogHeight, setDialogHeight] = useState(props.product.brand_id > 0 && props.editing == true ? props.isSmall ? 500 : 400 : props.isSmall ? height : 700);

    const [product, setProduct] = useState(props.editing == true ? false : {
        code: '',
        label: '',
        note_short: '',
        details: '25 ml',
        unit: '1',
        cost_price: '0.00',
        eur: '0.00',
        instock: '1',
        keywords: '',
        note_long: '',
    });

    const dialogWidth = props.isSmall ? width : 1000;
    const titleHeight = 120;
    const rowOffset = 20;
    const errorHeight = 40;
    const buttonHeight = 80;
    const photoSize = 220;

    const no_image = require('./react/app/no_image.jpg');


    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            db_get();

        }
        return () => {
        };

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_edit', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user_id,
                    product_id: props.product.id,
                    default: lang.products_my
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.categories;
                setCategories(tmp);

                if (tmp != false) {
                    setCategoryID(0);
                }

                if (props.editing == true) {
                    setProduct(json.product);
                    setPhoto(json.product.image);
                    setCategoryID(json.product.category_id);

                    setEnabled(json.product.enabled == false ? false : true);
                    setAction(json.product.sell == false ? false : true);
                    setEshop(json.product.eshop == false ? false : true);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);

                var timer = setTimeout(() => {
                    Press(true, data);
                }, 1100);

            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ShowPhoto = () => {
        if (photo != false && photo != null && photo != '') {
            setShowPhoto(true);
        }
    }

    const DialogImagePreviewResult = () => {
        setShowPhoto(false);
    }

    const SelectPhoto = () => {
        setShowSelectPhoto(true);
    }

    const PhotosResult = (typ, path, file) => {
        setShowSelectPhoto(false);

        if (typ == true) {
            setPhoto(path + file);
        }
    }

    const NoteLong = () => {
        setShowNote(true);
    }

    const EditorHtmlResult = (typ, text) => {
        setShowNote(false);
        if (typ == true) {
            product.note_long = text;
        }
    }

    const ShowErrorPress = () => {
        setShowError(false);
    }

    const ShowOKPress = () => {
        setShowOK(false);
    }

    const Process = () => {
        var error = 0;
        setError('');

        error += IsEmpty(product.label) == true ? true : false;
        error += IsEmpty(product.cost_price) == true ? true : false;
        error += notNumber(product.cost_price) == true ? true : false;

        error += IsEmpty(product.eur) == true ? true : false;
        error += notNumber(product.eur) == true ? true : false;

        error += IsEmpty(product.unit) == true ? true : false;
        error += notNumber(product.unit) == true ? true : false;

        error += IsEmpty(product.instock) == true ? true : false;
        error += notNumber(product.instock) == true ? true : false;

        if (error == 0) {
            var keywords = product.keywords.toLowerCase();
            keywords = keywords.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            keywords = keywords.replaceAll(',', ' ');
            keywords = keywords.replaceAll('    ', ' ');
            keywords = keywords.replaceAll('   ', ' ');
            keywords = keywords.replaceAll('  ', ' ');
            keywords = keywords.replaceAll('  ', ' ');

            var slug = GetTid(product.label);
            var cost_price = product.cost_price.replace(',', '.');
            cost_price = parseFloat(cost_price).toFixed(2);

            var eur = product.eur.replace(',', '.');
            eur = parseFloat(eur).toFixed(2);


            if (variation == false) {
                // JEDNA SA O NOVY PRODUKT, ALEBO UPRAVU MOJHO PRODUKTU
                var data = {
                    variant: false,
                    id: props.editing == true ? props.product.id : 0,
                    enabled: enabled,
                    label: product.label,
                    category_id: parseInt(categoryID),
                    image: photo == false ? null : photo,
                    instock: product.instock,
                    note_short: product.note_short,
                    note_long: product.note_long,
                    flag_id: 0,
                    eur: eur,
                    slug: slug,
                    salon_id: parseInt(props.user_id),
                    brand_id: 0,
                    language: props.language,
                    keywords: keywords,
                    care: 0,
                    cost_price: cost_price,
                    details: product.details,
                    code: product.code,
                    changed: 0,
                    ean: '',
                    structure: '',
                    available: true,
                    unit: product.unit,
                    editable: true,
                    action: false,
                    eshop: eshop,
                    booking: false,
                    background_color: '#DAC7B8',
                    text_color: '#000000'
                }
            } else {
                // PREDEFINOVANY PRODUKT - JEDNA SA O VARIACIU
                var data = {
                    variant: true,
                    //eshop: enabled,
                    product_id: props.editing == true ? props.product.id : 0,
                    salon_id: parseInt(props.user_id),
                    flag_id: 0,
                    eur: eur,
                    instock: product.instock,
                    sell: 0,
                    //price: cost_price,
                    language: props.language,
                    changed: 0,
                    //new: news,
                    //action: action,
                }
            }

            db_update(data);

        } else {
            setError(lang.form_error);
        }
    }

    return (

        <Backdrop open={true} style={{ position: 'fixed', top: props.isSmall ? undefined : GetTopPosition(height, dialogHeight), paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: titleHeight, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: titleHeight + 20, height: titleHeight }}>
                        <img onClick={() => ShowPhoto()} src={photo == false || photo == null || photo == '' ? no_image : GetImageLink(photo)} style={{ width: '100%', height: '100%', maxWidth: titleHeight - 10, maxHeight: titleHeight - 10, objectFit: 'contain', borderRadius: props.radius * 0.8, cursor: 'pointer' }}></img>
                    </div>
                    <div style={{ ...styles.Block, width: '55%', height: titleHeight, marginLeft: 20 }}>
                        <div style={{ ...styles.BlockLeft, justifyContent: 'center', height: titleHeight }}>
                            <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>
                            {props.isSmall ? null :
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 2 }}>{props.sub_title}</p>
                            }
                            {variation == false ?
                                <Button onClick={() => SelectPhoto()} style={{ ...styles.ButtonLink, justifyContent: 'flex-start', padding: 0, marginTop: 15, height: 30 }}>{lang.photo_select}</Button>
                                : null}
                        </div>
                    </div>
                    {props.isSmall ? null :
                        < div style={{ ...styles.BlockLeft, width: '35%', height: titleHeight, justifyContent: 'center' }}>
                            <div style={{ ...styles.BlockLeft, height: titleHeight / 3, justifyContent: 'center' }}>
                                {variation == false ?
                                    <div style={{ ...styles.BlockLeft }}>
                                        <FormControlLabel label={<p style={{ ...styles.TextTiny, marginLeft: 5, textAlign: 'left' }}>{lang.product_active}</p>}
                                            control={<Checkbox checked={enabled} size={'small'} style={{ padding: 0, marginLeft: 10 }} onChange={() => setEnabled(!enabled)} />}
                                        />
                                    </div>
                                    : null}
                            </div>
                            <div style={{ ...styles.BlockLeft, height: titleHeight / 3, justifyContent: 'center' }}>
                                {variation == false && enabled == true ?
                                    <div style={{ ...styles.BlockLeft }}>
                                        <FormControlLabel label={<p style={{ ...styles.TextTiny, marginLeft: 5, textAlign: 'left' }}>{lang.product_eshop_enabled}</p>}
                                            control={<Checkbox checked={eshop} size={'small'} style={{ padding: 0, marginLeft: 10 }} onChange={() => setEshop(!eshop)} />}
                                        />
                                    </div>
                                    : null}
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: titleHeight }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight, backgroundColor: props.background == true ? global.theme_back : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '100%', height: dialogHeight - titleHeight - errorHeight - buttonHeight, overflowY: 'scroll' }}>
                        {/* BODY */}
                        {product != false ?
                            <div style={{ ...styles.Block, width: dialogWidth, paddingTop: 10, marginBottom: props.isSmall ? 250 : 300 }}>

                                {props.isSmall ?
                                    <div style={{ ...styles.BlockLeft, width: '96%' }}>
                                        <div style={{ ...styles.BlockLeft }}>
                                            {variation == false ?
                                                <div style={{ ...styles.BlockLeft }}>
                                                    <FormControlLabel label={<p style={{ ...styles.TextTiny, marginLeft: 5, textAlign: 'left' }}>{lang.product_active}</p>}
                                                        control={<Checkbox checked={enabled} size={'small'} style={{ padding: 0, marginLeft: 10 }} onChange={() => setEnabled(!enabled)} />}
                                                    />
                                                </div>
                                                : null}
                                        </div>
                                        <div style={{ ...styles.BlockLeft, height: titleHeight / 3, justifyContent: 'center', marginTop: 10 }}>
                                            {variation == false && enabled == true ?
                                                <div style={{ ...styles.BlockLeft }}>
                                                    <FormControlLabel label={<p style={{ ...styles.TextTiny, marginLeft: 5, textAlign: 'left' }}>{lang.product_eshop_enabled}</p>}
                                                        control={<Checkbox checked={eshop} size={'small'} style={{ padding: 0, marginLeft: 10 }} onChange={() => setEshop(!eshop)} />}
                                                    />
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                    : null}


                                <div style={{ ...styles.Block, width: '95%', marginTop: props.isSmall ? 20 : 0 }}>
                                    {variation == false ?
                                        <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRow }}>
                                            {/* KOD PRODUKTU */}
                                            <div style={{ ...styles.BlockLeft, width: props.isSmall ? '100%' : '30%' }}>
                                                <TextInput required enabled={true} lang={lang} value={product.code} label={lang.code} func={(txt) => { product.code = txt }} />
                                            </div>
                                            {/* NAZOV PRODUKTU */}
                                            <div style={{ ...styles.BlockLeft, marginLeft: props.isSmall ? 0 : rowOffset, width: props.isSmall ? '100%' : '70%', marginTop: props.isSmall ? 20 : 0 }}>
                                                <TextInput required enabled={true} lang={lang} value={product.label} label={lang.product_name} func={(txt) => { product.label = txt }} />
                                            </div>
                                        </div>
                                        : null}

                                    {variation == false ?
                                        <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRow, marginTop: rowOffset }}>
                                            {/* KATEGORIA */}
                                            <div style={{ ...styles.BlockLeft, width: props.isSmall ? '100%' : '30%' }}>
                                                <p style={{ ...styles.TextInput, color: global.theme_dark_gray, marginLeft: 12 }}>{lang.category}</p>
                                                <Select
                                                    value={categoryID}
                                                    onChange={event => setCategoryID(event.target.value)}
                                                    size='small'
                                                    style={{ backgroundColor: global.theme_white, width: '100%', borderRadius: 20, justifyContent: 'flex-start' }}
                                                >
                                                    {categories == false ?
                                                        <MenuItem value={0}><div style={{ ...styles.BlockLeft }}><p style={{ ...styles.TextTiny }}>{lang.products_my}</p></div></MenuItem>
                                                        :
                                                        categories.map((item, index) => (
                                                            <MenuItem key={item.id} value={item.id}><div style={{ ...styles.BlockLeft }}><p style={{ ...styles.TextTiny }}>{item.label}</p></div></MenuItem>
                                                        ))}

                                                </Select>
                                            </div>
                                            {/* KRATKY POPIS */}
                                            <div style={{ ...styles.BlockLeft, marginLeft: props.isSmall ? 0 : rowOffset, width: props.isSmall ? '100%' : '70%', marginTop: props.isSmall ? 20 : 0 }}>
                                                <TextInput enabled={true} lang={lang} value={product.note_short} label={lang.note_short} func={(txt) => { product.note_short = txt }} />
                                            </div>
                                        </div>
                                        : null}
                                    {/* OBJEM, POCET KUSOV V BALENI, NAKUPNA CENA, PREDAJNA CENA, SKLADOM */}
                                    <div style={{ ...variation == false ? props.isSmall ? styles.BlockCenter : styles.BlockRow : styles.BlockRowRaw, marginTop: rowOffset }}>
                                        {variation == false ?
                                            <div style={{ ...styles.BlockLeft, width: props.isSmall ? '50%' : '20%' }}>
                                                <TextInput enabled={true} lang={lang} value={product.details} label={lang.details} func={(txt) => { product.details = txt }} />
                                            </div>
                                            : null}
                                        {variation == false ?
                                            <div style={{ ...styles.BlockLeft, marginLeft: props.isSmall ? 0 : rowOffset / 2, width: props.isSmall ? '50%' : '20%', marginTop: props.isSmall ? 20 : 0 }}>
                                                <TextInput required money money_text={lang.pieces} enabled={true} lang={lang} value={product.unit} label={lang.units} func={(txt) => { product.unit = txt }} />
                                            </div>
                                            : null}
                                        {variation == false ?
                                            <div style={{ ...styles.BlockLeft, marginLeft: props.isSmall ? 0 : rowOffset / 2, width: variation == false ? props.isSmall ? '50%' : '20%' : 200, marginTop: props.isSmall ? 20 : 0 }}>
                                                <TextInput required money money_text={lang.money} enabled={true} lang={lang} value={product.cost_price} label={lang.cost} func={(txt) => { product.cost_price = txt }} />
                                            </div>
                                            : null}
                                        <div style={{ ...styles.BlockLeft, marginLeft: props.isSmall ? 0 : rowOffset / 2, width: variation == false ? props.isSmall ? '50%' : '20%' : props.isSmall ? 170 : 200, marginTop: props.isSmall ? 20 : 0 }}>
                                            <TextInput required money money_text={lang.money} enabled={true} lang={lang} value={product.eur} label={lang.price} func={(txt) => { product.eur = txt }} />
                                        </div>
                                        <div style={{ ...styles.BlockLeft, marginLeft: props.isSmall ? 10 : rowOffset / 2, width: variation == false ? props.isSmall ? '50%' : '20%' : props.isSmall ? 170 : 200, marginTop: props.isSmall ? 20 : 0 }}>
                                            <TextInput required money money_text={lang.pieces} enabled={true} lang={lang} value={product.instock} label={lang.stock_items} func={(txt) => { product.instock = txt }} />
                                        </div>
                                    </div>

                                    {/* KEYWORDS */}
                                    {variation == false ?
                                        <div style={{ ...styles.BlockLeft, marginTop: rowOffset }}>
                                            <TextInput enabled={true} lang={lang} value={product.keywords} label={lang.keywords} func={(txt) => { product.keywords = txt }} />
                                        </div>
                                        : null}
                                    {/* DETAILNY POPIS PRODUKTU */}
                                    {variation == false ?
                                        <div style={{ ...styles.Block, marginTop: rowOffset * 2 }}>
                                            <Button onClick={() => NoteLong()} style={{ ...styles.ButtonDark, backgroundColor: global.theme_dark_gray, width: 280 }}>
                                                <FontAwesomeIcon style={{ height: 18, marginRight: 10 }} icon={faFileLines} />
                                                {lang.note_long}
                                            </Button>
                                        </div>
                                        : null}
                                </div>
                            </div>
                            : null}
                    </div>
                    <div style={{ ...styles.Block, width: '95%' }}>
                        <FormError error={error} />
                        <div style={{ ...styles.Block, height: buttonHeight }}>
                            <Button onClick={() => Process()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>{lang.save}</Button>
                        </div>
                    </div>
                </div>
            </Paper>

            {
                showSelectPhoto == true ?
                    <Photos lang={lang} typ={2} max_size={600} width={600} height={600} full={false} title={lang.photo_select} sub_title={lang.vision_system} radius={props.radius} offset={props.offset} theme={props.theme} func={PhotosResult.bind(this)} />
                    : null
            }

            {
                showPhoto == true ?
                    <DialogImagePreview theme={props.theme} file={GetImageLink(photo)} radius={props.radius} lang={lang} title={lang.preview} sub_title={lang.vision_system} background={global.theme_dark_gray} crop={false} func={DialogImagePreviewResult.bind(this)} />
                    : null
            }

            {
                showNote == true ?
                    <EditorHtml isSmall={props.isSmall} theme={props.theme} text={product.note_long} lang={lang} radius={props.radius} func={EditorHtmlResult.bind(this)} />
                    : null
            }

            {
                showOK == true ?
                    <ShowOK theme={props.theme} offset={props.offset} func={ShowOKPress.bind(this)} />
                    : null
            }

            {
                showError == true ?
                    <ShowError offset={props.offset} theme={props.theme} text={lang.db_error} func={ShowErrorPress.bind(this)} />
                    : null
            }

            {
                isBusy == true ?
                    <Loading offset={props.offset} theme={props.theme} ></Loading>
                    : null
            }

        </Backdrop >
    );
}


export const ProductPreview = (props) => {
    /*
            NAHLAD PRODUKTU
            
            USAGE:
            <ProductPreview radius={props.radius} title={'nazov'} sub_title={'podnázov'} backcolor={true} icon={faQuestion} background={false} func={ProductPreviewResult.bind(this)} />

            sell    ->true - moznost kupit produkt

            const ProductPreviewResult = (typ,value) => {
                console.log(value)
            }
    */

    const [isBusy, setBusy] = useState(false);

    const [photo, setPhoto] = useState(false);
    const [showPhoto, setShowPhoto] = useState(false);
    const [product, setProduct] = useState(false);
    const [showStructure, setShowStructure] = useState(false);
    const [money, setMoney] = useState(GetMoney(props.language));

    const [showOK, setShowOK] = useState(false);
    const [showPdf, setShowPdf] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = props.isSmall ? width : 1000;
    const dialogHeight = props.isSmall ? height : props.product.care == 1 ? 400 : 700;
    const titleHeight = 180;
    const bottomHeight = 80;
    const photoSize = props.isSmall ? 100 : titleHeight - 10;

    const no_image = require('./react/app/no_image.jpg');


    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            db_get();

        }
        return () => {
        };

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_edit', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user_id,
                    product_id: props.product.id,
                    default: lang.products_my
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setProduct(json.product);
                setPhoto(json.product.image);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_product_buy = async (product_id, quantity) => {

        try {
            const response = await fetch(
                global.db_url + 'product_buy', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language,
                    salon_id: props.user_id,
                    product_id: product_id,
                    quantity: quantity
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                product.shopping_card = 1;
                setShowOK(true);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const Close = () => {
        func(false, product);
    }

    const ShowPhoto = () => {
        if (photo != false && photo != null && photo != '') {
            setShowPhoto(true);
        }
    }

    const DialogImagePreviewResult = () => {
        setShowPhoto(false);
    }

    const ProductBuy = () => {
        if (props.user.typ == global.account_salon) {
            if (props.sell == true) {
                db_product_buy(product.id, 1);
            }
        }
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: titleHeight, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: photoSize + 10, height: titleHeight }}>
                        <img onClick={() => ShowPhoto()} src={photo == false || photo == null || photo == '' ? no_image : photo.substring(0, 4) == 'http' ? photo : global.web_images + '/' + photo} style={{ width: '100%', height: '100%', maxWidth: photoSize, maxHeight: photoSize, objectFit: 'contain', borderRadius: props.radius * 0.8, cursor: 'pointer' }}></img>
                    </div>
                    <div style={{ ...styles.Block, width: props.isSmall ? '60%' : dialogWidth - titleHeight - 160 - 100, height: titleHeight }}>
                        <div style={{ ...styles.BlockLeft, justifyContent: 'center', height: titleHeight, marginLeft: 20 }}>
                            <p style={{ ...styles.TextTiny, marginBottom: 2, textAlign: 'left', color: global.themes[props.theme].theme_darker }}>{product.code}</p>
                            <p style={{ ...styles.TextDialogLabel, fontWeight: '600', textAlign: 'left' }}>{props.title}</p>
                            {props.isSmall ? null :
                                <p style={{ ...styles.TextSmall, marginTop: 5, textAlign: 'left' }}>{props.sub_title}</p>
                            }
                            <p style={{ ...styles.TextTiny, marginTop: 20, textAlign: 'left', color: global.themes[props.theme].theme_darker }}>{product.details}</p>
                            <p style={{ ...styles.TextTiny, marginTop: 5, textAlign: 'left', color: global.themes[props.theme].theme_darker }}>{lang.stock}: {product.instock} {lang.pieces}</p>
                        </div>
                    </div>
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: 160, height: titleHeight }}>
                            <div style={{ ...styles.Block, justifyContent: 'center', height: titleHeight }}>
                                {(product.shopping_card == 0 && props.sell == true) || props.sell == undefined ?
                                    <Paper onClick={() => ProductBuy()} elevation={4} style={{ ...styles.Block, width: 120, height: 120, backgroundColor: global.theme_white, borderRadius: props.radius, cursor: 'pointer' }}>
                                        {props.user.typ == global.account_salon ?
                                            <FontAwesomeIcon style={{ height: 26, color: global.theme_light }} icon={props.sell == true ? faShoppingBasket : faUser} />
                                            : null}
                                        <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, fontWeight: '600' }}>{GetPrice(props.product.eur)} {money}</p>
                                        <p style={{ ...styles.TextXXTiny, textAlign: 'left', marginTop: 2, color: global.theme_dark_gray }}>{lang.price_dph}</p>
                                        <QuickHelp text={lang.price_final} color={global.theme_light_gray} theme={props.theme} marginLeft={10} lang={props.lang} />
                                    </Paper>
                                    : null}
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: titleHeight, justifyContent: 'flex-start' }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white, marginTop: 20 }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight, backgroundColor: props.background == true ? global.theme_back : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '95%', height: dialogHeight - titleHeight - bottomHeight, overflowY: 'scroll' }}>
                        {/* BODY */}
                        {props.isSmall ?
                            <div style={{ ...styles.Block, width: 160, paddingTop: 10, paddingBottom: 10 }}>
                                <div style={{ ...styles.Block, justifyContent: 'center' }}>
                                    {(product.shopping_card == 0 && props.sell == true) || props.sell == undefined ?
                                        <Paper onClick={() => ProductBuy()} elevation={4} style={{ ...styles.Block, width: 120, height: 120, backgroundColor: global.theme_white, borderRadius: props.radius, cursor: 'pointer' }}>
                                            {props.user.typ == global.account_salon ?
                                                <FontAwesomeIcon style={{ height: 26, color: global.theme_light }} icon={props.sell == true ? faShoppingBasket : faUser} />
                                                : null}
                                            <p style={{ ...styles.TextNormal, textAlign: 'left', marginTop: 10, fontWeight: '600' }}>{GetPrice(props.product.eur)} {money}</p>
                                            <p style={{ ...styles.TextXXTiny, textAlign: 'left', marginTop: 2, color: global.theme_dark_gray }}>{lang.price_dph}</p>
                                        </Paper>
                                        : null}
                                </div>
                            </div>
                            : null}
                        {props.isSmall ?
                            <p style={{ ...styles.TextSmall, marginTop: 5, textAlign: 'left' }}>{props.sub_title}</p>
                            : null}
                        {product != false ?
                            <div style={{ ...styles.TextSmall, textAlign: 'left', paddingTop: 20, paddingBottom: 20 }} dangerouslySetInnerHTML={{ __html: product.brand_id == 0 ? product.note_long : product.note_html }} />
                            : null}
                    </div>
                    <div style={{ ...styles.Bloc, height: bottomHeight }}>
                        <div style={{ ...styles.BlockRow, height: bottomHeight }}>
                            <div style={{ ...styles.Block, width: props.isSmall ? '50%' : 200, height: bottomHeight }}>
                                {props.product.salon_id == 0 ?
                                    <Button onClick={() => setShowStructure(true)} style={{ ...styles.ButtonDark, width: props.isSmall ? 160 : 180, backgroundColor: global.themes[props.theme].button_dark }}>{lang.products_structure}</Button>
                                    : null}
                            </div>
                            {product.pdf != '' && product.pdf != null ?
                                <div style={{ ...styles.Block, width: props.isSmall ? '50%' : 200, height: bottomHeight, marginLeft: 10 }}>
                                    {props.remoteApp == false ?
                                        <Button onClick={() => setShowPdf(true)} style={{ ...styles.ButtonDark, width: props.isSmall ? 160 : 180, backgroundColor: global.themes[props.theme].button_dark }}>
                                            {props.isSmall ? null :
                                                <FontAwesomeIcon style={{ height: 18, color: global.theme_white, marginRight: 10 }} icon={faFileAlt} />
                                            }
                                            <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.product_card}</p>
                                        </Button>
                                        :
                                        <a href={global.web_pdf + product.pdf} style={{ ...styles.ButtonDark, width: props.isSmall ? 160 : 180, backgroundColor: global.themes[props.theme].button_dark, textDecoration: 'none' }} target='_blank'>
                                            <div style={{ ...styles.Block, width: 180, height: 36 }}>
                                                <div style={{ ...styles.BlockRowRaw }}>
                                                    {props.isSmall ? null :
                                                        <FontAwesomeIcon style={{ height: 18, color: global.theme_white, marginRight: 10 }} icon={faFileAlt} />
                                                    }
                                                    <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.product_card}</p>
                                                </div>
                                            </div>
                                        </a>
                                    }
                                </div>
                                : null}
                        </div>
                    </div>
                </div>
            </Paper>

            {showPdf == true ?
                <DialogPDF theme={props.theme} remoteApp={props.remoteApp} file={global.web_pdf + product.pdf} radius={props.radius} lang={props.lang} title={props.product.label} sub_title={'Pdf'} background={global.theme_dark_gray} crop={false} func={() => setShowPdf(false)} />
                : null}

            {showStructure == true ?
                <ProductStructure isSmall={props.isSmall} product={product} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} func={() => setShowStructure(false)} />
                : null}

            {showPhoto == true ?
                <DialogImagePreview theme={props.theme} file={photo.substring(0, 4) == 'http' ? photo : global.web_images + "/" + photo} radius={props.radius} lang={lang} title={lang.preview} sub_title={lang.vision_system} background={global.theme_dark_gray} crop={false} func={DialogImagePreviewResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop >
    );
}


export const ShoppingCard = (props) => {
    /*            
            
            USAGE:
            <ShoppingCard func={ShoppingCardResult.bind(this)} />

            const ShoppingCardResult = (value) => {
                console.log(value)
            }
            
    */
    const no_image = require('./react/app/no_image.jpg');

    const [isBusy, setBusy] = useState(true);
    const [isUpdating, setUpdating] = useState(false);
    const [redrawRequired, setRedrawRequired] = useState(false);

    const [products, setProducts] = useState(false);
    const [sum, setSum] = useState(0);
    const [overID, setOverID] = useState(-1);

    const [client, setClient] = useState(false);
    const [showClient, setShowClient] = useState(false);
    const [showFinish, setShowFinish] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1000 ? 1000 : width;
    const dialogHeight = height >= 768 ? 768 : height;

    const rowHeight = 100;
    const rowWidth = 980;

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {

        if (running == false) {
            running = true;
            db_shopping_card();
        }

        return () => {
        };

    }, []);


    const Press = () => {
        func(redrawRequired);
    }

    const db_shopping_card = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'shopping_card', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language,
                    salon_id: props.user_id,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {

                if (json.products != false) {
                    var tmp = json.products;
                    GetSume(tmp);
                    setProducts(tmp);
                }
            }
            setBusy(false);
        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_product_update = async (product_id, quantity) => {
        setUpdating(true);
        try {
            const response = await fetch(
                global.db_url + 'product_buy', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language,
                    salon_id: props.user_id,
                    product_id: product_id,
                    quantity: quantity
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = products.find(x => x.id == product_id);

                if (tmp != undefined) {
                    tmp.quantity = quantity;
                    GetSume(products);
                }

                // aktualizovanie produktov v kosiku
                tmp = products.filter(x => x.quantity > 0);
                if (tmp.length == 0) {
                    setProducts(false);
                } else {
                    setProducts(tmp);
                }
                setRedrawRequired(true);
            }
            setUpdating(false);

        } catch (error) {
            console.log(error);
            setUpdating(false);
        }
    }

    const db_checkout = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'checkout', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language,
                    salon_id: props.user_id,
                    client_id: client == false ? 0 : client.id,
                    worker_id: props.employee_id
                })
            })

            const json = await response.json();

            setBusy(false);
            if (json.ok > 0) {
                func(true);
            }


        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const GetSume = (items) => {
        var sume = 0;
        items.forEach(item => {
            sume = sume + (item.quantity * item.eur);
        });
        setSum(sume.toFixed(2));
    }

    const PlusMinusResult = (value, item) => {
        db_product_update(item.id, value);
    }

    const Client = () => {
        setShowClient(true);
    }

    const ClientSelectResult = (typ, item) => {
        setShowClient(false);
        if (typ == true) {
            setClient(item);
        }
    }

    const ClientDelete = () => {
        setClient(false);
    }

    const CheckoutResult = (value) => {
        setShowFinish(false);
        if (value == true) {
            db_checkout();
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faShoppingBasket} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '60%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.lang.basket}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.lang.products_shop}</p>
                        </div>
                    </div>

                    <div style={{ ...styles.Block, width: 220, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, height: global.dialog_bar_height - 16, backgroundColor: global.theme_white, borderRadius: props.radius }}>
                            <p style={{ ...props.isSmall ? styles.TextXTiny : styles.TextTiny }}>{props.lang.sum_to_pay}</p>
                            {isUpdating == false ?
                                <div style={{ ...styles.Block, height: 24 }}>
                                    <p style={{ ...props.isSmall ? styles.TextSmall : styles.TextNormal, marginTop: 5, fontWeight: '600' }}>{GetPrice(sum)} {props.lang.money}</p>
                                </div>
                                : <div style={{ ...styles.Block, height: 24 }}>
                                    <CircularProgress size={16}></CircularProgress>
                                </div>
                            }
                        </div>
                    </div>

                    <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: global.theme_white, overflowY: 'scroll' }}>
                    <div style={{ ...styles.BlockCenter, width: '98%', paddingTop: 5, paddingBottom: 5 }}>
                        {products != false ?
                            products.map((item, index) => (
                                <Paper elevation={overID == item.id ? 5 : 2} key={item.id} style={{ ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: item.enabled == true ? global.theme_lighter : global.theme_gray, marginTop: 5, marginBottom: 5, borderRadius: rowHeight / 2 }}
                                    onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                >
                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                            <img src={item.image == null || item.image == '' ? no_image : item.image.substring(0, 4) == 'http' ? item.image : global.web_images + '/' + item.image} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                        </div>
                                        {props.isSmall ?
                                            <div style={{ ...styles.BlockLeft, height: rowHeight, width: '60%', justifyContent: 'center', marginLeft: 10 }}>
                                                <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.price__}: {GetPrice(item.eur)} {lang.money}/{lang.pieces}</p>
                                            </div>
                                            :
                                            <div style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label} <span style={{ fontSize: global.font_xtiny, fontWeight: '400' }}>{item.code} {item.details != '' ? '- ' + item.details : ''}</span></p>
                                                <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                    <p style={{ ...styles.TextTiny, marginTop: 0 }}>{item.note_short}</p>
                                                    <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                        <div style={{ ...styles.BlockLeft, width: '40%' }}>
                                                            <p style={{ ...styles.TextTiny }}>{lang.price__}: {GetPrice(item.eur)} {lang.money}/{lang.pieces}</p>
                                                        </div>
                                                        <div style={{ ...styles.BlockLeft, width: '40%' }}>

                                                        </div>
                                                        <div style={{ ...styles.BlockLeft, width: '20%' }}>
                                                            <p style={{ ...styles.TextTiny, color: item.instock == 0 ? global.theme_red : global.theme_black }}>{item.instock == 0 ? lang.no_stock : lang.stock + ': ' + item.instock + ' ' + lang.pieces}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div style={{ ...styles.Block, height: rowHeight, width: 200, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextTiny, marginBottom: 5 }}>{lang.quantity}</p>
                                            <PlusMinus min={0} value={item.quantity} data={item} func={PlusMinusResult.bind(this)} />
                                        </div>
                                    </div>
                                </Paper>
                            )) :
                            // ZIADNE PRODUKTY                            
                            <div style={{ ...styles.Block, paddingTop: 20 }}>
                                {isBusy == false ?
                                    <EmptyList lang={lang} />
                                    : null}
                            </div>
                        }
                    </div>
                </div>
                <div style={{ ...styles.BlockRow, height: 100 }}>
                    <div style={{ ...styles.BlockLeft, height: 100, justifyContent: 'center' }}>
                        {products != false ?
                            client == false ?
                                <div style={{ ...styles.BlockLeft }}>
                                    <p style={{ ...styles.TextTiny, marginBottom: 5, marginTop: 12, marginLeft: 20 }}>{lang.products_shop_client}</p>
                                    <Button onClick={() => Client()} style={{ ...styles.ButtonSelect, marginLeft: 20 }}>
                                        <FontAwesomeIcon style={{ height: 14, color: global.theme_white, marginRight: 10 }} icon={faUser} />
                                        {lang.client}
                                    </Button>
                                </div>
                                :
                                <div style={{ ...styles.BlockLeft, marginLeft: props.isSmall ? 10 : 20 }}>
                                    <p style={{ ...styles.TextTiny }}>{lang.client}:</p>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <p style={{ ...props.isSmall ? styles.TextTinyMobile : styles.TextSmall, marginTop: 5, textAlign: 'left' }}>{client.name} {client.surname}</p>
                                        <IconButton onClick={() => ClientDelete()} style={{ width: 30, height: 30, marginLeft: 10, backgroundColor: global.theme_white }}>
                                            <FontAwesomeIcon style={{ width: 12, color: global.theme_black }} icon={faXmark} />
                                        </IconButton>
                                    </div>
                                </div>
                            : null}
                    </div>
                    <div style={{ ...styles.BlockRight, height: 100, justifyContent: 'center' }}>
                        {products != false ?
                            <Button onClick={() => setShowFinish(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: props.isSmall ? 140 : 240, height: 60, marginRight: 20 }}>
                                {lang.shop_finish}
                                {props.isSmall ? null :
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginLeft: 10 }} icon={faAngleRight} />
                                }
                            </Button>
                            : null}
                    </div>
                </div>
            </Paper>

            {
                showClient == true ?
                    <ClientSelect isSmall={props.isSmall} today={true} employees={props.employees} employee_id={props.employee_id} user={props.user} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} func={ClientSelectResult.bind(this)} />
                    : null
            }

            {
                showFinish == true ?
                    <DialogYesNo theme={props.theme} radius={props.radius} title={lang.shop_finish} sub_title={lang.basket} text={lang.shop_finish_text} icon={faQuestion} yes={props.lang.continue} no={props.lang.cancel} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={CheckoutResult.bind(this)} />
                    : null
            }

            {
                isBusy == true ?
                    <Loading offset={props.offset} theme={props.theme} ></Loading>
                    : null
            }

        </Backdrop >
    );
}


export const ProductStructure = (props) => {
    /*            
        ZLOŽENIE PRODUKTU
    */


    const [isBusy, setBusy] = useState(true);
    const [items, setItems] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 800 ? 800 : width;
    const dialogHeight = props.isSmall ? height : height >= 600 ? 600 : height;

    const rowHeight = 60;

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {

        if (running == false) {
            running = true;
            db_structure();
        }

        return () => {
        };

    }, []);


    const Press = (value) => {
        func(value);
    }

    const db_structure = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_structure', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language,
                    code: props.product.code.substring(0, props.product.code.length - 1),
                })
            })

            const json = await response.json();

            if (json.ok > 0) {

                if (json.products != false) {
                    var tmp = json.items;
                    setItems(tmp);
                }
            }
            setBusy(false);
        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faLayerGroup} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '80%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{lang.products_structure_label}</p>
                            <p style={{ ...styles.TextDialogSubLabel, textAlign: 'left', marginTop: 4 }}>{props.product.label}</p>
                        </div>
                    </div>

                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: global.theme_white, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    <div style={{ ...styles.BlockCenter, paddingTop: 5, paddingBottom: 5 }}>
                        {items != false ?
                            items.map((item, index) => (
                                <Paper elevation={2} key={item.id} style={{ ...styles.Block, minHeight: rowHeight, marginBottom: 1, paddingTop: 15, paddingBottom: 15 }}>
                                    <div style={{ ...styles.BlockLeft, width: '96%' }}>
                                        <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                        <p style={{ ...styles.TextSmall, color: global.theme_darker_gray, marginLeft: 15, marginTop: 10, textAlign: 'left' }}>{item.note}</p>
                                    </div>
                                </Paper>
                            )) :
                            // ZIADNE PRODUKTY                            
                            <div style={{ ...styles.Block, paddingTop: 20 }}>
                                {isBusy == false ?
                                    <EmptyList lang={lang} />
                                    : null}
                            </div>
                        }
                    </div>
                </div>

            </Paper>

        </Backdrop>
    );
}