import React, { useEffect, useState, useRef } from 'react';

import { styles } from './styles';
import { Button, IconButton, Paper, Backdrop } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faFileText, faXmark } from '@fortawesome/free-solid-svg-icons';


import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';


export const EditorHtml = (props) => {

    /*
        Editor HTML textu

        <EditorHtml block={true} theme={props.theme} text={''} lang={lang} radius={radius} func={EditorHtmlResult.bind(this)} />

        --------------------------------------------------------------------------------------------------------------------
        text    => vo formáte HTML
        radius  => window borderRadius
        --------------------------------------------------------------------------------------------------------------------

        const EditorHtmlResult = (typ, text) => {
            if (typ == true) {
                console.log(text);
            }
        }

    */

    const blocksFromHTML = convertFromHTML(props.text);
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap,);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(state));
    const [contentState, setContentState] = useState();

    const [preview, setPreview] = useState(false);
    const [previewText, setPreviewText] = useState('');
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = props.isSmall ? width : props.width != undefined ? props.width : 900;
    const dialogHeight = props.isSmall ? height : props.height != undefined ? props.height : 700;
    const buttonHeight = 70;

    let { func } = props;
    var lang = props.lang;
    const editorRef = useRef(null);

    useEffect(() => {
        // FOCUS TO TEXTAREA
        setTimeout(() => {
            editorRef.current.focusEditor();
        }, 300);

    }, []);

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(false, false);
    }

    const Save = () => {
        let html = convertToHTML(editorState.getCurrentContent());
        Press(true, html);
    }

    const ChangePreview = (value) => {
        if (value == true) {
            let html = convertToHTML(editorState.getCurrentContent());
            setPreviewText(html);
        }
        setPreview(value);
    }

    return (
        props.block == true ?
            <Paper elevation={0} style={{ width: dialogWidth, height: dialogHeight, background: global.theme_white, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, height: dialogHeight, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    {preview == false ?
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - - buttonHeight, backgroundColor: global.theme_white }}>
                            {/* BODY */}
                            <Editor
                                ref={editorRef}
                                editorState={editorState}
                                onEditorStateChange={setEditorState}
                                defaultContentState={contentState}
                                onContentStateChange={setContentState}

                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"

                                editorStyle={{ backgroundColor: global.theme_white, height: props.isSmall ? dialogHeight - buttonHeight : dialogHeight - buttonHeight - 50, border: '1px solid ' + global.theme_light_gray }}
                                toolbarStyle={{ backgroundColor: global.theme_gray, width: dialogWidth - 20 }}

                                toolbar={{
                                    options: ['inline', 'blockType', 'list', 'history'],
                                    inline: { inDropdown: false },
                                    list: { inDropdown: false },
                                    textAlign: { inDropdown: false },
                                    history: { inDropdown: false },
                                    inline: {
                                        inDropdown: false,
                                        options: ['bold', 'italic', 'underline'],
                                    },
                                    blockType: {
                                        inDropdown: false,
                                        options: ['Normal', 'H1', 'H2', 'H3'],
                                    },
                                    list: {
                                        inDropdown: false,
                                        options: ['unordered', 'ordered'],
                                    },
                                }}
                            />
                        </div>
                        :
                        <div style={{ ...styles.BlockCenter, height: dialogHeight - buttonHeight }}>
                            <div style={{ ...styles.BlockLeft, width: '95%' }}>
                                <div style={{ ...styles.TextSmall, textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: previewText }} />
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.Block, width: '95%' }}>
                        <div style={{ ...styles.BlockRow }}>
                            <div style={{ ...styles.Block, height: buttonHeight }}>
                                <Button onClick={() => ChangePreview(!preview)} style={{ ...styles.ButtonDark, backgroundColor: preview == true ? global.theme_dark_red : global.themes[props.theme].theme_dark }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faEye} />
                                    {preview == true ? lang.close : lang.preview}
                                </Button>
                            </div>
                            <div style={{ ...styles.Block, height: buttonHeight }}>
                                <Button onClick={() => Save()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>{lang.save}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Paper>
            :
            <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
                <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                    <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                        <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                            <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                                <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faFileText} />
                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                            <div style={{ ...styles.BlockLeft, height: 45 }}>
                                <p style={{ ...styles.TextDialogLabel }}>{lang.text_editor}</p>
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.vision_system}</p>
                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                            <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                                <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                        {preview == false ?
                            <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, backgroundColor: global.theme_white }}>
                                {/* BODY */}
                                <Editor
                                    ref={editorRef}
                                    editorState={editorState}
                                    onEditorStateChange={setEditorState}
                                    defaultContentState={contentState}
                                    onContentStateChange={setContentState}

                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"

                                    editorStyle={{ backgroundColor: global.theme_white, height: props.isSmall ? dialogHeight - global.dialog_bar_height - buttonHeight - 100 : dialogHeight - global.dialog_bar_height - buttonHeight - 55, border: '1px solid ' + global.theme_light_gray }}
                                    toolbarStyle={{ backgroundColor: global.theme_gray, width: dialogWidth - 20 }}

                                    toolbar={{
                                        options: ['inline', 'blockType', 'list', 'history'],
                                        inline: { inDropdown: false },
                                        list: { inDropdown: false },
                                        textAlign: { inDropdown: false },
                                        history: { inDropdown: false },
                                        inline: {
                                            inDropdown: false,
                                            options: ['bold', 'italic', 'underline'],
                                        },
                                        blockType: {
                                            inDropdown: false,
                                            options: ['Normal', 'H1', 'H2', 'H3'],
                                        },
                                        list: {
                                            inDropdown: false,
                                            options: ['unordered', 'ordered'],
                                        },
                                    }}
                                />
                            </div>
                            :
                            <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight }}>
                                <div style={{ ...styles.BlockLeft, width: '95%' }}>
                                    <div style={{ ...styles.TextSmall, textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: previewText }} />
                                </div>
                            </div>
                        }
                        <div style={{ ...styles.Block, width: '95%' }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.Block, height: buttonHeight }}>
                                    <Button onClick={() => ChangePreview(!preview)} style={{ ...styles.ButtonDark, backgroundColor: preview == true ? global.theme_dark_red : global.themes[props.theme].theme_dark }}>
                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faEye} />
                                        {lang.preview}
                                    </Button>
                                </div>
                                <div style={{ ...styles.Block, height: buttonHeight }}>
                                    <Button onClick={() => Save()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>{lang.save}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Paper>
            </Backdrop>

    );
}


