import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, CopyData, Debug, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, GetMoney, GetPrice, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, QuickHelp, ShowError, ShowOK, TemplateWindow, TextSearch } from './items';
import { styles } from './styles';
import { Button, IconButton, InputAdornment, MenuItem, Pagination, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faL, faLayerGroup, faList, faListAlt, faQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';
import { ProductDialog, ProductPreview } from './product_dialog';
import { ServicePreview } from './service_dialog';


export const ServicesInfo = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [money, setMoney] = useState(GetMoney(props.language));

    // KATEGORIE
    const [categories, setCategories] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(false);

    // PRODUKTY
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const itemsMax = 24;

    // EDITING + NOVY PRODUKT
    const [itemSelected, setItemSelected] = useState(false);
    const [editing, setEditing] = useState(false);
    const [showProduct, setShowProduct] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const menuWidth = props.isSmall ? 0 : 250;
    const rowHeight = 100;
    const rowWidth = 850;

    // COLORS
    const font_selected = global.theme_black;
    const font_disabled = global.theme_darker;

    const no_image = require('./react/app/no_image.jpg');
    const service_1 = require('./react/app/service1.png');
    const service_2 = require('./react/app/service2.png');

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;
            db_get();
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'groups', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    default: lang.services_my,
                    language: props.language,
                    //new_services: lang.services_news,
                    no_others: props.user.typ == global.account_salon ? false : true
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;
                tmp = tmp.filter(x => x.enabled == true);

                tmp.sort((a, b) => {
                    if (parseInt(a.priority) > parseInt(b.priority)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })

                setCategories(tmp);
                if (tmp != false) {
                    db_services(tmp[0].id, tmp[0].brand_id == 0 ? true : false);
                    setIndex(tmp[0].id);
                    setSelectedCategory(tmp[0]);
                }
            }
            //setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_services = async (category_id, my_services) => {
        setBusy(true);
        Debug(category_id);
        try {
            const response = await fetch(
                global.db_url + 'services', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    category_id: category_id,
                    language: props.language,
                    my_services: my_services
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.services;

                if (tmp != false) {
                    tmp = tmp.filter(x => x.enabled == 1);

                    tmp.sort((a, b) => {
                        if (a.label > b.label) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_search = async (search) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'service_search', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    search: search,
                    language: props.language
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.products;

                if (tmp != false) {
                    tmp.sort((a, b) => {
                        if (a.label > b.label) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                    setIndex(-1);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                    setIndex(-1);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ChangeIndex = (id) => {
        if (categories != false) {
            var item = categories.find(x => x.id == id);

            if (item != undefined) {
                setIndex(item.id);
                setSearch('');
                db_services(item.id, item.brand_id == 0 ? true : false);
            }
        }
    }

    const CategorySelectResult = (id) => {
        Redraw(items, 0, search, id);
    }

    const Search = (text) => {
        setSearch(text);
        /*
        setSubCategoryID(0);
        Redraw(items, 0, text, 0);
        */
        if (text.trim().length > 2) {
            db_search(text.trim());
        }
        if (text == '') {
            setItems(false);
            setItemsFiltered(false);
            setIndex(-1);
        }
    }

    const Redraw = (items, p, search) => {
        // REDRAW PRODUKTOV
        var data = items;
        if (search.trim() != '') {
            // filtrovanie podla hladaneho retazca
            var words = search.split(' ');
            data = items.filter(x => FindWords(x.label, x.keywords, words));
        }

        var count = data.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax)
        setItemsFiltered(data);

        if (count == 0) {
            setItemsFiltered(false);
        }

        setPage(p);
        GoToStart();
    }

    function FindWords(label, keywords, words) {
        // hladanie retazca v nazve a keywords
        var result = 0;
        words.forEach(word => {
            if (label.toLowerCase().includes(word.toLowerCase()) || keywords.toLowerCase().includes(word.toLowerCase())) {
                result += 1;
            }

        });
        // ak je pocet najdených slov == poctu hladaných slov -> je zhoda
        return words.length == result;
    }

    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);

        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0, '');
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_, search);
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_products');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }


    const ShowProduct = (item) => {
        setItemSelected(item);
        setShowProduct(true);
    }

    const ProductDialogResult = (value, data) => {
        setShowProduct(false);
    }

    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? '50%' : '75%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>
                        {props.isSmall ? null :
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        }
                    </div>
                </div>
                <div style={{ ...styles.BlockRow, justifyContent: 'flex-end', alignItems: 'center', width: props.isSmall ? 160 : 300, height: global.dialog_bar_height }}>
                    <TextSearch lang={props.lang} isSmall={props.isSmall} func={Search.bind(this)} />
                    {/*
                    <TextField
                        value={search}
                        onInput={e => Search(e.target.value)}
                        size="small"
                        autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off"
                        style={{ width: props.isSmall ? 150 : 280 }}
                        placeholder={lang.search_in_category}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                            }
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                    <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                </IconButton>
                            </InputAdornment>,
                            style: { backgroundColor: global.theme_white, borderRadius: 30 },
                        }}
                        variant="outlined"
                    />
                    */}
                </div>
                <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                    {props.isSmall ?
                        <div style={{ ...styles.Block }}>
                            <Select
                                value={index}
                                onChange={event => ChangeIndex(event.target.value)}
                                size='small'
                                style={{ backgroundColor: global.theme_white, width: '100%' }}
                            >

                                {categories != false ?
                                    categories.map((item) => (
                                        item.typ != 100 ?
                                            <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                                            : null
                                    ))
                                    : null}
                            </Select>
                        </div>
                        :
                        <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                            <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                {categories != false ?
                                    categories.map((item) => (
                                        <Button key={item.id} onClick={() => ChangeIndex(item.id)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == item.id ? global.theme_back : '#00000000', color: index == item.id ? font_selected : font_disabled }}>
                                            <div style={{ ...styles.BlockLeft }}>
                                                <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{item.label}</p>
                                                <p style={{ ...styles.TextTiny, color: global.themes[props.theme].theme_darker, marginTop: 2, lineHeight: 1, textAlign: 'left' }}>{item.brand}</p>
                                            </div>
                                        </Button>
                                    ))
                                    : null}

                            </div>
                        </div>
                    }
                    <div style={{ ...styles.BlockCenter, width: props.width - menuWidth, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                        {/* BODY */}
                        <div id={'id_products'}>
                        </div>
                        <div style={{ ...styles.Block, width: '98%' }}>
                            {/* *********************************************************************************************************  
                                ZOZNAM PRODUKTOV
                        ********************************************************************************************************* */}
                            {itemsFiltered != false ?
                                itemsFiltered.map((item, index) => (
                                    <div key={item.id} style={{ ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: global.theme_lighter, marginTop: 5, marginBottom: 5, borderRadius: rowHeight / 2, cursor: 'pointer', overflow: 'hidden' }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            <div onClick={() => ShowProduct(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                <img src={item.typ == 0 ? service_1 : service_2} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                            </div>
                                            <div onClick={() => ShowProduct(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'flex-start', marginLeft: 10, paddingTop: 5 }}>
                                                <p style={{ ...props.isSmall ? styles.TextTinyMobile : styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                    {item.description != '' ?
                                                        <div style={{ ...styles.BlockLeft, marginTop: 5, height: 50, overflowY: 'hidden' }}>
                                                            <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{item.description}</p>
                                                        </div>
                                                        : null}
                                                </div>
                                            </div>
                                            <div style={{ ...styles.Block, height: rowHeight, width: props.isSmall ? 150 : 170 }}>
                                                <div style={{ ...props.isSmall ? styles.Block : styles.BlockRowRaw }}>
                                                    <p style={{ ...props.isSmall ? styles.TextTinyMobile : styles.TextSmall, fontWeight: '500' }}>{GetPrice(item.eur)} {money}</p>
                                                    <QuickHelp text={lang.price_final} color={global.theme_medium_xgray} theme={props.theme} marginLeft={10} lang={props.lang} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )) :
                                // ZIADNE PRODUKTY                            
                                <div style={{ ...styles.Block, paddingTop: 20 }}>
                                    <EmptyList lang={lang} />
                                </div>
                            }

                            {itemsFiltered != false ?
                                pageMax > 1 ?
                                    <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                        <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                    </div>
                                    : <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>1/1</div>
                                : null}
                        </div>
                    </div>
                </div>
            </div>

            {showProduct == true ?
                <ServicePreview isSmall={props.isSmall} remoteApp={props.remoteApp} offset={props.offset} theme={props.theme} editing={editing} user_id={props.user.id} service={itemSelected} lang={lang} language={props.language} radius={props.radius} title={itemSelected.label} sub_title={itemSelected.note_short} backcolor={true} background={false} func={ProductDialogResult.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Paper>
    );
}


