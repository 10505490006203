import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { AddDays, CategorySelect, Colors, ConvertToPrice, CopyData, Debug, DialogEnterText, DialogImagePreview, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, FormError, FormOK, FormatMoney, GetDate, GetDate3, GetDatum2, GetImageLink, GetMoney, GetPages, GetPrice, GetTodayMonth, GetTodayYear, GetTopPosition, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, TextLine, Today, dateAddMonths, nonEmail } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, Chip, CircularProgress, FormControlLabel, IconButton, InputAdornment, MenuItem, Pagination, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleLeft, faAngleRight, faAt, faCheck, faCheckCircle, faComment, faEdit, faEnvelope, faExclamationCircle, faIdCard, faImage, faInfo, faInfoCircle, faK, faL, faLayerGroup, faList, faListAlt, faListCheck, faP, faPaperPlane, faPeace, faPen, faPenAlt, faPlusCircle, faPrint, faQuestion, faQuestionCircle, faS, faSave, faShare, faShoppingBag, faShoppingBasket, faShoppingCart, faStopwatch, faTrash, faUser, faWarning, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { ProductSelect } from './product_select.js';
import { Photos } from './photos.js';
import { SalonCard } from './salon_card.js';
import { Maximize } from '@mui/icons-material';


export const Expirations = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);
    const [money, setMoney] = useState('');

    // PREDAJ - ZOZNAM
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(global.items_max);
    const [filter, setFilter] = useState(false);
    const itemsMax = 24;

    // NAHLAD
    const [itemSelected, setItemSelected] = useState(false);
    const [showOrder, setShowOrder] = useState(false);
    const [myOrders, setMyOrders] = useState(false);

    // AKTUALNY ROK - EXPORT
    const [actualYear, setActualYear] = useState(GetTodayYear());

    const [showOK, setShowOK] = useState(false);
    const [sended, setSended] = useState(false);

    const menuWidth = props.isSmall ? 0 : 250;
    const rowHeight = props.isSmall ? 120 : 88;
    const rowWidth = 850;
    const paginationHeight = 50;

    const no_image = require('./react/app/no_image.jpg');

    // Aktuálna farebna téma
    const color = global.themes[props.theme];

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            let filter = { year: new Date().getFullYear() };
            db_get(page, filter);
        }

    }, []);

    const db_get = async (page, filter) => {
        Debug(filter);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'expirations', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    start: page * maxItems,
                    length: maxItems,
                    filter: filter,

                    salon_id: props.agent == undefined ? props.user.id : 0,
                    agent_id: props.agent == undefined ? 0 : props.user.id,
                    language: props.language,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {

                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);

                GoToStart();


            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }


    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_orders');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const Edit = (item) => {
        setItemSelected(item);
        setShowOrder(true);
    }

    const ExpirationResult = (redraw) => {
        setShowOrder(false);
        if (redraw == true) {
            db_get(0, filter);
        }
    }

    const ChangeYear = (year, month) => {
        setActualYear(year)
        var filter = GetFilter();
        filter.year = year;
        db_get(0, filter);
    }


    const NewExpire = () => {
        setItemSelected(false);
        setShowOrder(true);
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GetFilter = () => {
        var filter = { year: actualYear };

        return filter;
    }

    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? '45%' : '58%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.isSmall ? lang.reklamacie : props.title}</p>
                        {props.isSmall ? null :
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        }
                    </div>
                </div>
                <div style={{ ...styles.BlockRow, justifyContent: 'flex-end', alignItems: 'center', width: props.isSmall ? 160 : '30%', height: global.dialog_bar_height }}>
                </div>
                <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                    <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockCenter, width: props.width, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                            <div id={'id_orders'} style={{ ...styles.BlockCenter, width: '98%' }}>

                                <div style={{ ...styles.BlockRight, maxWidth: rowWidth }}>
                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.BlockLeft, width: '60%', height: 60, justifyContent: 'center' }}>
                                            <Button onClick={() => NewExpire()} style={{ ...styles.ButtonDark, backgroundColor: color.button_dark, width: 180 }}>{lang.expiration_new}</Button>
                                        </div>
                                        {props.isSmall ? null :
                                            <div style={{ ...styles.BlockRight, width: 250, height: 60, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.expirations_in_year}</p>
                                            </div>
                                        }
                                        <div style={{ ...styles.BlockRight, width: 180, height: 60, justifyContent: 'center' }}>
                                            <SelectYear min={2020} value={actualYear} func={ChangeYear.bind(this)} />
                                        </div>
                                    </div>
                                </div>

                                {/* *********************************************************************************************************  
                                            ZOZNAM EXPIROVANYCH PRODUKTOV
                                    ********************************************************************************************************* */}

                                {itemsFiltered != false ?
                                    itemsFiltered.map((item, index) => (
                                        <Paper elevation={overID == item.id ? 5 : 2} key={item.id} style={{ ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: global.theme_gray, marginTop: 8, marginBottom: 8, borderRadius: global.row_radius, cursor: 'pointer' }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow }}>
                                                {props.isSmall ?
                                                    <div onClick={() => Edit(item)} style={{ ...styles.Block, height: rowHeight, justifyContent: 'center' }}>
                                                        <div style={{ ...styles.BlockRow }}>
                                                            <div style={{ ...styles.Block, width: rowHeight, height: rowHeight }}>
                                                                <img src={item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', width: rowHeight - 8, height: rowHeight - 8, objectFit: 'contain', borderRadius: 14 }}></img>
                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: '66%', height: rowHeight, justifyContent: 'center', marginLeft: 8 }}>
                                                                <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_dark_gray }}>{GetDatum2(item.date)}</p>
                                                                <TextLine text={item.label} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                                                                <p style={{ ...styles.TextXSmall }}>{lang.expiration}: {item.month}/{item.year}</p>
                                                                <Chip size='small' label={<p style={{ ...styles.TextTiny, color: global.expirations_colors[item.status] }}>{lang.expiration_statuses[item.status]}</p>} variant='outlined' style={{ borderColor: global.expirations_colors[item.status], marginTop: 10 }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div onClick={() => Edit(item)} style={{ ...styles.Block, height: rowHeight, justifyContent: 'center' }}>
                                                        <div style={{ ...styles.BlockRow }}>
                                                            <div style={{ ...styles.Block, width: rowHeight, height: rowHeight }}>
                                                                <img src={item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', width: rowHeight - 8, height: rowHeight - 8, objectFit: 'contain', borderRadius: 14 }}></img>
                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: '66%', height: rowHeight, justifyContent: 'center', marginLeft: 8 }}>
                                                                <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_dark_gray }}>{GetDatum2(item.date)} {props.agent == undefined ? '' : item.salon_name}</p>
                                                                <TextLine text={item.label} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                                                                <p style={{ ...styles.TextXSmall }}>{lang.expiration}: {item.month}/{item.year}</p>
                                                            </div>
                                                            <div style={{ ...styles.Block, width: 200, height: rowHeight, justifyContent: 'center' }}>
                                                                <Chip label={<p style={{ ...styles.TextTiny, color: global.expirations_colors[item.status] }}>{lang.expiration_statuses[item.status]}</p>} variant='outlined' style={{ borderColor: global.expirations_colors[item.status] }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </Paper>
                                    )) :
                                    // ZIADNE OBJEDNAVKY                            
                                    <div style={{ ...styles.Block, paddingTop: 20 }}>
                                        <EmptyList lang={lang} />
                                    </div>
                                }

                                <div style={{ ...styles.Block, height: paginationHeight }}>
                                    {pages > 1 ?
                                        <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                        : null}
                                </div>

                                <div style={{ ...styles.Block, height: 100 }}></div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {showOrder == true ?
                <Expiration item={itemSelected} isSmall={props.isSmall} offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} remoteApp={props.remoteApp} admin={props.admin} countries={props.countries} country={props.country} lang={lang} radius={props.radius} backcolor={true} background={true} language={props.language} func={ExpirationResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK side={menuWidth} offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading side={menuWidth} offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Paper >
    );
}


export const ExpirationsAgent = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);
    const [money, setMoney] = useState('');

    // PREDAJ - ZOZNAM
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(global.items_max);
    const [filter, setFilter] = useState(false);

    // NAHLAD
    const [itemSelected, setItemSelected] = useState(false);
    const [showOrder, setShowOrder] = useState(false);
    const [myOrders, setMyOrders] = useState(false);

    // AKTUALNY ROK - EXPORT
    const [actualYear, setActualYear] = useState(GetTodayYear());

    const [showOK, setShowOK] = useState(false);
    const [sended, setSended] = useState(false);

    const menuWidth = props.isSmall ? 0 : 250;
    const rowHeight = props.isSmall ? 120 : 88;
    const rowWidth = 850;
    const paginationHeight = 50;

    const no_image = require('./react/app/no_image.jpg');

    // Aktuálna farebna téma
    const color = global.themes[props.theme];
    const selectHeight = props.isSmall ? 40 : 0;
    // COLORS
    const font_selected = global.theme_black;
    const font_disabled = global.theme_darker;


    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            let filter = { status: [0] };
            db_get(page, filter);
        }

    }, []);

    const db_get = async (page, filter) => {
        Debug(page * maxItems);
        Debug(page);
        Debug(maxItems);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'expirations', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    start: page * maxItems,
                    length: maxItems,
                    filter: filter,

                    salon_id: 0,
                    agent_id: props.user.id,
                    admin: props.admin,
                    language: props.language,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {

                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems)); // Max pocet na stranku - Select

                setFilter(filter);

                GoToStart();


            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }


    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_orders');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const Edit = (item) => {
        setItemSelected(item);
        setShowOrder(true);
    }

    const ExpirationResult = (redraw) => {
        setShowOrder(false);
        Debug(redraw);
        if (redraw == true) {
            db_get(page, filter);
        }
    }

    const ChangeYear = (year, month) => {
        setActualYear(year)
        var filter = GetFilter();
        filter.year = year;
        db_get(0, filter);
    }


    const NewExpire = () => {
        setItemSelected(false);
        setShowOrder(true);
    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GetFilter = () => {
        var filter = { year: actualYear };

        if (index == 0) {
            delete (filter.year);
            filter.status = [0];
        }
        if (index == 1) {
            filter.status = [1, 2];
        }
        if (myOrders == true) {
            filter.mine = true;
        }
        return filter;
    }

    const ChangeIndex = (id) => {
        setIndex(id);
        Debug(id);
        let year = new Date().getFullYear();
        setActualYear(year);

        var filter = GetFilter();
        if (id == 0) {
            delete (filter.year);
            filter.status = [0];
        }
        if (id == 1) {
            filter.year = actualYear;
            filter.status = [1, 2];
        }

        db_get(0, filter);
    }

    const ChangeMy = (value) => {
        setMyOrders(value);

        var filter = GetFilter();
        if (value == true) {
            filter.mine = true;
        } else {
            delete (filter.mine);
        }

        db_get(0, filter);
    }
    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? '80%' : '58%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>
                        {props.isSmall ? null :
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        }
                    </div>
                </div>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, justifyContent: 'center', alignItems: 'flex-end', width: '30%', height: global.dialog_bar_height }}>
                        {props.admin == true ?
                            <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.show_mine}</p>}
                                control={<Checkbox checked={myOrders} size={'small'} onChange={() => ChangeMy(!myOrders)} />}
                            />
                            : null}
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                    <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                        {props.isSmall ?
                            <div style={{ ...styles.Block, height: selectHeight }}>
                                <Select
                                    value={index}
                                    onChange={event => ChangeIndex(event.target.value)}
                                    size='small'
                                    style={{ backgroundColor: global.theme_white, width: '100%', height: selectHeight }}
                                    MenuProps={{
                                        style: { zIndex: 10000 }
                                    }}
                                >
                                    <MenuItem value={0}>{lang.expiration_not_finished}</MenuItem>
                                    <MenuItem value={1}>{lang.expiration_finished}</MenuItem>
                                </Select>
                            </div>
                            :
                            <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                                <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    <Button onClick={() => ChangeIndex(0)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == 0 ? global.theme_back : '#00000000', color: index == 0 ? font_selected : font_disabled }}>
                                        <div style={{ ...styles.BlockLeft }}>
                                            <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{lang.expiration_not_finished}</p>
                                        </div>
                                    </Button>
                                    <Button onClick={() => ChangeIndex(1)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == 1 ? global.theme_back : '#00000000', color: index == 1 ? font_selected : font_disabled }}>
                                        <div style={{ ...styles.BlockLeft }}>
                                            <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{lang.expiration_finished}</p>
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        }
                        <div style={{ ...styles.BlockCenter, width: props.width - menuWidth, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                            < div id={'id_orders'} style={{ ...styles.BlockCenter, width: '98%' }}>

                                {index == 1 ?
                                    <div style={{ ...styles.BlockRight, maxWidth: rowWidth }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <div style={{ ...styles.Block, height: 60 }}>
                                                <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.expirations_in_year}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 200, height: 60 }}>
                                                <SelectYear min={2020} value={actualYear} func={ChangeYear.bind(this)} />
                                            </div>
                                        </div>
                                    </div>
                                    : null}

                                {/* *********************************************************************************************************  
                                            ZOZNAM EXPIROVANYCH PRODUKTOV
                                ********************************************************************************************************* */}

                                {itemsFiltered != false ?
                                    itemsFiltered.map((item, index) => (
                                        <Paper elevation={overID == item.id ? 5 : 2} key={item.id} style={{ ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: global.theme_gray, marginTop: 8, marginBottom: 8, borderRadius: global.row_radius, cursor: 'pointer' }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow }}>
                                                {props.isSmall ?
                                                    <div onClick={() => Edit(item)} style={{ ...styles.Block, height: rowHeight, justifyContent: 'center' }}>
                                                        <div style={{ ...styles.BlockRow }}>
                                                            <div style={{ ...styles.Block, width: rowHeight, height: rowHeight }}>
                                                                <img src={item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', width: rowHeight - 8, height: rowHeight - 8, objectFit: 'contain', borderRadius: 14 }}></img>
                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: '66%', height: rowHeight, justifyContent: 'center', marginLeft: 8 }}>
                                                                <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_dark_gray }}>{GetDatum2(item.date)}</p>
                                                                <TextLine text={item.label} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                                                                <p style={{ ...styles.TextXSmall }}>{lang.expiration}: {item.month}/{item.year}</p>
                                                                <Chip size='small' label={<p style={{ ...styles.TextTiny, color: global.expirations_colors[item.status] }}>{lang.expiration_statuses[item.status]}</p>} variant='outlined' style={{ borderColor: global.expirations_colors[item.status], marginTop: 10 }} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div onClick={() => Edit(item)} style={{ ...styles.Block, height: rowHeight, justifyContent: 'center' }}>
                                                        <div style={{ ...styles.BlockRow }}>
                                                            <div style={{ ...styles.Block, width: rowHeight, height: rowHeight }}>
                                                                <img src={item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', width: rowHeight - 8, height: rowHeight - 8, objectFit: 'contain', borderRadius: 14 }}></img>
                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: '54%', height: rowHeight, justifyContent: 'center', marginLeft: 10 }}>
                                                                <TextLine text={item.label} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                                                                <p style={{ ...styles.TextXSmall }}>{lang.expiration}: {item.month}/{item.year}</p>
                                                                <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_black, marginTop: 10 }}>{GetDatum2(item.date)}<span style={{ marginLeft: 10 }}>{item.agent_name}</span></p>
                                                            </div>
                                                            <div style={{ ...styles.Block, width: 300, height: rowHeight, justifyContent: 'center' }}>
                                                                <div style={{ ...styles.Block, width: '90%' }}>
                                                                    <div style={{ ...styles.BlockRight, height: rowHeight / 2, justifyContent: 'center' }}>
                                                                        <TextLine text={item.salon_name + ', ' + item.salon_town} fontSize={global.font_tiny} fontWeight={'400'} color={color.black} />
                                                                    </div>
                                                                    <div style={{ ...styles.BlockRight, height: rowHeight / 2, justifyContent: 'center' }}>
                                                                        <Chip size='small' label={<p style={{ ...styles.TextTiny, color: global.expirations_colors[item.status] }}>{lang.expiration_statuses[item.status]}</p>} variant='outlined' style={{ borderColor: global.expirations_colors[item.status] }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </Paper>
                                    )) :
                                    // ZIADNE OBJEDNAVKY                            
                                    <div style={{ ...styles.Block, paddingTop: 20 }}>
                                        <EmptyList lang={lang} />
                                    </div>
                                }

                                {itemsFiltered != false ?
                                    pages > 1 ?
                                        <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                            <Pagination count={pages} size={'small'} page={page + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                        </div>
                                        : null
                                    : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {showOrder == true ?
                <Expiration agent item={itemSelected} isSmall={props.isSmall} offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} remoteApp={props.remoteApp} admin={props.admin} countries={props.countries} country={props.country} lang={lang} radius={props.radius} backcolor={true} background={true} language={props.language} func={ExpirationResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK side={menuWidth} offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading side={menuWidth} offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Paper >
    );
}



export const Expiration = (props) => {


    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState('');
    const [redrawRequired, setRedrawRequired] = useState(false);

    // VALUES
    const [itemID, setItemID] = useState(props.item == false ? 0 : props.item.id);
    const [product, setProduct] = useState(false);
    const [date, setDate] = useState(Today());
    const [month, setMonth] = useState(GetTodayMonth() + 1);
    const [year, setYear] = useState(GetTodayYear());
    const [quantity, setQuantity] = useState(1);
    const [dateError, setDateError] = useState(false);
    const [status, setStatus] = useState(0);
    const [photoDate, setPhotoDate] = useState(false);
    const [photoProduct, setPhotoProduct] = useState(false);
    const [message, setMessage] = useState('');
    const [salon, setSalon] = useState(false);


    // DIALOGS
    const [showProducts, setShowProducts] = useState(false);
    const [showAddPhotoDate, setShowAddPhotoDate] = useState(false);
    const [showAddPhotoProduct, setShowAddPhotoProduct] = useState(false);
    const [showPhoto, setShowPhoto] = useState(false);
    const [showAskSend, setShowAskSend] = useState(false);
    const [sendData, setSendData] = useState(false);
    const [photo, setPhoto] = useState('');

    // SCHVALIŤ / ZAMIETNUT
    const [showDeny, setShowDeny] = useState(false);
    const [showAccept, setShowAccept] = useState(false);

    // zobraziť kartu salóna
    const [showSalonCard, setShowSalonCard] = useState(false);

    // Rozmery okna
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 800 ? 800 : width;
    const dialogHeight = props.isSmall ? height : height >= 850 ? 850 : height;

    const buttonHeight = 120;
    const labelHeight = props.agent == true ? 90 : global.dialog_bar_height;
    const imageSize = props.isSmall ? 150 : 200;
    const photoSize = 200;

    const no_image = require('./react/app/no_image.jpg');


    let { func } = props;
    let offset = 10;
    var lang = props.lang;
    const color = global.themes[props.theme];
    var running = false;
    const inputRef = useRef(null);

    useEffect(() => {
        if (running == false) {
            running = true;

            if (props.item != false) {
                db_get(props.item.id);
            }

        }
        return () => {
        };

    }, []);

    const db_get = async (item_id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'expiration', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language,
                    item_id: item_id
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                let item = json.item;
                setMonth(item.month);
                setYear(item.year);
                setQuantity(item.quantity);
                setStatus(item.status);
                setPhotoDate(item.photo_date);
                setPhotoProduct(item.photo_product);
                setProduct(json.product);
                setMessage(item.message);
                setSalon(json.salon);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'expiration_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();
            Debug(json);

            setBusy(false);
            if (json.ok > 0) {
                setRedrawRequired(true);
                setShowOK(true);
            } else {
                setShowError(true);
            }


        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }



    const Close = () => {
        func(redraw);
    }

    const ShowOKPress = (value) => {
        func(true);
    }


    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const ProductSelectResult = (typ, item) => {
        setShowProducts(false);

        if (typ == true) {
            setProduct(item);
        }
    }

    const PhotoResultDate = (typ, path, file) => {
        setShowAddPhotoDate(false);
        if (typ == true) {
            setPhotoDate(path + file);
        }
    }

    const PhotoResultProduct = (typ, path, file) => {
        setShowAddPhotoProduct(false);

        if (typ == true) {
            setPhotoProduct(path + file);
        }
    }

    const PhotoPreview = (image) => {
        setPhoto(image);
        setShowPhoto(true);
    }

    function dateFromMonthYear(year, month) {
        var futureDate = new Date(year, month - 1);
        var d = String(futureDate.getDate()).padStart(2, '0');
        var m = String(futureDate.getMonth() + 1).padStart(2, '0');
        var y = String(futureDate.getFullYear());

        return y + '-' + m + '-' + d;
    }

    const Send = () => {
        setShowError('');
        setDateError(false);
        setError('');

        var expireDate = new Date(dateFromMonthYear(year, month));
        expireDate = expireDate.getTime();

        var maxDate = new Date(dateAddMonths(dateFromMonthYear(GetTodayYear(), GetTodayMonth() + 1), 3));
        var maxDate = new Date(maxDate);
        maxDate = maxDate.getTime();

        if (maxDate <= expireDate) {
            if (photoDate == false) {
                setError(lang.expiration_photo_date_error);
            } else if (photoProduct == false) {
                setError(lang.expiration_photo_product_error);
            } else {

                let data = {
                    id: itemID,
                    salon_id: props.user.id,
                    agent_id: props.user.agent_id,
                    language: props.user.language,
                    product_id: product.id,
                    month: month,
                    year: year,
                    quantity: quantity,
                    photo_date: photoDate,
                    photo_product: photoProduct,
                    status: status,
                    message: ''
                }

                setSendData(data);
                setShowAskSend(true);
            }
        } else {
            setDateError(true);
            setError(props.isSmall ? lang.expiration_date_error_ : lang.expiration_date_error);
        }

    }

    const SendResult = (typ) => {
        setShowAskSend(false);
        if (typ == true) {
            db_update(sendData);
        }
    }

    const DenyResult = (value, text) => {
        setShowDeny(false);

        if (value == true) {
            let data = {
                id: itemID,
                status: 2,
                message: text
            }

            db_update(data);
            setStatus(2);
        }
    }

    const AcceptResult = (value) => {
        setShowAccept(false);

        if (value == true) {
            let data = {
                id: itemID,
                status: 1,
            }

            db_update(data);
            setStatus(1);
        }
    }

    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: labelHeight, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    {props.isSmall && props.agent == true ? <div style={{ ...styles.Block, width: 10 }}></div> :
                        <div style={{ ...styles.BlockCenter, width: 100, height: labelHeight, justifyContent: 'center' }}>
                            <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                                <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faStopwatch} />
                            </div>
                        </div>
                    }
                    {props.isSmall && props.agent == true && props.cardHide == undefined ? null :
                        <div style={{ ...styles.BlockLeft, width: props.agent == true ? props.cardHide != true ? '40%' : '80%' : '85%', height: labelHeight, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{itemID == 0 ? lang.expiration_new : lang.expiration}</p>
                            <p style={{ ...styles.TextDialogSubLabel, textAlign: 'left' }}>{lang.product}</p>
                        </div>
                    }
                    {props.agent == true ?
                        props.cardHide != true ?
                            <div style={{ ...styles.BlockRow, width: props.isSmall ? '83%' : '45%' }}>
                                <div style={{ ...styles.Block, width: 50, height: labelHeight }}>
                                    <div onClick={() => setShowSalonCard(true)} style={{ ...styles.Block, width: 50, height: 70, backgroundColor: global.themes[props.theme].theme_medium, borderRadius: 5 }}>
                                        <FontAwesomeIcon style={{ height: 28, color: global.theme_white }} icon={faIdCard} />
                                        <p style={{ ...styles.TextXXTiny, color: global.theme_white, marginTop: 5 }}>{lang.card}</p>
                                    </div>
                                </div>
                                <div style={{ ...styles.BlockLeft, marginLeft: 10, height: labelHeight, justifyContent: 'center' }}>
                                    <TextLine text={salon.name + ' ' + salon.surname} fontSize={global.font_tiny} fontWeight={'400'} color={color.black} />
                                    <TextLine text={salon.street + ' ' + salon.street_number + ', ' + salon.psc + ' ' + salon.town} fontSize={global.font_tiny} fontWeight={'400'} color={color.black} />
                                    <TextLine text={lang.email_ + ': ' + salon.email} fontSize={global.font_tiny} fontWeight={'400'} color={color.black} />
                                    <TextLine text={lang.mobil.toLowerCase() + ': ' + salon.mobil} fontSize={global.font_tiny} fontWeight={'400'} color={color.black} />
                                </div>
                            </div>
                            : null
                        : null}
                    <div style={{ ...styles.BlockCenter, width: props.isSmall ? 80 : 100, height: labelHeight, justifyContent: 'center' }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - labelHeight, backgroundColor: props.background == true ? global.theme_back : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '100%', height: dialogHeight - labelHeight - buttonHeight }}>
                        {/* BODY */}
                        <div style={{ ...styles.BlockCenter, width: '100%', height: dialogHeight - labelHeight - buttonHeight, paddingBottom: 10, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                            {product != false ?
                                <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, backgroundColor: global.theme_white }}>
                                    <div style={{ ...styles.Block, width: imageSize + 20, height: imageSize + 20 }}>
                                        <img src={product.image == null || product.image == '' ? no_image : GetImageLink(product.image)} style={{ width: '100%', height: '100%', width: imageSize - 8, height: imageSize - 8, objectFit: 'contain', borderRadius: 20 }}></img>
                                    </div>
                                    <div style={{ ...styles.Block, width: dialogWidth - imageSize - 20, height: props.isSmall ? undefined : imageSize + 20, paddingBottom: props.isSmall ? 20 : 0 }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{product.code}</p>
                                        <p style={{ ...styles.TextNormal, fontWeight: '600', textAlign: 'center' }}>{product.label}</p>
                                        <div style={{ ...styles.BlockRowRaw, marginTop: 10, width: dateError == false ? 200 : 280 }}>
                                            {dateError == true ?
                                                <div style={{ ...styles.Block, width: 40 }}></div>
                                                : null}
                                            <div style={{ ...styles.Block }}>
                                                {itemID == 0 ?
                                                    <div style={{ ...styles.BlockRowRaw }}>
                                                        <div style={{ ...styles.Block, width: 150 }}>
                                                            <p style={{ ...styles.TextTiny, marginBottom: 5 }}>{lang.expiration_month} { }</p>
                                                            <PlusMinus min={1} max={12} value={month} backgroundColor={global.theme_gray} func={(value) => setMonth(value)} />
                                                        </div>
                                                        <div style={{ ...styles.Block, width: 150 }}>
                                                            <p style={{ ...styles.TextTiny, marginBottom: 5 }}>{lang.expiration_year}</p>
                                                            <PlusMinus min={2024} value={year} backgroundColor={global.theme_gray} func={(value) => setYear(value)} />
                                                        </div>

                                                    </div>
                                                    :
                                                    <>
                                                        <p style={{ ...styles.TextXSmall, marginTop: 10 }}>{lang.expiration_date}: <b>{month}/{year}</b></p>
                                                        <p style={{ ...styles.TextXSmall, marginTop: 5 }}>{lang.expiration_quantity}: <b>{quantity}</b></p>
                                                    </>
                                                }
                                            </div>
                                            {dateError == true ?
                                                <div style={{ ...styles.Block, width: 40 }}>
                                                    <FontAwesomeIcon style={{ height: 30, color: global.theme_red, marginTop: 23 }} icon={faExclamationCircle} />
                                                </div>
                                                : null}
                                        </div>
                                        {itemID == 0 ?
                                            <Button onClick={() => setShowProducts(true)} style={{ ...styles.ButtonOutlined, marginTop: 30 }}>
                                                {product == false ? lang.product_select : lang.expiration_product_change}
                                            </Button>
                                            : null}
                                    </div>
                                </div>
                                :
                                <div style={{ ...styles.Block, marginTop: 20 }}>
                                    <p style={{ ...styles.TextXSmall, marginBottom: 10 }}>{lang.expiration_product_select}</p>
                                    <Button onClick={() => setShowProducts(true)} style={{ ...styles.ButtonDark, backgroundColor: color.theme_dark }}>
                                        {product == false ? lang.product_select : lang.expiration_product_change}
                                    </Button>
                                </div>
                            }

                            {message != '' ?
                                <div style={{ ...styles.BlockLeft, paddingTop: 10, paddingBottom: 10, width: '90%' }}>
                                    <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{lang.expiration_deny_reason}:</p>
                                    <p style={{ ...styles.TextXSmall, textAlign: 'left', marginTop: 5 }}>{message}</p>
                                </div>
                                : null}

                            {props.isSmall ?
                                <div style={{ ...styles.Block }}>
                                    <FontAwesomeIcon style={{ height: 30, color: global.theme_black }} icon={faAngleDown} />
                                </div>
                                : null}

                            {product != false ?
                                itemID == 0 ?
                                    <div style={{ ...styles.Block, marginTop: 20 }}>
                                        <p style={{ ...styles.TextTiny, marginBottom: 5 }}>{lang.expiration_quantity}</p>
                                        <PlusMinus min={1} value={quantity} backgroundColor={global.theme_white} func={(value) => setQuantity(value)} />
                                    </div>
                                    : null
                                : null}

                            {product != false ?
                                <div style={{ ...props.isSmall ? styles.Block : styles.BlockRowRaw, marginTop: 15 }}>

                                    <div style={{ ...styles.Block, width: photoSize + 40 }}>
                                        <div style={{ ...styles.Block, height: 30 }}>
                                            <p style={{ ...styles.TextXSmall }}>{lang.expiration_photo_date}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: photoSize + 20, height: photoSize + 20, backgroundColor: photoDate == false ? undefined : global.theme_white, borderRadius: 10, border: photoDate == false ? '1px solid #A0A0A0' : null }}>
                                            {photoDate != false ?
                                                <div style={{ ...styles.Block, width: photoSize, height: photoSize, position: 'relative' }}>
                                                    <img onClick={() => PhotoPreview(photoDate)} src={photoDate == null || photoDate == '' ? no_image : GetImageLink(photoDate)} style={{ width: '100%', height: '100%', width: imageSize, height: imageSize, objectFit: 'contain', cursor: 'pointer' }}></img>
                                                    {itemID == 0 ?
                                                        <IconButton onClick={() => setPhotoDate(false)} style={{ ...styles.ButtonIcon, position: 'absolute', top: 0, right: 0, backgroundColor: global.theme_white }}>
                                                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                                                        </IconButton>
                                                        : null}
                                                </div>
                                                :
                                                <Button onClick={() => { setShowAddPhotoDate(true); setError('') }} style={{ flexDirection: 'column', width: photoSize, height: 80 }}>
                                                    <FontAwesomeIcon style={{ height: 20, color: global.theme_blue, marginRight: 10 }} icon={faImage} />
                                                    <p style={{ ...styles.TextTiny, color: global.theme_blue, marginTop: 5, textTransform: 'none' }}>{lang.photo_choose_}</p>
                                                </Button>
                                            }
                                        </div>
                                    </div>

                                    {props.isSmall ?
                                        <div style={{ ...styles.Block, marginTop: 30 }}>
                                            <FontAwesomeIcon style={{ height: 30, color: global.theme_black }} icon={faAngleDown} />
                                        </div>
                                        : null}

                                    <div style={{ ...styles.Block, width: photoSize + 40, marginTop: props.isSmall ? 30 : 0, paddingBottom: props.isSmall ? 50 : 0 }}>
                                        <div style={{ ...styles.Block, height: 30 }}>
                                            <p style={{ ...styles.TextXSmall }}>{lang.expiration_photo_product}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: photoSize + 20, height: photoSize + 20, backgroundColor: photoProduct == false ? undefined : global.theme_white, borderRadius: 10, border: photoProduct == false ? '1px solid #A0A0A0' : null }}>
                                            {photoProduct != false ?
                                                <div style={{ ...styles.Block, width: photoSize, height: photoSize, position: 'relative' }}>
                                                    <img onClick={() => PhotoPreview(photoProduct)} src={photoProduct == null || photoProduct == '' ? no_image : GetImageLink(photoProduct)} style={{ width: '100%', height: '100%', width: imageSize, height: imageSize, objectFit: 'contain', cursor: 'pointer' }}></img>
                                                    {itemID == 0 ?
                                                        <IconButton onClick={() => { setPhotoProduct(false); setError('') }} style={{ ...styles.ButtonIcon, position: 'absolute', top: 0, right: 0, backgroundColor: global.theme_white }}>
                                                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                                                        </IconButton>
                                                        : null}
                                                </div>
                                                :
                                                <Button onClick={() => setShowAddPhotoProduct(true)} style={{ flexDirection: 'column', width: photoSize, height: 80 }}>
                                                    <FontAwesomeIcon style={{ height: 20, color: global.theme_blue, marginRight: 10 }} icon={faImage} />
                                                    <p style={{ ...styles.TextTiny, color: global.theme_blue, marginTop: 5, textTransform: 'none' }}>{lang.photo_choose_}</p>
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                    </div>

                    <div style={{ ...styles.Block, width: '100%', height: buttonHeight, backgroundColor: global.themes[props.theme].theme_lighter, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                        {error == '' ?
                            <div style={{ ...styles.Block, height: 40 }}>
                                {itemID > 0 ?
                                    <p style={{ ...styles.TextXSmall }}>{lang.expiration_status}: <b>{lang.expiration_statuses[status]}</b></p>
                                    :
                                    product != false && (photoDate == false || photoProduct == false) ?
                                        <Chip style={{ backgroundColor: global.theme_dark_red }} label={<p style={{ ...styles.TextXSmall, color: global.theme_white }}>{photoDate == false && photoProduct == false ? lang.expiration_photos_missing : photoDate == false ? lang.expiration_photos_missing1 : photoProduct == false ? lang.expiration_photos_missing2 : ''}</p>}></Chip>
                                        : null
                                }
                            </div>
                            :
                            <div style={{ ...styles.Block, height: 40 }}>
                                <FormError error={error} backgroundColor={global.theme_dark_red} small />
                            </div>
                        }
                        {props.agent == true && status == 0 ?
                            <div style={{ ...styles.BlockRowRaw }}>
                                <div style={{ ...styles.Block, height: buttonHeight - 40 }}>
                                    <Button onClick={() => setShowAccept(true)} style={{ ...styles.ButtonDark, backgroundColor: global.theme_darker_green }}>
                                        {lang.expiration_statuses[1]}
                                    </Button>
                                </div>
                                <div style={{ ...styles.Block, height: buttonHeight - 40, marginLeft: 20 }}>
                                    <Button onClick={() => setShowDeny(true)} style={{ ...styles.ButtonDark, backgroundColor: global.theme_dark_red }}>
                                        {lang.expiration_statuses[2]}
                                    </Button>
                                </div>
                            </div>
                            :
                            <div style={{ ...styles.BlockRowRaw }}>
                                {itemID == 0 && product != false && photoDate != false && photoProduct != false ?
                                    <div style={{ ...styles.Block, width: 240, height: buttonHeight - 40 }}>
                                        <Button onClick={() => Send()} style={{ ...styles.ButtonDark, backgroundColor: color.theme_dark, width: 220 }}>
                                            {lang.expiration_send}
                                        </Button>
                                    </div>
                                    :
                                    props.isSmall == true ?
                                        <div style={{ ...styles.Block, width: 240, height: buttonHeight - 40 }}>
                                            <Button onClick={() => Close()} style={{ ...styles.ButtonDark, backgroundColor: color.close, width: 220 }}>
                                                {lang.close}
                                            </Button>
                                        </div>
                                        : null
                                }
                                {props.isSmall ? null :
                                    <div style={{ ...styles.Block, width: 240, height: buttonHeight - 40 }}>
                                        <Button onClick={() => Close()} style={{ ...styles.ButtonDark, backgroundColor: color.close, width: 220 }}>
                                            {lang.close}
                                        </Button>
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div>
            </Paper>

            {showSalonCard == true && salon != false ?
                <SalonCard salon_id={salon.id} remoteApp={props.remoteApp} user={props.user} admin={props.admin} countries={props.countries} country={props.country} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} isSmall={props.isSmall} title={lang.salons_list} sub_title={lang.salons} func={() => setShowSalonCard(false)} />
                : null}

            {showAccept == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.expiration_accept} sub_title={''} text={lang.expiration_accept_text} sub_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={AcceptResult.bind(this)} />
                : null}

            {showDeny == true ?
                <DialogEnterText theme={props.theme} rows={8} radius={props.radius} title={lang.expiration_deny} sub_title={lang.reklamacie_deny_reason} text={''} icon={faInfoCircle} button={props.lang.save} func={DenyResult.bind(this)} />
                : null}

            {showAskSend == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.expiration_send + '?'} sub_title={''} text={lang.expiration_send_ask} sub_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={SendResult.bind(this)} />
                : null}

            {showPhoto == true ?
                <DialogImagePreview theme={props.theme} file={GetImageLink(photo)} radius={props.radius} lang={props.lang} title={lang.preview} sub_title={lang.expiration} background={global.theme_dark_gray} crop={false} func={() => setShowPhoto(false)} />
                : null}

            {showAddPhotoDate == true ?
                <Photos lang={props.lang} typ={6} max_size={1600} full title={lang.photo_choose} sub_title={lang.expiration_photo_date} radius={props.radius} offset={props.offset} theme={props.theme} func={PhotoResultDate.bind(this)} />
                : null}

            {showAddPhotoProduct == true ?
                <Photos lang={props.lang} typ={6} max_size={1600} full title={lang.photo_choose} sub_title={lang.expiration_photo_product} radius={props.radius} offset={props.offset} theme={props.theme} func={PhotoResultProduct.bind(this)} />
                : null}

            {showProducts == true ?
                <ProductSelect esthederm employee_id={props.employee_id} isSmall={props.isSmall} employees={props.employees} user={props.user} lang={lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} func={ProductSelectResult.bind(this)} />
                : null}

            {showError == true ?
                <ShowError text={lang.faktura_error} offset={props.offset} theme={props.theme} func={ShowErrorPress.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop >
    );
}
