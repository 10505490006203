import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { IsEmpty, TextInput, CopyData, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, GetPrice, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, ShowError, ShowOK, TemplateWindow, DialogEmail, DialogNotify, GetMoney, Debug, GetImageLink } from './items';
import { styles } from './styles';
import { Backdrop, Button, IconButton, InputAdornment, MenuItem, Pagination, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faEnvelope, faExclamationTriangle, faL, faLayerGroup, faList, faListAlt, faMobileAlt, faPen, faQuestion, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';
import { ProductDialog } from './product_dialog';
import { ServicesDialog } from './service_dialog';
import { DialogClient } from './clients_dialogs';


export const UserSelect = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(props.country != false ? props.country.id : 0);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);
    const [filter, setFilter] = useState(false);
    const [filterTyp, setFilterTyp] = useState(4);

    // ZOZNAM REPREZENTANTOV
    const [agents, setAgents] = useState(false);
    const [agentID, setAgentID] = useState(-1);

    // ZOZNAM
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const itemsMax = 24;

    // EDITING + NOVY PRODUKT
    const [itemSelected, setItemSelected] = useState(false);
    const [editing, setEditing] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1200 ? 1200 : width;
    const dialogHeight = height >= 850 ? 850 : height;

    const menuWidth = props.isSmall ? 0 : 250;
    const rowHeight = props.isSmall ? 110 : 80;
    const rowWidth = 1000;
    const bottomHeight = 120;

    // COLORS
    const font_selected = global.theme_black;
    const font_disabled = global.theme_darker;


    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {

        if (running == false) {
            running = true;

            var filter_ = { reps: false, language: props.country.language_id };
            db_get(filter_);
        }

    }, []);

    const db_get = async (filter_) => {

        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'users', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    admin: props.admin,
                    filter: filter_
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;

                if (tmp != false) {
                    tmp.sort((a, b) => {
                        if (a.surname > b.surname) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ChangeIndex = (id) => {
        // ZMENA KRAJINY
        if (id > 0) {
            var item = props.countries.find(x => x.id == id);
            if (item != undefined) {
                var filter_ = { reps: false, language: item.language_id };
                setIndex(item.id);
                setAgentID(0);
            }
        } else {
            var filter_ = { reps: true };
            setIndex(id);
        }
        setSearch('');
        setFilter(filter_);
        db_get(filter_);
    }

    const Search = (value) => {
        setSearch(value);
        if (value.length > 2) {
            var tmp = items.filter(x => x.name.toLowerCase().includes(value.toLowerCase()) || x.surname.toLowerCase().includes(value.toLowerCase()));
            setItemsFiltered(tmp);
        } else {
            setItemsFiltered(items);
        }
    }

    const Redraw = (items, p, search) => {
        // REDRAW ZOZNAMU
        var data = items;

        var count = data.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax)
        setItemsFiltered(data);

        if (count == 0) {
            setItemsFiltered(false);
        }

        setPage(p);
        GoToStart();
    }

    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);

        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0, '');
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_, search);
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_scroll');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const Card = (item) => {

    }

    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Backdrop open={true} style={{ zIndex: 31000, paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                            <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                                <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.Block, width: props.isSmall ? '45%' : '35%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                        <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>
                            {props.isSmall ? null :
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                            }
                        </div>
                    </div>
                    <div style={{ ...styles.BlockRow, justifyContent: 'flex-end', alignItems: 'center', width: props.isSmall ? 160 : '50%', height: global.dialog_bar_height }}>
                        <TextField
                            value={search}
                            onInput={e => Search(e.target.value)}
                            size="small"
                            autoCapitalize="off"
                            autoComplete="off"
                            autoCorrect="off"
                            style={{ width: props.isSmall ? 150 : 300 }}
                            placeholder={lang.search_in_category}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                }
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                        <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                    </IconButton>
                                </InputAdornment>,
                                style: { backgroundColor: global.theme_white, borderRadius: 30 },
                            }}
                            variant="outlined"
                        />
                    </div>
                    <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, width: dialogWidth, height: dialogHeight - global.dialog_bar_height }}>

                        {props.isSmall ?
                            <div style={{ ...styles.Block }}>
                                <Select
                                    value={index}
                                    onChange={event => ChangeIndex(event.target.value)}
                                    size='small'
                                    style={{ backgroundColor: global.theme_white, width: '100%' }}
                                >
                                    {props.countries != false ?
                                        props.countries.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>{item.country}</MenuItem>
                                        ))
                                        : null}
                                    <MenuItem value={-1}>{lang.reps}</MenuItem>
                                </Select>
                            </div>
                            :
                            <div style={{ ...styles.BlockCenter, width: menuWidth, height: dialogHeight - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                                <div style={{ ...styles.BlockCenter, width: menuWidth, height: dialogHeight - global.dialog_bar_height - bottomHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    {props.countries != false ?
                                        props.countries.map((item) => (
                                            <Button key={item.id} onClick={() => ChangeIndex(item.id)} style={{ ...styles.ButtonSettingsMedium, justifyContent: 'flex-start', backgroundColor: index == item.id ? global.theme_back : '#00000000', color: index == item.id ? font_selected : font_disabled }}>
                                                <div style={{ ...styles.BlockLeft }}>
                                                    <p style={{ ...styles.TextSmall, fontSize: global.font_small, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{item.country}</p>
                                                </div>
                                            </Button>
                                        ))
                                        : null}
                                    <Button onClick={() => ChangeIndex(-1)} style={{ ...styles.ButtonSettingsMedium, justifyContent: 'flex-start', backgroundColor: index == -1 ? global.theme_back : '#00000000', color: index == -1 ? font_selected : font_disabled }}>
                                        <div style={{ ...styles.BlockLeft }}>
                                            <p style={{ ...styles.TextSmall, fontSize: global.font_small, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{lang.reps}</p>
                                        </div>
                                    </Button>
                                </div>
                                <div style={{ ...styles.Block, height: bottomHeight, borderTop: '1px solid #FFFFFF50' }}>
                                </div>
                            </div>
                        }
                        <div style={{ ...styles.BlockCenter, width: dialogWidth - menuWidth, height: dialogHeight - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            {/* BODY */}
                            <div id={'id_scroll'}></div>
                            <div style={{ ...styles.Block, width: '98%' }}>
                                {/* *********************************************************************************************************  
                                ZOZNAM UZIVATELOV
                                ********************************************************************************************************* */}
                                {itemsFiltered != false ?
                                    itemsFiltered.map((item) => (
                                        props.isSmall ?
                                            <Paper onClick={() => Press(true, item)} elevation={item.id == overID ? 4 : 1} key={item.id} style={{
                                                ...styles.Block, maxWidth: rowWidth, height: rowHeight,
                                                backgroundColor: item.typ == global.account_salon ? global.theme_gray : global.theme_lighteen,
                                                marginTop: 10,
                                                marginBottom: 10,
                                                borderRadius: props.isSmall ? 20 : rowHeight / 2,
                                                cursor: 'pointer'
                                            }}>
                                                <div style={{ ...styles.BlockRow, height: rowHeight }}>
                                                    <div style={{ ...styles.Block, width: 100, height: rowHeight }}>
                                                        <p style={{ ...styles.TextXTiny }}>{item.typ == 2 ? lang.settings_salon : lang.rep}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: '85%', height: rowHeight }}>
                                                        <div style={{ ...styles.Block }}>
                                                            <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{item.name} {item.surname}</p>
                                                        </div>
                                                        <div style={{ ...styles.Block, marginTop: 5 }}>
                                                            {item.typ == 2 ?
                                                                <p style={{ ...styles.TextTiny }}>{item.salon_town}</p>
                                                                : null}
                                                            <p style={{ ...styles.TextTiny }}>{lang.mobil}: {item.mobil}</p>
                                                            <p style={{ ...styles.TextTiny }}>{lang.email_}: {item.email}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Paper>
                                            :
                                            <Paper onClick={() => Press(true, item)} elevation={item.id == overID ? 4 : 1} key={item.id} style={{
                                                ...styles.Block, maxWidth: rowWidth, height: rowHeight,
                                                backgroundColor: item.typ == global.account_salon ? global.theme_gray : global.theme_lighteen,
                                                marginTop: 10,
                                                marginBottom: 10,
                                                borderRadius: props.isSmall ? 20 : rowHeight / 2,
                                                cursor: 'pointer'
                                            }}
                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                            >
                                                <div style={{ ...styles.BlockRow, height: rowHeight }}>
                                                    <div style={{ ...styles.Block, width: 100, height: rowHeight }}>
                                                        <p style={{ ...styles.TextXTiny }}>{item.typ == 2 ? lang.settings_salon : lang.rep}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: '85%', height: rowHeight }}>
                                                        <div style={{ ...styles.BlockLeft, width: '94%' }}>
                                                            <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{item.name} {item.surname}</p>
                                                        </div>
                                                        <div style={{ ...styles.BlockRow, width: '94%', marginTop: 5 }}>
                                                            <div style={{ ...styles.BlockLeft, width: '60%', justifyContent: 'center' }}>
                                                                <p style={{ ...styles.TextTiny }}>{lang.mobil}: {item.mobil}</p>
                                                                <p style={{ ...styles.TextTiny }}>{lang.email_}: {item.email}</p>
                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: '40%', justifyContent: 'center' }}>
                                                                {item.typ == 2 ?
                                                                    <p style={{ ...styles.TextTiny }}>{item.salon_town}</p>
                                                                    : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Paper>
                                    )) :
                                    // ZIADNE ZAZNAMY                            
                                    <div style={{ ...styles.Block, paddingTop: 20 }}>
                                        {isBusy == false ?
                                            <EmptyList lang={lang} />
                                            : null}
                                    </div>
                                }

                                {itemsFiltered != false ?
                                    pageMax > 1 ?
                                        <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                            <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                        </div>
                                        : null
                                    : null}
                            </div>

                        </div>
                    </div>
                </div>

                {isBusy == true ?
                    <Loading offset={props.offset} theme={props.theme} ></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}
