/*
*
* =================================================================================================================
* REZERVAČNÝ SYSTÉM - DESKTOP
* =================================================================================================================
*
*/

import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation, createRoutesFromChildren } from "react-router-dom";
import { cz, sk } from './language.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark, faCircle, faBackward, faHome, faUser, faSearch, faRightFromBracket, faImage, faChevronRight, faChevronUp, faSquare, faBars, faTriangleExclamation, faInfoCircle, faTrash, faEdit, faAdd, faMessage, faQuestionCircle, faLayerGroup, faTag, faLocationDot, faBell, faCog, faList, faPlusCircle, faPlus, faCogs, faAngleLeft, faCalendarDay, faCheckDouble, faQuestion } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Drawer, Slide } from '@mui/material';
import { Alert, Autocomplete, Button, IconButton, MenuItem, Select, TextField, Paper, Tabs, Tab } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { styles } from './styles.js';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import useMediaQuery from '@mui/material/useMediaQuery';
import './items.css';
import { EmptyList, GetTodayDatum, GetHours, Today, GetDatum2, TextInput, DialogYesNo, Colors, GetImageLink, DialogImagePreview, GetTopPosition, GetDatum, GetDate } from './items.js';
import { TerminPreview } from './calendar_dialog.js';

export const DesktopReserve = (props) => {
    /*
            
            USAGE:
            <DesktopReserve radius={props.radius} title={'nazov'} sub_title={'podnázov'} [ backcolor={true} icon={faQuestion} background={false} ] func={DesktopReserveResult.bind(this)} />

            const DesktopReserveResult = (typ,value) => {
                console.log(value)
            }
            */
    const [isBusy, setBusy] = useState(true);
    const [items, setItems] = useState([]);
    const [showPreview, setShowPreview] = useState(false);
    const [selected, setSelected] = useState([]);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {

        if (running == false) {
            running = true;
            db_calendar();
        }

        return () => {
        };

    }, [props.employee_id, props.user]);

    const db_calendar = async () => {

        try {
            const response = await fetch(
                global.db_url + 'calendar', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    employee_id: props.employee_id,
                    reserve_only: true,
                    arr: [Today()]
                })
            })

            const json = await response.json();


            if (json.ok > 0) {
                var tmp = json.items;

                tmp = tmp.filter(x => x.deleted == false);
                
                tmp.sort((a, b) => {
                    if (a.date > b.date) {
                        return 1;
                    } else {
                        return -1;
                    }
                })

                tmp.forEach(item => {
                    item.services = JSON.parse(item.services);
                });

                setItems(tmp);
            }
            setBusy(false);
        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Preview = (item) => {
        setSelected(item);
        setShowPreview(true);
    }

    const PreviewResult = (typ, data) => {
        setShowPreview(false);
        selected.color = data.color;
        selected.verifed = data.verifed;
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: '100%', background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius, marginTop: props.marginTop != undefined ? props.marginTop : 0 }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                        <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                    </div>
                </div>
                <div style={{ ...styles.Block, width: '85%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: 45 }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>
                        <p style={{ ...styles.TextDialogSubLabel, marginTop: 4, textAlign: 'left' }}>{props.sub_title}</p>
                    </div>
                </div>
            </div>
            <div style={{ ...styles.BlockCenter, backgroundColor: props.background == true ? global.theme_back : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, paddingBottom: props.radius }}>
                <div style={{ ...styles.Block, minHeight: 60, paddingTop: 10, paddingBottom: props.isSmall ? 10 : 0 }}>
                    {items.length > 0 ?
                        <div style={{ ...styles.Block }}>
                            {items.map((item, index) => (
                                item.verifed == false ?
                                    <div onClick={() => Preview(item)} key={item.id} style={{ ...styles.Block, backgroundColor: item.color > 0 ? global.colors[item.color] : undefined, paddingTop: 7, paddingBottom: 7, borderBottom: item.color > 0 ? '' : index < items.length - 1 ? '1px solid #DDDDDD' : '', minHeight: 50, cursor: 'pointer' }}>
                                        <div style={{ ...styles.BlockRow, width: '98%' }}>
                                            <div style={{ ...styles.BlockLeft, width: 120 }}>
                                                <p style={{ ...styles.TextSmall }}><b>{GetDate(item.date)}</b></p>
                                                <p style={{ ...styles.TextTiny, marginTop: 2 }}>{GetHours(item.time_start)}-{GetHours(item.time_end)}</p>
                                            </div>
                                            <div style={{ ...styles.BlockLeft, width: '85%' }}>
                                                <p style={{ ...styles.TextSmall }}>{item.label}</p>
                                                <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{item.name} {item.surname}</p>
                                                {item.mobil != '' ?
                                                    <p style={{ ...styles.TextTiny, marginTop: 4, color: global.theme_dark_gray }}>{lang.mobil.toLowerCase()}: {item.mobil}</p>
                                                    : null}
                                                {item.email != '' ?
                                                    <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.email_.toLowerCase()}: {item.email}</p>
                                                    : null}
                                                {/*
                                            <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.note.toLowerCase()}: {item.note}</p>
                                            <div style={{ ...styles.BlockRowRaw, marginTop: 5 }}>
                                                <p style={{ ...styles.TextTiny }}>{lang.services}: </p>
                                                {item.services.map(element => (
                                                    <p style={{ ...styles.TextTiny, marginLeft: 5 }}>{element.label}, </p>
                                                ))}
                                            </div>
                                            */}
                                            </div>

                                        </div>
                                    </div>
                                    : null
                            ))}
                        </div>
                        :
                        <div style={{ ...styles.Block, paddingTop: 0, height: 60 }}>
                            {isBusy == false ?
                                <EmptyList row small lang={lang} />
                                : null}
                        </div>
                    }
                </div>
            </div>

            {showPreview == true ?
                <TerminPreview isSmall={props.isSmall} item={selected} lang={lang} language={props.language} radius={props.radius} title={GetDatum2(selected.date)} sub_title={GetHours(selected.time_start) + ' - ' + GetHours(selected.time_end)} backcolor={true} icon={faCalendarDay} background={true} employees={props.employees} employee_id={props.employee_id} user={props.user} offset={props.offset} theme={props.theme} func={PreviewResult.bind(this)} />
                : null}
        </Paper>
    );
}
