import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, ConvertToPrice, CopyData, Debug, DialogEnterText, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, GetDate, GetImageLink, GetPages, GetPrice, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, ShowError, ShowOK, TemplateWindow, TextInput, TextLine, Today } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, CircularProgress, FormControlLabel, IconButton, InputAdornment, MenuItem, Pagination, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCheckCircle, faEdit, faImage, faInfo, faL, faLayerGroup, faList, faListAlt, faListCheck, faPeace, faPen, faQuestion, faQuestionCircle, faSave, faShoppingBag, faShoppingBasket, faTrash, faUser, faWarning, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';
import { ProductDialog, ProductPreview, ShoppingCard } from './product_dialog';
import { ClientSelect } from './client_select';
import { centerCrop } from 'react-image-crop';
import { ProductBookingCard } from './products_booking';
import { Photos } from './photos';


export const BonusBookingHistory = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);

    // PREDAJ - ZOZNAM
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(false);
    const itemsMax = global.items_max;

    // NAHLAD
    const [itemSelected, setItemSelected] = useState(false);
    const [showOrder, setShowOrder] = useState(false);

    const [showOK, setShowOK] = useState(false);

    // zobrazovat nevybavene
    const [showActive, setShowActive] = useState(false);

    const menuWidth = 250;
    const rowHeight = props.isSmall ? 110 : 80;
    const rowWidth = 850;

    // COLORS
    const font_selected = global.theme_black;
    const font_disabled = global.theme_darker;

    const no_image = require('./react/app/no_image.jpg');

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;
            db_orders(0, false);
        }

    }, []);

    const db_orders = async (page, filter) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'bonus_orders', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    language: props.language,
                    start: page * itemsMax,
                    length: itemsMax,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, itemsMax));

                setFilter(filter);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }



    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }


    const Search = (txt) => {
        setSearch(txt);
        if (txt.length > 2) {
            let filter = { active: showActive, search: txt };
            db_orders(0, filter);
        }
        if (txt == '') {
            let filter = { active: showActive };
            db_orders(0, filter);
        }
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_orders');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const ChangeActive = (value) => {
        setShowActive(value);
        let filter = { active: value, search: search };
        db_orders(0, filter);
    }

    const ChangePage = (value) => {
        db_orders(value - 1, filter);
    }

    // ------------------------------------------------------------------------------------
    // NAHLAD OBJEDNAVKY
    // ------------------------------------------------------------------------------------
    const Edit = (item) => {
        setItemSelected(item);
        setShowOrder(true);
    }

    const ProductCardResult = (value, value2) => {

        setShowOrder(false);
        if (value == true || value2 == true) {
            db_orders(page, filter);
        }
    }


    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? '55%' : '35%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left', marginLeft: props.isSmall ? 10 : 0 }}>{props.title}</p>
                        {props.isSmall ? null :
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        }
                    </div>
                </div>
                {props.isSmall ? null :
                    <div style={{ ...styles.BlockRow, justifyContent: 'flex-end', alignItems: 'center', width: '30%', height: global.dialog_bar_height }}>
                        <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.show_active}</p>}
                            control={<Checkbox checked={showActive} size={'small'} onChange={() => ChangeActive(!showActive)} />}
                        />
                    </div>
                }
                <div style={{ ...styles.BlockRow, justifyContent: 'flex-end', alignItems: 'center', width: props.isSmall ? 160 : '30%', height: global.dialog_bar_height }}>
                    <TextField
                        value={search}
                        onInput={e => Search(e.target.value)}
                        size="small"
                        autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off"
                        style={{ width: props.isSmall ? 160 : 300 }}
                        placeholder={lang.search_in_category}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                            }
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                    <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                </IconButton>
                            </InputAdornment>,
                            style: { backgroundColor: global.theme_white, borderRadius: 30 },
                        }}
                        variant="outlined"
                    />
                </div>
                <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockCenter, width: props.width, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_white, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                        {/* *********************************************************************************************************  
                                ZOZNAM OBJEDNAVOK
                        ********************************************************************************************************* */}

                        <div id={'id_orders'} style={{ ...styles.BlockCenter, width: '98%' }}>

                            {props.isSmall ?
                                <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                                    <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.show_active}</p>}
                                        control={<Checkbox checked={showActive} size={'small'} onChange={() => ChangeActive(!showActive)} />}
                                    />
                                </div>
                                : null}

                            {itemsFiltered != false ?
                                itemsFiltered.map((item, index) => (
                                    <Paper elevation={overID == item.id ? 5 : 2} key={item.id} style={{ ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: global.theme_gray, marginTop: 8, marginBottom: 8, borderRadius: global.row_radius, cursor: 'pointer' }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow }}>
                                            <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: props.isSmall ? 70 : rowHeight, justifyContent: 'center' }}>
                                                <div style={{ ...styles.Block, maxHeight: rowWidth }}>
                                                    <div style={{ ...styles.Block, height: 36, width: 36, backgroundColor: global.booking_color[item.status], borderRadius: '50%' }}>
                                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={global.booking_icon[item.status]} />
                                                    </div>
                                                </div>
                                            </div>
                                            {props.isSmall ?
                                                <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{lang.booking_preview} <span style={{ fontSize: global.font_xtiny, fontWeight: '400' }}>{item.number}</span></p>
                                                    <p style={{ ...styles.TextTinyMobile }}>{GetDate(item.date)}</p>
                                                    <p style={{ ...styles.TextTinyMobile }}>{lang.booking_items}: {item.count} {lang.pieces}</p>
                                                    {item.reklamacia == true ?
                                                        <p style={{ ...styles.TextTinyMobile, color: global.theme_dark_red, marginTop: 10 }}>{lang.booking_reklamacia_true}</p>
                                                        :
                                                        <p style={{ ...styles.TextTinyMobile, marginTop: 10 }}>{lang.booking_status_salon[item.status]}</p>
                                                    }
                                                </div>
                                                :
                                                <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{lang.booking_preview} <span style={{ fontSize: global.font_xtiny, fontWeight: '400' }}>{item.number}</span></p>
                                                    <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                        <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                            <div style={{ ...styles.BlockLeft, width: '30%' }}>
                                                                <p style={{ ...styles.TextTiny }}>{GetDate(item.date)}</p>
                                                            </div>
                                                            <div style={{ ...styles.Block, width: '30%' }}>
                                                                <p style={{ ...styles.TextTiny }}>{lang.booking_items}: {item.count} {lang.pieces}</p>
                                                            </div>
                                                            <div style={{ ...styles.BlockRight, width: '40%' }}>
                                                                {item.reklamacia == true ?
                                                                    <p style={{ ...styles.TextTiny, color: global.theme_dark_red }}>{lang.booking_reklamacia_true}</p>
                                                                    :
                                                                    <p style={{ ...styles.TextTiny }}>{lang.booking_status_salon[item.status]}</p>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </Paper>
                                )) :
                                // ZIADNE OBJEDNAVKY                            
                                <div style={{ ...styles.Block, paddingTop: 20 }}>
                                    <EmptyList lang={lang} />
                                </div>
                            }

                            {pages > 1 ?
                                <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                </div>
                                : null}

                        </div>

                    </div>
                </div>
            </div>

            {
                showOrder == true ?
                    <BonusBookingDetails isSmall={props.isSmall} offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} item={itemSelected} lang={lang} radius={props.radius} backcolor={true} background={true} language={props.language} func={ProductCardResult.bind(this)} />
                    : null
            }

            {
                showOK == true ?
                    <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null
            }

            {
                isBusy == true ?
                    <Loading offset={props.offset} theme={props.theme} ></Loading>
                    : null
            }

        </Paper >
    );
}


export const BonusBookingDetails = (props) => {


    const [isBusy, setBusy] = useState(false);
    const [isUpdating, setUpdating] = useState(false);
    const [order, setOrder] = useState(false);
    const [orderStatus, setOrderStatus] = useState(0);
    const [items, setItems] = useState(false);

    // poznámka k objednávke
    const [note, setNote] = useState('');
    const [showNote, setShowNote] = useState(false);
    const [dph, setDph] = useState(1);

    // Opytat sa na stav objednavky
    const [showAsk, setShowAsk] = useState(false);
    const [showAskOK, setShowAskOK] = useState(false);
    const [showAskError, setShowAskError] = useState(false);

    const [showOK, setShowOK] = useState(false);

    // Reklamacia
    const [showReklamacia, setShowReklamacia] = useState(false);
    const [reklamacia, setReklamacia] = useState(false);


    // Suma
    const [sum, setSum] = useState(0);

    // cislo objednavky
    const [number, setNumber] = useState('');

    // zmena stavu
    const [showDelete, setShowDelete] = useState(false);
    const [showVerify, setShowVerify] = useState(false);
    const [changed, setChanged] = useState(false);

    // Rozmery okna
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1000 ? 1000 : width;
    const dialogHeight = height >= 768 ? 768 : height;

    const infoHeight = 34;
    const buttonHeight = props.isSmall ? 160 : 100;
    const rowHeight = props.isSmall ? 120 : 100;


    const no_image = require('./react/app/no_image.jpg');


    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            db_get();

        }
        return () => {
        };

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'bonus_order', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language,
                    order_id: props.item.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setItems(json.items);
                setNumber(json.order.number);
                setOrder(json.order);
                setNote(json.order.note);
                setOrderStatus(json.order.status);
                setSum(json.sums.sum);
                setDph(json.dph);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data, close) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'bonus_order_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (close == true) {
                    Press(true);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_ask = async (message) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'products_booking_ask', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    label: lang.bonus_ask_status_notify + ' ' + order.number,
                    message: message,
                    order_number: order.number,

                    label_email: lang.email,
                    label_mobil: lang.mobil,
                    label_not_reply: lang.do_not_reply,
                    label_not_reply_text: lang.do_not_reply_text,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowAskOK(true);
            } else {
                setShowAskError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (redraw) => {
        var value = false;
        if (orderStatus != order.status) {
            value = true;
        }
        if (reklamacia == true) {
            value = true;
        }
        if (changed == true) {
            value = true;
        }
        func(value, redraw);
    }


    const DeleteResult = (typ) => {
        setShowDelete(false);
        if (typ == true) {
            let data = {
                id: props.item.id,
                status: 3
            }

            db_update(data, true);
        }
    }

    const DialogEnterTextResult = (typ, text) => {
        setShowNote(false);
        if (typ == true) {
            setNote(text);
            let data = {
                id: props.item.id,
                note: text
            }

            db_update(data);
        }
    }

    const AskResult = (typ, text) => {
        setShowAsk(false);
        if (typ == true) {
            db_ask(text);
        }
    }

    const ReklamaciaResult = (value) => {
        setShowReklamacia(false);
        if (value == true) {
            // bola vytvorena reklamacia -> po uzatvoreni okna nacitat objednavky
            setReklamacia(true);
        }
        if (value == true) {
            db_get();
        }
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }


    const VerifyResult = (redraw) => {
        setShowVerify(false);
        if (redraw == true) {
            setChanged(true);
            db_get();
        }
    }

    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faShoppingBasket} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '56%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.lang.bonus_booking}</p>
                            <p style={{ ...styles.TextTiny, marginTop: 4 }}>{lang.booking_number}: <b>{number}</b></p>
                        </div>
                    </div>


                    <div style={{ ...styles.Block, width: 230, height: global.dialog_bar_height, marginLeft: 10 }}>
                        <div style={{ ...styles.Block, height: global.dialog_bar_height - 8, backgroundColor: global.theme_white, borderRadius: props.radius }}>
                            {isUpdating == false ?
                                <div style={{ ...styles.Block, height: global.dialog_bar_height - 8 }}>
                                    <p style={{ ...styles.TextTiny }}>{props.lang.bonus_pay_sum}</p>
                                    <p style={{ ...props.isSmall ? styles.TextTiny : styles.TextNormal, marginTop: 0, fontWeight: '600' }}>{GetPrice(ConvertToPrice(sum * dph))} {props.lang.money} <span style={{ ...styles.TextTiny }}>{lang.with_dph}</span></p>
                                    <p style={{ ...styles.TextTiny, marginTop: 2 }}>{GetPrice(ConvertToPrice(sum))} {props.lang.money} {lang.without_dph}</p>
                                </div>
                                : <div style={{ ...styles.Block, height: global.dialog_bar_height - 8 }}>
                                    <CircularProgress size={16}></CircularProgress>
                                </div>
                            }
                        </div>
                    </div>

                    <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? global.theme_back : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '100%', height: dialogHeight - global.dialog_bar_height - buttonHeight }}>
                        {/* BODY */}
                        {order.reklamacia == true ?
                            <div style={{ ...styles.Block, height: infoHeight, backgroundColor: global.theme_dark_red }}>
                                <p style={{ ...styles.TextSmall, color: global.theme_white }}>{lang.booking_reklamacia_status}: {lang.reklamacia_status[order.reklamacia_status]}</p>
                            </div>
                            :
                            order.changes == true ?
                                <div style={{ ...styles.Block, height: infoHeight, backgroundColor: global.theme_dark_red }}>
                                    <p style={{ ...styles.TextSmall, color: global.theme_white }}>{lang.booking_changes}</p>
                                </div>
                                :
                                <div style={{ ...styles.Block, height: infoHeight, backgroundColor: global.theme_white }}>
                                    <p style={{ ...styles.TextSmall, color: global.theme_dark }}>{lang.products_list}</p>
                                </div>
                        }
                        <div style={{ ...styles.BlockCenter, width: '100%', height: dialogHeight - global.dialog_bar_height - buttonHeight - infoHeight, overflowY: 'scroll', paddingBottom: 10 }}>

                            {/* *********************************************************************************************************  
                                DOVOD ZAMIETNUTIA
                            ********************************************************************************************************* */}
                            {order.reklamacia == true ?
                                order.reklamacia_status == 3 ?
                                    <Paper elevation={2} style={{ ...styles.Block, width: '96%', backgroundColor: global.light_gray, marginTop: 5, marginBottom: 5, borderRadius: 10 }}>
                                        <div style={{ ...styles.BlockLeft, width: '98%' }}>
                                            <p style={{ ...styles.TextSmall, textAlign: 'left', marginTop: 10, fontWeight: '600' }}>{lang.reklamacie_deny_reason}:</p>
                                            <p style={{ ...styles.TextSmall, textAlign: 'left', marginTop: 5, marginBottom: 10 }}>{order.deny_reason}</p>
                                        </div>
                                    </Paper>
                                    : null : null}

                            {/* *********************************************************************************************************  
                                ZOZNAM PRODUKTOV
                        ********************************************************************************************************* */}
                            {items != false ?
                                items.map((item, index) => (
                                    <Paper elevation={2} key={item.id} style={{ ...styles.Block, width: '96%', height: rowHeight, backgroundColor: global.theme_lighter, marginTop: 5, marginBottom: 5, borderRadius: props.isSmall ? 20 : rowHeight / 2 }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            {props.isSmall ?
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: 90, justifyContent: 'center' }}>
                                                    <img src={item.image == null || item.image == '' ? no_image : item.image.substring(0, 4) == 'http' ? item.image : global.web_images + '/' + item.image} style={{ width: '100%', height: '100%', width: 80, height: 80, objectFit: 'contain', borderRadius: 10, marginLeft: 5 }}></img>
                                                </div>
                                                :
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                    <img src={item.image == null || item.image == '' ? no_image : item.image.substring(0, 4) == 'http' ? item.image : global.web_images + '/' + item.image} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                                </div>
                                            }
                                            {props.isSmall ?
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextTiny, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                    <p style={{ ...styles.TextTiny, marginTop: 10, textAlign: 'left' }}>{item.code} <span style={{ ...styles.TextTiny }}>{item.details}</span></p>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.price__}: {GetPrice(item.cost_dph)} {lang.money}/1{lang.pieces} ({lang.without_dph})</p>
                                                </div>
                                                :
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '75%', justifyContent: 'center', marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                    <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                        <p style={{ ...styles.TextTiny, marginTop: 0 }}>{item.code} <span style={{ ...styles.TextTiny }}>{item.details}</span></p>
                                                        <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                            <div style={{ ...styles.BlockLeft, width: '40%' }}>
                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: '40%' }}>

                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: '20%' }}>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {parseInt(item.quantity) != parseInt(item.quantity_original) ?
                                                <div style={{ ...styles.BlockRight, height: rowHeight, width: props.isSmall ? 100 : 250, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, marginRight: 25 }}>{lang.booking_product_ordered}: {item.quantity_original} {lang.pieces}</p>
                                                    <p style={{ ...styles.TextTiny, marginRight: 25, marginTop: 5 }}>{lang.booking_product_received}: {item.quantity} {lang.pieces}</p>
                                                </div>
                                                :
                                                <div style={{ ...styles.BlockRight, height: rowHeight, width: props.isSmall ? 100 : 250, justifyContent: 'center' }}>
                                                    <p style={{ ...props.isSmall ? styles.TextTinyMobile : styles.TextSmall, marginRight: 25 }}>{item.quantity} {lang.pieces}</p>
                                                </div>

                                            }
                                        </div>
                                    </Paper>
                                )) :
                                // ZIADNE PRODUKTY                            
                                <div style={{ ...styles.Block, paddingTop: 20 }}>
                                    <EmptyList lang={lang} />
                                </div>
                            }

                        </div>
                    </div>
                    {props.isSmall ?
                        <div style={{ ...styles.Block, width: '100%', height: buttonHeight, backgroundColor: global.themes[props.theme].theme_lighter, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                            <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                                <p style={{ ...styles.TextTiny }}>{lang.booking_status}: {order != false ? lang.booking_status_salon[order.status] : null}</p>
                            </div>
                            {order != false ? order.status == 2 || order.status == 5 ?
                                order.reklamacia == false ?
                                    <Button onClick={() => setShowReklamacia(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 200 }}>
                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faWarning} />
                                        <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.booking_reklamacia}</p>
                                    </Button>
                                    : null : null : null}

                            {order != false ? order.status == 0 ?
                                <Button onClick={() => setShowDelete(true)} style={{ ...styles.ButtonRed, width: 200, marginTop: 20 }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faXmark} />
                                    <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.booking_cancel}</p>
                                </Button>
                                : null : null}

                            {order != false ? order.status == 2 ?
                                <Button onClick={() => setShowVerify(true)} style={{ ...styles.ButtonBlue, width: 200, marginTop: 20 }}>
                                    <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.bonus_order_close}</p>
                                </Button>
                                : null : null}

                        </div>
                        :
                        <div style={{ ...styles.Block, width: '100%', height: buttonHeight, backgroundColor: global.themes[props.theme].theme_lighter, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft, width: '25%', height: buttonHeight, justifyContent: 'center' }}>
                                    {order != false ? order.status == 0 ?
                                        <Button onClick={() => setShowNote(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 200, marginLeft: 20 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPen} />
                                            <p style={{ ...styles.TextTiny, color: global.theme_white }}>{note.trim() == '' ? lang.booking_note : lang.booking_note_edit}</p>
                                        </Button>
                                        : null : null}
                                    {order != false ? order.status == 2 || order.status == 5 ?
                                        order.reklamacia == false ?
                                            <Button onClick={() => setShowReklamacia(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 200, marginLeft: 20 }}>
                                                <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faWarning} />
                                                <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.booking_reklamacia}</p>
                                            </Button>
                                            : null : null : null}
                                </div>
                                <div style={{ ...styles.Block, width: '50%', height: buttonHeight, justifyContent: 'center' }}>
                                    <p style={{ ...styles.TextTiny }}>{lang.booking_status}: {order != false ? lang.booking_status_salon[order.status] : null}</p>
                                    {order != false ? order.status != 5 && order.status != 6 ?
                                        <Button onClick={() => setShowAsk(true)} style={{ ...styles.ButtonDarkSmall, backgroundColor: global.themes[props.theme].button_dark, width: 200, marginTop: 10 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faQuestionCircle} />
                                            <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.booking_ask_status}</p>
                                        </Button>
                                        : null : null}
                                </div>
                                <div style={{ ...styles.BlockRight, width: '25%', height: buttonHeight, justifyContent: 'center' }}>
                                    {order != false ? order.status == 0 ?
                                        <Button onClick={() => setShowDelete(true)} style={{ ...styles.ButtonRed, width: 200, marginRight: 20 }}>
                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faXmark} />
                                            <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.booking_cancel}</p>
                                        </Button>
                                        : null : null}
                                    {order != false ? order.status == 2 ?
                                        <Button onClick={() => setShowVerify(true)} style={{ ...styles.ButtonBlue, width: 200, marginRight: 20 }}>
                                            <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.bonus_order_close}</p>
                                        </Button>
                                        : null : null}
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </Paper>

            {showNote == true ?
                <DialogEnterText theme={props.theme} rows={9} radius={props.radius} title={lang.booking_note} sub_title={''} text={note} icon={faPen} button={props.lang.save} func={DialogEnterTextResult.bind(this)} />
                : null}

            {showAsk == true ?
                <DialogEnterText theme={props.theme} rows={9} radius={props.radius} title={lang.booking_ask_status_label} sub_title={lang.booking_ask_status_text}
                    text={lang.booking_ask_status_message + order.number + ' (' + GetDate(order.date) + ')' + '. ' + props.user.name + ' ' + props.user.surname + ', ' + props.user.town}
                    icon={faQuestionCircle} button={props.lang.send} func={AskResult.bind(this)}
                />
                : null}

            {showAskOK == true ?
                <DialogInfo theme={props.theme} radius={props.radius} title={lang.info_} sub_title={''} text={lang.booking_ask_status_send_ok} icon={faQuestion} button={props.lang.close} icon_show={faCheckCircle} icon_color={global.theme_darker_green} func={() => setShowAskOK(false)} />
                : null}

            {showAskError == true ?
                <DialogInfo theme={props.theme} radius={props.radius} title={lang.info_} sub_title={''} text={lang.send_err} icon={faQuestion} button={props.lang.close} icon_show={faXmarkCircle} icon_color={global.theme_dark_red} func={() => setShowAskError(false)} />
                : null}

            {showDelete == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.booking_cancel + '?'} sub_title={''} text={lang.booking_cancel_text} sub_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={DeleteResult.bind(this)} />
                : null}

            {showReklamacia == true ?
                <ProductBookingReklamacia isSmall={props.isSmall} offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} item={props.item} lang={lang} radius={props.radius} backcolor={true} background={true} language={props.language} func={ReklamaciaResult.bind(this)} />
                : null}

            {showVerify == true ?
                <BonusBookingVerify isSmall={props.isSmall} offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} order={order} lang={lang} radius={props.radius} backcolor={true} background={true} language={props.language} func={VerifyResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop>
    );
}


export const ProductBookingReklamacia = (props) => {


    const [isBusy, setBusy] = useState(false);
    const [order, setOrder] = useState(false);
    const [items, setItems] = useState(false);

    const [index, setIndex] = useState(0);
    const [text, setText] = useState('');
    const [photo, setPhoto] = useState([]);
    const [showAddPhoto, setShowAddPhoto] = useState(false);
    const [reason, setReason] = useState(-1);

    const [dodaciList, setDodaciList] = useState('');
    const [faktura, setFaktura] = useState('');
    const [fakturaDate, setFakturaDate] = useState(Today());

    // cislo objednavky
    const [number, setNumber] = useState('');

    // odoslať
    const [redraw, setRedraw] = useState(false);
    const [showFinish, setShowFinish] = useState(false);
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // Rozmery okna
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1000 ? 1000 : width;
    const dialogHeight = height >= 768 ? 768 : height;

    const labelHeight = 48;
    const buttonHeight = 80;
    const rowHeight = props.isSmall ? 100 : 60;
    const photoSize = 160;


    const no_image = require('./react/app/no_image.jpg');


    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            db_get();

        }
        return () => {
        };

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'bonus_order', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language,
                    order_id: props.item.id
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                var tmp = json.items;

                tmp.forEach(item => {
                    item.selected = false;
                });
                setItems(tmp);

                setNumber(json.order.number);
                setOrder(json.order);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_send = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'products_booking_reklamacia_send', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                    lang: {
                        label: lang.reklamacia_bonus,
                        do_not_reply: lang.do_not_reply,
                        code: lang.code_,
                        product_name: lang.product_name,
                        quantity: lang.quantity
                    }
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (value) => {
        func(value);
    }

    const Next = () => {
        setIndex(1);
    }

    const Prev = () => {
        setIndex(0);
    }

    const SelectItem = (item) => {
        var tmp = items.find(x => x.id == item.id);
        if (tmp != undefined) {
            tmp.selected = !tmp.selected;
            item.quantity_max = item.quantity;
            setRedraw(!redraw);
        }
    }

    const AddPhoto = () => {
        setShowAddPhoto(true);
    }

    const PhotoResult = (typ, path, file) => {
        setShowAddPhoto(false);

        if (typ == true) {
            let data = {
                id: photo.length,
                path: path,
                file: file
            }
            let tmp = photo;
            tmp.push(data);
            setPhoto(tmp);
        }
    }

    const DeletePhoto = (item) => {
        var tmp = photo.filter(x => x.id != item.id);
        setPhoto(tmp);
    }

    const FinishResult = (value) => {
        setShowFinish(false);
        var photos = photo;

        var reason_text = '';
        if (reason >= 0) {
            var reason_ = lang.reklamacia_dovod.find(x => x.id == reason);
            if (reason_ != undefined) {
                reason_text = reason_.text;
            }
        }

        if (value == true) {
            let products = items.filter(x => x.selected == true);
            if (products.length == 0) {
                products = false;
            }
            if (photo.length == 0) {
                photos = false;
            }
            let data = {
                booking_id: props.item.id,
                salon_id: props.user.id,
                language: props.language,
                products: products,
                text: text,
                photos: photos,
                reason: reason_text,
                reason_id: reason,
                dodaci_list: dodaciList,
                faktura_cislo: faktura,
                faktura_date: fakturaDate,
                typ: 1,

                // texty - preklady
                label: lang.booking_reklamacia_label,
                do_not_replay_text: lang.do_not_reply_text,
                do_not_replay: lang.do_not_reply,
                label_mobil: lang.mobil,
                label_email: lang.email_,
                label_products: lang.booking_reklamacia_products,
                label_photos: lang.photos,
                label_photo: lang.photo,
                label_show_on_web: lang.show_on_web,
                label_reason: lang.reklamacia_reason
            }

            db_send(data);
        }
    }

    const PlusMinusResult = (value, item) => {
        item.quantity = value;
    }

    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faLayerGroup} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: props.isSmall ? '70%' : '60%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.lang.booking_reklamacia}</p>
                            <p style={{ ...styles.TextTiny, marginTop: 4, textAlign: 'left' }}>{lang.booking_number}: <b>{number}</b></p>
                        </div>
                    </div>

                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: 220, height: global.dialog_bar_height }}>
                        </div>
                    }

                    <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? global.theme_back : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '100%', height: dialogHeight - global.dialog_bar_height - buttonHeight }}>
                        {/* BODY */}
                        <div style={{ ...styles.Block, height: labelHeight, justifyContent: 'center', backgroundColor: global.theme_back }}>
                            {index == 0 ?
                                <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{lang.booking_reklamacia_select}</p>
                                : null}
                            {index == 1 ?
                                <div style={{ ...styles.Block }}>
                                    <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{lang.booking_reklamacia_text_photos}</p>
                                    <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.booking_reklamacia_note}</p>
                                </div>
                                : null}
                        </div>
                        {index == 0 ?
                            <div style={{ ...styles.BlockCenter, width: '100%', height: dialogHeight - global.dialog_bar_height - buttonHeight - labelHeight, paddingTop: 10, paddingBottom: 10, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container' >
                                {/* *********************************************************************************************************  
                                ZOZNAM PRODUKTOV
                            ********************************************************************************************************* */}
                                {items != false ?
                                    items.map((item, index) => (
                                        <Paper elevation={2} key={item.id} style={{ ...styles.Block, width: '96%', minHeight: rowHeight, backgroundColor: item.selected == true ? global.theme_lighter : global.theme_light_gray, marginTop: 5, marginBottom: 5, borderRadius: rowHeight / 2 }}>
                                            <div style={{ ...styles.BlockRow }}>
                                                <div style={{ ...styles.BlockLeft, minHeight: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                    <img src={item.image == null || item.image == '' ? no_image : item.image.substring(0, 4) == 'http' ? item.image : global.web_images + '/' + item.image} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                                </div>
                                                <div style={{ ...styles.BlockLeft, minHeight: rowHeight, width: '50%', justifyContent: 'center', marginLeft: 10 }}>
                                                    {props.isSmall ?
                                                        <TextLine text={item.label} fontSize={global.font_tiny} fontWeight={'600'} color={global.theme_black} />
                                                        :
                                                        <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                    }
                                                    <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                        <p style={{ ...styles.TextTiny, marginTop: 0 }}>{item.code} <span style={{ ...styles.TextTiny }}>{item.details}</span></p>
                                                    </div>
                                                    {item.selected == true && props.isSmall == true ?
                                                        <div style={{ ...styles.BlockLeft, marginTop: 3 }}>
                                                            <PlusMinus min={1} max={item.quantity_max} data={item} value={item.quantity} func={PlusMinusResult.bind(this)} />
                                                        </div>
                                                        : null}
                                                </div>
                                                <div style={{ ...styles.Block, minHeight: rowHeight, width: '20%', justifyContent: 'center' }}>
                                                    {props.isSmall ? null : item.selected == false ?
                                                        <p style={{ ...styles.TextTiny }}>{item.quantity} {lang.pieces}</p>
                                                        :
                                                        <PlusMinus min={1} max={item.quantity_max} data={item} value={item.quantity} func={PlusMinusResult.bind(this)} />
                                                    }
                                                </div>
                                                <div style={{ ...styles.BlockRight, minHeight: rowHeight, width: '15%', justifyContent: 'center' }}>
                                                    <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{''}</p>}
                                                        control={<Checkbox checked={item.selected} size={'medium'} onChange={() => SelectItem(item)} />}
                                                    />
                                                </div>
                                            </div>
                                        </Paper>
                                    )) :
                                    // ZIADNE PRODUKTY                            
                                    <div style={{ ...styles.Block, paddingTop: 20 }}>
                                        <EmptyList lang={lang} />
                                    </div>
                                }
                            </div>
                            : null}
                        {index == 1 ?
                            <div style={{ ...styles.BlockCenter, width: '100%', height: dialogHeight - global.dialog_bar_height - buttonHeight - labelHeight, paddingTop: 20, paddingBottom: 150, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container' >
                                <Select
                                    value={reason}
                                    onChange={event => setReason(event.target.value)}
                                    size='small'
                                    style={{ backgroundColor: global.theme_white, width: 300, borderRadius: 20 }}
                                >
                                    <MenuItem disabled value={-1}>{lang.reklamacia_select}</MenuItem>
                                    {lang.reklamacia_dovod.map((item) => (
                                        <MenuItem key={item.id} value={item.id}>{item.text}</MenuItem>
                                    ))}
                                </Select>
                                <div style={{ ...styles.BlockLeft, width: '98%', marginTop: 20 }}>
                                    <p style={{ ...styles.TextTiny }}>{lang.booking_reklamacia_text}</p>
                                    <TextField
                                        //inputRef={inputRef}
                                        value={text}
                                        onInput={e => setText(e.target.value)}
                                        size="small"
                                        autoCapitalize="off"
                                        autoComplete="off"
                                        autoCorrect="off"
                                        multiline={true}
                                        rows={4}
                                        style={{ width: '100%' }}
                                        onKeyDown={(event) => {
                                            if (event.key === 'Enter') {
                                            }
                                        }}
                                        InputProps={{
                                            style: { backgroundColor: global.theme_white },
                                        }}
                                        variant="outlined"
                                    />
                                </div>
                                <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, width: '98%', marginTop: 20 }}>
                                    <div style={{ ...styles.Block, width: props.isSmall ? '90%' : '37%' }}>
                                        <TextInput required redraw enabled={true} lang={lang} value={dodaciList} label={lang.dodaci_list_cislo} func={(txt) => setDodaciList(txt)} />
                                    </div>
                                    <div style={{ ...styles.Block, width: props.isSmall ? '90%' : '37%', marginLeft: props.isSmall ? 0 : 20, marginTop: props.isSmall ? 10 : 0 }}>
                                        <TextInput required redraw enabled={true} lang={lang} value={faktura} label={lang.faktura_cislo} func={(txt) => setFaktura(txt)} />
                                    </div>
                                    <div style={{ ...styles.Block, width: 210, marginLeft: props.isSmall ? 0 : 20, marginTop: props.isSmall ? 10 : 0 }}>
                                        <TextInput date redraw enabled={true} lang={lang} value={fakturaDate} label={lang.faktura_date} func={(txt) => setFakturaDate(txt)} />
                                    </div>
                                </div>
                                <div style={{ ...styles.Block, marginTop: 10 }}>
                                    <p style={{ ...styles.TextTiny, }}>{lang.booking_reklamacia_note2}</p>
                                    <div style={{ ...styles.Block, justifyContent: 'center', alignItems: photo.length == 0 ? 'center' : 'flex-start', height: photoSize + 40, width: dialogWidth, overflowY: 'scroll' }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            {photo.map((item, index) => (
                                                <div key={item.id} style={{ ...styles.Block, width: photoSize, height: photoSize + 30, marginRight: 10 }}>
                                                    <img src={item.path + item.file} style={{ width: '100%', height: '100%', maxWidth: photoSize - 10, maxHeight: photoSize - 10, objectFit: 'contain' }}></img>
                                                    <div style={{ ...styles.Block, height: 30 }}>
                                                        <Button onClick={() => DeletePhoto(item)} style={{ ...styles.ButtonLink, width: 200, marginTop: 10 }}>
                                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginRight: 10 }} icon={faXmark} />
                                                            <p style={{ ...styles.TextTiny, color: global.theme_black }}>{lang.delete}</p>
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))}
                                            {photo.length < 5 ?
                                                <div style={{ ...styles.Block, width: photoSize, height: photoSize + 20 }}>
                                                    <Button onClick={() => AddPhoto()} style={{ backgroundColor: global.theme_blue, flexDirection: 'column', width: photoSize, height: 80 }}>
                                                        <FontAwesomeIcon style={{ height: 20, color: global.theme_white, marginRight: 10 }} icon={faImage} />
                                                        <p style={{ ...styles.TextTiny, color: global.theme_white, marginTop: 5, textTransform: 'none' }}>{lang.booking_reklamacia_add_photo}</p>
                                                    </Button>
                                                    <div style={{ ...styles.Block, height: 30 }}></div>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                </div>

                                {props.isSmall ?
                                    <div style={{ ...styles.Block, minHeight: 100 }}></div>
                                    : null}

                            </div>
                            : null}

                    </div>
                    <div style={{ ...styles.Block, width: '100%', height: buttonHeight, backgroundColor: global.themes[props.theme].theme_lighter, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                        {index == 0 ?
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft }}></div>
                                <div style={{ ...styles.BlockRight }}>
                                    <Button onClick={() => Next()} style={{ ...styles.ButtonBlue, backgroundColor: global.themes[props.theme].theme_dark, width: 200, marginRight: 20 }}>
                                        <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.continue}</p>
                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginLeft: 10 }} icon={faAngleRight} />
                                    </Button>
                                </div>
                            </div>
                            :
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.BlockLeft }}>
                                    <Button onClick={() => Prev()} style={{ ...styles.ButtonBlue, backgroundColor: global.themes[props.theme].theme_dark, width: props.isSmall ? 160 : 200, marginLeft: 20 }}>
                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faAngleLeft} />
                                        <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.back}</p>
                                    </Button>
                                </div>
                                <div style={{ ...styles.BlockRight }}>
                                    {reason > -1 && dodaciList.trim() != '' && faktura.trim() != '' ?
                                        <Button onClick={() => setShowFinish(true)} style={{ ...styles.ButtonRed, width: props.isSmall ? 160 : 200, marginRight: 20 }}>
                                            <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.send}</p>
                                        </Button>
                                        : null}
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </Paper>

            {
                showAddPhoto == true ?
                    <Photos lang={props.lang} typ={6} max_size={1600} full title={lang.photo_choose} sub_title={lang.booking_reklamacia} radius={props.radius} offset={props.offset} theme={props.theme} func={PhotoResult.bind(this)} />
                    : null
            }

            {
                showOK == true ?
                    <DialogInfo theme={props.theme} radius={props.radius} title={lang.info_} sub_title={''} text={lang.booking_ask_status_send_ok} icon={faQuestion} button={props.lang.close} icon_show={faCheckCircle} icon_color={global.theme_darker_green} func={() => Press(true)} />
                    : null
            }

            {
                showError == true ?
                    <DialogInfo theme={props.theme} radius={props.radius} title={lang.info_} sub_title={''} text={lang.send_err} icon={faQuestion} button={props.lang.close} icon_show={faXmarkCircle} icon_color={global.theme_dark_red} func={() => setShowError(false)} />
                    : null
            }

            {
                showFinish == true ?
                    <DialogYesNo theme={props.theme} radius={props.radius} title={lang.booking_reklamacia_send_label + '?'} sub_title={''} text={lang.booking_reklamacia_send_text} sub_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={FinishResult.bind(this)} />
                    : null
            }

            {
                isBusy == true ?
                    <Loading offset={props.offset} theme={props.theme} ></Loading>
                    : null
            }

        </Backdrop >
    );
}



export const BonusBookingVerify = (props) => {


    const [isBusy, setBusy] = useState(false);
    const [isUpdating, setUpdating] = useState(false);
    const [order, setOrder] = useState(false);
    const [orderStatus, setOrderStatus] = useState(0);
    const [items, setItems] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // zmena stavu
    const [changed, setChanged] = useState(false);
    const [showFinish, setShowFinish] = useState(false);

    // Rozmery okna
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1000 ? 1000 : width;
    const dialogHeight = height >= 768 ? 768 : height;

    const infoHeight = 34;
    const buttonHeight = 100;
    const rowHeight = props.isSmall ? 120 : 80;


    const no_image = require('./react/app/no_image.jpg');


    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            db_get();

        }
        return () => {
        };

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'bonus_order', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language,
                    order_id: props.order.id
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                setItems(json.items);
                setOrder(json.order);
                setOrderStatus(json.order.status);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'bonus_verify_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setChanged(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_finish = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'bonus_order_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                Press(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (value) => {
        var redraw = value;
        if (changed == true) {
            redraw = true;
        }
        func(redraw);
    }

    const FinishResult = (typ) => {
        setShowFinish(false);
        if (typ == true) {
            let data = {
                id: props.order.id,
                status: 5
            }
            order.status = 5;
            db_finish(data, true);
        }
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const PlusMinusResult = (value, item) => {
        let data = {
            id: item.id,
            quantity: value,
        }

        db_update(data);
    }


    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                            <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                                <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faListCheck} />
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.Block, width: '80%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel, textAlign: 'left', marginLeft: props.isSmall ? 10 : 0 }}>{lang.order_verify_label}</p>
                            {props.isSmall ? null :
                                <p style={{ ...styles.TextTiny, marginTop: 4 }}>{lang.booking_number}: <b>{order.number}</b></p>
                            }
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? global.theme_back : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '100%', height: dialogHeight - global.dialog_bar_height - buttonHeight }}>
                        {/* BODY */}
                        <div style={{ ...styles.BlockCenter, width: '100%', height: dialogHeight - global.dialog_bar_height - buttonHeight - infoHeight, overflowY: 'scroll', paddingBottom: 10 }}>

                            {/* *********************************************************************************************************  
                                ZOZNAM PRODUKTOV
                        ********************************************************************************************************* */}
                            {items != false ?
                                items.map((item, index) => (
                                    <Paper elevation={2} key={item.id} style={{ ...styles.Block, width: '96%', height: rowHeight, backgroundColor: global.theme_lighter, marginTop: 5, marginBottom: 5, borderRadius: props.isSmall ? 20 : rowHeight / 2 }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            {props.isSmall ?
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: 90, justifyContent: 'center' }}>
                                                    <img src={item.image == null || item.image == '' ? no_image : item.image.substring(0, 4) == 'http' ? item.image : global.web_images + '/' + item.image} style={{ width: '100%', height: '100%', width: 80, height: 80, objectFit: 'contain', borderRadius: 10, marginLeft: 5 }}></img>
                                                </div>
                                                :
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                    <img src={item.image == null || item.image == '' ? no_image : item.image.substring(0, 4) == 'http' ? item.image : global.web_images + '/' + item.image} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                                </div>
                                            }
                                            {props.isSmall ?
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '40%', justifyContent: 'center', marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextTiny, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                    <p style={{ ...styles.TextTiny, marginTop: 5 }}>{item.code} <span style={{ ...styles.TextTiny }}>{item.details}</span></p>
                                                </div>
                                                :
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '80%', justifyContent: 'center', marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                    <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                        <p style={{ ...styles.TextTiny, marginTop: 0 }}>{item.code} <span style={{ ...styles.TextTiny }}>{item.details}</span></p>
                                                        <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                            <div style={{ ...styles.BlockLeft, width: '40%' }}>
                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: '40%' }}>

                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: '20%' }}>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <div style={{ ...styles.Block, height: rowHeight, width: props.isSmall ? 150 : 300, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, marginBottom: 5 }}>{lang.order_verify_quantity}</p>
                                                <PlusMinus data={item} value={item.quantity} func={PlusMinusResult.bind(this)} />
                                            </div>
                                        </div>
                                    </Paper>
                                )) :
                                // ZIADNE PRODUKTY                            
                                <div style={{ ...styles.Block, paddingTop: 20 }}>
                                    <EmptyList lang={lang} />
                                </div>
                            }

                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '100%', height: buttonHeight, backgroundColor: global.themes[props.theme].theme_lighter, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                        <Button onClick={() => setShowFinish(true)} style={{ ...styles.ButtonBlue, backgroundColor: global.themes[props.theme].theme_dark, width: 200 }}>
                            <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.bonus_order_close}</p>
                        </Button>
                    </div>
                </div>
            </Paper>

            {showFinish == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.bonus_order_close + '?'} sub_title={''} text={lang.bonus_finist_text} sub_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={FinishResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <DialogInfo theme={props.theme} radius={props.radius} title={lang.info_} sub_title={''} text={lang.send_err} icon={faQuestion} button={props.lang.close} icon_show={faXmarkCircle} icon_color={global.theme_dark_red} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop>
    );
}

