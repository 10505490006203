import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, CopyData, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, GetPrice, Init, LoadProductsDisplay, Loading, Menu, MenuTitle, MenuTop, PlusMinus, SaveProductsDisplay, ShowError, ShowOK, TemplateWindow, TextSearch } from './items';
import { styles } from './styles';
import { Button, IconButton, InputAdornment, Pagination, Paper, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCheckCircle, faL, faLayerGroup, faList, faListAlt, faQuestion, faShoppingBag, faShoppingBasket, faTableCellsLarge, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';
import { ProductDialog, ProductPreview, ShoppingCard } from './product_dialog';
import { Select, MenuItem } from '@mui/material';
import { GetProductColor2 } from './functions.js';


export const ProductsShop = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);

    // KATEGORIE
    const [categories, setCategories] = useState(false);
    const [subCategories, setSubCategories] = useState(false);
    const [subCategoryID, setSubCategoryID] = useState(0);

    // PRODUKTY
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const itemsMax = 24;

    // NAHLAD
    const [itemSelected, setItemSelected] = useState(false);
    const [editing, setEditing] = useState(false);
    const [showProduct, setShowProduct] = useState(false);
    const [showShoppingCard, setShowShoppingCard] = useState(false);
    const [typ, setTyp] = useState(props.isSmall == true ? 0 : LoadProductsDisplay());

    const [showOK, setShowOK] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const menuWidth = props.isSmall ? 0 : 250;
    const rowHeight = 100;
    const rowWidth = 850;
    const selectHeight = props.isSmall ? 60 : 0;

    // COLORS
    const font_selected = global.theme_black;
    const font_disabled = global.theme_darker;

    const no_image = require('./react/app/no_image.jpg');

    let { func } = props;
    var lang = props.lang;
    const iconSize = 175;
    var running = false;
    let color = global.themes[props.theme];

    useEffect(() => {
        if (running == false) {
            running = true;
            db_get();
        }

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'categories', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    language: props.language,
                    default: lang.products_my,
                    action: lang.action,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;
                tmp = tmp.filter(x => x.enabled == true);
                setCategories(tmp);
                if (tmp != false) {
                    db_products(tmp[0].id);
                    setIndex(tmp[0].id);
                }
            }
            //setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_products = async (category_id) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'products', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    category_id: category_id,
                    language: props.language,
                    shopping_card: true,
                    enabled: true
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setSubCategories(json.categories);
                setSubCategoryID(0);
                var tmp = json.products;

                if (tmp != false) {
                    tmp.sort((a, b) => {
                        if (a.label > b.label) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_search = async (search) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_search', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    search: search,
                    language: props.language,
                    not_cabine: true,
                    enabled: true,
                    shopping_card: true,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setSubCategories(false);
                setSubCategoryID(0);
                var tmp = json.products;

                if (tmp != false) {
                    tmp.sort((a, b) => {
                        if (a.label > b.label) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                    setItems(tmp);
                    PageInit(tmp);
                    setIndex(-1);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                    setIndex(-1);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_product_buy = async (product_id, quantity) => {

        try {
            const response = await fetch(
                global.db_url + 'product_buy', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language,
                    salon_id: props.user.id,
                    product_id: product_id,
                    quantity: quantity
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = items.find(x => x.id == product_id);

                if (tmp != undefined) {
                    tmp.shopping_card = quantity;
                }

                var tmp = itemsFiltered.find(x => x.id == product_id);
                if (tmp != undefined) {
                    tmp.shopping_card = quantity;
                }


                setShowOK(true);
            }

        } catch (error) {
            console.log(error);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const ChangeIndex = (id) => {
        setIndex(id);
        setSubCategoryID(0);
        setSearch('');
        db_products(id);
    }

    const CategorySelectResult = (id) => {
        var value = id;
        if (id != subCategoryID) {
            setSubCategoryID(id);
        } else {
            setSubCategoryID(0);
            value = 0;
        }
        Redraw(items, 0, search, value);
    }

    const Search = (text) => {
        setSearch(text);
        /*
        setSubCategoryID(0);
        Redraw(items, 0, text, 0);
        */
        if (text.trim().length > 2) {
            db_search(text.trim());
        }
        if (text == '') {
            setItems(false);
            setItemsFiltered(false);
            setIndex(-1);
        }
    }

    const Redraw = (items, p, search, sub_category) => {
        // REDRAW PRODUKTOV
        var data = items;
        if (search.trim() != '') {
            // filtrovanie podla hladaneho retazca
            //var words = search.split(' ');
            //data = items.filter(x => FindWords(x.label, x.keywords, words));
        } else {

            if (sub_category > 0) {
                // filtrovanie vybratej kategórie
                data = items.filter(x => parseInt(x.category_id) == sub_category);
            }

        }

        var count = data.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax)
        setItemsFiltered(data);

        if (count == 0) {
            setItemsFiltered(false);
        }

        setPage(p);
        GoToStart();
    }

    function FindWords(label, keywords, words) {
        // hladanie retazca v nazve a keywords
        var result = 0;
        words.forEach(word => {
            if (label.toLowerCase().includes(word.toLowerCase()) || keywords.toLowerCase().includes(word.toLowerCase())) {
                result += 1;
            }

        });
        // ak je pocet najdených slov == poctu hladaných slov -> je zhoda
        return words.length == result;
    }

    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);

        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0, '', 0);
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_, search, subCategoryID);
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_products');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const ProductBuy = (item) => {
        db_product_buy(item.id, 1);
    }

    const Card = () => {
        setShowShoppingCard(true);
    }

    const ShoppingCardResult = (redraw) => {
        setShowShoppingCard(false);

        if (redraw == true) {
            setSearch('');
            db_products(index);
        }
    }


    // ------------------------------------------------------------------------------------
    // NAHLAD
    // ------------------------------------------------------------------------------------
    const Edit = (item) => {
        setItemSelected(item);
        setEditing(true);
        setShowProduct(true);
    }

    const ProductDialogResult = (value, data) => {
        itemSelected.shopping_card = data.shopping_card;
        setShowProduct(false);

    }

    const ChangeTyp = (value) => {
        setTyp(value);
        SaveProductsDisplay(value);
    }

    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                        <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                    </div>
                </div>
                <div style={{ ...styles.Block, width: '40%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>
                        {props.isSmall ? null :
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        }
                    </div>
                </div>
                <div style={{ ...styles.BlockRow, justifyContent: 'flex-end', alignItems: 'center', width: props.isSmall ? 160 : '50%', height: global.dialog_bar_height }}>
                    {props.isSmall == false ?
                        <div style={{ ...styles.BlockRowRaw, justifyContent: 'center', alignItems: 'center', width: 140, height: global.dialog_bar_height }}>
                            <IconButton onClick={() => ChangeTyp(0)} style={{ width: 36, height: 36, backgroundColor: typ == 0 ? global.theme_white : undefined }}>
                                <FontAwesomeIcon style={{ width: 16 }} icon={faBars} />
                            </IconButton>
                            <IconButton onClick={() => ChangeTyp(1)} style={{ width: 36, height: 36, backgroundColor: typ == 1 ? global.theme_white : undefined, marginLeft: 10 }}>
                                <FontAwesomeIcon style={{ width: 16 }} icon={faTableCellsLarge} />
                            </IconButton>
                        </div>
                        : null}
                    <TextSearch lang={props.lang} isSmall={props.isSmall} func={Search.bind(this)} />
                    {/*
                    <TextField
                        value={search}
                        onInput={e => Search(e.target.value)}
                        size="small"
                        autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off"
                        style={{ width: props.isSmall ? 160 : 300, marginRight: 30 }}
                        placeholder={lang.search_in_category}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                            }
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                    <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                </IconButton>
                            </InputAdornment>,
                            style: { backgroundColor: global.theme_white, borderRadius: 30 },
                        }}
                        variant="outlined"
                    />
                    */}
                    {props.isSmall ? null :
                        <Button onClick={() => Card()} style={{ ...styles.ButtonBlue, width: 180, marginLeft: 20 }}>
                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faShoppingBag} />
                            <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.basket}</p>
                        </Button>
                    }

                </div>
                <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                    {props.isSmall ?
                        <div style={{ ...styles.BlockRow, height: selectHeight, backgroundColor: global.theme_back }}>
                            <div style={{ ...styles.Block, height: selectHeight }}>
                                <Select
                                    value={index}
                                    onChange={event => ChangeIndex(event.target.value)}
                                    size='small'
                                    style={{ backgroundColor: global.theme_white, width: '100%' }}
                                >

                                    {categories != false ?
                                        categories.map((item) => (
                                            item.typ != 100 ?
                                                <MenuItem key={item.id} value={item.id}>{item.label}</MenuItem>
                                                : null
                                        ))
                                        : null}
                                </Select>
                            </div>
                            <div style={{ ...styles.Block, height: selectHeight }}>
                                <Button onClick={() => Card()} style={{ ...styles.ButtonBlue, width: 170 }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faShoppingBag} />
                                    <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.basket}</p>
                                </Button>
                            </div>
                        </div>
                        :
                        <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                            <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                {categories != false ?
                                    categories.map((item) => (
                                        <Button key={item.id} onClick={() => ChangeIndex(item.id)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == item.id ? global.theme_back : '#00000000', color: index == item.id ? font_selected : font_disabled }}>
                                            <div style={{ ...styles.BlockLeft }}>
                                                <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{item.label}</p>
                                                <p style={{ ...styles.TextTiny, color: global.themes[props.theme].theme_darker, marginTop: 2, lineHeight: 1, textAlign: 'left' }}>{item.brand}</p>
                                            </div>
                                        </Button>
                                    ))
                                    : null}

                            </div>
                        </div>
                    }
                    <div style={{ ...styles.BlockCenter, width: props.isSmall ? width : props.width - menuWidth - selectHeight, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                        {/* BODY */}
                        <div id={'id_products'}>
                            {subCategories != false ?
                                subCategories.length > 1 ?
                                    <CategorySelect background={global.theme_back} width={props.width - menuWidth} items={subCategories} value={subCategoryID} func={CategorySelectResult.bind(this)} />
                                    : null
                                : null}
                        </div>


                        {/* *********************************************************************************************************  
                                ZOZNAM PRODUKTOV
                        ********************************************************************************************************* */}
                        {typ == 0 ?
                            <div style={{ ...styles.BlockCenter, width: '98%' }}>
                                {itemsFiltered != false ?
                                    itemsFiltered.map((item, index) => (
                                        <Paper elevation={overID == item.id ? 5 : 2} key={item.id} style={{ ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: item.enabled == true ? global.theme_lighter : global.theme_gray, marginTop: 5, marginBottom: 5, borderRadius: rowHeight / 2, cursor: 'pointer' }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow }}>
                                                <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                    <img src={item.image == null || item.image == '' ? no_image : item.image.substring(0, 4) == 'http' ? item.image : global.web_images + '/' + item.image} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                                </div>
                                                {props.isSmall ?
                                                    <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: '65%', justifyContent: 'center', marginLeft: 10 }}>
                                                        <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                        <p style={{ ...styles.TextTinyMobile }}>{item.code}</p>
                                                        <p style={{ ...styles.TextTinyMobile, color: item.instock == 0 ? global.theme_red : global.theme_black }}>{item.instock == 0 ? lang.no_stock : lang.stock + ': ' + item.instock + ' ' + lang.pieces}</p>
                                                    </div>
                                                    :
                                                    <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                        <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label} <span style={{ fontSize: global.font_xtiny, fontWeight: '400' }}>{item.code} {item.details != '' ? '- ' + item.details : ''}</span></p>
                                                        <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                            <p style={{ ...styles.TextTiny, marginTop: 0 }}>{item.note_short}</p>
                                                            <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                                <div style={{ ...styles.BlockLeft, width: '40%' }}>
                                                                    <p style={{ ...styles.TextTiny }}>{lang.price__}: {GetPrice(item.eur)} {lang.money}</p>
                                                                </div>
                                                                <div style={{ ...styles.BlockLeft, width: '40%' }}>

                                                                </div>
                                                                <div style={{ ...styles.BlockLeft, width: '20%' }}>
                                                                    <p style={{ ...styles.TextTiny, color: item.instock == 0 ? global.theme_red : global.theme_black }}>{item.instock == 0 ? lang.no_stock : lang.stock + ': ' + item.instock + ' ' + lang.pieces}</p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div style={{ ...styles.Block, height: rowHeight, width: 120, justifyContent: 'center' }}>
                                                    {item.shopping_card > 0 ?
                                                        <div style={{ ...styles.Block }}>
                                                            <p style={{ ...styles.TextTiny, color: global.theme_dark }}>{lang.product_in_basket}</p>
                                                        </div>
                                                        :
                                                        <Paper onClick={() => ProductBuy(item)} elevation={5} style={{ ...styles.Block, backgroundColor: global.theme_white, width: 80, height: 80, flexDirection: 'column', borderRadius: 40, cursor: 'pointer' }}>
                                                            <FontAwesomeIcon style={{ height: 20, color: global.theme_dark }} icon={faShoppingBasket} />
                                                            <p style={{ ...styles.TextXTiny, color: global.theme_dark, marginTop: 5 }}>{lang.product_buy}</p>
                                                        </Paper>
                                                    }
                                                </div>
                                            </div>
                                        </Paper>
                                    )) :
                                    // ZIADNE PRODUKTY                            
                                    <div style={{ ...styles.Block, paddingTop: 20 }}>
                                        <EmptyList lang={lang} />
                                    </div>
                                }
                            </div>
                            : null}

                        {typ == 1 ?
                            <div style={{ ...styles.BlockCenter, flexDirection: 'row', flexWrap: 'wrap' }}>
                                {itemsFiltered != false ?
                                    itemsFiltered.map((item, index) => (
                                        <Paper
                                            elevation={overID == item.id ? 5 : 2}
                                            key={item.id}
                                            style={{
                                                ...styles.Block,
                                                width: iconSize + 8,
                                                height: (iconSize * 2) + 8 + 50,
                                                backgroundColor: GetProductColor2(item.background_color, props.theme),
                                                borderRadius: 10,
                                                cursor: 'pointer',
                                                margin: 5,
                                                marginBottom: 10
                                            }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockCenter, width: iconSize }}>
                                                <div onClick={() => Edit(item)} style={{ ...styles.Block, height: iconSize, width: iconSize, position: 'relative' }}>
                                                    <img src={item.image == null || item.image == '' ? no_image : item.image.substring(0, 4) == 'http' ? item.image : global.web_images + '/' + item.image} style={{ width: '100%', height: '100%', width: iconSize, height: iconSize, objectFit: 'contain', borderRadius: 10 }}></img>
                                                    {item.flag_id == 2 ?
                                                        <div style={{ ...styles.BlockCenter, width: 90, height: 20, backgroundColor: global.theme_dark_red, borderRadius: 10, position: 'absolute', top: 0, right: 0 }}>
                                                            <p style={{ ...styles.TextXTiny, color: global.theme_white }}>{lang.product_news}</p>
                                                        </div>
                                                        : null}
                                                </div>

                                                <div style={{ ...styles.BlockCenter, height: iconSize, width: iconSize, justifyContent: 'center' }}>
                                                    <div onClick={() => Edit(item)} style={{ ...styles.BlockCenter, height: 70, overflow: 'hidden' }}>
                                                        <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'center', marginTop: 8 }}>{item.label}</p>
                                                    </div>
                                                    <div onClick={() => Edit(item)} style={{ ...styles.BlockCenter, height: 76, overflow: 'hidden' }}>
                                                        <p style={{ ...styles.TextTiny, marginTop: 0 }}>{item.code}</p>
                                                        <div style={{ ...styles.BlockCenter, marginTop: 10 }}>
                                                            <p style={{ ...styles.TextTiny, textAlign: 'center' }}>{item.note_short}</p>
                                                        </div>
                                                    </div>

                                                    <div style={{ ...styles.Block, height: iconSize - 70 - 76 }}>
                                                        <p style={{ ...props.isSmall ? styles.TextTiny : styles.TextSmall, fontWeight: '500' }}>{GetPrice(item.eur)} {lang.money}</p>
                                                    </div>
                                                </div>

                                                {item.shopping_card > 0 ?
                                                    <div style={{ ...styles.Block, height: 36, marginTop: 14, width: iconSize, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextTiny, color: global.theme_black }}>{lang.product_in_basket}</p>
                                                    </div>
                                                    :
                                                    <div onClick={() => ProductBuy(item)} style={{ ...styles.Block, height: 36, marginTop: 14, width: iconSize, justifyContent: 'center', backgroundColor: color.theme_dark, borderRadius: 10 }}>
                                                        <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.product_buy}</p>
                                                    </div>
                                                }
                                            </div>
                                        </Paper>

                                    )) :
                                    // ZIADNE PRODUKTY                            
                                    <div style={{ ...styles.Block, paddingTop: 20 }}>
                                        <EmptyList lang={lang} />
                                    </div>
                                }
                            </div>
                            : null}

                        {itemsFiltered != false ?
                            pageMax > 1 ?
                                <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                    <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                </div>
                                : <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>1/1</div>
                            : null}

                    </div>
                </div>
            </div>

            {showProduct == true ?
                <ProductPreview isSmall={props.isSmall} sell={true} offset={props.offset} theme={props.theme} editing={editing} user={props.user} user_id={props.user.id} product={itemSelected} lang={lang} radius={props.radius} title={itemSelected.label} sub_title={itemSelected.note_short} backcolor={true} background={false} language={props.language} func={ProductDialogResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showShoppingCard == true ?
                <ShoppingCard isSmall={props.isSmall} offset={props.offset} theme={props.theme} user_id={props.user.id} user={props.user} lang={lang} radius={props.radius} language={props.language} employee_id={props.employee_id} func={ShoppingCardResult.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Paper>
    );
}


