import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { AddDays, CategorySelect, Colors, CopyData, Debug, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, GetDate, GetDate3, GetImageLink, GetPages, GetPrice, GetTopPosition, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, ShowError, ShowOK, TemplateWindow, TextInput, Today } from './items';
import { styles } from './styles';
import { Backdrop, Button, CircularProgress, IconButton, InputAdornment, Pagination, Paper, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCheckCircle, faEdit, faFile, faL, faLayerGroup, faList, faListAlt, faPen, faQuestion, faSave, faShoppingBag, faShoppingBasket, faTrash, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';
import { ProductDialog, ProductPreview, ShoppingCard } from './product_dialog';
import { ClientSelect } from './client_select';
import { centerCrop } from 'react-image-crop';


export const ProductsShopHistory = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);

    // PREDAJ - ZOZNAM
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [filter, setFilter] = useState(false);
    const itemsMax = global.items_max;

    // NAHLAD
    const [itemSelected, setItemSelected] = useState(false);
    const [showOrder, setShowOrder] = useState(false);

    const [showOK, setShowOK] = useState(false);

    const menuWidth = 250;
    const rowHeight = 100;
    const rowWidth = 850;

    // COLORS
    const font_selected = global.theme_black;
    const font_disabled = global.theme_darker;

    const no_image = require('./react/app/no_image.jpg');

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;
            db_orders(0, false);
        }

    }, []);

    const db_orders = async (page, filter) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_orders', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    language: props.language,
                    start: page * itemsMax,
                    length: itemsMax,
                    filter: filter
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, itemsMax));

                setFilter(filter);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }


    const Search = (txt) => {
        setSearch(txt);
        if (txt.length > 2) {
            let filter = { search: txt };
            db_orders(0, filter);
        }
        if (txt == '') {
            db_orders(0, false);
        }
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_orders');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }


    const ChangePage = (value) => {
        db_orders(value - 1, filter);
    }

    // ------------------------------------------------------------------------------------
    // NAHLAD OBJEDNAVKY
    // ------------------------------------------------------------------------------------
    const Edit = (item) => {
        setItemSelected(item);
        setShowOrder(true);
    }

    const ProductOrderDetailsResult = (value) => {
        setShowOrder(false);
        if (value == true) {
            db_orders(page, filter);
        }
    }


    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? '50%' : '40%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>
                        {props.isSmall ? null :
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        }
                    </div>
                </div>
                <div style={{ ...styles.BlockRow, justifyContent: 'flex-end', alignItems: 'center', width: props.isSmall ? 160 : '50%', height: global.dialog_bar_height }}>
                    <TextField
                        value={search}
                        onInput={e => Search(e.target.value)}
                        size="small"
                        autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off"
                        style={{ width: props.isSmall ? 150 : 300 }}
                        placeholder={lang.search_in_category}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                            }
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                    <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                </IconButton>
                            </InputAdornment>,
                            style: { backgroundColor: global.theme_white, borderRadius: 30 },
                        }}
                        variant="outlined"
                    />
                </div>
                <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockCenter, width: props.width, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>

                        {/* *********************************************************************************************************  
                                ZOZNAM OBJEDNAVOK
                        ********************************************************************************************************* */}
                        <div id={'id_orders'} style={{ ...styles.BlockCenter, width: '98%' }}>
                            {itemsFiltered != false ?
                                itemsFiltered.map((item, index) => (
                                    <Paper elevation={overID == item.id ? 5 : 2} key={item.id} style={{ ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: global.theme_lighter, marginTop: 5, marginBottom: 5, borderRadius: global.row_radius, cursor: 'pointer' }}
                                        onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                    >
                                        <div style={{ ...styles.BlockRow }}>
                                            <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                {item.photo != null && item.photo != '' ?
                                                    <img src={item.photo == null || item.photo == '' ? no_image : GetImageLink(item.photo)} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: global.row_radius - 5, marginLeft: 5 }}></img>
                                                    :
                                                    <div style={{ ...styles.Block, maxHeight: rowWidth }}>
                                                        <div style={{ ...styles.Block, height: rowHeight / 2, width: rowHeight / 2, backgroundColor: global.theme_white, borderRadius: '50%' }}>
                                                            <FontAwesomeIcon style={{ height: (rowHeight / 4), color: global.theme_dark }} icon={faShoppingBasket} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: '90%', justifyContent: 'center', marginLeft: 10 }}>
                                                <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{lang.products_shop} <span style={{ fontSize: global.font_xtiny, fontWeight: '400' }}>{lang.products_shop_number}: {item.invoice_number}</span></p>
                                                <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                    <p style={{ ...styles.TextTiny, marginTop: 0 }}>{item.name} {item.surname}</p>
                                                    <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                        <div style={{ ...styles.BlockLeft, width: '40%' }}>
                                                            <p style={{ ...styles.TextTiny }}>{GetDate(item.date)}</p>
                                                        </div>
                                                        <div style={{ ...styles.BlockLeft, width: '40%' }}>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {/* //! SUMA NAKUPU JE INA PO ZMENE NA kARTE ZAKAZNIKA - preto nezobrazovat
                                            <div style={{ ...styles.Block, width: 200, height: rowHeight }}>
                                                <div style={{ ...styles.Block }}>
                                                    <p style={{ ...styles.TextTiny, color: global.theme_black }}>{lang.products_shop_sum}</p>
                                                    <p style={{ ...styles.TextTiny, color: global.theme_black, fontWeight: '600' }}>{item.sum} {lang.money}</p>
                                                </div>
                                            </div>
                                            */}
                                        </div>
                                    </Paper>
                                )) :
                                // ZIADNE OBJEDNAVKY                            
                                <div style={{ ...styles.Block, paddingTop: 20 }}>
                                    <EmptyList lang={lang} />
                                </div>
                            }

                            {pages > 1 ?
                                <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                </div>
                                : null}

                            <div style={{ ...styles.Block, height: 50 }}></div>

                        </div>

                    </div>
                </div>
            </div>

            {
                showOrder == true ?
                    <ProductOrderDetails isSmall={props.isSmall} offset={props.offset} theme={props.theme} user_id={props.user.id} user={props.user} order={itemSelected} lang={lang} radius={props.radius} title={itemSelected.label} sub_title={itemSelected.note_short} backcolor={true} background={false} language={props.language} employee_id={props.employee_id} func={ProductOrderDetailsResult.bind(this)} />
                    : null
            }

            {
                showOK == true ?
                    <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null
            }

            {
                isBusy == true ?
                    <Loading offset={props.offset} theme={props.theme} ></Loading>
                    : null
            }

        </Paper >
    );
}


export const ProductOrderDetails = (props) => {
    /*            
            
            USAGE:
            <ProductOrderDetails func={ProductOrderDetailsResult.bind(this)} />

            const ProductOrderDetailsResult = (value) => {
                console.log(value)
            }
            
    */
    const no_image = require('./react/app/no_image.jpg');

    const [isBusy, setBusy] = useState(true);
    const [isUpdating, setUpdating] = useState(false);
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    const [products, setProducts] = useState(false);
    const [invoice, setInvoice] = useState(false);
    const [sum, setSum] = useState(0);
    const [overID, setOverID] = useState(-1);

    const [client, setClient] = useState(false);
    const [showClient, setShowClient] = useState(false);
    const [changes, setChanges] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    // existuje faktura?
    const [faktura, setFaktura] = useState(false);
    const [fakturaDays, setFakturaDays] = useState(global.faktura_days);
    const [askFaktura, setAskFaktura] = useState(false);
    const [showFakturaOK, setShowFakturaOK] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1000 ? 1000 : width;
    const dialogHeight = height >= 768 ? 768 : height;

    const rowHeight = 100;
    const rowWidth = 980;
    const buttonHeight = props.isSmall ? 230 : 120;

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {

        if (running == false) {
            running = true;
            db_order();
        }

        return () => {
        };

    }, []);


    const Press = (value) => {
        func(value);
    }

    const db_order = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'product_order', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language,
                    salon_id: props.user_id,
                    order_id: props.order.id,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;
                setProducts(tmp);

                setInvoice(json.invoice);
                GetSume(tmp);
                if (json.client != false) {
                    setClient(json.client);
                }

                setFaktura(json.faktura > 0 ? true : false);
            }
            setBusy(false);
        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_product_update = async () => {
        // PRIPISAT PRODUKTY ZAKAZNIKOVI / ODPISAT
        setUpdating(true);
        try {
            const response = await fetch(
                global.db_url + 'product_order_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language,
                    salon_id: props.user_id,
                    order_id: props.order.id,
                    client: client
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                Press(true);
            }
            setUpdating(false);

        } catch (error) {
            console.log(error);
            setUpdating(false);
        }
    }


    const db_order_delete = async () => {
        // PRIPISAT PRODUKTY ZAKAZNIKOVI / ODPISAT
        setUpdating(true);
        try {
            const response = await fetch(
                global.db_url + 'product_order_delete', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language,
                    salon_id: props.user_id,
                    order_id: props.order.id,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                Press(true);
            }
            setUpdating(false);

        } catch (error) {
            console.log(error);
            setUpdating(false);
        }
    }

    const db_faktura = async (data, data_items) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'faktura_create', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                    data_items: data_items
                })
            })

            const json = await response.json();

            setBusy(false);
            if (json.ok > 0) {
                setFaktura(true);
                setShowFakturaOK(true);
            } else {
                setShowError(true);
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const GetSume = (items) => {
        var sume = 0;
        items.forEach(item => {
            if (item.enabled == true) {
                sume = sume + (item.quantity * item.eur);
            }
        });
        setSum(sume.toFixed(2));
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const Client = () => {
        setShowClient(true);
    }

    const ClientSelectResult = (typ, item) => {
        setShowClient(false);
        if (typ == true) {
            setClient(item);
            setChanges(true);
        }
    }

    const ClientDelete = () => {
        setClient(false);
        setChanges(true);
    }

    const Save = () => {
        // ULOZIT ZMENY
        db_product_update();
    }

    const DeleteResult = (value) => {
        setShowDelete(false);
        if (value == true) {
            db_order_delete();
        }
    }

    const CreateFaktura = (value, name, surname, email) => {
        setAskFaktura(false);

        if (value == true) {
            let data = {
                id: 0,
                uid: '',
                date_to_pay: GetDate3(AddDays(Today(), fakturaDays)),
                salon_id: props.user.id,
                eshop_id: 0,
                order_id: invoice.id,
                enabled: true,
                language: props.language,
                number: '',
                company: '',
                name: name,
                surname: surname,
                street: invoice.street == null ? '' : invoice.street,
                street_number: invoice.street_number == null ? '' : invoice.street_number,
                psc: invoice.psc == null ? '' : invoice.psc,
                town: invoice.town == null ? '' : invoice.town,
                email: email,
                ico: '',
                dic: '',
                ic_dph: '',
                sum: invoice.sum,
                days: fakturaDays,
                var_symbol: '',
                kon_symbol: '',
                text: '',
                note: '',
                uhradena: false
            };

            var data_items = [];

            var n = 1;
            if (products != false) {
                products.forEach(item => {
                    let element = {
                        id: 0,
                        salon_id: props.user.id,
                        faktura_id: 0,
                        line: n,
                        label: item.label,
                        note: item.code + (item.details != '' ? ' - ' + item.details : ''),
                        quantity: item.quantity,
                        price: item.eur,
                        sum: parseFloat(parseFloat(item.eur) * parseFloat(item.quantity)).toFixed(2)
                    };

                    data_items.push(element);

                    n++;
                });
            }

            Debug(data);
            Debug(data_items);

            db_faktura(data, data_items);

        }
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faShoppingBasket} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '60%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.lang.products_shop_details} - {invoice.invoice_number}</p>
                            {props.isSmall ? null :
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.lang.products_shop}</p>
                            }
                        </div>
                    </div>

                    <div style={{ ...styles.Block, width: 220, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, height: global.dialog_bar_height - 16, backgroundColor: global.theme_white, borderRadius: props.radius }}>
                            <p style={{ ...styles.TextTiny }}>{props.lang.products_shop_sum}</p>
                            <div style={{ ...styles.Block, height: 24 }}>
                                <p style={{ ...styles.TextNormal, marginTop: 5, fontWeight: '600' }}>{GetPrice(invoice != false ? GetPrice(sum) : 0)} {props.lang.money}</p>
                            </div>
                        </div>
                    </div>

                    <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, backgroundColor: global.theme_white, overflowY: 'scroll' }}>
                    <div style={{ ...styles.BlockCenter, width: '98%', paddingTop: 5, paddingBottom: 5 }}>
                        {products != false ?
                            products.map((item, index) => (
                                <Paper elevation={overID == item.id ? 5 : 2} key={item.id} style={{ ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: item.enabled == true ? global.theme_lighter : global.theme_gray, marginTop: 5, marginBottom: 5, borderRadius: rowHeight / 2 }}
                                    onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                >
                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                            <img src={item.image == null || item.image == '' ? no_image : item.image.substring(0, 4) == 'http' ? item.image : global.web_images + '/' + item.image} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                        </div>
                                        {props.isSmall ?
                                            <div style={{ ...styles.BlockLeft, height: rowHeight, width: '70%', justifyContent: 'center', marginLeft: 10 }}>
                                                <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                <p style={{ ...styles.TextTiny }}>{lang.price__}: {GetPrice(item.eur)} {lang.money}/{lang.pieces}<span style={{ fontSize: global.font_xtiny, fontWeight: '400', marginLeft: 10 }}>{item.code} {item.details != '' ? '- ' + item.details : ''}</span></p>
                                                <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.quantity}: {item.quantity}</p>
                                            </div>
                                            :
                                            <div style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label} <span style={{ fontSize: global.font_xtiny, fontWeight: '400' }}>{item.code} {item.details != '' ? '- ' + item.details : ''}</span></p>
                                                <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                    {item.enabled == false ?
                                                        <p style={{ ...styles.TextTiny, marginTop: 0, color: global.theme_red }}>{lang.products_shop_deletes}</p>
                                                        : null}
                                                    <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                        <div style={{ ...styles.BlockLeft }}>
                                                            <p style={{ ...styles.TextTiny }}>{lang.price__}: {GetPrice(item.eur)} {lang.money}/{lang.pieces}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {props.isSmall == true ? null :
                                            <div style={{ ...styles.Block, height: rowHeight, width: 200, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextTiny, marginBottom: 5 }}>{lang.quantity}: {item.quantity}</p>
                                            </div>
                                        }
                                    </div>
                                </Paper>
                            )) :
                            // ZIADNE PRODUKTY                            
                            <div style={{ ...styles.Block, paddingTop: 20 }}>
                                {isBusy == false ?
                                    <EmptyList lang={lang} />
                                    : null}
                            </div>
                        }
                    </div>
                </div>
                <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, height: buttonHeight }}>
                    <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockLeft, height: props.isSmall ? 80 : buttonHeight }}>
                        {products != false ?
                            client == false ?
                                <div style={{ ...styles.BlockCenter, marginLeft: props.isSmall ? 0 : 20, width: 200 }}>
                                    <p style={{ ...styles.TextTiny, marginBottom: 5, marginTop: 12 }}>{lang.products_shop_client}</p>
                                    <Button onClick={() => Client()} style={{ ...styles.ButtonBlue, width: 200 }}>
                                        <FontAwesomeIcon style={{ height: 14, color: global.theme_white, marginRight: 10 }} icon={faUser} />
                                        <p style={{ ...styles.TextTiny, color: global.theme_white }}>
                                            {lang.client}
                                        </p>
                                    </Button>
                                </div>
                                :
                                <div style={{ ...props.isSmall ? styles.Block : styles.BlockLeft, marginLeft: 20, height: props.isSmall ? 80 : buttonHeight, justifyContent: 'center' }}>
                                    <p style={{ ...styles.TextTiny }}>{lang.client}:</p>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <p style={{ ...styles.TextSmall, marginTop: 5 }}>{client.name} {client.surname}</p>
                                        <IconButton onClick={() => Client()} style={{ width: 30, height: 30, marginLeft: 10, backgroundColor: global.theme_lighter }}>
                                            <FontAwesomeIcon style={{ width: 12, color: global.theme_dark_blue }} icon={faEdit} />
                                        </IconButton>
                                    </div>
                                </div>
                            : null}
                    </div>
                    <div style={{ ...styles.Block, height: props.isSmall ? changes == true ? 50 : 100 : buttonHeight, justifyContent: 'center' }}>
                        {changes == true ? null :
                            <Button onClick={() => setShowDelete(true)} style={{ ...styles.ButtonRed, marginRight: props.isSmall ? 0 : 0, width: 200 }}>
                                {props.isSmall ? null :
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faTrash} />
                                }
                                <p style={{ ...styles.TextTiny, color: global.theme_white }}>
                                    {lang.products_shop_delete}
                                </p>
                            </Button>
                        }
                        {changes == true ? null :
                            faktura == true ?
                                <p style={{ ...styles.TextTiny, marginTop: 20, fontWeight: '600' }}>{lang.faktura_created}</p>
                                :
                                < Button onClick={() => setAskFaktura(true)} style={{ ...styles.ButtonBlue, marginRight: props.isSmall ? 0 : 0, width: 200, marginTop: 16 }}>
                                    {props.isSmall ? null :
                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPen} />
                                    }
                                    <p style={{ ...styles.TextTiny, color: global.theme_white }}>
                                        {lang.faktura_create}
                                    </p>
                                </Button>
                        }
                    </div>
                    <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRight, height: props.isSmall ? 50 : buttonHeight, justifyContent: 'center' }}>
                        {changes == true ?
                            <Button onClick={() => Save()} style={{ ...styles.ButtonGreen, marginRight: props.isSmall ? 0 : 20, width: 200 }}>
                                <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faSave} />
                                <p style={{ ...styles.TextTiny, color: global.theme_white }}>
                                    {lang.save_changes}
                                </p>
                            </Button>
                            : null}
                    </div>
                </div>
            </Paper>

            {askFaktura == true ?
                <FakturaContact client={client} theme={props.theme} radius={props.radius} title={lang.faktura_create} sub_title={''} text={lang.faktura_create_ask} sub_text={lang.faktura_create_note} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} lang={props.lang} language={props.language} offset={props.offset} isSmall={props.isSmall} func={CreateFaktura.bind(this)} />
                : null}

            {showDelete == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.products_shop_delete} sub_title={''} text={lang.products_shop_delete_text1} sub_text={lang.products_shop_delete_text2} icon={faTrash} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={DeleteResult.bind(this)} />
                : null}

            {showClient == true ?
                <ClientSelect isSmall={props.isSmall} today={true} employees={props.employees} employee_id={props.employee_id} user={props.user} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} func={ClientSelectResult.bind(this)} />
                : null}

            {showFakturaOK == true ?
                <DialogInfo radius={props.radius} title={lang.info} sub_title={lang.faktura} text={lang.faktura_created_success} sub_text={lang.faktura_created_success_note} icon={faQuestion} button={props.lang.close} icon_show={faCheckCircle} icon_color={global.theme_darker_green} theme={props.theme} func={() => setShowFakturaOK(false)} />
                : null}

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError text={lang.faktura_error} offset={props.offset} theme={props.theme} func={ShowErrorPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading theme={props.theme} offset={props.offset}  ></Loading>
                : null}

        </Backdrop >
    );
}

export const FakturaContact = (props) => {

    const [name, setName] = useState(props.client == false ? '' : props.client.name);
    const [surname, setSurname] = useState(props.client == false ? '' : props.client.surname);
    const [email, setEmail] = useState(props.client == false ? '' : props.client.email);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 400;
    const dialogHeight = 500;

    let { func } = props;
    let lang = props.lang;
    let offset = 20;

    useEffect(() => {

        return () => {
        };

    }, []);


    const Press = (value) => {
        func(value, name, surname, email);
    }

    return (
        <Backdrop open={true} style={{ position: 'fixed', top: GetTopPosition(height, dialogHeight), paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faFile} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                            {props.sub_title != '' && props.sub_title != undefined ?
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                                : null}
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                    <div style={{ ...styles.Block, width: '92%', paddingTop: 20, paddingBottom:200 }}>
                        <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.faktura_odberatel}</p>
                        <TextInput required redraw enabled={true} lang={lang} value={name} label={lang.name} func={(txt) => setName(txt)} />
                        <div style={{ ...styles.Block, marginTop: offset }}></div>
                        <TextInput required redraw enabled={true} lang={lang} value={surname} label={lang.surname} func={(txt) => setSurname(txt)} />
                        <div style={{ ...styles.Block, marginTop: offset }}></div>
                        <TextInput redraw enabled={true} lang={lang} value={email} label={lang.email} func={(txt) => setEmail(txt)} />
                    </div>
                </div>
                <div style={{ ...styles.Block, height: 100 }}>
                    {name.trim() != '' && surname.trim() != '' ?
                        <Button onClick={() => Press(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 180 }}>{lang.faktura_create}</Button>
                        : null}
                </div>
            </Paper>
        </Backdrop>
    );
}
