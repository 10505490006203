import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation, createRoutesFromChildren } from "react-router-dom";
import { cz, sk } from './language.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark, faCircle, faBackward, faHome, faUser, faSearch, faRightFromBracket, faImage, faChevronRight, faChevronUp, faSquare, faBars, faTriangleExclamation, faInfoCircle, faTrash, faEdit, faAdd, faMessage, faQuestionCircle, faLayerGroup, faTag, faLocationDot, faBell, faCog, faList, faPlusCircle, faPlus, faCogs, faAngleLeft, faCalendarDay, faCheckDouble, faQuestion } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Drawer, Slide } from '@mui/material';
import { Alert, Autocomplete, Button, IconButton, MenuItem, Select, TextField, Paper, Tabs, Tab } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { styles } from './styles.js';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import useMediaQuery from '@mui/material/useMediaQuery';
import './items.css';
import { EmptyList, GetTodayDatum, GetHours, Today, GetDatum2, TextInput, DialogYesNo, Colors, GetImageLink, DialogImagePreview, GetTopPosition, Debug } from './items.js';
import { TerminPreview } from './calendar_dialog.js';

export const DesktopKalendar = (props) => {
    /*
            
            USAGE:
            <DesktopKalendar radius={props.radius} title={'nazov'} sub_title={'podnázov'} [ backcolor={true} icon={faQuestion} background={false} ] func={DesktopKalendarResult.bind(this)} />

            const DesktopKalendarResult = (typ,value) => {
                console.log(value)
            }
            */
    const [isBusy, setBusy] = useState(true);
    const [items, setItems] = useState([]);
    const [datum, setDatum] = useState(GetTodayDatum());
    const [showPreview, setShowPreview] = useState(false);
    const [selected, setSelected] = useState([]);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {

        if (running == false) {
            running = true;
            db_calendar();
        }
        return () => {
        };

    }, [props.employee_id, props.user]);

    const db_calendar = async () => {

        try {
            const response = await fetch(
                global.db_url + 'calendar', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    employee_id: props.employee_id,
                    arr: [Today()]
                })
            })

            const json = await response.json();


            if (json.ok > 0) {
                var tmp = json.items;

                tmp = tmp.filter(x => x.deleted == false);

                tmp.sort((a, b) => {
                    if (parseInt(a.time_start) > parseInt(b.time_start)) {
                        return 1;
                    } else {
                        return -1;
                    }
                })

                tmp.forEach(item => {
                    item.services = JSON.parse(item.services);
                });

                setItems(tmp);
            }
            setBusy(false);
        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Preview = (item) => {
        setSelected(item);
        setShowPreview(true);
    }

    const PreviewResult = (typ, data) => {
        setShowPreview(false);
        selected.color = data.color;
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: '100%', background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius, marginTop: props.marginTop != undefined ? props.marginTop : 0 }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                        <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                    </div>
                </div>
                <div style={{ ...styles.Block, width: props.isSmall ? '60%' : '80%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: 45 }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>
                        <p style={{ ...styles.TextDialogSubLabel, marginTop: 4, textAlign: 'left' }}>{props.sub_title}</p>
                    </div>
                </div>

                <div style={{ ...styles.Block, width: 150, height: global.dialog_bar_height }}>
                    <p style={{ ...styles.TextNormal, fontWeight: '600', color: global.theme_white }}>{datum}</p>
                </div>

            </div>
            <div style={{ ...styles.BlockCenter, backgroundColor: props.background == true ? global.theme_back : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, paddingBottom: props.radius }}>
                <div style={{ ...styles.Block, minHeight: 60, paddingTop: 10, paddingBottom: props.isSmall ? 10 : 0 }}>
                    {items.length > 0 ?
                        <div style={{ ...styles.Block }}>
                            {items.map((item, index) => (
                                <div onClick={() => Preview(item)} key={item.id} style={{ ...styles.Block, backgroundColor: item.color > 0 ? global.colors[item.color] : undefined, paddingTop: 7, paddingBottom: 7, borderBottom: item.color > 0 ? '' : index < items.length - 1 ? '1px solid #DDDDDD' : '', minHeight: 50, cursor: 'pointer' }}>
                                    <div style={{ ...styles.BlockRow, width: '98%' }}>
                                        <div style={{ ...styles.BlockLeft, width: 120 }}>
                                            <p style={{ ...styles.TextSmall }}><b>{GetHours(item.time_start)}-{GetHours(item.time_end)}</b></p>
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '85%' }}>
                                            <p style={{ ...styles.TextSmall }}>{item.label}</p>
                                            <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{item.name} {item.surname}</p>
                                            {item.mobil != '' ?
                                                <p style={{ ...styles.TextTiny, marginTop: 4, color: global.theme_dark_gray }}>{lang.mobil.toLowerCase()}: {item.mobil}</p>
                                                : null}
                                            {item.email != '' ?
                                                <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.email_.toLowerCase()}: {item.email}</p>
                                                : null}
                                            {/*
                                            <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.note.toLowerCase()}: {item.note}</p>
                                            <div style={{ ...styles.BlockRowRaw, marginTop: 5 }}>
                                                <p style={{ ...styles.TextTiny }}>{lang.services}: </p>
                                                {item.services.map(element => (
                                                    <p style={{ ...styles.TextTiny, marginLeft: 5 }}>{element.label}, </p>
                                                ))}
                                            </div>
                                            */}
                                        </div>

                                    </div>
                                </div>
                            ))}
                        </div>
                        :
                        <div style={{ ...styles.Block, paddingTop: 0, height: 60 }}>
                            {isBusy == false ?
                                <EmptyList row small lang={lang} />
                                : null}
                        </div>
                    }
                </div>
            </div>

            {showPreview == true ?
                <TerminPreview isSmall={props.isSmall} item={selected} lang={lang} language={props.language} radius={props.radius} title={GetDatum2(selected.date)} sub_title={GetHours(selected.time_start) + ' - ' + GetHours(selected.time_end)} backcolor={true} icon={faCalendarDay} background={true} employees={props.employees} employee_id={props.employee_id} user={props.user} offset={props.offset} theme={props.theme} func={PreviewResult.bind(this)} />
                : null}
        </Paper>
    );
}


export const DesktopBirthday = (props) => {
    /*
            
            USAGE:
            <DesktopBirthday radius={props.radius} title={'nazov'} sub_title={'podnázov'} [ backcolor={true} icon={faQuestion} background={false} ] func={DesktopBirthdayResult.bind(this)} />

            const DesktopBirthdayResult = (typ,value) => {
                console.log(value)
            }
            */
    const [isBusy, setBusy] = useState(false);
    const [items, setItems] = useState([]);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    let { func } = props;
    var lang = sk;

    var running = false;

    useEffect(() => {

        if (running == false) {
            running = true;
            db_birthday();
        }

        return () => {
        };

    }, [props.employee_id, props.user]);

    const db_birthday = async () => {

        try {
            const response = await fetch(
                global.db_url + 'calendar_birthday', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    language: props.language,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
            }
            setBusy(false);
        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (item) => {
        func(item);
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: '100%', background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius, marginTop: props.marginTop != undefined ? props.marginTop : 0 }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                        <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                    </div>
                </div>
                <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: 45 }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>
                        <p style={{ ...styles.TextDialogSubLabel, marginTop: 4, textAlign: 'left' }}>{props.sub_title}</p>
                    </div>
                </div>
            </div>
            <div style={{ ...styles.BlockCenter, backgroundColor: props.background == true ? global.theme_back : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                    {items != false ?
                        <div style={{ ...styles.Block, width: '98%' }}>
                            {items.map((item, index) => (
                                <div onClick={() => Press(item)} key={item.id} style={{ ...styles.BlockLeft, backgroundColor: item.color > 0 ? global.colors[item.color] : undefined, paddingTop: 7, paddingBottom: 7, borderBottom: item.color > 0 ? '' : index < items.length - 1 ? '1px solid #DDDDDD' : '', minHeight: 50, cursor: 'pointer' }}>
                                    <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{item.name} {item.surname}</p>
                                    <div style={{ ...styles.BlockLeft, marginTop: 5 }}>
                                        <p style={{ ...styles.TextTiny, fontWeight: '400', color: global.theme_dark_gray, marginTop: 2, marginLeft: 20 }}>{lang.mobil.toLowerCase()}: {item.mobil}</p>
                                        <p style={{ ...styles.TextTiny, fontWeight: '400', color: global.theme_dark_gray, marginTop: 2, marginLeft: 20 }}>{lang.email.toLowerCase()}: {item.email}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                        :
                        <div style={{ ...styles.Block, paddingTop: 0, height: 60 }}>
                            {isBusy == false ?
                                <EmptyList row small lang={lang} />
                                : null}
                        </div>
                    }
                </div>
            </div>
        </Paper>
    );
}


export const DesktopTodo = (props) => {
    /*
            
            USAGE:
            <DesktopTodo radius={props.radius} title={'nazov'} sub_title={'podnázov'} [ backcolor={true} icon={faQuestion} background={false} ] func={DesktopTodoResult.bind(this)} />

            const DesktopTodoResult = (typ,value) => {
                console.log(value)
            }
            */
    const [isBusy, setBusy] = useState(true);
    const [items, setItems] = useState([]);
    const [datum, setDatum] = useState(GetTodayDatum());
    const [showTodo, setShowTodo] = useState(false);
    const [selected, setSelected] = useState([]);
    const [editing, setEditing] = useState(false);
    const [showDelete, setShowDelete] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    let { func } = props;
    var lang = sk;

    var running = false;

    useEffect(() => {

        if (running == false) {
            running = true;
            db_todo();
        }

        return () => {
        };

    }, [props.employee_id, props.user]);

    const db_todo = async () => {

        try {
            const response = await fetch(
                global.db_url + 'todo', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user.id,
                    employee_id: props.employee_id,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;

                tmp.sort((a, b) => {
                    if (a.date < b.date) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
                setItems(tmp);
            }
            setBusy(false);
        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_todo_update = async (data) => {

        try {
            const response = await fetch(
                global.db_url + 'todo_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (editing == false) {
                    db_todo();
                }
            }
            setBusy(false);
        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const AddNew = () => {
        setEditing(false);
        setShowTodo(true);
    }

    const Edit = (item) => {
        setSelected(item);
        setEditing(true);
        setShowTodo(true);
    }

    const Delete = (item) => {
        setSelected(item);
        setEditing(false);
        setShowDelete(true);
    }

    const DeleteResult = (value) => {
        setShowDelete(false);
        if (value == true) {
            var data = {
                id: selected.id,
                salon_id: 0,
                employee_id: 0,
                text: '',
            }

            db_todo_update(data);
        }
    }
    const DialogTodoResult = (typ, note, color) => {
        setShowTodo(false);
        if (typ == true) {
            if (note.trim() != '') {
                var data = {
                    id: editing == true ? selected.id : 0,
                    salon_id: props.user.id,
                    worker_id: props.employee_id,
                    text: note.trim(),
                    checked: false,
                    color: color
                }

                db_todo_update(data);
                if (editing == true) {
                    selected.text = data.text;
                    selected.checked = data.checked
                    selected.color = data.color
                }
            }
        }
    }

    /*
    *
    * =================================================================================================================
    * TODO - DIALOG
    * =================================================================================================================
    *
    */

    const DialogTodo = (props) => {
        /*
                
                USAGE:
                <DialogTodo func={DialoTodoResult.bind(this)} />
     
                const DialoTodoResult = (value) => {
                    console.log(value)
                }
        */
        const [note, setNote] = useState('');
        const [color, setColor] = useState(0);

        const [width, setWidth] = useState(window.innerWidth);
        const [height, setHeight] = useState(window.innerHeight);

        const dialogWidth = props.isSmall ? width : 750;
        const dialogHeight = 340;

        let { func } = props;
        var lang = props.lang;
        const inputRef = useRef(null);

        var running = false;
        useEffect(() => {

            if (running == false) {
                running = true;

                var timer = setTimeout(() => {
                    inputRef.current.focus();
                }, 350);

                if (props.editing == true) {
                    setNote(props.item.text);
                    setColor(props.item.color);
                }
            }
            return () => {
            };

        }, []);


        const Press = (typ) => {
            func(typ, note, color);
        }

        return (
            <Backdrop open={true} style={{ position: 'fixed', top: GetTopPosition(height, dialogHeight), paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 999, marginTop: props.marginTop }}>
                <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                    <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                        <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                            <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                                <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faCheck} />
                            </div>
                        </div>
                        <div style={{ ...styles.Block, width: props.isSmall ? '70%' : '40%', height: global.dialog_bar_height }}>
                            <div style={{ ...styles.BlockLeft, height: 45, justifyContent: 'center' }}>
                                <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>
                                {props.sub_title != '' && props.sub_title != undefined ?
                                    <p style={{ ...styles.TextDialogSubLabel, marginTop: 4, textAlign: 'left' }}>{props.sub_title}</p>
                                    : null}
                            </div>
                        </div>
                        {props.isSmall ? null :
                            <div style={{ ...styles.Block, width: '50%', height: global.dialog_bar_height }}>
                                <Colors small value={color} func={(txt) => setColor(txt)} />
                            </div>
                        }
                        <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                            <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                                <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, height: dialogHeight - global.dialog_bar_height - 100, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                        <div style={{ ...styles.Block, width: '96%' }}>
                            <TextInput inputRef={inputRef} rows={3} redraw enabled={true} lang={lang} value={note} label={lang.note} func={(txt) => { setNote(txt) }} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, height: 100 }}>
                        <Button onClick={() => Press(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>{lang.save}</Button>
                    </div>
                </Paper>
            </Backdrop>
        );
    }
    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */

    return (
        <Paper elevation={global.menu_elevation} style={{ width: '100%', background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius, marginTop: props.marginTop != undefined ? props.marginTop : 0 }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                        <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                    </div>
                </div>
                <div style={{ ...styles.Block, width: props.isSmall ? '68%' : '80%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: 45 }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                        <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                    </div>
                </div>
                <div style={{ ...styles.Block, width: 80, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => AddNew()} style={{ width: 36, height: 36, backgroundColor: global.theme_black }}>
                        <FontAwesomeIcon style={{ height: 16, color: global.theme_white }} icon={faPlus} />
                    </IconButton>
                </div>
            </div>
            <div style={{ ...styles.BlockCenter, backgroundColor: props.background == true ? global.theme_back : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, paddingBottom: props.radius }}>
                <div style={{ ...styles.Block, minHeight: 60, paddingTop: 10, paddingBottom: props.isSmall ? 10 : 0 }}>
                    {items.length > 0 ?
                        <div style={{ ...styles.Block }}>
                            {items.map((item, index) => (
                                <div key={item.id} style={{ ...styles.Block, backgroundColor: undefined, paddingTop: 7, paddingBottom: 7, borderBottom: '1px solid #DDDDDD', cursor: 'pointer' }}>
                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.Block, width: 50 }}>
                                            <div style={{ ...styles.Block, width: 16, height: 16, backgroundColor: item.color == 0 ? undefined : global.colors_dark[item.color], borderRadius: 8 }}></div>
                                        </div>
                                        <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, minHeight: 40 }}>
                                            <p style={{ ...styles.TextSmall, textAlign: 'left' }}>{item.text}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 50 }}>
                                            <IconButton onClick={() => Delete(item)} style={{ width: 34, height: 34 }}>
                                                <FontAwesomeIcon style={{ height: 14, color: global.theme_dark_gray }} icon={faXmark} />
                                            </IconButton>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        :
                        <div style={{ ...styles.Block, paddingTop: 0, height: 60 }}>
                            {isBusy == false ?
                                <EmptyList row small lang={lang} />
                                : null}
                        </div>
                    }
                </div>
            </div>

            {
                showTodo == true ?
                    <DialogTodo item={selected} editing={editing} employees={props.employees} employee_id={props.employee_id} user={props.user} lang={props.lang} offset={props.offset} theme={props.theme} radius={props.radius} isSmall={props.isSmall} title={editing == true ? lang.todo_edit : lang.todo_new} sub_title={lang.note} func={DialogTodoResult.bind(this)} />
                    : null
            }

            {
                showDelete == true ?
                    <DialogYesNo theme={props.theme} radius={props.radius} title={lang.todo_delete} sub_title={''} text={lang.todo_delete_text} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={DeleteResult.bind(this)} />
                    : null
            }
        </Paper >
    );
}

export const DesktopBanner = (props) => {
    /*
            
            USAGE:
            <DesktopBanner radius={props.radius} title={'nazov'} sub_title={'podnázov'} [ backcolor={true} icon={faQuestion} background={false} ] func={DesktopBannerResult.bind(this)} />

            const DesktopBannerResult = (typ,value) => {
                console.log(value)
            }
            */
    const [showImage, setShowImage] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {

        if (running == false) {
            running = true;

        }

        return () => {
        };

    }, [props.employee_id, props.user]);


    const Press = (typ, value) => {
        func(typ, value);
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: '100%', background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius, marginTop: 0, cursor: 'pointer' }}>
            <div style={{ ...styles.Block }}>
                <img onClick={() => setShowImage(true)} src={props.isSmall ? GetImageLink(props.banner.image_preview) : GetImageLink(props.banner.image)} style={{ width: '100%', borderRadius: props.radius }}></img>
            </div>

            {showImage == true && props.banner.image_preview != false ?
                <DialogImagePreview theme={props.theme} file={props.isSmall ? GetImageLink(props.banner.image_preview_small) : GetImageLink(props.banner.image_preview)} radius={props.radius} lang={props.lang} title={lang.preview} sub_title={lang.vision_system} background={global.theme_dark_gray} crop={false} func={() => setShowImage(false)} />
                : null}
        </Paper>
    );
}
