import React, { useEffect, useState, useRef } from 'react';

// 2xhU-64e5fe3729c49-6584-Q2hr-ZQFs

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, ConvertToPrice, CopyData, Debug, DialogEnterNumber, DialogEnterText, DialogImagePreview, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, FormOK, GetDate, GetDate3, GetImageLink, GetLanguage, GetMoney, GetPrice, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, Today } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, CircularProgress, FormControlLabel, Hidden, IconButton, InputAdornment, MenuItem, Pagination, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faA, faAngleDown, faAngleLeft, faAngleRight, faAngleUp, faCheckCircle, faComment, faEdit, faEye, faEyeSlash, faIdCard, faImage, faInfo, faInfoCircle, faL, faLayerGroup, faList, faListAlt, faListCheck, faListOl, faPaperPlane, faPeace, faPen, faPlusCircle, faQuestion, faQuestionCircle, faSave, faShare, faShoppingBag, faShoppingBasket, faShoppingCart, faTrash, faUser, faWarning, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';
import { ProductDialog, ProductPreview, ShoppingCard } from './product_dialog';
import { ClientSelect } from './client_select';
import { centerCrop } from 'react-image-crop';
import { ProductBookingCard } from './products_booking';
import { Photos } from './photos';
import { ProductSelect } from './product_select';
import { SalonCard } from './salon_card';
import { SalonSelect } from './salon_select';

import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { BonusSelect } from './bonus_select';
import QRCode from "react-qr-code";
import { ServiceSelect } from './service_select';
import { ServicePreview } from './service_dialog';

export const NewsDisplay = () => {

    const service_1 = require('./react/app/service1.png');
    const service_2 = require('./react/app/service2.png');

    const [isBusy, setBusy] = useState(false);
    const [language, setLanguage] = useState(0);

    const [news, setNews] = useState(false);
    const [items, setItems] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);

    const [showProduct, setShowProduct] = useState(false);
    const [showBonus, setShowBonus] = useState(false);
    const [showService, setShowService] = useState(false);

    // IMAGE / FOTOGRAFIE
    const [photo, setPhoto] = useState(false);
    const [showPhoto, setShowPhoto] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [radius, setRadius] = useState(0);

    const introImageSize = 250;
    const imageSize = 300;
    const qrSize = 164;
    const imageRadius = 10;
    const offset = 30;

    var running = false;
    let params = useParams();
    var lang = GetLanguage(language);

    useEffect(() => {

        if (running == false) {
            db_get();            

            running = true;
        }

        return () => {
        };

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'news_details', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    uid: params.uid,
                    enabled: true
                })
            })

            const json = await response.json();
            
            if (json.ok > 0) {

                setNews(json.news);
                setLanguage(json.news.language);

                if (json.news != false) {
                    var tmp = json.items;
                    if (tmp != false) {
                        tmp.sort((a, b) => {
                            if (parseInt(a.priority) > parseInt(b.priority)) {
                                return 1;
                            } else {
                                return -1;
                            }
                        })
                    }

                    setItems(tmp);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const ProductPreviewPress = (item) => {
        setItemSelected(item);
        setShowProduct(true);
    }

    const ServicePreviewPress = (item) => {
        setItemSelected(item);
        setShowService(true);
    }

    const ImagePreview = (item) => {
        setPhoto(item.image);
        setShowPhoto(true);
    }


    return (
        <div style={{ ...styles.BlockCenter, height: height, width: '100%', fontFamily: 'roboto', backgroundColor: global.theme_white, overflowY: 'scroll' }}>
            <div style={{ ...styles.BlockCenter, maxWidth: 900 }}>

                {/* BODY */}
                {news != false ?
                    <div style={{ ...styles.BlockCenter, backgroundColor: global.theme_back, paddingTop: offset, paddingBottom: offset }}>
                        <div style={{ ...styles.BlockRow, width: '96%' }}>
                            {news.image != false ?
                                <div style={{ ...styles.Block, width: introImageSize + 50 }}>
                                    <img src={GetImageLink(news.image)} style={{ width: '100%', height: '100%', maxWidth: introImageSize, maxHeight: introImageSize, objectFit: 'contain', borderRadius: imageRadius }}></img>
                                </div>
                                : null}
                            <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                <p style={{ ...styles.TextXTiny, color: global.theme_medium }}>{GetDate(news.date)}</p>
                                <p style={{ ...styles.TextNormal, fontWeight: '600', textAlign: 'left', marginTop: 5 }}>{news.title}</p>
                                <p style={{ ...styles.TextSmall, marginTop: 20, textAlign: 'left' }}>{news.description}</p>
                            </div>
                        </div>
                    </div>
                    : null}

                {items != false ?
                    <div style={{ ...styles.BlockCenter, width: '96%', marginTop: 20, backgroundColor: global.theme_white }}>

                        {items.map((item, index) => (
                            item.enabled == true ?
                                <div key={item.id} style={{ ...styles.Block, marginBottom: offset }}>
                                    { // TEXT HTML
                                        item.typ == 0 ?
                                            <div style={{ ...styles.TextSmall, textAlign: 'left' }} dangerouslySetInnerHTML={{ __html: item.text }} />
                                            : null}

                                    {item.typ == 1 ?
                                        // IMAGE
                                        <div style={{ ...styles.Block }}>
                                            <img onClick={() => ImagePreview(item)} src={GetImageLink(item.image)} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain', cursor: 'pointer' }}></img>
                                            <p style={{ ...styles.TextTiny }}>{item.text}</p>
                                        </div>
                                        : null}

                                    {item.typ == 2 ?
                                        // PRODUKT
                                        <div style={{ ...styles.Block, cursor: 'pointer' }}>
                                            <p style={{ ...styles.TextLarge, fontWeight: '600', marginBottom: 10 }}>{lang.product}</p>
                                            {item.product != false ?
                                                <img src={GetImageLink(item.product.image)} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain' }}></img>
                                                : null}
                                            <p style={{ ...styles.TextTiny, fontWeight: '400', marginTop: 5 }}>{item.product.code}</p>
                                            <p style={{ ...styles.TextNormal, fontWeight: '600' }}>{item.product.label}</p>
                                            <p style={{ ...styles.TextTiny, fontWeight: '400', marginTop: 5 }}>{item.product.note_short}</p>
                                            <p style={{ ...styles.TextNormal, fontWeight: '400', marginTop: 10 }}>{item.text}</p>
                                        </div>
                                        : null}


                                    {item.typ == 4 ?
                                        // BONUS
                                        <div style={{ ...styles.Block }}>
                                            <p style={{ ...styles.TextLarge, fontWeight: '600', marginBottom: 10 }}>{lang.bonus}</p>
                                            {item.bonus != false ?
                                                item.bonus.image != '' ?
                                                    <img src={GetImageLink(item.bonus.image)} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain' }}></img>
                                                    : null
                                                : null}
                                            <p style={{ ...styles.TextTiny, fontWeight: '400', marginTop: 5 }}>{item.bonus.code}</p>
                                            <p style={{ ...styles.TextNormal, fontWeight: '600' }}>{item.bonus.label}</p>
                                            <p style={{ ...styles.TextNormal, fontWeight: '400', marginTop: 10 }}>{item.text}</p>
                                        </div>
                                        : null}

                                    {item.typ == 3 ?
                                        // ODDELOVAC
                                        <div style={{ ...styles.Block, height: 2, backgroundColor: global.theme_light_gray }}></div>
                                        : null}

                                    {item.typ == 5 ?
                                        // QRCODE
                                        <div style={{ ...styles.Block }}>
                                            <div style={{ ...styles.Block, width: qrSize, height: qrSize }}>
                                                <QRCode
                                                    size={256}
                                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                    value={item.image == false ? '' : item.image}
                                                    viewBox={`0 0 256 256`}
                                                />
                                            </div>
                                            <div style={{ ...styles.Block, width: '50%' }}>
                                                <p style={{ ...styles.TextSmall, marginTop: 10 }}>{item.text}</p>
                                            </div>
                                        </div>
                                        : null}

                                    {item.typ == 6 ?
                                        // OSETRENIE
                                        <div style={{ ...styles.Block, cursor: 'pointer' }}>
                                            <p style={{ ...styles.TextLarge, fontWeight: '600', marginBottom: 10 }}>{lang.service_}</p>
                                            {item.service != false ?
                                                <img src={service_1} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain', borderRadius: imageRadius }}></img>
                                                : null}
                                            <p style={{ ...styles.TextNormal, fontWeight: '600', marginTop: 5 }}>{item.service.label}</p>
                                            <p style={{ ...styles.TextNormal, fontWeight: '400', marginTop: 10 }}>{item.text}</p>
                                        </div>
                                        : null}

                                </div>
                                : null
                        ))}
                    </div>
                    : null}
                {news != false ?
                    <div style={{ ...styles.Block, marginTop: items == false ? 0 : offset, borderTop: '1px solid ' + global.theme_light_gray, paddingTop: offset, backgroundColor: global.theme_back }}>
                        <div style={{ ...styles.Block, width: '96%', alignItems: 'flex-start' }}>
                            <p style={{ ...styles.TextTiny, marginBottom: offset }}>{lang.news_author}: {news.author}</p>
                        </div>
                    </div>
                    : null}

            </div>

            {showPhoto == true ?
                <DialogImagePreview theme={0} file={GetImageLink(photo)} radius={0} lang={lang} title={lang.preview} sub_title={lang.news} background={global.theme_dark_gray} crop={false} func={() => setShowPhoto(false)} />
                : null}

            {isBusy == true ?
                <Loading offset={0} theme={0} ></Loading>
                : null}
        </div>

    );
}