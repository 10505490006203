/*
*
* =================================================================================================================
* AKTUALITY - zobrazenie
* =================================================================================================================
*
*/

import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation, createRoutesFromChildren } from "react-router-dom";
import { cz, sk } from './language.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark, faCircle, faBackward, faHome, faUser, faSearch, faRightFromBracket, faImage, faChevronRight, faChevronUp, faSquare, faBars, faTriangleExclamation, faInfoCircle, faTrash, faEdit, faAdd, faMessage, faQuestionCircle, faLayerGroup, faTag, faLocationDot, faBell, faCog, faList, faPlusCircle, faPlus, faCogs, faAngleLeft, faCalendarDay, faCheckDouble, faQuestion } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Drawer, Slide } from '@mui/material';
import { Alert, Autocomplete, Button, IconButton, MenuItem, Select, TextField, Paper, Tabs, Tab } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { styles } from './styles.js';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import useMediaQuery from '@mui/material/useMediaQuery';
import './items.css';
import { EmptyList, GetTodayDatum, GetHours, Today, GetDatum2, TextInput, DialogYesNo, Colors, GetImageLink, DialogImagePreview, GetTopPosition, GetDatum, GetDate, Debug } from './items.js';
import { TerminPreview } from './calendar_dialog.js';
import { DialogPostRead, PostIcon } from './news_agent.js';

export const DesktopNews = (props) => {

    const [isBusy, setBusy] = useState(false);
    const [items, setItems] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {

        if (running == false) {
            running = true;
            db_get();
        }
        return () => {
        };

    }, [props.user, props.country]);

    const db_get = async () => {
        if (isBusy == false) {
            try {
                const response = await fetch(
                    global.db_url + 'news', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        user_id: props.user.id,
                        admin: props.admin,
                        language: props.country.language_id,
                        max: 4
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    setItems(json.items)
                }
                setBusy(false);
            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }
    }

    const Press = (typ) => {
        func(typ);
    }

    const PostResult = (id) => {

    }

    const Preview = (item) => {
        setItemSelected(item)
        setShowPreview(true)
    }

    const CloseList = () => {
        setShowAll(false);
        db_get();
    }

    return (
        <Paper elevation={global.menu_elevation} style={{ width: '100%', background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius, marginTop: props.marginTop != undefined ? props.marginTop : 0 }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                        <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faInfoCircle} />
                    </div>
                </div>
                <div style={{ ...styles.Block, width: '85%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: 45 }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                        <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                    </div>
                </div>
            </div>
            <div style={{ ...styles.BlockCenter, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, paddingBottom: props.isSmall ? 20 : props.radius }}>
                <div style={{ ...styles.BlockCenter, flexDirection: 'row', flexWrap: 'wrap', paddingTop: 10 }}>
                    {items != false ?
                        items.map((item, index) => (
                            <div key={item.id} onClick={() => Preview(item)} style={{ margin: 5 }}>
                                <PostIcon theme={props.theme} key={item.id} id={item.id} elevation={0} image={item.image} title={item.title} description={item.description} date={item.date} func={PostResult.bind(this)} />
                            </div>
                        )) :
                        <div style={{ ...styles.Block, paddingTop: 0, height: 60 }}>
                            {isBusy == false ?
                                <EmptyList row small lang={lang} />
                                : null}
                        </div>
                    }

                    {items != false ?
                        <div style={{ ...styles.Block, marginTop: 20 }}>
                            <Button onClick={() => setShowAll(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 180 }}>{lang.news_show_all}</Button>
                        </div>
                        : null}
                </div>
            </div>

            {showPreview == true ?
                <DialogPostRead newsID={itemSelected.id} remoteApp={props.remoteApp} user={props.user} admin={props.admin} countries={props.countries} country={props.country} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} isSmall={props.isSmall} func={() => setShowPreview(false)} />
                : null}

            {showAll == true ?
                <NewsList lremoteApp={props.remoteApp} user={props.user} admin={props.admin} countries={props.countries} country={props.country} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} isSmall={props.isSmall} func={() => CloseList()} />
                : null}
        </Paper>
    );
}


export const NewsList = (props) => {
    /*
            
            USAGE:
            <NewsList lremoteApp={props.remoteApp} user={props.user} admin={props.admin} countries={props.countries} country={props.country} lang={props.lang} language={props.language}  offset={props.offset} theme={props.theme}  radius={props.radius} isSmall={props.isSmall}  func={NewsListResult.bind(this)} />

            const NewsListResult = (typ) => {
                console.log(typ)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [items, setItems] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [radius, setRadius] = useState(height > 850 ? props.radius : 0);

    const dialogWidth = width >= 1200 ? 1200 : width;
    const dialogHeight = height >= 850 ? 850 : height;

    let { func } = props;
    var lang = props.lang;
    var running = false;

    useEffect(() => {
        if (running == false) {
            db_get();

            running = true
        }

        return () => {
        };

    }, []);

    const db_get = async () => {
        if (isBusy == false) {
            try {
                const response = await fetch(
                    global.db_url + 'news', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        user_id: props.user.id,
                        admin: props.admin,
                        language: props.country.language_id,
                        max: 50
                    })
                })

                const json = await response.json();

                if (json.ok > 0) {
                    setItems(json.items)
                }
                setBusy(false);
            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }
    }

    const Press = (typ) => {
        func(typ);
    }

    const Preview = (item) => {
        setItemSelected(item)
        setShowPreview(true)
    }

    const PostResult = () => {

    }


    return (

        <Backdrop open={true} style={{ zIndex: 45, paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: radius, borderTopRightRadius: radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faInfoCircle} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.news}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.vision_system}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: radius, borderBottomRightRadius: radius }}>
                    <div style={{ ...styles.BlockCenter, width: '95%', height: dialogHeight - global.dialog_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        {/* BODY */}
                        <div style={{ ...styles.BlockCenter, flexDirection: 'row', flexWrap: 'wrap' }}>
                            {items != false ?
                                items.map((item, index) => (
                                    <div key={item.id} onClick={() => Preview(item)} style={{ margin: 5 }}>
                                        <PostIcon theme={props.theme} key={item.id} id={item.id} elevation={0} image={item.image} title={item.title} description={item.description} date={item.date} func={PostResult.bind(this)} />
                                    </div>
                                )) :
                                null
                            }
                        </div>
                    </div>
                </div>
            </Paper>

            {showPreview == true ?
                <DialogPostRead newsID={itemSelected.id} remoteApp={props.remoteApp} user={props.user} admin={props.admin} countries={props.countries} country={props.country} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} isSmall={props.isSmall} func={() => setShowPreview(false)} />
                : null}

        </Backdrop>
    );
}