import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation, createRoutesFromChildren } from "react-router-dom";
import { cz, sk } from './language.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark, faCircle, faBackward, faHome, faUser, faSearch, faRightFromBracket, faImage, faChevronRight, faChevronUp, faSquare, faBars, faTriangleExclamation, faInfoCircle, faTrash, faEdit, faAdd, faMessage, faQuestionCircle, faLayerGroup, faTag, faLocationDot, faBell, faCog, faList, faPlusCircle, faPlus, faCogs, faAngleLeft, faArrowLeft, faEye, faCheckCircle, faWarning, faPhoneAlt, faMobileAlt, faNoteSticky, faPenClip, faPen, faPhotoFilm, faChartBar, faHeart, faStar, faCashRegister, faInfo, faMagnifyingGlass, faQuestion, faSave, faBook, faEnvelope, faMobile, faRotateLeft, faMobilePhone, faPhone, faAppleAlt, faMobileAndroid, faMobileAndroidAlt, faExclamationTriangle, faCalendarDays, faPaperPlane, faXmarkCircle, faShare } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Checkbox, Drawer, FormControlLabel, InputAdornment, Pagination, Slide, Tooltip } from '@mui/material';
import { Button, IconButton, MenuItem, Select, TextField, Paper, Tabs, Tab } from '@mui/material';
import { styles } from './styles.js';
import './items.css';
import { AddDays, BinaryToData, BinaryToList, ConvertToPrice, CopyData, DataToBinary, Debug, DialogAgreements, DialogEmail, DialogGdprLog, DialogImagePreview, DialogInfo, DialogNotify, DialogWarningYesNo, DialogYesNo, EmptyList, FormOK, GDPRDetails, GetAge, GetDate3, GetDatum, GetImageLink, GetPrice, GetTodayMonth, GetTodayYear, GetVoucher, GetVoucherPrice, Loading, SelectYear, ShowError, ShowOK, TextInput, Today, UniqueID, Voucher } from './items.js';
import { DialogClient } from './clients_dialogs.js';
import { Photos } from './photos.js';
import { AddServiceNote, DialogServiceNote, ServiceSelect } from './service_select.js';
import { AddProductNote, ProductSelect } from './product_select.js';
import { Graph } from './graph.js';
import QRCode from "react-qr-code";


export const ClientCard = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <ClientCard employees={props.employees} user_id={props.user.id} client_id={itemSelected.id} lang={lang} radius={props.radius}  offset={props.offset} theme={props.theme}  func={ClientCardResult.bind(this)} />

            const ClientCardResult = (typ,value) => {
                console.log(value)
            }
    */

    const no_image = require('./react/app/no_image.jpg');
    const service_1 = require('./react/app/service1.png');
    const service_2 = require('./react/app/service2.png');
    const client_card = require('./react/app/client_card.png');
    const voucher_sum = require('./react/app/voucher_circle.png');
    const apple = require('./react/app/apple-logo.png');
    const android = require('./react/app/android-logo.png');

    const [isLoading, setLoading] = useState(true);
    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState(false);
    const [index, setIndex] = useState(-1);
    const [overID, setOverID] = useState(-1);

    // KLIENT
    const [data, setData] = useState({});
    const [items, setItems] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);

    const itemsMax = 24;

    const [showSettings, setShowSettings] = useState(false);
    const [showEmail, setShowEmail] = useState(false);
    const [showMobile, setShowMobile] = useState(false);
    const [showPhoto, setShowPhoto] = useState(false);

    // OK / ERROR
    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // POZNAMKY
    const [note, setNote] = useState('');
    const [showDeleteNote, setShowDeleteNote] = useState(false);
    const [showEditNote, setShowEditNote] = useState(false);

    // GALERIA
    const [showAddPhoto, setShowAddPhoto] = useState(false);
    const [showDeletePhoto, setShowDeletePhoto] = useState(false);
    const [showGalleryPhoto, setShowGalleryPhoto] = useState(false);

    // SLUZBY
    const [showService, setShowService] = useState(false);
    const [serviceSelected, setServiceSelected] = useState(false);
    const [showServiceNote, setShowServiceNote] = useState(false);

    // PRODUKTY 
    const [showProduct, setShowProduct] = useState(false);
    const [productSelected, setProductSelected] = useState(false);
    const [showProductNote, setShowProductNote] = useState(false);

    // VERNOSTNE BODY
    const [vouchers, setVouchers] = useState(false);
    const [vouchersUsed, setVouchersUsed] = useState(0);
    const [showVoucherDelete, setShowVoucherDelete] = useState(false);
    const [showVoucherEdit, setShowVoucherEdit] = useState(false);

    // ŠTATISTIKA
    const [statsData, setStatsData] = useState(false);

    // DIAGNOSTIKA
    const [servicesItems, setServicesItems] = useState(false);
    const [showServicesDiagnose, setShowServicesDiagnose] = useState(false);

    // PRIHLASOVACI PIN
    const [pin, setPin] = useState('');
    const [loginSended, setLoginSended] = useState(false);

    // GDPR DOKUMENT - EXPORT
    const [gdprBasicData, setGdprBasicData] = useState(false);
    const [gdprNotes, setGdprNotes] = useState(false);
    const [gdprGallery, setGdprGallery] = useState(false);
    const [gdprDiagnostic, setGdprDiagnostic] = useState(false);
    const [GDPRSettings, setGDPRSettings] = useState(false);
    const [showGDPR, setShowGDPR] = useState(false);
    const [showGDPRAgreement, setShowGDPRAgreement] = useState(false);
    const [showGDPRDelete, setShowGDPRDelete] = useState(false);
    const [showGDPRSettings, setShowGDPRSettings] = useState(false);
    const [askExportClient, setAskExportClient] = useState(false);
    const [askExportCard, setAskExportCard] = useState(false);
    const [askExportGDPR, setAskExportGDPR] = useState(false);
    const [showExportOK, setShowExportOK] = useState(false);
    const [showExportError, setShowExportError] = useState(false);
    const [GDPRTmp, setGDPRTmp] = useState(false);
    const [showGDPRDeleteRecords, setShowGDPRDeleteRecords] = useState(false);
    const [GDPRDeleteType, setGDPRDeleteType] = useState(0);
    const [showGDPRLogs, setShowGDPRLogs] = useState(false);
    const [showCardDelete, setShowCardDelete] = useState(false);

    // LINK NA KLIENTSKU APLIKACIU
    const [link, setLink] = useState(global.link_iphone);
    const [linkID, setLinkID] = useState(0);

    // FAKTURA
    const [faktura, setFaktura] = useState(false);
    const [fakturaDays, setFakturaDays] = useState(global.faktura_days);
    const [askFaktura, setAskFaktura] = useState(false);
    const [showFakturaOK, setShowFakturaOK] = useState(false);


    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [radius, setRadius] = useState(height > 850 ? props.radius : 0);

    const dialogWidth = width >= 1200 ? 1200 : width;
    const dialogHeight = height >= 850 ? 850 : height;

    const titleHeight = 120;
    const avatar_size = 90;
    const iconWidth = 70;
    const menuWidth = 200;
    const button_size = 100;
    const gallerySize = 220;
    const rowHeight = 100;
    const selectHeight = props.isSmall ? 40 : 0;

    const avatar_man = require('./react/app/man_.png');
    const avatar_woman = require('./react/app/woman_.png');


    // COLORS
    const font_selected = global.theme_black;
    const font_disabled = global.themes[props.theme].theme_darker;
    const color = global.themes[props.theme];

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            db_get();

        }

        return () => {
        };

    }, []);

    const db_get = async () => {
        setLoading(true);
        try {
            const response = await fetch(
                global.db_url + 'client', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    client_id: props.client_id,
                    salon_id: props.user_id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setData(json.item);

                if (json.item.gdpr_v2 == 0) {
                    // kompatibilita s V1
                    setGdprBasicData(true);
                    setGdprNotes(true);
                    setGdprGallery(true);
                    setGdprDiagnostic(true);

                } else {
                    let tmp = BinaryToData(json.item.gdpr_v2);
                    setGdprBasicData(tmp.d1);
                    setGdprNotes(tmp.d2);
                    setGdprGallery(tmp.d3);
                    setGdprDiagnostic(tmp.d4);
                }

                /*
                setGDPR(json.item.gdpr == false ? false : true);
                setEmployee(json.item.employee_id);
                setAvatar(json.item.photo);
                */
            }
            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const db_get_data = async (typ, filter) => {
        setBusy(true);

        setItems(false);
        setItemsFiltered(false);

        try {
            const response = await fetch(
                global.db_url + 'card_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    client_id: props.client_id,
                    salon_id: props.user_id,
                    typ: typ,
                    filter: filter,
                    language: props.language
                })
            })

            const json = await response.json();

            if (json.ok > 0) {

                if (index != typ) {
                    setIndex(typ);
                }
                var tmp = json.items;

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }

            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            setShowError(true);
            console.log(error);
            setBusy(false);
        }
    }

    const db_update_data = async (data_) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'card_data_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data_
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                if (data_.typ == 0) {
                    setNote('');
                }

                if (json.client_points > -1) {
                    // AK DOSLO K ZMENE BODOV - AKTUALIZOVAT
                    data.score = json.client_points;
                }
                db_get_data(data_.typ);
            } else {
                setShowError(true);
            }


        } catch (error) {
            setShowError(true);
            console.log(error);
            setBusy(false);
        }
    }

    const db_gdpr_update = async (email, gdpr_v2, delete_notes, delete_gallery, delete_card) => {
        setBusy(true);


        try {
            const response = await fetch(
                global.db_url + 'gdpr_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    client_id: props.client_id,
                    salon_id: props.user_id,
                    gdpr_list: BinaryToList(gdpr_v2, lang),
                    email: email,
                    gdpr_v2: gdpr_v2,
                    gdpr_log: gdpr_v2,
                    delete_notes: delete_notes,
                    delete_gallery: delete_gallery,
                    lang: {
                        do_not_reply: lang.do_not_reply,
                        do_not_reply_text: lang.do_not_reply_text_,
                        gdpr_changes: lang.gdpr_changes,
                    }
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                let tmp = BinaryToData(gdpr_v2);

                setGdprBasicData(tmp.d1);
                setGdprNotes(tmp.d2);
                setGdprGallery(tmp.d3);
                setGdprDiagnostic(tmp.d4);

                data.gdpr_v2 = gdpr_v2;
            } else {
                setShowError(true);
            }


        } catch (error) {
            setShowError(true);
            console.log(error);
            setBusy(false);
        }
    }

    const db_vouchers = async () => {
        setBusy(true);

        setItems(false);
        setItemsFiltered(false);

        try {
            const response = await fetch(
                global.db_url + 'vouchers_use', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    client_id: props.client_id,
                    salon_id: props.user_id,
                    vouchers: vouchersUsed,
                    points: props.user.bonus_points,
                    price: props.user.bonus_price,
                    points_sum: 0 - (parseInt(vouchersUsed) * parseInt(props.user.bonus_points)),
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                data.score = data.score - (parseInt(vouchersUsed) * parseInt(props.user.bonus_points));
                setVouchersUsed(0);
                setVouchers(false);
                setIndex(9);
                db_get_data(9);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            setShowError(true);
            console.log(error);
            setBusy(false);
        }
    }

    const db_voucher_update = async () => {
        setBusy(true);

        setItems(false);
        setItemsFiltered(false);

        try {
            const response = await fetch(
                global.db_url + 'voucher_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    client_id: props.client_id,
                    score: parseInt(data.score)
                })
            })

            const json = await response.json();
            if (json.ok > 0) {
                VoucherCalculate();

            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            setShowError(true);
            console.log(error);
            setBusy(false);
        }
    }


    const db_voucher_delete = async (voucher) => {
        setBusy(true);

        setItems(false);
        setItemsFiltered(false);

        try {
            const response = await fetch(
                global.db_url + 'voucher_delete', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    client_id: props.client_id,
                    salon_id: props.user_id,
                    voucher_id: voucher.item_id,
                    points: voucher.points
                })
            })

            const json = await response.json();
            setBusy(false);

            if (json.ok > 0) {
                data.score = parseInt(data.score) + parseInt(voucher.points);
                setVouchersUsed(0);
                setVouchers(false);
                setIndex(9);
                db_get_data(9);
            } else {
                setShowError(true);
            }


        } catch (error) {
            setShowError(true);
            console.log(error);
            setBusy(false);
        }
    }

    const db_diagnose = async (age, list) => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'diagnose', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.user_id,
                    age: age,
                    list: list,
                    language: props.language
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.services;

                if (tmp != false) {
                    tmp = tmp.filter(x => x.enabled == true);

                    tmp.sort((a, b) => {
                        if (a.score < b.score) {
                            return 1;
                        } else {
                            return -1;
                        }
                    })
                }

                setServicesItems(tmp);
                setShowServicesDiagnose(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            setShowError(true);
            console.log(error);
            setBusy(false);
        }
    }


    const db_pin = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'client_pin', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    client_id: data.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setPin(json.pin);
                setTimeout(() => {
                    setPin('------');
                }, 65 * 1000);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            setShowError(true);
            console.log(error);
            setBusy(false);
        }
    }


    const db_login_code = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'send_login_code', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    client_id: data.id,
                    salon_id: props.user_id,
                    lang: {
                        www_eshop: lang.www_eshop,
                        do_not_reply: lang.do_not_reply,
                        do_not_reply_text: lang.do_not_reply_text_,
                        pin: lang.pin,
                        login_code_send_label: lang.login_code_send_label,
                        login_code_send_text_1: lang.login_code_send_text_1,
                        login_code_send_text_2: lang.login_code_send_text_2,
                        email_thanks: lang.email_thanks
                    }
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
                setTimeout(() => {
                    setLoginSended(true);
                }, 850);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            setShowError(true);
            console.log(error);
            setBusy(false);
        }
    }


    const db_export = async (typ) => {
        /*
            TYP:
                    0 - informácie o zákazníkovi
                    1 - karta zákazníka

        */

        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'client_export', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language,
                    salon_id: props.user_id,
                    client_id: data.id,
                    gdpr_list: BinaryToList(data.gdpr_v2, lang),
                    typ: typ,
                    texts: {
                        header: typ == 0 ? lang.client_card : lang.gdpr_client_info,
                        label: typ == 0 ? lang.gdpr_saved_data : lang.client_card,
                        created: lang.created,
                        name: lang.name,
                        surname: lang.surname,
                        email: lang.email_,
                        mobil: lang.mobil,
                        products: lang.buyed_products,
                        product: lang.product,
                        products_eshop: lang.buyed_products_eshop,
                        services: lang.absolved_services,
                        service: lang.service,
                        eshop: lang.products_buy_eshop,
                        gallery: lang.gallery,
                        photos: lang.photos,
                        notes: lang.notes,
                        note: lang.note,
                        money: lang.money,
                        pieces: lang.pieces,
                        price_dph: lang.price_dph,
                        quantity: lang.quantity_,
                        date: lang.date,
                        client_points: lang.client_points,
                        address: lang.address,
                        sex: lang.sex,
                        birthdate: lang.birthdate,
                        male: lang.male,
                        female: lang.female,
                        email_text_1: lang.gdpr_email_client_text1,
                        email_text_2: lang.gdpr_email_client_text2,
                        email_text_3: lang.gdpr_email_client_text3,
                        email_text_4: lang.gdpr_email_client_text4,
                        email_text_5: lang.gdpr_email_client_text5,
                        email_text_6: lang.gdpr_email_client_text6,
                        email_text_7: lang.gdpr_email_client_text7,
                        eshop_link: lang.www_eshop,
                        do_not_reply: lang.do_not_reply_text_,
                    }
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                if (json.sended == true) {
                    setShowExportOK(true);
                } else {
                    setShowExportError(true);
                }
            } else {
                setShowExportError(true);
            }
            setBusy(false);

        } catch (error) {
            setShowError(true);
            console.log(error);
            setBusy(false);
        }
    }


    const db_delete_card = async () => {
        setBusy(true);

        setItems(false);
        setItemsFiltered(false);

        try {
            const response = await fetch(
                global.db_url + 'client_delete_card', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    client_id: props.client_id,
                    salon_id: props.user_id,
                    lang: {
                        title: lang.gdpr_delete_email,
                        do_not_reply: lang.do_not_reply
                    }
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                data.typ = global.account_client_deleted;
                Press(true, data);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            setShowError(true);
            console.log(error);
            setBusy(false);
        }
    }

    const db_faktura = async (data, data_items) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'faktura_create', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                    data_items: data_items
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setFaktura(true);
                setShowFakturaOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(false, data);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_scroll-' + index);

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 300);
    }

    const StatsGotoStart = () => {
        setTimeout(() => {
            const element = document.getElementById('stats_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 300);
    }

    const ChangeIndex = (id) => {
        var filter = '';

        setShowEditNote(false);
        setLoginSended(false);

        if (id == 6) {
            VoucherCalculate()
        } else if (id == 7) {
            db_pin();
        } else {
            if (id == 5) {
                filter = GetTodayYear().toString() + '-' + (GetTodayMonth() + 1).toString().padStart(2, '0');
                setStatsData(false);
            }
            db_get_data(id, filter);
        }
        setIndex(id);
    }

    const VoucherCalculate = () => {
        var q = GetVoucher(data.score, props.user.bonus_points);

        if (q > 0) {
            var n = 0;
            var list = [];
            while (n < q) {
                let tmp = {
                    id: n,
                    used: false
                };
                list.push(tmp);
                n++;
            }

            setVouchers(list);
        } else {
            setVouchers(false);
        }
        setVouchersUsed(0);
    }

    const SumToPay = (items) => {
        var sum = 0.00;
        items.forEach(item => {
            let tmp = parseInt(item.quantity) * parseFloat(item.eur);
            sum = sum + tmp;
        });

        return sum;
    }

    const Settings = () => {
        setShowSettings(true);
    }

    const Mobile = () => {
        setShowMobile(true);
    }

    const DialogNotifyResult = (typ, value) => {
        setShowMobile(false);
    }

    const Message = () => {
        setShowEmail(true);
    }

    const DialogEmailResult = () => {
        setShowEmail(false);
    }

    const DialogClientResult = (typ, usr) => {
        if (typ == true) {

            CopyData(usr, data, []);
        }
        setShowSettings(false);
    }

    const ShowPhoto = () => {
        if (data.photo != '' && data.photo != null) {
            setShowPhoto(true);
        }
    }

    const AddNote = () => {
        if (note.trim() != '') {
            var item = {
                id: 0,
                typ: 0,
                user_id: props.client_id,
                text: note
            };

            db_update_data(item);
        }
    }

    const AskDeleteNote = (item) => {
        setItemSelected(item);
        setShowDeleteNote(true);
    }

    const DeleteNote = (value) => {
        // VYMAZANIE POZNAMKY
        setShowDeleteNote(false);
        if (value == true) {
            var item = {
                id: itemSelected.id,
                typ: 0,
                user_id: 0
            };
            db_update_data(item);
        }
    }

    const EditNote = () => {
        var item = {
            id: showEditNote.id,
            typ: 0,
            user_id: showEditNote.user_id,
            text: showEditNote.text
        };

        db_update_data(item);
        setShowEditNote(false);
    }

    const PhotosResult = (typ, path, file) => {
        setShowAddPhoto(false);
        if (typ == true) {
            var item = {
                id: 0,
                typ: 1,
                image: path + file,
                note: '',
                salon_id: props.user_id,
                client_id: props.client_id
            };

            db_update_data(item);
        }
    }

    const PhotoDelete = (item) => {
        setItemSelected(item);
        setShowDeletePhoto(true);
    }

    const ShowGalleryPhoto = (item) => {
        setItemSelected(item);
        setShowGalleryPhoto(true);
    }

    const PhotoDeleteResult = (value) => {
        setShowDeletePhoto(false);
        if (value == true) {
            var item = {
                id: itemSelected.id,
                typ: 1,
                client_id: 0
            };
            db_update_data(item);
        }
    }

    // *********************************************************************************************************
    // SLUZBY
    // *********************************************************************************************************
    const AddService = () => {
        setShowService(true);
    }

    const ServiceSelectResult = (typ, item) => {
        // PRIDANIE NOVEJ SLUZBY
        setShowService(false);
        setShowServicesDiagnose(false);

        setServiceSelected(item);
        Debug(item);
        if (typ == true) {
            var tmp = {
                id: 0,
                typ: 2,

                enabled: true,
                date: item.date,
                service_id: item.id,
                label: item.label,
                note: item.note,
                time: item.time,
                client_id: props.client_id,
                salon_id: props.user_id,
                eur: item.eur,
                type: 0,
                quantity: 1,
                cost: item.price,
                group_id: item.typ == 0 ? 1 : 2,
                link: item.link,
                page: item.paper,
                invoice_id: 0,
                worker_id: item.worker_id,
                score: 0, //props.user.score == true ? parseInt(item.eur) : 0,
            }

            db_update_data(tmp);
        }
    }


    const EditServiceNote = (item) => {
        setServiceSelected(item);
        setShowServiceNote(true);
    }

    const AddServiceNoteResult = (typ, item) => {
        // UPRAVA SLUZBY
        setShowServiceNote(false);
        if (typ == true) {
            if (item.enabled == true) {
                var tmp = {
                    id: serviceSelected.id,
                    typ: 2,
                    worker_id: item.worker_id,
                    date: item.date,
                    note: item.note,
                    eur: item.eur,
                    score: 0, // props.user.score == true ? parseInt(item.eur) : 0,
                }
            } else {
                // DELETE
                var tmp = {
                    id: serviceSelected.id,
                    typ: 2,
                    enabled: false
                }

            }
            db_update_data(tmp);
        }
    }


    // *********************************************************************************************************
    // PRODUKTY
    // *********************************************************************************************************
    const AddProduct = () => {
        setShowProduct(true);
    }

    const ProductSelectResult = (typ, item) => {
        // ------------------------------------------------------
        // PRIDANIE NOVEHO PRODUKT
        // ------------------------------------------------------
        setShowProduct(false);
        setProductSelected(item);

        if (typ == true) {
            var tmp = {
                /*
                id: 0,
                typ: 3,
                date: item.date,
                enabled: true,
                salon_id: props.user_id,
                client_id: props.client_id,
                employee_id: item.employee_id,
                service_id: 0,
                product_id: item.id,
                label: item.label,
                note: item.note,
                cost: item.cost,
                price: item.price,
                quantity: item.quantity,
                invoice_id: 0,
                stock: item.stock,
                points: item.points,
                points_add: item.points_add,
                stock_status: item.stock_status,
                */
                id: 0,
                typ: 3,
                type: 1,
                enabled: true,
                date: item.date,
                service_id: item.id,
                label: item.label,
                note: item.note,
                time: 0,
                client_id: props.client_id,
                salon_id: props.user_id,
                eur: item.eur,
                type: 1,
                quantity: item.quantity,
                cost: item.cost_price,
                group_id: item.brand_id == 0 ? 2 : 1,
                link: '',
                page: '',
                invoice_id: 0,
                worker_id: item.worker_id,
                score: item.score,
                points_add: item.points_add,
                stock_status: item.stock_status,
                stock: item.stock,
            }

            db_update_data(tmp);
        }
    }


    const EditProductNote = (item) => {
        setProductSelected(item);
        setShowProductNote(true);
    }

    const AddProductNoteResult = (typ, item) => {
        // UPRAVA PRODUKTU V KARTE
        setShowProductNote(false);

        if (typ == true) {
            if (item.enabled == true) {
                // ------------------------------------------------------
                // UPRAVA PRODUKTU
                // ------------------------------------------------------
                var tmp = {
                    id: productSelected.id,
                    typ: 3,
                    date: item.date,
                    salon_id: props.user_id,
                    client_id: props.client_id,
                    service_id: productSelected.service_id,
                    worker_id: item.worker_id,
                    date: item.date,
                    note: item.note,
                    eur: item.eur,
                    group_id: productSelected.group_id,
                    stock: item.stock,
                    stock_status: item.stock_status,
                    quantity: item.quantity,
                    points_add: item.points_add,
                    score: item.score
                }
            } else {
                // ------------------------------------------------------
                // VYMAZANIE PRODUKTU
                // ------------------------------------------------------
                var tmp = {
                    id: productSelected.id,
                    salon_id: props.user_id,
                    client_id: props.client_id,
                    service_id: productSelected.service_id,
                    typ: 3,
                    enabled: false,
                    group_id: productSelected.group_id,
                    stock: item.stock,
                    stock_status: 0,
                    points_add: item.points_add,
                    score: item.score
                }

            }

            db_update_data(tmp);
        }
    }

    // *********************************************************************************************************
    //
    // VERNOSTNE BODY
    //
    // *********************************************************************************************************
    const VoucherUse = (item) => {
        var used = 0;
        var tmp = vouchers.find(x => x.id == item.id);
        if (tmp.used == false) {
            tmp.used = true;
            used = vouchersUsed + 1;
        } else {
            tmp.used = false;
            used = vouchersUsed - 1;
            if (used < 0) {
                used = 0;
            }
        }

        setVouchersUsed(used);
    }

    const VoucherSave = () => {
        db_vouchers();
    }

    const VoucherDeletePress = (item) => {
        setItemSelected(item);
        setShowVoucherDelete(true);
    }


    const VoucherDelete = (value) => {
        setShowVoucherDelete(false);
        if (value == true) {
            // vymazanie voucheru z pokladne
            db_voucher_delete(itemSelected);
        }
    }

    const VoucherChange = () => {
        setShowVoucherEdit(false);
        db_voucher_update();
    }



    // *********************************************************************************************************
    //
    // ŠTATISTIKA
    //
    // *********************************************************************************************************
    const GraphResult = (id, value, value2) => {
        if (id == -2) {
            // ZMENA ROKA
            setStatsData(false);
            db_get_data(5, value + '-' + value2.toString().padStart(2, '0'));
        }
        if (id == -1) {
            // STLPEC - deselect
            setStatsData(false);
        }
        if (id >= 0) {
            // OZNACENY STLPEC
            var tmp = items.find(x => x.id == id);

            tmp.products.sort((a, b) => {
                if (parseInt(a.quantity) < parseInt(b.quantity)) {
                    return 1;
                } else {
                    return -1;
                }
            })

            tmp.services.sort((a, b) => {
                if (parseInt(a.quantity) < parseInt(b.quantity)) {
                    return 1;
                } else {
                    return -1;
                }
            })

            if (tmp != undefined) {
                if (value == 0) {
                    setStatsData(tmp.products);
                } else {
                    setStatsData(tmp.services);
                }
                StatsGotoStart();
            }
        }
    }


    // *********************************************************************************************************
    //
    // DIAGNOSTIKA
    //
    // *********************************************************************************************************
    const ChangeAnswer = (item, value) => {
        item.value = value;
        setRedraw(!redraw);
    }

    const Diagnose = () => {
        // VYPOCET VEKOVEHO ROZPATIA
        var age = GetAge(data.birthday);
        var age_text = '';
        if (age < 21) {
            age_text = '0-20';
        }
        if (age > 20 && age < 31) {
            age_text = '21-30';
        }
        if (age > 30 && age < 41) {
            age_text = '31-40';
        }
        if (age > 40 && age < 51) {
            age_text = '41-50';
        }
        if (age > 50) {
            age_text = '51';
        }
        // 

        var list = [];
        items.forEach(question => {
            var answers = question.answers.filter(x => x.value == true);
            answers.forEach(answer => {
                list.push(answer);
            });
        });

        db_diagnose(age_text, list);
    }


    // *********************************************************************************************************
    //
    // PAGINATION
    //
    // *********************************************************************************************************
    const Redraw = (items, p) => {
        // REDRAW PRODUKTOV
        var data = items;

        var count = data.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax)
        setItemsFiltered(data);

        if (count == 0) {
            setItemsFiltered(false);
        }

        setPage(p);
        GoToStart();
    }

    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);

        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0);
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_);
    };

    const ChangeLink = (id) => {
        setLinkID(id);
        if (id == 0) {
            // apple
            setLink(global.link_iphone);
        }
        if (id == 1) {
            // android
            setLink(global.link_android);
        }

    }

    const ExportClientResult = (value) => {
        setAskExportClient(false);

        if (value == true) {
            // ODOSLAT UDAJE ZAKAZNIKOVI
            db_export(0);
        }
    }

    const ExportCardResult = (value) => {
        setAskExportCard(false);

        if (value == true) {
            // EXPORTOVAT KARTU ZAKAZNIKA
            db_export(1);
        }

    }

    const ExportGDPRResult = (value) => {
        setAskExportGDPR(false);

        if (value == true) {
            // EXPORTOVAT GDPR LOG
            db_export(3);
        }

    }

    const ChangeGDPR = () => {
        let tmp = {
            basic_data: gdprBasicData,
            notes: gdprNotes,
            gallery: gdprGallery,
            diagnostic: gdprDiagnostic
        }
        setGDPRSettings(tmp);

        setShowGDPRSettings(true);
    }


    const GDPRDetailsResult = (typ, item) => {
        setShowGDPRSettings(false);

        if (typ == true) {
            let value = DataToBinary(item.basic_data, item.notes, item.gallery, item.diagnostic, false, false, false, false);
            if (value != data.gdpr_v2) {
                // ZMENA NASTAVENI
                setGDPRTmp(item);

                if (item.basic_data == false) {
                    setTimeout(() => {
                        setShowGDPRDelete(true);
                    }, 150);

                } else {
                    // ULOZIT DO DB a odoslat email zo zmenov
                    var deleteGallery = false;
                    var deleteNotes = false;
                    var text = '';
                    if (item.gallery != gdprGallery) {
                        if (item.gallery == false) {
                            deleteGallery = true;
                            text = lang.gdpr_settings_gallery;
                        }
                    }
                    if (item.notes != gdprNotes) {
                        if (item.notes == false) {
                            deleteNotes = true;
                            if (text != '') {
                                text = text + ', ';
                            }
                            text = text + lang.gdpr_settings_notes;
                        }
                    }

                    if (deleteGallery == true || deleteNotes == true) {
                        setGDPRDeleteType({
                            delete_gallery: deleteGallery,
                            delete_notes: deleteNotes,
                            item: item,
                            text: text,
                            value: value
                        });
                        setTimeout(() => {
                            setShowGDPRDeleteRecords(true);
                        }, 150);
                    } else {
                        // ULOZIT NASTAVENIA - BEZ VYMAZAVANIA
                        db_gdpr_update(data.email, value, false, false, false);
                    }
                }


            } else {
                setGdprBasicData(item.basic_data);
                setGdprNotes(item.notes);
                setGdprGallery(item.gallery);
                setGdprDiagnostic(item.diagnostic);
            }
        }
    }

    const CardDelete = (value) => {
        setShowGDPRDelete(false);
        setShowCardDelete(false);

        if (value == true) {
            // VYMAZAT CELU KARTU ZÁKAZNÍKA
            db_delete_card();
        }
    }

    const GdprDeleteRecords = (value) => {
        setShowGDPRDeleteRecords(false);
        if (value == true) {
            // ZMENIT NASTAVENIA + VYMAZAT ZAZNAMY
            db_gdpr_update(data.email, GDPRDeleteType.value, GDPRDeleteType.delete_notes, GDPRDeleteType.delete_gallery, false);
        }

    }

    const GdprLog = () => {
        setShowGDPRLogs(true);
    }

    const DialogGdprLogResult = () => {
        setShowGDPRLogs(false);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const CreateFaktura = (value) => {
        setAskFaktura(false);

        let order = {};

        if (value == true) {
            let data_faktura = {
                id: 0,
                uid: '',
                date_to_pay: GetDate3(AddDays(Today(), fakturaDays)),
                salon_id: props.user.id,
                eshop_id: 0,
                order_id: 0,
                enabled: true,
                language: props.language,
                number: '',
                company: '',
                name: data.name,
                surname: data.surname,
                street: data.street,
                street_number: data.street_number,
                psc: data.psc,
                town: data.town,
                email: data.email,
                ico: '',
                dic: '',
                ic_dph: '',
                sum: parseFloat(SumToPay(itemsFiltered)).toFixed(2),
                days: fakturaDays,
                var_symbol: '',
                kon_symbol: '',
                text: '',
                note: '',
                uhradena: false
            };

            var data_items = [];

            var n = 1;
            if (itemsFiltered != false) {
                itemsFiltered.forEach(item => {
                    let quantity = item.typ == 3 ? 1 : item.quantity;
                    let price = item.typ == 3 ? 0 - parseFloat(item.eur) : item.eur;

                    let element = {
                        id: 0,
                        salon_id: props.user.id,
                        faktura_id: 0,
                        line: n,
                        label: item.typ == 3 ? lang.voucher : item.label,
                        note: '',
                        quantity: quantity,
                        price: price,
                        sum: parseFloat(parseFloat(quantity) * parseFloat(price)).toFixed(2)
                    };

                    data_items.push(element);

                    n++;
                });
            }

            Debug(data_faktura);
            Debug(data_items);

            db_faktura(data_faktura, data_items);
        }
    }


    // *********************************************************************************************************
    //
    // RENDER
    //
    // *********************************************************************************************************
    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: radius }}>
                {props.isSmall ?
                    <div style={{ ...styles.BlockRow, height: titleHeight, borderTopLeftRadius: radius, borderTopRightRadius: radius, background: global.themes[props.theme].window_bar_color }}>
                        <div style={{ ...styles.Block, width: titleHeight, height: titleHeight }}>
                            {isLoading == false ?
                                <div onClick={() => ShowPhoto()} style={{ ...styles.Block, width: avatar_size, height: avatar_size, background: data.photo == '' || data.photo == null ? global.icon_wite_background : '#00000000', borderRadius: 10, cursor: 'pointer' }} >
                                    {data.photo == '' || data.photo == null || gdprGallery == false ?
                                        <img src={data.sex == 0 ? avatar_woman : avatar_man} style={{ width: '100%', height: '100%', width: avatar_size, height: avatar_size, objectFit: 'contain', borderRadius: 10 }}></img>
                                        :
                                        <img src={GetImageLink(data.photo)} style={{ width: '100%', height: '100%', width: avatar_size, height: avatar_size, objectFit: 'contain', borderRadius: 10 }}></img>
                                    }
                                </div>
                                : null}
                        </div>
                        <div style={{ ...styles.Block, width: '60%', height: titleHeight, alignItems: 'flex-start' }}>
                            <p style={{ ...styles.TextLarge, fontWeight: '600', textAlign: 'left' }}><span style={{ ...styles.TextTiny, fontWeight: '400' }}>{props.user.id == 117 ? data.id + ' ' : ''}</span>{data.name} {data.surname}</p>
                            <p style={{ ...styles.TextTinyMobile, marginTop: 10 }}>{lang.mobil}: {data.mobil}</p>
                            <p style={{ ...styles.TextTinyMobile, marginTop: 2 }}>{lang.email_}: {data.email}</p>
                            <p style={{ ...styles.TextTinyMobile, marginTop: 0 }}>{lang.age}: {GetAge(data.birthday)} {lang.age_years}</p>
                        </div>
                        <div style={{ ...styles.Block, width: 70, height: titleHeight }}>
                            <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                                <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>
                    :
                    <div style={{ ...styles.BlockRow, height: titleHeight, borderTopLeftRadius: radius, borderTopRightRadius: radius, background: global.themes[props.theme].window_bar_color }}>
                        <div style={{ ...styles.Block, width: titleHeight, height: titleHeight }}>
                            {isLoading == false ?
                                <div onClick={() => ShowPhoto()} style={{ ...styles.Block, width: avatar_size, height: avatar_size, background: data.photo == '' || data.photo == null ? global.icon_wite_background : '#00000000', borderRadius: 10, cursor: 'pointer' }} >
                                    {data.photo == '' || data.photo == null || gdprGallery == false ?
                                        <img src={data.sex == 0 ? avatar_woman : avatar_man} style={{ width: '100%', height: '100%', width: avatar_size, height: avatar_size, objectFit: 'contain', borderRadius: 10 }}></img>
                                        :
                                        <img src={GetImageLink(data.photo)} style={{ width: '100%', height: '100%', width: avatar_size, height: avatar_size, objectFit: 'contain', borderRadius: 10 }}></img>
                                    }
                                </div>
                                : null}
                        </div>
                        <div style={{ ...styles.Block, width: dialogWidth - (6 * iconWidth) - avatar_size, height: titleHeight }}>
                            {isBusy == false ?
                                <div style={{ ...styles.BlockLeft, justifyContent: 'center', height: titleHeight }}>
                                    <div style={{ ...styles.BlockLeft, height: 30 }}>
                                        <p style={{ ...styles.TextLarge, fontWeight: '600' }}><span style={{ ...styles.TextTiny, fontWeight: '400' }}>{props.user.id == 117 ? data.id + ' ' : ''}</span>{data.name} {data.surname}</p>
                                    </div>
                                    <div style={{ ...styles.BlockRow, height: 60, marginTop: 5 }}>
                                        <div style={{ ...styles.BlockLeft, width: '60%', height: 55, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextSmall, marginTop: 0, textAlign: 'left' }}>{lang.mobil}: {data.mobil}</p>
                                            <p style={{ ...styles.TextSmall, marginTop: 2, textAlign: 'left' }}>{lang.email_}: {data.email}</p>
                                            {data.email_verifed == false ?
                                                <p style={{ ...styles.TextTiny, marginTop: 2, color: global.theme_dark_red }}><span><FontAwesomeIcon style={{ height: global.font_tiny, color: global.theme_dark_red }} icon={faExclamationTriangle} /></span> {lang.email_no_exist}</p>
                                                : null}
                                        </div>
                                        <div style={{ ...styles.BlockLeft, width: '40%', height: 50, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextSmall, marginTop: 0 }}>{lang.age}: {GetAge(data.birthday)} {lang.age_years}</p>
                                            <p style={{ ...styles.TextSmall, marginTop: 4 }}>{lang.points}: {data.score}</p>
                                        </div>
                                    </div>
                                </div>
                                : null}
                        </div>
                        {data.token == '' || data.token == null ?
                            <div style={{ ...styles.Block, width: iconWidth }}></div>
                            : null}

                        <div style={{ ...styles.Block, width: iconWidth, height: titleHeight }}>
                            {data.email != '' && data.email != null ?
                                <Tooltip title={lang.send_email}>
                                    <IconButton onClick={() => Message()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                                        <FontAwesomeIcon style={{ height: 16, color: global.themes[props.theme].theme_darker }} icon={faEnvelope} />
                                    </IconButton>
                                </Tooltip>
                                : null}
                        </div>
                        {data.token != '' && data.token != null ?
                            <div style={{ ...styles.Block, width: iconWidth, height: titleHeight }}>
                                <Tooltip title={lang.notify_send}>
                                    <IconButton onClick={() => Mobile()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                                        <FontAwesomeIcon style={{ height: 16, color: global.themes[props.theme].theme_darker }} icon={faMessage} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                            : null}
                        <div style={{ ...styles.Block, width: iconWidth, height: titleHeight }}>
                            <Tooltip title={lang.edit_data}>
                                <IconButton onClick={() => Settings()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.themes[props.theme].theme_darker }} icon={faPen} />
                                </IconButton>
                            </Tooltip>
                        </div>

                        <div style={{ ...styles.Block, width: iconWidth * 2, height: titleHeight }}>
                            <div style={{ ...styles.Block, width: iconWidth * 2, height: titleHeight / 2, justifyContent: 'flex-end' }}>
                                <Button onClick={() => AddService()} style={{ ...styles.ButtonWhite, width: iconWidth * 2 - 15, marginBottom: 6 }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginRight: 6 }} icon={faPlusCircle} />
                                    <p style={{ ...styles.TextTiny }}>{lang.service}</p>
                                </Button>
                            </div>
                            <div style={{ ...styles.Block, width: iconWidth * 2, height: titleHeight / 2, justifyContent: 'flex-start' }}>
                                <Button onClick={() => AddProduct()} style={{ ...styles.ButtonWhite, width: iconWidth * 2 - 15, marginTop: 6 }}>
                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginRight: 6 }} icon={faPlusCircle} />
                                    <p style={{ ...styles.TextTiny }}>{lang.product}</p>
                                </Button>
                            </div>
                        </div>

                        <div style={{ ...styles.Block, width: iconWidth, height: titleHeight }}>
                            <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                                <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>
                }
                <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight, backgroundColor: undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRow, height: dialogHeight - titleHeight }}>
                        {props.isSmall ?
                            <div style={{ ...styles.Block, height: selectHeight }}>
                                <Select
                                    value={index}
                                    onChange={event => ChangeIndex(event.target.value)}
                                    size='small'
                                    style={{ backgroundColor: global.theme_white, width: '100%', height: selectHeight }}
                                    MenuProps={{
                                        style: { zIndex: 10000 }
                                    }}
                                >
                                    <MenuItem disabled value={-1}>{lang.menu_title}</MenuItem>
                                    {gdprNotes == true ?
                                        <MenuItem value={0}>{lang.notes}</MenuItem>
                                        : null}
                                    <MenuItem value={10}>{lang.visits}</MenuItem>
                                    {gdprGallery == true ?
                                        <MenuItem value={1}>{lang.gallery}</MenuItem>
                                        : null}
                                    {gdprDiagnostic == true ?
                                        <MenuItem value={8}>{lang.diagnostics}</MenuItem>
                                        : null}
                                    <MenuItem value={2}>{lang.services}</MenuItem>
                                    <MenuItem value={3}>{lang.products}</MenuItem>
                                    <MenuItem value={4}>{lang.eshop}</MenuItem>
                                    <MenuItem value={5}>{lang.stats_sell}</MenuItem>
                                    <MenuItem value={6}>{lang.points}</MenuItem>
                                    <MenuItem value={7}>{lang.client_app}</MenuItem>
                                    <MenuItem value={11}>{lang.gdpr_}</MenuItem>
                                    <MenuItem value={9}>{lang.sum_to_pay}</MenuItem>
                                </Select>
                            </div>
                            :
                            <div style={{ ...styles.BlockCenter, width: menuWidth, height: dialogHeight - titleHeight, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                                <div style={{ ...styles.BlockCenter, width: menuWidth, height: dialogHeight - titleHeight - button_size - 20, overflowY: 'scroll' }}>
                                    {gdprNotes == true ?
                                        <Button onClick={() => ChangeIndex(0)} style={{ ...styles.ButtonSettings, justifyContent: 'flex-start', backgroundColor: index == 0 ? global.theme_back : '#00000000', color: index == 0 ? font_selected : font_disabled }}>
                                            <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faPen} />
                                            {lang.notes}
                                        </Button>
                                        : null}
                                    <Button onClick={() => ChangeIndex(10)} style={{ ...styles.ButtonSettings, justifyContent: 'flex-start', backgroundColor: index == 10 ? global.theme_back : '#00000000', color: index == 10 ? font_selected : font_disabled }}>
                                        <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faCalendarDays} />
                                        {lang.visits}
                                    </Button>
                                    {gdprGallery == true ?
                                        <Button onClick={() => ChangeIndex(1)} style={{ ...styles.ButtonSettings, justifyContent: 'flex-start', backgroundColor: index == 1 ? global.theme_back : '#00000000', color: index == 1 ? font_selected : font_disabled }}>
                                            <FontAwesomeIcon style={{ height: 12, marginRight: 10 }} icon={faPhotoFilm} />
                                            {lang.gallery}
                                        </Button>
                                        : null}
                                    {gdprDiagnostic == true ?
                                        <Button onClick={() => ChangeIndex(8)} style={{ ...styles.ButtonSettings, justifyContent: 'flex-start', backgroundColor: index == 8 ? global.theme_back : '#00000000', color: index == 8 ? font_selected : font_disabled }}>
                                            <FontAwesomeIcon style={{ height: 12, marginRight: 12 }} icon={faMagnifyingGlass} />
                                            {lang.diagnostics}
                                        </Button>
                                        : null}
                                    <div style={{ ...styles.Block, width: '100%', height: 1, background: global.themes[props.theme].theme_line, marginBottom: 10 }}></div>
                                    <Button onClick={() => ChangeIndex(2)} style={{ ...styles.ButtonSettings, justifyContent: 'flex-start', backgroundColor: index == 2 ? global.theme_back : '#00000000', color: index == 2 ? font_selected : font_disabled }}>
                                        <FontAwesomeIcon style={{ height: 6, marginRight: 15, marginLeft: 5 }} icon={faCircle} />
                                        {lang.services}
                                    </Button>
                                    <Button onClick={() => ChangeIndex(3)} style={{ ...styles.ButtonSettings, justifyContent: 'flex-start', backgroundColor: index == 3 ? global.theme_back : '#00000000', color: index == 3 ? font_selected : font_disabled }}>
                                        <FontAwesomeIcon style={{ height: 6, marginRight: 15, marginLeft: 5 }} icon={faCircle} />
                                        {lang.products}
                                    </Button>
                                    <Button onClick={() => ChangeIndex(4)} style={{ ...styles.ButtonSettings, justifyContent: 'flex-start', backgroundColor: index == 4 ? global.theme_back : '#00000000', color: index == 4 ? font_selected : font_disabled }}>
                                        <FontAwesomeIcon style={{ height: 6, marginRight: 15, marginLeft: 5 }} icon={faCircle} />
                                        {lang.eshop}
                                    </Button>
                                    <div style={{ ...styles.Block, width: '100%', height: 1, background: global.themes[props.theme].theme_line, marginBottom: 10 }}></div>
                                    <Button onClick={() => ChangeIndex(5)} style={{ ...styles.ButtonSettings, justifyContent: 'flex-start', backgroundColor: index == 5 ? global.theme_back : '#00000000', color: index == 5 ? font_selected : font_disabled }}>
                                        <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faChartBar} />
                                        {lang.stats_sell}
                                    </Button>
                                    <Button onClick={() => ChangeIndex(6)} style={{ ...styles.ButtonSettings, justifyContent: 'flex-start', backgroundColor: index == 6 ? global.theme_back : '#00000000', color: index == 6 ? font_selected : font_disabled }}>
                                        <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faStar} />
                                        {lang.points}
                                    </Button>
                                    <Button onClick={() => ChangeIndex(7)} style={{ ...styles.ButtonSettings, justifyContent: 'flex-start', backgroundColor: index == 7 ? global.theme_back : '#00000000', color: index == 7 ? font_selected : font_disabled }}>
                                        <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faMobile} />
                                        {lang.client_app}
                                    </Button>
                                    <Button onClick={() => ChangeIndex(11)} style={{ ...styles.ButtonSettings, justifyContent: 'flex-start', backgroundColor: index == 11 ? global.theme_back : '#00000000', color: index == 11 ? font_selected : font_disabled }}>
                                        <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faExclamationTriangle} />
                                        {lang.gdpr_}
                                    </Button>
                                </div>
                                <div style={{ ...styles.Block, width: menuWidth, height: button_size + 20, }}>
                                    <Button onClick={() => ChangeIndex(9)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, flexDirection: 'column', width: button_size + 20, height: button_size - 20, borderRadius: 10, color: global.theme_white }}>
                                        <FontAwesomeIcon style={{ height: 20, marginBottom: 10 }} icon={faCashRegister} />
                                        <p style={{ ...styles.TextTiny, color: global.theme_white, lineHeight: 1 }}>{lang.sum_to_pay}</p>
                                    </Button>
                                </div>
                            </div>
                        }
                        <div style={{ ...styles.BlockCenter, width: props.isSmall ? '100%' : dialogWidth - menuWidth, height: dialogHeight - titleHeight - selectHeight, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, }}>
                            {index == -1 ?
                                /*
                                *
                                * =================================================================================================================
                                * EMPTY
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.Block, width: dialogWidth - menuWidth, height: dialogHeight - titleHeight - selectHeight }}>
                                    <img src={client_card} style={{ width: '100%', height: '100%', maxWidth: 200, maxHeight: 200, objectFit: 'contain' }}></img>
                                </div>
                                : null}


                            {index == 0 ?
                                /*
                                *
                                * =================================================================================================================
                                * POZNAMKY
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight - selectHeight /*, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' */ }} /* className='container' */ >
                                    <div style={{ ...styles.Block, backgroundColor: global.theme_light_gray }}>
                                        <div style={{ ...styles.BlockRow, width: '98%', height: 120, paddingTop: 10 }}>
                                            <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                                <TextInput rows={3} placeholder={lang.note_default} redraw enabled={true} lang={lang} value={note} label={lang.note} func={(txt) => { setNote(txt) }} />
                                            </div>
                                            <div style={{ ...props.isSmall ? styles.Block : styles.BlockRight, width: '20%' }}>
                                                {props.isSmall ?
                                                    <IconButton onClick={() => AddNote()} style={{ width: 34, height: 34, backgroundColor: global.themes[props.theme].button_dark, marginTop: 20 }}>
                                                        <FontAwesomeIcon style={{ width: 10, color: global.theme_white }} icon={faPlus} />
                                                    </IconButton>
                                                    :
                                                    <Button onClick={() => AddNote()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, marginTop: 20 }}>
                                                        <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faPlus} />
                                                        {lang.add}
                                                    </Button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.BlockCenter, width: '98%', height: dialogHeight - titleHeight - 140, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        <div id={'id_scroll-0'} style={{ ...styles.Block, borderBottom: '1px solid ' + global.theme_light_gray, paddingBottom: 10 }}>
                                            <p style={{ ...styles.TextTiny, textAlign: 'left', marginTop: 10 }}>{data.note}</p>
                                        </div>
                                        <div style={{ ...styles.Block }}>
                                            {
                                                // ZOZNAM POZNÁMOK
                                                itemsFiltered != false ?
                                                    itemsFiltered.map((item, index) => (
                                                        <Paper elevation={item.id == overID ? 4 : 0} key={item.id} style={{ ...styles.BlockRow, paddingTop: 10, paddingBottom: 10, backgroundColor: global.theme_white, marginTop: 10, marginBottom: 10, borderRadius: props.radius / 2 }}
                                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                                        >
                                                            <div onClick={() => setShowEditNote(item)} style={{ ...styles.BlockLeft, width: '95%', cursor: 'pointer' }}>
                                                                <p style={{ ...styles.TextXTiny, marginLeft: 10, color: global.theme_medium_gray }}>{GetDatum(lang.months, item.date)}</p>
                                                                {item.id == showEditNote.id ?
                                                                    <TextInput rows={3} redraw enabled={true} lang={lang} value={showEditNote.text} label={''} func={(txt) => { showEditNote.text = txt }} />
                                                                    :
                                                                    <p style={{ ...styles.TextSmall, marginLeft: 10, textAlign: 'left', marginTop: 5 }}>{item.text}</p>
                                                                }
                                                            </div>
                                                            <div style={{ ...styles.Block, width: 70 }}>
                                                                {showEditNote.id == item.id ?
                                                                    <Button onClick={() => EditNote()} style={{ ...styles.ButtonLink, backgroundColor: global.theme_dark_green, width: 70, height: 30, borderRadius: 15 }}>
                                                                        <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.save}</p>
                                                                    </Button>
                                                                    :
                                                                    item.id == overID ?
                                                                        <IconButton onClick={() => AskDeleteNote(item)} style={{ width: 30, height: 30, backgroundColor: global.theme_light_gray }}>
                                                                            <FontAwesomeIcon style={{ height: 12, color: global.theme_dark_blue }} icon={faXmark} />
                                                                        </IconButton>
                                                                        : null
                                                                }
                                                            </div>
                                                        </Paper>
                                                    ))
                                                    :
                                                    <div style={{ ...styles.Block }}>
                                                        {isBusy == false ?
                                                            <EmptyList lang={lang} />
                                                            : null}
                                                    </div>
                                            }

                                            {itemsFiltered != false ?
                                                pageMax > 1 ?
                                                    <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                                        <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                                    </div>
                                                    : null
                                                : null}
                                        </div>
                                    </div>
                                </div>
                                : null}

                            {index == 10 ?
                                /*
                                *
                                * =================================================================================================================
                                * HISTORIA NAVSTEV
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockCenter, width: '98%', height: dialogHeight - titleHeight - selectHeight /*, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' */ }} /* className='container' */ >
                                    <div style={{ ...styles.BlockLeft, height: 60, justifyContent: 'center' }}>
                                        <p style={{ ...styles.TextLarge }}>{lang.visits}</p>
                                    </div>

                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight - 70, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        <div id={'id_scroll-10'} style={{ ...styles.Block }}>
                                            {
                                                // ZOZNAM NAVSTEV
                                                itemsFiltered != false ?
                                                    itemsFiltered.map((item, index) => (
                                                        <Paper elevation={item.id == overID ? 4 : 0} key={item.id} style={{ ...styles.Block, width: '98%', minHeight: rowHeight, backgroundColor: global.theme_light_gray, marginTop: 5, marginBottom: 5, borderRadius: rowHeight / 4, cursor: 'pointer', paddingTop: 5, paddingBottom: 5 }}
                                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                                        >
                                                            <div style={{ ...styles.BlockLeft, width: '95%' }}>
                                                                <div style={{ ...styles.BlockLeft, height: 30, justifyContent: 'center', borderBottom: '1px solid ' + global.theme_medium_gray }}>
                                                                    <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{GetDatum(lang.months, item.date)}</p>
                                                                </div>
                                                                <p style={{ ...styles.TextTiny, marginTop: 5, textAlign: 'left', color: global.theme_darker_gray }}><span style={{ fontWeight: '500', color: global.theme_black }}>{lang.services}:</span> {item.services}</p>
                                                                <p style={{ ...styles.TextTiny, marginTop: 5, textAlign: 'left', color: global.theme_darker_gray }}><span style={{ fontWeight: '500', color: global.theme_black }}>{lang.products}:</span> {item.products}</p>
                                                            </div>
                                                        </Paper>
                                                    ))
                                                    :
                                                    <div style={{ ...styles.Block }}>
                                                        {isBusy == false ?
                                                            <EmptyList lang={lang} />
                                                            : null}
                                                    </div>
                                            }

                                            {itemsFiltered != false ?
                                                pageMax > 1 ?
                                                    <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                                        <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                                    </div>
                                                    : null
                                                : null}
                                        </div>
                                    </div>
                                </div>
                                : null}


                            {index == 1 ?
                                /*
                                *
                                * =================================================================================================================
                                * GALERIA
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockCenter, width: '98%', height: dialogHeight - titleHeight - selectHeight /*, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' */ }} /* className='container' */ >
                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.BlockLeft, height: 60, justifyContent: 'center', width: '50%' }}>
                                            <p style={{ ...styles.TextLarge }}>{lang.gallery}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, height: 60, justifyContent: 'center' }}>
                                            <Button onClick={() => setShowAddPhoto(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>
                                                <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faPlus} />
                                                {lang.add}
                                            </Button>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight - 70, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        <div id={'id_scroll-1'} style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRowRaw, flexWrap: 'wrap' }}>
                                            {
                                                // ZOZNAM fotografii
                                                itemsFiltered != false ?
                                                    itemsFiltered.map((item, index) => (
                                                        <Paper key={item.id} elevation={item.id == overID ? 4 : 1} style={{ ...styles.BlockCenter, position: 'relative', width: gallerySize, height: gallerySize + 20, backgroundColor: global.theme_light_gray, margin: 10, borderRadius: props.radius / 2 }}
                                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                                        >
                                                            <img onClick={() => ShowGalleryPhoto(item)} src={GetImageLink(item.image)} style={{ width: '100%', height: '100%', maxWidth: gallerySize - 20, maxHeight: gallerySize - 30, marginTop: 10, objectFit: 'contain', cursor: 'pointer' }}></img>
                                                            <p style={{ ...styles.TextTiny, fontSize: 13, color: global.theme_dark_gray, marginTop: 10 }}>{GetDatum(lang.months, item.date)}</p>
                                                            {item.id == overID ?
                                                                <IconButton onClick={() => PhotoDelete(item)} style={{ width: 30, height: 30, position: 'absolute', top: 5, right: 5, backgroundColor: global.theme_black }}>
                                                                    <FontAwesomeIcon style={{ height: 12, color: global.theme_white }} icon={faXmark} />
                                                                </IconButton>
                                                                : null}
                                                        </Paper>
                                                    ))
                                                    :
                                                    <div style={{ ...styles.Block }}>
                                                        {isBusy == false ?
                                                            <EmptyList lang={lang} />
                                                            : null}
                                                    </div>
                                            }

                                            {itemsFiltered != false ?
                                                pageMax > 1 ?
                                                    <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                                        <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                                    </div>
                                                    : null
                                                : null}
                                        </div>
                                    </div>
                                </div>
                                : null}

                            {index == 2 ?
                                /*
                                *
                                * =================================================================================================================
                                * SLUŽBY
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockCenter, width: '98%', height: dialogHeight - titleHeight - selectHeight /*, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' */ }} /* className='container' */ >
                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.BlockLeft, height: 60, justifyContent: 'center', width: '50%' }}>
                                            <p style={{ ...styles.TextLarge }}>{lang.services}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, height: 60, justifyContent: 'center' }}>
                                            <Button onClick={() => AddService()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>
                                                <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faPlus} />
                                                {lang.add}
                                            </Button>
                                        </div>
                                    </div>

                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight - 70, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        <div id={'id_scroll-2'} style={{ ...styles.Block }}>
                                            {
                                                // ZOZNAM SLUZIEB
                                                itemsFiltered != false ?
                                                    itemsFiltered.map((item, index) => (
                                                        <Paper onClick={() => EditServiceNote(item)} elevation={item.id == overID ? 4 : 0} key={item.id} style={{ ...styles.Block, width: '98%', height: rowHeight, backgroundColor: global.theme_light_gray, marginTop: 5, marginBottom: 5, borderRadius: rowHeight / 2, cursor: 'pointer' }}
                                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                                        >
                                                            <div style={{ ...styles.BlockRow }}>
                                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                                    <img src={item.group_id == 1 ? service_1 : service_2} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                                                </div>
                                                                {props.isSmall ?
                                                                    <div style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                                        <p style={{ ...styles.TextXTiny, fontWeight: '400', color: global.theme_dark_gray, textAlign: 'left' }}>{GetDatum(lang.months, item.date)}</p>
                                                                        <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                                    </div>
                                                                    :
                                                                    <div style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                                        <div style={{ ...styles.BlockRow, borderBottom: item.note == '' ? '0px' : '1px solid ' + global.theme_medium_gray, paddingBottom: 5 }}>
                                                                            <div style={{ ...styles.BlockLeft, width: '60%' }}>
                                                                                <p style={{ ...styles.TextXTiny, fontWeight: '400', color: global.theme_dark_gray, textAlign: 'left' }}>{GetDatum(lang.months, item.date)}</p>
                                                                                <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                                            </div>
                                                                            <div style={{ ...styles.Block, width: '40%' }}>
                                                                                <p style={{ ...styles.TextTiny, fontWeight: '400', textAlign: 'left', marginTop: 15 }}>{lang.service_time_}: {item.time} {lang.minutes}</p>
                                                                            </div>
                                                                        </div>
                                                                        {item.note != '' ?
                                                                            <div style={{ ...styles.BlockLeft, width: '100%', marginTop: 5, height: 38, overflowY: 'hidden' }}>
                                                                                <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{item.note}</p>
                                                                            </div>
                                                                            : null}
                                                                    </div>
                                                                }
                                                                <div style={{ ...styles.Block, height: rowHeight, width: 120 }}>
                                                                    <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{GetPrice(item.eur)} {lang.money}</p>
                                                                </div>
                                                            </div>
                                                        </Paper>
                                                    ))
                                                    :
                                                    <div style={{ ...styles.Block }}>
                                                        {isBusy == false ?
                                                            <EmptyList lang={lang} />
                                                            : null}
                                                    </div>
                                            }

                                            {itemsFiltered != false ?
                                                pageMax > 1 ?
                                                    <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                                        <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                                    </div>
                                                    : null
                                                : null}
                                        </div>
                                    </div>
                                </div>
                                : null}


                            {index == 3 ?
                                /*
                                *
                                * =================================================================================================================
                                * PRODUKTY
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockCenter, width: '98%', height: dialogHeight - titleHeight - selectHeight /*, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' */ }} /* className='container' */ >
                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.BlockLeft, height: 60, justifyContent: 'center', width: '50%' }}>
                                            <p style={{ ...styles.TextLarge }}>{lang.products}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, height: 60, justifyContent: 'center' }}>
                                            <Button onClick={() => AddProduct()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>
                                                <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faPlus} />
                                                {lang.add}
                                            </Button>
                                        </div>
                                    </div>

                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight - 70, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        <div id={'id_scroll-3'} style={{ ...styles.Block }}>
                                            {
                                                // ZOZNAM PRODUKTOV
                                                itemsFiltered != false ?
                                                    itemsFiltered.map((item, index) => (
                                                        <Paper onClick={() => EditProductNote(item)} elevation={item.id == overID ? 4 : 0} key={item.id} style={{ ...styles.Block, width: '98%', height: rowHeight, backgroundColor: global.theme_light_gray, marginTop: 5, marginBottom: 5, borderRadius: rowHeight / 2, cursor: 'pointer' }}
                                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                                        >
                                                            <div style={{ ...styles.BlockRow }}>
                                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                                    <img src={item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                                                </div>
                                                                {props.isSmall ?
                                                                    <div style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                                        <p style={{ ...styles.TextXTiny, fontWeight: '400', color: global.theme_dark_gray, textAlign: 'left' }}>{GetDatum(lang.months, item.date)}</p>
                                                                        <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                                    </div>
                                                                    :
                                                                    <div style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                                        <div style={{ ...styles.BlockLeft, borderBottom: '1px solid ' + global.theme_medium_gray, paddingBottom: 5 }}>
                                                                            <div style={{ ...styles.BlockLeft, width: '60%' }}>
                                                                                <p style={{ ...styles.TextXTiny, fontWeight: '400', color: global.theme_dark_gray, textAlign: 'left' }}>{GetDatum(lang.months, item.date)}</p>
                                                                                <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ ...styles.BlockLeft, width: '100%', marginTop: 5 }}>
                                                                            <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.quantity}: {item.quantity}</p>
                                                                            {item.note != '' ?
                                                                                <div style={{ ...styles.BlockCenter, height: 17, marginTop: 2, overflowY: 'hidden' }}>
                                                                                    <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.note}: {item.note}</p>
                                                                                </div>
                                                                                : null}
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div style={{ ...styles.Block, height: rowHeight, width: 120 }}>
                                                                    <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{GetPrice(ConvertToPrice(parseFloat(item.eur) * parseInt(item.quantity)))} {lang.money}</p>
                                                                </div>
                                                            </div>
                                                        </Paper>
                                                    ))
                                                    :
                                                    <div style={{ ...styles.Block }}>
                                                        {isBusy == false ?
                                                            <EmptyList lang={lang} />
                                                            : null}
                                                    </div>
                                            }

                                            {itemsFiltered != false ?
                                                pageMax > 1 ?
                                                    <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                                        <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                                    </div>
                                                    : null
                                                : null}
                                        </div>
                                    </div>
                                </div>
                                : null}

                            {index == 4 ?
                                /*
                                *
                                * =================================================================================================================
                                * ESHOP
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockCenter, width: '98%', height: dialogHeight - titleHeight - selectHeight /*, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' */ }} /* className='container' */ >
                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.BlockLeft, height: 60, justifyContent: 'center' }}>
                                            <p style={{ ...styles.TextLarge }}>{lang.products_buy_eshop}</p>
                                        </div>
                                    </div>

                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight - 70, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        <div id={'id_scroll-4'} style={{ ...styles.Block }}>
                                            {
                                                // ZOZNAM PRODUKTOV
                                                itemsFiltered != false ?
                                                    itemsFiltered.map((item, index) => (
                                                        <Paper elevation={item.id == overID ? 4 : 0} key={item.id} style={{ ...styles.Block, width: '98%', height: rowHeight, backgroundColor: global.theme_light_gray, marginTop: 5, marginBottom: 5, borderRadius: rowHeight / 2 }}
                                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                                        >
                                                            <div style={{ ...styles.BlockRow }}>
                                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                                    <img src={item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                                                </div>
                                                                {props.isSmall ?
                                                                    <div style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                                        <p style={{ ...styles.TextXTiny, fontWeight: '400', color: global.theme_dark_gray, textAlign: 'left' }}>{GetDatum(lang.months, item.date)}</p>
                                                                        <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                                    </div>
                                                                    :
                                                                    <div style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                                        <div style={{ ...styles.BlockLeft, borderBottom: '1px solid ' + global.theme_medium_gray, paddingBottom: 5 }}>
                                                                            <div style={{ ...styles.BlockLeft, width: '60%' }}>
                                                                                <p style={{ ...styles.TextXTiny, fontWeight: '400', color: global.theme_dark_gray, textAlign: 'left' }}>{GetDatum(lang.months, item.date)}</p>
                                                                                <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div style={{ ...styles.BlockLeft, width: '100%', marginTop: 5 }}>
                                                                            <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.quantity}: {item.quantity}</p>
                                                                        </div>
                                                                    </div>}

                                                                <div style={{ ...styles.Block, height: rowHeight, width: 120 }}>
                                                                    <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{GetPrice(ConvertToPrice(parseFloat(item.sum)))} {lang.money}</p>
                                                                </div>
                                                            </div>
                                                        </Paper>
                                                    ))
                                                    :
                                                    <div style={{ ...styles.Block }}>
                                                        {isBusy == false ?
                                                            <EmptyList lang={lang} />
                                                            : null}
                                                    </div>
                                            }

                                            {itemsFiltered != false ?
                                                pageMax > 1 ?
                                                    <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                                        <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                                    </div>
                                                    : null
                                                : null}
                                        </div>
                                    </div>
                                </div>
                                : null}

                            {index == 5 ?
                                /*
                                *
                                * =================================================================================================================
                                * ŠTATISTIKA
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight - selectHeight /*, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' */ }} /* className='container' */ >
                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight }} >
                                        <Graph
                                            lang={props.lang}
                                            elevation={0}
                                            radius={0}
                                            year={true}
                                            title={lang.stats_sell}
                                            sub_title={''}
                                            width={props.isSmall ? dialogWidth - 10 : dialogWidth - menuWidth}
                                            height={320}
                                            typ={0}
                                            data={items}
                                            barWidth={props.isSmall ? 30 : 60}
                                            highColor={global.theme_red}
                                            overColor={global.theme_blue}
                                            showMax={false}
                                            lines={true}
                                            units={''}
                                            marginTop={0}
                                            selectable={true}
                                            show_value={true}
                                            legenda2={lang.services}
                                            legenda={lang.products}
                                            isSmall={props.isSmall}
                                            func={GraphResult.bind(this)}
                                        />

                                        <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight - 320 - 10, marginTop: 5, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                            <div id={'stats_start'} style={{ ...styles.Block }}></div>
                                            {
                                                // ZOZNAM PRODUKTOV / SLUZIEB
                                                statsData != false ?
                                                    statsData.map((item, index) => (
                                                        <Paper elevation={2} key={item.id} style={{ ...styles.Block, width: '98%', height: rowHeight * 0.75, backgroundColor: global.theme_light_gray, marginTop: 5, marginBottom: 5, borderRadius: rowHeight * 0.75 }}>
                                                            <div style={{ ...styles.BlockRow }}>
                                                                <div style={{ ...styles.BlockLeft, height: rowHeight * 0.75, width: rowHeight * 0.75, justifyContent: 'center' }}>
                                                                    {item.typ == 0 ?
                                                                        <img src={service_1} style={{ width: '100%', height: '100%', width: (rowHeight * 0.75) - 10, height: (rowHeight * 0.75) - 10, objectFit: 'contain', borderRadius: (rowHeight * 0.75) - 10, marginLeft: 5 }}></img>
                                                                        :
                                                                        <img src={item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', width: (rowHeight * 0.75) - 10, height: (rowHeight * 0.75) - 10, objectFit: 'contain', borderRadius: (rowHeight * 0.75) - 10, marginLeft: 5 }}></img>
                                                                    }
                                                                </div>
                                                                <div style={{ ...styles.BlockLeft, height: (rowHeight * 0.75), width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                                    <div style={{ ...styles.BlockLeft, borderBottom: '1px solid ' + global.theme_medium_gray, paddingBottom: 5 }}>
                                                                        <div style={{ ...styles.BlockLeft, width: '60%' }}>
                                                                            <p style={{ ...props.isSmall ? styles.TextTiny : styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ ...styles.BlockLeft, width: '100%', marginTop: 5 }}>
                                                                        <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{item.typ == 0 ? lang.quantity_ : lang.quantity}: {item.quantity}</p>
                                                                    </div>
                                                                </div>
                                                                <div style={{ ...styles.Block, height: (rowHeight * 0.75), width: 120 }}>
                                                                    <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{GetPrice(ConvertToPrice(item.sum))} {lang.money}</p>
                                                                </div>
                                                            </div>
                                                        </Paper>
                                                    ))
                                                    :
                                                    null}
                                        </div>

                                    </div>
                                </div>
                                : null}


                            {index == 6 ?
                                /*
                                *
                                * =================================================================================================================
                                * VERNOSTNÉ BODY
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockCenter, width: '98%', height: dialogHeight - titleHeight - selectHeight /*, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' */ }} /* className='container' */ >
                                    <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRow }}>
                                        {props.isSmall ? null :
                                            <div style={{ ...styles.BlockLeft, width: vouchers != false && parseInt(vouchersUsed) > 0 ? '60%' : '70%', height: 60, justifyContent: 'center' }}>
                                                <p style={{ ...styles.TextLarge }}>{lang.points}</p>
                                            </div>
                                        }

                                        <div style={{ ...styles.BlockRowRaw, width: props.isSmall ? '100%' : '50%', height: 70, justifyContent: 'center' }}>
                                            {showVoucherEdit == false ?
                                                <div style={{ ...styles.Block, width: props.isSmall ? '60%' : 300, height: 70, alignItems: 'flex-end' }}>
                                                    <p style={{ ...styles.TextSmall }}>{lang.client_points}: {data.score}</p>
                                                </div>
                                                : null}
                                            {showVoucherEdit == true ?
                                                <div style={{ ...styles.Block, width: 180, height: 70 }}>
                                                    <TextInput numeric redraw width={180} enabled={true} lang={lang} value={data.score} placeholder={lang.points} label={lang.points_edit} func={(txt) => { data.score = txt }} />
                                                </div>
                                                : null}
                                            <div style={{ ...styles.Block, width: 60, height: 70 }}>
                                                {showVoucherEdit == false ?
                                                    <IconButton onClick={() => setShowVoucherEdit(!showVoucherEdit)} style={{ width: 34, height: 34, backgroundColor: global.theme_dark }}>
                                                        <FontAwesomeIcon style={{ width: 12, color: global.theme_white }} icon={faPen} />
                                                    </IconButton>
                                                    :
                                                    <IconButton onClick={() => VoucherChange()} style={{ width: 34, height: 34, backgroundColor: global.theme_darker_green, marginTop: 15 }}>
                                                        <FontAwesomeIcon style={{ width: 12, color: global.theme_white }} icon={faCheck} />
                                                    </IconButton>
                                                }
                                            </div>
                                        </div>

                                        {vouchers != false && parseInt(vouchersUsed) > 0 ?
                                            <div style={{ ...styles.Block, width: '50%', height: 70, justifyContent: 'center', alignItems: 'flex-end' }}>
                                                <Button onClick={() => VoucherSave()} style={{ ...styles.ButtonRed, width: 180 }}>
                                                    {lang.bonus_use}
                                                </Button>
                                            </div>
                                            : null}
                                    </div>
                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight - selectHeight - 70, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        <div style={{ ...styles.BlockLeft, height: 120 }}>
                                            <div style={{ ...props.isSmall ? styles.Block : styles.BlockLeft, marginTop: 0 }}>
                                                {props.isSmall ? null :
                                                    <p style={{ ...styles.TextSmall, marginTop: 0 }}>{lang.client_vouchers}: {GetVoucher(data.score, props.user.bonus_points)}</p>
                                                }
                                                {vouchers != false ?
                                                    <div style={{ ...props.isSmall ? styles.Block : styles.BlockLeft, marginTop: 15 }}>
                                                        <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{lang.vouchers_used}: {vouchersUsed} {lang.vouchers_used_value} {GetPrice(GetVoucherPrice(vouchersUsed, props.user.bonus_price))} {lang.money}</p>
                                                        <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.bonus_click_to_use}</p>
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>
                                        <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRowRaw, flexWrap: props.isSmall ? 'nowrap' : 'wrap', height: dialogHeight - titleHeight - selectHeight - 70 - 120, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                            {vouchers != false ?
                                                vouchers.map((item, index) => (
                                                    <div onClick={() => VoucherUse(item)} key={item.id} style={{ margin: 10 }}>
                                                        <Voucher used={item.used} value={GetPrice(props.user.bonus_price)} lang={lang} />
                                                        <p style={{ ...styles.TextTiny }}>{item.used}</p>
                                                    </div>
                                                ))
                                                : null
                                            }

                                        </div>
                                    </div>
                                </div>
                                : null}

                            {index == 8 ?
                                /*
                                *
                                * =================================================================================================================
                                * DIAGNOSTIKA
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockCenter, width: '98%', height: dialogHeight - titleHeight - selectHeight /*, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' */ }} /* className='container' */ >
                                    <div style={{ ...styles.BlockRow }}>
                                        <div style={{ ...styles.BlockLeft, height: 60, justifyContent: 'center', width: '50%' }}>
                                            <p style={{ ...styles.TextLarge }}>{lang.diagnostics}</p>
                                        </div>
                                        <div style={{ ...styles.BlockRight, height: 60, justifyContent: 'center', width: '50%' }}>
                                            <Button onClick={() => Diagnose()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark }}>{lang.diagnose}</Button>
                                        </div>
                                    </div>

                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight - 70, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        <div id={'id_scroll-8'} style={{ ...styles.BlockCenter, backgroundColor: global.theme_white, paddingTop: 20, paddingBottom: 20, borderRadius: props.radius }}>
                                            <div style={{ ...styles.Block, width: props.isSmall ? '90%' : 500 }}>
                                                {
                                                    // ZOZNAM OTAZOK
                                                    items != false ?
                                                        items.map((item, index) => (
                                                            <div key={item.id} style={{ ...styles.BlockLeft }}>
                                                                <div style={{ ...styles.BlockLeft, justifyContent: 'center', backgroundColor: global.theme_light_gray, height: 40, borderRadius: 20 }}>
                                                                    <p style={{ ...styles.TextNormal, marginLeft: 20 }}>{item.question}:</p>
                                                                </div>
                                                                <div style={{ ...styles.Block, marginLeft: props.isSmall ? 10 : 100, marginBottom: 40 }}>
                                                                    {item.answers.map((answer) => (
                                                                        <div key={answer.id} style={{ ...styles.BlockLeft }}>
                                                                            <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{answer.answer}</p>}
                                                                                control={<Checkbox checked={answer.value == false ? false : true} size={'small'} onChange={(event) => { ChangeAnswer(answer, event.target.checked) }} />}
                                                                            />
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        ))
                                                        :
                                                        <div style={{ ...styles.Block }}>
                                                            {isBusy == false ?
                                                                <EmptyList lang={lang} />
                                                                : null}
                                                        </div>
                                                }
                                            </div>

                                            {itemsFiltered != false ?
                                                pageMax > 1 ?
                                                    <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                                        <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                                    </div>
                                                    : null
                                                : null}
                                        </div>
                                    </div>
                                </div>
                                : null}

                            {index == 9 ?
                                /*
                                *
                                * =================================================================================================================
                                * SUMA K ZAPLATENIU
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockCenter, width: '98%', height: dialogHeight - titleHeight - selectHeight /*, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' */ }} /* className='container' */ >
                                    <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRow }}>
                                        <div style={{ ...props.isSmall ? styles.Block : styles.BlockLeft, height: 60, justifyContent: 'center', width: props.isSmall ? '100%' : '50%' }}>
                                            <p style={{ ...props.isSmall ? styles.TextNormal : styles.TextLarge }}><b>{lang.sum_to_pay}</b> ({lang.price_dph.toLowerCase()})</p>
                                        </div>
                                        <div style={{ ...props.isSmall ? styles.Block : styles.BlockRight, height: 60, justifyContent: 'center', width: props.isSmall ? '100%' : '50%' }}>
                                            {itemsFiltered != false ?
                                                <div style={{ ...styles.Block, width: 250, backgroundColor: global.theme_lighter_red, height: 50, borderRadius: 25 }}>
                                                    <p style={{ ...styles.TextLarge, fontWeight: '600' }}>{GetPrice(ConvertToPrice(SumToPay(itemsFiltered)))} {lang.money}</p>
                                                </div>
                                                : null}
                                        </div>
                                    </div>

                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight - 70, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        <div id={'id_scroll-9'} style={{ ...styles.Block, marginTop: props.isSmall ? 30 : 0 }}>
                                            {
                                                // ZOZNAM ZAKUPENYCH
                                                itemsFiltered != false ?
                                                    itemsFiltered.map((item, index) => (
                                                        <Paper elevation={item.id == overID ? 4 : 0} key={item.id} style={{ ...styles.Block, width: '98%', height: rowHeight, backgroundColor: global.theme_light_gray, marginTop: 5, marginBottom: 5, borderRadius: rowHeight / 2, cursor: 'pointer' }}
                                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                                        >
                                                            <div style={{ ...styles.BlockRow }}>
                                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                                    <img src={item.typ == 3 ? voucher_sum : item.typ == 0 ? item.group_id == 1 ? service_1 : service_2 : item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                                                </div>
                                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                                    <p style={{ ...styles.TextXTiny }}>{item.typ == 3 ? '' : item.typ == 0 ? lang.service : lang.product}</p>
                                                                    <p style={{ ...styles.TextSmall, fontWeight: '600' }}>{item.typ == 3 ? lang.voucher : item.label}</p>
                                                                    {item.typ == 3 ?
                                                                        <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                                                            <Button onClick={() => VoucherDeletePress(item)} style={{ ...styles.ButtonDelete }}>{lang.delete}</Button>
                                                                        </div>
                                                                        :
                                                                        item.typ == 1 ?
                                                                            <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                                                                                <p style={{ ...styles.TextTiny }}>{lang.quantity + ': ' + item.quantity}</p>
                                                                                <p style={{ ...styles.TextTiny, marginTop: 2 }}>{lang.price_piece + ': ' + GetPrice(item.eur) + ' ' + lang.money}</p>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </div>
                                                                <div style={{ ...styles.Block, height: rowHeight, width: 120 }}>
                                                                    {GetPrice(ConvertToPrice(parseFloat(item.eur) * parseInt(item.quantity)))} {lang.money}
                                                                </div>
                                                            </div>
                                                        </Paper>
                                                    ))
                                                    :
                                                    <div style={{ ...styles.Block }}>
                                                        {isBusy == false ?
                                                            <EmptyList lang={lang} />
                                                            : null}
                                                    </div>
                                            }

                                            {itemsFiltered != false ?
                                                pageMax > 1 ?
                                                    <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                                        <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                                    </div>
                                                    : null
                                                : null}

                                            {itemsFiltered != false ?
                                                <div style={{ ...styles.Block, paddingBottom: 100, paddingTop: 20 }}>
                                                    < Button onClick={() => setAskFaktura(true)} style={{ ...styles.ButtonBlue, backgroundColor: global.themes[props.theme].button_dark, width: 200 }}>
                                                        {props.isSmall ? null :
                                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPen} />
                                                        }
                                                        <p style={{ ...styles.TextTiny, color: global.theme_white }}>
                                                            {lang.faktura_create}
                                                        </p>
                                                    </Button>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                </div>
                                : null}

                            {index == 7 ?
                                /*
                                *
                                * =================================================================================================================
                                * KLIENTSKA APLIKACIA
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight - selectHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    <div style={{ ...styles.Block, height: 50 }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <FontAwesomeIcon style={{ height: 30, color: global.theme_black }} icon={faMobileAlt} />
                                            <p style={{ ...styles.TextXLarge, fontWeight: '600', marginLeft: 16 }}>{lang.client_app_}</p>
                                        </div>
                                    </div>

                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight - selectHeight - 50 }}>
                                        {pin == '------' ?
                                            <div style={{ ...styles.Block, height: 100, marginTop: 40 }}>
                                                <p style={{ ...styles.TextNormal }}>{lang.pin_life_end}</p>
                                                <Button onClick={() => db_pin()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, backgroundColor: global.theme_dark_gray, width: 280, marginTop: 10 }}>
                                                    <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faRotateLeft} />
                                                    {lang.pin_generate}
                                                </Button>
                                            </div>
                                            :
                                            <div style={{ ...styles.Block, height: 100, marginTop: 40 }}>
                                                <p style={{ ...styles.TextNormal }}>{lang.pin}</p>
                                                <p style={{ ...styles.TextTiny }}>{lang.pin_life}</p>
                                                <p style={{ ...styles.TextXXLarge, marginTop: 20, letterSpacing: 10 }}>{pin}</p>
                                            </div>
                                        }
                                        {data.email != '' ?
                                            <div style={{ ...styles.Block, marginTop: 20 }}>
                                                <p style={{ ...styles.TextNormal }}>{lang.login_code_send}</p>
                                                <div style={{ ...styles.Block, height: 60 }}>
                                                    {loginSended == false ?
                                                        <Button onClick={() => db_login_code()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 200 }}>
                                                            {lang.send}
                                                            <FontAwesomeIcon style={{ height: 16, marginLeft: 10 }} icon={faPaperPlane} />
                                                        </Button>
                                                        :
                                                        <div style={{ ...styles.Block, width: 340 }}>
                                                            <FormOK color={global.theme_darkest_green} text={lang.email_sended}></FormOK>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                            : null}
                                        <div style={{ ...styles.Block, marginTop: 30, paddingBottom: 40 }}>
                                            <p style={{ ...styles.TextNormal }}>{linkID == 0 ? lang.link_iphone : lang.link_android}</p>
                                            <QRCode
                                                size={256}
                                                style={{ height: "auto", maxWidth: 160, width: 160, marginTop: 10 }}
                                                value={link}
                                                viewBox={`0 0 256 256`}
                                            />
                                            <div style={{ ...styles.BlockRowRaw, marginTop: 40 }}>
                                                <Button onClick={() => ChangeLink(0)} style={{ ...styles.ButtonDark, backgroundColor: linkID == 0 ? global.theme_lighter : global.theme_light_gray, width: 80, height: 80 }}>
                                                    <img src={apple} width={40} height={40}></img>
                                                </Button>
                                                {global.appstore_publishing == false ?
                                                    <Button onClick={() => ChangeLink(1)} style={{ ...styles.ButtonDark, backgroundColor: linkID == 1 ? global.theme_lighter : global.theme_light_gray, width: 80, height: 80, marginLeft: 40 }}>
                                                        <img src={android} width={40} height={40}></img>
                                                    </Button>
                                                    : null}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                : null}

                            {index == 11 ?
                                /*
                                *
                                * =================================================================================================================
                                * GDPR
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight - selectHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'  >
                                    <div style={{ ...styles.Block, height: 50 }}>
                                        <p style={{ ...styles.TextLarge, fontWeight: '600' }}>{lang.gdpr}</p>
                                    </div>
                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight - 50 }}>
                                        <div style={{ ...styles.Block, width: '90%' }}>
                                            <p style={{ ...styles.TextSmall }}>{lang.gdpr_doc}</p>
                                            <Button onClick={() => setShowGDPR(true)} style={{ ...styles.ButtonDark, backgroundColor: global.theme_dark_gray, width: 280, marginTop: 10 }}>
                                                <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faEye} />
                                                {lang.gdpr_read}
                                            </Button>
                                        </div>
                                        <div style={{ ...styles.Block, marginTop: 40, width: '90%' }}>
                                            <p style={{ ...styles.TextSmall }}>{lang.gdpr_agreement_doc}</p>
                                            <Button onClick={() => setShowGDPRAgreement(true)} style={{ ...styles.ButtonDark, backgroundColor: global.theme_dark_gray, width: 280, marginTop: 10 }}>
                                                <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faEye} />
                                                {lang.gdpr_read}
                                            </Button>
                                        </div>
                                        <div style={{ ...styles.Block, marginTop: 40, width: '90%' }}>
                                            <p style={{ ...styles.TextSmall }}>{lang.gdpr_settings_change}</p>
                                            <Button onClick={() => ChangeGDPR()} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, width: 280, marginTop: 10 }}>
                                                <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faEdit} />
                                                {lang.gdpr_settings_buttom}
                                            </Button>
                                        </div>

                                        <div style={{ ...styles.Block, width: '80%', marginTop: 40, paddingTop: 10, backgroundColor: global.theme_lighteen_red, paddingBottom: 20, borderRadius: props.radius }}>
                                            <p style={{ ...styles.TextLarge, fontWeight: '600' }}>{lang.export_data}</p>
                                            <p style={{ ...styles.TextSmall, fontWeight: '600', marginTop: 30 }}>{lang.gdpr_client_info}</p>
                                            <p style={{ ...styles.TextTiny, marginTop: 2 }}>{lang.gdpr_client_info_text}</p>
                                            {data.email != '' ?
                                                <Button onClick={() => setAskExportClient(true)} style={{ ...styles.ButtonLight, backgroundColor: global.themes[props.theme].theme_medium, width: 280, marginTop: 10 }}>
                                                    <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faPaperPlane} />
                                                    {lang.gdpr_export_client}
                                                </Button>
                                                :
                                                <div style={{ ...styles.Block, height: 40, marginTop: 10 }}>
                                                    <FontAwesomeIcon style={{ height: 24, color: global.theme_dark_red }} icon={faExclamationTriangle} />
                                                    <p style={{ ...styles.TextTiny, fontWeight: '600', color: global.theme_dark_red }}>{lang.gdpr_export_email_error}</p>
                                                </div>
                                            }

                                            <p style={{ ...styles.TextSmall, fontWeight: '600', marginTop: 30 }}>{lang.client_card}</p>
                                            <p style={{ ...styles.TextTiny, marginTop: 2 }}>{lang.gdpr_export_card_text}</p>
                                            <Button onClick={() => setAskExportCard(true)} style={{ ...styles.ButtonLight, backgroundColor: global.themes[props.theme].theme_medium, width: 280, marginTop: 10 }}>
                                                <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faSave} />
                                                {lang.gdpr_export_card}
                                            </Button>

                                            <p style={{ ...styles.TextSmall, fontWeight: '600', marginTop: 30 }}>{lang.gdpr_logs}</p>
                                            <p style={{ ...styles.TextTiny, marginTop: 2 }}>{lang.gdpr_show_log}</p>
                                            <div style={{ ...styles.Block }}>
                                                <Button onClick={() => GdprLog()} style={{ ...styles.ButtonLight, backgroundColor: global.themes[props.theme].theme_medium, width: 250, marginTop: 10, marginRight: 10 }}>
                                                    <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faEye} />
                                                    {lang.gdpr_show_gdpr_log}
                                                </Button>
                                            </div>
                                        </div>

                                        <p style={{ ...styles.TextSmall, fontWeight: '600', marginTop: 50 }}>{lang.gdpr_delete_card_title}</p>
                                        <p style={{ ...styles.TextTiny, marginTop: 2 }}>{lang.gdpr_delete_card_sub_title}</p>
                                        <div style={{ ...styles.BlockCenter, paddingBottom: 100 }}>
                                            <Button onClick={() => setShowCardDelete(true)} style={{ ...styles.ButtonRed, width: 250, marginTop: 10, marginRight: 10 }}>
                                                <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faExclamationTriangle} />
                                                {lang.gdpr_delete_card_button}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                : null}

                        </div>
                    </div>
                </div>
            </Paper >

            {
                showSettings == true ?
                    <DialogClient remoteApp={props.remoteApp} employees={props.employees} offset={props.offset} theme={props.theme} editing={true} user_id={props.user_id} user={props.user} client={data} lang={lang} radius={props.radius} title={''} sub_title={lang.client} backcolor={true} background={true} func={DialogClientResult.bind(this)} />
                    : null
            }

            {
                showPhoto == true ?
                    <DialogImagePreview theme={props.theme} file={GetImageLink(data.photo)} radius={props.radius} lang={lang} title={lang.preview} sub_title={data.name + ' ' + data.surname} background={global.theme_dark_gray} crop={false} func={() => setShowPhoto(false)} />
                    : null
            }

            {
                showGalleryPhoto == true ?
                    <DialogImagePreview theme={props.theme} file={GetImageLink(itemSelected.image)} radius={props.radius} lang={lang} title={lang.preview} sub_title={data.name + ' ' + data.surname} background={global.theme_dark_gray} crop={false} func={() => setShowGalleryPhoto(false)} />
                    : null
            }

            {
                showAddPhoto == true ?
                    <Photos full lang={props.lang} typ={4} max_size={1200} title={lang.photo_choose} sub_title={data.name + ' ' + data.surname} radius={props.radius} offset={props.offset} theme={props.theme} func={PhotosResult.bind(this)} />
                    : null
            }

            {
                showDeleteNote == true ?
                    <DialogYesNo theme={props.theme} radius={props.radius} title={lang.delete_note} sub_title={''} text={lang.delete_note_ask} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={DeleteNote.bind(this)} />
                    : null
            }

            {
                showDeletePhoto == true ?
                    <DialogYesNo theme={props.theme} radius={props.radius} title={lang.galery_delete} sub_title={''} text={lang.galery_delete_ask} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={PhotoDeleteResult.bind(this)} />
                    : null
            }

            {
                showService == true ?
                    <ServiceSelect isSmall={props.isSmall} remoteApp={props.remoteApp} employee_id={props.employee_id} employees={props.employees} note user={props.user} lang={lang} offset={props.offset} theme={props.theme} radius={props.radius} language={props.language} func={ServiceSelectResult.bind(this)} />
                    : null
            }

            {
                showServicesDiagnose == true ?
                    <ServiceSelect isSmall={props.isSmall} remoteApp={props.remoteApp} note title={lang.diagnose_services} items={servicesItems} employee_id={props.employee_id} employees={props.employees} user={props.user} lang={lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} func={ServiceSelectResult.bind(this)} />
                    : null
            }

            {
                showServiceNote == true ?
                    <AddServiceNote isSmall={props.isSmall} theme={props.theme} remoteApp={props.remoteApp} delete employees={props.employees} item={serviceSelected} lang={lang} radius={props.radius} title={'nazov'} sub_title={'podnázov'} backcolor={true} background={true} func={AddServiceNoteResult.bind(this)} />
                    : null
            }

            {
                showProduct == true ?
                    <ProductSelect isSmall={props.isSmall} remoteApp={props.remoteApp} employee_id={props.employee_id} employees={props.employees} note user={props.user} lang={lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} func={ProductSelectResult.bind(this)} />
                    : null
            }

            {
                showProductNote == true ?
                    <AddProductNote isSmall={props.isSmall} theme={props.theme} remoteApp={props.remoteApp} quantityOnly={false} delete stock employees={props.employees} item={productSelected} lang={lang} language={props.language} radius={props.radius} title={'nazov'} sub_title={'podnázov'} backcolor={true} background={true} func={AddProductNoteResult.bind(this)} />
                    : null
            }

            {
                showVoucherDelete == true ?
                    <DialogYesNo theme={props.theme} radius={props.radius} title={lang.bonus_delete} sub_title={''} text={lang.bonus_delete_ask} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={VoucherDelete.bind(this)} />
                    : null
            }

            {
                askExportClient == true ?
                    <DialogYesNo theme={props.theme} radius={props.radius} title={lang.gdpr_send} sub_title={''} text={lang.gdpr_send_question} sub_text={lang.gdpr_send_question_ + ' ' + data.email} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={ExportClientResult.bind(this)} />
                    : null
            }

            {
                askExportCard == true ?
                    <DialogYesNo theme={props.theme} radius={props.radius} title={lang.gdpr_export} sub_title={''} text={lang.gdpr_export_question} sub_text={lang.gdpr_export_question_ + ' ' + props.user.email} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={ExportCardResult.bind(this)} />
                    : null
            }

            {
                askExportGDPR == true ?
                    <DialogYesNo theme={props.theme} radius={props.radius} title={lang.gdpr_export} sub_title={''} text={lang.gdpr_export_ask} sub_text={lang.gdpr_export_question_ + ' ' + props.user.email} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={ExportGDPRResult.bind(this)} />
                    : null
            }

            {
                showEmail == true ?
                    <DialogEmail client={data} offset={props.offset} theme={props.theme} user_id={props.user.id} user={props.user} lang={lang} radius={props.radius} title={lang.send_email} sub_title={data.name + ' ' + data.surname} backcolor={true} icon={faQuestion} background={true} func={DialogEmailResult.bind(this)} />
                    : null
            }

            {
                showMobile == true ?
                    <DialogNotify remoteApp={props.remoteApp} client={data} offset={props.offset} theme={props.theme} user_id={props.user.id} user={props.user} lang={lang} radius={props.radius} title={lang.notify_send} sub_title={data.name + ' ' + data.surname} backcolor={true} icon={faQuestion} background={false} language={props.language} func={DialogNotifyResult.bind(this)} />
                    : null
            }

            {
                showExportOK == true ?
                    <DialogInfo theme={props.theme} radius={props.radius} title={lang.file_export} sub_title={''} text={lang.file_export_ok} icon={faInfoCircle} button={props.lang.close} icon_show={faCheckCircle} icon_color={global.theme_darker_green} func={() => setShowExportOK(false)} />
                    : null
            }

            {
                showExportError == true ?
                    <DialogInfo theme={props.theme} radius={props.radius} title={lang.file_export} sub_title={''} text={lang.file_export_error} icon={faInfoCircle} button={props.lang.close} icon_show={faXmarkCircle} icon_color={global.theme_dark_red} func={() => setShowExportError(false)} />
                    : null
            }


            {
                showGDPR == true ?
                    <DialogAgreements theme={props.theme} email={data.email == '' ? undefined : data.email} typ={5} lang={lang} user={props.user} client={data} radius={props.radius} sub_title={lang.vision_system} backcolor={true} background={false} func={() => setShowGDPR(false)} />
                    : null
            }

            {
                showGDPRAgreement == true ?
                    <DialogAgreements theme={props.theme} email={props.user.email} typ={4} lang={lang} user={props.user} client={data} radius={props.radius} sub_title={lang.vision_system} backcolor={true} background={false} func={() => setShowGDPRAgreement(false)} />
                    : null
            }

            {
                showGDPRSettings == true ?
                    <GDPRDetails isSmall={props.isSmall} theme={props.theme} data={GDPRSettings} lang={lang} radius={props.radius} func={GDPRDetailsResult.bind(this)} />
                    : null
            }

            {showGDPRDelete == true ?
                <DialogWarningYesNo theme={props.theme} center red confirm lang={props.lang} radius={props.radius} title={lang.gdpr_delete_card_title} sub_title={''} text={lang.gdpr_delete_card_text} sub_text={lang.gdpr_delete_card_text_} sub_text_2={lang.gdpr_delete_card_text_2} icon={faQuestion} yes={props.lang.agree} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={CardDelete.bind(this)} />
                : null}

            {showCardDelete == true ?
                <DialogWarningYesNo theme={props.theme} center red confirm lang={props.lang} radius={props.radius} title={lang.gdpr_delete_card_title} sub_title={''} text={lang.gdpr_delete_card_attention} sub_text={lang.gdpr_delete_card_text_} sub_text_2={lang.gdpr_delete_card_text_2} icon={faQuestion} yes={props.lang.agree} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={CardDelete.bind(this)} />
                : null}

            {showGDPRDeleteRecords == true ?
                <DialogWarningYesNo theme={props.theme} lang={props.lang} radius={props.radius} title={lang.gdpr_delete_card_items_title} sub_title={''} text={lang.gdpr_delete_items_label} sub_text={GDPRDeleteType.text} sub_text_2={lang.gdpr_delete_card_text_2} icon={faQuestion} yes={props.lang.agree} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={GdprDeleteRecords.bind(this)} />
                : null}

            {showGDPRLogs == true ?
                <DialogGdprLog isSmall={props.isSmall} user={props.user} client_id={data.id} lang={lang} radius={props.radius} offset={props.offset} theme={props.theme} func={DialogGdprLogResult.bind(this)} />
                : null}

            {askFaktura == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.faktura_create} sub_title={''} text={lang.faktura_create_ask} sub_text={lang.faktura_create_note} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={CreateFaktura.bind(this)} />
                : null}

            {showFakturaOK == true ?
                <DialogInfo radius={props.radius} title={lang.info} sub_title={lang.faktura} text={lang.faktura_created_success} sub_text={lang.faktura_created_success_note} icon={faQuestion} button={props.lang.close} icon_show={faCheckCircle} icon_color={global.theme_darker_green} theme={props.theme} func={() => setShowFakturaOK(false)} />
                : null}

            {showOK == true ?
                <ShowOK side={menuWidth} offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError theme={props.theme} text={props.lang.db_error} func={ShowErrorPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} side={menuWidth}></Loading>
                : null}

        </Backdrop >
    );
}
