/*
*
* =================================================================================================================
* STATISTIKA SALONOV - PODLA OBCHODNYCH ZASTUPCOV
* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { CategorySelect, Colors, ConvertToPrice, CopyData, Debug, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, FormatMoney, GetCountry, GetDate, GetImageLink, GetMoney, GetPrice, GetTodayYear, GetTodayYearMonth, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, SelectInput, SelectYear, ShowError, ShowOK, TemplateWindow, TextLines, Today } from './items';
import { styles } from './styles';
import { Backdrop, Button, CircularProgress, IconButton, InputAdornment, Pagination, Paper, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faArrowDown, faCheckCircle, faEdit, faL, faLayerGroup, faList, faListAlt, faPaperPlane, faQuestion, faSave, faShoppingBag, faShoppingBasket, faTrash, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';


export const StatsLessonsReps = (props) => {

    var lang = props.lang;

    const [isBusy, setBusy] = useState(false);

    const [items, setItems] = useState(false);
    const [actualYear, setActualYear] = useState(new Date().getFullYear());
    const [column, setColumn] = useState(-1);
    const [row, setRow] = useState(-1);
    const [agentID, setAgentID] = useState(0);
    const [agents, setAgents] = useState(false);


    // EXPORT STATISTIKY
    const [showExport, setShowExport] = useState(false);

    // OK
    const [showOK, setShowOK] = useState(false);

    const offset = 10;
    const cellWidth = 134;
    const cellHeight = 50;
    const titleHeight = 80;

    const no_image = require('./react/app/no_image.jpg');

    // Aktuálna farebna téma
    const color_theme = global.themes[props.theme];

    let { func } = props;

    var running = false;

    useEffect(() => {
        if (running == false) {

            let date = GetTodayYear();

            db_get(date, 0);
            db_get_data();

            running = true;
        }

    }, []);

    const db_get = async (year, agent_id) => {
        Debug(year);
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url_agent + 'stats_lessons_reps', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    admin: props.admin,
                    language: props.country.language_id,
                    agent_id: agent_id,
                    year: year
                })
            })

            const json = await response.json();

            Debug(json);
            if (json.ok > 0) {
                setItems(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_data = async () => {
        
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url_agent + 'stats_lessons_reps_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    admin: props.admin,
                    user_id: props.user.id,
                    language: props.country.language_id,
                })
            })

            const json = await response.json();

            Debug(json);
            if (json.ok > 0) {
                setAgents(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_export = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url_agent + 'stats_lessons_salons_export', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    typ: 1,
                    user_id: props.user.id,
                    admin: props.admin,
                    email: props.user.email,
                    items: items,
                    year: actualYear,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ) => {
        func(typ);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const SelectYearResult = (year, month) => {
        setActualYear(year);
        setAgentID(0);
        db_get(year)
    }


    const ExportResult = (value) => {
        setShowExport(false);

        if (value == true) {
            db_export();
        }
    }

    const ChangeAgent = (id) => {
        setAgentID(id);
        db_get(actualYear, id);
    }


    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                        <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                    </div>
                </div>
                <div style={{ ...styles.Block, width: '48%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: 45 }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                        <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                    </div>
                </div>
                <div style={{ ...styles.Block, width: 260, height: global.dialog_bar_height }}>
                    <Button onClick={() => setShowExport(true)} style={{ ...styles.ButtonDarkSmall, backgroundColor: global.themes[props.theme].theme_medium, color: global.theme_white, width: 160 }}>
                        <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPaperPlane} />
                        {lang.export}
                    </Button>
                </div>
                <div style={{ ...styles.Block, width: 250, height: global.dialog_bar_height, marginRight: 20 }}>
                    <SelectInput theme={props.theme} enabled={true} lang={lang} value={agentID} items={agents} field={'name'} label={lang.mobil} radius={global.radius} func={(txt) => ChangeAgent(txt)} />
                </div>
                <div style={{ ...styles.Block, width: 150, height: global.dialog_bar_height }}>
                    <SelectYear min={2020} value={actualYear} func={SelectYearResult.bind(this)} />
                </div>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Press()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, alignItems: 'flex-start', height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, overflow: 'scroll' }}>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple table">
                        <TableHead>
                            {items != false ? items.map((item, index) => (
                                index == 0 &&
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { borderRight: '1px solid #D0D0D0' } }}
                                >
                                    {item.map((value, i) => (
                                        <TableCell key={i} component="th" scope="row" >
                                            {value}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            )) : null}
                        </TableHead>
                        <TableBody>
                            {items != false ? items.map((item, index) => (
                                index > 0 &&
                                <TableRow
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { borderRight: '1px solid #D0D0D0' } }}
                                >
                                    {item.map((value, i) => (
                                        <TableCell key={i} onClick={() => { setRow(index == 0 ? -1 : index); setColumn(i == 0 ? -1 : i); }} component="th" scope="row" sx={{ backgroundColor: row == index ? global.theme_lighteen_blue : column == i ? global.theme_lighteen_blue : global.theme_white, textAlign: i == 0 ? 'left' : 'center', borderRight: '1px solid #D0D0D0', color: index == 0 || i == 0 ? global.theme_black : value == 1 ? global.theme_black : '#C0C0C0' }}>
                                            {index == 0 || i == 0 ? value : value == 1 ? lang.yes : lang.no}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            )) : null}
                        </TableBody>
                    </Table>
                </TableContainer>

            </div>


            {
                showExport == true ?
                    <DialogYesNo theme={props.theme} radius={props.radius} title={lang.export_stats} sub_title={''} text={lang.export_agent_data_ask} sub_text={lang.export_agent_data_text + ' ' + props.user.email} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={ExportResult.bind(this)} />
                    : null
            }

            {
                showOK == true ?
                    <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                    : null
            }

            {
                isBusy == true ?
                    <Loading offset={props.offset} theme={props.theme}  ></Loading>
                    : null
            }


        </Paper >
    );
}
