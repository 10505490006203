import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, ConvertToPrice, CopyData, Debug, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, GetDate, GetImageLink, GetPrice, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, ShowError, ShowOK, TemplateWindow, Today } from './items';
import { styles } from './styles';
import { Backdrop, Button, CircularProgress, IconButton, InputAdornment, Pagination, Paper, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCheckCircle, faEdit, faFilePdf, faL, faLayerGroup, faList, faListAlt, faQuestion, faSave, faShoppingBag, faShoppingBasket, faTrash, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Graph } from './graph';
import QRCode from "react-qr-code";

export const Ipad = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);

    const apple = require('./react/app/apple-logo.png');
    const android = require('./react/app/android-logo.png');

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;
        }

    }, []);


    const Press = () => {
        func(false);
    }


    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                        <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                    </div>
                </div>
                <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                    <div style={{ ...styles.BlockLeft, height: 45 }}>
                        <p style={{ ...styles.TextDialogLabel }}>{props.title}</p>
                        <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                    </div>
                </div>
                <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Press()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockCenter, width: props.width, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                    {/* *********************************************************************************************************  
                                BODY
                        ********************************************************************************************************* */}
                    <div style={{ ...styles.BlockCenter, paddingTop: 20 }}>
                        {index == 0 ?
                            <div style={{ ...styles.Block, width: '50%' }}>
                                <p style={{ ...styles.TextXXLarge, fontWeight: '600' }}>{lang.ipad_app_salon}</p>
                                {props.remoteApp == true ?
                                    <div style={{ ...styles.Block }}>
                                        <div style={{ ...styles.BlockCenter, height: 40 }}>
                                            <p style={{ ...styles.TextTiny }}>{lang.ipad_app_text}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 200, height: 200 }}>
                                            <QRCode
                                                size={256}
                                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                value={global.ipad_link}
                                                viewBox={`0 0 256 256`}
                                            />
                                        </div>
                                        <p style={{ ...styles.TextTiny, marginTop: 20 }}>{global.ipad_link}</p>
                                    </div>
                                    :
                                    <a href={global.ipad_link} style={{ ...styles.Block, textDecoration: 'none' }} target='_blank'>
                                        <div style={{ ...styles.BlockCenter, height: 40 }}>
                                            <p style={{ ...styles.TextTiny }}>{lang.ipad_app_text}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 200, height: 200 }}>
                                            <QRCode
                                                size={256}
                                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                value={global.ipad_link}
                                                viewBox={`0 0 256 256`}
                                            />
                                        </div>
                                        <p style={{ ...styles.TextTiny, marginTop: 20 }}>{global.ipad_link}</p>
                                    </a>
                                }
                            </div>
                            : null}
                        {index == 1 ?
                            <div style={{ ...styles.Block, width: '50%' }}>
                                <p style={{ ...styles.TextXXLarge, fontWeight: '600' }}>{lang.link_android}</p>
                                {props.remoteApp == true ?
                                    <div style={{ ...styles.Block }}>
                                        <div style={{ ...styles.BlockCenter, height: 40 }}>
                                            <p style={{ ...styles.TextTiny }}>{lang.android_app_text}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 200, height: 200 }}>
                                            <QRCode
                                                size={256}
                                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                value={global.android_link}
                                                viewBox={`0 0 256 256`}
                                            />
                                        </div>
                                        <p style={{ ...styles.TextTiny, marginTop: 20 }}>{global.android_link}</p>
                                    </div>
                                    :
                                    <a href={global.android_link} style={{ ...styles.Block, textDecoration: 'none' }} target='_blank'>
                                        <div style={{ ...styles.BlockCenter, height: 40 }}>
                                            <p style={{ ...styles.TextTiny }}>{lang.android_app_text}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 200, height: 200 }}>
                                            <QRCode
                                                size={256}
                                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                value={global.android_link}
                                                viewBox={`0 0 256 256`}
                                            />
                                        </div>
                                        <p style={{ ...styles.TextTiny, marginTop: 20 }}>{global.android_link}</p>
                                    </a>
                                }
                            </div>
                            : null}
                    </div>

                    <div style={{ ...styles.BlockRowRaw, marginTop: 50 }}>
                        <Button onClick={() => setIndex(0)} style={{ ...styles.ButtonDark, backgroundColor: index == 0 ? global.theme_lighter : global.theme_light_gray, width: 80, height: 80 }}>
                            <img src={apple} width={40} height={40}></img>
                        </Button>
                        {global.appstore_publishing == false ?
                            <Button onClick={() => setIndex(1)} style={{ ...styles.ButtonDark, backgroundColor: index == 1 ? global.theme_lighter : global.theme_light_gray, width: 80, height: 80, marginLeft: 40 }}>
                                <img src={android} width={40} height={40}></img>
                            </Button>
                            : null}
                    </div>

                    {global.appstore_publishing == false ?
                        <div style={{ ...styles.Block, height: 1, backgroundColor: global.theme_light_gray, marginTop: 80, marginBottom: 60 }}>
                            <p style={{ ...styles.TextXXLarge, fontWeight: '600' }}>{lang.web_application}</p>
                            <p style={{ ...styles.TextNormal, marginTop: 5 }}>{lang.web_link}</p>
                            {props.user.typ == global.account_agent ?
                                <div style={{ ...styles.Block, marginTop: 40 }}>
                                    <p style={{ ...styles.TextLarge, fontWeight: '600' }}>{lang.register_new_salon}</p>
                                    <p style={{ ...styles.TextNormal, marginTop: 5 }}>{lang.web_link_register}</p>
                                </div>
                                : null}
                        </div>
                        : null}

                </div>
            </div >

            {
                isBusy == true ?
                    <Loading offset={props.offset} theme={props.theme}  ></Loading>
                    : null
            }

        </Paper >
    );
}
