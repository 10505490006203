import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, ConvertToPrice, CopyData, Debug, DialogEnterText, DialogImagePreview, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, FormOK, GetDate, GetImageLink, GetMoney, GetPrice, GetTodayYear, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, SelectYear, ShowError, ShowOK, TemplateWindow } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, CircularProgress, FormControlLabel, IconButton, InputAdornment, MenuItem, Pagination, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faAt, faCheck, faCheckCircle, faComment, faEdit, faIdCard, faImage, faInfo, faInfoCircle, faL, faLayerGroup, faList, faListAlt, faListCheck, faPeace, faPen, faPenAlt, faPlusCircle, faQuestion, faQuestionCircle, faSave, faShare, faShoppingBag, faShoppingBasket, faShoppingCart, faTrash, faUser, faWarning, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';
import { ProductDialog, ProductPreview, ShoppingCard } from './product_dialog';
import { ClientSelect } from './client_select';
import { centerCrop } from 'react-image-crop';
import { ProductBookingCard } from './products_booking';
import { Photos } from './photos';
import { ProductSelect } from './product_select';
import { SalonCard } from './salon_card';
import { ProductBookingDetailsAgent } from './products_booking_agent';
import { BonusBookingDetailsAgent } from './bonus_booking_agent';


export const Reklamacie = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);
    const [money, setMoney] = useState('');

    // PREDAJ - ZOZNAM
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const itemsMax = 24;

    // NAHLAD
    const [itemSelected, setItemSelected] = useState(false);
    const [showOrder, setShowOrder] = useState(false);
    const [myOrders, setMyOrders] = useState(false);

    // AKTUALNY ROK - EXPORT
    const [actualYear, setActualYear] = useState(GetTodayYear());
    const [showExport, setShowExport] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [sended, setSended] = useState(false);

    const menuWidth = props.isSmall ? 0 : 250;
    const rowHeight = props.isSmall ? 120 : 80;
    const rowWidth = 850;
    const selectHeight = props.isSmall ? 40 : 0;

    // COLORS
    const font_selected = global.theme_black;
    const font_disabled = global.theme_darker;

    const no_image = require('./react/app/no_image.jpg');

    // Aktuálna farebna téma
    const color_theme = global.themes[props.theme];

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            let year = new Date().getFullYear();
            db_get(0, year);
        }

    }, []);

    const db_get = async (typ, year) => {
        setBusy(true);
        setItems(false);
        setItemsFiltered(false);
        try {
            const response = await fetch(
                global.db_url + 'reklamacie', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    admin: props.admin,
                    language: props.country.language_id,
                    typ: typ,
                    year: year
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                var tmp = json.items;

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_export = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'reklamacie_export', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    agent_id: props.admin == true ? 0 : props.user.id,
                    language: props.country.language_id,
                    year: actualYear,
                    email: props.user.email,
                    name: props.user.name + ' ' + props.user.surname,
                    lang: {
                        do_not_reply: lang.do_not_reply,
                        do_not_reply_text: lang.do_not_reply_text,

                        email: lang.email_,
                        mobil: lang.mobil.toLowerCase(),
                        firm: lang.settings_company,
                        ico: lang.ico,
                        dic: lang.dic,
                        ic_dph: lang.ic_dph,

                        status: lang.reklamacia_status,
                        created: lang.created,
                        date: lang.date,
                        label: lang.reklamacie_orders,
                        sub_label: actualYear,
                        code: lang.code_,
                        product_name: lang.product_name,
                        quantity: lang.quantity,
                        reason: lang.reklamacia_reason,
                        rep: lang.rep,
                        settings_company: lang.settings_company,
                        message: lang.reklamacia_message,
                        pieces: lang.pieces,
                        sum_to_pay: lang.sum_to_pay,
                        booking_number: lang.booking_number,
                        reklamacia_date: lang.date,
                        reklamacia_status: lang.booking_reklamacia_status,
                        products_list: lang.products_list,
                        photos: lang.photos,
                        reklamacia_number: lang.reklamacia_number,
                        reklamacia: lang.reklamacia,

                        dodaci_list_cislo: '',
                        faktura_cislo: lang.faktura_cislo,
                        faktura_date: lang.faktura_date,

                        email_subject: lang.reklamacie,
                        email_label: lang.reklamacie,
                        email_text: lang.reklamacie_export,
                    }
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
                setSended(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const Search = (text) => {
        setSearch(text);
        Redraw(items, 0, text, myOrders);
        /*
        if (text.trim().length > 2) {
            db_order_search(text.trim());
        }
        if (text == '') {
            setItems(false);
            setItemsFiltered(false);
            setIndex(-1);
        }
        */
    }

    const Redraw = (items, p, search, mine) => {
        // REDRAW PRODUKTOV
        var data = items;

        if (mine == true) {
            data = data.filter(x => x.agent_id == props.user.id);
        }

        if (search.trim() != '') {
            // filtrovanie podla hladaneho retazca
            var words = search.split(' ');
            data = data.filter(x => FindWords(x.number, GetDate(x.date), x.salon, words));
        }

        var count = data.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax)
        setItemsFiltered(data);

        if (count == 0) {
            setItemsFiltered(false);
        }

        setPage(p);
        GoToStart();
    }

    function FindWords(label, date, salon, words) {
        // hladanie retazca v nazve a keywords        
        var result = 0;
        words.forEach(word => {
            if (label.toLowerCase().includes(word.toLowerCase()) || salon.toLowerCase().includes(word.toLowerCase()) || date.toLowerCase().includes(word.toLowerCase())) {
                result += 1;
            }
        });
        // ak je pocet najdených slov == poctu hladaných slov -> je zhoda
        return words.length == result;
    }

    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);

        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0, '', myOrders);
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_, search, myOrders);
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_orders');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    // ------------------------------------------------------------------------------------
    // NAHLAD OBJEDNAVKY
    // ------------------------------------------------------------------------------------
    const Edit = (item) => {
        setItemSelected(item);
        setShowOrder(true);
    }

    const ReklamaciaResult = (value, value2) => {
        setShowOrder(false);
        if (value == true || value2 == true) {
            db_get(index, actualYear);
        }
    }

    const ChangeIndex = (id) => {
        setIndex(id);
        setSended(false);

        let year = new Date().getFullYear();
        setActualYear(year);

        if (id == 99) {

        } else {
            setSearch('');
            db_get(id, actualYear);
        }
    }

    const SelectYearResult = (year, month) => {
        setSended(false);
        setActualYear(year);
    }

    const ExportResult = (value) => {
        setShowExport(false);

        if (value == true) {
            db_export();
        }
    }


    const ChangeMyOrders = (value) => {
        setMyOrders(value);
        Redraw(items, 0, search, value);
    }

    const ChangeYear = (year, month) => {
        setActualYear(year)
        db_get(index, year);
    }


    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? '45%' : '50%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>
                        {props.isSmall ? null :
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        }
                    </div>
                </div>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, justifyContent: 'center', alignItems: 'flex-end', width: '20%', height: global.dialog_bar_height }}>
                        {props.admin == true && index != 99 ?
                            <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.show_mine}</p>}
                                control={<Checkbox checked={myOrders} size={'small'} onChange={() => ChangeMyOrders(!myOrders)} />}
                            />
                            : null}
                    </div>
                }
                <div style={{ ...styles.BlockRow, justifyContent: 'flex-end', alignItems: 'center', width: props.isSmall ? 160 : '30%', height: global.dialog_bar_height }}>
                    <TextField
                        value={search}
                        onInput={e => Search(e.target.value)}
                        size="small"
                        autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off"
                        style={{ width: props.isSmall ? 150 : 300 }}
                        placeholder={lang.search_in_category}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                            }
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                    <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                </IconButton>
                            </InputAdornment>,
                            style: { backgroundColor: global.theme_white, borderRadius: 30 },
                        }}
                        variant="outlined"
                    />
                </div>
                <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                    <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                        {props.isSmall ?
                            <div style={{ ...styles.Block, height: selectHeight }}>
                                <Select
                                    value={index}
                                    onChange={event => ChangeIndex(event.target.value)}
                                    size='small'
                                    style={{ backgroundColor: global.theme_white, width: '100%', height: selectHeight }}
                                    MenuProps={{
                                        style: { zIndex: 10000 }
                                    }}
                                >
                                    <MenuItem value={0}>{lang.reklamacie_open}</MenuItem>
                                    <MenuItem value={1}>{lang.reklamacie_close}</MenuItem>
                                    <MenuItem value={99}>{lang.reklamacie_export}</MenuItem>
                                </Select>
                            </div>
                            :
                            <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                                <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    <Button onClick={() => ChangeIndex(0)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == 0 ? global.theme_back : '#00000000', color: index == 0 ? font_selected : font_disabled }}>
                                        <div style={{ ...styles.BlockLeft }}>
                                            <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{lang.reklamacie_open}</p>
                                        </div>
                                    </Button>
                                    <Button onClick={() => ChangeIndex(1)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == 1 ? global.theme_back : '#00000000', color: index == 1 ? font_selected : font_disabled }}>
                                        <div style={{ ...styles.BlockLeft }}>
                                            <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{lang.reklamacie_close}</p>
                                        </div>
                                    </Button>
                                    <Button onClick={() => ChangeIndex(99)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == 99 ? global.theme_back : '#00000000', color: index == 99 ? font_selected : font_disabled }}>
                                        <div style={{ ...styles.BlockLeft }}>
                                            <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{lang.reklamacie_export}</p>
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        }
                        <div style={{ ...styles.BlockCenter, width: props.width - menuWidth, height: props.height - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>
                            {index == 99 ?
                                <div style={{ ...styles.Block }}>
                                    {/* *********************************************************************************************************  
                                            EXPORT REKLAMACII
                                    ********************************************************************************************************* */}
                                    <div style={{ ...styles.Block, height: 70 }}>
                                        <p style={{ ...styles.TextLarge }}>{lang.reklamacie_export}</p>
                                        <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.reklamacie_export_text}</p>
                                    </div>

                                    <div style={{ ...styles.Block, height: 70 }}>
                                        <p style={{ ...styles.TextTiny, marginBottom: 5 }}>{lang.year}</p>
                                        <SelectYear min={2020} value={actualYear} func={SelectYearResult.bind(this)} />
                                    </div>
                                    <div style={{ ...styles.Block, marginTop: 40 }}>
                                        {sended == true ?
                                            <div style={{ ...styles.Block, width: 400 }}>
                                                <FormOK text={lang.email_sended} small />
                                            </div>
                                            : <Button onClick={() => setShowExport(true)} style={{ ...styles.ButtonDark, width: 220 }}>
                                                <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faShare} />
                                                {lang.export}
                                            </Button>

                                        }
                                    </div>
                                </div>
                                :
                                < div id={'id_orders'} style={{ ...styles.BlockCenter, width: '98%' }}>

                                    {index == 1 ?
                                        <div style={{ ...styles.BlockRight, maxWidth: rowWidth }}>
                                            <div style={{ ...styles.BlockRowRaw }}>
                                                <div style={{ ...styles.Block, height: 60 }}>
                                                    <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.reklamacie_year_list}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 200, height: 60 }}>
                                                    <SelectYear min={2020} value={actualYear} func={ChangeYear.bind(this)} />
                                                </div>
                                            </div>
                                        </div>
                                        : null}

                                    {/* *********************************************************************************************************  
                                            ZOZNAM REKLAMACII
                                    ********************************************************************************************************* */}

                                    {itemsFiltered != false ?
                                        itemsFiltered.map((item, index) => (
                                            <Paper elevation={overID == item.id ? 5 : 2} key={item.id} style={{ ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: global.theme_gray, marginTop: 8, marginBottom: 8, borderRadius: global.row_radius, cursor: 'pointer' }}
                                                onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                            >
                                                <div style={{ ...styles.BlockRow }}>
                                                    <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: props.isSmall ? 50 : rowHeight, justifyContent: 'center' }}>
                                                        <div style={{ ...styles.Block, maxHeight: rowWidth }}>
                                                            <div style={{ ...styles.Block, height: 36, width: 36, backgroundColor: global.reklamacia_back_color[item.status], borderRadius: '50%' }}>
                                                                <FontAwesomeIcon style={{ height: 16, color: global.theme_black, color: global.reklamacia_color[item.status] }} icon={global.reklamacia_icon[item.status]} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {props.isSmall ?
                                                        <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                            <p style={{ ...styles.TextTinyMobile, fontWeight: '600', textAlign: 'left', marginBottom: 10 }}>{item.typ == 0 ? lang.reklamacia_products : lang.reklamacia_bonus} <span style={{ fontSize: global.font_xtiny, fontWeight: '400' }}>{item.number}</span></p>
                                                            {item.salon != false ?
                                                                <p style={{ ...styles.TextTinyMobile, fontWeight: '400', textAlign: 'left' }}>{item.salon}</p>
                                                                : null}
                                                            <p style={{ ...styles.TextTiny }}>{GetDate(item.date)}</p>
                                                            {props.admin == true && myOrders == false ?
                                                                <p style={{ ...styles.TextTiny }}>{lang.rep}: {item.agent}</p>
                                                                : null}
                                                            {item.reklamacia == true ?
                                                                <p style={{ ...styles.TextTiny, color: global.theme_dark_red, fontWeight: '600', marginTop: 10 }}>{lang.booking_reklamacia_true}</p>
                                                                :
                                                                <p style={{ ...styles.TextTiny, fontWeight: '600', marginTop: 10 }}>{lang.reklamacia_status[item.status]}</p>
                                                            }
                                                        </div>
                                                        :
                                                        <div onClick={() => Edit(item)} style={{ ...styles.BlockLeft, height: rowHeight, width: '85%', justifyContent: 'center', marginLeft: 10 }}>
                                                            <div style={{ ...styles.BlockRow }}>
                                                                <div style={{ ...styles.BlockLeft, width: '30%' }}>
                                                                    <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.typ == 0 ? lang.reklamacia_products : lang.reklamacia_bonus}</p>
                                                                    <p style={{ ...styles.TextTiny, fontWeight: '400', textAlign: 'left' }}>{item.number}</p>
                                                                </div>
                                                                <div style={{ ...styles.BlockRight, width: '70%' }}>
                                                                    {item.salon != false ?
                                                                        <p style={{ ...styles.TextTiny, fontWeight: '400', textAlign: 'left' }}>{item.salon}</p>
                                                                        : null}
                                                                </div>

                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                                <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                                    <div style={{ ...styles.BlockLeft, width: '30%' }}>
                                                                        <div style={{ ...styles.BlockRowRaw }}>
                                                                            <p style={{ ...styles.TextTiny }}>{GetDate(item.date)}</p>
                                                                            {props.admin == true && myOrders == false ?
                                                                                <p style={{ ...styles.TextTiny, marginLeft: 10 }}>{item.agent}</p>
                                                                                : null}
                                                                        </div>

                                                                    </div>
                                                                    <div style={{ ...styles.Block, width: '30%' }}>
                                                                    </div>
                                                                    <div style={{ ...styles.BlockRight, width: '40%' }}>
                                                                        {item.reklamacia == true ?
                                                                            <p style={{ ...styles.TextTiny, color: global.theme_dark_red, fontWeight: '600' }}>{lang.booking_reklamacia_true}</p>
                                                                            :
                                                                            <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.reklamacia_status[item.status]}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </Paper>
                                        )) :
                                        // ZIADNE OBJEDNAVKY                            
                                        <div style={{ ...styles.Block, paddingTop: 20 }}>
                                            <EmptyList lang={lang} />
                                        </div>
                                    }

                                    {itemsFiltered != false ?
                                        pageMax > 1 ?
                                            <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                                <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                            </div>
                                            : null
                                        : null}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {showOrder == true ?
                <ReklamaciaDetailsAgent isSmall={props.isSmall} offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} remoteApp={props.remoteApp} admin={props.admin} countries={props.countries} country={props.country} reklamacia_id={itemSelected.id} lang={lang} radius={props.radius} backcolor={true} background={true} language={props.language} func={ReklamaciaResult.bind(this)} />
                : null}

            {showExport == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.reklamacie_export} sub_title={''} text={lang.export_agent_data_ask} sub_text={lang.export_agent_data_text + ' ' + props.user.email} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={ExportResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK side={menuWidth} offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading side={menuWidth} offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Paper >
    );
}


export const ReklamaciaDetailsAgent = (props) => {


    const [isBusy, setBusy] = useState(false);
    const [order, setOrder] = useState(false);
    const [showOrder, setShowOrder] = useState(false);
    const [reklamaciaStatus, setReklamaciaStatus] = useState(0);
    const [items, setItems] = useState(false);
    const [changes, setChanges] = useState(false);
    const [money, setMoney] = useState('');
    const [showOK, setShowOK] = useState(false);

    // UDAJE O ZAKAZNIKOVI
    const [salon, setSalon] = useState(false);

    // Reklamacia
    const [reklamacia, setReklamacia] = useState(false);
    const [enterText, setEnterText] = useState(false);
    const [showReasonEdit, setShowReasonEdit] = useState(false);

    // Obrázky
    const [images, setImages] = useState(false);
    const [image, setImage] = useState(false);
    const [showImage, setShowImage] = useState(false);

    // Suma
    const [sum, setSum] = useState(0);

    // cislo objednavky
    const [number, setNumber] = useState('');

    // zobraziť kartu salóna
    const [showSalonCard, setShowSalonCard] = useState(false);

    // Komentár k reklamácii
    const [showComment, setShowComment] = useState(false);
    const [comment, setComment] = useState('');

    // zmena stavu
    const [showStatusChange, setShowStatusChange] = useState(false);
    const [statusTMP, setStatusTMP] = useState(0);

    // Export objednávky
    const [showExport, setShowExport] = useState(false);

    // kontrola zmeny
    const [changed, setChanged] = useState(false);

    // Rozmery okna
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1200 ? 1200 : width;
    const dialogHeight = height >= 850 ? 850 : height;

    const infoHeight = 34;
    const buttonHeight = 100;
    const rowHeight = 100;
    const labelHeight = 180;
    const imageSize = 275;
    const rowOffset = 35;

    const no_image = require('./react/app/no_image.jpg');


    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            db_get();

        }
        return () => {
        };

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'reklamacia', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.language,
                    reklamacia_id: props.reklamacia_id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setItems(json.products);
                setReklamacia(json.reklamacia);
                setReklamaciaStatus(json.reklamacia.status);
                setNumber(json.reklamacia.number);
                setSalon(json.salon);
                setImages(json.images);
                setOrder(json.order);
                setComment(json.reklamacia.comment);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    // ZMENA STAVU REKLAMACIE - STATUS
    const db_update = async (data, status) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'reklamacia_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                    lang: {
                        notify_title: lang.booking_reklamacia_order,
                        notify_text: lang.booking_reklamacia_notify + ' ' + (status > 0 ? lang.reklamacia_status[status].toLowerCase() : ''),
                    }
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setReklamaciaStatus(data.status);
                setChanges(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    // UPRAVA REKLAMACIE - COMMENT,....
    const db_update_data = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'reklamacia_update_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_export = async (email) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'reklamacia_export', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    reklamacia_id: reklamacia.id,
                    user_id: props.user.id,
                    language: props.country.language_id,
                    email: email,
                    sum: sum,
                    lang: {
                        do_not_reply: lang.do_not_reply,
                        do_not_reply_text: lang.do_not_reply_text,

                        email: lang.email_,
                        mobil: lang.mobil.toLowerCase(),
                        firm: lang.settings_company,
                        ico: lang.ico,
                        dic: lang.dic,
                        ic_dph: lang.ic_dph,
                        contact: lang.contact,

                        status: lang.reklamacia_status,
                        created: lang.created,
                        date: lang.date,
                        label: reklamacia.typ == 0 ? lang.reklamacia_products : lang.reklamacia_bonus,
                        sub_label: number,
                        code: lang.code_,
                        product_name: lang.product_name,
                        quantity: lang.quantity,
                        reason: lang.reklamacia_reason,
                        rep: lang.rep,
                        settings_company: lang.settings_company,
                        message: lang.reklamacia_message,
                        money: money,
                        cost: lang.cost,
                        pieces: lang.pieces,
                        sum_to_pay: lang.sum_to_pay,
                        booking_number: lang.booking_number,
                        reklamacia_date: lang.reklamacia_date,
                        reklamacia_status: lang.booking_reklamacia_status,
                        products_list: lang.products_list,
                        photos: lang.photos,
                        reklamacie_deny_reason: lang.reklamacie_deny_reason,
                        faktura_address: lang.faktura_address.toUpperCase(),
                        deliver_address: lang.deliver_address.toUpperCase(),
                        dodavatel: lang.dodavatel,
                        prevadzka_nazov: lang.prevadzka_nazov,
                        company_label: lang.label,
                        address: lang.address,
                        zodpovedna_osoba: lang.zodpovedna_osoba,
                        reklamacia_number: lang.reklamacia_number,
                        reklamacia: lang.reklamacia,

                        dodaci_list_cislo: lang.dodaci_list_cislo,
                        faktura_cislo: lang.faktura_cislo,
                        faktura_date: lang.faktura_date,

                        email_subject: lang.reklamacia,
                        email_label: reklamacia.typ == 0 ? lang.reklamacia_products : lang.reklamacia_bonus,
                        email_text: lang.reklamacia_email,
                    }
                })
            })

            const json = await response.json();
Debug(json);

            if (json.ok > 0) {
                setShowOK(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = () => {
        var redraw = false;
        if (reklamaciaStatus != reklamacia.status) {
            redraw = true;
        }
        if (changed == true) {
            redraw = true;
        }
        func(redraw);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const ChangeStatus = (status) => {
        let data = {
            id: reklamacia.id,
            status: status,
        }

        db_update(data, status);
    }

    const ChangeStatusAsk = (status) => {
        setStatusTMP(status);

        if (status == 3) {
            setEnterText(true);
        } else {
            setEnterText(false);
            setShowStatusChange(true);
        }

    }

    const ChangeStatusResult = (value) => {
        setShowStatusChange(false);

        if (value == true) {
            ChangeStatus(statusTMP);
        }
    }

    const DialogEnterTextResult = (value, text) => {
        setEnterText(false);
        if (value == true) {
            let data = {
                id: reklamacia.id,
                status: statusTMP,
                deny_reason: text
            }
            db_update(data, statusTMP);
        }

    }

    const ReasonResult = (value, text) => {
        setShowReasonEdit(false);
        if (value == true) {
            let data = {
                id: reklamacia.id,
                deny_reason: text
            }
            reklamacia.deny_reason = text;
            db_update(data, 0);
        }

    }


    const ExportResult = (value, email) => {
        setShowExport(false);
        if (value == true) {
            if (email.trim() != '') {
                db_export(email);
            }
        }
    }

    const Preview = (item) => {
        let data = {
            file: item.path + item.file
        }
        setImage(data);
        setShowImage(true);
    }

    const CommentResult = (typ, text) => {
        setShowComment(false);

        if (typ == true) {
            let data = {
                id: reklamacia.id,
                comment: text
            }
            setComment(text);

            db_update_data(data);
        }
    }

    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: labelHeight, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    {props.isSmall ?
                        <div onClick={() => setShowSalonCard(true)} style={{ ...styles.BlockLeft, width: '90%', height: labelHeight, marginLeft: 10, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextTinyMobile, fontWeight: '600' }}>{reklamacia != false ? reklamacia.typ == 0 ? lang.reklamacia_products : lang.reklamacia_bonus : null}</p>
                            <p style={{ ...styles.TextTinyMobile, marginTop: 2, textAlign: 'left' }}>{lang.reklamacia_number}: <b>{number}</b></p>

                            <p style={{ ...styles.TextTinyMobile, marginTop: 10 }}>{salon.name} {salon.surname}</p>
                            <p style={{ ...styles.TextTinyMobile, marginTop: 0 }}>{salon.street} {salon.street_number}, {salon.psc} {salon.town}</p>
                            <p style={{ ...styles.TextTinyMobile, marginTop: 4 }}>{lang.email_}: {salon.email}</p>
                            <p style={{ ...styles.TextTinyMobile, marginTop: 0 }}>{lang.mobil}: {salon.mobil}</p>
                        </div>
                        : null}
                    {props.isSmall ? null :
                        <div style={{ ...styles.BlockCenter, width: 100, height: labelHeight }}>
                            <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10, marginTop: 23 }} >
                                <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faShoppingCart} />
                            </div>
                        </div>
                    }
                    {props.isSmall ? null :
                        <div onClick={() => setShowSalonCard(true)} style={{ ...styles.Block, width: '40%', height: labelHeight }}>
                            <div style={{ ...styles.BlockLeft, height: labelHeight - 20, justifyContent: 'center' }}>
                                <p style={{ ...styles.TextDialogLabel }}>{reklamacia != false ? reklamacia.typ == 0 ? lang.reklamacia_products : lang.reklamacia_bonus : null}</p>
                                <p style={{ ...styles.TextTiny, marginTop: 2 }}>{lang.reklamacia_number}: <b>{number}</b></p>
                                <p style={{ ...styles.TextTiny }}>{lang.reklamacia_date}: <b>{reklamacia != false ? GetDate(reklamacia.date) : ''}</b></p>
                                <div style={{ ...styles.BlockRow }}>
                                    <div style={{ ...styles.Block, width: 50 }}>
                                        <div onClick={() => setShowSalonCard(true)} style={{ ...styles.Block, width: 50, height: 70, marginTop: 14, backgroundColor: global.themes[props.theme].theme_medium, borderRadius: 5 }}>
                                            <FontAwesomeIcon style={{ height: 28, color: global.theme_white }} icon={faIdCard} />
                                            <p style={{ ...styles.TextXXTiny, color: global.theme_white, marginTop: 5 }}>{lang.card}</p>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.BlockLeft, marginLeft: 10 }}>
                                        <p style={{ ...styles.TextTiny, marginTop: 14 }}>{salon.name} {salon.surname}</p>
                                        <p style={{ ...styles.TextTiny, marginTop: 0 }}>{salon.street} {salon.street_number}, {salon.psc} {salon.town}</p>
                                        <p style={{ ...styles.TextTiny, marginTop: 4 }}>{lang.email_}: {salon.email}</p>
                                        <p style={{ ...styles.TextTiny, marginTop: 0 }}>{lang.mobil}: {salon.mobil}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    }
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: '30%', height: labelHeight }}>
                            <div style={{ ...styles.BlockLeft, justifyContent: 'center' }}>
                                <p style={{ ...styles.TextTiny, marginTop: 0, textAlign: 'left' }}>{lang.settings_company}:</p>
                                <p style={{ ...styles.TextTiny, marginTop: 5, textAlign: 'left' }}>{salon.company}</p>
                                <p style={{ ...styles.TextTiny, marginTop: 0 }}>{salon.street} {salon.street_number}</p>
                                <p style={{ ...styles.TextTiny, marginTop: 0 }}>{salon.psc} {salon.town}</p>
                                <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.ico}: {salon.ico}</p>
                                {salon.language == 0 ?
                                    <p style={{ ...styles.TextTiny, marginTop: 0 }}>{lang.dic}: {salon.dic}</p>
                                    : null}
                                <p style={{ ...styles.TextTiny, marginTop: 0 }}>{lang.ic_dph}: {salon.ic_dph}</p>
                            </div>
                        </div>
                    }
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: 240, height: labelHeight }}>
                            <div style={{ ...styles.Block, height: labelHeight - 10, backgroundColor: global.theme_white, borderRadius: props.radius }}>
                                <p style={{ ...styles.TextTiny }}>{lang.reklamacia_reason}:</p>
                                <p style={{ ...styles.TextTiny, fontWeight: '600', marginTop: 1 }}>{reklamacia.reason}</p>
                                <div onClick={() => setShowOrder(true)} style={{ ...styles.Block, marginTop: 5, cursor: 'pointer' }}>
                                    <p style={{ ...styles.TextTiny }}>{lang.dodaci_list_cislo}:</p>
                                    <p style={{ ...styles.TextTiny, fontWeight: '600', marginTop: 1 }}>{reklamacia.dodaci_list}</p>
                                    <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.faktura_cislo}:</p>
                                    <p style={{ ...styles.TextTiny, fontWeight: '600', marginTop: 1 }}>{reklamacia.faktura_cislo}</p>
                                    <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.faktura_date}:</p>
                                    <p style={{ ...styles.TextTiny, fontWeight: '600', marginTop: 1 }}>{reklamacia.faktura_date != null ? GetDate(reklamacia.faktura_date) : ''}</p>
                                </div>
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.BlockCenter, width: props.isSmall ? 150 : 200, height: labelHeight }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            <IconButton onClick={() => setShowExport(true)} style={{ width: 36, height: 36, backgroundColor: global.theme_white, marginTop: 15, marginRight: props.isSmall ? 20 : 36 }}>
                                <FontAwesomeIcon style={{ width: 16, color: global.themes[props.theme].theme_darker }} icon={faShare} />
                            </IconButton>

                            <IconButton onClick={() => Press(false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white, marginTop: 15 }}>
                                <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - labelHeight, backgroundColor: props.background == true ? global.theme_back : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '100%', height: dialogHeight - labelHeight - buttonHeight }}>
                        {/* BODY */}
                        <div style={{ ...styles.BlockCenter, width: '100%', height: dialogHeight - global.dialog_bar_height - buttonHeight - infoHeight, overflowY: 'scroll', paddingBottom: 10 }}>
                            {/* *********************************************************************************************************  
                                KOMENTAR PRE ZAKAZNIKA
                            ********************************************************************************************************* */}
                            {comment != '' ?
                                <div elevation={2} style={{ ...styles.Block, width: '96%', backgroundColor: global.light_gray, marginTop: 5, marginBottom: 5, borderRadius: 10, cursor: 'pointer' }}>
                                    <div style={{ ...styles.BlockLeft, width: '98%' }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            <div style={{ ...styles.BlockLeft, width: '98%' }}>
                                                <p style={{ ...props.isSmall ? styles.TextTinyMobile : styles.TextSmall, textAlign: 'left', marginTop: 10, fontWeight: '600' }}>{lang.reklamacie_message}:</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 30 }}>
                                                <IconButton onClick={() => setShowComment(true)} style={{ width: 34, height: 34, backgroundColor: global.theme_lighter, marginTop: 5 }}>
                                                    <FontAwesomeIcon style={{ width: 14, color: global.theme_dark_blue }} icon={faPen} />
                                                </IconButton>
                                            </div>
                                        </div>
                                        <p style={{ ...props.isSmall ? styles.TextTinyMobile : styles.TextSmall, textAlign: 'left', marginTop: 5, marginBottom: 10, color: global.theme_darker_gray }}>{comment}</p>
                                    </div>
                                </div>
                                : null}

                            {/* *********************************************************************************************************  
                                DOVOD ZAMIETNUTIA
                            ********************************************************************************************************* */}
                            {reklamacia.status == 3 ?
                                <Paper elevation={2} style={{ ...styles.Block, width: '96%', backgroundColor: global.light_gray, marginTop: 5, marginBottom: 5, borderRadius: 10 }}>
                                    <div style={{ ...styles.BlockLeft, width: '98%' }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            <div style={{ ...styles.BlockLeft, width: '98%' }}>
                                                <p style={{ ...props.isSmall ? styles.TextTinyMobile : styles.TextSmall, textAlign: 'left', marginTop: 10, fontWeight: '600' }}>{lang.reklamacie_deny_reason}:</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 30 }}>
                                                <IconButton onClick={() => setShowReasonEdit(true)} style={{ width: 34, height: 34, backgroundColor: global.theme_lighter, marginTop: 5 }}>
                                                    <FontAwesomeIcon style={{ width: 14, color: global.theme_dark_blue }} icon={faPen} />
                                                </IconButton>
                                            </div>
                                        </div>

                                        <p style={{ ...props.isSmall ? styles.TextTinyMobile : styles.TextSmall, textAlign: 'left', marginTop: 5, marginBottom: 10 }}>{reklamacia.deny_reason}</p>
                                    </div>
                                </Paper>
                                : null}

                            {/* *********************************************************************************************************  
                                TEXT REKLAMACIE
                            ********************************************************************************************************* */}
                            {reklamacia.message != '' ?
                                <Paper elevation={2} style={{ ...styles.Block, width: '96%', backgroundColor: global.light_gray, marginTop: 5, marginBottom: 5, borderRadius: 10 }}>
                                    <div style={{ ...styles.BlockLeft, width: '98%' }}>
                                        <p style={{ ...props.isSmall ? styles.TextTinyMobile : styles.TextSmall, textAlign: 'left', marginTop: 10, fontWeight: '600' }}>{lang.booking_reklamacia_text}:</p>
                                        <p style={{ ...props.isSmall ? styles.TextTinyMobile : styles.TextSmall, textAlign: 'left', marginTop: 5, marginBottom: 10 }}>{reklamacia.message}</p>
                                    </div>
                                </Paper>
                                : null}


                            {/* *********************************************************************************************************  
                                ZOZNAM PRODUKTOV
                            ********************************************************************************************************* */}
                            <div style={{ ...styles.Block, height: infoHeight }}>
                                <p style={{ ...styles.TextSmall, color: global.theme_dark }}>{lang.products_list}</p>
                            </div>

                            {items != false ?
                                items.map((item, index) => (
                                    <Paper elevation={2} key={item.id} style={{ ...styles.Block, width: '96%', height: rowHeight, backgroundColor: global.theme_lighter, marginTop: 5, marginBottom: 5, borderRadius: rowHeight / 2 }}>
                                        <div style={{ ...styles.BlockRow }}>
                                            <div style={{ ...styles.BlockLeft, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                <img src={item.image == null || item.image == '' ? no_image : item.image.substring(0, 4) == 'http' ? item.image : global.web_images + '/' + item.image} style={{ width: '100%', height: '100%', width: rowHeight - 10, height: rowHeight - 10, objectFit: 'contain', borderRadius: rowHeight - 10, marginLeft: 5 }}></img>
                                            </div>
                                            {props.isSmall ?
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '75%', justifyContent: 'center', marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextTinyMobile, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                    <p style={{ ...styles.TextTinyMobile, marginTop: 0 }}>{item.code} <span style={{ ...styles.TextTiny }}>{item.details}</span></p>
                                                </div>
                                                :
                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '75%', justifyContent: 'center', marginLeft: 10 }}>
                                                    <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.label}</p>
                                                    <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                        <p style={{ ...styles.TextTiny, marginTop: 0 }}>{item.code} <span style={{ ...styles.TextTiny }}>{item.details}</span></p>
                                                    </div>
                                                </div>
                                            }
                                            {props.isSmall ? null :
                                                <div style={{ ...styles.Block, height: rowHeight, width: 250, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, marginTop: 0 }}>{lang.booking_reklamacia_quantity}</p>
                                                    <p style={{ ...styles.TextTiny, marginTop: 3 }}><b>{item.quantity}</b></p>
                                                </div>
                                            }
                                        </div>
                                    </Paper>
                                )) :
                                // ZIADNE PRODUKTY                            
                                <div style={{ ...styles.Block, paddingTop: 20 }}>
                                    <EmptyList small row lang={lang} />
                                </div>
                            }

                            {images != false ?
                                // FOTOGRAFIE
                                <div style={{ ...styles.Block, marginTop: rowOffset }}>
                                    <p style={{ ...styles.TextSmall, color: global.theme_dark }}>{lang.photos}</p>
                                    <div style={{ ...styles.Block, flexDirection: 'row', flexWrap: 'wrap' }}>
                                        {images.map((item, index) => (
                                            <Paper onClick={() => Preview(item)} elevation={2} key={item.id} style={{ ...styles.Block, width: imageSize, margin: 10, cursor: 'pointer' }}>
                                                <img src={GetImageLink(item.path + item.file)} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain' }}></img>
                                            </Paper>
                                        ))}
                                    </div>
                                </div>
                                : <div style={{ ...styles.Block, paddingTop: 20 }}>
                                    <p style={{ ...styles.TextSmall, color: global.theme_dark, marginBottom: 10 }}>{lang.photos}</p>
                                    <EmptyList small row lang={lang} />
                                </div>
                            }

                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '100%', height: buttonHeight, backgroundColor: global.themes[props.theme].theme_lighter, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                        {props.preview == true ?
                            <div style={{ ...styles.Block, width: '50%', height: buttonHeight, justifyContent: 'center' }}>
                                <p style={{ ...styles.TextTiny }}>{lang.booking_reklamacia_status}: <b>{reklamacia != false ? lang.reklamacia_status[reklamaciaStatus] : null}</b></p>
                            </div>
                            :
                            <div style={{ ...styles.BlockRow }}>
                                <div style={{ ...styles.Block, height: buttonHeight }}>
                                    <p style={{ ...styles.TextTiny }}>{lang.booking_reklamacia_status}: <b>{reklamacia != false ? lang.reklamacia_status[reklamaciaStatus] : null}</b></p>
                                    <div style={{ ...styles.Block, marginTop: 10 }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            {reklamaciaStatus == 0 ?
                                                <Button onClick={() => ChangeStatus(1)} style={{ ...styles.ButtonDark, width: 180 }}>{lang.reklamacia_status[1]}</Button>
                                                : null}
                                            {reklamaciaStatus == 1 ?
                                                <div style={{ ...styles.BlockRowRaw }}>
                                                    <Button onClick={() => ChangeStatusAsk(2)} style={{ ...styles.ButtonGreen, width: props.isSmall ? 160 : 180, marginLeft: 10, marginRight: 10 }}>
                                                        {props.isSmall ? null :
                                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faCheck} />
                                                        }
                                                        <p style={{ ...styles.TextTiny, color: global.theme_white }}>
                                                            {lang.reklamacia_status[2]}
                                                        </p>
                                                    </Button>
                                                    {props.isSmall ? null :
                                                        <Button onClick={() => setShowComment(true)} style={{ ...styles.ButtonBlue, width: props.isSmall ? 160 : 180, marginLeft: 10, marginRight: 10 }}>
                                                            {props.isSmall ? null :
                                                                <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPen} />
                                                            }
                                                            <p style={{ ...styles.TextTiny, color: global.theme_white }}>
                                                                {comment == '' ? lang.reklamacie_message_add : lang.reklamacie_message_edit}
                                                            </p>
                                                        </Button>
                                                    }
                                                    <Button onClick={() => ChangeStatusAsk(3)} style={{ ...styles.ButtonRed, width: props.isSmall ? 160 : 180, marginLeft: 10 }}>
                                                        {props.isSmall ? null :
                                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faXmark} />
                                                        }
                                                        <p style={{ ...styles.TextTiny, color: global.theme_white }}>
                                                            {lang.reklamacia_status[3]}
                                                        </p>
                                                    </Button>
                                                </div>
                                                : null}
                                            {reklamaciaStatus > 1 ?
                                                <div style={{ ...styles.BlockRowRaw }}>
                                                    <Button onClick={() => setShowComment(true)} style={{ ...styles.ButtonBlue, width: props.isSmall ? 160 : 180, marginLeft: 10, marginRight: 10 }}>
                                                        {props.isSmall ? null :
                                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPen} />
                                                        }
                                                        <p style={{ ...styles.TextTiny, color: global.theme_white }}>
                                                            {comment == '' ? lang.reklamacie_message_add : lang.reklamacie_message_edit}
                                                        </p>
                                                    </Button>
                                                </div>
                                                : null}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }
                    </div>
                </div>
            </Paper>

            {showComment == true ?
                <DialogEnterText rows={8} radius={props.radius} title={lang.reklamacie_message_label} sub_title={lang.reklamacie_message_agent} text={comment} icon={faComment} button={props.lang.save} theme={props.theme} func={CommentResult.bind(this)} />
                : null}

            {showSalonCard == true ?
                <SalonCard salon_id={salon.id} remoteApp={props.remoteApp} user={props.user} admin={props.admin} countries={props.countries} country={props.country} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} isSmall={props.isSmall} title={lang.salons_list} sub_title={lang.salons} func={() => setShowSalonCard(false)} />
                : null}

            {showImage == true ?
                <DialogImagePreview theme={props.theme} file={GetImageLink(image.file)} radius={props.radius} lang={lang} title={lang.preview} sub_title={lang.photo} background={global.theme_dark_gray} crop={false} func={() => setShowImage(false)} />
                : null}

            {showExport == true ?
                <DialogEnterText button1={lang.email_mine} button2={lang.email_reklamacie} button1_text={props.user.email} button2_text={global.reklamacne_oddelenie[props.country.language_id]} theme={props.theme} rows={1} radius={props.radius} title={lang.reklamacie_send_title} sub_title={lang.reklamacie_send_subtitle} text={props.user.email} icon={faAt} button={props.lang.send} func={ExportResult.bind(this)} />
                : null}

            {showStatusChange == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.eshop_ask_email} sub_title={''} text={lang.ask_order_change_status} sub_text={lang.reklamacia_status[statusTMP]} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={ChangeStatusResult.bind(this)} />
                : null}

            {enterText == true ?
                <DialogEnterText theme={props.theme} rows={8} radius={props.radius} title={lang.reklamacie_deny} sub_title={lang.reklamacie_deny_reason} text={''} icon={faInfoCircle} button={props.lang.save} func={DialogEnterTextResult.bind(this)} />
                : null}

            {showReasonEdit == true ?
                <DialogEnterText theme={props.theme} rows={8} radius={props.radius} title={lang.reklamacie_deny} sub_title={lang.reklamacie_deny_reason} text={reklamacia.deny_reason} icon={faInfoCircle} button={props.lang.save} func={ReasonResult.bind(this)} />
                : null}

            {showOrder == true ?
                reklamacia.typ == 0 ?
                    <ProductBookingDetailsAgent preview offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} remoteApp={props.remoteApp} admin={props.admin} countries={props.countries} country={props.country} order_id={reklamacia.item_id} lang={lang} radius={props.radius} backcolor={true} background={true} language={props.language} func={() => setShowOrder(false)} />
                    :
                    <BonusBookingDetailsAgent preview offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} remoteApp={props.remoteApp} admin={props.admin} countries={props.countries} country={props.country} order_id={reklamacia.item_id} lang={lang} radius={props.radius} backcolor={true} background={true} language={props.language} func={() => setShowOrder(false)} />
                : null}


            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop >
    );
}