/*
*
* =================================================================================================================
* STATISTIKA SALONOV - INFORMACIE (jeden salon / vsetky salony)
* =================================================================================================================
*
*/

import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { CategorySelect, Colors, ConvertToPrice, CopyData, Debug, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, FormatMoney, GetCountry, GetDate, GetImageLink, GetMoney, GetPrice, GetTodayYearMonth, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, ShowError, ShowOK, TemplateWindow, Today } from './items';
import { styles } from './styles';
import { Backdrop, Button, CircularProgress, IconButton, InputAdornment, Pagination, Paper, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faCheckCircle, faEdit, faFileExport, faL, faLayerGroup, faList, faListAlt, faPaperPlane, faQuestion, faSave, faShoppingBag, faShoppingBasket, faTrash, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Graph } from './graph';


export const StatsInfoSalons = (props) => {
    /*

        <StatsInfoSalons country={props.country} language={props.language} lang={props.lang} user={props.user} salon={salon_id} width={dialogWidth - menuWidth}  offset={props.offset} theme={props.theme}  menu_width={0}  />

        -------------------------------------------------------------------------------------
        admin       -> true - zobrazí štatistiku salónov v celej krajine, false -> len moje
        salon       -> false - bude sa riadiť podľa admin, inak len daný salón
        export      -> možnosť export údajov
        -------------------------------------------------------------------------------------

    */
    const [isBusy, setBusy] = useState(false);
    const [subIndex, setSubIndex] = useState(-1);
    const [items, setItems] = useState(false);
    const [money, setMoney] = useState(GetMoney(props.salon == false ? props.country.language_id : props.salon.language));

    // GRAF
    const [data, setData] = useState(props.salon);
    const [graphData, setGraphData] = useState(false);
    const [label, setLabel] = useState('');
    const [cost, setCost] = useState(0);
    const [costAll, setCostAll] = useState(0);
    const [quantity, setQuantity] = useState(0);

    // EXPORT STATISTIKY
    const [showExport, setShowExport] = useState(false);

    // OK
    const [showOK, setShowOK] = useState(false);


    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const rowHeight = 80;

    const no_image = require('./react/app/no_image.jpg');

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            var today = new Date();

            var m = String(today.getMonth() + 1).padStart(2, '0');
            var y = String(today.getFullYear());


            let filter = {
                date: y + '-' + m,
                year: y,
            }

            db_get_stats(filter);
        }

    }, []);

    const db_get_stats = async (filter) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url_agent + 'salons_stat_info', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    salon_id: props.salon == false ? 0 : props.salon.id,
                    admin: props.admin,
                    language: props.country.language_id,
                    agent_id: props.user.id,
                    filter: filter
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setItems(json.items);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_export = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'stat_agent_info_export', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    admin: props.admin,
                    email: props.user.email,
                    graph_data: items.orders_table,
                    items: items,

                    lang: {
                        do_not_reply: lang.do_not_reply,
                        do_not_reply_text: lang.do_not_reply_text,

                        label: lang.statistic,
                        sub_label: props.admin == true ? lang.salons + ' - ' + GetCountry(props.country.language_id) : lang.salons_rep_ + ' (' + props.user.name + ' ' + props.user.surname + ')',
                        graph_label: lang.info_salons_orders,
                        graph_sub_label: lang.info_salon_orders_stack + ' (' + money + ' ' + lang.with_dph + ')',
                        created: lang.created,

                        money: money,
                        pieces: lang.pieces,

                        orders: lang.info_salon_orders_stack,
                        info_salon_products: lang.info_salon_products,
                        info_salon_top_product: lang.info_salon_top_product,
                        info_eshop_products: lang.info_eshop_products,
                        info_salon_services: lang.info_salon_services,
                        info_salon_top_service: lang.info_salon_top_service,

                        email_subject: lang.export_stats,
                        email_label: lang.info_salons_orders,
                        email_text: lang.export_stats_text,
                    }
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setShowOK(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const GraphInfoResult = (id, value, value2) => {
        if (id == -2) {
            // ZMENA ROKA
            let filter = {
                date: value + '-' + value2,
                year: value,
            }
            db_get_stats(filter);
        }
    }

    const ExportResult = (value) => {
        setShowExport(false);

        if (value == true) {
            db_export();
        }
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    /*
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <div style={{ ...styles.Block, maxWidth: 900 }}>
            <div style={{ ...styles.BlockCenter }}>
                {/* BODY */}
                {props.accountMedical == true ? null :
                    <div>
                        <div style={{ ...styles.Block, marginTop: 20 }}>
                            <Graph
                                lang={props.lang}
                                elevation={0}
                                radius={props.radius}
                                year={false}
                                title={lang.info_clients_age}
                                sub_title={''}
                                width={props.isSmall ? width - 10 : 400}
                                height={320}
                                typ={0}
                                data={items.age_table}
                                barWidth={40}
                                highColor={global.theme_red}
                                overColor={global.theme_blue}
                                showMax={false}
                                lines={true}
                                units={''}
                                marginTop={0}
                                selectable={false}
                                show_value={true}
                                legend={false}
                                legenda={lang.products}
                                legenda2={lang.services}
                                isSmall={props.isSmall}
                                func={null}
                            />
                        </div>
                        <div style={{ ...styles.BlockLeft, marginTop: 10 }}>
                            <p style={{ ...styles.TextSmall, fontWeight: '500' }}>{lang.info_clients_count}: {items.clients}</p>
                            <p style={{ ...styles.TextXSmall }}>{lang.info_clients_young}: {items.min_age} {lang.age_years}</p>
                            <p style={{ ...styles.TextXSmall }}>{lang.info_clients_old}: {items.max_age} {lang.age_years}</p>
                            <p style={{ ...styles.TextSmall, marginTop: 10, fontWeight: '400' }}>{lang.info_clients_average}: {items.average} {lang.age_years}</p>
                            <p style={{ ...styles.TextXSmall }}>{lang.info_clients_womans}: {items.woman}</p>
                            <p style={{ ...styles.TextXSmall }}>{lang.info_clients_mans}: {items.man}</p>
                        </div>
                    </div>
                }
                <div style={{ ...styles.Block, marginTop: 50 }}>
                    <Graph
                        lang={props.lang}
                        elevation={0}
                        radius={props.radius}
                        year={true}
                        title={props.salon == false ? lang.info_salons_orders : lang.info_salon_orders}
                        sub_title={''}
                        width={props.isSmall ? width - 10 : props.width - 60 > 900 ? 900 : props.width - 60}
                        height={320}
                        typ={0}
                        data={items.orders_table}
                        barWidth={props.isSmall ? 26 : 60}
                        highColor={global.theme_red}
                        overColor={global.theme_blue}
                        showMax={false}
                        lines={true}
                        units={money}
                        marginTop={0}
                        selectable={false}
                        show_value={true}
                        legend={false}
                        legenda={lang.products}
                        legenda2={lang.services}
                        isSmall={props.isSmall}
                        func={GraphInfoResult.bind(this)}
                    />
                </div>
                {props.export == true ?
                    <div style={{ ...styles.BlockRight, marginTop: 10 }}>
                        <Button onClick={() => setShowExport(true)} style={{ ...styles.ButtonDarkSmall, backgroundColor: global.theme_light_gray, color: global.theme_black, width: 200 }}>
                            <FontAwesomeIcon style={{ height: 16, color: global.theme_black, marginRight: 10 }} icon={faPaperPlane} />
                            {lang.export}
                        </Button>
                    </div>
                    : null}

                <div style={{ ...styles.BlockLeft, marginTop: 10, paddingBottom: 40 }}>
                    {props.isSmall ?
                        <div style={{ ...styles.Block }}>
                            <p style={{ ...styles.TextSmall, fontWeight: '500', textAlign:'left' }}>{lang.info_salon_orders_stack}:</p>
                            <p style={{ ...styles.TextSmall, fontWeight: '500', textAlign:'left' }}>{FormatMoney(items.orders_sum)} {money}</p>
                        </div>
                        :
                        <p style={{ ...styles.TextSmall, fontWeight: '500' }}>{lang.info_salon_orders_stack}: {FormatMoney(items.orders_sum)} {money}</p>
                    }
                    <p style={{ ...styles.TextSmall, marginTop: 15, fontWeight: '400' }}>{lang.info_salon_products}: {items.products_salon_quantity} {lang.pieces}</p>
                    <p style={{ ...styles.TextXSmall }}>{lang.info_salon_top_product}: {items.products_salon_max_label}</p>
                    {props.accountMedical == true ? null :
                        <div>
                            <p style={{ ...styles.TextSmall, marginTop: 10, fontWeight: '400', textAlign: 'left' }}>{lang.info_eshop_products}: {items.eshop_salon_quantity} {lang.pieces}</p>
                            <p style={{ ...styles.TextXSmall, textAlign: 'left' }}>{lang.info_salon_top_product}: {items.eshop_salon_max_label}</p>
                            <p style={{ ...styles.TextSmall, marginTop: 10, fontWeight: '400', textAlign: 'left' }}>{lang.info_salon_services}: {items.services_salon_quantity} x</p>
                            <p style={{ ...styles.TextXSmall, textAlign: 'left' }}>{lang.info_salon_top_service}: {items.services_salon_max_label}</p>
                        </div>
                    }
                </div>
            </div>

            {showExport == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.export_stats} sub_title={''} text={lang.export_agent_data_ask} sub_text={lang.export_agent_data_text + ' ' + props.user.email} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={ExportResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}
        </div>
    );
}
