import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styles } from './styles.js';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import img_background from './react/app/background.jpg';


export const NotFound = (props) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);


    const navigate = useNavigate();
    let params = useParams();

    useEffect(() => {

        return () => {
        };

    }, []);
    return (
        <div style={{ ...styles.Block, height: height, backgroundImage: `url(${img_background})`, backgroundSize: 'cover' }}>
            <div style={{ ...styles.Block, width: 320, height: 150, backgroundColor: '#FFFFFF88', borderRadius: 20 }}>
                <FontAwesomeIcon style={{ width: 60, height: 60, color: global.theme_dark_red }} icon={faTriangleExclamation} />
                <p style={{ ...styles.TextLarge, marginTop: 20 }}>Error 404</p>
            </div>
        </div>
    )

};

